import { Component, Input, Inject } from '@angular/core';
import {FirmwareLinkV2Entity} from "../../../entities/firmware/link-v2.entity";
import {FirmwareApprovalEntity} from '../../../entities/firmware/approval.entity';

@Component({
  selector: 'embed-firmware-approval',
  template: `
   <firmware-approval-widget [entity]="entity" [options]="options" [layout]="layout"></firmware-approval-widget>
    `
})
export class EmbedFirmwareApprovalComponent {
  @Input() entity: FirmwareApprovalEntity = null;
  @Input() options: any = null;
  @Input() layout: string = null;
  constructor() {
  }

}
