import {Component, Input, Inject, Output, EventEmitter} from '@angular/core';
import {LacrosseEntity} from "../../../entities/lacrosse.entity";
import {NoizuStruct} from "../../../noizu/structs/noizu-struct";
import {CallbackObject, RuntimeConfigurationObject, SubscriberObject} from '../../../entities/alert';
import {WidgetBase} from '../widget-base';
import {PermissionWidgetEventStruct} from '../permission';
import {RuntimeWidgetEventStruct} from './runtime-widget-event.struct';

@Component({
  selector: 'runtime-config-widget',
  template: `
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
            <div class="col"><b class="text-left">Runtime Configuration </b></div>
            <div class="col-2 text-right p-0 text-middle">
              <toggle-widget [options]="expand_settings"></toggle-widget>
            </div>
          </div>
          </div>
          <div class="card-body"  [ngClass]="{'d-none': !expand_settings.toggle}" ><pre>{{entity.toJson()| json}}</pre></div>
        </div>
      </div>
    </div>
    `
})
export class RuntimeConfigurationObjectWidgetComponent extends WidgetBase{
  @Input() entity: RuntimeConfigurationObject = null;
  @Input() options: any = null;
  @Input() layout: string = null;
  @Output() widgetEvent = new EventEmitter<RuntimeWidgetEventStruct>();
  forwardEvent(widgetEvent: RuntimeWidgetEventStruct) {
    this.widgetEvent.emit(widgetEvent);
  }
  constructor() {
    super();
  }
}
