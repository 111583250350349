
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { FirebaseAuthService }        from '../../noizu/services/firebase-auth.service';
import {ElixirRepo} from '../elixir.repo';
import {PinEntity} from '../../entities/entity-pins/pin.entity';
import {PinGroupEntity} from '../../entities';

@Injectable()
export class PinGroupRepo extends ElixirRepo {
  public _kind = "entity-pins";
  public _singular = "entity-pin";
  public _appengine = false;

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  entity(record) {
    return new PinGroupEntity(this.client, this.auth, record)
  }

  getListPromise(options:any = {}) {
    let expand_refs = "expand-pins=true&expand-refs=device,device-type";
    let include_pins = "";
    let join = "";
    if (options['expand'] === false) {
      expand_refs = "";
    }
    if (options['pins']) {
      if (expand_refs != "") join = "&"
      include_pins = "include-pins=true";
    }

    let url = `${this.apiBase()}/admin-tools/user/entity-pin-groups?${expand_refs}${join}${include_pins}`;
    return this._getListPromise(url, (record) => {return this.entity(record); }, options);
  }

}
