
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { FirebaseAuthService } from '../noizu/services/firebase-auth.service';
import { DomainObject } from '../noizu/domain-object';
import { WidgetEnum} from '../enums';

export class ElixirEntity extends DomainObject {
  public _kind: string = null;
  public _singular: string = null;
  public _deleted = false;
  public _modified = false;
  public _new = false;

  public get new() {return this._new; }
  public set new(v: boolean) {this._new = v; }

  public get modified() {return this._modified; }
  public set modified(v: boolean) {this._modified = v; }

  public get deleted() {return this._deleted; }
  public set deleted(v: boolean) {this._deleted = v; }

  constructor(client: HttpClient, auth: FirebaseAuthService, raw) {
    super(client, auth, raw);
    this.strip_from_json['new'] = 1;
    this.strip_from_json['_new'] = 1;
    this.strip_from_json['_modified'] = 1;
    this.strip_from_json['_deleted'] = 1;
    this.strip_from_json['_singular'] = 1;
    this.strip_from_json['_version'] = 1;
    this.strip_from_json['_api'] = 1;
    this.strip_from_json['_endpoint'] = 1;
    this.strip_from_json['validation'] = 1;
  } // end constructor






  refresh(data) {
    return super.refresh(data);
  }

  singular(str) {
    this._singular = str;
  }

  apiBase() {
    return this.ingvEndpoint();
  }

  updateEndpoint() {
    return `${this.apiBase()}/${this._singular}/${this.identifier}`;
  }

  createEndpoint() {
    return `${this.apiBase()}/${this._kind}`
  }

  deleteEndpoint() {
    return `${this.apiBase()}/${this._singular}/${this.identifier}`;
  }

  update() {
    if (this.identifier === 'new' || this.new || this.meta.new) {
      let entity = this.toJson({for: 'elixir'});
      entity.identifier = null;
      return this._post(this.createEndpoint(), entity,
        (data, resolve) => {
        this.modified = false;
        this.new = false;
        resolve(this.refresh(data)); },
        {}
      );
    } else {
      return this._put(this.updateEndpoint(), this.toJson({for: 'elixir'}),
        (data, resolve) => {
          this.modified = false;
          this.new = false;
          resolve(this.refresh(data));
        },
        {}
      );
    }
  }


  delete() {
    return this._delete(
      this.deleteEndpoint(),
      (_data, resolve) => {
        this.deleted = true;
        resolve(true)
      },
      {}
    );
  }

  public widget() {
    return `embed-${this._singular}`;
  }

} // end Package
