<!--
DataManagementSynchQueueComponent
<spinner *ngIf="!entries"></spinner>

<div *ngIf="entries">
  <pre *ngFor="let entry of entries.items">
    {{entry.raw|json}}
  </pre>
</div>
-->
<page-header [section]="'synch-queue-list'">
  <page-title>Synch Queue List</page-title>
  <page-blurb>View and manage sync queue list, and initiate actions to retry or purge a user alert. Monitor status of user alert if synchronized or not.</page-blurb>
</page-header>

<div class="row">

  <div class="col-12">
      <div class="row">
        <div class="col-md-12">
          <div *ngIf="state?.loading || entries" class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  <i class="fa fa-align-justify"></i> Synch Queue List
                </div>
                <div class="card-body card-block">
                  <div *ngIf="state?.loading" class="overlayParent spinner-holder">
                    <div  class="overlay">
                      <widget-spinner></widget-spinner>
                    </div>
                  </div>
                  <div *ngIf="!state?.loading">
                    <div *ngIf="!entries?.items">No Matching Entries Found</div>
                    <div *ngIf="entries?.items">
                      <ul class="pagination">
                          <li class="page-item" *ngIf="pagination.page > 0"><a (click)="searchAt(entries, pagination.page - 1,  $event)" class="page-link">Previous</a></li>

                          <li class="page-item" *ngFor="let cursor of pagination.cursorArray; let cursorIndex = index;" [ngClass]="{'active' : cursorIndex == pagination.page}">
                            <a (click)="searchAt(entries, cursorIndex, $event)" class="page-link">{{cursorIndex}}</a>
                          </li>

                          <li class="page-item" *ngIf="pagination.next && pagination.next != ''"><a (click)="searchNext(entries, $event)" class="page-link">Next</a></li>
                      </ul>

                      <table class="table table-bordered table-striped table-sm">
                        <thead><tr>
                          <th>Id</th>
                          <th>Created On</th>
                          <th (click)="sortEntries(2)" class="pointer"><strong><u>Enviroment</u></strong></th>
                          <th (click)="sortEntries(1)" class="pointer"><strong><u>Entity Class</u></strong></th>
                          <th>Entity ID</th>
                          <th (click)="sortEntries(3)" class="pointer"><strong><u>Status</u></strong></th>
                          <th>Token</th>
                          <th>Action</th>
                        </tr></thead>
                        <tbody>
                          <tr *ngFor="let entry of entries.items;">
                            <td>{{entry.identifier}}</td>
                            <td>{{entry.createdOn | date:'MM/dd/yyyy @ h:mm:ssa'}}</td>
                            <td>

                              <span *ngIf="entry.environment == 1" >VNext</span>
                              <span *ngIf="entry.environment == 0" >Legacy</span>

                            </td>
                            <td>{{entry.entityClass}}</td>
                            <td>{{entry.entityIdentifier}}</td>
                            <td>


                              <span *ngIf="entry.status == 100001" class="badge badge-info">CREATE ERROR</span>
                              <span *ngIf="entry.status == 100002" class="badge badge-info">UPDATE ERROR</span>
                              <span *ngIf="entry.status == 100003" class="badge badge-info">DELETE ERROR</span>
                              <span *ngIf="entry.status == 100004" class="badge badge-success">Synchronized</span>


                            </td>
                            <td><span [title]="entry.requestToken">{{entry.requestToken|slice:0:16 }}</span></td>
                            <td>
                              <button (click)="entry.retry(false)" type="button" class="btn btn-primary">Retry</button>
                              <button (click)="entry.retry(true)" type="button" class="btn btn-danger">W/Purge</button>
                            </td>

                          </tr>
                        </tbody>
                      </table>
                      <ul class="pagination">
                          <li class="page-item" *ngIf="pagination.page > 0"><a (click)="searchAt(entries, pagination.page - 1,  $event)" class="page-link">Previous</a></li>

                          <li class="page-item" *ngFor="let cursor of pagination.cursorArray; let cursorIndex = index;" [ngClass]="{'active' : cursorIndex == pagination.page}">
                            <a (click)="searchAt(entries, cursorIndex, $event)" class="page-link">{{cursorIndex}}</a>
                          </li>

                          <li class="page-item" *ngIf="pagination.next && pagination.next != ''"><a (click)="searchNext(entries, $event)" class="page-link">Next</a></li>
                      </ul>
                    </div> <!-- end *ngIf="entries" -->
                  </div>
                </div>
              </div><!-- end search results card -->
            </div>
          </div>
        </div>
      </div><!-- end search results row -->
    </div>
</div>
<style>
.pointer {cursor:pointer;color:blue};
</style>
<!--
<ng-template #confirm>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{currentModal.title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {{currentModal.msg}}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="currentModal.cancel()" data-dismiss="modal">{{currentModal.cancelMsg}}</button>
    <button type="button" class="btn btn-danger" (click)="currentModal.confirm()">{{currentModal.confirmMsg}}</button>
  </div>
</ng-template>
-->
