
    <div *ngIf="status?.feed">

      <div class="row mb-4">
        <div class="col-12 col-md-6">
          <select class="form-control" name="aggregate" [(ngModel)]="aggregate">
            <option *ngFor="let entry of selectors()" value={{entry.value}}>
              {{entry.name}}
            </option>
          </select>
        </div>
        
        <div class="col-12 col-md-6">
          <select class="form-control" name="pullType" [(ngModel)]="pullType">
            <option value="head">Running Average</option>
            <option value="pending">Current Period</option>
            <option value="trail">Previous Period</option>
          </select>
        </div>
      </div>
        
      <div *ngIf="status.feed[sequence[aggregate]?.index]?.data" >
        <div *ngIf="aggregate != 'current' && aggregate != 'unbound'">
          <div *ngIf="status.feed[sequence[aggregate].index].data[pullType]" >

            
            <div class="row">
              <div class="col-12">

                <div class="card">
                  <div class="card-header">Pull Ranges</div>
                  <div class="card-block">

                    <div class="col-12" *ngIf="status.feed[sequence[aggregate].index].data[pullType]?.cover?.u_from">
                      First Record: {{ status.feed[sequence[aggregate].index].data[pullType].cover.u_from * 1000 | date:'MM/dd/yyyy @ h:mm:ssa' }}
                    </div>
                    <div class="col-12" *ngIf="!status.feed[sequence[aggregate].index].data[pullType]?.cover?.u_from">
                      First Record: N/A
                    </div>

                    <div class="col-12" *ngIf="status.feed[sequence[aggregate].index].data[pullType]?.cover?.u_to">
                      Last Record: {{ status.feed[sequence[aggregate].index].data[pullType].cover.u_to * 1000 | date:'MM/dd/yyyy @ h:mm:ssa' }}
                    </div>
                    <div class="col-12" *ngIf="!status.feed[sequence[aggregate].index].data[pullType]?.cover?.u_to">
                      Last Record: N/A
                    </div>

                    <div class="col-12" *ngIf="status.feed[sequence[aggregate].index].data[pullType]?.block?.u_from">
                      Block Start: {{ status.feed[sequence[aggregate].index].data[pullType].block.u_from * 1000 | date:'MM/dd/yyyy @ h:mm:ssa' }}
                    </div>
                    <div class="col-12" *ngIf="!status.feed[sequence[aggregate].index].data[pullType]?.block?.u_from">
                      Block Start: N/A
                    </div>

                    <div class="col-12" *ngIf="status.feed[sequence[aggregate].index].data[pullType]?.block?.u_to">
                      Block End: {{ status.feed[sequence[aggregate].index].data[pullType].block.u_to * 1000 | date:'MM/dd/yyyy @ h:mm:ssa' }}
                    </div>
                    <div class="col-12" *ngIf="!status.feed[sequence[aggregate].index].data[pullType]?.block?.u_to">
                      Block End: N/A
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
            
          </div>
        </div>
        
        
        <div class="row m-0 p-0" *ngFor="let field of extractFields(aggregate, pullType, status.feed[sequence[aggregate].index].data)" >
          <div class="col-12 m-0 p-0">
          <div class="card m-0 p-0">
            <div class="card-header  m-1 p-1"><b>{{field.key}}</b> ({{field.value.unit}})</div>
            <div class="card-block  m-1 p-1">
              <div *ngFor="let reading of readings">
                <div *ngIf="field.value[reading]">
                  <div class="row" >
                    <div class="col-3"><b>{{reading}}</b></div>
                    <div class="col-3">{{field.value[reading].value| number}}</div>
                    <div class="col-6" *ngIf="field.value[reading]['time']">{{field.value[reading].time * 1000| date:'MM/dd/yyyy @ h:mm:ssa'}}</div>
                    <div class="col-12" *ngIf="hasAttributes(field.value[reading])">Attributes: {{field.value[reading].attributes | json}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      </div>
    </div>
