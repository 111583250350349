
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { DomainObjectRepo } from '../../noizu/domain-object-repo';
import { FirebaseAuthService }        from '../../noizu/services/firebase-auth.service';
import { ReportingEntity }      from '../../entities/reporting.entity';
import { AppengineRepo }      from '../appengine.repo';

@Injectable()
export class ReportingRepo extends AppengineRepo {
  public _kind = "";
  public _singular = "";

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  entity(record) {
    return new ReportingEntity(this.client, this.auth, record)
  }

  getViewStatus() { //
    return this._get(`https://my-module-dot-decent-destiny-704.appspot.com/services/viewStatusCheck`, (record, resolve) => {
      resolve(record);
    }, {});
  }

  getViewVars() {
    return this._get(`https://my-module-dot-decent-destiny-704.appspot.com/services/viewStatusCheck?update=update&action=get`, (record, resolve) => {
      resolve(record);
    }, {});
  }

  updateViewAlert(vars) {
    //console.log(`https://my-module-dot-decent-destiny-704.appspot.com/services/viewStatusCheck?update=update&action=${vars["action"]}&id=${vars["id"]}&type=${vars["type"]}&val=${vars["val"]}`);
    return this._get(`https://my-module-dot-decent-destiny-704.appspot.com/services/viewStatusCheck?update=update&action=${vars["action"]}&id=${vars["id"]}&type=${vars["type"]}&val=${vars["val"]}`
        , (record, resolve) => {
      resolve(record);
    }, {});
  }

  getWeatherCategory(cat) {
    return this._get(`https://ingv2.lacrossetechnology.com/api/v1.1/forecast/admin/getRanges/WeatherService/${cat}`,
      (record, resolve) => {
      resolve(record);
    }, {});
  }

}
