
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { DomainObjectRepo } from '../noizu/domain-object-repo';
import { FirebaseAuthService }        from '../noizu/services/firebase-auth.service';
import { AppengineRepo }      from './appengine.repo';
import { PermissionsEntity } from '../entities/permissions.entity';

@Injectable()
export class PermissionsRepo extends AppengineRepo {
  public _kind = "permissions";
  public _singular = "permission";
  public _appengine = true;

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  entity(record) {
    return new PermissionsEntity(this.client, this.auth, record);
  }

  getGroupPermissionsPromise(id, options:any = {}) {
    this._appengine = false;
    let url = `${this.apiBase()}/group/${id}/admin-permissions`;
    return this._getEntityPromise(url, (record, resolve) =>
    {
      let ret = record["items"] ? this.entity(record["items"][0]) : null;
      resolve(ret);
    }, options);
  }
/*
  getListPromise(options:any = {}) {

    this._appengine = true;
    let url = `${this.apiBase()}/${this._kind}?resultsPerPage=500`;
    if ("id" in options) url = url + `&id=${encodeURIComponent(options.id)}`;
    if ("system" in options && options["system"] == true) url = url + "&system=true";
    /*
    if ("displayName" in options) url = url + `&displayName=${encodeURIComponent(options.displayName)}`;
    if ("lastName" in options) url = url + `&lastName=${encodeURIComponent(options.lastName)}`;
    if ("email" in options) url = url + `&email=${encodeURIComponent(options.email)}`;
    if ("id" in options) url = url + `&id=${encodeURIComponent(options.id)}`;

    * /

    // Should be moved into base.
    if ("cursor" in options) {
      url = url + `&cursor=${options.cursor}`
    }

    console.log(`url: ${url}`);
    return this._getListPromise(url, (record) => {return this.entity(record); }, options);

  }
  */
}
