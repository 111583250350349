import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FirmwareTargetSetLogicalWidget, TargetStruct} from '../../../../entities';
import {FirmwareLogicalWidgetEventStruct} from './firmware-logical-widget-event.struct';
import {FirmwareWidgetEnum, LogicalWidgetEnum} from '../../../../enums';
import {FirmwareWidgetEventStruct} from '../../firmware';

@Component({
  selector: 'firmware-target-set-logical-widget',
  template: `
    <div *ngIf="entity">
      <div class="card mt-2">
        <div class="card-header">Targets <div class="card-header-actions"><i *ngIf="options?.edit" (click)="addTarget($event)" class="fa fa-2x fa-plus text-success"></i></div></div>
        <div class="card-body p-1 m-1">
          <div *ngIf="entity.selected">
            <div class="row" *ngFor="let target of entity.selected; let i = index">
              <div class="col-12">
                <firmware-target-widget (widgetEvent)="eventHandler($event, i)" [entity]="target" [layout]="'inline'" [options]="options"></firmware-target-widget>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class FirmwareTargetSetLogicalWidgetComponent implements OnInit {
  @Input() entity: FirmwareTargetSetLogicalWidget | null = null;
  @Input() templateHint: string = null;
  @Input() options: any = null;
  @Input() layout = 'standard';

  @Output() widgetEvent = new EventEmitter<FirmwareLogicalWidgetEventStruct>();
  forwardEvent(e, widgetEvent: FirmwareLogicalWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }
  updateOutput() {
    let event = new FirmwareLogicalWidgetEventStruct()
    event.event_widget_type = this.entity.widget_type();
    event.event_logical_widget_type = this.entity.logical_widget_type();
    event.event_firmware_logical_widget_type = this.entity.firmware_logical_widget_type();
    event.event_type = "firmware_target_set_saved"
    event.event_body = {entity: this.entity.selected}
    this.widgetEvent.emit(event);
  }

  public valueSubscriber: any = null;

  constructor() {

  }

  public ngOnInit() {
    this.entity.selected = this.entity.subject
    this.options.target_delete = true;
  }

  addTarget(e) {
    e.preventDefault()
    if (!this.entity.selected) {
      this.entity.selected = [];
    }
    let t = new TargetStruct({});
    this.entity.selected.push(t);
  }

  removeTarget(e, i) {
    e.preventDefault()
    this.entity.selected.splice(i, 1);
  }

  eventHandler(event, index) {
    if (event instanceof FirmwareWidgetEventStruct) {
      if (event.event_firmware_widget_type == FirmwareWidgetEnum.FIRMWARE_WIDGET__TARGET) {
        if (event.event_type == 'delete_target') {
          this.entity.selected.splice(index, 1);
          return;
        }

        if (event.event_type == 'is_valid') {


          let valid = true;
          for(let i in this.entity.selected) {
            this.entity.selected[i].validate();
            valid = valid && this.entity.selected[i].validation.valid
          }

          if (valid) {
            let event = new FirmwareLogicalWidgetEventStruct()
            event.event_widget_type = this.entity.widget_type();
            event.event_logical_widget_type = this.entity.logical_widget_type();
            event.event_firmware_logical_widget_type = this.entity.firmware_logical_widget_type();
            event.event_type = "is_valid"
            event.event_body = {}
            this.widgetEvent.emit(event);
          }




        }

      }
    }
    console.log('TargetSet unhandled event', index, event)
  }

}
