import {HttpClient} from '@angular/common/http';
import {FirebaseAuthService} from '../../../noizu/services/firebase-auth.service';
import {AlertLogWidgetEnum, LogWidgetEnum} from '../../../enums';
import {EntityLogEntity} from '../entity-log.entity';

export class AlertEntityLogEntity extends EntityLogEntity {
  public alert_log_type: AlertLogWidgetEnum = AlertLogWidgetEnum.ALERT_LOG_WIDGET__GENERIC;

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);

    switch(this.topic) {
      case 'load':
        this.alert_log_type = AlertLogWidgetEnum.ALERT_LOG_WIDGET__LOAD;
        break;
      case 'load_error':
        this.alert_log_type = AlertLogWidgetEnum.ALERT_LOG_WIDGET__LOAD_ERROR;
        break;
      case 'set_enabled':
        this.alert_log_type = AlertLogWidgetEnum.ALERT_LOG_WIDGET__SET_ENABLED;
        break;
      case 'set_disabled':
        this.alert_log_type = AlertLogWidgetEnum.ALERT_LOG_WIDGET__SET_DISABLED;
        break;
      case 'prepare_callbacks':
        this.alert_log_type = AlertLogWidgetEnum.ALERT_LOG_WIDGET__PREPARE_CALLBACKS;
        break;
      case 'register_callback':
        this.alert_log_type = AlertLogWidgetEnum.ALERT_LOG_WIDGET__REGISTER_CALLBACK;
        break;
      case 'deregister_callback':
        this.alert_log_type = AlertLogWidgetEnum.ALERT_LOG_WIDGET__DEREGISTER_CALLBACK;
        break;
      case 'refresh_callback':
        this.alert_log_type = AlertLogWidgetEnum.ALERT_LOG_WIDGET__REFRESH_CALLBACK;
        break;
      case 'triggered':
        this.alert_log_type = AlertLogWidgetEnum.ALERT_LOG_WIDGET__TRIGGERED;
        break;
      case 'triggered_error':
        this.alert_log_type = AlertLogWidgetEnum.ALERT_LOG_WIDGET__TRIGGERED_ERROR;
        break;
      case 'recovered':
        this.alert_log_type = AlertLogWidgetEnum.ALERT_LOG_WIDGET__RECOVERED;
        break;
      case 'recovered_error':
        this.alert_log_type = AlertLogWidgetEnum.ALERT_LOG_WIDGET__RECOVERED_ERROR;
        break;
      case 'refresh':
        this.alert_log_type = AlertLogWidgetEnum.ALERT_LOG_WIDGET__REFRESH;
        break;
      case 'refresh_error':
        this.alert_log_type = AlertLogWidgetEnum.ALERT_LOG_WIDGET__REFRESH_ERROR;
        break;
      case 'unchanged':
        this.alert_log_type = AlertLogWidgetEnum.ALERT_LOG_WIDGET__UNCHANGED;
        break;
      case 'unloaded':
        this.alert_log_type = AlertLogWidgetEnum.ALERT_LOG_WIDGET__UNLOADED;
        break;
      case 'created':
        this.alert_log_type = AlertLogWidgetEnum.ALERT_LOG_WIDGET__CREATED;
        break;
      case 'updated':
        this.alert_log_type = AlertLogWidgetEnum.ALERT_LOG_WIDGET__UPDATED;
        break;
      case 'deleted':
        this.alert_log_type = AlertLogWidgetEnum.ALERT_LOG_WIDGET__DELETED;
        break;
      default:
        this.alert_log_type = AlertLogWidgetEnum.ALERT_LOG_WIDGET__GENERIC;
    }



  } // end constructor



  public brief() {
    if (!this._brief) {

      switch (this.alert_log_widget_type()) {
        case AlertLogWidgetEnum.ALERT_LOG_WIDGET__LOAD:
          this._brief = 'Load Alert Worker';
          break;
        case AlertLogWidgetEnum.ALERT_LOG_WIDGET__LOAD_ERROR:
          this._brief = 'Load Alert (Error)';
          break;
        case AlertLogWidgetEnum.ALERT_LOG_WIDGET__SET_ENABLED:
          this._brief = 'Enable Alert';
          break;
        case AlertLogWidgetEnum.ALERT_LOG_WIDGET__SET_DISABLED:
          this._brief = 'Disable Alert';
          break;
        case AlertLogWidgetEnum.ALERT_LOG_WIDGET__PREPARE_CALLBACKS:
          this._brief = 'Prep. Callbacks';
          break;
        case AlertLogWidgetEnum.ALERT_LOG_WIDGET__REGISTER_CALLBACK:
          this._brief = 'Register Callback';
          break;
        case AlertLogWidgetEnum.ALERT_LOG_WIDGET__DEREGISTER_CALLBACK:
          this._brief = 'Deregister Callback';
          break;
        case AlertLogWidgetEnum.ALERT_LOG_WIDGET__REFRESH_CALLBACK:
          this._brief = 'Refresh Callback';
          break;
        case AlertLogWidgetEnum.ALERT_LOG_WIDGET__TRIGGERED:
          this._brief = 'Alert Triggered';
          break;
        case AlertLogWidgetEnum.ALERT_LOG_WIDGET__TRIGGERED_ERROR:
          this._brief = 'Alert Triggered (Error)';
          break;
        case AlertLogWidgetEnum.ALERT_LOG_WIDGET__RECOVERED:
          this._brief = 'Alert Recovered';
          break;
        case AlertLogWidgetEnum.ALERT_LOG_WIDGET__RECOVERED_ERROR:
          this._brief = 'Alert Recovered (Error)';
          break;
        case AlertLogWidgetEnum.ALERT_LOG_WIDGET__REFRESH:
          this._brief = 'Refresh Trigger State';
          break;
        case AlertLogWidgetEnum.ALERT_LOG_WIDGET__REFRESH_ERROR:
          this._brief = 'Refresh Trigger State (Error)';
          break;
        case AlertLogWidgetEnum.ALERT_LOG_WIDGET__UNCHANGED:
          this._brief = 'Unchanged Trigger State';
          break;
        case AlertLogWidgetEnum.ALERT_LOG_WIDGET__UNLOADED:
          this._brief = 'Unload Worker';
          break;
        case AlertLogWidgetEnum.ALERT_LOG_WIDGET__CREATED:
          this._brief = 'Created Alert';
          break;
        case AlertLogWidgetEnum.ALERT_LOG_WIDGET__UPDATED:
          this._brief = 'Updated Alert';
          break;
        case AlertLogWidgetEnum.ALERT_LOG_WIDGET__DELETED:
          this._brief = 'Deleted Alert';
          break;
        default:
          this._brief = 'Other Alert Event';
      }
    }
    return this._brief;
  }

  log_widget_type(): LogWidgetEnum | null {
    return LogWidgetEnum.LOG_WIDGET__ALERT;
  }

  alert_log_widget_type(): AlertLogWidgetEnum  {
    return this.alert_log_type;
  }
} // end Package
