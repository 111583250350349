
import { Component, Inject, Input, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DeviceFieldDefinitionRepo } from '../../../../repos/device/field/definition.repo';
import { DeviceFieldDefinitionEntity } from '../../../../entities/device/field/definition.entity';
import {AuthService} from '../../../../services/auth.service';

@Component({
  template: `
    <div>
      <spinner *ngIf="!entity"></spinner>
      <widget *ngIf="entity" [entity]="entity" [layout]="'standard'" [options]="options"></widget>
    </div>
  `
})
export class DeviceFieldDefinitionViewComponent implements OnInit {
  public identifier: Number = null;
  public entity: DeviceFieldDefinitionEntity = null;
  public subscription: any;
  public options = {};
  constructor(
    public repo: DeviceFieldDefinitionRepo,
    public zone: NgZone,
    public authService: AuthService,
    public activatedRoute: ActivatedRoute,
  ) {
    if (this.authService.userHasPermission("device_type_admin")) {
      this.options['edit'] = true;
    }

  }

  ngOnInit() {
    this.subscription = this.activatedRoute.params.subscribe(
      (params: any) => {
        console.log("params", params);
        if ('id' in params) {
          let id = new Number(params.id);
          this.identifier = id;
          this.loadDefinition(id);
        }
      }
    );
  }


  loadDefinition(id) {
    this.repo.getEntityPromise(id).then((entity: DeviceFieldDefinitionEntity) => {
      this.entity = entity;
    });
  }

}
