import { NoizuStruct }        from '../../../noizu/structs/noizu-struct';
import { NodeTypeEnum }      from '../../../enums/node-type.enum';

export class FormulaTreeNodeType extends NoizuStruct {
  public enum: any;
  public name: string;
  constructor(json: any) {
      super();
      this.name = NodeTypeEnum[json];
      this.enum = NodeTypeEnum[this.name];
  } // end constructor

  toJson(options = {}) {
    return {name: this.name, enum: this.enum};
  }

}
