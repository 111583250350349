


    <ng-container [ngSwitch]="layout">

      <!-- ========================================================================== -->
      <!-- Table Layout                                                               -->
      <!-- ========================================================================== -->
      <ng-container *ngSwitchCase="'table'">
        <div class="firmware-definition-widget" [ngClass]="{'div-table-tr': (options?.index % 2) == 1, 'div-table-tr-alt': (options?.index % 2) == 0}">
          <div class="row">
            <div class="col-3 text-left"> #{{entity?.identifier }}</div>
            <div class="col-3 text-left"> {{entity?.manufacturerId }} </div>
            <div class="col-3 text-left"> {{entity?.series }} </div>
            <div class="col-3 text-left"> {{entity?.firmwareVersion}} </div>
          </div>
        </div>
      </ng-container>

      <div class="firmware-definition-widget" *ngSwitchCase="'shadowbox'">
        <ng-container *ngTemplateOutlet="defaultView"></ng-container>
      </div>

      <!-- ========================================================================== -->
      <!-- Default                                                                    -->
      <!-- ========================================================================== -->
      <div class="firmware-definition-widget" *ngSwitchDefault>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div *ngIf="entity">
                <!-- put link to manufacturer -->
                <div class="card-header">
                  <strong>Firmware Definition:</strong>

                  <span> Version ({{entity?.firmwareVersion}}) </span>

                  <span *ngIf="entity?.manufacturerId == '14'"> Sino (14)</span>
                  <span *ngIf="entity?.manufacturerId == '23'"> Fos (23)</span>
                  <span *ngIf="entity?.manufacturerId != '23' && entity?.manufacturerId != '14' "> Unknown ({{entity?.manufacturerId | json}})</span>


                </div>
                <div class="card-body m-1 p-0">
                  <ng-container *ngTemplateOutlet="defaultView"></ng-container>
                </div>
                <div class="card-footer" *ngIf="entity?.pending && !options?.modal_form">
                  <div class="row">
                    <div class="col-12">
                      <div class="alert alert-dark">
                        Caution: Saving changes to a firmware definition will clear it's approval flag, blocking units from OTAing to it if currently active!
                      </div>
                    </div>
                    <div class="col-12">
                      <button (click)="save(entity, $event)" class="btn btn-sm btn-danger" type="submit"><i class="fa fa-plus-circle"></i> Save Changes</button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>



    <ng-template #defaultView>



      <div class="row">
        <div class="col-4">Identifier: </div>
        <div class="col-8">{{entity?.identifier}}</div>
      </div>

      <div class="row mb-2">
        <div class="col-4">Version: </div>
        <div *ngIf="!options?.edit" class="col-8"><b>{{entity?.firmwareVersion}}</b></div>
        <div *ngIf="options?.edit" class="col-8"><input type="text" [(ngModel)]="entity.firmwareVersion" (ngModelChange)="updateDefinition($event)" size="8" /></div>
      </div>


      <div class="row">
        <div class="col-4">Series: </div>
        <div *ngIf="!options?.edit" class="col-8"><b>{{entity?.series}}</b></div>
        <div *ngIf="options?.edit" class="col-8"><input type="text" [(ngModel)]="entity.series" (ngModelChange)="updateDefinition($event)" size="8" /></div>
      </div>

      <div class="row mb-3">
        <div class="col-4">Manufacturer: </div>
        <div class="col-8" *ngIf="!options?.edit">

          <span *ngIf="entity?.manufacturerId == 14"> Sino (14)</span>
          <span *ngIf="entity?.manufacturerId == 23"> Fos (23)</span>
          <span *ngIf="entity?.manufacturerId != 23 && entity?.manufacturerId != 14 "> Unknown ({{entity?.manufacturerId}})</span>

        </div>


        <div *ngIf="options?.edit" class="col-8">

          <ngx-select-dropdown *ngIf="entity?.manufacturerId"
            [(ngModel)]="manufacturerSelectModel"
            (ngModelChange)="updateManufacturer($event)"
            [options]="manufacturerSelectOptions"
          ></ngx-select-dropdown>

        </div>

      </div>



      <div class="row">
        <div class="col-12">
          <div class="card mt-1">
            <div class="card-header bg-dark">Files</div>
            <div class="card-body m-0 p-0">


              <div class="row m-1">
                <div class="col-4">Checksum: </div>
                <div *ngIf="!options?.edit" class="col-8"><b>{{entity?.checksum}}</b></div>
                <div *ngIf="options?.edit" class="col-8"><input type="text" [(ngModel)]="entity.checksum" (ngModelChange)="updateDefinition($event)" size="8" /></div>
              </div>

              <div class="row m-1">
                <div class="col-4">Size: </div>
                <div *ngIf="!options?.edit" class="col-8"><b>{{entity?.size}}</b></div>
                <div *ngIf="options?.edit" class="col-8"><input type="text" [(ngModel)]="entity.size" (ngModelChange)="updateDefinition($event)" size="8" /></div>
              </div>

              <div class="div-table-tr-alt">
                <div class="row">
                  <div class="col-12 text-center">
                    File A (User 1)
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 text-center">
                    <div *ngIf="!options?.edit" ><b>{{entity?.fileA}}</b></div>
                    <div *ngIf="options?.edit" ><input type="text" [(ngModel)]="entity.fileA" (ngModelChange)="updateDefinition($event)" size="128" /></div>
                  </div>
                </div>

              </div>
              <div class="div-table-tr">

                <div class="row">
                  <div class="col-12 text-center">
                    File B (User 2)
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 text-center">
                    <div *ngIf="!options?.edit" ><b>{{entity?.fileB}}</b></div>
                    <div *ngIf="options?.edit" ><input type="text" [(ngModel)]="entity.fileB" (ngModelChange)="updateDefinition($event)" size="128" /></div>
                  </div>
                </div>
              </div>





            </div>


          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">Release Notes</div>
            <div class="card-body m-0 p-0">
              <div class="row">
                <div class="col-12">

                  <div *ngIf="!options?.edit" ><b>{{entity?.notes}}</b></div>
                  <div *ngIf="options?.edit" ><textarea [(ngModel)]="entity.notes" (ngModelChange)="updateDefinition($event)"  rows="8" cols="90"></textarea></div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-12">
          <embed-device-attributes [entity]="entity" [layout]="'card'" [options]="options?.attributes || {title: 'Attributes', edit: options?.edit}"></embed-device-attributes>
        </div>
      </div>

      <div class="row" *ngIf="entity?.identifier">
        <div class="col-12">
          <entity-notes-v2
            [title] = "'Firmware Admin Notes'"
            [entity]="entity.sref()"
            [options]="{'expand': true, 'dragula': false}"
            [placeholder] = "'Firmware Staff Note.'"
          ></entity-notes-v2>
        </div>
      </div>




    </ng-template>


  