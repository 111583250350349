
    <ng-template #deviceModal>
      <modal-widget [entity]="currentModal" [layout]="'shadowbox'" [options]="{}"></modal-widget>
    </ng-template>

    <div class="device-set-logical-widget" *ngIf="!entity">
      <widget-spinner></widget-spinner>
    </div>
    <ng-container *ngIf="entity">
      <ng-container [ngSwitch]="layout">


        <!-- =========================================== -->
        <!-- Table                                       -->
        <!-- =========================================== -->
        <div class="device-set-logical-widget" *ngSwitchCase="'table'">
          <div *ngIf="!entity.set || entity.set.length == 0">
            <div class="alert alert-warning mb-0">
              <p>{{no_results_msg()}}</p>
            </div>
          </div>
          <div *ngIf="entity.set && entity.set.length > 0">
              <div class="row shadow div-table-border div-table div-table-classic div-table-bg m-1">
                <div class="col">
                  <device-entity-widget *ngFor="let device of entity.set; let i = index"
                                 (widgetEvent)="handleEvent($event)"
                                 [entity]="device"
                                 [options]="{'index': i, 'header': options['header'], 'columns': options['columns'], 'allowed-actions': options['allowed-actions']}"
                                 [layout]="'table'"  ></device-entity-widget>
                </div>
              </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
