import {HttpClient} from '@angular/common/http';
import { FirebaseAuthService }            from '../../noizu/services/firebase-auth.service';
import {WidgetEnum} from '../../enums';
import {ElixirRepo} from '../elixir.repo';
import {Inject, Injectable, OnInit} from '@angular/core';
import {SubscriptionDefinitionEntity} from '../../entities/subscription/definition.entity';
import {ElixirEntity, UserSubscriptionEntity} from '../../entities';

@Injectable()
export class SubscriptionDefinitionRepo extends ElixirRepo {
  public types: Map<string, SubscriptionDefinitionEntity> | null = null;
  private _select_map: any = null;
  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
    this.updateCache();
  }

  updateCache() {
    const url = `${this.apiBase()}/subscription-definitions`;
    return this._get(url, (record, resolve) => {
      this.types = new Map<string, SubscriptionDefinitionEntity>();
      for (let i = 0; i < record.length; i++) {
        let tmp = this.entity(record[i]);
        // @ts-ignore
        this.types.set(tmp.sref(), tmp);
      }
      this._select_map = null;
      resolve(true);
    }, {});
  }

  public get select_map() {
    if (!this._select_map && this.types) {
      let sm = [];
      this.types.forEach((value, key) => {
        sm.push({
          value: key,
          text: `${value.code} - ${value.name} (${value.identifier})`
        });
      });
      this._select_map = sm;
    }
    return this._select_map;
  }


  widget_type(): any {
    return WidgetEnum.EMBED_WIDGET__SUBSCRIPTION_DEFINITION_REPO;
  }

  entity(record = {}) : ElixirEntity {
    let e = new SubscriptionDefinitionEntity(this.client, this.auth, record);
    return e;
  }

  toJson(options = {}) {
    let records = [];
    if (this.types) {
      this.types.forEach((value, key) => {
        records.push(value.toJson(options));
      })
    }
    return {'type': 'SubscriptionDefinitionRepo', 'records': records};
  }


} // end Package
