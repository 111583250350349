import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { FirebaseAuthService }        from '../noizu/services/firebase-auth.service';
import {ElixirRepo} from './elixir.repo';
import {NoteEntity} from '../entities/note.entity';
import {MaintenanceEntity} from '../entities';

@Injectable()
export class AdminRepo extends ElixirRepo {

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  getMaintenanceMode() {
    return this._get(`${this.apiBase()}/admin-tools/maintenance-mode`, (record, resolve) => {
      let response: any = null;
      response = new MaintenanceEntity(record);
      resolve(response);
    }, {});
  }

}
