
import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from './lacrosse.entity';
import { FirebaseAuthService }            from '../noizu/services/firebase-auth.service';
import {ToolsRepo} from '../repos';
import * as crypto from 'crypto-js'

export class GatewayEntity extends LacrosseEntity {
  public _kind = "gateways";
  public _singular = "gateway";

  public series:string;
  public serial:string;
  public batch:number;
  public manufacturer:number; // TODO enum
  public factoryMode:number;
  public handle: string;
  public secret: string;

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    this.refresh(json);
  } // end constructor

  refresh(data) {
    let sr = super.refresh(data);
    this.series = data.series;
    this.serial =  data.serial;
    this.batch = data.batch;
    this.manufacturer = data.manufacturer;
    this.factoryMode = data.factoryMode;
    this.handle = data.handle;
    this.secret = data.secret;
    return sr;
  }


  calcHmac(type, url, body, secret) {
    let digestString = `[${type}] ${url}\n----- body -----\n${body}`;
    var hash = crypto.HmacSHA256(digestString.replace(/\\n/g, '\n'), secret);
    var hashInBase64 = crypto.enc.Base64.stringify(hash);
    console.log({type: type, url: url, secret: secret, digest: digestString, hmac: hashInBase64});
    return hashInBase64;
  }

  public getFosGeo(direct, secret = null) {
    secret = secret || this.secret;
    if (direct) {
      let url = `https://ingv2.lacrossetechnology.com/api/v1.2/gateways/geo/${this.serial}`;
      let hmac = this.calcHmac("GET", url, "", secret);
      let options = {'X-Hmac-Token': hmac}
      return this._get(url, (record, resolve) => { resolve( record );}, options)
    } else {
      return this.errorPromise('Admin Based Passthrough Pending, Please select direct and provide PSK');
    }
  }
  public getGeo(direct, secret = null) {
    secret = secret || this.secret;
    if (direct) {
      let url = `https://ingv2.lacrossetechnology.com/api/v1.1/forecast/id/${this.serial}`;
      let hmac = this.calcHmac("GET", url, "", secret);
      let options = {'X-Hmac-Token': hmac}
      return this._get(url, (record, resolve) => { resolve( record );}, options)
    } else {
      return this.errorPromise('Admin Based Passthrough Pending, Please select direct and provide PSK');
    }
  }


  public getNotification(direct, secret = null) {
    secret = secret || this.secret;
    if (direct) {
      let firmware = "WeatherStationV2.2.18"
      let url = `https://ingv2.lacrossetechnology.com/api/v1.1/gateways/notifications/${this.serial}/firmware/${firmware}`;
      let hmac = this.calcHmac("GET", url, "", secret);
      let options = {'X-Hmac-Token': hmac}
      return this._get(url, (record, resolve) => { resolve( record );}, options)
    } else {
      return this.errorPromise('Admin Based Passthrough Pending, Please select direct and provide PSK');
    }
  }

  public getAlarm(direct, secret = null) {
    secret = secret || this.secret;
    if (direct) {
      return this.errorPromise('Pending');
    } else {
      return this.errorPromise('Admin Based Passthrough Pending, Please select direct and provide PSK');
    }
  }



} // end Package
