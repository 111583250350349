import {Component, Input, Inject, Output, EventEmitter, OnInit} from '@angular/core';
import {LogicalDeviceWidgetEnum, LogicalExtendedDeviceWidgetEnum, LogicalWidgetEnum, WidgetEnum} from '../../../../enums/widget';
import {ExtendedDeviceChartEntity, ExtendedDeviceLogicalWidget, ExtendedDeviceReadingEntity} from '../../../../entities';
import {LogicalWidgetEventStruct} from '../../logical';
import {ValuesPipe} from 'ngx-pipes';

@Component({
  selector: 'extended-device-reading-logical-widget',
  template: `
    <ng-container [ngSwitch]="layout">


      <ng-container *ngSwitchCase="'status'">
        <div class="extended-device-reading-logical-widget device-status alert alert-success">
          <div class="row">
            <div class="col-11">
              <!-- <new-spinner *ngIf="device?.meta?.fetching_status || !device.extended.status"  [size]="'sm'"></new-spinner> -->
              <div *ngIf="!entity?.meta?.loading && !entity?.meta?.loading_failed">
                <div>Battery status/level: <span>{{entity.battery_status}}/{{entity.battery_level}}</span></div>
                <div>Last Update: {{entity.last_update| date:'medium'}}<span *ngIf="entity?.meta?.fall_back_mode">*</span></div>
              </div>
              <div *ngIf="entity?.meta?.loading_failed">
                <div>Status Unknown</div>
              </div>
            </div>
            <div class="col-1 justify-content-center align-self-center">
              <span *ngIf="entity?.meta?.loading" class="float-right"><new-spinner [size]="'sm'"  ></new-spinner></span>
              <i *ngIf="!entity?.meta?.loading" class="fa fa-lg fa-repeat float-right" (click)="refresh($event)"></i>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- ===================================== -->
      <!-- Default (Card)                        -->
      <!-- ===================================== -->
      <ng-container *ngSwitchDefault>
        <div class="extended-device-reading-logical-widget card">
          <div class="card-header">Readings
            <span *ngIf="entity?.meta?.loading" class="float-right"><new-spinner [size]="'sm'"  ></new-spinner></span>
            <i *ngIf="!entity?.meta?.loading" class="fa fa-lg fa-repeat float-right" (click)="refresh($event)"></i></div>
          <div class="card-body">





            <div class="row mb-4">
              <div class="col-12 col-md-6">
                <select class="form-control" name="aggregate" [(ngModel)]="time_period">
                  <option *ngFor="let entry of time_options" value="{{entry.value}}">
                    {{entry.text}}
                  </option>
                </select>
              </div>
              <div class="col-12 col-md-6 text-center" *ngIf="entity">
                <select class="form-control" name="aggregate" (ngModelChange)="changeTimeZone($event)" [(ngModel)]="entity.time_zone">
                  <option *ngFor="let entry of time_zone_options" value="{{entry.value}}">
                    {{entry.text}}
                  </option>
                </select>
              </div>
            </div>


            <ng-template #aggregatePeriod let-period>
              <div *ngIf="time_period != 'current' && time_period != 'unbound'" class="alert alert-success">
                <div class="row">
                  <div class="col-2">Readings</div>
                  <div class="col">
                    <span *ngIf="period.cover_start">{{period.cover_start| date: 'medium'}}</span> - <span *ngIf="period.cover_end">{{period.cover_end| date: 'medium'}}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-2">Block</div>
                  <div class="col">
                    <span *ngIf="period.block_start">{{period.block_start| date: 'medium'}}</span> - <span *ngIf="period.block_end">{{period.block_end| date: 'medium'}}</span>
                  </div>
                </div>
              </div>
            </ng-template>


            <ng-template #aggregateReadings let-period>
              <div class="row" *ngFor="let field of period.fields | values">
                <div class="col">
                  <div class="card mb-1 mt-0">
                    <div class="card-header"><b>{{field.name}}</b> ({{field.unit}}): <b>DataPoints</b> #{{field.data_points}}</div>
                    <div class="card-body m-0 p-1">
                      <ng-container *ngFor="let aggregate of field.aggregates | values">
                        <div class="row">
                          <div class="col">
                            <b>{{aggregate.aggregate}}</b>
                          </div>
                          <div class="col">
                            {{aggregate.value}}
                          </div>
                          <div class="col">
                            <span *ngIf="aggregate.time">{{aggregate.time | date: 'medium'}}</span>
                          </div>
                        </div>
                        <div class="row mb-0 mt-0" *ngIf="aggregate.has_attributes">
                          <div class="col"><b>attributes</b>: {{aggregate.attributes| json}}</div>
                        </div>
                        <hr *ngIf="aggregate.has_attributes" class="m-0 p-0" />
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>


            <div class="row">
            <tabset #aggregates class="col">
              <tab heading="Running Average" *ngIf="entity?.readings && entity?.readings[time_period] && entity?.readings[time_period]['head']">
                <div class="row">
                  <div class="col">
                    <ng-container *ngTemplateOutlet="aggregatePeriod; context: {$implicit: entity.readings[time_period]['head']}"></ng-container>
                    <ng-container *ngTemplateOutlet="aggregateReadings; context: {$implicit: entity.readings[time_period]['head']}"></ng-container>
                  </div>
                </div>
              </tab>
              <tab heading="Current Period" *ngIf="entity?.readings && entity?.readings[time_period] && entity?.readings[time_period]['pending']">
                <div class="row">
                  <div class="col">
                    <ng-container *ngTemplateOutlet="aggregatePeriod; context: {$implicit: entity.readings[time_period]['pending']}"></ng-container>
                    <ng-container *ngTemplateOutlet="aggregateReadings; context: {$implicit: entity.readings[time_period]['pending']}"></ng-container>
                  </div>
                </div>
              </tab>
              <tab heading="Previous Period" *ngIf="entity?.readings && entity?.readings[time_period] && entity?.readings[time_period]['trail']">
                <div class="row">
                  <div class="col">
                    <ng-container *ngTemplateOutlet="aggregatePeriod; context: {$implicit: entity.readings[time_period]['trail']}"></ng-container>
                    <ng-container *ngTemplateOutlet="aggregateReadings; context: {$implicit: entity.readings[time_period]['trail']}"></ng-container>
                  </div>
                </div>
              </tab>
            </tabset>
            </div>



          </div>
        </div>
      </ng-container>

    </ng-container>
  `
})
export class ExtendedDeviceReadingLogicalWidgetComponent implements OnInit {
  @Input() entity: ExtendedDeviceReadingEntity | null = null;
  @Input() templateHint: string = null;
  @Input() options: any = null;
  @Input() layout = 'standard';
  @Output() widgetEvent = new EventEmitter<LogicalWidgetEventStruct>();
  forwardEvent(e, widgetEvent: LogicalWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }
  public  LogicalExtendedDeviceWidgetEnum = LogicalExtendedDeviceWidgetEnum;

  public time_period = 'current';
  public time_options = [
    {value: 'current', text: 'Plot'},
    {value: 'ai.minutes.1', text: '1 Minute'},
    {value: 'ai.minutes.5', text: '5 Minute'},
    {value: 'ai.minutes.15', text: '15 Minute'},
    {value: 'ai.minutes.30', text: '30 Minute'},
    {value: 'ai.hours.1', text: '1 Hour'},
    {value: 'ai.days.1', text: '1 Day'},
    {value: 'ai.weeks.1', text: '1 Week'},
    {value: 'ai.months.1', text: '1 Month'},
    {value: 'ai.years.1', text: '1 Year'},
    {value: 'unbound', text: 'All Time'},
  ];

  public time_zone_options = [
    {value: 'America/Chicago', text: 'America/Chicago'},
    {value: 'Pacific/Honolulu', text: 'America/Honolulu'},
    {value: 'America/Anchorage', text: 'America/Anchorage'},
    {value: 'America/Los_Angeles', text: 'America/Los_Angeles'},
    {value: 'America/Denver', text: 'America/Denver'},
    {value: 'America/New_York', text: 'America/Chicago'},
    {value: 'Asia/Bangkok', text: 'Asia/Bangkok'},
    {value: 'Europe/Berlin', text: 'Europe/Berlin'},
    {value: 'Europe/Paris', text: 'Europe/Paris'},
    {value: 'Asia/Chongqing', text: 'Asia/Chongqing'},
  ]

  constructor() {

  }

  changeTimeZone(e) {
    this.entity.getPromise(e);
  }

  ngOnInit() {
    if (this.entity && this.layout == 'status') {
      this.entity.getPromise();
    }
  }

  refresh(e) {
    e.preventDefault();
    this.entity.getPromise('default', true);
  }

}
