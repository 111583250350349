import {Component, Input, Output, EventEmitter} from '@angular/core';
import {EntityLogEntity} from '../../../../entities/log/entity-log.entity';
import {WidgetBase} from '../../widget-base';
import {LogWidgetEventStruct} from '../log-widget-event.struct';
import {DeviceLogWidgetEnum, GatewayLogWidgetEnum, LogWidgetEnum} from '../../../../enums';
import {DeviceEntityLogEntity, GatewayEntityLogEntity} from '../../../../entities';

@Component({
  selector: 'device-log-details-widget',
  template: `
    <ng-container [ngSwitch]="entity.device_log_widget_type()">

      <ng-container *ngSwitchCase="DeviceLogWidgetEnum.DEVICE_LOG_WIDGET__STAGE_ONE">
        <device-stage-one-log-details-widget [entity]="entity" [options]="options" [layout]="layout"> </device-stage-one-log-details-widget>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <log-details-generic-widget [entity]="entity" [options]="options" [layout]="layout"> </log-details-generic-widget>
      </ng-container>

    </ng-container>
    `
})
export class DeviceLogDetailsWidgetComponent extends WidgetBase{
  @Input() entity: DeviceEntityLogEntity = null;
  @Input() options: any = {};
  @Input() layout: string = null;
  @Output() widgetEvent = new EventEmitter<LogWidgetEventStruct>();
  forwardEvent(e, widgetEvent: LogWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }
  public DeviceLogWidgetEnum = DeviceLogWidgetEnum;
  public expand = false;
  public expandRaw = false;

  constructor() {
    super();

  }
}
