
    <div class="card shadow" *ngIf="entity">
      <div class="card-header">
        <div class="row">
          <div class="col">
            User Settings
            <span *ngIf="options" class="float-right"><i (click)="toggleExpand()" class="fa fa-sm" [ngClass]="{'fa-chevron-down': !options.expand, 'fa-chevron-up': options.expand}"> </i></span>
          </div>
        </div>
      </div>
      <div class="card-body" *ngIf="entity && expandWidget()">
        <div class="card-block card-accent-dark">
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Value</th>
                <th scope="col">Is Null</th>
                <th scope="col">Modified On</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let set of mySettings">
                <td>{{set.setting}}</td>
                <td>{{set.value}}</td>
                <td>{{set.isNull}}</td>
                <td>{{set.modifiedOn}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  