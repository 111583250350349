import {Component, Input, Inject, Output, EventEmitter} from '@angular/core';
import {NoizuStruct} from '../noizu/structs/noizu-struct';
import {WidgetEnum} from '../enums/widget';
import {FirmwareWidgetEventStruct} from './widget/firmware/firmware-widget-event.struct';
import {WidgetEventStruct} from './widget-event.struct';

@Component({
  selector: 'widget',
  template: `
<div *ngIf="!entity">
  <new-spinner [size]="'sm'"></new-spinner>
</div>
<ng-container *ngIf="entity">
  <ng-container [ngSwitch]="entity.widget_type()">


    <batch-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__BATCH_ENTITY" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></batch-widget>
    <manufacturer-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__MANUFACTURER_ENTITY" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></manufacturer-widget>

    <!-- Alerts -->
    <alert-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__ALERT_ENTITY" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></alert-widget>
    <alert-facade-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__ALERT_FACADE_ENTITY" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></alert-facade-widget>
    <alert-trigger-state-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__ALERT_TRIGGER_STATE_OBJECT"  (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></alert-trigger-state-widget>
    <alert-status-facade-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__ALERT_STATUS_FACADE" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></alert-status-facade-widget>
    <alert-constraint-status-set-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__ALERT_CONSTRAINT_STATUS_SET" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></alert-constraint-status-set-widget>
    <alert-constraint-status-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__ALERT_CONSTRAINT_STATUS" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></alert-constraint-status-widget>
    <alert-trigger-event-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__ALERT_TRIGGER_EVENT" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></alert-trigger-event-widget>


    <!-- Firmware -->
    <firmware-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__FIRMWARE"  (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></firmware-widget>

    <!-- Callback -->
    <callback-object-set-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__CALLBACK_OBJECT_SET" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></callback-object-set-widget>
    <callback-object-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__CALLBACK_OBJECT" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></callback-object-widget>

    <!-- Subkects -->
    <subscriber-object-set-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__SUBSCRIBER_OBJECT_SET" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></subscriber-object-set-widget>
    <subscriber-object-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__SUBSCRIBER_OBJECT" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></subscriber-object-widget>

    <!-- Subscriptions -->
    <subscription-definition-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__SUBSCRIPTION_DEFINITION_ENTITY" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></subscription-definition-widget>
    <subscription-definitions-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__SUBSCRIPTION_DEFINITION_REPO" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></subscription-definitions-widget>

    <!-- Runtime-->
    <runtime-config-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__RUNTIME_CONFIGURATION_OBJECT" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></runtime-config-widget>

    <!-- Logs -->
    <log-set-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__LOG_SET_ENTITY" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></log-set-widget>
    <log-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__LOG_ENTITY"  (widgetEvent)="forwardEvent($event)"[entity]="entity" [layout]="layout" [options]="options"></log-widget>

    <!-- Notification Settings -->
    <notification-settings-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__NOTIFICATION_SETTINGS_ENTITY" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></notification-settings-widget>


    <!-- Sets -->
    <set-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__GENERIC_SET" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></set-widget>
    <appengine-set-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__APPENGINE_SET" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></appengine-set-widget>
    <generic-set-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__ELIXIR_SET" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></generic-set-widget>

    <!-- User Widget -->
    <user-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__USER_ENTITY" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></user-widget>
    <user-channel-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__COMMUNICATION_CHANNEL_ENTITY" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></user-channel-widget>
    <user-extended-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__USER_EXTENDED" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></user-extended-widget>

    <!-- Permission Widget -->
    <permission-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__PERMISSION_ENTITY" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></permission-widget>


    <!-- Device Type -->
    <!-- TODO: Move to new system -->
    <device-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__DEVICE" (widgetEvent)="forwardEvent($event)"   [entity]="entity" [layout]="layout" [options]="options"></device-widget>
    <device-type-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__DEVICE_TYPE"  (widgetEvent)="forwardEvent($event)"  [entity]="entity" [layout]="layout" [options]="options"></device-type-widget>
    <device-field-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__DEVICE_FIELD" (widgetEvent)="forwardEvent($event)"   [entity]="entity" [layout]="layout" [options]="options"></device-field-widget>
    <device-entity-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__DEVICE_ENTITY" (widgetEvent)="forwardEvent($event)"   [entity]="entity" [layout]="layout" [options]="options"></device-entity-widget>
    <device-link-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__DEVICE_LINK" (widgetEvent)="forwardEvent($event)"   [entity]="entity" [layout]="layout" [options]="options"></device-link-widget>

    <!-- Device range -->
    <device-range-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__DEVICE_RANGE" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></device-range-widget>

    <!-- Logical Widget -->
    <logical-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__LOGICAL" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></logical-widget>

    <!-- MODAL -->
    <modal-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__MODAL_WIDGET" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></modal-widget>


    <!-- Pin and PinGroup -->
    <pin-group-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__PIN_GROUP" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></pin-group-widget>
    <pin-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__PIN" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></pin-widget>

    <!-- Report -->
    <report-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__REPORT" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></report-widget>

    <!-- Email Related -->
    <email-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__EMAIL" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></email-widget>

    <!-- Notes -->
    <note-widget *ngSwitchCase="WidgetEnum.EMBED_WIDGET__NOTE_ENTITY" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></note-widget>

    <!-- Default -->
    <div *ngSwitchDefault>
        <div [ngSwitch]="layout">
          <div *ngSwitchCase="'shadowbox'">
            <div class="widget row mb-5">
              <div class="col text-center border-bottom">
                <span class="display-4">{{ WidgetEnum[entity.widget_type()] }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <pre>{{entity.toJson()| json}}</pre>
              </div>
            </div>
          </div>
          <div *ngSwitchDefault>
            <div class="widget card" [ngClass]="{'mb-0': options?.nested}">
              <div class="card-header" [ngClass]="{'p-2 m-0': options?.nested}"> {{ WidgetEnum[entity.widget_type()] }}</div>
              <div class="card-body">
                <pre>{{entity.toJson()| json}}</pre>
              </div>
            </div>
          </div>
        </div>



    </div>

  </ng-container>
</ng-container>
`
})
export class WidgetComponent {
  @Input() entity: NoizuStruct | null = null;
  @Input() templateHint: string = null;
  @Input() options: any = null;
  @Input() layout = 'standard';

  @Output() widgetEvent = new EventEmitter<WidgetEventStruct>();
  forwardEvent(widgetEvent: WidgetEventStruct) {
    this.widgetEvent.emit(widgetEvent);
  }


  public  WidgetEnum = WidgetEnum;

  constructor() {

  }
}
