
<div *ngIf="!entity">
  <div class="row"><div class="col">
    <new-spinner [size]="'sm'"></new-spinner>
  </div></div>
</div>
<div *ngIf="entity">
  <div *ngIf="options?.edit">
    <div *ngIf="valueSubscriber | async as values; else loading">
      <ng-select
        placeholder="Select Device Type"
        [items]="values"
        bindLabel="label"
        bindValue="value"
        [multiple]="false"
        [(ngModel)]="entity.selected"
        (ngModelChange)="updateOutput()"
      ></ng-select>
    </div>

    <ng-template #loading>
      <div class="row"><div class="col">
        <new-spinner [size]="'sm'"></new-spinner>
      </div></div>
    </ng-template>

  </div>
  <div *ngIf="!options?.edit">
    {{entity.selected | json}}
  </div>
</div>
