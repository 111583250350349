import {
  LogicalDeviceWidgetEnum,
} from "../../enums/widget";
import {LogicalWidget} from '../logical-widget';
import {DeviceEntity} from '../device.entity';
import {DeviceLogicalWidget} from './logical-widget';
import {DeviceDefinitionRepo} from '../../repos/device';
import {DeviceRepo} from '../../repos';

export class SimulatorWidget extends DeviceLogicalWidget{

  constructor(device: DeviceEntity) {
    super(LogicalDeviceWidgetEnum.LOGICAL_DEVICE_WIDGET__SIMULATOR);
    this.subject = device;
  } // end constructor

} // end Package
