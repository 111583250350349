/*
function _units(UnitTypes) {
  return {
    none: UnitTypes.none,
    // Units of Distance
    millimeters: UnitTypes.distance + UnitTypes.metric + 1, centimeters: UnitTypes.distance + UnitTypes.metric + 2, meters: UnitTypes.distance + UnitTypes.metric + 3, kilometers: UnitTypes.distance + UnitTypes.metric + 4,
    inches: UnitTypes.distance + 5, miles: UnitTypes.distance + 6,
    steps: UnitTypes.distance + 7,

    // Units of Velocity
    metersPerSecond: UnitTypes.velocity + UnitTypes.metric + 1, kilometersPerHour: UnitTypes.velocity + UnitTypes.metric + 2,
    feetPerSecond: UnitTypes.velocity + 3, milesPerHour: UnitTypes.velocity + 4, knots: UnitTypes.velocity + 5,

    // Units of Time
    seconds: UnitTypes.utime + UnitTypes.metric + 1, minutes: UnitTypes.utime + UnitTypes.metric + 2, hours: UnitTypes.utime + UnitTypes.metric + 3, days: UnitTypes.utime + UnitTypes.metric + 4,

    // Units of Temperature
    degreesCelsius: UnitTypes.temperature + UnitTypes.metric + 1, degreesFahrenheit: UnitTypes.temperature + 2,

    // Units of Angular Measure
    radians: UnitTypes.angularMeasure + UnitTypes.metric + 1, degrees: UnitTypes.angularMeasure + 2,

    // Units of Humidity
    relativeHumidity: UnitTypes.humidity + UnitTypes.metric + 1}
}
*/

import { MetricUnit, ImperialUnit, UnitTypeEnum } from './unit-type.enum';

export enum UnitEnum {
  None = 0 + UnitTypeEnum.Scalar,

  // Units of Distance
  Millimeters = MetricUnit + UnitTypeEnum.UnitOfDistance + 1,
  Centimeters = MetricUnit + UnitTypeEnum.UnitOfDistance + 2,
  Meters = MetricUnit + UnitTypeEnum.UnitOfDistance + 3,
  Kilometers = MetricUnit + UnitTypeEnum.UnitOfDistance + 4,
  Inches = ImperialUnit + UnitTypeEnum.UnitOfDistance + 5,
  Miles = ImperialUnit + UnitTypeEnum.UnitOfDistance + 6,
  Steps = ImperialUnit + UnitTypeEnum.UnitOfDistance + 7,

  // Units of Velocity
  MetersPerSecond = MetricUnit + UnitTypeEnum.UnitOfVelocity + 1,
  KilometersPerHour = MetricUnit + UnitTypeEnum.UnitOfVelocity + 2,
  FeetPerSecond = ImperialUnit + UnitTypeEnum.UnitOfVelocity + 3,
  MilesPerHour = ImperialUnit + UnitTypeEnum.UnitOfVelocity + 4,
  Knots = ImperialUnit + UnitTypeEnum.UnitOfVelocity + 5,

  // Units of Time
  Seconds = MetricUnit + UnitTypeEnum.UnitOfTime + 1,
  Minutes = MetricUnit + UnitTypeEnum.UnitOfTime + 2,
  Hours = MetricUnit + UnitTypeEnum.UnitOfTime + 3,
  Days = MetricUnit + UnitTypeEnum.UnitOfTime + 4,

  // Units of Temperature
  DegreesCelsius =  MetricUnit + UnitTypeEnum.UnitOfTemperature + 1,
  DegreesFahrenheit = ImperialUnit + UnitTypeEnum.UnitOfTemperature + 2,

  // Units of Angular Measure
  Radians = MetricUnit + UnitTypeEnum.UnitOfAngularMeasure + 1,
  Degrees = ImperialUnit + UnitTypeEnum.UnitOfAngularMeasure + 2,

  // Units of Humidity
  RelativeHumidity =  MetricUnit + UnitTypeEnum.UnitOfHumidity + 1,

  Millibarrs = 32897
}
