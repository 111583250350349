import { NoizuStruct }        from '../../noizu/structs/noizu-struct';
import { MetricUnit, ImperialUnit, UnitTypeEnum }      from '../../enums/unit-type.enum';
import { UnitEnum }      from '../../enums/unit.enum';

export class ValueAndUnitUnit extends NoizuStruct {
  public metric: boolean;
  public name: string;
  public enum: number;
  public type: UnitTypeEnum;
  public type_name: string;

  constructor(json: any) {
      super();


      if (typeof json == 'string') {
        this.name = UnitEnum[json];
        this.enum = UnitEnum[this.name];
      } else if (json['enum']) {
        this.enum = json['enum'];
        this.name = UnitEnum[this.enum];
      } else {
        this.name = UnitEnum[json['unit']];
        this.enum = UnitEnum[this.name];
      }


      this.metric = (this.enum & MetricUnit) == MetricUnit;

      this.type = (this.enum & (
        UnitTypeEnum.Scalar |
        UnitTypeEnum.UnitOfDistance |
        UnitTypeEnum.UnitOfVelocity |
        UnitTypeEnum.UnitOfTime |
        UnitTypeEnum.UnitOfTemperature |
        UnitTypeEnum.UnitOfAngularMeasure |
        UnitTypeEnum.UnitOfHumidity
      ));
      this.type_name = UnitTypeEnum[this.type];
  } // end constructor

  toJson(options = {}) {
    return {name: this.name, enum: this.enum};
  }

}
