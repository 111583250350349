
    <div *ngIf="entity">
      <ng-template #approvalDetails>
        <div class="row">
          <div class="col text-left">{{entity.identifier}}</div>
          <div class="col" *ngIf="!entity?.validation?.valid && entity?.validation?.messages['common']">
            <div class="alert alert-warning">{{entity?.validation?.messages['common']}}</div>
          </div>
          <div class="col" *ngIf="!entity?.validation?.valid && entity?.validation?.messages['reference']">
            <div class="alert danger">{{entity?.validation?.messages['reference']}}</div>
          </div>
          <div class="col text-left">{{entity.reference}}</div>
          <div class="col" *ngIf="!entity?.validation?.valid && entity?.validation?.messages['comment']">
            <div class="alert danger">{{entity?.validation?.messages['comment']}}</div>
          </div>
          <div class="row">
            <div class="col-2 text-right"><i class="fa fa-exclamation text-danger" *ngIf="entity?.validation?.messages['comment']"></i></div>
            <div class="col text-left">
              <ng-container *ngIf="options?.create_approval">
                <inline-editor type="text" [(ngModel)]="entity.comment" size="64" ></inline-editor>
              </ng-container>
              <ng-container *ngIf="!options?.create_approval">
                {{entity.comment}}
              </ng-container>
            </div>
          </div>
          <div class="col" *ngIf="!entity?.validation?.valid && entity?.validation?.messages['approver']">
            <div class="alert danger">{{entity?.validation?.messages['approver']}}</div>
          </div>
          <div class="col text-left">{{entity.approver}}</div>
          <div class="col text-left">{{entity.approved_on || '(null)'}}</div>
        </div>
      </ng-template>
      <div [ngSwitch]="layout">
        <div *ngSwitchCase="'thumbnail'">
          <ng-container *ngTemplateOutlet="approvalDetails"></ng-container>
        </div>
        <div *ngSwitchCase="'sidebar'">
          <ng-container *ngTemplateOutlet="approvalDetails"></ng-container>
        </div>
        <div *ngSwitchCase="'shadowbox'">
          <ng-container *ngTemplateOutlet="approvalDetails"></ng-container>
        </div>
        <div *ngSwitchDefault>
          <div class="card p-1 mb-1 rounded">
            <div class="card-body">
              <ng-container *ngTemplateOutlet="approvalDetails"></ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  