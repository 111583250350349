
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { FirebaseAuthService }        from '../../noizu/services/firebase-auth.service';
import { AppengineRepo }      from '../appengine.repo';
import {UserSettingsEntity} from '../../entities/user/settings.entity';

@Injectable()
export class UserSettingsRepo extends AppengineRepo {

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }


  entity(record) {
      return new UserSettingsEntity(this.client, this.auth, record)
  }

  getUserListPromise(user: string, options = {}) {
    const url = `${this.apiBase()}/user/${user}/settings`;
    return this._getEntityPromise(url, (record, resolve) => {resolve(this.entity(record)); }, options);
  }
}
