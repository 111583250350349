import {Component, NgZone} from '@angular/core';
import {FirmwareReleaseRepo} from '../../../repos/firmware/release.repo';
import {CacheService} from '../../../services/cache.service';
import {FirmwareApprovalEntity, FirmwareDefinitionEntity, FirmwareReleaseEntity, ModalWidget} from '../../../entities';
import {FirmwareWidgetEventStruct, WidgetEventStruct} from '../../../widgets';
import {FirmwareWidgetEnum, ModalWidgetEnum} from '../../../enums';
import {FirmwareApprovalRepo, FirmwareDefinitionRepo, FirmwareLinkV2Repo} from '../../../repos';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap/modal';
import {Location} from '@angular/common';

@Component({
  templateUrl: './list.component.html',
})
export class FirmwareReleaseListComponent {
  public entries: any; //Map<String, Array<FirmwareDefinitionEntity>>;
  public selectedEntity: FirmwareReleaseEntity = null;
  public currentModal: any = null;
  public modalRef: BsModalRef;

  public firmware_cache: any = {};
  public approval_cache: any = {};
  public link_cache: any = {};

  constructor(public cache: CacheService, public repo: FirmwareReleaseRepo, public firmwareDefinitionRepo: FirmwareDefinitionRepo, public approvalRepo: FirmwareApprovalRepo, public firmwareLinkRepo: FirmwareLinkV2Repo, public zone: NgZone, public modalService: BsModalService) {
    this.zone.run(
      () => {
        this.repo.getListPromise().then((u: any) => {
          this.entries = u.sort((a,b) => {
            return a.identifier - b.identifier
          })
        });
      }
    )
  }


  eventHandler(event: WidgetEventStruct, modal) {
    if (event instanceof FirmwareWidgetEventStruct) {
      if (event.event_firmware_widget_type == FirmwareWidgetEnum.FIRMWARE_WIDGET__RELEASE) {
        switch(event.event_type) {
          case 'open_sidebar':
            this.selectedEntity = event.event_body['entity'];
            break;
          case 'close_sidebar':
            this.selectedEntity = null;
            break;

          case 'edit_release':
            this.editRelease(event.event_body['entity'], modal);
            break;

          case 'firmware_modal':
            this.showFirmware(event.event_body['sref'].toString(), modal)
            break;
          case 'rollback_modal':
            console.log(event)
            this.showRollback(event.event_body['sref'].toString(), modal)
            break;
          case 'approval_modal':
            this.showApproval(event.event_body['sref'].toString(), modal)
            break;
          case 'link_modal':
            this.showLink(event.event_body['sref'].toString(), modal)
            break;


          default:
            console.log('unhandled event', event)
        }
        return;
      }
    }
    console.log('unhandled event', event)
  }



  showApproval(approval, modal) {
    if (this.approval_cache[approval]) {
      let entity = this.approval_cache[approval]
      let dialog = new ModalWidget(`Firmware Approval - ${approval}| ${entity.comment}`, ModalWidgetEnum.MODAL_WIDGET__SHOW, entity, {edit: false}, 'shadowbox');
      this.showModal(dialog, modal, true)
    } else {
      this.approvalRepo.getEntityPromise(approval).then((entity: FirmwareApprovalEntity) => {
        this.approval_cache[approval] = entity;
        let dialog = new ModalWidget(`Firmware Approval - ${approval}| ${entity.comment}`, ModalWidgetEnum.MODAL_WIDGET__SHOW, entity, {edit: false}, 'shadowbox');
        this.showModal(dialog, modal, true, 'lg-modal')
      })
    }
  }


  showRollback(firmware, modal) {
    if (this.firmware_cache[firmware]) {
      let entity = this.firmware_cache[firmware]
      let dialog = new ModalWidget(`Rollback Firmware: - ${entity.firmwareVersion}@${entity.series}`, ModalWidgetEnum.MODAL_WIDGET__SHOW, entity, {edit: false}, 'shadowbox');
      this.showModal(dialog, modal, true)
    } else {
      let identifier = this.firmwareDefinitionRepo.srefToIdentifier('firmware', firmware, false)
      this.firmwareDefinitionRepo.getEntityPromise(identifier).then((entity: FirmwareDefinitionEntity) => {
        this.firmware_cache[firmware] = entity;
        let dialog = new ModalWidget(`Rollback Firmware: - ${entity.firmwareVersion}@${entity.series}`, ModalWidgetEnum.MODAL_WIDGET__SHOW, entity, {edit: false}, 'shadowbox');
        this.showModal(dialog, modal, true)
      })
    }
  }

  showLink(link, modal) {
    if (this.link_cache[link]) {
      let entity = this.link_cache[link]
      let dialog = new ModalWidget(`Firmware Association: ${link}`, ModalWidgetEnum.MODAL_WIDGET__SHOW, entity, {edit: false}, 'shadowbox');
      this.showModal(dialog, modal, true)
    } else {
      let identifier = this.firmwareLinkRepo.srefToIdentifier('fw-link-v2', link, false)
      this.firmwareLinkRepo.getEntityPromise(identifier).then((entity) => {
        this.link_cache[link] = entity;
        let dialog = new ModalWidget(`Firmware Association: ${link}`, ModalWidgetEnum.MODAL_WIDGET__SHOW, entity, {edit: false}, 'shadowbox');
        this.showModal(dialog, modal, true)
      })
    }
  }

  showFirmware(firmware, modal) {
    if (this.firmware_cache[firmware]) {
      let entity = this.firmware_cache[firmware]
      let dialog = new ModalWidget(`Target Firmware: - ${entity.firmwareVersion}@${entity.series}`, ModalWidgetEnum.MODAL_WIDGET__SHOW, entity, {edit: false}, 'shadowbox');
      this.showModal(dialog, modal, true)
    } else {
      let identifier = this.firmwareDefinitionRepo.srefToIdentifier('firmware', firmware, false)
      this.firmwareDefinitionRepo.getEntityPromise(identifier).then((entity: FirmwareDefinitionEntity) => {
        this.firmware_cache[firmware] = entity;
        let dialog = new ModalWidget(`Target Firmware: - ${entity.firmwareVersion}@${entity.series}`, ModalWidgetEnum.MODAL_WIDGET__SHOW, entity, {edit: false}, 'shadowbox');
        this.showModal(dialog, modal, true)
      })
    }
  }

  editRelease(entity, modal) {
    let dialog = new ModalWidget(`Update Release - ${entity.identifier}`, ModalWidgetEnum.MODAL_WIDGET__UPDATE, entity, {edit: true}, 'shadowbox');
    this.showModal(dialog, modal, false, 'xl-modal')
  }

  createManager(e, modal) {
    e.preventDefault();
    let entity = this.repo.entity({identifier: 'new'});
    let dialog = new ModalWidget("Create New Release", ModalWidgetEnum.MODAL_WIDGET__CREATE, entity, {edit: true}, 'shadowbox');
    this.showModal(dialog, modal, false, 'lg-modal')
  }

  showModal(current, modal, clickOut = false, customClass="") {
    this.currentModal = current;
    if (!clickOut) {
      let config: ModalOptions = {
        backdrop : 'static',
        keyboard : false,
        class: customClass
      };
      this.currentModal.modalRef = this.modalService.show(modal, config);
    } else {
      let config: ModalOptions = {
        class: customClass
      };
      this.currentModal.modalRef = this.modalService.show(modal, config);
    }
  }



}
