
import { Component, Inject, Input, NgZone } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location }               from '@angular/common';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { SubscriptionsEntity } from '../../entities/subscriptions.entity';
import { SubscriptionsRepo } from '../../repos/data/subscriptions.repo';

@Component({
  templateUrl: './subscriptions.component.html',
})
export class SubscriptionsComponent {
  public entries: Array<SubscriptionsEntity>;

  constructor(public repo: SubscriptionsRepo, public zone: NgZone) {
    this.zone.run(
      () => {
        this.repo.getListPromise().then((u: any) => {this.entries = u;});
      }
    )
  }

  add(e) {
    e.preventDefault();
    this.entries["items"].push(this.repo.entity({id:'new', new:true}));
  }
}
