import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToasterService} from 'angular2-toaster';
import {AlertProbeFacadeEntity} from '../../../../entities/alert';
import {AlertFormatEnum} from '../../../../enums';
import {AlertFacadeDefaultWidgetComponent} from './default.widget.component';
import {AlertFacadeWidgetEventStruct} from './alert-facade-widget-event.struct';

@Component({
  selector: 'alert-facade-probe-widget',
  template: `
    <div class="card card-accent-dark alert-facade-probe-widget">
      <div class="card-header">Probe Alert</div>
      <div class="card-body">
        <div class="row"><div class="col-4">Device</div><div class="col-8"><a [routerLink]="'/portal/devices/show/' + entity.device">{{entity.device}}</a></div></div>
        <div class="row"><div class="col-4">Association</div><div class="col-8">{{entity.deviceAssociation}}</div></div>
        <div class="row"><div class="col-4">Field</div><div class="col-8">{{entity.field}}</div></div>
        <div class="row"><div class="col-12"><hr/></div></div>
        <div class="row">
          <div [ngSwitch]="temperatureFormat">
            <div *ngSwitchCase="FormatEnum.ValueAbove" class="row">
              <div class="col-5"><b>Temperature Above</b></div>
              <div *ngIf="!options?.edit" class="col-7"><b>{{entity.temperatureAbove.value}}</b> <span> {{entity.temperatureAbove.unit?.name}}</span></div>
              <div *ngIf="options?.edit" class="col-7">
                <input
                  type="number"
                  [(ngModel)]="entity.temperatureAbove.value"
                  (ngModelChange)="facadeChange($event)"
                  name="temp_above"
                />
                <span> {{entity.temperatureAbove.unit?.name}}</span>
              </div>


            </div>
            <div *ngSwitchCase="FormatEnum.ValueBelow" class="row">
              <div class="col-5"><b>Temperature Below</b></div>
              <div *ngIf="!options?.edit" class="col-7"><b>{{entity.temperatureBelow.value}}</b> <span> {{entity.temperatureBelow.unit?.name}}</span></div>
              <div *ngIf="options?.edit" class="col-7">
                <input
                  type="number"
                  [(ngModel)]="entity.temperatureBelow.value"
                  (ngModelChange)="facadeChange($event)"
                  name="temp_above"
                />
                <span> {{entity.temperatureBelow.unit?.name}}</span>
              </div>
            </div>

            <div *ngSwitchCase="FormatEnum.ValueBetween" class="row">
              <div class="col-5"><b>Temperature Between</b></div>
              <div *ngIf="!options?.edit" class="col-7"><b>{{entity.temperatureAbove.value}}</b> <span> {{entity.temperatureAbove.unit?.name}}</span> and <b>{{entity.temperatureBelow.value}}</b> <span> {{entity.temperatureBelow.unit?.name}}</span></div>
              <div *ngIf="options?.edit" class="col-7">
                <input
                  type="number"
                  [(ngModel)]="entity.temperatureAbove.value"
                  (ngModelChange)="facadeChange($event)"
                  name="temp_above"
                />
                <span> {{entity.temperatureAbove.unit?.name}}</span>
                and
                <input
                  type="number"
                  [(ngModel)]="entity.temperatureBelow.value"
                  (ngModelChange)="facadeChange($event)"
                  name="temp_above"
                />
                <span> {{entity.temperatureBelow.unit?.name}}</span>
              </div>
            </div>

            <div *ngSwitchCase="FormatEnum.ValueOutside" class="row">
              <div class="col-5"><b>Temperature Outside</b></div>
              <div *ngIf="!options?.edit" class="col-7"><b>{{entity.temperatureBelow.value}}</b> <span> {{entity.temperatureBelow.unit?.name}}</span> and <b>{{entity.temperatureAbove.value}}</b> <span> {{entity.temperatureAbove.unit?.name}}</span></div>
              <div *ngIf="options?.edit" class="col-7">
                <input
                  type="number"
                  [(ngModel)]="entity.temperatureBelow.value"
                  (ngModelChange)="facadeChange($event)"
                  name="temp_above"
                />
                <span> {{entity.temperatureBelow.unit?.name}}</span>
                and
                <input
                  type="number"
                  [(ngModel)]="entity.temperatureAbove.value"
                  (ngModelChange)="facadeChange($event)"
                  name="temp_above"
                />
                <span> {{entity.temperatureAbove.unit?.name}}</span>
              </div>
            </div>
            <div *ngSwitchDefault></div>
          </div>
        </div>
      </div>
    </div>
    `
})
export class EmbedAlertProbeFacadeComponent extends AlertFacadeDefaultWidgetComponent implements OnInit{
  public editMode = false;
  @Input() layout: string = null;
  @Input() entity: AlertProbeFacadeEntity;
  @Input() options: any;
  @Output() widgetEvent = new EventEmitter<AlertFacadeWidgetEventStruct>();
  forwardEvent(e, widgetEvent: AlertFacadeWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }
  temperatureFormat : AlertFormatEnum = AlertFormatEnum.Unknown;
  FormatEnum = AlertFormatEnum;

  constructor(public toasterService: ToasterService) {
    super();
  }

  ngOnInit() {
    this.setFormat();
  }

  setFormat() {
    if (this.entity.temperatureAbove && this.entity.temperatureBelow) {
      if (this.entity.temperatureAbove.value < this.entity.temperatureBelow.value) {
        this.temperatureFormat = AlertFormatEnum.ValueBetween;
      } else {
        this.temperatureFormat = AlertFormatEnum.ValueOutside;
      }
    } else if (this.entity.temperatureAbove) {
      this.temperatureFormat = AlertFormatEnum.ValueAbove;
    } else if (this.entity.temperatureBelow) {
      this.temperatureFormat = AlertFormatEnum.ValueBelow;
    }
  }

}
