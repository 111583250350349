import {NoizuStruct} from '../../noizu/structs/noizu-struct';

export class DataStreamMediaCardEntity extends NoizuStruct {
  public _kind = 'GoldenRatio.DataStream.Card.MediaCard.V1_1';
  public messages = {one: null, two: null};
  public readings = {one: null, two: null};
  public modified = false;

  constructor(json) {
    super();
    if (json['messages']) {
      this.messages.one = json['messages']['one'];
      this.messages.two = json['messages']['two'];
    }
    if (json['readings']) {
      // @todo add support for non integer/float readings. (ValueAndUnit, and actual links.)
      this.readings.one = json['readings']['one'];
      this.readings.two = json['readings']['two'];
    }
  } // end constructor

  toJson(options: any = {}) {
    return {
      messages: this.messages,
      readings: this.readings,
    }
  }

  validate() {
    return [];
  }


  widget() {
    return 'embed-ds-media-card';
  }
} // End Package
