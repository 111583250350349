
import {map} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';

import { Observable} from 'rxjs';

import {Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {FirebaseAuthService} from '../noizu/services/firebase-auth.service';
import {environment} from '../../environments/environment';
import {UserEntity} from '../entities/user.entity';
import {UserRepo} from '../repos/user.repo';
import {basicNav, fullNav} from '../_nav';
import {HttpClient} from '@angular/common/http';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFireDatabase} from '@angular/fire/database';
@Injectable()
export class AuthService {
  public afAuth;
  public redundancyPrevent = -1; // prevents assigning permissions repeatedly, they wont change except on login/out
  public url = environment.lax_gateway_url + "/_ah/api/lacrosseAdmin/v1.1/";
  public db;
  public bearer;
  public user: UserEntity = null;

  public loadedPromise;

  public readyPromise;
  public _resolve_ready: any;
  public _reject_ready: any;

  public permissionsPromise;
  public _resolve_permissions: any;
  public _reject_permissions: any;


  defaultNavigation = [
    {
      title: true,
      name: 'Dashboard',
      wrapper: {            // optional wrapper object
        element: 'span',      // required valid HTML5 element tag
        attributes: {}        // optional valid JS object with JS API naming ex: { className: 'my-class', style: { fontFamily: 'Verdana' }, id: 'my-id'}
      },
      class: 'text-center'             // optional class names space delimited list for title item ex: 'text-center'
    },
  ];
  public navigation = basicNav;

  constructor(
    public http: HttpClient,
    public authHack: FirebaseAuthService,
    afAuth: AngularFireAuth,
    public router: Router,
    db: AngularFireDatabase,
    public userRepo: UserRepo) {

    this.readyPromise = new Promise((resolve, reject) => {
      this._resolve_ready = resolve;
      this._reject_ready = reject;
    })

    this.permissionsPromise = new Promise((resolve, reject) => {
      this._resolve_permissions = resolve;
      this._reject_permissions = reject;
    })

    this.afAuth = afAuth;
    this.db = db;
    this.headerUpdate();
    setInterval(() => {this.headerUpdate();}, 60000);
  }

  constraintMet(v: any) {
    if ('and' in v) {
      let r = true;
      // tslint:disable-next-line:forin
      for (const i in v.and) {
        r = r && this.constraintMet(v.and[i]);
      }
      return r;
    } else if ('or' in v) {
      let r = false;
      // tslint:disable-next-line:forin
      for (const i in v.or) {
        r = r || this.constraintMet(v.or[i]);
      }
    } else if ('level' in v) {
      return this.authHack.permissionLevel <= v.level;
    } else if ('permission' in v) {
      // @todo we should be using the newer user.hasPermission flow but the elixir list does not match the appengine list currently.
      return this.authHack.permissionsList[v.permission] === 1;
    } else {
      return false;
    }
  }

  processList(input: any) {
    const r = [];
    // tslint:disable-next-line:forin
    for (const index in input) {
      const entry = input[index];
      let m: any = null;
      if (!('constraint' in entry)) {
        m = entry;
      } else {
        if (this.constraintMet(entry.constraint)) {
          m = entry;
        }
      }

      if (m) {
        if ('children' in m) {
          const children = this.processList(m.children);
          m.children = children;
        }
        r.push(m);
      }
    }
    return r;
  }

  updateNavigation() {
    this.navigation = this.processList(fullNav);
  }

  // only prevents navigation if the user is confirmed to not be logged in
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean|Observable<boolean> {
    let basicValURLS = ["login","portal/devices/search"];
    let supportURLS = ["portal/users/list","portal/users/show/","portal/devices/show/","portal/gateways/search"];
    let navPath = 0;
    let loc = state.url;
    supportURLS.forEach((x)=>{
      if (loc.indexOf(x) > -1) navPath=1;
    })
    if (navPath==0) {
      basicValURLS.forEach((x)=>{
        if (loc.indexOf(x) > -1) navPath=2;
      })
    }

    if (navPath==2) {
      if (this.authHack.isLoggedIn) {
        //We are logged in already
        return true;
      } else {
        //Not logged in yet listen to the thing that tells us when we are first logged in, and once it triggers, check it.
        return this.authHack.permissionStatus.pipe(map((val)=> {
          if (val == 1) {
            return true;
          } else {
            return false;
          }
        }));
      }
    } else if (navPath==1) {
      if (this.authHack.permissionLevel<6) {return true;}
      else if(this.authHack.permissionLevel>=6 && this.authHack.permissionLevel<11) {
        this.router.navigate(['portal/devices/search']);
        return false;
      }
      return this.authHack.obsPL.pipe(map((val)=> {
        if (val<6) {return true;}
        else {this.router.navigate(['portal/devices/search']);return false;}
      }))
    }
    else {
      if (this.authHack.permissionLevel==0) {return true;}
      else if(this.authHack.permissionLevel>0 && this.authHack.permissionLevel<11) {
        this.router.navigate(['portal/devices/search']);
        return false;
      }
      return this.authHack.obsPL.pipe(map((val)=> {
        if (val==0) {return true;}
        else {this.router.navigate(['portal/devices/search']);return false;}
      }))
    }
  }


  headerUpdate(): void { // Currently Commented out
    this.afAuth.authState.subscribe(auth => {  // on load, once login is confirmed, set headers
        if (auth) {
          auth.getIdToken().then(
            (token) => {
              this.authHack.setFBS(this.afAuth);
              // Output this for extracting and use outside of the app
              this.bearer = '[AUTH] Bearer ' + token;
              if (this.redundancyPrevent !== 1) {
                // Setup the services permissions
                this.permissionScan(auth.uid);
                // now that we have triggered them, we dont have to do it again until logged out.
              }

              if (auth.uid) {
                try {

                  this.loadedPromise =  new Promise(
                    (resolve, reject) => {

                      let waitOn = 2;

                      this.userRepo.getEntityPromise(auth.uid).then((user: UserEntity) => {
                        this.user = user;
                        this.user.getAdminPermissionsPromise().then(() => {
                          waitOn--;
                          this._resolve_permissions(true);
                          if (waitOn == 0) resolve(true);
                        });
                        this.user.getUserSettingsPromise().then(() => {
                          waitOn--;
                          if (waitOn == 0) resolve(true);
                        });
                      });


                    }
                  );

                  this.loadedPromise.then(() => {
                    this._resolve_ready(true);
                  }).catch(() => {
                    this._reject_ready(true);
                  });


                } catch (e)  {
                  console.warn('Unable to load ActiveUser');
                }
              } else {
                this._reject_permissions(null);
              }
            },
            (error) => {
              console.warn('------------ ERROR GETTING TOKEN ----------', error);
            }
          );
        } else {
          this.redundancyPrevent = 0;
          console.warn('[Auth] not logged in');
          // not logged in, make sure to send them to the login page
          this.router.navigate(['/login']);
        }
      });
  }

  userHasPermission(permission) {
    if (this.user) {
      return this.user.hasPermission(permission);
    } else {
      return false;
    }
  }

  userHasPermissionPromise(permission) {
    return new Promise((resolve, reject) => {
      this.permissionsPromise.then(() => {
          resolve(this.userHasPermission(permission));
      })
    });
  }

  permissionScan(uid) {
    this.httpGet(this.url + "user/"+ uid + "/admin-permissions").then((res:any) => {
      if (res.permissions) {
        this.authHack.permissionsList = res.permissions;

        if (res.permissions["super-user"] || res.permissions["support-admin"]) {
          if (this.router.url === "/login") {
            this.router.navigateByUrl('portal/users/list');
          }
        } else if ( res.permissions["call-center"]) {
          if (this.router.url === "/login") {
            this.router.navigateByUrl('portal/devices/search');
          }
        } else {
          this.router.navigateByUrl('login');
        }
      }
      this.redundancyPrevent = 1;
      this.authHack.permissionStatus.next(1);
      this.authHack.setAdmin();


      // Populate Navigation Menu based off of user Permissions and level.
      this.updateNavigation();

    }).catch((err) => {
      this.redundancyPrevent = 0;
      console.warn(err);
      alert("Error loading permissions, Please Refresh");
    })
  }

  loginWithCreds(user: string, password: string): Promise<Object> {
    return this.afAuth.auth.signInWithEmailAndPassword(user, password)
    .then((response) => {
      this.headerUpdate();
      response as Object;
      })
    .catch((response) => response as Object);
  }

  logout(): void {
    this.redundancyPrevent = 0;
    this.authHack.clearAdmin();
    this.afAuth.auth.signOut();
    this.updateNavigation();
  }

  resetPassEmail(email:string) {
    this.afAuth.auth.sendPasswordResetEmail(email);
  }

  getDatabaseObject(path) {
    return this.db.list(path);
  }

 //a hack, used exclusively to get permissions list. Less intensive than including all HTTP in Domain Object
  httpGet(url, options = {})  {
    return new Promise(
      (resolve, reject) => {
        this.authHack.getTokenPromise().then(
          (token) => {
              let requestOptions = this.authHack.request_options(token, options);
              this.http.get(url, requestOptions)
                .toPromise()
                .then((response: any) => {
                  return resolve(response);
                 })
                .catch((error) => {
                  console.warn(`Request Error . . . ${url}`, error);
                  reject({message: "request error", details: error});
                });
          }, (error) => {
              console.error("Token Error", error);
              reject({message: "token error", details: error});
          }
        );
      }
    );
  }

}
