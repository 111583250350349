import {Component, Input, Inject, ChangeDetectorRef, NgZone, OnInit, Output, EventEmitter} from '@angular/core';
import {DeviceDefinitionRepo, DeviceRepo} from '../../../repos';
import {ToasterService} from 'angular2-toaster';
import {AuthService} from '../../../services/auth.service';
import {SimulatorWidget} from '../../../entities/device/simulator-widget';
import {DeviceDefinitionEntity, DeviceLinkStruct, FieldSimulatorWidget} from '../../../entities/device';
// import {forEach} from '@angular/router/src/utils/collection';
import {
  BatchLogicalWidget, DeviceEntity,
  DeviceTypeLogicalWidget,
  ManufacturerLogicalWidget,
  SensorTypeRangeEntity,
  SeriesLogicalWidget
} from '../../../entities';
import {NoizuStruct} from '../../../noizu/structs/noizu-struct';
import {DeviceWidgetEventStruct} from './device-widget-event.struct';
import {WidgetEventStruct} from '../../widget-event.struct';
import {WidgetBase} from '../widget-base';

@Component({
  selector: 'device-link-widget',
  template: `
<div class="device-link-widget" *ngIf="!entity?.device">
  <widget-spinner></widget-spinner>
</div>
<div class="device-link-widget" *ngIf="entity?.device">
  <device-entity-widget (widgetEvent)="forwardEvent($event)" [entity]="entity.device" [layout]="layout" [options]="options" ></device-entity-widget>
</div>
`
})
export class DeviceLinkWidgetComponent extends WidgetBase implements OnInit {
  @Input() entity: DeviceLinkStruct = null;
  @Input() templateHint: string = null;
  @Input() options: any = {};
  @Input() layout = 'standard';
  @Output() widgetEvent = new EventEmitter<DeviceWidgetEventStruct>();
  forwardEvent(e, widgetEvent: DeviceWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }

  constructor(public repo: DeviceRepo) {
    super();
  }

  ngOnInit(): void {
    if (!this.entity.device) {
       this.repo.getEntityPromise(this.entity.device_identifier).then((entity: DeviceEntity) => {
         this.entity.device = entity;
       })
    }
  }
}
