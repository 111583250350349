import { Injectable, Inject }    from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from '../../environments/environment';

/*
Makes requests to the Node server  /server/routes/api.js
Useful for requests that rely on keys that should not be revealed casually to everyone
*/

@Injectable()
export class ApiService {
  public apiUrl = environment.view_admin_url + "/api";

  constructor(public client: HttpClient) {}

  //Update Email in Backend AND github by requesting the Node server to do so
  updateEmail(uid:string, email:string) {
    let url = this.apiUrl + "?key=KeyValApp&";
    let err = false;
    let errMsg = "";
    if (uid.length < 10) {
      err = true;
      errMsg += "Invalid or missing ID. ";
    }
    if (email.length < 5) {
      err = true;
      errMsg += "Email too short to be an email. ";
    }
    return new Promise(
      (resolve, reject) => {
        if (err) {
          reject({"success":false, details: errMsg});
        } else {
        this.client.get(url + "path=1&uid=" + uid + "&email=" + email)
          .toPromise()
          .then((response: any) => {
            let data = response;
            resolve({"success":data.success, "res":data});
           })
          .catch((error) => {
            console.error(error);
            resolve({"success":false, details: error});
          });
        }
      })
  }

  //Delete account from github
  deleteAccount(uid:string) {
    let url = this.apiUrl + "?key=KeyValApp&";
    let err = false;
    let errMsg = "";
    if (uid.length < 10) {
      return new Promise(
        (resolve, reject) => {
          resolve({"success":false, details:"invalid UID"});
        })
    }

    return new Promise(
      (resolve, reject) => {
        if (err) {
          reject({"success":false, details: errMsg});
        } else {
        this.client.get(url + "uid=" + uid + "&path=2")
          .toPromise()
          .then((response: any) => {
            let data = response;
            resolve({"success":data.success, "res":data});
           })
          .catch((error) => {
            console.error(error);
            resolve({"success":false, details: error});
          });
        }
      })
  }

//Check github to see if email exists
  checkEmail(email:string) {
    let url = this.apiUrl + "?key=KeyValApp&";
    let err = false;
    let errMsg = "";
    if (!email || email.length < 5) {
      return new Promise(
        (resolve, reject) => {
          resolve({"success":false, details:"Email too short"});
        })
    }

    return new Promise(
      (resolve, reject) => {
        if (err) {
          reject({"success":false, details: errMsg});
        } else {
        this.client.get(url + "email=" + email + "&path=0")
          .toPromise()
          .then((response: any) => {
            let data = response;
            resolve(data);
           })
          .catch((error) => {
            console.error(error);
            resolve({"success":false, details: error});
          });
        }
      })
  }

//Get list of subscriptions for user
  getSubscriptions(id:string) {

    let url = this.apiUrl + "/sub?key=KeyValApp&";
    let err = false;
    let errMsg = "";
    if (!id || id.length < 15) {
      return new Promise(
        (resolve, reject) => {
          resolve({"success":false, details:"Invalid id"});
        })
    }

    return new Promise(
      (resolve, reject) => {
        if (err) {
          reject({"success":false, details: errMsg});
        } else {
        this.client.get(url + "uid=" + id)
          .toPromise()
          .then((response: any) => {
            let data = response;
            let d = data.data;
            //Basic formatting and organizing of data
            for (let x=0;x<d.subscriptions.length;x++) {
              let s = new Date(d.subscriptions[x].subscription_start);
              let e = new Date(d.subscriptions[x].subscription_end);
              d.subscriptions[x].ufss = d.subscriptions[x].subscription_start;
              d.subscriptions[x].ufse = d.subscriptions[x].subscription_end;
              d.subscriptions[x].subscription_start = { date: { year: s.getFullYear(), month: s.getMonth()+1, day: s.getDate()}};
              d.subscriptions[x].subscription_end = { date: { year: e.getFullYear(), month: e.getMonth()+1, day: e.getDate()}};
            }
            resolve(data);
           })
          .catch((error) => {
            console.error(error);
            resolve({"success":false, details: error});
          });
        }
      })

  }

//Attempt to create a new user subscription
  postSubscriptions(sub) {
    let url = this.apiUrl + "/sub?key=KeyValApp";
    let s = sub.subscription_start;
    let e = sub.subscription_end;
    //Month is index, so its 1 greater than actual.
    let st = new Date(s.date.year,s.date.month-1,s.date.day);
    let end = new Date(e.date.year,e.date.month-1,e.date.day);

    let obj = {
			"subscription_type": sub.subscription_type,
			"subscription_start": st.toISOString(),
			"subscription_end": end.toISOString(),
			"owner": sub.owner,
      "isNew":sub.isNew,
      "identifier":sub.identifier
		};

    return new Promise(
      (resolve, reject) => {
        this.client.post(url, obj)
          .toPromise()
          .then((response: any) => {
            let data = response;
            resolve(data);
           })
          .catch((error) => {
            console.error(error);
            resolve({"success":false, details: error});
          });
      })
  }

//Delete user subscription
  deleteSubscription(sub) {
    let url = this.apiUrl + "/sub?key=KeyValApp";

		let uid = sub.owner.substring(9);
    url += "&uid=" + uid;
    url += "&sid=" + sub.identifier;

    return new Promise(
      (resolve, reject) => {
        this.client.delete(url)
          .toPromise()
          .then((response: any) => {
            let data = response;
            resolve(data);
           })
          .catch((error) => {
            console.error(error);
            resolve(error);
          });
      })
  }

//send a simulated device request to the servers as if from the device itself
  reportBinary(payload){
    let url = this.apiUrl + "/simulateDevice?key=KeyValApp&";
    return new Promise(
      (resolve, reject) => {
        this.client.post(url, payload)
          .toPromise()
          .then((response: any) => {
            let data = response;
            console.log(data);
            resolve(data);
           })
          .catch((error) => {
            console.error(error);
            resolve({"success":false, details: error});
          });
      })
  }
}
