
      <div class="card m-0 p-0">
          <div class="card-header m-0 p-0">
              <div class="row">
                  <div class="col-10">
                      <b>Field: </b> {{entity.identifier}}
                    <a
                      routerLinkActive="active"
                      [routerLink]="['/portal/device/field/definitions/' + entity.sensorFieldId]"
                    >{{entity.sensorFieldId}}</a>
                  </div>
                  <div class="col-2 text-center">
                      <i
                              (click)="expand = !expand"
                              class="fa fa-sm"
                              [ngClass]="{'fa-chevron-down': !expand, 'fa-chevron-up': expand}"> </i>
                  </div>
              </div>
          </div>
          <div class="card-body m-0 p-1" *ngIf="expand">
              <!-- Identifier -->
              <div class="row">
                  <div class="col-6"><b>Identifier</b></div>
                  <div class="col-6" *ngIf="!options?.edit">{{entity.identifier}}</div>
                  <div class="col-6" *ngIf="options?.edit">
                      <input type="text" [(ngModel)]="entity.identifier" (ngModelChange)="updateField()"
                                     size="10"/>
                  </div>
              </div>

              <!-- Json Name -->
              <div class="row">
                  <div class="col-6"><b>Json Name</b></div>
                  <div class="col-6" *ngIf="!options?.edit">{{entity.jsonName}}</div>
                  <div class="col-6" *ngIf="options?.edit">
                      <input type="text" [(ngModel)]="entity.jsonName" (ngModelChange)="updateField()"
                                     size="10"/>
                  </div>
              </div>

              <!-- Field Type -->
              <div class="row mb-2">
                  <div class="col-6"><b>Field Type</b></div>
                  <div class="col-6">
                      <a
                              routerLinkActive="active"
                              [routerLink]="['/portal/device/field/definitions/' + entity.sensorFieldId]"
                      >{{entity.sensorFieldId}}</a>
                  </div>
              </div>


              <div class="row" *ngIf="entity.field?.is_derived_type">
                  <div class="col-12">
                      <div class="card">
                          <div class="card-header">Derived Field (Inputs)</div>
                          <div class="card-body m-0 p-0">
                              <div *ngIf="entity.inputFields">


                                  <div *ngFor="let input of entity.entryInputs; let i = index"
                                       [ngClass]="{'div-table-tr': (i % 2) == 1, 'div-table-tr-alt': (i % 2) == 0}"
                                  >
                                      <div class="row" *ngIf="!options?.edit">
                                          <div class="col-5"><b>{{input.key}}</b></div>
                                          <div class="col-7">{{input.value}}</div>
                                      </div>
                                      <div class="row" *ngIf="options?.edit">
                                          <div class="col-5">
                                              <input type="text" [(ngModel)]="input.key"
                                                             (ngModelChange)="updateField()" size="25"/>
                                          </div>
                                          <div class="col-6">
                                              <input type="text" [(ngModel)]="input.value"
                                                             (ngModelChange)="updateField()" size="25"/>
                                          </div>
                                          <div class="col-1"><span
                                                  (click)="showDeleteInputModal(fieldEntryModal, input, $event)"><i
                                                  class="text-danger fa fa-sm fa-close"> </i></span></div>
                                      </div>
                                  </div>

                                  <div *ngIf="options?.edit"
                                       [ngClass]="{'div-table-tr': (entity.entryInputs.length % 2) == 1, 'div-table-tr-alt': (entity.entryInputs.length % 2) == 0}">
                                      <div class="row">
                                          <div class="col-12 text-center">
                                              <button (click)="addInput(fieldEntryModal, $event)"
                                                      class="button btn-primary">Add Input
                                              </button>
                                          </div>
                                      </div>
                                  </div>

                              </div>

                              <div *ngIf="options?.edit && !entity.inputFields">
                                  <div class="row">
                                      <div class="col-12 text-center">
                                          <button (click)="addInput(fieldEntryModal, $event)"
                                                  class="button btn-primary">Add Input
                                          </button>
                                      </div>
                                  </div>
                              </div>

                          </div>
                      </div>
                  </div>
              </div>

              <!-- Aggregation Rules -->
              <div class="row">
                  <div class="col-12">
                      <div class="card m-0">
                          <div class="card-header m-0 p-1">
                              <div class="row">
                                  <div class="col-10">
                                      <b>Aggregation Rules:</b> {{entity.identifier}}
                                  </div>
                                  <div class="col-2">
                                      <i
                                              (click)="expandRules = !expandRules"
                                              class="fa fa-sm"
                                              [ngClass]="{'fa-chevron-down': !expandRules, 'fa-chevron-up': expandRules}"> </i>
                                  </div>
                              </div>
                          </div>
                          <div class="card-body m-0 p-1" *ngIf="expandRules">
                              <div class="div-table-tr">
                                  <div class="row">
                                      <div class="col-3"><strong>Type</strong></div>
                                      <div class="col-3"><strong>Period</strong></div>
                                      <div class="col-3"><strong>Count</strong></div>
                                      <div class="col-3"><strong>Retention</strong></div>
                                  </div>
                              </div>
                              <div *ngFor="let rule of entity?.aggregationRules; let j = index"
                                   [ngClass]="{'div-table-tr': (j % 2) == 1, 'div-table-tr-alt': (j % 2) == 0}">
                                  <div class="row" *ngIf="!options?.edit">
                                      <div class="col-3">{{rule.ufType.name}}</div>
                                      <div class="col-3">{{rule.ufPeriod.name}}</div>
                                      <div class="col-3">{{rule.count}}</div>
                                      <div class="col-3">{{rule.retention}}</div>
                                  </div>

                                  <div class="row" *ngIf="options?.edit">
                                      <div class="col-3">

                                        <ng-select
                                          [multiple]="false"
                                          [(ngModel)]="rule.type"
                                          bindLabel="text"
                                          bindValue="value"
                                          (ngModelChange)="updateRule($event, rule)"
                                          [items]="ruleTypeOptions"
                                        ></ng-select>


                                      </div>
                                      <div class="col-3">

                                        <ng-select
                                          [multiple]="false"
                                          [(ngModel)]="rule.rulePeriodOptions"
                                          bindLabel="text"
                                          bindValue="value"
                                          (ngModelChange)="updateRule($event, rule)"
                                          [items]="rulePeriodOptions"
                                        ></ng-select>

                                      </div>
                                      <div class="col-2">
                                          <input type="text" [(ngModel)]="rule.count"
                                                         (ngModelChange)="updateRule($event, rule)" size="10"/>
                                      </div>
                                      <div class="col-3">
                                          <input type="text" [(ngModel)]="rule.retention"
                                                         (ngModelChange)="updateRule($event, rule)" size="10"/>
                                      </div>
                                      <div class="col-1"><span
                                              (click)="showDeleteRuleModal(fieldEntryModal, rule, j,  $event)"><i
                                              class="text-danger fa fa-sm fa-close"> </i></span></div>
                                  </div>
                              </div>
                              <div *ngIf="options?.edit"
                                   [ngClass]="{'div-table-tr': ( entity?.aggregationRules.length % 2) == 1, 'div-table-tr-alt': ( entity?.aggregationRules.length % 2) == 0}">
                                  <div class="row">
                                      <div class="col-12 text-center">
                                          <button (click)="addRule(fieldEntryModal, $event)" class="button btn-primary">
                                              Add Retention Rule
                                          </button>
                                      </div>
                                  </div>
                              </div>


                          </div>
                      </div>
                  </div>
              </div> <!-- End Aggregation Rules -->

              <!--
                      <div class="row" *ngIf="pending">
                        <div class="col-12">
                          <button (click)="updateEntity($event)" class="button btn-primary">Save Changes</button>
                        </div>
                      </div>
              -->
          </div><!-- end card block -->
      </div><!-- end card -->


      <!--                                                        -->
      <!--                    Pop-Up Menu                         -->
      <!--                                                        -->
      <ng-template #fieldEntryModal>
          <div class="modal-header">
              <h4 class="modal-title pull-left">{{currentModal?.title}}</h4>
              <button type="button" class="close pull-right" aria-label="Close" (click)="fieldEntryModalRef.hide()">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body">
              <widget-spinner *ngIf="currentModal?.processing"></widget-spinner>

              <div class="row">
                  <div class="col-12">
                      {{currentModal?.msg}}
                  </div>
              </div>

              <div class="row" *ngIf="currentModal?.form == 'new-input'">


                  <div class="col-6">
                      <input type="text" [(ngModel)]="newInput.key" size="10"/>
                  </div>
                  <div class="col-6">
                      <input type="text" [(ngModel)]="newInput.value" size="10"/>
                  </div>


              </div>


              <div class="row" *ngIf="currentModal?.form == 'new-rule'">


                  <div class="col-3">

                    <ng-select
                      [multiple]="false"
                      [(ngModel)]="newRule.type"
                      bindLabel="text"
                      bindValue="value"
                      [items]="ruleTypeOptions"
                    ></ng-select>

                  </div>
                  <div class="col-3">

                    <ng-select
                      [multiple]="false"
                      [(ngModel)]="newRule.period"
                      bindLabel="text"
                      bindValue="value"
                      [items]="rulePeriodOptions"
                    ></ng-select>
                  </div>
                  <div class="col-3">
                      <input type="text" [(ngModel)]="newRule.count" size="10"/>
                  </div>
                  <div class="col-3">
                      <input type="text" [(ngModel)]="newRule.retention" size="10"/>
                  </div>


              </div>


          </div>
          <div class="modal-footer">
              <button
                      *ngIf="currentModal?.cancelMsg"
                      type="button"
                      class="btn btn-secondary"
                      (click)="currentModal?.cancel()"
                      data-dismiss="modal">{{currentModal?.cancelMsg}}</button>
              <button
                      *ngIf="currentModal?.deleteMsg"
                      type="button"
                      class="btn btn-warning"
                      (click)="currentModal?.delete()"
                      data-dismiss="modal">{{currentModal?.deleteMsg}}</button>
              <button
                      *ngIf="currentModal?.confirmMsg"
                      type="button"
                      class="btn btn-danger"
                      (click)="currentModal?.confirm()">{{currentModal?.confirmMsg}}</button>
          </div>
      </ng-template>




  