<page-header [section]="'mcu-firmware-link-assoc'">
  <page-title>MCU Firmware Link Associations</page-title>
  <page-blurb> </page-blurb>
</page-header>

<spinner *ngIf="!entries"></spinner>

<div *ngIf="entries">

  <div class="row">
    <div  class="col-12">
      <div class="row mb-4" *ngFor="let cat of this.categories">
        <div class="col-12 mb-2 text-center">
          <span class="display-4">{{cat}}</span>
        </div>
        <div [ngClass]="{'col-12': !selectedLink[cat], 'col-7': selectedLink[cat]}">
          <div class="row mb-3">


            <div *ngIf="entries[cat]" class="col">

              <div class="row">
                <div class="col text-left"><strong>#ID</strong></div>
                <div class="col text-left"><strong>Name</strong></div>
                <div class="col text-left"><strong>Status</strong></div>
                <div class="col text-left"><strong>Firmware</strong></div>
                <div class="col text-left"><strong>Criteria</strong></div>
                <div class="col text-left"><strong>Approval</strong></div>
              </div>

              <div *ngFor="let entity of entries[cat]; let i = index;">
                <widget-embed [entity]="entity"  [layout]="'table'" [options]="{index: i, edit: false}" (click)="selectLink(entity, cat, $event)"></widget-embed>
              </div>
            </div>
          </div>



        </div>


        <div *ngIf="selectedLink[cat]" class="m-0 p-0 pt-3 pl-2 col-5">
          <widget-embed [entity]="selectedLink[cat]" [layout]="'sidebar'" [options]="sidebar_options"> </widget-embed>
        </div>

        <div class="col-12 mt-3">
          <div class="row">
            <div class="col-4"></div>
            <div class="col-4">
              <button (click)="add(createLinkModal, cat, $event)" class="btn btn-block btn-primary" type="submit"><i class="fa fa-plus-circle"></i> New</button>
            </div>
          </div>
          <hr/>
        </div>
      </div>

    </div>

  </div>
</div>






















<!-- <div class="row mb-5">
  <div class="col text-center">
    <span class="display-1">MCU Firmware Link Associations</span>
  </div>
</div>



<spinner *ngIf="!entries"></spinner>


<div class="row" *ngFor="let cat of this.categories">

  <div class="col-12 mb-2 text-center">
    <span class="display-4">{{cat}}</span>
  </div>


  <div  class="row mb-3">
    <div [ngClass]="{'col-12': !selectedLink, 'col-7': selectedLink}">
      <div class="row">
        <div class="col text-left"><strong>#ID</strong></div>
        <div class="col text-left"><strong>Name</strong></div>
        <div class="col text-left"><strong>Status</strong></div>
        <div class="col text-left"><strong>Firmware</strong></div>
        <div class="col text-left"><strong>Criteria</strong></div>
        <div class="col text-left"><strong>Approval</strong></div>
      </div>
      <widget-embed *ngFor="let entity of entries[cat]; let i = index;" [entity]="entity"  [layout]="'table'" [options]="{index: i, edit: list_options.edit}"  (click)="selectLink(entity, $event)"> </widget-embed>
      <div class="row mt-3">
        <div class="col-4"></div>
        <div class="col-4">
          <button (click)="add(createLinkModal, cat, $event)" class="btn btn-block btn-primary" type="submit"><i class="fa fa-plus-circle"></i> New</button>
        </div>
      </div>

    </div>

    <div *ngIf="selectedLink" class="col-5">
      <widget-embed [entity]="selectedLink" [layout]="'sidebar'" [options]="sidebar_options"> </widget-embed>
    </div>
  </div>



</div> -->

<!--
<div *ngIf="entries">
              <b class="btn-pill pl-2 pr-2" [ngClass]="{'bg-success': (entity.status == 'approved' || entity.status == 'managed'), 'bg-danger': entity.status == 'pending'}">
                  {{entity?.status}}
              </b>

</div>
-->










<!--                                                        -->
<!--                    Pop-Up Menu                         -->
<!--                                                        -->
<ng-template #createLinkModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{currentModal.title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <widget-spinner *ngIf="currentModal?.processing"></widget-spinner>
    <div class="row" *ngIf="!currentModal?.processing">
      <div class="col-12" *ngIf="currentModal?.msg">
        <div class="alert alert-info">
          {{currentModal?.msg}}
        </div>
      </div>
      <div class="col-12">
        <widget-embed [entity]="newLink" [layout]="'modal'" [options]="widget_options"> </widget-embed>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      *ngIf="currentModal?.cancelMsg"
      type="button"
      class="btn btn-secondary"
      (click)="currentModal?.cancel()"
      data-dismiss="modal">{{currentModal?.cancelMsg}}</button>
    <button
      *ngIf="currentModal?.deleteMsg"
      type="button"
      class="btn btn-warning"
      (click)="currentModal?.delete()"
      data-dismiss="modal">{{currentModal?.deleteMsg}}</button>
    <button
      *ngIf="currentModal?.confirmMsg"
      type="button"
      class="btn btn-danger"
      (click)="currentModal?.confirm()">{{currentModal?.confirmMsg}}</button>
  </div>
</ng-template>
