
      <div class="embed-ds-media-card" *ngIf="!editMode">
          <div class="row">
              <div class="col font-xl"><b>Text 1:</b> {{entity.messages.one}}</div>
              <div class="col font-xl"><b>Text 2:</b> {{entity.messages.two}}</div>
          </div>
          <div class="row">
              <div class="col font-xl"><b>Reading 1:</b> {{entity.readings.one}}</div>
              <div class="col font-xl"><b>Reading 2:</b> {{entity.readings.two}}</div>
          </div>
      </div>
      <div class="embed-ds-media-card" *ngIf="editMode">
          <div class="row">
              <div class="col-12">
                  <div class="card mb-0 pb-0 border-warning">
                      <div class="card-body" *ngIf="entity">
                          <!-- Type Specific -->
                          <div class="row"><div class="col-4"><b>Text One</b></div><div class="col-8">
                              <input
                                      type="text"
                                      name="[message]one"
                                      [(ngModel)]="entity.messages.one"
                                      (ngModelChange)="inlineEdit($event)"
                                      name="editMessageOne"
                                      size="10"/>
                          </div></div>
                          <div class="row"><div class="col-4"><b>Text Two</b></div><div class="col-8">
                              <input
                                      type="text"
                                      name="[message]two"
                                      [(ngModel)]="entity.messages.two"
                                      (ngModelChange)="inlineEdit($event)"
                                      name="editMessageTwo"
                                      size="10"/>
                          </div></div>
                          <div class="row"><div class="col-4"><b>Reading One</b></div><div class="col-8">
                              <input
                                      type="text"
                                      name="[reading]one"
                                      [(ngModel)]="entity.readings.one"
                                      (ngModelChange)="editReadingOne($event)"
                                      name="editReadingOne"
                                      size="5"/>
                          </div></div>
                          <div class="row"><div class="col-4"><b>Reading Two</b></div><div class="col-8">
                              <input
                                      type="text"
                                      name="[reading]two"
                                      [(ngModel)]="entity.readings.two"
                                      (ngModelChange)="editReadingTwo($event)"
                                      name="editReadingTwo"
                                      size="5"/>
                          </div></div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    