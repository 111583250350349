
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { DomainObjectRepo } from '../../../noizu/domain-object-repo';
import { FirebaseAuthService }        from '../../../noizu/services/firebase-auth.service';
import { ElixirRepo }      from '../../elixir.repo';
import { GatewayBackendLookupTableEntryEntity }      from '../../../entities/gateway/backend/lookup-table-entry.entity';

@Injectable()
export class GatewayBackendLookupTableRepo extends ElixirRepo {
  public _kind = "gateway-lookups";

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  entity(record) {
    return new GatewayBackendLookupTableEntryEntity(this.client, this.auth, record)
  }
}
