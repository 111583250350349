import {Component, Input, Inject, Output, EventEmitter} from '@angular/core';
import {NoizuStruct} from '../../../noizu/structs/noizu-struct';
import {ModalWidgetEnum, WidgetEnum} from '../../../enums/widget';
import {ModalWidget} from "../../../entities/";
import {ToasterService} from 'angular2-toaster';
import {ModalWidgetEventStruct} from './modal-widget-event.struct';

@Component({
  selector: 'bare-modal-widget',
  template: `

    <div class="modal-header">
      <h4 class="modal-title pull-left">{{entity?.title}}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="entity?.modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <widget [entity]="entity?.subject" [layout]="entity?.embed_layout" [options]="entity?.embed_options"></widget>
    </div>
  `
})
export class BareModalWidgetComponent {
  @Input() entity: ModalWidget | null = null;
  @Input() templateHint: string = null;
  @Input() options: any = null;
  @Input() layout = 'standard';
  @Output() widgetEvent = new EventEmitter<ModalWidgetEventStruct>();
  forwardEvent(e, widgetEvent: ModalWidgetEventStruct) {
    e.preventDefault();
    this.widgetEvent.emit(widgetEvent);
  }
  constructor(public toasterService: ToasterService) {

  }
}
