
import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from '../lacrosse.entity';
import { FirebaseAuthService }            from '../../noizu/services/firebase-auth.service';
import {ElixirEntity} from '../elixir.entity';
import {FirmwareWidgetEnum, WidgetEnum} from '../../enums';
//import { DeviceDefinitionRepo }           from '../../repos/device/definition.repo';

export class FirmwareApprovalEntity extends ElixirEntity {
  public _kind = "firmware-approval";
  public _singular = "firmware-approval";

  public identifier: any;
  public modified_on: Date;
  public created_on: Date;
  public _sref_module = "firmware-approval"
  public reference: string;
  public comment: string;
  public approver: string;
  public approved_on: Date;




  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    if (json) {
      this.refresh(json);
    }
  } // end constructor
  refresh(data) {
    console.log(data)
    this.identifier = data.identifier;
    this.created_on = data.created_on ? new Date(data.created_on) : null;
    this.modified_on = data.modified_on ? new Date(data.modified_on) : null;
    this.reference = data['reference'];
    this.comment = data['comment'];
    this.approver = data['approver'];
    this.approved_on = data['approved_on'] ? new Date(data['approved_on']) : null



    return super.refresh(data);
  }
  // Sets the fields, used above, and to reset the values when canceling an edit

  widget_type() {
    return WidgetEnum.EMBED_WIDGET__FIRMWARE;
  }

  firmware_widget_type() {
    return FirmwareWidgetEnum.FIRMWARE_WIDGET__APPROVAL
  }

  approveRelease() {
    const url = `${this.apiBase()}/firmware/releases/${this.reference}/approve`;
    let entity = this.toJson({for: 'elixir'});
    entity.identifier = null;
    return this._put(url, entity,
      (data, resolve) => {
        this.modified = false;
        this.new = false;
        resolve(this.refresh(data)); },
      {}
    );
  }

  validate() {
    let valid = true;
    let error = "";
    let messages = {};

    if (!this.reference) {
      valid = false;
      messages['reference'] = "Reference Required"
    }
    if (!this.comment) {
      valid = false;
      messages['comment'] = "Comment Required"
    }
    if (!this.approver) {
      valid = false;
      messages['approver'] = "Authorizer Required"
    }

    if (!valid) {
      error = "Validation Errors"
      messages['common'] = "Please Resolve Validation Errors"
    }

    this.validation = {valid: valid,  validated: true, error: error, messages: messages}
    return this.validation;
  }

} // end Package
