
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { DomainObjectRepo } from '../../noizu/domain-object-repo';
import { FirebaseAuthService }        from '../../noizu/services/firebase-auth.service';
import { CmsDataStreamEntity }      from '../../entities/cms/';
import {ElixirRepo} from '../elixir.repo';

@Injectable()
export class CmsDataStreamRepo extends ElixirRepo {
  public _kind = "null";
  public _singular = "null";
  public _appengine = false;

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  entity(record) {
    return new CmsDataStreamEntity(this.client, this.auth, record)
  }

  getListPromise(options = {}) {
    return this._getListPromise(`${this.apiBase()}/cms/v2/data-stream`, (record) => {return this.entity(record); }, options);
  }

}
