import {HttpClient} from '@angular/common/http';
import {FirebaseAuthService} from '../../noizu/services/firebase-auth.service';
import {ElixirEntity} from '../elixir.entity';
import {EmailWidgetEnum, WidgetEnum} from '../../enums';
import {EmailEntity} from './email.entity';

//import { DeviceDefinitionRepo }           from '../../repos/device/definition.repo';

export class EmailQueueEntityTemplateCache {
  public version: string;
  public substitutions: any;

  constructor(json) {
    this.version = json.version;
    this.substitutions = json.substitutions;
  }
}

export class EmailQueueEntityTemplate {
  public synched_on: Date;
  public name: string;
  public description: string;
  public identifier: string;
  public external_template_identifier: any;
  public cached_details: any;
  public cached: EmailQueueEntityTemplateCache;
  public binding_defaults: any;

  constructor(json) {
    this.synched_on = json.synched_on && new Date(json.synched_on);
    this.name = json.name;
    this.identifier = json.identifier;
    this.external_template_identifier = json.external_template_identifier && json.external_template_identifier.tuple[1];
    this.description = json.description;
    this.cached_details = json.cached_details;
    this.cached = json.cached && new EmailQueueEntityTemplateCache(json.cached);
    this.binding_defaults = json.binding_defaults;
  }
}

export class EmailQueueEntityEvents {
  public loaded: boolean;


  constructor() {
    this.loaded = false;
  }
}



export class EmailQueueEntityEffectiveBinding {
  public vsn: string;
  public unbound: any;
  public bound: any;
  public bind: any;

  public extract_bindings(json) {
    let selectors = [];
    for(let i = 0; i < json.length; i++) {
      let selector = [];

      if (json[i]['selector']) {
        for(let j = 0; j < json[i].selector.length; j++) {
          let entry = "";
          if (json[i].selector[j]['tuple']) {
            for(let k = 0; k < json[i].selector[j].tuple.length; k++) {
              if (k == 0) {
                entry += json[i].selector[j].tuple[k];
              } else {
                entry += "." + json[i].selector[j].tuple[k];
              }
            }
            selector.push(entry);
          } else {
            selector.push(json[i].selector[j]);
          }
        }
      } else {
        selector.push(json[i]);
      }
      selectors.push(selector);
    }
    return selectors;
  }

  constructor(json) {
    this.vsn = json.vsn;
    this.unbound = {required: [], optional: []};
    if (json.unbound['required']) this.unbound.required = this.extract_bindings(json.unbound.required);
    if (json.unbound['optional']) this.unbound.optional = this.extract_bindings(json.unbound.optional);
    this.bind = this.extract_bindings(json.bind);
    this.bound = json.bound;
  }
}

export class EmailQueueEntityBinding {
  public vsn: string;
  public unbound: any;
  public template_version: any;
  public template: EmailQueueEntityTemplate;
  public effective_binding: EmailQueueEntityEffectiveBinding | null;
  public subject: string;
  public state: string;
  public sender_name: string;
  public sender_email: string;
  public sender: string;
  public recipient_name: string;
  public recipient_email: string;
  public recipient: string;
  public html_body: string;
  public body: string;
  public attachments: any;
  public events: EmailQueueEntityEvents;

  constructor(json) {
    this.vsn = json.vsn;
    this.unbound = json.unbound;
    this.template_version = {version: json.template_version.version, template: json.template_version.template.tuple[1]};
    this.template = json.template && new EmailQueueEntityTemplate(json.template);
    this.effective_binding = json['effective_binding'] && new EmailQueueEntityEffectiveBinding(json.effective_binding);

    this.subject = json.subject;
    this.state = json.state;
    this.sender_name = json.sender_name;
    this.sender_email = json.sender_email;
    this.sender = json.sender;
    this.recipient_name = json.recipient_name;
    this.recipient_email = json.recipient_email;
    this.recipient = json.recipient;
    this.html_body = json.html_body;
    this.body = json.body;
    this.attachments = json.attachments;
    this.events = new EmailQueueEntityEvents();
  }
}

export class EmailQueueEntity extends EmailEntity {
  public _kind = "null";
  public _singular = "null";
  public raw:any;
  public version: any;
  public template: string;
  public state: string;
  public sender: string;
  public retry_on: Date;
  public recipient: string;
  public email;
  public created_on: Date;
  public binding: EmailQueueEntityBinding;


  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    if (json) {
      this.refresh(json);
    }
  } // end constructor

  isObject(val) {
    if (val === null) { return false;}
    return ( (typeof val === 'function') || (typeof val === 'object') );
  }

  refresh(data) {
    super.refresh(data);
    this.raw = data;
    this.version = {version: data.version.version, template: data.version.template.tuple[1]};
    this.template = data.template;

    this.state = data.state;
    if (this.isObject(this.state)) this.state = this.state["tuple"][0] + "." + this.state["tuple"][1];

    this.sender = data.sender;
    this.retry_on = data.retry_on && new Date(data.retry_on);
    this.recipient = data.recipient;
    this.email = data.email;
    this.created_on = data.created_on && new Date(data.created_on);
    this.binding = new EmailQueueEntityBinding(data.binding);

    return this;
  }

  filter(filter: string) {
    if (filter) {
      let filters = filter.split(" ")
      for(let i = 0; i < filters.length; i++) {
        let f = filters[i].trim();
        let command = f.split(':');
        if (command.length == 2) {
          switch(command[0]) {
            case 'id':
              if  (`${this.identifier}` != command[1]) return false;
              break;
            case 'email':
              if  (this.binding.recipient_email != command[1]) return false;
              break;
            case 'user':
              if (this.binding.recipient != `ref.user.${command[1]}`) return false;
              break;
            case 'state':
              if (this.state != command[1]) return false;
            break;
            case 'template':
              if (this.template != `ref.email-template.${command[1]}`) return false;
              break;
          }
        } else {
          let outcome = false;
          if (`${this.identifier}` == command[0]) outcome = true;
          if (this.binding.recipient_email == command[0]) outcome = true;
          if (this.binding.recipient == `ref.user.${command[0]}`) outcome = true;
          if (this.state == command[0]) outcome = true;
          if (this.template == `ref.email-template.${command[0]}`) outcome = true;
          return outcome;
        }
      }
    }
    return true;
  }

  email_widget_type(): EmailWidgetEnum| null {
    return EmailWidgetEnum.EMAIL_WIDGET__QUEUE_ENTRY;
  }

} // end Package
