
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { DomainObjectRepo } from '../../noizu/domain-object-repo';
import { FirebaseAuthService }        from '../../noizu/services/firebase-auth.service';
import { AppengineRepo }      from '../appengine.repo';
import { DeviceLookupTableEntryEntity }      from '../../entities/device/lookup-table-entry.entity';

@Injectable()
export class DeviceLookupTableRepo extends AppengineRepo {
  public _kind = "device-lookup";
  public _singular = "device-lookup";

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  entity(record) {
    return new DeviceLookupTableEntryEntity(this.client, this.auth, record)
  }
}
