import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WidgetBase} from '../../widget-base';
import {DateTimeIntervalEntity, DeviceReading, ModalWidget, RequestLogSet, UserSubscriptionEntity} from '../../../../entities';
import {UserWidgetEventStruct} from '../user-widget-event.struct';
import {SubscriptionDefinitionRepo} from '../../../../repos';
import {WidgetEventStruct} from '../../../widget-event.struct';
import {ModalWidgetEnum, WidgetEnum} from '../../../../enums';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap/modal';
import {ToasterService} from 'angular2-toaster';

@Component({
  selector: 'extended-user-subscription',
  template: `


    <ng-container [ngSwitch]="layout">

      <!-- =============================================== -->
      <!-- Table View                                      -->
      <!-- =============================================== -->
      <ng-container *ngSwitchCase="'table'">
        <div class="row" *ngIf="options['index'] == 0 && options['header']">
          <ng-container *ngFor="let column of options.columns">
            <ng-container [ngSwitch]="column">
              <div class="col div-table-th" *ngSwitchCase="'identifier'">Id</div>
              <div class="col div-table-th" *ngSwitchCase="'handle'">Handle</div>
              <div class="col div-table-th" *ngSwitchCase="'type'">Type</div>
              <div class="col div-table-th" *ngSwitchCase="'status'">Status</div>
              <div class="col div-table-th" *ngSwitchCase="'payment'">Source</div>
              <div class="col div-table-th" *ngSwitchCase="'start'">Start</div>
              <div class="col div-table-th" *ngSwitchCase="'end'">End</div>
              <div class="col div-table-th" *ngSwitchCase="'actions'">Actions</div>
            </ng-container>
          </ng-container>
        </div>
        <div class="row">
          <ng-container *ngFor="let column of options.columns">
            <ng-container [ngSwitch]="column">
              <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'identifier'">
                <!-- <a [routerLink]="['/portal/pins/show/'  + entity.identifier]">{{entity?.identifier}}</a> -->
                {{entity?.identifier}}
              </div>

              <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'handle'">
                {{entity.handle}}
              </div>

              <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'status'">
                {{entity.subscription_status}}
              </div>

              <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'payment'">
                {{entity.subscription_payment?.source || "internal"}}
              </div>

              <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'type'">
                {{entity.subscription_type}}
              </div>

              <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'start'">
                <span *ngIf="entity.subscription_start">{{entity.subscription_start| date:'longDate'}}</span>
              </div>

              <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'end'">
                <span *ngIf="entity.subscription_end">{{entity.subscription_end| date:'longDate'}}</span>
              </div>

              <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'actions'" >
                [Actions]
              </div>


            </ng-container>
          </ng-container>
        </div>
      </ng-container>

      <!-- =============================================== -->
      <!-- Default View (Card)                             -->
      <!-- =============================================== -->
      <ng-container *ngSwitchDefault>
        <ng-container *ngIf="entity?.new">

          <div class="row">
            <div class="col-2">
              Handle
            </div>
            <div class="col">
              <input type="text"  class="form-control" [(ngModel)]="entity.handle" (ngModelChange)="editEntity()" size="32" />
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              Owner
            </div>
            <div class="col">
              {{entity.owner}}
            </div>
          </div>

          <div class="row" *ngIf="entity.validation?.messages['subscription_type']">
            <div class="col">
              <div class="alert alert-warning">{{entity.validation.messages.subscription_type}}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              Type
            </div>
            <div class="col">
              <widget [entity]="typeRepo" (widgetEvent)="widgetEventHandler($event)" [options]="{edit: options['edit'], selected: entity.subscription_type}" [layout]="'select'"></widget>
            </div>
          </div>

          <div class="row" *ngIf="entity.validation?.messages['subscription_status']">
            <div class="col">
              <div class="alert alert-warning">{{entity.validation.messages.subscription_status}}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-2">Status</div>
            <div class="col">
              <ng-select
                [name]="'subscription_status'"
                [multiple]="false"
                [(ngModel)]="entity.subscription_status"
                bindLabel="text"
                bindValue="value"
                (ngModelChange)="setStatus($event)"
                [items]="status_options"
              ></ng-select>
            </div>
          </div>

          <div class="row" *ngIf="entity.validation?.messages['subscription_start']">
            <div class="col">
              <div class="alert alert-warning">{{entity.validation.messages.subscription_start}}</div>
            </div>
          </div>
          <div class="row" *ngIf="entity.validation?.messages['subscription_end']">
            <div class="col">
              <div class="alert alert-warning">{{entity.validation.messages.subscription_end}}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              Period
            </div>
            <div class="col">
              <ng-select
                placeholder="Select TimeSpan"
                [items]="time_span_options"
                [multiple]="false"
                [(ngModel)]="time_span"
                bindLabel="text"
                bindValue="value"
                (ngModelChange)="selectSpan($event)"
              ></ng-select>
            </div>
          </div>


        </ng-container>
        <ng-container *ngIf="!entity?.new">
          <div class="card">
            <div class="card-header">
              Subscription #{{entity?.identifier}}
              <div class="card-header-actions" *ngIf="!inAppPurchase() && options['edit']">
                <div class="card-header-action">
                  <i class="text-danger fa fa-minus-circle" (click)="delete($event)"> </i>
                </div>
              </div>
            </div>
            <div class="card-body m-1 p-0">
              <div class="row">
                <div class="col-9">
                  <div class="row">
                    <div class="col-2">
                      Type
                    </div>
                    <div class="col">
                      <widget [entity]="typeRepo" (widgetEvent)="widgetEventHandler($event)" [options]="{edit: !inAppPurchase() && (options['edit'] || false), selected: entity.subscription_type}" [layout]="'select'"></widget>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-2">
                      Handle
                    </div>
                    <div class="col">
                      <span *ngIf="!(entity && options && options['edit'])">{{entity.handle}}</span>
                      <span *ngIf="entity && options && options['edit']"><input type="text" class="form-control" [(ngModel)]="entity.handle" (ngModelChange)="editEntity()" size="32" /></span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-2">
                      Owner
                    </div>
                    <div class="col">
                      {{entity.owner}}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-2">
                      Status
                    </div>
                    <div class="col" >

                      <ng-container *ngIf="!inAppPurchase() && options['edit']">
                        <ng-select
                          [name]="'subscription_status'"
                          [multiple]="false"
                          [(ngModel)]="entity.subscription_status"
                          bindLabel="text"
                          bindValue="value"
                          (ngModelChange)="setStatus($event)"
                          [items]="status_options"
                        ></ng-select>
                      </ng-container>
                      <ng-container *ngIf="!(!inAppPurchase() && options['edit'])">
                        {{entity.subscription_status}}
                      </ng-container>
                    </div>
                  </div>
                  <div class="row" *ngIf="entity.subscription_payment">
                    <div class="col-2">Payment Details</div>
                    <div class="col">
                      <widget (widgetEvent)="forwardEvent($event)" [entity]="entity.subscription_payment" [layout]="layout" [options]="options"></widget>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-2">
                      Period
                    </div>
                    <div class="col">
                      <span *ngIf="entity.subscription_start">{{entity.subscription_start| date:'medium'}}</span> - <span *ngIf="entity.subscription_end">{{entity.subscription_end | date:'medium'}}</span> <i *ngIf="!inAppPurchase() && options['edit']" class="fa text-primary fa-pencil" (click)="editPeriod($event, pickerModal)"> </i>
                    </div>
                  </div>
                </div>
                <div class="col">

                  <entity-notes-v2
                    [title] = "'Subscription Notes'"
                    [entity]="entity.sref()"
                    [options]="{'expand': true, 'dragula': false}"
                    [placeholder] = "'Subscription Note.'"
                  ></entity-notes-v2>

                </div>
              </div>


            </div>
            <div class="card-footer text-center m-0 p-1" *ngIf="entity?.meta?.hasChanges">
              <button (click)="save($event)" type="button" class="btn btn-danger rounded">Update</button>
            </div>
          </div>
        </ng-container>
      </ng-container>

    </ng-container>


    <ng-template #pickerModal>
      <modal-widget *ngIf="currentModal" (widgetEvent)="eventHandler($event, 'pop-up', pickerModal)" [entity]="currentModal" [layout]="'shadowbox'" [options]="{}"></modal-widget>
    </ng-template>

  `
})
export class UserExtendedSubscriptionWidgetComponent extends WidgetBase implements OnInit {
  @Input() layout: string = null;
  @Input() entity: UserSubscriptionEntity;
  @Input() options: any;
  @Output() widgetEvent = new EventEmitter<UserWidgetEventStruct>();
  forwardEvent(e, widgetEvent: UserWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }
  widgetEventHandler(event: WidgetEventStruct) {
    switch(event.event_widget_type) {
      case WidgetEnum.EMBED_WIDGET__SUBSCRIPTION_DEFINITION_REPO:
         switch (event.event_type) {
           case 'select':
             this.entity.subscription_type = event.event_body;
             this.entity.meta.hasChanges = true;
             break;
         }
        break;
    }
  }

  public editMode = false;
  public modalRef: BsModalRef;
  public currentModal: any = null;
  public status_options = [
    {value: "pending", text: "Pending"},
    {value: "active", text: "Active"},
    {value: "expired", text: "Expired"},
    {value: "cancelled", text: "Cancelled"},
    {value: "error", text: "Error"},
    {value: "trial", text: "Trial"},
    {value: "complimentary", text: "Complimentary"},
    {value: "refunded", text: "Refunded"},
  ];

  time_span = 'year';
  time_span_options = [
    {value: 'week', text: '1 Week'},
    {value: 'month', text: '1 Month'},
    {value: 'ninety_days', text: '90 Days'},
    {value: 'year', text: '1 Year'},
    {value: 'two_years', text: '2 Years'},
    {value: 'five_years', text: '5 years'}
  ];

  constructor(public typeRepo: SubscriptionDefinitionRepo, public modalService: BsModalService, public toasterService: ToasterService) {
    super();
  }


  eventHandler(event, field, modal) {
    console.log("Release Unhandled Event", field, event);
  }

  inAppPurchase() {
    if (!this.entity.subscription_payment) return false;
    if (this.entity.subscription_payment.source == "ios") return true;
    if (this.entity.subscription_payment.source == "android") return true;
    return false;
  }

  setStatus(event) {
    this.entity.meta.hasChanges = true;
  }

  selectSpan() {
    switch (this.time_span) {
      case 'week':
        this.entity.subscription_end = new Date(this.entity.subscription_start.getTime() + (1000 * 60 * 60 * 24 * 7) );
        break;
      case 'month':
        this.entity.subscription_end = new Date(this.entity.subscription_start.getTime() + (1000 * 60 * 60 * 24 * 32) );
        break;
      case 'ninety_days':
        this.entity.subscription_end = new Date(this.entity.subscription_start.getTime() + (1000 * 60 * 60 * 24 * 60) );
        break;
      case 'year':
        this.entity.subscription_end = new Date(this.entity.subscription_start.getTime() + (1000 * 60 * 60 * 24 * 365) );
        break;
      case 'two_years':
        this.entity.subscription_end = new Date(this.entity.subscription_start.getTime() + (1000 * 60 * 60 * 24 * 365 * 2) );
        break;
      case 'five_years':
        this.entity.subscription_end = new Date(this.entity.subscription_start.getTime() + (1000 * 60 * 60 * 24 * 365 * 5) );
        break;
    }
    this.entity.meta.hasChanges = true;
  }

  editEntity() {
    this.entity.meta.hasChanges = true;
  }


  editPeriod(e, modal) {
    let to = this.entity.subscription_end;
    let from = this.entity.subscription_start
    let time_range = new DateTimeIntervalEntity(from, to);
    let dialog = new ModalWidget(`Pull Range`, ModalWidgetEnum.MODAL_WIDGET__CONFIRM, time_range, {edit: true}, 'shadowbox', time_range.overrides());
    this.showModal(dialog, modal, false, 'md-modal');
    time_range.promise.then((r) => {
      this.entity.subscription_end = r.to;
      this.entity.subscription_start = r.from;
      this.entity.meta.hasChanges = true;
    }).catch(() => {
      // swallow
    });
  }


  showModal(current, modal, clickOut = false, customClass="") {
    this.currentModal = current;
    if (!clickOut) {
      let config: ModalOptions = {
        backdrop : 'static',
        keyboard : false,
        class: customClass
      };
      this.currentModal.modalRef = this.modalService.show(modal, config);
    } else {
      let config: ModalOptions = {
        class: customClass
      };
      this.currentModal.modalRef = this.modalService.show(modal, config);
    }
  }

  delete(e) {
    e.preventDefault();
    if (confirm("Delete Subscription Record?")) {
      this.entity.delete().then(() => {
        this.toasterService.pop('info', 'Delete Subscription', `Record Removed`);
        let a = new UserWidgetEventStruct();
        a.event_body = this.entity;
        a.event_type = 'delete';
        a.event_widget_type = WidgetEnum.EMBED_WIDGET__USER_EXTENDED;
        this.widgetEvent.emit(a);
      }).catch(() => {
        this.toasterService.pop('warning', 'Delete Subscription', `Request Failed`);
      });
    }
  }

  save(e) {
    e.preventDefault();
    this.entity.update().then(r => {
      this.toasterService.pop('success',  'Success', `Subscription Updated`);
      this.entity.meta.hasChanges = false;
    }).catch(r => {
      this.toasterService.pop('warning', 'Error', `Subscription Update Failed`);
    });
  }

  ngOnInit(): void {
  }
}
