
import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from '../lacrosse.entity';
import { FirebaseAuthService }            from '../../noizu/services/firebase-auth.service';
import { DeviceDefinitionRepo }           from '../../repos/device/definition.repo';
import { DeviceDefinitionCategory }       from '../../structs/device/definition/category';
import { DeviceDefinitionFieldEntry }     from '../../structs/device/definition/field-entry';

export class GatewayLookupTableEntryEntity extends LacrosseEntity {
  public _kind = "gateway-lookup";

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
  } // end constructor

} // end Package
