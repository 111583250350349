import {Component, Input, Inject, ChangeDetectorRef, NgZone, OnInit, Output, EventEmitter} from '@angular/core';
import {DeviceDefinitionRepo, DeviceRepo} from '../../../../repos';
import {ToasterService} from 'angular2-toaster';
import {AuthService} from '../../../../services/auth.service';
import {SimulatorWidget} from '../../../../entities/device/simulator-widget';
import {DeviceDefinitionEntity, FieldSimulatorWidget} from '../../../../entities/device';
// import {forEach} from '@angular/router/src/utils/collection';
import {FirmwareWidgetEventStruct} from '../../firmware';
import {DeviceWidgetEventStruct} from '../../device/device-widget-event.struct';
import {LogicalDeviceWidgetEventStruct} from './logical-device-widget-event.struct';

@Component({
  selector: 'device-field-simulator-widget',
  template: `
<div class="device-field-simulator-widget" *ngIf="!entity">
  <widget-spinner></widget-spinner>
</div>
<div class="device-field-simulator-widget" *ngIf="entity?.subject">

  <div class="row">
    <div class="col-2 text-right">{{entity.subject.identifier}}</div>
    <div class="col text-center">

      <div *ngIf="!entity.subject.field?.is_derived_type">
        <div class="alert alert-success">

          <ng-container *ngIf="entity.subject.field.meta.include_previous">
            <input
              type="number"
              min="{{rangeLow}}"
              max="{{rangeHigh}}"
              [(ngModel)]="entity.previous_value"
              (ngModelChange)="changeValue($event)"
              name="device"
              size="10"/>
            <span> - </span>
            <input
              type="number"
              min="{{rangeLow}}"
              max="{{rangeHigh}}"
              [(ngModel)]="entity.value"
              (ngModelChange)="changeValue($event)"
              name="device"
              size="10"/>
          </ng-container>

          <ng-container *ngIf="!entity.subject.field.meta.include_previous">
            <input
              type="number"
              min="{{rangeLow}}"
              max="{{rangeHigh}}"
              [(ngModel)]="entity.value"
              (ngModelChange)="changeValue($event)"
              name="device"
              size="10"/>
          </ng-container>





        </div>
      </div>
      <div *ngIf="entity.subject.field?.is_derived_type">
        <div class="alert alert-info">[Derived Field]</div>
      </div>

    </div>
    <div class="col text-left"> <b>Range</b>: [{{rangeLow}}, {{rangeHigh}}], <b>Precision</b>: {{entity.subject.field?.precision}}, <b>Unit</b>: {{entity.subject.field?.unit}}</div>
  </div>

</div>
`
})
export class DeviceFieldSimulatorWidgetComponent implements OnInit{
  @Input() entity: FieldSimulatorWidget | null = null;
  @Input() templateHint: string = null;
  @Input() options: any = null;
  @Input() layout = 'standard';
  @Output() widgetEvent = new EventEmitter<LogicalDeviceWidgetEventStruct>();
  forwardEvent(e, widgetEvent: LogicalDeviceWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }

  public rangeLow: number = 0;
  public rangeHigh: number = 0;

  constructor(
  ) {
  }

  changeValue(e) {
  }

  ngOnInit(): void {
    this.rangeLow = this.entity.subject.field.rangeLow || 0;
    this.rangeHigh = this.entity.subject.field.rangeHigh || 0;
  }

}
