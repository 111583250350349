
            <div class="row">
              <div class="col-12">
                <div class="card border-primary">
                  <div class="card-header">
                  <div class="row">
                    <div class="col-10">Request Log|
                      <i class="fa fa-lg fa-2x fa-calendar text-success" (click)="customRange($event, pickerModal)"></i>
                    </div>
                    <div class="col-2">
                        <label class="switch switch-3d switch-primary float-right" *ngIf="load_status">
                          <input (change)="toggleEnabled($event)" class="switch-input" type="checkbox" [(ngModel)]="toggle" name="toggleEnabled">
                          <span class="switch-slider"></span>
                        </label>
                    </div>
                  </div>

                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12 text-center">


                        <div class="row mb-2">
                          <div class="col">
                            <input (change)="filterRecords($event)" [(ngModel)]="newFilter" [width]="64" type="text" placeholder="Filter" class="col-6 form-control-lg" />
                            <div class="alert alert-warning" *ngIf="entity?.filterOptions()">
                              Filter Options: {{entity.filterOptions()}}
                            </div>
                          </div>
                        </div>

                        <div class="row mb-2">
                          <div class="col text-center">
                            <button *ngIf="!last_to" (click)="fetchLogs($event)" class="btn btn-lg btn-warning" type="submit"><i class="fa" [ngClass]="{'fa-dot-circle-o': !refreshing,  'fa-spinner' : refreshing}" ></i>  Query</button>
                            <button *ngIf="last_to" (click)="fetchNewerLogs($event)" class="btn btn-lg btn-warning" type="submit"><i class="fa" [ngClass]="{'fa-arrow-circle-up': !refreshing,  'fa-spinner' : refreshing}" ></i> Newer</button>
                          </div>
                        </div>


                        <div class="row" *ngIf="error_code != 0">
                          <div class="col">
                            <div class="alert alert-info">{{error_message}}</div>
                          </div>
                        </div>

                        <div class="row" *ngIf="entity?.loaded && entity.entries.length == 0 && error_code == 0">
                          <div class="col">
                            <div class="alert alert-info">No records in selected range {{this.last_from| date:'medium'}} - {{this.last_to| date:'medium'}}</div>
                          </div>
                        </div>

                        <div *ngIf="entity?.loaded">
                          <div *ngFor="let log of entity.entries; let i = index;">
                            <div class="row" *ngIf="log.filter(newFilter)">
                              <div class="col-12 text-left">
                                <log-sub-type-embed
                                  [entity]="log"
                                  [options]="options"
                                  [layout]="layout">
                                </log-sub-type-embed>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mt-2" *ngIf="last_from">
                          <div class="col text-center">
                            <button (click)="fetchOlderLogs($event)" class="btn btn-lg btn-warning" type="submit"><i class="fa" [ngClass]="{'fa-arrow-circle-down': !refreshing,  'fa-spinner' : refreshing}" ></i> More </button>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>




            <ng-template #pickerModal>
              <modal-widget *ngIf="currentModal" (widgetEvent)="eventHandler($event, 'pop-up', pickerModal)" [entity]="currentModal" [layout]="'shadowbox'" [options]="{}"></modal-widget>
            </ng-template>

    