import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {LogicalWidgetEventStruct} from './logical-widget-event.struct';
import {DateTimeIntervalEntity, DateTimeLogicalWidget, ModalWidget} from '../../../entities';
import {ModalWidgetEnum} from '../../../enums';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap/modal';
import {NgbDate, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
//import { FormGroup, FormControl, Validators } from "@angular/forms";

@Component({
  selector: 'date-time-range-picker-logical-widget',
  template: `
    <div class="row justify-content-center" *ngIf="entity">
      <div class="col-auto">
        <div class="row">
          <div class="col text-center">
            <strong>From</strong>
          </div>
        </div>
        <div class="row">
          <div class="col text-left">
            <ngb-datepicker #fdp [(ngModel)]="entity.select_from_date"></ngb-datepicker>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-auto text-left">
            <span><ngb-timepicker #ftp [meridian]="true" [(ngModel)]="entity.select_from_time"></ngb-timepicker></span>
          </div>
        </div>
      </div>
      <div class="col-auto">
        <div class="row">
          <div class="col text-center">
            <strong>To</strong>
          </div>
        </div>
        <div class="row">
          <div class="col text-left">
            <ngb-datepicker #tdp [(ngModel)]="entity.select_to_date"></ngb-datepicker>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-auto text-left">
            <span><ngb-timepicker #ttp [meridian]="true" [(ngModel)]="entity.select_to_time"></ngb-timepicker></span>
          </div>
        </div>
      </div>
    </div>
  `
})
export class DateTimeRangePickerLogicalWidgetComponent implements OnInit{
  @Input() entity: DateTimeIntervalEntity = null;
  @Input() templateHint: string = null;
  @Input() options: any = null;
  @Input() layout = 'standard';
  @Output() widgetEvent = new EventEmitter<LogicalWidgetEventStruct>();

  constructor() {
  }

  forwardEvent(e, widgetEvent: LogicalWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }

  sendOutput() {
    let from = null;
    let to = null;
    let event = new LogicalWidgetEventStruct()
    event.event_widget_type = this.entity.widget_type();
    event.event_logical_widget_type = this.entity.logical_widget_type();
    event.event_type = "time_range_selection_made"
    event.event_body = {from: from, to: to}
    this.widgetEvent.emit(event);
  }

  ngOnInit() {
  }
}
