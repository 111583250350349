import {HttpClient} from '@angular/common/http';
import { FirebaseAuthService }            from '../../../noizu/services/firebase-auth.service';
import { ElixirEntity } from '../../elixir.entity';
import {UserExtendedWidgetEnum, WidgetEnum} from '../../../enums';

export class UserExtendedEntity extends ElixirEntity {
  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
  } // end constructor

  widget_type(): any {
    return WidgetEnum.EMBED_WIDGET__USER_EXTENDED;
  }

  user_extended_widget_type(): any {
    return UserExtendedWidgetEnum.USER_EXTENDED_WIDGET__GENERIC;
  }

} // end Package
