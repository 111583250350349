<page-header [section]="'overview-sys-health'">
  <page-title>Overview</page-title>
  <page-blurb>Display the status summary of the nodes, services, devices, monitors and service logs in charts</page-blurb>
</page-header>


<div class="row" *ngIf="show_maintenance">
  <div class="col-auto">
    <button (click)="clear_maintenance($event)" class="btn btn-danger">Clear Maintenance Mode</button>
  </div>
  <div class="col-auto">
    <button (click)="schedule_maintenance($event, scheduleMaintenance)" class="btn btn-warning">Schedule Maintenance Mode</button>
  </div>
</div>



<div class="row">
  <div class="col-12 my-3">
    <div class="row col-12">
      <div class="col-12" class="scrollCanvasWrapper">
        <div class="scrollCanvas">
          <plotly-plot [data]="graph.data" [layout]="graph.layout"
                       [useResizeHandler]="true" [style]="{position: 'relative', width: '100%', height: '100%'}">
          </plotly-plot>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="row">
  <div class="col-12 my-3">
    <div class="row col-12">
      <div class="col-12" class="scrollCanvasWrapper">
        <div class="scrollCanvas">
          <plotly-plot [data]="graph2.data" [layout]="graph2.layout"
                       [useResizeHandler]="true" [style]="{position: 'relative', width: '100%', height: '100%'}">
          </plotly-plot>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="row">
  <div class="col-12 my-3">
    <div class="row col-12">
      <div class="col-12" class="scrollCanvasWrapper">
        <div class="scrollCanvas">
          <plotly-plot [data]="graph3.data" [layout]="graph3.layout"
                       [useResizeHandler]="true" [style]="{position: 'relative', width: '100%', height: '100%'}">
          </plotly-plot>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="row">
  <div class="col">
    public user_registration_report: any = null;
    <pre>{{user_registration_report | json}}</pre>
  </div>
</div>
<!--

<div class="row">
  <div class="col">
    public user_registration_report_daily: any = null;
    <pre>{{user_registration_report_daily | json}}</pre>
  </div>
</div>


<div class="row">
  <div class="col">
    public user_registration_report_hourly: any = null;
    <pre>{{user_registration_report_hourly | json}}</pre>
  </div>
</div>


<div class="row">
  <div class="col">
    public sensor_daily_report: any = null;
    <pre>{{sensor_daily_report | json}}</pre>
  </div>
</div>


<div class="row">
  <div class="col">
    public sensor_hourly_report: any = null;
    <pre>{{sensor_hourly_report | json}}</pre>
  </div>
</div>


<div class="row">
  <div class="col">
    public metric_report: any;
    <pre>{{metric_report | json}}</pre>
  </div>
</div>
-->


<div class="row">
  <div class="col mh-100">

    <div class="row pb-4">
      <div class="col">
        <h1>Nodes</h1>

        <ng-select
          [(ngModel)]="selected"
          bindLabel="label"
          bindValue="value"
          [items]="nodes"
        ></ng-select>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <ng-container *ngIf="selected">
          <widget [entity]="node_reports[selected]" [options]="{}" [layout]="'card'"></widget>
        </ng-container>
      </div>
    </div>

  </div>
</div>


<div class="row">
  <div class="col">
    public system_status_report: any = null;
    <pre>{{system_status_report | json}}</pre>
  </div>
</div>

<ng-template #scheduleMaintenance>
  <modal-header>
    <h4 class="modal-title pull-left">Schedule Maintenance Mode</h4>
  </modal-header>
  <div class="modal-body">
    <div class="row">
      <div class="col-auto">
        Reason:
      </div>
      <div class="col-auto">
        <input type="text" [(ngModel)]="maintenance_reason"/>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="confirm_maintenance($event, maintenance_reason)" class="btn btn-info">Submit</button>
  </div>
</ng-template>
