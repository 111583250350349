import {Component, Input, Output, EventEmitter} from '@angular/core';
import {EntityLogEntity} from '../../../entities/log/entity-log.entity';
import {WidgetBase} from '../widget-base';
import {LogWidgetEventStruct} from './log-widget-event.struct';
import {LogWidgetEnum} from '../../../enums';
import {UserEntityLogEntity} from '../../../entities';
import {LogWidgetComponent} from './log-widget.component';

@Component({
  selector: 'user-log-widget',
  template: `
    <generic-log-widget (widgetEvent)="forward($event)" [entity]="entity" [options]="options" [layout]="layout"></generic-log-widget>
    `
})
export class UserLogWidgetComponent extends LogWidgetComponent{
  @Input() entity: UserEntityLogEntity = null;
  @Input() options: any = {};
  @Input() layout: string = null;
  @Output() widgetEvent = new EventEmitter<LogWidgetEventStruct>();
  forward(widgetEvent: LogWidgetEventStruct) {
    this.widgetEvent.emit(widgetEvent);
  }
  forwardEvent(e, widgetEvent: LogWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }
  public LogWidgetEnum = LogWidgetEnum;
  public expand = false;
  public expandRaw = false;

  constructor() {
    super();

  }
}
