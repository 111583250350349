
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { DomainObjectRepo } from '../noizu/domain-object-repo';
import { FirebaseAuthService }        from '../noizu/services/firebase-auth.service';
import { ElixirRepo }      from './elixir.repo';
import { AuditHistoryEntryEntity }      from '../entities/audit-history-entry.entity';
import {ElixirEntity} from '../entities';
import {NoizuStruct} from '../noizu/structs';

@Injectable()
export class AuditHistoryRepo extends ElixirRepo {
  public _kind = "audit-history";

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  entity(record) : NoizuStruct {
    return new AuditHistoryEntryEntity(this.client, this.auth, record)
  }
}
