
import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from '../lacrosse.entity';
import { FirebaseAuthService }            from '../../noizu/services/firebase-auth.service';
import {FirmwareWidgetEnum, WidgetEnum} from '../../enums';
import {AttributeFieldType} from '../device';
//import { DeviceDefinitionRepo }           from '../../repos/device/definition.repo';

export class FirmwareDefinitionEntity extends LacrosseEntity {
  public _kind = "firmware-definitions";
  public _singular = "firmware-definition";
  public notes: String;
  public firmwareVersion: String;
  public checksum: String;
  public size: Number;
  public fileA: String;
  public fileB: String;
  public series: String;
  public manufacturerId: number;
  public _sref_module = "firmware"
  public pending = false;
  public attributes:Object = null;
  public _attributeList: any = null;

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    this.strip_from_json["pending"] = 1;
    this.strip_from_json["_attributeList"] = 1;
    this.strip_from_json["attributeList"] = 1;
    // double call to properly set fields with default values.
    this.refresh(json);
  } // end constructor

  // Sets the fields, used above, and to reset the values when canceling an edit
  refresh(data) {
    this.checksum = data.checksum;
    this.firmwareVersion = data.firmwareVersion;
    this.size = data.size;
    this.fileA = data.fileA;
    this.fileB = data.fileB;
    this.series = data.series;
    this.manufacturerId = parseInt(data.manufacturerId);
    this.notes = data.notes;
    this.pending = false;


    this.attributes = {};
    if (data["attributes"]) {
      for (const key in data["attributes"]) {
        let value =  data["attributes"][key];
        this.attributes[key] = parseInt(value);
      }
    }
    console.log("Firmware Attributes", this.attributes);

    return super.refresh(data);
  }


  /**
   * @todo attribute handling logic is copy pasta and should be wrapped into it's own object for easier re-use  - kebrings
   * @param key
   */
  public attributeType(key) {
    return AttributeFieldType.NumericField;
  }

  updateAttribute(attribute) {
    this.meta.hasChanges = true;
    if (attribute.originalKey != attribute.key) {
      delete this.attributes[attribute.originalKey];
    }

    this.attributes[attribute.key] = attribute.value;
    this.attributeList = null;
    console.log("Update ", attribute);
  }

  addAttribute(attribute) {
    this.meta.hasChanges = true;
    this.attributes[attribute.key] = attribute.value;
    this.attributeList = null;
    console.log("Add ", attribute);
  }

  dropAttribute(attribute) {
    this.meta.hasChanges = true;
    delete this.attributes[attribute.key]
    this.attributeList = null;
    console.log("drop", attribute);
  }


  set attributeList(v) {
    this._attributeList = v;
  }

  get attributeList() {
    if (this._attributeList) return this._attributeList;
    this._attributeList = [];
    if (this.attributes) {
      for (const key in this.attributes) {
        let value =  this.attributes[key];
        this._attributeList.push({key: key, originalKey: key, value: value, type: this.attributeType(key)});
      }
      this._attributeList = this._attributeList.sort((a,b) => a.key < b.key);
    }
    return this._attributeList;
  }

  widget_type() {
    return WidgetEnum.EMBED_WIDGET__FIRMWARE;
  }

  firmware_widget_type() {
    return FirmwareWidgetEnum.FIRMWARE_WIDGET__DEFINITION
  }
} // end Package
