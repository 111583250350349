import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from '../lacrosse.entity';
import { FirebaseAuthService }            from '../../noizu/services/firebase-auth.service';
import { DeviceDefinitionRepo }           from '../../repos/device/definition.repo';
import { DeviceDefinitionCategory }       from '../../structs/device/definition/category';
import { DeviceDefinitionFieldEntry }     from '../../structs/device/definition/field-entry';
import {GatewayCycleOverrideEntity} from './cycle-override.entity';
import {GatewayScriptOverrideEntity} from './script-override.entity';
import {GatewayFirmwareReportEntity} from './firmeware-report.entity';
import {GatewayMcuReportEntity} from './mcu-report.entity';

export class GatewayWifiAndMcuFirmwareReport extends LacrosseEntity {
  public _kind = "wifi-and-mcu-report";
  public _singular = "wifi-and-mcu-report";


  public wifi: GatewayFirmwareReportEntity;
  public mcu: GatewayMcuReportEntity;

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    this.wifi = new GatewayFirmwareReportEntity(client, auth, json["wifi"])
    this.mcu = new GatewayMcuReportEntity(client, auth, json["mcu"])
  } // end constructor


  targetStage() {
    return this.wifi.targetStage();
  }


  targetProd() {
    return this.wifi.targetProd();
  }

} // end Package
