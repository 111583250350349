import {Component, Input, Inject, Output, EventEmitter} from '@angular/core';
import {NoizuStruct} from '../../../noizu/structs/noizu-struct';
import {FirmwareWidgetEnum, ModalWidgetEnum, WidgetEnum} from '../../../enums/widget';
import {FirmwareLinkV2Entity, FirmwareReleaseEntity, ModalWidget} from '../../../entities/';
import {ToasterService} from 'angular2-toaster';
import {WidgetEventStruct} from '../../widget-event.struct';
import {FirmwareWidgetEventStruct} from './firmware-widget-event.struct';
import {FirmwareDefinitionWidgetComponent} from './firmware-definition-widget.component';

@Component({
  selector: 'firmware-generic-widget',
  template: `
    <div class="firmware-generic-widget row mb-5">
      <div class="col text-center border-bottom">
        <span class="display-4">{{ FirmwareWidgetEnum[entity.firmware_widget_type()] }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <pre>{{entity.toJson()| json}}</pre>
      </div>
    </div>
  `
})
export class FirmwareGenericWidgetComponent {
  @Input() entity: FirmwareLinkV2Entity | null = null;
  @Input() options: any = null;
  @Input() layout = 'standard';

  @Output() widgetEvent = new EventEmitter<FirmwareWidgetEventStruct>();
  forwardEvent(e, widgetEvent: FirmwareWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }

  FirmwareWidgetEnum = FirmwareWidgetEnum

  constructor(public toasterService: ToasterService) {

  }
}
