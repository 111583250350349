import {HttpClient} from '@angular/common/http';
import { FirebaseAuthService }            from '../../noizu/services/firebase-auth.service';
import {ElixirEntity} from '../elixir.entity';
import {WidgetEnum} from '../../enums';

export class SubscriptionDefinitionEntity extends ElixirEntity {
  public _kind: string = "subscription-definitions";
  public _singular: string = "subscription-definition";
  public _sref_module = 'subscription';

  public code: string | null = null;
  public description: string | null = null;
  public features: any = {};
  public name: string | null = null;

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    this.code =  json['code'];
    this.description = json['description'];
    this.features = json['features'];
    this.name = json['name'];
    this.raw = null;
  } // end constructor

  widget_type(): any {
    return WidgetEnum.EMBED_WIDGET__SUBSCRIPTION_DEFINITION_ENTITY;
  }
} // end Package
