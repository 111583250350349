import {HttpClient} from '@angular/common/http';
import { FirebaseAuthService }            from '../../noizu/services/firebase-auth.service';
import {ReportWidgetEnum, WidgetEnum} from '../../enums';
import {ReportEntity} from './report.entity';



export class WorkerReportEntityTotal {
  public workers: number;
  public supervisors: number;
  public specs: number;
  public online: number;
  public nodes: number;
  public active: number;

  public constructor(json) {
    this.workers = json['workers'];
    this.supervisors = json['supervisors'];
    this.specs = json['specs'];
    this.online = json['online'];
    this.active = json['active'];
    this.nodes = json['nodes'];
  }
}

export class WorkerReportEntityTally {
  public node: string;
  public workers: number;
  public supervisors: number;
  public specs: number;
  public response_time: number;
  public online: boolean;
  public active: number;

  public constructor(json) {
    this.node = json['node'];
    this.workers = json['workers'];
    this.supervisors = json['supervisors'];
    this.specs = json['specs'];
    this.response_time = json['response_time'];
    this.online = json['online'];
    this.active = json['active'];
  }
}

export class WorkerReportEntity extends ReportEntity {
  public _kind = '';
  public type: string;
  public total: WorkerReportEntityTotal;
  public nodes: any;


  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    this.refresh(json);
  } // end constructor


  public refresh(json) {
    super.refresh(json);
    this.type = json['type'];
    this.total = new WorkerReportEntityTotal(json['total'] || {});
    this.nodes = {};
    let nodes = json['nodes'] || {}
    for(let k in nodes) {
      let n = nodes[k];
      n['node'] = k;
      this.nodes[k] = new WorkerReportEntityTally(n);
    }
    return this;
  }

  widget_report_type(): any {
    return ReportWidgetEnum.REPORT_WIDGET__WORKER_REPORT;
  }

} // end Package
