import {Component, Input, Inject, Output, OnInit, EventEmitter} from '@angular/core';
import {Observable} from 'rxjs';
import {LogicalAlertWidgetEventStruct} from './logical-alert-widget-event.struct';
import {AlertFacadeSelectorLogicalWidget} from '../../../../entities';
import {WidgetEnum_Alert_Facade} from '../../../../enums';

@Component({
  selector: 'alert-facade-selector-logical-widget',
  template: `
    <ng-container *ngIf="!entity">
      <div class="row"><div class="col">
        <new-spinner [size]="'sm'"></new-spinner>
      </div></div>
    </ng-container>
    <ng-container class="alert-facade-selector-logical-widget" *ngIf="entity">
      <div *ngIf="options?.edit">
        <div *ngIf="valueSubscriber | async as values; else loading">
          <ng-select
            placeholder="Alert Template"
            [items]="values"
            bindLabel="label"
            bindValue="value"
            [multiple]="false"
            [(ngModel)]="entity.selected"
            (ngModelChange)="updateOutput()"
          ></ng-select>
        </div>

        <ng-template #loading>
          <div class="row"><div class="col">
            <new-spinner [size]="'sm'"></new-spinner>
          </div></div>
        </ng-template>

      </div>
      <div *ngIf="!options?.edit" class="align-middle text-center">
        {{facade_types[entity.selected]}}
      </div>
    </ng-container>
  `
})
export class AlertFacadeSelectorLogicalWidgetComponent implements OnInit{
  @Input() entity: AlertFacadeSelectorLogicalWidget | null = null;
  @Input() templateHint: string = null;
  @Input() options: any = null;
  @Input() layout = 'standard';

  @Output() widgetEvent = new EventEmitter<LogicalAlertWidgetEventStruct>();
  forwardEvent(e, widgetEvent: LogicalAlertWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }
  public facade_types: any;

  updateOutput() {
    let event = new LogicalAlertWidgetEventStruct()
    event.event_widget_type = this.entity.widget_type();
    event.event_logical_widget_type = this.entity.logical_widget_type();
    event.event_alert_widget_type = this.entity.alert_widget_type();
    event.event_type = "alert_facade_selection_made"
    event.event_body = {sref: this.entity.selected}
    this.widgetEvent.emit(event);
  }



  public valueSubscriber: any  = null;

  constructor() {
    this.facade_types = {};
    this.facade_types['ALERT_FACADE_WIDGET__CONTACT'] = 'Contact';
    this.facade_types['ALERT_FACADE_WIDGET__HUMIDITY'] = 'Humidity';
    this.facade_types['ALERT_FACADE_WIDGET__LIGHTNING'] = 'Lightning';
    this.facade_types['ALERT_FACADE_WIDGET__MOTION'] = 'Motion';
    this.facade_types['ALERT_FACADE_WIDGET__ON_OFF'] = 'On Off';
    this.facade_types['ALERT_FACADE_WIDGET__PROBE'] = 'Probe';
    this.facade_types['ALERT_FACADE_WIDGET__RAINFALL'] = 'Rainfall';
    this.facade_types['ALERT_FACADE_WIDGET__TEMPERATURE'] = 'Temperature';
    this.facade_types['ALERT_FACADE_WIDGET__THERMOMETER'] = 'Thermometer';
    this.facade_types['ALERT_FACADE_WIDGET__WET_DRY'] = 'Wet/Dry';
    this.facade_types['ALERT_FACADE_WIDGET__WIND'] = 'Wind';
    this.facade_types['ALERT_FACADE_WIDGET__DEFAULT'] = 'Raw';
  }


  ngOnInit() {
    this.entity.selected = this.entity.subject
    this.valueSubscriber = new Observable<any>(observer => {
      const response = [];

      response.push({value: "ALERT_FACADE_WIDGET__CONTACT", label: "Contact"})
      response.push({value: "ALERT_FACADE_WIDGET__HUMIDITY", label: "Humidity"})
      response.push({value: "ALERT_FACADE_WIDGET__LIGHTNING", label: "Lightning"})
      response.push({value: "ALERT_FACADE_WIDGET__MOTION", label: "Motion"})
      response.push({value: "ALERT_FACADE_WIDGET__ON_OFF", label: "On Off"})
      response.push({value: "ALERT_FACADE_WIDGET__PROBE", label: "Probe"})
      response.push({value: "ALERT_FACADE_WIDGET__RAINFALL", label: "Rainfall"})
      response.push({value: "ALERT_FACADE_WIDGET__TEMPERATURE", label: "Temperature"})
      response.push({value: "ALERT_FACADE_WIDGET__THERMOMETER", label: "Thermometer"})
      response.push({value: "ALERT_FACADE_WIDGET__WET_DRY", label: "Wet/Dry"})
      response.push({value: "ALERT_FACADE_WIDGET__WIND", label: "Wind"})
      response.push({value: "ALERT_FACADE_WIDGET__DEFAULT", label: "Raw Format"})

      observer.next(response)
    });
  }

}




