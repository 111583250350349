import { Component, Inject, Input, OnInit} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { PermissionsEntity } from '../../entities/permissions.entity';
import { PermissionsRepo } from '../../repos/permissions.repo';
import { PermissionsDD } from '../../enums/drop-down-data';

@Component({
  selector: 'permissions-entry',
  templateUrl: './show.component.html',
})
export class PermissionsEntryComponent implements OnInit{
  @Input() initVars = {init:false,type:"g",id:null};
  @Input() permissions = null;
  @Input() groupId = null;
  public errors = {"show":false,"msg":""};
  public saving = false;
  public newPermissions = null;
  public subscription;
  public permissionSelect;
  public permissionsList = [];
  public editEntry = false;
  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public permRepo: PermissionsRepo) {
      this.permissionsList = PermissionsDD.list;
    }

  ngOnInit(): void {
    if (this.initVars.init === true && this.permissions == null) {
      this.permRepo.getGroupPermissionsPromise(this.initVars.id).then((res:PermissionsEntity) => {
        this.permissions = res;
      });
    }
  }

  createPermission(e) {
    e.preventDefault();
    this.permissions = this.permRepo.entity({"identifier":"new","permissionType":8193, "permissions":{},"handle":"Permissions Entry","groupId":this.groupId});
    this.newPermissions = this.permissions.permissions;
    this.editEntry = true;
  }

  deletePermission(e, perm) {
    e.preventDefault();
    let temp = JSON.parse(JSON.stringify(this.newPermissions));
    delete temp[perm];
    this.newPermissions = temp;
  }

  addPermissions(e) {
    e.preventDefault();
    if (this.permissionSelect) {
      console.log(this.newPermissions);
      let temp = null;
      if (this.newPermissions) {
        temp = JSON.parse(JSON.stringify(this.newPermissions));
      } else {
        temp = {}
      }
      temp[this.permissionSelect] = 1;
      this.newPermissions = temp;
    }
  }

  update(e) {
    e.preventDefault();
    this.saving = true;
    this.editEntry = false;
    this.permissions.updatePermissions(this.newPermissions).then((res:any) => {
      console.log(res);
      this.saving = false;
    }).catch((err:any) => {
      err = err.details;
      console.log(err);
      this.errors.show = true;
      this.errors.msg = err.error && err.error.message ? err.error.message : "An error occurred and changes were likely not saved";;
      this.saving = false;
    });
  }

  edit(e) {
    e.preventDefault();
    this.newPermissions = this.permissions.permissions;
    this.editEntry = true;
  }

  cancel(e) {
    e.preventDefault();
    this.editEntry = false;
  }

}
