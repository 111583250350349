import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToasterService} from 'angular2-toaster';
import {WidgetBase} from '../widget-base';
import {EntityList} from '../../../noizu/structs';
import {WidgetEventStruct} from '../../widget-event.struct';
import {AppengineWidgetSetComponent} from '../appengine-set-widget';
import {EntitySetWidgetEventStruct} from './entity-set-widget-event.struct';
import {GenericWidgetSetComponent} from '../generic-set-widget';

@Component({
  selector: 'entity-set-header-widget',
  template: `

    <div class="entity-set-header-widget row mb-4">
      <div class="col-lg-6 col-12">
        <div class="btn btn-info btn-pill mr-4" style="vertical-align: unset" (click)="changeView($event)">
          <ng-container *ngIf="!entity.tableView">Card View</ng-container>
          <ng-container *ngIf="entity.tableView">Table View</ng-container>
        </div>
        <ng-container *ngIf="!entity.tableView" >
          <ng-select class="form-control-lg ng-select-inline mr-4"
                     placeholder="Columns"
                     bindLabel="label"
                     bindValue="value"
                     [items]="entity.columnOptions"
                     [multiple]="false"
                     [(ngModel)]="entity.columns"
          ></ng-select>
        </ng-container>
        <input (change)="filterRecords($event)" [(ngModel)]="newFilter" [width]="64" type="text" placeholder="Filter" class="col-6 form-control-lg" />
        <div class="alert alert-warning" *ngIf="entity?.entity?.repo?.filterOptions()">
          Filter Options: {{entity.entity.repo.filterOptions()}}
        </div>
      </div>
    </div>

    <div class="entity-set-header-widget row mb-4">
      <div class="col">
        <div *ngIf="!entity.has_entries" class="alert m-0 alert-warning">No Entries<ng-container *ngIf="entity.filter"> For Filter [{{entity.filter}}]  <i class="text-danger fa fa-minus-circle" (click)="clearFilter($event)"> </i>, Not Shown {{entity.not_shown}}</ng-container></div>
        <div *ngIf="entity.has_entries" class="alert m-0 alert-info">
          <ng-container *ngIf="!entity.filter">Records {{entity.records}}</ng-container>
          <ng-container *ngIf="entity.filter">Filter [{{entity.filter}}] <i class="text-danger fa fa-minus-circle" (click)="clearFilter($event)"> </i>, Matches {{entity.shown}}, Hidden {{entity.not_shown}}</ng-container>
        </div>
      </div>
      <div class="col">
        <div *ngIf="entity.entity.queryable" class="btn btn-primary btn-lg" (click)="next($event)">More Results
          <new-spinner *ngIf="entity.fetching" [inline]="true" [size]="'sm'"></new-spinner>
        </div>
        <button *ngIf="options['edit']" (click)="add($event)" class="btn btn-lg ml-4 btn-warning" type="submit"><i class="fa fa-plus-circle"></i> New</button>
      </div>
    </div>


    <div class="entity-set-header-widget row mb-4" *ngIf="entity.pages > 1">
      <div class="col">
        <div class="alert alert-success">
          Pagination: <input (ngModelChange)="updatePagination()" type="number" min="5" max="1000" [(ngModel)]="entity.dpp" placeholder="Records Per Page"/> (Results Per Page)

          <ng-container *ngIf="entity.pages > 15">
            <ul class="pagination">
              <ng-container *ngFor="let p of entity.pagination; let i = index;">
                <li class="page-item" *ngIf="i == 0 || i == (entity.pages - 1)  || (i % 100 == 0) || ( (entity.page > i - 100 && entity.page < i + 100) &&  (i % 10 == 0)) ||  (entity.page > i - 5 && entity.page < i + 5)" [ngClass]="{'active': i == entity.page}">
                  <a (click)="toPage($event, p)" class="page-link">
                    <span *ngIf="i == 0">First</span>
                    <span *ngIf="i == (entity.pages - 1)">Last</span>
                    <span *ngIf="i > 0 && i < (entity.pages - 1)">{{i}}</span>
                  </a>
                </li>
              </ng-container>
            </ul>
          </ng-container>
          <ng-container *ngIf="entity.pages <= 15">
            <ul class="pagination">
              <li class="page-item" *ngFor="let p of entity.pagination; let i = index;" [ngClass]="{'active': i == entity.page}">
                <a (click)="toPage($event, p)" class="page-link">{{i}}</a>
              </li>
            </ul>
          </ng-container>
        </div>
      </div>
    </div>


    <!--










-->

  `
})
export class EntitySetHeaderWidgetComponent extends WidgetBase implements OnInit {
  public editMode = false;
  @Input() layout: string = null;
  @Input() entity: GenericWidgetSetComponent;
  @Input() options: any;
  @Output() widgetEvent = new EventEmitter<EntitySetWidgetEventStruct>();

  public newFilter = null;

  changeView(event) {
    this.forwardEvent(event, new EntitySetWidgetEventStruct('change-view'));
  }

  next(event) {
    this.forwardEvent(event, new EntitySetWidgetEventStruct('next'));
  }


  add(event) {
    this.forwardEvent(event, new EntitySetWidgetEventStruct('add-modal'));
  }


  updatePagination(event = null) {
    this.forwardEvent(event, new EntitySetWidgetEventStruct('add-modal'));
  }

  toPage(event, page) {
    this.forwardEvent(event, new EntitySetWidgetEventStruct('to-page', page['page']));
  }

  clearFilter(event) {
    this.forwardEvent(event, new EntitySetWidgetEventStruct('clear-filter'));
  }


  filterRecords(event) {
    this.forwardEvent(event, new EntitySetWidgetEventStruct('filter', this.newFilter));
  }

  forwardEvent(e, widgetEvent: EntitySetWidgetEventStruct) {
    if (e) e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
