<div class="col-12" *ngIf="permissions">
  <div class="overlayParent">
    <div class="overlay" *ngIf="saving"></div>
    <div class="card" *ngIf="!editEntry">
      <div class="card-header"><strong>Permissions: {{permissions.handle}}</strong></div>
      <div class="card-body card-block">

        <div class="row"><div class="col-3">Permission Type: </div><div class="col-9">{{permissions.permissionType}}</div></div>

        <div class="row my-2">
          <div class="col-3 float-left">Permissions</div>
          <div class="col-9 float-left" *ngIf="permissions.permissions">
            <div class="row" *ngFor="let key of permissions.permissions | keys">
              <div class="col-12">{{key}}</div>
            </div>
          </div>
        </div>

        <div class="row"><div class="col-3">Created On</div><div class="col-9">{{permissions.createdOn}}</div></div>
        <div class="row"><div class="col-3">Modified On</div><div class="col-9">{{permissions.modifiedOn}}</div></div>

        <button class="btn btn-primary pull-right" (click)="edit($event)">Edit</button>
      </div>
    </div>

    <div class="card" *ngIf="editEntry">
      <div class="card-header">
        <input class="form-control" required [(ngModel)]="permissions.handle" value="permissions.handle" id="handle" name="handle" placeholder="name" type="text">
      </div>
      <div class="card-body card-block row">
        <div class="col-6">
          <select name="permSel" [(ngModel)]="permissionSelect"  style="color:black;width:100%">
            <option *ngFor="let perm of permissionsList" [value]="perm">{{perm}}</option>
          </select>
        </div>
        <button class="btn btn-sm btn-primary offset-1" (click)="addPermissions($event)">Add Permission</button>
        <div class="col-12 row my-2">
          <div class="col-3 float-left">Permissions</div>
          <div class="col-9 float-left" *ngIf="newPermissions">
            <div class="row" *ngFor="let key of newPermissions | keys">
              <div class="col-12 row my-2">
                <div class="col-6">
                  {{key}}
                </div>
                <div class="col-6">
                  <button class="btn btn-sm btn-primary" (click)="deletePermission($event, key)">Remove Permission</button>
                </div>
                <hr class="col-12 my-2">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer col-12">
        <button (click)="cancel($event)" class="btn btn-sm btn-primary" type="submit"><i class="fa fa-dot-circle-o"></i> Cancel</button>
        <button (click)="update($event)" class="btn btn-sm btn-warning float-right" type="submit"><i class="fa fa-dot-circle-o"></i>Update</button>
      </div>
    </div>
  </div>
  <div *ngIf="errors.show" class="col-12 alert alert-warning" (click)="errors.show = false">
    {{errors.msg}}
  </div>
<!--
<pre>{{permissions.toJson()|json}}</pre>-->
</div>
<div class="col-12" *ngIf="!permissions">
  <button (click)="createPermission($event)" class="btn btn-sm btn-primary" type="submit"><i class="fa fa-dot-circle-o"></i> Create Permissions</button>
</div>
