import { NoizuStruct }        from '../../../noizu/structs/noizu-struct';
import {DeviceWidgetEnum, WidgetEnum} from '../../../enums';
export class GatewayEnvironmentTarget extends NoizuStruct {
  public loading: boolean = false;
  public error: boolean = false;
  public target_stage: boolean = false;
  public gateway: string = null;

  constructor(settings: any) {
    super();
    this.loading = true;

  } // end constructor


  widget_type(): any {
    return WidgetEnum.EMBED_WIDGET__DEVICE;
  }

  device_widget_type() {
    return DeviceWidgetEnum.DEVICE_WIDGET__ENVIRONMENT_TARGET;
  }


}
