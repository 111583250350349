<page-header [section]="'auto-load-gateway-entries'">
  <page-title>Auto-Load Gateway Entries</page-title>
  <page-blurb>Manage gateway entries. Update or delete existing gateways. Create new gateway entries.</page-blurb>
</page-header>

<spinner *ngIf="!entries"></spinner>

<div *ngIf="entries">
  <div class="card" *ngFor="let entry of entries.items">
    <div class="card-block">
      <h4 class="card-title">{{entry.identifier}}</h4>
      <p class="card-text">internal_name: {{entry.series}}</p>
      <p class="card-text">internal_name: {{entry.serial}}</p>
      <p class="card-text">internal_name: {{entry.verification_code}}</p>
    </div>
  </div>
</div>
