import {Component, Input, Inject, OnInit} from '@angular/core';
import {EntityLogEntity} from '../../../entities/log/entity-log.entity';
import {RequestLogEntity} from '../../../entities/log/request-log.entity';
import {EntityLogSet} from '../../../entities/log/entity-log.set';
import {RequestLogSet} from '../../../entities/log/request-log.set';
import {HttpClient} from '@angular/common/http';
import {FirebaseAuthService} from '../../../noizu/services/firebase-auth.service';
import {EntityLogRepo} from '../../../repos/log/entity-log.repo';
import {RequestLogRepo} from '../../../repos/log/request-log.repo';
import {DomainObject} from '../../../noizu/domain-object';
import {ToasterService} from 'angular2-toaster';
import {DateTimeLogicalWidget} from '../../../entities/logical/date-time-logical.entity';
import {ChartFeed, DateTimeIntervalEntity, ModalWidget} from '../../../entities';
import {ModalWidgetEnum} from '../../../enums';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap/modal';

@Component({
  selector: 'embed-request-logs',
  template: `
            <div class="row">
              <div class="col-12">
                <div class="card border-primary">
                  <div class="card-header">
                  <div class="row">
                    <div class="col-10">Request Log|
                      <i class="fa fa-lg fa-2x fa-calendar text-success" (click)="customRange($event, pickerModal)"></i>
                    </div>
                    <div class="col-2">
                        <label class="switch switch-3d switch-primary float-right" *ngIf="load_status">
                          <input (change)="toggleEnabled($event)" class="switch-input" type="checkbox" [(ngModel)]="toggle" name="toggleEnabled">
                          <span class="switch-slider"></span>
                        </label>
                    </div>
                  </div>

                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12 text-center">


                        <div class="row mb-2">
                          <div class="col">
                            <input (change)="filterRecords($event)" [(ngModel)]="newFilter" [width]="64" type="text" placeholder="Filter" class="col-6 form-control-lg" />
                            <div class="alert alert-warning" *ngIf="entity?.filterOptions()">
                              Filter Options: {{entity.filterOptions()}}
                            </div>
                          </div>
                        </div>

                        <div class="row mb-2">
                          <div class="col text-center">
                            <button *ngIf="!last_to" (click)="fetchLogs($event)" class="btn btn-lg btn-warning" type="submit"><i class="fa" [ngClass]="{'fa-dot-circle-o': !refreshing,  'fa-spinner' : refreshing}" ></i>  Query</button>
                            <button *ngIf="last_to" (click)="fetchNewerLogs($event)" class="btn btn-lg btn-warning" type="submit"><i class="fa" [ngClass]="{'fa-arrow-circle-up': !refreshing,  'fa-spinner' : refreshing}" ></i> Newer</button>
                          </div>
                        </div>


                        <div class="row" *ngIf="error_code != 0">
                          <div class="col">
                            <div class="alert alert-info">{{error_message}}</div>
                          </div>
                        </div>

                        <div class="row" *ngIf="entity?.loaded && entity.entries.length == 0 && error_code == 0">
                          <div class="col">
                            <div class="alert alert-info">No records in selected range {{this.last_from| date:'medium'}} - {{this.last_to| date:'medium'}}</div>
                          </div>
                        </div>

                        <div *ngIf="entity?.loaded">
                          <div *ngFor="let log of entity.entries; let i = index;">
                            <div class="row" *ngIf="log.filter(newFilter)">
                              <div class="col-12 text-left">
                                <log-sub-type-embed
                                  [entity]="log"
                                  [options]="options"
                                  [layout]="layout">
                                </log-sub-type-embed>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mt-2" *ngIf="last_from">
                          <div class="col text-center">
                            <button (click)="fetchOlderLogs($event)" class="btn btn-lg btn-warning" type="submit"><i class="fa" [ngClass]="{'fa-arrow-circle-down': !refreshing,  'fa-spinner' : refreshing}" ></i> More </button>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>




            <ng-template #pickerModal>
              <modal-widget *ngIf="currentModal" (widgetEvent)="eventHandler($event, 'pop-up', pickerModal)" [entity]="currentModal" [layout]="'shadowbox'" [options]="{}"></modal-widget>
            </ng-template>

    `
})
export class EmbedRequestLogsComponent extends DomainObject implements OnInit {
  @Input() entity: RequestLogSet = null;
  @Input() options: any = {};
  @Input() layout: string = null;

  public range_from: DateTimeLogicalWidget;
  public range_to: DateTimeLogicalWidget;
  public repo: RequestLogRepo;
  public refreshing = false;
  public load_status = false;
  public toggle = false;
  public modalRef: BsModalRef;
  public currentModal: any = null;
  public last_from: any = null;
  public last_to: any = null;
  public error_code = 0;
  public error_message = "";

  public newFilter = "";

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService, public modalService: BsModalService, public toasterService: ToasterService) {
    super(client, auth, []);
    this.repo = new RequestLogRepo(client, auth);
    this.range_from = new DateTimeLogicalWidget(null, "24 Hours")
    this.range_to = new DateTimeLogicalWidget(null, "Now")
  }


  public filterRecords(event) {

  }

  eventHandler(event, field, modal) {
    console.log("Release Unhandled Event", field, event);
  }

  showModal(current, modal, clickOut = false, customClass="") {
    this.currentModal = current;
    if (!clickOut) {
      let config: ModalOptions = {
        backdrop : 'static',
        keyboard : false,
        class: customClass
      };
      this.currentModal.modalRef = this.modalService.show(modal, config);
    } else {
      let config: ModalOptions = {
        class: customClass
      };
      this.currentModal.modalRef = this.modalService.show(modal, config);
    }
  }

  customRange(e, modal) {
    let to = new Date();
    let from = new Date(to.getTime() - (4 * 60 * 60 * 1000));
    let time_range = new DateTimeIntervalEntity(from, to);
    let dialog = new ModalWidget(`Pull Range`, ModalWidgetEnum.MODAL_WIDGET__CONFIRM, time_range, {edit: true}, 'shadowbox', time_range.overrides());
    this.showModal(dialog, modal, false, 'md-modal');
    time_range.promise.then((r) => {

      const to = r.to;
      const from = r.from;
      this.refreshing = true;
      this.repo.query(this.entity.subject, this.entity.type, from, to).then( (set: RequestLogSet) => {
        this.error_code = set.error_code;
        this.error_message = set.error_message;
        this.entity.entries = this.entity.entries.concat(set.entries).sort((a,b) => b.time - a.time);
        if (this.error_code == 0) {
          this.last_from = from;
          this.last_to = to;
        } else {
          this.last_from = null;
          this.last_to = null;
        }
        this.entity.loaded = true;
        this.refreshing = false;
      });

    }).catch(() => {
      // swallow
    });
  }

  ngOnInit() {
    this.entity.isLoaded().then((v) => {
      this.load_status = true
      this.toggle = this.entity.enabled;
    });
  }

  toggleEnabled(event) {
    event.preventDefault();
    this.entity.toggleEnabled().then((v: boolean) => {
      this.toasterService.pop('info', 'Request Logs', `Request Logs ${ v ? 'Enabled' : 'Disabled'}`);
      this.toggle = v;
    });
  }

  update_last_from_to(from, to) {
    if (!this.last_from) {
      this.last_from = from;
    } else {
      this.last_from = from < this.last_from ? from : this.last_from;
    }

    if (!this.last_to) {
      this.last_to = to;
    } else {
      this.last_to = to > this.last_to ? to : this.last_to;
    }
  }

  fetchLogs(event, from = null, to = null, retry = true) {
    event.preventDefault();
    to = to || new Date();
    from = from || new Date(to.getTime() - (1000 * 60 * 60 * 4));
    //this.toasterService.pop('info', 'Request Logs', `Querying Request Logs ${to} - ${from}`);
    this.refreshing = true;
    this.repo.query(this.entity.subject, this.entity.type, from, to).then( (set: RequestLogSet) => {
      this.error_code = set.error_code;
      this.error_message = set.error_message;
      if (this.error_code == 1022 && retry) {
        const from2 = new Date(to.getTime() - (1000 * 60 * 60 * 1));
        this.fetchLogs(event, from2, to, false);
      } else {
        if (this.error_code == 0) this.update_last_from_to(from, to);
        if (!this.entity.entries) this.entity.entries = new Array();
        this.entity.entries = this.entity.entries.concat(set.entries).sort((a,b) => b.time - a.time);
        this.entity.loaded = true;
        this.refreshing = false;
      }
    });
  }

  fetchNewerLogs(event) {
    let from = this.last_to;
    let to = new Date(from.getTime() + (1000 * 60 * 60 * 1));
    let now = new Date();
    to = to > now ? now : to;
    this.fetchLogs(event, from, to, false);
  }

  fetchOlderLogs(event) {
    let to = this.last_from;
    let from = new Date(to.getTime() - (1000 * 60 * 60 * 1));
    this.fetchLogs(event, from, to, false);
  }




}
