
import { Component, Inject, Input, NgZone } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location }               from '@angular/common';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { DeviceLookupTableEntryEntity } from '../../../entities/device/lookup-table-entry.entity';
import { DeviceLookupTableRepo } from '../../../repos/device/lookup-table.repo';

@Component({
  templateUrl: './lookup-table.component.html',
})
export class GatewayAutoLoadLookupTableComponent {
  public entries: Array<DeviceLookupTableEntryEntity>;

  constructor(public repo: DeviceLookupTableRepo, public zone: NgZone) {
    this.zone.run(
      () => {
        this.repo.getListPromise().then((u: any) => {this.entries = u;});
      }
    )
  }
}
