import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'body',
  template: `<router-outlet></router-outlet>`
})

export class AppComponent {
  title = 'Admin Panel';
  // loggedIn;
  constructor(public authServ: AuthService) {
    // this.loggedIn = authServ.isSignedIn;
  }

  logout() {
    this.authServ.logout();
  }
}
