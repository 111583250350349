
    <entity-set-header-widget [entity]="this" (widgetEvent)="entitySetEvent($event)" [layout]="layout" [options]="options"></entity-set-header-widget>

    <entity-set-list-widget [entity]="this" (widgetEvent)="forwardEvent($event)" [layout]="layout" [options]="options"></entity-set-list-widget>

    <entity-set-footer-widget [entity]="this" (widgetEvent)="entitySetEvent($event)" [layout]="layout" [options]="options"></entity-set-footer-widget>

    <!--                                                        -->
    <!--                    Pop-Up Menu                         -->
    <!--                                                        -->
    <ng-template #popUpModal>
      <modal-widget [entity]="currentModal" [layout]="'shadow_box'" [options]="{}"></modal-widget>
    </ng-template>

  