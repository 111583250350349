import {Component, Input, OnInit} from '@angular/core';
import { NotesRepo } from '../../repos/notes.repo';
import {DataStreamCardEntity} from '../../entities/data-stream-card.entity';
import {AuthService} from '../../services/auth.service';
import {WidgetEventStruct} from '../../widgets';


@Component({
  selector: 'entity-notes-v2',
  template: `



    <div class="card border-warning">
      <div class="card-header bg-warning">
        {{title}}
        <div class="card-header-actions">
              <span class="pr-2">
                <i
                  (click)="toggleCard()"
                  class="fa fa-sm"
                  [ngClass]="{'fa-chevron-down': !expandCard, 'fa-chevron-up': expandCard}"> </i>
              </span>
          <span *ngIf="options['dragula'] != false" class="dragula-handle"><i class="dragula-handle text-black-50 fa fa-lg fa-arrows"></i></span>
        </div>


      </div>

      <div class="card-body p-1 m-0 " *ngIf="expandCard">
        <div *ngIf="!notes" class="row">
          <div class="col-12">
            <div class="col-12 text-center"><i class="fa fa-lg fa-repeat" (click)="retrieveNotes($event)"></i></div>
          </div>
        </div>

        <ng-container *ngIf="notes">
          <div class="row" *ngFor="let note of notes">
            <div  class="col-12">
              <widget [entity]="note" (widgetEvent)="handleEvent($event)"  [layout]="'card'" [options]="{}"></widget>
            </div>
          </div>
        </ng-container>

      </div>
      <div class="card-footer p-1 m-0" *ngIf="expandCard">
        <div class="row">
          <div class="col-12">
            <textarea
                    class="form-control"
                    name="noteHolder"
                    placeholder="{{placeholder}}"
                    type="text"
                    [(ngModel)]="newNote"
                    autocomplete="off"></textarea>
            <button *ngIf="newNote && newNote.length > 2" (click)="saveNote($event)" class="btn btn-outline-primary btn-block">Save Note</button>
          </div>
        </div>
      </div>
    </div>
`,
styles: [``]
})


export class EntityNotesV2Component implements OnInit{
  @Input() entity: string = null;
  @Input() placeholder = 'Staff Note.';
  @Input() title = 'Notes';
  @Input() options = {};
  public notes = null;
  public newNote = '';
  public expandCard = false;
  public loaded = false;

  constructor(public repo: NotesRepo, public authService: AuthService) {}

  handleEvent(event) {
    if (event instanceof  WidgetEventStruct) {
      if (event.event_type == 'deleted') {
        this.notes = this.notes.filter((note) => note.identifier != event.event_body.note.identifier);
      }
    }
  }

  ngOnInit() {
    if (this.options['expand'] == true) this.expandCard = true;
    this.getNotes();
  }

  toggleCard() {
    if (this.loaded === false) {
      this.getNotes();
    }
    this.expandCard = !this.expandCard;
  }

  retrieveNotes($event) {
    $event.preventDefault();
    this.getNotes();
  }

  getNotes() {
    this.loaded = true;
    this.repo.getNotes(this.entity).then(
      (ret) => {
        this.notes = ret || [];
      }
    );
  }



  saveNote($event) {
    if (this.newNote.length > 2) {
      this.repo.saveNote(this.entity, this.newNote).then((ret) => {this.getNotes(); });
    }
  }


}
