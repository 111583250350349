
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { FirebaseAuthService }        from '../noizu/services/firebase-auth.service';
import {BatchEntity} from '../entities/batch.entity';
import {AppengineRepo} from './appengine.repo';

@Injectable()
export class BatchRepo extends AppengineRepo {
  public _kind = "batches";
  public _singular = "batch";
  public _sort = function(a,b) {return (a.identifier > b.identifier) ? 1 : ((b.identifier > a.identifier) ? -1 : 0);}

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  entity(record) {
    return new BatchEntity(this.client, this.auth, record)
  }
}
