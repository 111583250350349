import { Component, Input, Inject } from '@angular/core';
import {DeviceEntity} from "../../../entities/device.entity";

@Component({
  selector: 'embed-gateway-firmware',
  template: `
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">Gateway Firmware</div>
          <div class="card-body">WIP</div>
        </div>
      </div>
    </div>
    `
})
export class EmbedGatewayFirmwareComponent {
  @Input() entity: any = null;
  @Input() options: any = null;
  @Input() layout: string = null;
  constructor() {
  }

}
