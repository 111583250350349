import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {LogicalWidgetEventStruct} from './logical-widget-event.struct';
import {DateTimeLogicalWidget, ModalWidget} from '../../../entities';
import {ModalWidgetEnum} from '../../../enums';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap/modal';
//import { FormGroup, FormControl, Validators } from "@angular/forms";

@Component({
  selector: 'date-time-picker-logical-widget',
  template: `
    <ng-container *ngIf="entity">
      <ng-container [ngSwitch]="layout">

        <ng-container *ngSwitchCase="'shadowbox'">




          <div class="row justify-content-center" *ngIf="entity">
            <div class="col-auto">
              <div class="row">
                <div class="col text-left">
                  <ngb-datepicker #tdp [(ngModel)]="entity.select_date"></ngb-datepicker>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-auto text-left">
                  <span><ngb-timepicker #ttp [meridian]="true" [(ngModel)]="entity.select_time"></ngb-timepicker></span>
                </div>
              </div>
            </div>
          </div>




        </ng-container>
        <ng-container *ngSwitchDefault>
          <span>[DateTime Picker]</span>
        </ng-container>
      </ng-container>
    </ng-container>

    <!--                                                        -->
    <!--                    Pop-Up Menu                         -->
    <!--                                                        -->
    <ng-template #pickerModal>
      <modal-widget [entity]="currentModal" [layout]="'shadow_box'" [options]="{}"></modal-widget>
    </ng-template>


  `
})
export class DateTimePickerLogicalWidgetComponent {
  @Input() entity: DateTimeLogicalWidget = null;
  @Input() templateHint: string = null;
  @Input() options: any = null;
  @Input() layout = 'standard';
  @Output() widgetEvent = new EventEmitter<LogicalWidgetEventStruct>();

  public newMapModalRef: BsModalRef;
  public currentModal: any;

  forwardEvent(e, widgetEvent: LogicalWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }

  public valueSubscriber: any = null;

  constructor() {
  }

}
