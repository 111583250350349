import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToasterService} from 'angular2-toaster';
import {WidgetBase} from '../widget-base';
import {EntityList} from '../../../noizu/structs';
import {WidgetEventStruct} from '../../widget-event.struct';
import {AppengineWidgetSetComponent} from '../appengine-set-widget';
import {EntitySetWidgetEventStruct} from './entity-set-widget-event.struct';
import {GenericWidgetSetComponent} from '../generic-set-widget';

@Component({
  selector: 'entity-set-footer-widget',
  template: `

    <div class="entity-set-footer-widget row mt-3 mb-4" *ngIf="entity.shown > 15">
      <div class="col">
        <div *ngIf="!entity.has_entries" class="alert m-0 alert-warning">No Entries<ng-container *ngIf="entity.filter"> For Filter [{{entity.filter}}]  <i class="text-danger fa fa-minus-circle" (click)="clearFilter($event)"> </i>, Not Shown {{entity.not_shown}}</ng-container></div>
        <div *ngIf="entity.has_entries" class="alert m-0 alert-info">
          <ng-container *ngIf="!entity.filter">Records {{entity.records}}</ng-container>
          <ng-container *ngIf="entity.filter">Filter [{{entity.filter}}] <i class="text-danger fa fa-minus-circle" (click)="clearFilter($event)"> </i>, Matches {{entity.shown}}, Hidden {{entity.not_shown}}</ng-container>
        </div>
      </div>
      <div class="col">
        <div *ngIf="entity.entity.queryable" class="btn btn-primary btn-lg" (click)="next($event)">More Results
          <new-spinner *ngIf="entity.fetching" [inline]="true" [size]="'sm'"></new-spinner>
        </div>
        <button *ngIf="options['edit']" (click)="add($event)" class="btn btn-lg ml-4 btn-warning" type="submit"><i class="fa fa-plus-circle"></i> New</button>
      </div>
    </div>

  `
})
export class EntitySetFooterWidgetComponent extends WidgetBase implements OnInit {
  public editMode = false;
  @Input() layout: string = null;
  @Input() entity: GenericWidgetSetComponent;
  @Input() options: any;
  @Output() widgetEvent = new EventEmitter<EntitySetWidgetEventStruct>();
  forwardEvent(e, widgetEvent: EntitySetWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }

  constructor() {
    super();
  }

  next(event) {
    this.forwardEvent(event, new EntitySetWidgetEventStruct('next'));
  }

  add(event) {
    this.forwardEvent(event, new EntitySetWidgetEventStruct('add-modal'));
  }

  clearFilter(event) {
    this.forwardEvent(event, new EntitySetWidgetEventStruct('clear-filter'));
  }

  ngOnInit(): void {
  }

}
