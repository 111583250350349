import {Component, Input, Inject, Output, EventEmitter} from '@angular/core';
import {LacrosseEntity} from "../../../entities/lacrosse.entity";
import {NoizuStruct} from "../../../noizu/structs/noizu-struct";
import {CallbackObject, SubscriberObject, SubscriberObjectSet} from '../../../entities/alert';
import {WidgetBase} from '../widget-base';
import {RuntimeWidgetEventStruct} from '../runtime-configuration';
import {WidgetEventStruct} from '../../widget-event.struct';
import {SubscriberWidgetEventStruct} from './subscriber-widget-event.struct';

@Component({
  selector: 'subscriber-object-set-widget',
  template: `
    <set-widget (widgetEvent)="forwardEvent($event)" [entity]="this.entity" [layout]="this.layout" [options]="options" [title]="'Outgoing Subscribers'"></set-widget>
  `
})
export class SubscriberObjectSetWidgetComponent extends WidgetBase {
  @Input() entity: SubscriberObjectSet = null;
  @Input() options: any = null;
  @Input() layout: string = null;

  @Output() widgetEvent = new EventEmitter<SubscriberWidgetEventStruct>();
  forwardEvent(widgetEvent: SubscriberWidgetEventStruct) {
    this.widgetEvent.emit(widgetEvent);
  }

  constructor() {
    super();
  }
}
