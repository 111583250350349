import {ElixirEntity} from '../elixir.entity';
import {ReportWidgetEnum, WidgetEnum} from '../../enums';

export class ReportEntity extends ElixirEntity {
  widget_type(): any {
    return WidgetEnum.EMBED_WIDGET__REPORT;
  }

  widget_report_type(): any {
    return ReportWidgetEnum.REPORT_WIDGET__GENERIC;
  }

} // end Package
