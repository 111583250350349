<div class="card" *ngIf="!deleted">
  <div *ngIf="!(entry.identifier=='new' ) && editEntry==false" class="card-block">
      <div>
        <h4 class="card-title">{{entry.name}}</h4>
        <h6 class="card-subtitle mb-2 text-muted">Id: {{entry.identifier}}</h6>
        <p>Handle: {{entry.handle}}</p>
        <p>Description: {{entry.description}}</p>
        <p>Code: {{entry.code}}</p>



        <div class="row mb-3" *ngIf="entry.features">
          <div class="col-2 float-left">Features:</div>
          <div class="col-10">
            <div class="float-left">
              <render-object [object]="entry.features"></render-object>
              <!--
              <span *ngFor="let key of entry.features | keys">
              {{key}}: <pre>{{entry.features[key]["settings"]|json}}</pre>
            </span>-->
            </div>
          </div>
        </div>

        <p>Created On: {{entry.createdOn}}</p>
        <p>Modified On:{{entry.modifiedOn}}</p>
      </div>
      <button (click)="edit($event, entry)" class="btn btn-sm btn-primary" type="submit"><i class="fa fa-dot-circle-o"></i> Edit</button>
  </div>

  <div *ngIf="entry.identifier=='new' || editEntry==true" class="card-block">
    <div class="card-block">

      <div class="form-group row">
        <label class="col-md-2 form-control-label" for="{{entry.identifier}}-name">Name</label>
        <div class="col-md-10">
          <input class="form-control" required [(ngModel)]="entry.name" value="{{entry.name}}" id="subscription-name" name="subscription-name" placeholder="Subscription Name" type="text">
          <span class="help-block">Main Name</span>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-md-2 form-control-label" for="{{entry.identifier}}-handle">Handle</label>
        <div class="col-md-10">
          <input class="form-control" required [(ngModel)]="entry.handle" value="{{entry.handle}}" id="handle" name="handle" placeholder="handle" type="text">
          <span class="help-block">internal name</span>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-md-2 form-control-label" for="{{entry.identifier}}-desc">Description</label>
        <div class="col-md-10">
          <input class="form-control" required [(ngModel)]="entry.description" value="{{entry.description}}" id="desc" name="desc" placeholder="Subscription Description" type="text">
        </div>
      </div>

      <div class="form-group row">
        <label class="col-md-2 form-control-label" for="{{entry.identifier}}-code">Code</label>
        <div class="col-md-10">
          <input class="form-control" required [(ngModel)]="entry.code" value="{{entry.code}}" id="code" name="code" placeholder="code" type="text">
          <span class="help-block">Internal friendly ID</span>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-md-2 form-control-label" for="{{entry.identifier}}-desc">Features</label>
        <div class="col-md-10">

          <div class="row">
            <div class="col-2">Export</div>
            <div class="col-10">
              <div class="input-group mb-3">
              <input type="text" class="form-control" aria-label="Export Value" placeholder="EX: ai.days.30" [(ngModel)]="entry.features.export.max">
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-2">View</div>
            <div class="col-10">
              <div class="input-group mb-3">
              <input type="text" class="form-control" aria-label="Export Value" placeholder="EX: ai.days.30" [(ngModel)]="entry.features.view.max">
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-2">Notifications</div>
            <div class="col-10 row">
              <div class="col-12 row">
                <div class="col-2">Webhook:</div>
                <div class="col">
                  Unlimited
                  <input type="checkbox" [checked]="entry.features.notifications.webhook == 'unlimited'" (change)="onSelectionChange('webhook')"/>
                </div>
              </div>
              <div class="col-12 row">
                <div class="col-2">SMS:</div>
                <div class="col">
                  Unlimited
                  <input type="checkbox" [checked]="entry.features.notifications.sms == 'unlimited'" (change)="onSelectionChange('sms')"/>
                </div>
              </div>
              <div class="col-12 row">
                <div class="col-2">Email:</div>
                <div class="col">
                  Unlimited
                  <input type="checkbox" [checked]="entry.features.notifications.email == 'unlimited'" (change)="onSelectionChange('email')"/>
                </div>
              </div>
              <div class="col-12 row">
                <div class="col-2">In-App:</div>
                <div class="col">
                  Unlimited
                  <input type="checkbox" [checked]="entry.features.notifications['in-app'] == 'unlimited'" (change)="onSelectionChange('in-app')"/>
                </div>
              </div>
              <div class="col-12 row">
                <div class="col-2">Push:</div>
                <div class="col">
                  Unlimited
                  <input type="checkbox" [checked]="entry.features.notifications.push == 'unlimited'" (change)="onSelectionChange('push')"/>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
    <div class="card-footer">
      <button (click)="cancel($event)" class="btn btn-sm btn-primary" type="submit"><i class="fa fa-dot-circle-o"></i> Cancel</button>
      <button (click)="update($event, entry)" class="btn btn-sm btn-primary float-right" type="submit"><i class="fa fa-dot-circle-o"></i> Add/Update</button>
      <button (click)="delete($event, entry)" class="btn btn-sm btn-warning" type="submit"><i class="fa fa-minus-circle"></i> Delete</button>
    </div>
  </div>
</div>
