import { NoizuStruct }        from '../../../../noizu/structs/noizu-struct';
import { Transformation }        from '../../../transformation';

export class DeviceFieldDefinitionSpotBinaryEncoding extends NoizuStruct {
  public width: number;
  public transformation: Transformation;
  public raw: any;

  constructor(json: any) {
      super();
      this.width = json["width"];
      this.transformation = new Transformation(json["transformation"]);
  } // end constructor
}
