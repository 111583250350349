export enum AggregationLevelEnum {
  Ticks =  0,
  Minutes =  1,
  FiveMinutes =  2,
  FifteenMinutes =  3,
  HalfHours =  4,
  Hours =  5,
  Days =  6,
  Weeks =  7,
  Months =  8,
  Years =  9,
  Unbound =  10
}
