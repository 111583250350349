
import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from './lacrosse.entity';
import { FirebaseAuthService }            from '../noizu/services/firebase-auth.service';
import { DeviceDefinitionRepo }           from '../repos/device/definition.repo';
import { DeviceDefinitionCategory }       from '../structs/device/definition/category';
import { DeviceDefinitionFieldEntry }     from '../structs/device/definition/field-entry';
import {DeviceWidgetEnum, WidgetEnum} from '../enums/widget';

export class SensorTypeRangeEntity extends LacrosseEntity {
  public _kind = "sensor-type-ranges";
  public _singular = "sensor-type-range";

  public name : string;
  public description: string;
  public range_series: string;
  public range_start: string;
  public range_end: string;
  public manufacturer: string;
  public batch: string;
  public device_type: string;
  public _sensor_type_id: string;
  public cover: any;

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    this.strip_from_json["flaggedForSynchVNext"] = 1;
    this.strip_from_json["_kind"] = 1;
  } // end constructor




  toJson(options: any = {}) {
    let json = super.toJson(options)

    json['range_series'] = this.srefToIdentifier('series', json['range_series']);
    json['batch'] = this.srefToIdentifier('batch', json['batch']);
    json['manufacturer'] = this.srefToIdentifier('manufacturer', json['manufacturer']);
    return json;
  }

  repairRange(type) {
    return this._put(
      `${this.ingvEndpoint()}/device-type-ranges/${this.identifier}/repair/${type}`,
      {},
      (data, resolve) => {resolve(true);},
      {}
    );
  }

  validate() {

    let valid = true;
    this.validation = {valid: false, validated: true,  error: null, messages: {common: null}}

    if (!this.name) {
      valid = false;
      this.validation.messages['name'] = "Name Field is Required."
    }
    if (!this.description) {
      valid = false;
      this.validation.messages['description'] = "Description Field is Required."
    }
    if (!this.range_series) {
      valid = false;
      this.validation.messages['range_series'] = "RangeSeries Field is Required."
    } else if (this.range_series.length != 2) {
      valid = false;
      this.validation.messages['range_series'] = "RangeSeries should be two chars V2, V3, etc.."
    }
    if (!this.range_start) {
      valid = false;
      this.validation.messages['range_start'] = "RangeStart Field is Required."
    } else if (this.range_start.length != 6) {
      this.validation.messages['range_start'] = "RangeStart Must Be Six Hex Digits."
    }
    if (!this.range_end) {
      valid = false;
      this.validation.messages['range_end'] = "RangeEnd Field is Required."
    } else if (this.range_end.length != 6) {
      this.validation.messages['range_end'] = "RangeEnd Must Be Six Hex Digits."
    }

    if (!this.manufacturer) {
      valid = false;
      this.validation.messages['manufacturer'] = "Manufacturer Field is Required."
    }
    if (!this.batch) {
      valid = false;
      this.validation.messages['batch'] = "Batch Field is Required."
    }
    if (!this.device_type) {
      valid = false;
      this.validation.messages['device_type'] = "Device Type Field is Required."
    }


    if (valid) {
      this.validation.valid = true;
    } else {
      this.validation.valid = false;
      this.validation.error = "Validation Failed."
      this.validation.messages.common = "Please Resolve Following Validation Errors."
    }

    return this.validation;
  }

  refresh(data) {
    this.name = data["name"];
    this.description = data["description"];
    this.range_series = data["range_series"];
    this.range_start = data["range_start"];
    this.range_end = data["range_end"];

    this.cover = 0;

    if (this.range_start && this.range_end) {
      try {
        this.cover = parseInt(this.range_end, 16) - parseInt(this.range_start, 16)
      } catch {
        this.cover = "ERROR"
      }
    }

    this.manufacturer = data["manufacturer"];
    this.batch = data["batch"];
    this.device_type = data["_sensor_type_id"] ? `ref.device-type.${ data["_sensor_type_id"]}` : null;
    this._sensor_type_id = data["_sensor_type_id"]

    return super.refresh(data);
  }

  public widget() {
    return "embed-device-range"
  }

  widget_type() {
    return WidgetEnum.EMBED_WIDGET__DEVICE_RANGE;
  }

  device_widget_type() {
    return DeviceWidgetEnum.DEVICE_WIDGET__RANGE;
  }

} // end Package
