
import { Component, Inject, Input, NgZone, OnInit, ElementRef } from '@angular/core';
import { D3Service, D3, Selection } from 'd3-ng2-service'; // <-- import the D3 Service, the type alias for the d3 variable and the Selection interface
import { ActivatedRoute, Params } from '@angular/router';
import { Location }               from '@angular/common';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { FormulaTreeNode } from '../../../../structs/formula-tree/node'

@Component({
  selector: 'formula-node',
  templateUrl: './formula-node.component.html',
})
export class FormulaNodeComponent {
  @Input() node: FormulaTreeNode = null;

  constructor() { // <-- pass the D3 Service into the constructor
  }

}
