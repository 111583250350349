<page-header [section]="'tools'">
  <page-title>Tools</page-title>
  <page-blurb>Generate hash-based message authentication codes and verify hexadecimal checksum codes.</page-blurb>
</page-header>

<div class="row">

  <div class="col">

    <div class="card">
      <div class="card-header bg-info">HMac</div>
      <div class="card-body bg-transparent">
        <p class="card-text">
          String: <textarea class="form-control" id="hmstr" name="str" placeholder="string" [(ngModel)]="hmacstr" autocomplete="off"></textarea>
        </p>
        <p class="card-text">
          Secret: <input class="form-control" id="hmsec" name="sec" placeholder="secret" [(ngModel)]="hmacsecret" autocomplete="off">
        </p>
        <button class="btn-sm btn-primary" (click)="calcHmac()"> Get Hmac </button>
      </div>
      <div class="card-footer bg-transparent border-info">
        <p class="card-text"> Sample Strings:</p>
        <pre>[GET] https://ingv2.lacrossetechnology.com/api/v1.1/forecast/id/2CF2F7\n----- body -----\n</pre>
        <pre>[POST] https://ingv2.lacrossetechnology.com/api/v1.1/gateways/wake\n----- body -----\n{{'{'}}\"GatewaySerial\":\"28000A\",\"DisplaySerial\":\"28000A\",\"Firmware\":\"1.1\"{{'}'}}</pre>
        <div *ngIf="hmac" class="alert-success">{{hmac}}</div>
      </div>
    </div>

  </div>
</div>
