
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { DomainObjectRepo } from '../noizu/domain-object-repo';
import { FirebaseAuthService }        from '../noizu/services/firebase-auth.service';
import { ToolsEntity }      from '../entities/tools.entity';
import { AppengineRepo }      from './appengine.repo';

@Injectable()
export class ToolsRepo extends AppengineRepo {
  public _kind = "";
  public _singular = "";

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  entity(record) {
    return new ToolsEntity(this.client, this.auth, record)
  }

}
