import { Component, Input, Inject } from '@angular/core';
import {FirmwareLinkEntity} from "../../../entities/firmware/link.entity";

@Component({
  selector: 'embed-firmware-link',
  template: `
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">Firmware Link</div>
          <div class="card-body"><pre>{{entity?.toJson(options) | json}}</pre></div>
        </div>
      </div>
    </div>
    `
})
export class EmbedFirmwareLinkComponent {
  @Input() entity: FirmwareLinkEntity = null;
  @Input() options: any = null;
  @Input() layout: string = null;
  constructor() {
  }

}
