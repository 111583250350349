
import { Component, Inject, Input, NgZone } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location }               from '@angular/common';
import { NgModel, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { DataManufacturersEntity } from '../../entities/data-manufacturers.entity';
import { DataManufacturersRepo } from '../../repos/data/manufacturers.repo';

@Component({
  selector: 'manufacturers-entry',
  templateUrl: './manufacturers-entry.component.html',
})
export class ManufacturersEntryComponent {
  @Input() entry: DataManufacturersEntity = null;
  public unmodifiedEntry = null;
  public editEntry : boolean = false;
  public deleted: boolean = false;
  constructor(public repo: DataManufacturersRepo) {
  }

  update(e, entity) {
    e.preventDefault();
    this.editEntry = false;
    entity.ManufacturerUpdate();
  }

  edit(e, entity) {
    e.preventDefault();
    this.unmodifiedEntry = this.entry.toJson();
    this.editEntry = true;
  }

  cancel(e) {
    e.preventDefault();
    this.entry.refresh(this.unmodifiedEntry);
    this.editEntry = false;
  }

  delete(e, entity) {
    e.preventDefault();
    // todo remove from parent so it vanishes after success
    this.editEntry = false;
    entity.delete().then( (res:any) => {
      if(res) {
        this.deleted=true;
      }
    });
  }

}
