
import {Component, Inject, Input, NgZone, OnInit} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location }               from '@angular/common';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { DeviceFieldDefinitionEntity } from '../../../../entities/device/field/definition.entity';
import { DeviceFieldDefinitionRepo } from '../../../../repos/device/field/definition.repo';
import {DeviceDefinitionEntity} from '../../../../entities/device/definition.entity';
import {DiagnosticReading} from '../../../../entities/data-stream/diagnostic-card.entity';
import {ElixirEntityList} from '../../../../noizu/structs';
import {FirebaseAuthService} from '../../../../noizu/services/firebase-auth.service';
import {AuthService} from '../../../../services/auth.service';

@Component({
  templateUrl: './list.component.html',
})
export class DeviceFieldDefinitionListComponent implements OnInit {
  public activeEntry: DeviceFieldDefinitionEntity = null;
  public entries: ElixirEntityList;
  public layout = 'table'
  public options = {header: true, sideBarWidth: 4, columns: 2, headers: ['identifier', 'name', 'handle', 'unit', 'high', 'low', 'width', 'derived', 'variant', 'actions']};
  constructor(public repo: DeviceFieldDefinitionRepo, public authService: AuthService, public zone: NgZone) {
    this.zone.run(
      () => {
        this.repo.getListPromise({set: true}).then((u: any) => {this.entries = u;});
      }
    )
  }

  ngOnInit() {
    this.authService.userHasPermissionPromise("device_type_admin").then((r) => {
      this.options['edit']  = r;
    });
  }

  // add(e) {
  //   e.preventDefault();
  //   this.entries["items"].push(this.repo.entity({id: "new"}));
  // }
  //
  // public viewDefinition(entry, event) {
  //   event.preventDefault();
  //   //this.router.navigateByUrl(`/portal/devices/show/${device.identifier}`);
  //   if (this.activeEntry == entry) {
  //     this.activeEntry = null;
  //   } else {
  //     this.activeEntry = null;
  //     this.activeEntry = entry;
  //   }
  // }
}
