import {Component, Input, Inject, Output, EventEmitter, OnInit} from '@angular/core';
import {ModalWidget} from '../entities';
import {WidgetEventStruct} from './widget-event.struct';
import {AuthService} from '../services/auth.service';
import {UserPreferencesService} from '../services/user-preferences.service';

@Component({
    selector: 'page-header',
    template: `
      <div class="row mb-3">
        <div class="col border-bottom">
          <h1 [ngClass]="{'display-4': !minimize, 'font-weight-light h3': minimize}"><ng-content select="page-title"></ng-content> <i (click)="toggleMinimization($event)" class="fa text-dark"  [ngClass]="{'fa-compress font-sm align-top ' : !minimize, 'align-middle fa-expand font-xs': minimize}" ></i></h1>
          <p class="lead" *ngIf="!minimize">
            <ng-content select="page-blurb"></ng-content><ng-container *ngIf="wizard"><i class="fa fa-mortar-board"> </i></ng-container>
          </p>
        </div>
      </div>
    `
})
export class PageHeaderComponent implements OnInit {
  @Input() section: string = "";
  @Input() wizard: ModalWidget| null = null;
  @Output() widgetEvent = new EventEmitter<WidgetEventStruct>();
  public minimize = false;
  forwardEvent(widgetEvent: WidgetEventStruct) {
    this.widgetEvent.emit(widgetEvent);
  }

  constructor(public preferences: UserPreferencesService) {

  }

  ngOnInit() {
    this.minimize = this.preferences.sectionMinimization(this.section);
  }

  toggleMinimization(event) {
    event.preventDefault();
    this.minimize = !this.minimize;
    this.preferences.setSectionMinimization(this.section, this.minimize);
  }

}
