
import { Component, Inject, Input, NgZone } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location }               from '@angular/common';
import { NgModel, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ChannelDefinitionEntity } from '../../entities/channel-definition.entity';
import { ChannelDefinitionRepo } from '../../repos/channel-definition.repo';


@Component({
  templateUrl: './show.component.html'
})
export class ChannelDefinitionShowComponent {
  @Input() channelDefinition: ChannelDefinitionEntity = null;
  constructor(public repo: ChannelDefinitionRepo) {
  }
}
