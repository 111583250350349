import {Component, Input, Inject, OnInit, Output, EventEmitter} from '@angular/core';
import {
  FirmwareDefinitionEntity,
  FirmwareDefinitionLogicalWidget,
} from '../../../../entities';
import {BatchRepo, FirmwareDefinitionRepo} from '../../../../repos';
import {Observable} from 'rxjs';
import {AppengineEntityList} from '../../../../noizu/structs/appengine-entity-list';
import {FirmwareLogicalWidgetEventStruct} from './firmware-logical-widget-event.struct';
import {LogicalWidgetEventStruct} from '../logical-widget-event.struct';

@Component({
  selector: 'firmware-definition-logical-widget',
  template: `
    <ng-container *ngIf="!entity">
      <div class="firmware-definition-logical-widget row"><div class="col">
        <new-spinner [size]="'sm'"></new-spinner>
      </div></div>
    </ng-container>
    <div class="firmware-definition-logical-widget" *ngIf="entity">
      <div *ngIf="options?.edit">
        <div *ngIf="valueSubscriber | async as values; else loading">
          <ng-select
            placeholder="Select Firmware"
            [items]="values"
            bindLabel="label"
            bindValue="value"
            [multiple]="false"
            [(ngModel)]="entity.selected"
            (ngModelChange)="updateOutput()"
          ></ng-select>
        </div>

        <ng-template #loading>
          <div class="row"><div class="col">
            <new-spinner [size]="'sm'"></new-spinner>
          </div></div>
        </ng-template>

      </div>
      <div *ngIf="!options?.edit">
        <span *ngIf="entity.selected">
          <a (click)="showFirmware($event)" href="/portal/firmware/definitions/{{entity.selected}}">
            <span *ngIf="selected">
            ({{selected.identifier}}) {{selected.firmwareVersion}}@{{selected.series}}
            </span>
            <span *ngIf="!selected">
            {{entity.selected}}
            </span>
          </a>
        </span>
        <span *ngIf="!entity.selected">
          (Null)
        </span>
      </div>
    </div>
`
}) export class FirmwareDefinitionLogicalWidgetComponent implements OnInit {
  @Input() entity: FirmwareDefinitionLogicalWidget | null = null;
  @Input() templateHint: string = null;
  @Input() options: any = null;
  @Input() layout = 'standard';

  @Output() widgetEvent = new EventEmitter<FirmwareLogicalWidgetEventStruct>();
  forwardEvent(e, widgetEvent: FirmwareLogicalWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }
  updateOutput() {
    let event = new FirmwareLogicalWidgetEventStruct()
    event.event_widget_type = this.entity.widget_type();
    event.event_logical_widget_type = this.entity.logical_widget_type();
    event.event_firmware_logical_widget_type = this.entity.firmware_logical_widget_type();
    event.event_type = "firmware_selection_made"
    event.event_body = {sref: this.entity.selected}
    this.widgetEvent.emit(event);
  }

  public valueSubscriber: any = null;
  public selected;

  constructor(public repo: FirmwareDefinitionRepo) {

  }


  public sortVersion(a,b) {
    let av = a.firmwareVersion.split(".");
    let bv = b.firmwareVersion.split(".");
    for (let i = 0; i < 3; i++) {
      const an = av[i] + 0;
      const bn = bv[i] + 0;
      if (an != bn) return an - bn;
    }
    return 0;
  }

  public showFirmware(e) {
    e.preventDefault();
    let event = new FirmwareLogicalWidgetEventStruct()
    event.event_widget_type = this.entity.widget_type();
    event.event_logical_widget_type = this.entity.logical_widget_type();
    event.event_firmware_logical_widget_type = this.entity.firmware_logical_widget_type();
    event.event_type = "show_firmware"
    event.event_body = {sref: this.entity.selected}
    this.widgetEvent.emit(event);
  }

  public ngOnInit() {
    this.entity.selected = this.entity.subject
    this.valueSubscriber =  new Observable<any>(observer => {
      this.repo.getListPromise().then((v: AppengineEntityList) => {
        const response = [];

        let i = v.items.sort(this.sortVersion)
        v.items.forEach((i: FirmwareDefinitionEntity) => {
          if (this.entity.selected && this.entity.selected.toString() == i.sref()) {
            this.selected = i;
          }
          const entry = {value: i.sref(), label: `${i.identifier} - ${i.firmwareVersion}`};
          response.push(entry);
        });
        observer.next(response)
      });
    });
  }
}
