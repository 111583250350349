import {NoizuStruct} from '../../../noizu/structs/noizu-struct';

export enum TimeOption {
  year_month,
  month_year,
  disabled,
  unknown
}

export enum LanguageOption {
  english,
  german,
  french,
  unknown
}

export class DataStreamTimeLink extends NoizuStruct {
  public type: TimeOption | undefined = TimeOption.unknown;
  public language: LanguageOption | undefined = LanguageOption.unknown;
  public time_zone: any = null;

  constructor(json) {
    super();

    if (json['type']) {
      this.type = (<any>TimeOption)[json['type']];
      if (this.type === undefined) {
        console.log(`Unsupported Time Link Type: ${json['type']}`);
        this.type = TimeOption.unknown;
      }
    } else {
      this.type = TimeOption.unknown;
    }

    if (json['language']) {
      this.language = (<any>LanguageOption)[json['language']];
      if (this.language === undefined) {
        console.log(`Unsupported Time Link Language: ${json['language']}`);
        this.language = LanguageOption.unknown;
      }
    } else {
      this.language = LanguageOption.unknown;
    }

    this.time_zone = json['time_zone'];
  }

  linkType() {
    return TimeOption[this.type];
  }


  linkLanguage() {
    return LanguageOption[this.language];
  }

  toJson(options: any = {}) {
    return {
      language: LanguageOption[this.language],
      type: this.linkType(),
      time_zone: this.time_zone
    }
  }

  validate() {
    const errors = [];
    if (this.type === undefined
      || this.type === null
      || this.type === TimeOption.unknown
      || this.type === TimeOption.disabled) {
      errors.push({error: 'NoLink', message: 'Time Link Type Required'});
    }

    if (this.language === undefined
      || this.language === null
      || this.language === LanguageOption.unknown
      ) {
      errors.push({error: 'NoLink', message: 'Time Link Language Required'});
    }

    if (this.time_zone === undefined || this.time_zone === null) {
      errors.push({error: 'NoTimeZone', message: 'TimeZone Not Set.'});
    }

    return errors;
  }

}
