import {Component, Input} from '@angular/core';
import {DataStreamCardEntity} from '../../../entities/data-stream-card.entity';
import {ToasterService} from 'angular2-toaster';
import {CmsAlertEntity} from '../../../entities/cms';

@Component({
  selector: 'embed-cms-alert',
  template: `
   <div>Pending</div>
    `
})
export class EmbedCmsAlertComponent {
  public editMode = false;
  @Input() layout: string = null;
  @Input() entity: CmsAlertEntity;
  @Input() options: any;

  constructor(public toasterService: ToasterService) {
  }
}
