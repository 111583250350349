import { Component, OnInit }      from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { DeviceEntity } from '../../entities/device.entity';
import { DeviceRepo } from '../../repos/device.repo';
import { UserEntity } from '../../entities/user.entity';
import { UserRepo } from '../../repos/user.repo';
import {IMyDpOptions} from 'mydatepicker';
import { CountriesList } from '../../enums/countries-list';

import {BsModalService, ModalOptions, BsModalRef} from 'ngx-bootstrap/modal';
import {UserPreferencesService} from '../../services/user-preferences.service';
import {ModalWidget, PinEntity, PinGroupEntity} from '../../entities';
import {PinGroupRepo, PinRepo} from '../../repos';
import {AlertWidgetEnum, ModalWidgetEnum} from '../../enums';
import {AlertWidgetEventStruct} from '../../widgets';
import {ToasterService} from 'angular2-toaster';

@Component({
  templateUrl: './show.component.html'
})
export class UserShowComponent implements OnInit {
  public subscription:any;
  public pinGroups: Array<PinGroupEntity> = [];
  public pinned: PinEntity | null = null;

  public user:UserEntity;
  public userEdit:UserEntity;
  public state = {edit: false, accountEdit:false, emailEdit:false};
  public notification = {"showLoading":false, "message":"","iserror":false};
  public error = {error:false, msg:""};
  public currentModal : any = {title: null,msg: null,confirmMsg: null,confirm:null,cancelMsg:null,cancel:null};
  public modalRef: BsModalRef;
  public showNotes = false;
  public timezones = {"current":[], "defaults":{}};
  public myDatePickerOptions: IMyDpOptions = {dateFormat: 'mm.dd.yyyy'};
  public model: any={ date: { year: 2018, month: 10, day: 9 } };
  public clist;
  public ddr;
  public ddc = {"placeholder":"None Selected (Default USA)","search":true};
  public geoData = {"search":true,"locs":[]};
  public user_sub_version = "1.0";
  public user_sub_version_toggle = false;
  public subscriptionDetails = false;
  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public modalService: BsModalService,
    public userRepo: UserRepo,
    public deviceRepo: DeviceRepo,
    public preferences: UserPreferencesService,
    public pinRepo: PinRepo,
    public pinGroupRepo: PinGroupRepo,
    public toasterService: ToasterService
  ) {
    this.timezones = {"current": [], "defaults":{
      "America/Chicago":"Chicago (Central)",
      "Pacific/Honolulu":"Honolulu (Hawaii)",
      "America/Anchorage":"Anchorage (Alaska)",
      "America/Los_Angeles":"Los Angeles (Pacific)",
      "America/Denver":"Denver (Mountain)",
      "America/Phoenix":"Arizona (Mountain No DST)" ,
      "America/New_York":"New York (Eastern)"
    }};
  }


  loadSubscriptions() {
    if (!this.user.extended.meta.subscription_details_loaded) {
      this.preferences.session.readyPromise.then(() => {
        this.user.extended.getSubscriptionDetailsPromise().then(() => {
          // setup widget
          // console.log('setup widget');
        });
      });
    }
  }

  loadVersion() {
    if (this.preferences) {
      this.preferences.session.readyPromise.then(() => {
        this.user_sub_version = this.preferences.featureVersion('user-sub-widget', '1.0');
        this.user_sub_version_toggle = (this.user_sub_version == '1.0') ? false : true;
      });
    }
  }

  toggleUserSubWidgetVersion(event) {
    event.preventDefault();
    this.user_sub_version = this.user_sub_version_toggle ? '2.0' : '1.0';
    this.preferences.setFeatureVersion('user-sub-widget', this.user_sub_version);
    this.loadSubscriptions();
  }


  handleEvent(event) {
    console.log("HANDLE EVENT", event);
  }

  public pinDevice(event, modal) {
    event.preventDefault();
    if (this.pinned) {
      let dialog = new ModalWidget(
        "Pin: " + this.user.identifier,
        ModalWidgetEnum.MODAL_WIDGET__UPDATE,
        this.pinned,
        {edit: true, revert: false, confirmName: 'Update', deleteName: 'Unpin'},
        'shadowbox'
      );
      this.showModal(dialog, modal);
    } else {
      let pin = this.pinRepo.entity({})
      pin.group = (this.pinGroups && this.pinGroups.length > 0) ? this.pinGroups[0].sref() : null;
      pin.name = `User ${this.user.identifier}`;
      pin.description = "auto generated";
      pin.subject = this.user.sref();
      pin.meta.new = true;
      let dialog = new ModalWidget(
        "Pin: " + this.user.sref(),
        ModalWidgetEnum.MODAL_WIDGET__CREATE,
        pin,
        {edit: true, revert: false, confirmName: 'Pin'},
        'shadowbox'
      );
      this.showModal(dialog, modal);
    }
  }

  showModal(current, modal, clickOut = false, customClass="") {
    this.currentModal = current;
    if (!clickOut) {
      let config: ModalOptions = {
        backdrop : 'static',
        keyboard : false,
        class: customClass
      };
      this.currentModal.modalRef = this.modalService.show(modal, config);
    } else {
      let config: ModalOptions = {
        class: customClass
      };
      this.currentModal.modalRef = this.modalService.show(modal, config);
    }
  }

  checkPin() {
    this.pinGroups = [];
    this.pinGroupRepo.getListPromise({expand: false, pins: true}).then((r: Array<PinGroupEntity>) => {
      this.pinGroups = r;
      this.pinGroups.sort((a: PinGroupEntity,b: PinGroupEntity) => {return a.identifier >= b.identifier ? 1 : -1})
      // Check if pinned
      let sref = `ref.user.${this.user.identifier}`
      for(let pinGroup in this.pinGroups) {
        if (this.pinGroups[pinGroup].pins) {
          for(let pin in this.pinGroups[pinGroup].pins) {
            if (this.pinGroups[pinGroup].pins[pin].subject == sref) {
              this.pinned = this.pinGroups[pinGroup].pins[pin];
              break;
            }
          }
        }
      }
    })
  }

  ngOnInit(): void {
    this.clist = CountriesList.list;
    this.loadVersion();
    this.subscription = this.activatedRoute.params.subscribe(
      (params: any) => {
        if ("id" in params) {
          this.userRepo.getEntityPromise(params.id).then(
            (user:UserEntity) => {
              //We have a user, call other Endpoints to fill in necessary and commonly used data
              this.user = user;
              this.user.getLocationsPromise();
              this.user.getDeviceAssociationsPromise();
              this.checkPin();
            }
          );
        }
      }
    );
  }

  selectionChanged(e,device){
    if (e.value[0]) {
      device.geo.countryCode = e.value[0].value;
    } else {
      device.geo.countryCode = "US";
    }
  }

  loadSettings() {
    return this.user.getUserSettingsPromise();
  }

  loadUserChannels() {
    if (!this.user.meta['channels']) {
      this.user.getChannelsPromise();
    }
  }

  handleAlertEvent(event) {
    if (event instanceof AlertWidgetEventStruct) {
      let alertEvent : AlertWidgetEventStruct = event;
      if (alertEvent.event_alert_widget_type == AlertWidgetEnum.ALERT_WIDGET__ALERT_ENTITY_DELETE) {
        alertEvent.event_body.entity.delete().then(() => {
          this.toasterService.pop('warning', 'Alert', `Alert Deleted`);
        }).catch(() => {
          this.toasterService.pop('error', 'Alert', `Alert Delete Failed`);
        });
        let index = this.user.extended.alerts.items.indexOf(alertEvent.event_body['entity']);
        if (index > -1) {
          this.user.extended.alerts.items = this.user.extended.alerts.items.splice(index, 1);
        }

      }
    }
  }

  loadAlerts(refresh = false) {
    if (!this.user.extended || !this.user.extended.alerts || refresh) {
      this.user.extended.getAlertsPromise().then(() => {
        if(this.user.extended.alerts) {
          this.user.extended.alerts.queryable = false;
        }
      })
    }
  }



//Upon triggers, see if information needs to be loaded in
//Keeps infrequently used data from being called every time.
  loadInfo(key) {
    switch(key) {
      case "n":
        if (!this.user._nested.notifications.data) {
          this.user.getNotificationsPromise();
        }
      break;
      break;
      case "a":
        if (!this.user._nested.alerts) {
          this.user.getAlertsPromise();
        }
      break;
      case "e":
        if (!this.user._nested.alertEvents) {
          this.user.getAlertEventsRecentSimplifiedPromise();
        }
      break;
      case "g":
        if (!this.user._nested.group) {
          this.user.getGroupPromise();
        }
      break;
      case "s":

      break;
    }
  }
  //When associations change at all
  reloadAssocDevices() {
    this.user._nested.deviceAssociations = null;
    this.user.getLocationsPromise();
    this.user.getDeviceAssociationsPromise();
  }

  reloadLocations(e) {
    e.preventDefault();
    this.user._nested.locations = null;
    this.user.getLocationsPromise();
  }

  back(event) {
    event.preventDefault();
    this.router.navigateByUrl(`/portal/users/list`);
  }

  setNotification(inp = '', error=false, loading=false) {
    this.notification.message =inp;
    this.notification.iserror = error;
    //If user clicks on message to hide, dont mess with loading incase it still is
    if (!loading) {
      this.notification.showLoading = false;
    }
  }

  getNotificationStatus() {
    this.user.getNotificationStatus().catch((err)=> {
      this.setNotification("An error occured loading Notification Status",true);
    })
  }

  edit(event, type) {
    event.preventDefault();
    this.userEdit = this.user.clone();
    if(type === 1) {
      this.state.edit = true;
      this.state.accountEdit = true;
    } else if (type === 2) {
      this.state.edit = true;
      this.state.emailEdit = true;
    }
  }

  deleteNotification(event, n) {
    event.preventDefault();
    this.user.deleteNotification(n.identifier).then((res) => {
    }).catch((err) => {
      console.log(err);
      n.loading=false;
      this.setNotification("An error occured while trying to delete", true);
    });
  }

  exitEditMode() {
    this.state.edit = false;
    this.state.emailEdit = false;
    this.state.accountEdit = false;
  }

  cancel(event) {
    event.preventDefault();
    this.exitEditMode();
  }

  getAlertState(alert, event) {
    event.preventDefault();
    this.user.getAlertStatePromise(alert);
  }

  viewDevice(device, event) {
    event.preventDefault();
    //window.open(`/portal/devices/show/${device.raw.sensor.id}/${this.user.identifier}`, "_blank");
    this.router.navigateByUrl(`/portal/devices/show/${device.raw.sensor.id}/${this.user.identifier}`);
  }

  toggleDeviceDetails(device) {
    device.expanded = !device.expanded;
    if (!device.isfact23 && device.isStation && !(device.geo["called"])) {
      device.getGeo();
    }
  }

  toggleNotificationRaw(notification) {
    notification.showRaw = !notification.showRaw;
  }

  toggleAlertStatus(alert) {
    alert.expanded = !alert.expanded
  }

  getDeviceStatus(device, event) {
    event.preventDefault();
    device.getStatusPromise();
  }

  addDevice() {
    this.user.addEmptyDeviceAssociation();
  }

  testChannel(c){
    c.loading = true;
    this.user.testChannel(c.identifier).then((r)=>{
      c.loading=false;
      this.setNotification("Successful test initiated");
    }).catch((e)=> {
      console.log(e);
      c.loading=false;
      this.setNotification("An error occured trying to test this channel", true);
    });
  }

  addLocation() {
    this.user.addEmptyLocation();
  }

  createLocation(l, event) {
    event.preventDefault();
    l.saving = true;
    l.createLocation().then( (res:any) => {
        this.user.getLocationsPromise();
      }
    );
  }

  reposLocation(e, l) {
    e.preventDefault();
    if (l.newPos != null) {
      l.saving = true;
      l.reposition(l.newPos).then((res:any) => {
        this.user.getLocationsPromise();
      })
    } else {
      this.setNotification("New location not selected", true);
    }
  }

  reposDevice(e, dev, dir) {
    e.preventDefault();
    dev.saving = true;
    dev.reposition(this.user.identifier, dir).then((res:any) => {
      this.user.getDeviceAssociationsPromise();
    })
  }

  listLinkedDevices(l) {
    let s = "";
    l.linkedDevices.forEach((item,index)=> {
      if (index > 0)
        s+=", "
      s += item;
    })
    this.setNotification(s);
  }

  editDevice(device, $event) {
    $event.preventDefault();
    this.geoData = {"search":true,"locs":[]};
    if (!device.isfact23) {
      if (!(device.geo["called"])) {
        device.saving = true;
        device.getGeo().then((res=> {
          device.saving = false;
          device.editMode = true;
          device.editPrep();
        })).catch(err=> {device.editMode = true; device.saving = false;});
      } else {
        device.editPrep();
        device.editMode = true;
      }
    } else {
      this.ddr = null;
      device.editPrep();
      if (device.isStation && device.raw.sensor.geo && device.raw.sensor.geo.timezone) {
        let temp = device.raw.sensor.geo.timezone;
        this.timezones.current = [temp];
      } else if (device.geo) {
        device.geo.timezone = "America/Chicago";
      }
      device.editMode = true;
    }
  }

  cancelEditDevice(device, $event) {
    $event.preventDefault();
    device.cancelEdit();
    device.editMode = false;
  }

  updateDeviceAssociation(device, $event) {
    $event.preventDefault();
    device.saving = true;
    device.updateAssociation(this.user.identifier).then(
      (res) => {
        if (res) {
          this.user.getDeviceAssociationsPromise();
        } else {
          device.saving = false;
          this.setNotification("Something failed", true);
        }
      }
    );
  }

  updateDeviceGeoLegacy(device, $event) {
  $event.preventDefault();
  if (device.geo.anonymous || (device.geo.timezone && device.geo.pc)) {
    device.saving = true;
    device.updateGeoLegacy().then(
      (res) => {
        if (res) {
          this.user.getDeviceAssociationsPromise();
        } else {
          device.saving = false;
          this.setNotification("Something failed", true);
        }
      }
    );
  } else {
    this.setNotification("Must be Anonymous, or have zip and timezone filled in", true);
    device.saving = false;
  }
}

  getGeoCode(device, $event) {
    $event.preventDefault();
    if (device.geo.pc && device.geo.pc.length>0) {
      device.saving = true;
      device.searchGeoCode().then(
        (res) => {
          device.saving = false;
          this.geoData.locs = res["locations"];
          this.geoData.search = false;
        }
      );
    } else {
      this.setNotification("No postal code provided", true);
      device.saving = false;
    }
  }

  assignGeo(d, l,e) {
    d.saving=true;
    e.preventDefault();
    d.assignGeo(l.acw_key,l.country_code).then((res)=> {
      this.user.getDeviceAssociationsPromise();
    }).catch(err=> {
      d.saving=false;
      console.log(err);
    });
  }

  checkDevice(device, event) {
    event.preventDefault();
    if (device.sensor_serial && device.sensor_serial.trim().length == 6) {
      device.sensor_serial = device.sensor_serial.trim();
      this.deviceRepo.getBySerialPromise(device.sensor_serial, {}).then(
        (res) => {
          device.sensor_identifier = res["identifier"];
          if (res["attributes"] && res["attributes"]["display"] === "1") {
            device.sensor = {"geo":{"anonymous": true, "zip":0, "timezone":"America/Chicago"} };
            device.status = true;
          } else {
            device.status = true;
          }
        }
      )
    } else {
      this.setNotification("Invalid Serial", true);
    }
  }

  updateAlert(a) {
    a.saving=true;
    a.update().then((ret)=>{
      a.saving=false;
      a.editMode = false;
    }).catch(err=> {
      a.saving=false;
    });
  }

  createDevice(device, event) {
    device.saving = true;
    event.preventDefault();
    if (device.location_id.length > 1) {
      device.createAssociation(this.user.identifier).then(
        (res) => {
          if (res) {
            this.user.getDeviceAssociationsPromise();
          } else {
            this.setNotification("Something failed on creation, it is unknown if the device was added successfully", true);
            device.saving = false;
          }
        }
      );
    } else {
        this.setNotification("Location must be set", true);
        device.saving = false;
    }
  }

  save(confirmModal, event) {
    event.preventDefault();
    this.setNotification();
    this.currentModal = {
      title: "Confirm Update User Record",
      msg: `Are you sure you want to make these changes?`,
      confirmMsg: "Yes, Proceed",
      confirm: () => {
        this.modalRef.hide();
        this.notification.showLoading = true;
        this.userEdit.savePromise().then(
          (result:UserEntity) => {
            this.user.refresh(result.toJson());
            this.exitEditMode();
            this.setNotification("Success");
          }
        ).catch( (err:any) => {
          console.log(err);
          this.setNotification("an error occured", true);
        });
      },
      cancelMsg: "No, Cancel",
      cancel: () => {this.modalRef.hide()},
    }
    let config: ModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    this.modalRef = this.modalService.show(confirmModal, config);
  }

  saveEmail(confirmModal, event) {
    this.setNotification();
    event.preventDefault();
    this.currentModal = {
      title: "Confirm Update User Email",
      msg: `Are you sure you want to make these changes?`,
      confirmMsg: "Yes, Proceed",
      confirm: () => {
        this.notification.showLoading = true;
        this.modalRef.hide();
        this.userEdit.updateEmail().then(
          (emailresult:any) => {
            if (emailresult["success"] == true) {
              this.userEdit.savePromise().then(
                (result:UserEntity) => {
                  this.user = result;
                  this.exitEditMode();
                  this.setNotification("Success");
                })
            } else {
              console.log(emailresult);
              this.setNotification(emailresult["res"]["error"], true);
              this.exitEditMode();
            }
          }
        ).catch((error) => {
          this.setNotification("an error occured", true);
          console.log(error);
          this.exitEditMode();
        });;
      },
      cancelMsg: "No, Cancel",
      cancel: () => {this.modalRef.hide()},
    }
    let config: ModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    this.modalRef = this.modalService.show(confirmModal, config);
  }

  deleteAlert(confirmModal,alert, event) {
    event.preventDefault();
    this.currentModal = {
      title: "Confirm Delete Alert",
      msg: `Are you sure you want to delete this Alert?`,
      confirmMsg: "Yes, Proceed",
      confirm: () => {
        this.notification.showLoading = true;
        this.modalRef.hide();
        alert.delete().then( (res)=> {
          this.user.getAlertsPromise();
          this.setNotification("Success");
        }).catch( (err:any)=> {
          this.setNotification("failed", true);
        });
      },
      cancelMsg: "No, Cancel",
      cancel: () => {this.modalRef.hide()},
    }
    let config: ModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    this.modalRef = this.modalService.show(confirmModal, config);
  }

  deleteAssoc(confirmModal,device, event) {
    event.preventDefault();
    this.currentModal = {
      title: "Confirm Delete Device from account",
      msg: `Are you sure you want to delete this device?`,
      confirmMsg: "Yes, Proceed",
      confirm: () => {
        this.modalRef.hide();
        this.notification.showLoading = true;
        device.saving = true;
        device.deleteAssociation().then( (res)=> {
          this.user.getDeviceAssociationsPromise();
          this.setNotification("Success");
        }).catch( (err)=> {
          console.log(err);
          device.saving = false;
          this.user.getDeviceAssociationsPromise();
          this.setNotification("Something went wrong", true);
        });
      },
      cancelMsg: "No, Cancel",
      cancel: () => {this.modalRef.hide()},
    }
    let config: ModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    this.modalRef = this.modalService.show(confirmModal, config);
  }

  deleteLocation(confirmModal,location, event) {
    event.preventDefault();
    this.currentModal = {
      title: "Confirm Delete this Location",
      msg: `Are you sure you want to delete this location?`,
      confirmMsg: "Yes, Proceed",
      confirm: () => {
        this.modalRef.hide();
        location.saving = true;
        this.notification.showLoading = true;
        location.deleteLocation().then( (res)=> {
          this.user.getLocationsPromise();
          this.setNotification("Success");
        }).catch( (err)=> {
          console.log(err);
          location.saving = false;
          this.user.getLocationsPromise();
          this.setNotification("Something went wrong", true);
        });
      },
      cancelMsg: "No, Cancel",
      cancel: () => {this.modalRef.hide()},
    }
    let config: ModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    this.modalRef = this.modalService.show(confirmModal, config);
  }

  deleteSubscription(confirmModal, i, event) {
    event.preventDefault();
    this.currentModal = {
      title: "Confirm Delete subscription",
      msg: `Are you sure you want to delete this Subscription? This can not be undone.`,
      confirmMsg: "Yes, Delete subscription",
      confirm: () => {
        this.modalRef.hide();
        this.user._nested.subs.subscriptions[i]["saving"] = true;
        this.user.deleteSubscription(this.user._nested.subs.subscriptions[i]).then( (res)=> {
          alert(res);
          this.user.getUserSubcriptionInfo();
        }).catch( (err)=> {
          console.log(err);
          this.setNotification("Something went wrong", true);
        });
      },
      cancelMsg: "No, Cancel",
      cancel: () => {this.modalRef.hide()},
    }
    let config: ModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    this.modalRef = this.modalService.show(confirmModal, config);
  }

  saveSubscription(i) {
    this.user._nested.subs.subscriptions[i]["saving"] = true;
    this.user.saveSubscription(this.user._nested.subs.subscriptions[i]).then( (res)=> {
      if (res && res["success"] == false) {
        this.setNotification("Failed", true);
      }
      this.user.getUserSubcriptionInfo();
    });
  }

  addNewSub() {
    let today = new Date();
    let end;
    if (today.getMonth() == 11) {
      end = new Date(today.getFullYear() + 1, 0, today.getDate());
    } else {
      end = new Date(today.getFullYear(), today.getMonth() + 1,today.getDate());
    }
    this.user._nested.subs.subscriptions.push({"isNew":true,"edit":true,"subscription_type":"ref.subscription.5361034439163904",
      "subscription_start":{ date: { year: today.getFullYear(), month: today.getMonth()+1, day: today.getDate() } },
      "subscription_end":{ date: { year: end.getFullYear(), month: end.getMonth()+1, day: end.getDate() } },
      "owner":"ref.user." + this.user.identifier});
  }

  removeNewSubs() {
    let replace = [];
    for (let i of this.user._nested.subs.subscriptions) {
      if (!(i.isNew)) replace.push(i);
    }
    this.user._nested.subs.subscriptions = replace;
  }

  deleteThisUser(confirmModal, event) {
    event.preventDefault();
    this.currentModal = {
      title: "Confirm Delete This User",
      msg: `Are you sure you want to delete this User? This can not be undone.`,
      confirmMsg: "Yes, Delete this User",
      confirm: () => {
        this.modalRef.hide();
        this.user.deleteUser().then( (res)=> {
          alert(res);
          this.setNotification(res.toString());
        }).catch( (err)=> {
          console.log(err);
          this.setNotification("Something went wrong", true);
        });
      },
      cancelMsg: "No, Cancel",
      cancel: () => {this.modalRef.hide()},
    }
    let config: ModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    this.modalRef = this.modalService.show(confirmModal, config);
  }

  confirmSetAnon(confirmModal,device, event) {
    event.preventDefault();
    this.currentModal = {
      title: "Confirm set to Anonymous",
      msg: `Are you sure you want Set this device to Anonymous?`,
      confirmMsg: "Yes",
      confirm: () => {
        this.modalRef.hide();
        device.saving = true;
        device.assignGeo("","").then( (res)=> {
          this.user.getDeviceAssociationsPromise();
        }).catch( (err)=> {
          console.log(err);
          device.saving = false;
          this.setNotification("Something went wrong", true);
        });
      },
      cancelMsg: "No, Cancel",
      cancel: () => {this.modalRef.hide()},
    }
    let config: ModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    this.modalRef = this.modalService.show(confirmModal, config);
  }


}
