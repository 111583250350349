//-------------------------------
// Top Level Widget Types
//------------------------------
export enum WidgetEnum_Alert_Facade {
  ALERT_FACADE_WIDGET__DEFAULT = 0,
  ALERT_FACADE_WIDGET__CONTACT,
  ALERT_FACADE_WIDGET__HUMIDITY,
  ALERT_FACADE_WIDGET__LIGHTNING,
  ALERT_FACADE_WIDGET__MOTION,
  ALERT_FACADE_WIDGET__ON_OFF,
  ALERT_FACADE_WIDGET__PROBE,
  ALERT_FACADE_WIDGET__RAINFALL,
  ALERT_FACADE_WIDGET__TEMPERATURE,
  ALERT_FACADE_WIDGET__THERMOMETER,
  ALERT_FACADE_WIDGET__WET_DRY,
  ALERT_FACADE_WIDGET__WIND,
}


export enum WidgetEnum_Alert_Status {
  ALERT_STATUS_WIDGET__DEFAULT = 0,
  ALERT_STATUS_WIDGET__CONTACT,
  ALERT_STATUS_WIDGET__HUMIDITY,
  ALERT_STATUS_WIDGET__LIGHTNING,
  ALERT_STATUS_WIDGET__MOTION,
  ALERT_STATUS_WIDGET__ON_OFF,
  ALERT_STATUS_WIDGET__PROBE,
  ALERT_STATUS_WIDGET__RAINFALL,
  ALERT_STATUS_WIDGET__TEMPERATURE,
  ALERT_STATUS_WIDGET__THERMOMETER,
  ALERT_STATUS_WIDGET__WET_DRY,
  ALERT_STATUS_WIDGET__WIND,
}
