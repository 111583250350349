import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {DeviceDefinitionRepo} from '../../../repos';
import {DeviceDefinitionEntity, DeviceFieldDefinitionEntity} from '../../../entities/device';
import {DeviceEntity} from '../../../entities';
import {DeviceWidgetEventStruct} from './device-widget-event.struct';
import {WidgetBase} from '../widget-base';
import {DeviceCategoryEnum, DeviceWidgetEnum, WidgetEnum} from '../../../enums';
import {UserWidgetEventStruct} from '../user';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap/modal';
import {DeviceDefinitionCategory} from '../../../structs';
import {AuthService} from '../../../services/auth.service';
import {cloneDeep} from 'lodash';
import { diffChars } from 'diff';

@Component({
  selector: 'device-type-widget',
  template: `
<div class="device-type-widget" *ngIf="!entity">
  <widget-spinner></widget-spinner>
</div>
<ng-container *ngIf="entity">
  <ng-container [ngSwitch]="layout">
    <!-- ================================================= -->
    <!-- Mini                                              -->
    <!-- ================================================= -->
    <div class="device-type-widget" *ngSwitchCase="'mini'">
        [Device Type.mini]
    </div>

    <!-- ================================================= -->
    <!-- Brief                                             -->
    <!-- ================================================= -->
    <div class="device-type-widget" *ngSwitchCase="'brief'">


      <div class="card border-info" *ngIf="entity">
        <div class="card-header bg-info m-0 p-1">
          <strong>Device Type:</strong> <a class="text-dark" [routerLink]="['/portal/device/definitions/' + entity.identifier]"> {{entity.internalName}}</a>
          <div class="card-header-actions">
                  <span><i *ngIf="collapsible" (click)="expandCard = !expandCard" class="fa fa-sm pr-2"
                           [ngClass]="{'fa-chevron-down': !expandCard, 'fa-chevron-up': expandCard}"> </i></span>
          </div>
        </div>
        <div class="card-body p-1">
          <!-- Device Image -->
          <div class="row mb-2">
            <div class="col-12 text-center" *ngIf="entity.image">
              <img [src]="entity.image" style="max-width: 90%; max-height: 10vh" alt="{{entity.name}}">
            </div>
          </div>

          <div *ngIf="expandCard">

            <!-- Name -->
            <div class="row mb-1">
              <div class="col-12"><b>Name:</b></div>
              <div class="col-12">{{entity.name}}</div>
            </div>

            <!-- Internal Name -->
            <div class="row mb-1">
              <div class="col-12"><b>Internal:</b></div>
              <div class="col-12">{{entity.internalName}}</div>
            </div>


            <!-- Definition -->
            <div class="row mb-1">
              <div class="col-12"><b>Definition:</b></div>
              <div class="col-12">
                <a [routerLink]="['/portal/device/definitions/' + entity.identifier]">{{entity.identifier}}</a>
              </div>
            </div>


          </div>



        </div>
      </div>


    </div>

    <!-- ================================================= -->
    <!-- Standard                                          -->
    <!-- ================================================= -->
    <div class="device-type-widget" *ngSwitchCase="'standard'">
      <ng-container *ngTemplateOutlet="standard"></ng-container>
    </div>


    <!-- ================================================= -->
    <!-- shadowbox                                          -->
    <!-- ================================================= -->
    <div class="device-type-widget" *ngSwitchCase="'shadowbox'">
      <ng-container *ngTemplateOutlet="standard"></ng-container>
    </div>



    <!-- ================================================= -->
    <!-- Sidebar                                           -->
    <!-- ================================================= -->
    <div class="device-type-widget" *ngSwitchCase="'sidebar'">
      <ng-container *ngTemplateOutlet="standard"></ng-container>
    </div>


    <!-- ================================================= -->
    <!-- Table                                             -->
    <!-- ================================================= -->

    <ng-container *ngSwitchCase="'nested-header'">
      [Device Field: Nested Headers]
    </ng-container>


    <ng-container *ngSwitchCase="'table'">
      <div class="device-type-widget row" *ngIf="options['index'] == 0 && options['header']">
        <ng-container *ngFor="let column of options.columns">
          <ng-container [ngSwitch]="column">
            <div class="col-2  div-table-th" *ngSwitchCase="'identifier'">Id</div>
            <div class="col-2  div-table-th" *ngSwitchCase="'internal'">Internal Name</div>
            <div class="col-2  div-table-th" *ngSwitchCase="'name'">Name</div>
            <div class="col-2  div-table-th" *ngSwitchCase="'handle'">Handle</div>
            <div class="col-2  div-table-th" *ngSwitchCase="'description'">Description</div>
            <div class="col-2  div-table-th" *ngSwitchCase="'actions'">Actions</div>

            <!-- <div class="col  div-table-th" *ngSwitchCase="'actions'">Actions</div> -->
          </ng-container>
        </ng-container >
      </div>

      <div class="row">
        <ng-container *ngFor="let column of options.columns">
          <ng-container [ngSwitch]="column">
            <div class="col-2 text-nowrap" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'identifier'">
              <a [routerLink]="['/portal/device/definitions/' + entity.identifier]"  title="{{entity.identifier}}" ><i class="pr-1 text-dark fa fa-link"></i></a>&nbsp;<a title="{{entity.identifier}}" [href]="'/portal/device/definitions/'  + entity.identifier" (click)="select($event)" >{{entity.identifier}}</a>
            </div>
            <div class="col-2" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'internal'">{{entity.internalName}}</div>
            <div class="col-2" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'name'">{{entity.name}}</div>
            <div class="col-2" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'handle'">{{entity.handle}}</div>
            <div class="col-2" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'description'">{{entity.description}}</div>
            <div class="col-2" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'actions'">

              <button *ngIf="device_type_admin" (click)="showCloneModal(deviceTypeListModal, entity, $event)" class="button btn-primary">Clone</button>


            </div>

            <!-- <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'actions'">
              [actions]
            </div> -->
          </ng-container>
        </ng-container>

      </div>


    </ng-container>


    <ng-container *ngSwitchCase="'nested-table'">
      [Device Field: Nested Table]
    </ng-container>


    <div class="device-entity-widget" *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="standard"></ng-container>
    </div>
  </ng-container>
</ng-container>



<ng-template #standard>

  <!-- Not Loaded -->
  <div class="card" *ngIf="!entity">
    <div class="card-header">
      <strong>Device Type:</strong> Loading
      <div class="card-header-actions">
        <span class="dragula-handle"><i class="dragula-handle text-black-50 fa fa-lg fa-arrows"></i></span>
      </div>
    </div>
    <div class="card-body">
      <widget-spinner></widget-spinner>
    </div>
  </div>
  <!-- Loaded -->
  <div class="card border-info" *ngIf="entity">
    <div class="card-header bg-info">


      <strong>Device Type:</strong> {{entity.internalName}}
      <button *ngIf="entity.pending" (click)="showSaveChanges(deviceDefinitionModal, $event)" class="button btn-danger">Save Changes</button> <button *ngIf="entity.pending" (click)="showRevertChanges(deviceDefinitionModal, $event)" class="button btn-secondary">Revert Changes</button>

      <div class="card-header-actions">
                  <span> <i *ngIf="options?.optional_edit"  (click)="toggleEdit($event)" class="fa fa-pencil pr-3"
                            [ngClass]="{'text-dark': optionalEdit, 'text-warn': !optionalEdit}"> </i></span>
        <span><i *ngIf="collapsible" (click)="expandCard = !expandCard" class="fa fa-sm pr-2"
                 [ngClass]="{'fa-chevron-down': !expandCard, 'fa-chevron-up': expandCard}"> </i></span>
        <span class="dragula-handle"><i class="dragula-handle text-black-50 fa fa-lg fa-arrows"></i></span>
      </div>
    </div>
    <div class="card-body p-2" *ngIf="expandCard">

      <!-- Device Image -->
      <div class="row mb-4">
        <div class="col-12 text-center" *ngIf="entity.image">
          <img [src]="entity.image" style="max-width: 90%; max-height: 20vh" alt="{{entity.name}}">
        </div>
        <div *ngIf="options?.edit" class="col-12 text-center">
          <input type="text" [(ngModel)]="entity.image" (ngModelChange)="updateType(entity)" size="50" />
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header bg-secondary">Details</div>
            <div class="card-body m-0 p-1">


              <!-- Definition @todo link-->
              <div class="row mb-1">
                <div class="col-3"><b>Definition:</b></div>
                <div class="col-9">
                  <a
                    routerLinkActive="active"
                    [routerLink]="['/portal/device/definitions/' + entity.identifier]"
                  >{{entity.identifier}}</a>
                </div>
              </div>


              <!-- Name -->
              <div class="row mb-1">
                <div class="col-3"><b>Name:</b></div>
                <div class="col-9" *ngIf="!options?.edit">{{entity.name}}</div>
                <div class="col-9" *ngIf="options?.edit"><input type="text" [(ngModel)]="entity.name" (ngModelChange)="updateType(entity)" size="35" /></div>
              </div>

              <!-- Internal Name -->
              <div class="row mb-1">
                <div class="col-3"><b>Internal:</b></div>
                <div class="col-9" *ngIf="!options?.edit">{{entity.internalName}}</div>
                <div class="col-9" *ngIf="options?.edit"><input type="text" [(ngModel)]="entity.internalName" (ngModelChange)="updateType(entity)" size="35" /></div>
              </div>

              <!-- Description -->
              <div class="row mb-3">
                <div class="col-3"><b>Description:</b></div>
                <div class="col-9" *ngIf="!options?.edit">{{entity.description}}</div>
                <div class="col-9" *ngIf="options?.edit"><input type="text" [(ngModel)]="entity.description" (ngModelChange)="updateType(entity)" size="35" /></div>
              </div>

              <!-- Default Series -->
              <div class="row mb-3">
                <div class="col-3"><b>Default Series:</b></div>
                <div class="col-9" *ngIf="!options?.edit">{{entity.defaultSeries}}</div>
                <div class="col-9" *ngIf="options?.edit"><input type="text" [(ngModel)]="entity.defaultSeries" (ngModelChange)="updateType(entity)" size="35" /></div>
              </div>

              <!-- Category -->
              <div class="row mb-3">
                <div class="col-3"><b>Device Category:</b></div>
                <div class="col-9" *ngIf="!options?.edit">{{entity.category?.name}}</div>
                <div class="col-9" *ngIf="options?.edit">
                  <ng-select *ngIf="this.category.enum"
                             placeholder="Select Category"
                             [items]="categories"
                             bindLabel="label"
                             bindValue="value"
                             [multiple]="false"
                             handleInputChanges="true"
                             [(ngModel)]="this.category.enum"
                             (ngModelChange)="updateCategory($event)"
                  ></ng-select>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="col-12">
          <embed-device-attributes [entity]="entity" [layout]="'card'" [options]="options?.attributes || {title: 'Device Type Attributes'}"></embed-device-attributes>
        </div>


      </div>


      <!-- Field Entries -->
      <div class="card ">
        <div class="card-header bg-dark">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <b>Field Entries</b>
            </div>
          </div>
        </div>

        <div class="card-body m-0 p-0">
          <div class="row" *ngFor="let field of entity.fieldEntries">
            <div class="col-12 mb-2">
              <embed-device-type-field-entry
                [entity]="field"
                [options]="options"
                [layout]="layout"></embed-device-type-field-entry>
            </div>
          </div>
        </div>
      </div><!-- End of Field Entries -->

    </div>
  </div>


</ng-template>

<ng-template #deviceTypeListModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{currentModal?.title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="deviceTypeListModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <widget-spinner *ngIf="currentModal?.processing"></widget-spinner>
    <div class="row" *ngIf="!currentModal?.processing">
      <div class="col-12">{{currentModal?.msg}}</div>
      <div class="col-12">

        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">Updated Fields</div>
              <div class="card-body">


                <!-- image -->
                <div class="row mb-1">
                  <div class="col-3"><b>Image:</b></div>
                  <div class="col-9" ><input type="text" [(ngModel)]="clone.image" size="64" /></div>
                </div>


                <!-- Name -->
                <div class="row mb-1">
                  <div class="col-3"><b>Name:</b></div>
                  <div class="col-9" ><input type="text" [(ngModel)]="clone.name" size="8" /></div>
                </div>

                <!-- Internal Name -->
                <div class="row mb-1">
                  <div class="col-3"><b>Internal:</b></div>
                  <div class="col-9" ><input type="text" [(ngModel)]="clone.internalName" size="8" /></div>
                </div>

                <!-- Description -->
                <div class="row mb-3">
                  <div class="col-3"><b>Description:</b></div>
                  <div class="col-9" ><input type="text" [(ngModel)]="clone.description" size="8" /></div>
                </div>




              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <widget [entity]="clone" [layout]="'aside'" [options]="{expand: true, collapsible: false, attributes: {expand: true}}"></widget>
          </div>
        </div>



      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      *ngIf="currentModal?.cancelMsg"
      type="button"
      class="btn btn-secondary"
      (click)="currentModal?.cancel()"
      data-dismiss="modal">{{currentModal?.cancelMsg}}</button>
    <button
      *ngIf="currentModal?.deleteMsg"
      type="button"
      class="btn btn-warning"
      (click)="currentModal?.delete()"
      data-dismiss="modal">{{currentModal?.deleteMsg}}</button>
    <button
      *ngIf="currentModal?.confirmMsg"
      type="button"
      class="btn btn-danger"
      (click)="currentModal?.confirm()">{{currentModal?.confirmMsg}}</button>
  </div>
</ng-template>


<!--                                                        -->
<!--                    Pop-Up Menu                         -->
<!--                                                        -->
<ng-template #deviceDefinitionModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{currentModal?.title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="deviceDefinitionModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <widget-spinner *ngIf="currentModal?.processing"></widget-spinner>
    <div class="row" *ngIf="!currentModal?.processing">
      <div class="col-12">
        {{currentModal?.msg}}
      </div>
      <div class="col-12">


        <div class="alert alert-success mt-4">



          <table>
            <thead>
            <tr>
              <th>Field</th>
              <th>Action</th>
              <th>New Value</th>
              <th>Diff</th>

            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let item of compareObjects(_entity.toJson(), edit_clone) | keyvalue">
              <tr>
                <td>{{ item.key }}</td>
                <td>{{ item.value.action }}</td>
                <td>{{ item.value.newValue }}</td>
                <td><pre style="text-overflow: ellipsis; width: 200px;">{{ item.value.diff }}</pre></td>
              </tr>
            </ng-container>
            </tbody>
          </table>


        </div>

      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      *ngIf="currentModal?.cancelMsg"
      type="button"
      class="btn btn-secondary"
      (click)="currentModal?.cancel()"
      data-dismiss="modal">{{currentModal?.cancelMsg}}</button>
    <button
      *ngIf="currentModal?.deleteMsg"
      type="button"
      class="btn btn-warning"
      (click)="currentModal?.delete()"
      data-dismiss="modal">{{currentModal?.deleteMsg}}</button>
    <button
      *ngIf="currentModal?.confirmMsg"
      type="button"
      class="btn btn-danger"
      (click)="currentModal?.confirm()">{{currentModal?.confirmMsg}}</button>
  </div>
</ng-template>

`
})
export class DeviceDefinitionWidgetComponent extends WidgetBase implements OnInit {
  _entity: DeviceDefinitionEntity = null;
  public category = {enum: '0'};
  public _options: any = {};
  public expandCard = false;
  public collapsible = true;
  public attributesExpand = true;
  public expand = false;
  public deviceDefinitionModalRef: BsModalRef;
  public currentModal: any;
  public categories: any = [];
  public clone: DeviceDefinitionEntity;
  public edit_clone: any;
  public device_type_admin = false;
  public optionalEdit = false;


  public deviceTypeListModalRef: BsModalRef;

  public cloneTypeModal = {
    title: 'Clone Device Definition',
    msg: `Clone Definition.`,
    errorMsg: null,
    processing: false,
    form: 'new-attribute',
    confirmMsg: 'Save',
    confirm: ()  => {
      if (this.cloneDefinition()) {
        this.deviceTypeListModalRef.hide();
      }
    },

    cancelMsg: 'Cancel',
    cancel: ()  => {
      this.cloneTypeModal.errorMsg = null;
      this.deviceTypeListModalRef.hide()
    },
  };

  toggleEdit(e) {
    e.preventDefault()
    this.optionalEdit = !this.optionalEdit;
    this.options.edit = this.optionalEdit
    this.options.attributes.edit = this.optionalEdit;
  }


  generateDiff(oldValue: string, newValue: string): string {
    const diff = diffChars(oldValue, newValue);
    let diffText = '';

    diff.forEach(part => {
      const prefix = part.added ? '+' : part.removed ? '-' : ' ';
      diffText += `${prefix}[${part.value}]\n`;
    });

    return diffText;
  }

  compareObjects(obj1, obj2) {
    const changes = {};

    // Find added and modified properties
    for (let key in obj1) {
      if (obj1.hasOwnProperty(key) && !obj2.hasOwnProperty(key)) {
        changes[key] = {
          action: 'Removed',
          oldValue: obj1[key],
          newValue: undefined,
          diff: this.generateDiff(JSON.stringify(obj1[key]), "")
        };
      } else if (JSON.stringify(obj1[key]) != JSON.stringify(obj2[key])) {
        changes[key] = {
          action: 'Edited',
          oldValue: obj1[key],
          newValue: obj2[key],
          diff: this.generateDiff(JSON.stringify(obj1[key]), JSON.stringify(obj2[key]),)
        };
      }
    }

    // Find newly added properties
    for (let key in obj2) {
      if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key)) {
        changes[key] = {
          action: 'Added',
          oldValue: undefined,
          newValue: obj2[key],
          diff: this.generateDiff("", JSON.stringify(obj2[key]))
        };
      }
    }

    return changes;
  }

  @Input() set entity(v: DeviceDefinitionEntity) {
    this._entity = v;
    this.edit_clone = this.edit_clone = cloneDeep(this._entity.toJson());;
    if (this._entity.category) {
      this.category.enum = this._entity.category.name;
    } else {
      console.log("Uhh");
    }

    this.categories = [];
    // noinspection TypeScriptValidateTypes
    Object.keys(DeviceCategoryEnum)
      .filter(value => isNaN(Number(value)) === false)
      .map((key: any) => {

        if (DeviceCategoryEnum[key + 0] == this.category.enum) {
          // console.log(`Active ${key}`);
          this.categories.push({selected: true, label: `${key}: ${DeviceCategoryEnum[key]}`, value: `${DeviceCategoryEnum[key]}`});
        } else {
          this.categories.push({label: `${key}: ${DeviceCategoryEnum[key]}`, value: `${DeviceCategoryEnum[key]}`});
        }

      });
  }
  get entity() {return this._entity; }
  @Input() layout: string = null;
  @Output() widgetEvent = new EventEmitter<DeviceWidgetEventStruct>();
  forwardEvent(e, widgetEvent: DeviceWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
    console.log(widgetEvent);
  }


  public saveChangesModal = {
    title: 'Save Changes',
    msg: `Save changes to device type definition.`,
    errorMsg: null,
    processing: false,
    form: 'save-changes',
    confirmMsg: 'Save',
    confirm: ()  => {
      if (this.saveChanges()) {
        this.deviceDefinitionModalRef.hide();
      }
    },

    cancelMsg: 'Cancel',
    cancel: ()  => {
      this.saveChangesModal.errorMsg = null;
      this.deviceDefinitionModalRef.hide()
    },
  };


  public revertChangesModal = {
    title: 'Revert Changes',
    msg: `Revert changes to device type definition.`,
    errorMsg: null,
    processing: false,
    form: 'revert-changes',
    confirmMsg: 'Revert',
    confirm: ()  => {
      if (this.revertChanges()) {
        this.deviceDefinitionModalRef.hide();
      }
    },

    cancelMsg: 'Cancel',
    cancel: ()  => {
      this.revertChangesModal.errorMsg = null;
      this.deviceDefinitionModalRef.hide()
    },
  };

  @Input() set options(v: any) {
    console.log("Options", v);
    v = v || {};
    this._options = v;
    if (v['expand'] == false) {
      this.expandCard = false;
    } else {
      this.expandCard = true;
    }

    if (v['collapsible'] == false) {
      this.collapsible = false;
    } else {
      this.collapsible = true;
    }

    if (v['attributes']) {
      this.options['attributes'] = v['attributes']
    } else {
      this.options['attributes'] = {}
    }
    if (!this.options.attributes['title']) {
      this.options.attributes['title'] = "Device Type Attributes"
    }


  }
  get options() {return this._options || {}; }

  constructor(public modalService: BsModalService,  public authService: AuthService, public repo: DeviceDefinitionRepo) {
    super();


    this.authService.userHasPermissionPromise("device_type_admin").then((r: boolean) => {
      this.device_type_admin = r;
    });
  }


  toggleExpand() {
    this.expand = !this.expand;
  }

  public select(event) {
    let a = new DeviceWidgetEventStruct();
    a.event_body = this.entity;
    a.event_type = 'select_entity';
    a.event_widget_type = WidgetEnum.EMBED_WIDGET__DEVICE_TYPE;
    this.forwardEvent(event, a);
  }


  revertChanges() {
    this.repo.getEntityPromise(this.entity.identifier).then((r: DeviceDefinitionEntity) => {
      for (let attr in r) {
        this.entity[attr] = r[attr];
      }
    });
    return true;
  }

  saveChanges() {
    this._entity.update().then((r: DeviceDefinitionEntity) => {
      for (let attr in r) {
        this.entity[attr] = r[attr];
      }
      this.entity.pending = false;
    });
    return true;
  }

  showRevertChanges(modal, event) {
    event.preventDefault();
    // todo reload entity
    this.currentModal = this.revertChangesModal;
    let config: ModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    this.deviceDefinitionModalRef = this.modalService.show(modal, config);
  }

  showSaveChanges(modal, event) {
    event.preventDefault();
    this.currentModal = this.saveChangesModal;
    let config: ModalOptions = {
      backdrop : 'static',
      keyboard : false,
      class: 'lg-modal'
    };
    this.deviceDefinitionModalRef = this.modalService.show(modal, config);
  }


  updateCategory(e) {
    this.entity.pending = true;
    this.entity.category = new DeviceDefinitionCategory(DeviceCategoryEnum[e]);
  }

  updateType(entity) {
    this.entity.pending = true;
  }


  public cloneDefinition() {
    this.clone.update();
    return true;
  }

  public showCloneModal(modal, entry: DeviceDefinitionEntity, event) {
    event.preventDefault();
    this.clone = entry.deepCopy();
    this.clone.identifier = "new";
    this.clone.handle = null;
    this.currentModal = this.cloneTypeModal;
    let config: ModalOptions = {
      backdrop : 'static',
      keyboard : false,
      class : 'lg-modal'
    };
    this.deviceTypeListModalRef = this.modalService.show(modal, config);
  }

  ngOnInit(): void {


  }
}
