
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { FirebaseAuthService }        from '../../noizu/services/firebase-auth.service';
import {ElixirRepo} from '../elixir.repo';
import {PinEntity} from '../../entities/entity-pins/pin.entity';

@Injectable()
export class PinRepo extends ElixirRepo {
  public _kind = "entity-pins";
  public _singular = "entity-pin";

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  entity(record) {
    return new PinEntity(this.client, this.auth, record)
  }
}
