
import { Component, Inject, Input, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location }               from '@angular/common';

import { FirmwareDefinitionEntity } from '../../../entities/firmware/definition.entity';
import {FirmwareApprovalRepo} from '../../../repos';
import {FirmwareApprovalEntity} from '../../../entities';

@Component({
  template: `


    <div *ngIf="entity">
      <firmware-approval-widget [entity]="entity" [layout]="'full'" [options]="{}" ></firmware-approval-widget>
    </div>
  `
})
export class FirmwareApprovalShowComponent implements OnInit {
  public entity: FirmwareApprovalEntity = null;
  public subscription: any;

  constructor(public activatedRoute: ActivatedRoute, public repo: FirmwareApprovalRepo) {
  }

  ngOnInit() {
    this.subscription = this.activatedRoute.params.subscribe(
      (params: any)  => {
        if ('id' in params) {

          this.repo.getEntityPromise(params['id']).then((entity: FirmwareApprovalEntity) => this.entity = entity);
        }
      }
    );
  }
}
