import {Component, Input, Inject, ChangeDetectorRef, NgZone, OnInit, Output, EventEmitter} from '@angular/core';
import {DeviceDefinitionRepo, DeviceRepo, GatewayRepo} from '../../../../repos';
import {AuthService} from '../../../../services/auth.service';
import {SimulatorWidget} from '../../../../entities/device/simulator-widget';
import {DeviceDefinitionEntity, FieldSimulatorWidget} from '../../../../entities/device';
// import {forEach} from '@angular/router/src/utils/collection';
import {DeviceEntity, GatewayEntity} from '../../../../entities';
import {DeviceWidgetEventStruct} from '../../device/device-widget-event.struct';
import {LogicalDeviceWidgetEventStruct} from './logical-device-widget-event.struct';
import {environment} from '../../../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {FirebaseAuthService} from '../../../../noizu/services/firebase-auth.service';
import {ToasterService} from 'angular2-toaster';

@Component({
  selector: 'device-simulator-widget',
  template: `
    <div class="device-simulator-widget" *ngIf="!entity">
      <widget-spinner></widget-spinner>
    </div>
    <ng-container *ngIf="entity">

      <div class="device-simulator-widget card m-0">
        <div class="card-header bg-dark text-center" style="z-index: 600;">
          <span class="font-xl">Device Simulator</span>
          <span class=" float-right pt-1"><i (click)="showSimulatorExtension($event)" class="font-xl fa fa-wrench"></i></span>
        </div>


        <ng-container *ngIf="showExtended" class="device-simulator-widget-extension">
          <div class="bg-gray-500 mr-4 align-middle table-align-middle" style="width: 100%; opacity: 0.9; height: 100%; position: absolute; z-index: 500; display:flex; align-items: center; justify-content: center; ">
            <div class="row my-auto" style="width: 100%">
              <div class="col-2">

              </div>
              <div class="col">
                <div class="card">
                  <div class="card-header">
                    <div class="row">
                      <div class="col">
                        Extended Gateway Simulator
                      </div>
                      <div class="col-auto">

                        <span>Direct</span>
                        <label class="switch switch-3d switch-info ml-1">
                          <input checked="" class="switch-input" [(ngModel)]="extendedGatewayDirect" type="checkbox">
                          <span class="switch-slider"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row mb-2">
                      <div class="col-xl-4 col-6  text-right"><b>Gateway:</b></div>
                      <div class="col text-left">
                        <input
                          type="text"
                          [(ngModel)]="displaySerial"
                          (ngModelChange)="changeDisplay($event)"
                          name="device"
                          size="10"/>
                        @ {{displaySeries}}</div>
                    </div>
                    <div *ngIf="extendedGatewayDirect" class="row mb-2">
                      <div class="col-xl-4 col-6  text-right"><b>PSK:</b></div>
                      <div class="col text-left">
                        <input
                          type="text"
                          [(ngModel)]="displaySecret"
                          name="secret"
                          size="10"/>
                        </div>
                    </div>





                    <div class="row">
                      <div class="col">
                        <div class="container mt-3" style="display: flex; align-items: center; justify-content: center">
                          <button class="btn btn-primary" (click)="gatewayFosGeo($event)">FOS-GEO</button>
                          <button class="btn btn-primary" (click)="gatewayGeo($event)">GEO</button>
                          <button class="btn btn-primary" (click)="gatewayNotification($event)">Notifications</button>
                          <button class="btn btn-primary" (click)="gatewayGetAlarm($event)">Alarms</button>
                        </div>
                      </div>
                    </div>


                    <ng-container>
                      <div class="row">
                        <div class="col">
                          <div class="alert alert-info">
                            Details
                          </div>
                        </div>
                      </div>

                    </ng-container>

                  </div>
                </div>
              </div>
              <div class="col-2">

              </div>
            </div>


          </div>
        </ng-container>
        <div class="card-body">




          <div class="row">
            <div class="col-5">

              <div class="row mb-2">
                <div class="col-xl-4 col-6 text-right"><b>Sensor:</b></div>
                <div class="col text-left">
                  <input
                    type="text"
                    [(ngModel)]="deviceSerial"
                    (ngModelChange)="changeDevice($event)"
                    name="device"
                    size="10"/>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-xl-4 col-6  text-right"><b>Gateway:</b></div>
                <div class="col text-left">
                  <input
                    type="text"
                    [(ngModel)]="displaySerial"
                    (ngModelChange)="changeDisplay($event)"
                    name="device"
                    size="10"/>
                  @ {{displaySeries}}</div>
              </div>
              <div class="row mb-2">
                <div class="col-xl-4 col-6  text-right"><b>Firmware:</b></div>
                <div class="col text-left">
                  <input
                    type="text"
                    [(ngModel)]="firmware"
                    name="device"
                    size="10"/>
                </div>
              </div>

            </div>
            <div class="col">
              <div *ngIf="deviceReady">

                <div class="row mb-2">
                  <div class="col-xl-4 col-6  text-right"><b>Battery Low:</b></div>
                  <div class="col text-left ">
                    <label class="switch switch-3d switch-danger">
                      <input checked="" class="switch-input" [(ngModel)]="batteryLow" type="checkbox">
                      <span class="switch-slider"></span>
                    </label>
                  </div>
                </div>


                <div class="row mb-2">
                  <div class="col-xl-4 col-6  text-right"><b>On Display:</b></div>
                  <div class="col text-left ">
                    <label class="switch switch-3d switch-primary">
                      <input checked="" class="switch-input" [(ngModel)]="onDisplay" type="checkbox">
                      <span class="switch-slider"></span>
                    </label>
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-xl-4 col-6  text-right"><b>Event:</b></div>
                  <div class="col  text-left">
                    <span class="font-xl">
                      <span class="mr-4">{{event}}</span>
                      <button class="btn btn-dark btn-pill mr-2" (click)="incrementEvent($event)">+</button>
                      <button class="btn btn-light btn-pill" (click)="decrementEvent($event)">-</button>
                    </span>
                  </div>
                </div>
              </div>

            </div>


          </div>


          <div class="row b-t-1 pt-4"  *ngIf="deviceReady">

            <div class="col">


                <div class="row mb-2">
                  <div class="col-xl-4 col-6  text-right"><b>Mark As Simulated:</b></div>
                  <div class="col text-left">
                    <label class="switch switch-3d switch-success">
                      <input checked="" class="switch-input" [(ngModel)]="tagSimulation" type="checkbox">
                      <span class="switch-slider"></span>
                    </label>
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-xl-4 col-6  text-right"><b>Force Tx:</b></div>
                  <div class="col text-left ">
                    <label class="switch switch-3d switch-danger">
                      <input checked="" class="switch-input" [(ngModel)]="transaction" type="checkbox">
                      <span class="switch-slider"></span>
                    </label>
                  </div>
                </div>

              </div>
            <div class="col">

                <div class="row mb-2">
                  <div class="col-xl-4 col-6  text-right"><b>Channel:</b></div>
                  <div class="col text-left ">
                    <label class="switch switch-3d switch-danger">
                      <input checked="" class="switch-input" [(ngModel)]="channel" type="checkbox">
                      <span class="switch-slider"></span>
                    </label>
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-xl-4 col-6  text-right"><b>PassThruBit:</b></div>
                  <div class="col text-left ">
                    <label class="switch switch-3d switch-danger">
                      <input checked="" class="switch-input" [(ngModel)]="ptb" type="checkbox">
                      <span class="switch-slider"></span>
                    </label>
                  </div>
                </div>


            </div>
          </div>


          <div *ngIf="!deviceReady">
            <div class="row">
              <div class="col">
                <new-spinner [size]="'md'"></new-spinner>
              </div>
            </div>
          </div>
          <div *ngIf="deviceReady">


            <hr>
            <div class="row mt-2" *ngIf="fields">
              <div class="col-xl-3 col-6  text-right"><b>Fields</b></div>
              <div class="col">
                <div class="row" *ngFor="let field of fields">
                  <div class="col">
                    <device-field-simulator-widget [entity]="field" [layout]="'row'"
                                                   [options]="{identifier: field.identifier, edit: true}"></device-field-simulator-widget>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col text-right">
                <button class="btn btn-primary" (click)="binaryReport($event)">Send</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  `
})
export class DeviceSimulatorWidgetComponent implements OnInit{
  @Input() entity: SimulatorWidget | null = null;
  @Input() templateHint: string = null;
  @Input() options: any = null;
  @Input() layout = 'standard';
  @Output() widgetEvent = new EventEmitter<LogicalDeviceWidgetEventStruct>();
  forwardEvent(e, widgetEvent: LogicalDeviceWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }

  public deviceReady = false;
  public deviceDefinition: number = null;
  public definitions: any = {};
  public deviceSerial: string = null;
  public displaySerial: string = null;
  public displaySecret: string = "";
  public displaySeries: string = null;
  public displaySerialSet = false;

  public tagSimulation: boolean = true;
  public batteryLow: boolean = false;
  public ptb: boolean = false;
  public transaction: boolean = false;
  public channel: boolean = false;
  public onDisplay: boolean = false;
  public event: number = 0;
  public firmware: string = "14.3.0";
  public fields: Array<any> = null;
  public display: DeviceEntity = null;
  public gateway: GatewayEntity = null;
  public showExtended = false;
  public extendedGatewayDirect = true;
  public extendedGatewayDirectDetails = null;
  constructor(
    public deviceRepo: DeviceRepo,
    public gatewayRepo: GatewayRepo,
    public definitionRepo: DeviceDefinitionRepo,
    public toasterService: ToasterService,
    public authService: AuthService,
    private httpClient: HttpClient, private auth: FirebaseAuthService
  ) {
    this.onDisplay = false;
  }

  ngOnInit(): void {
    this.deviceSerial = this.entity.subject.serial;
    if (this.entity.subject.attributes['display'] == 1) {
      this.displaySerial = this.deviceSerial;
      this.displaySeries = this.entity.subject.series;
      this.onDisplay = true;
    } else {
      this.displaySerial = "280065";
      this.displaySeries = "V2";
      this.onDisplay = false;
    }

    this.deviceDefinition = this.entity.subject.sensorTypeEntityId;
    this.definitionRepo.getEntityPromise(this.deviceDefinition).then( (definition: DeviceDefinitionEntity) => {
      this.definitions[definition.identifier] = definition;
      this.fields = [];
      definition.fieldEntries.forEach((e) => {
        let field = new FieldSimulatorWidget(e);
        this.fields.push(field);
      });
      this.deviceReady = true;
    });

  }

  public showSimulatorExtension(event) {
    event.preventDefault();
    this.showExtended = !this.showExtended;
  }


  public changeDevice(e) {
    this.deviceReady = false;
    this.deviceRepo.getBySerialPromise(e).then((d: DeviceEntity) => {
      this.entity.subject = d;

      if (!this.displaySerialSet) {
        if (this.entity.subject.attributes['display'] == 1) {
          this.displaySerial = this.deviceSerial;
          this.displaySeries = this.entity.subject.series;
          this.onDisplay = true;
        } else {
          this.displaySerial = "280065";
          this.displaySeries = "V2";
          this.onDisplay = false;
        }
      }

      this.deviceDefinition = this.entity.subject.sensorTypeEntityId;
      if (!this.definitions[this.deviceDefinition]) {
        this.definitionRepo.getEntityPromise(this.deviceDefinition).then( (definition: DeviceDefinitionEntity) => {
          this.definitions[definition.identifier] = definition;
          this.fields = [];
          definition.fieldEntries.forEach((e) => {
            let field = new FieldSimulatorWidget(e);
            this.fields.push(field);
          });
          this.deviceReady = true;
        });
      } else {
        this.fields = [];
        this.definitions[this.deviceDefinition].fieldEntries.forEach((e) => {
          let field = new FieldSimulatorWidget(e);
          this.fields.push(field);
        });
        this.deviceReady = true;
      }
    });
  }

  public gatewayCall(event, callback) {
    event.preventDefault();
    if (this.gateway == null && this.display) {
      this.gateway = this.gatewayRepo.entity({
        series: this.display.series,
        serial: this.display.serial,
        batch: this.display.batch,
        manufacturer: this.display.manufacturer,
        factoryMode: 0,
        secret: this.displaySecret
      });
    }
    if (this.gateway) {
      callback();
    } else {
      this.toasterService.pop('error', 'Error', 'No gateway not found')
    }
  }

  public gatewayFosGeo(event) {
    this.gatewayCall(event,
      () => {
        this.gateway.getFosGeo(this.extendedGatewayDirect, this.displaySecret).then((r) => {
          this.extendedGatewayDirectDetails = r;
        }).catch((error) => {
          this.toasterService.pop('error', 'Fos Geo', error)
        });
      });
  }
  public gatewayGeo(event) {
    this.gatewayCall(event,
      () => {
        this.gateway.getGeo(this.extendedGatewayDirect, this.displaySecret).then((r) => {
          this.extendedGatewayDirectDetails = r;
        }).catch((error) => {
          this.toasterService.pop('error', 'Geo', error)
        });
      });
  }
  public gatewayNotification(event) {
    this.gatewayCall(event,
      () => {
        this.gateway.getNotification(this.extendedGatewayDirect, this.displaySecret).then((r) => {
          this.extendedGatewayDirectDetails = r;
        }).catch((error) => {
          this.toasterService.pop('error', 'Notifications', error)
        });
      });
  }
  public gatewayGetAlarm(event) {
    this.gatewayCall(event,
      () => {
        this.gateway.getAlarm(this.extendedGatewayDirect, this.displaySecret).then((r) => {
          this.extendedGatewayDirectDetails = r;
        }).catch((error) => {
          console.log(error);
          this.toasterService.pop('error', 'Get Alarm', error)
        });
      });
  }


  public changeDisplay(e) {
    this.deviceRepo.getBySerialPromise(e).then((d: DeviceEntity) => {
      this.display = d;
      this.displaySerialSet = true;
      this.displaySeries = d.series;
    });

    this.gateway = null;
    this.gatewayRepo.getBySerialPromise(e).then((g: GatewayEntity) => {
      this.gateway = g;
      if (this.gateway.secret) {
        this.displaySecret = this.gateway.secret;
      }
    })
  }

  public incrementEvent(e) {
    e.preventDefault();
    this.event++;
    if (this.event > 6) this.event = 0;
  }

  public decrementEvent(e) {
    e.preventDefault();
    this.event--;
    if (this.event < 0) this.event = 6;
  }

  formatData() {
    let data = '';
    this.fields.forEach((f: FieldSimulatorWidget) => {
      if (!f.subject.field.is_derived_type) {
        data += `${f.binaryEncode({current: f.value, previous: f.previous_value})}`
        f.previous_value = f.value;
      }
    })

    let sensor = parseInt(this.deviceSerial, 16).toString(16).padStart(6, '0')
    let status = parseInt( (this.batteryLow ? '1' : '0') + (this.transaction ? '1' : '0') + (this.channel ? '01' : '00'), 2).toString(16)
    let eventAndPtb = parseInt(this.event.toString(2) + (this.ptb ? '1' : '0'), 2).toString(16)

    return `${sensor}${status}${eventAndPtb}${data}`.toUpperCase();
  }

  public binaryReport(e) {
    e.preventDefault();
    let payload = {
      "DisplaySerial": this.displaySerial,
      "GatewaySerial": this.displaySerial,
      "GatewaySeries": this.displaySeries,
      "Data": this.formatData(),
      "OnDisplay": this.onDisplay,
      "AutoSign": this.tagSimulation,
      "Firmware": this.firmware
    }


    const url = `${environment.ingv2_url + '/api'}/v1.1/admin-tools/report/binary`;
    this.auth.getTokenPromise().then(
      (token) => {
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', `bearer ${token}`)
        headers = headers.set('Content-Type', `application/json`)


        this.httpClient.post<any>(url, payload, {headers: headers, responseType: 'json'}).subscribe(
          (res) =>
          {
            this.toasterService.pop('success', 'Report Binary',`Payload Sent: ${payload['Data']}` )

          },
          (err) => {
            this.toasterService.pop('warning', 'Report Binary', `Unexpected Error`);
          }
        );

      }
    );
    return true
  }
}
