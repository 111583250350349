import {LogicalWidgetEnum} from "../../enums/widget";
import {LogicalWidget} from '../logical-widget';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';

export class DateTimeIntervalEntity extends LogicalWidget{
  public select_from_date: NgbDate;
  public select_from_time: any = {hour: 1, minute: 1, second: 1}
  public select_to_date: NgbDate;
  public select_to_time: any = {hour: 1, minute: 1, second: 1}

  private _resolve;
  private _reject;
  public promise;

  constructor(public from: Date, public to: Date) {
    super();
    this.to = this.to || new Date();
    this.from = this.from || new Date(this.to.getTime() - (24 * 60 * 60 * 1000));
    this.select_from_date = new NgbDate(this.from.getFullYear(), this.from.getMonth() + 1, this.from.getDate());
    this.select_from_time = {
      hour: this.from.getHours(),
      minute: this.from.getMinutes(),
      second: this.from.getSeconds(),
    };
    this.select_to_date = new NgbDate(this.to.getFullYear(), this.to.getMonth() + 1, this.to.getDate());
    this.select_to_time = {
      hour: this.to.getHours(),
      minute: this.to.getMinutes(),
      second: this.to.getSeconds(),
    };

    this._resolve = null;
    this._reject = null;
    this.promise = new Promise(
      (resolve, reject) => {
        this._resolve = resolve;
        this._reject = reject;
      });

  } // end constructor

  public fromDate() {
    return new Date(this.select_from_date.year,this.select_from_date.month - 1,this.select_from_date.day,this.select_from_time.hour, this.select_from_time.minute, this.select_from_time.second);
  }
  public toDate() {
    return new Date(this.select_to_date.year,this.select_to_date.month - 1,this.select_to_date.day,this.select_to_time.hour, this.select_to_time.minute, this.select_to_time.second);
  }

  public overrides() {
    let overrides = {
      "confirm": {
        msg: "Select",
        cb: (self, event, modalRef, toaster) => {
          this._resolve({from: self.subject.fromDate(), to: self.subject.toDate()});
          modalRef.hide();
        }
      },
      "cancel": {
        msg: "Cancel",
        cb: (self, event, modalRef, toaster) => {
          this._reject(null);
          modalRef.hide();
        }
      }
    };
    return overrides;
  }


  logical_widget_type(): LogicalWidgetEnum | null {
    return LogicalWidgetEnum.LOGICAL_WIDGET__DATE_TIME_RANGE;
  }
} // end Package

