
    <ng-container [ngSwitch]="entity.data_stream_log_widget_type()">

      <ng-container *ngSwitchCase="DataStreamLogWidgetEnum.DATA_STREAM_LOG_WIDGET__DATA_STREAM">
        <data-stream-fetch-log-details-widget [entity]="entity" [options]="options" [layout]="layout"> </data-stream-fetch-log-details-widget>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <log-details-generic-widget [entity]="entity" [options]="options" [layout]="layout"> </log-details-generic-widget>
      </ng-container>

    </ng-container>
    