
      <div class="row embed-ds-generic-card">
          <div class="col-12">
              <div class="card mb-0 pb-0 border-warning">
                  <div class="card-body">
                      <pre>{{entity.raw | json}}</pre>
                  </div>
              </div>
          </div>
      </div>
    