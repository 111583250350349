import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { FirebaseAuthService }            from '../../../noizu/services/firebase-auth.service';
import {ElixirEntity} from '../../elixir.entity';
import {TargetStruct} from '../../firmware/target.struct';

export class MCUFirmwareLinkEntity extends ElixirEntity {
  public _kind = "mcu-fw-link-v2";
  public _singular = "mcu-fw-link-v2";
  public _sref_module = 'mcu-fw-link-v2';

  public identifier: any;
  public target: TargetStruct;
  public status: string;
  public release: string;
  public query_key: any;
  public owner: string;
  public notes: string;
  public name: string;
  public modified_on: Date;
  public firmware: string;
  public description: string;
  public created_on: Date;
  public approval: string;

  // book keeping
  public pending: boolean;

  //--------------------------------------
  // Constructor
  //--------------------------------------
  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    if (json) {
      this.refresh(json);
    } else {
      this.target = new TargetStruct({});
    }
  } // end constructor

  public scopeLessThan(target) {
    // Only allow if firmware covers less than 5 devices.
    if (this.target.type == 'by_gateway') return true;
    if (this.target.type == 'by_appengine') return true;
    if (this.target.type == 'by_range'  || this.target.type == 'by_type_and_range' || this.target.type == 'by_batch_and_range'  || this.target.type == 'by_batch_and_type_and_range') {
      const range_from = parseInt(this.target.constraints.range_from, 16)
      const range_to = parseInt(this.target.constraints.range_to, 16)
      if (range_from < range_to && ((range_from - range_to) < target)) {
        return true;
      }
    }
    return false;
  }

  public userMayApprove() {
    return this.scopeLessThan(6);
  }

  public userMayDelete() {
    return this.scopeLessThan(6);
  }



  updateEndpoint() {
    return `${this.apiBase()}/mcu/firmware/links/${this.identifier}`;
  }

  createEndpoint() {
    return `${this.apiBase()}/mcu/firmware/links`;
  }

  deleteEndpoint() {
    return `${this.apiBase()}/mcu/firmware/links/${this.identifier}`;
  }

  approve(message)  {
    const url = `${this.apiBase()}/mcu/firmware/links/${this.sref()}/approve`;
    return this._put(url, {comment: message}, (json, r) => {r(json)}, {} );
  }



  //--------------------------------------
  // User Friendly Criteria Display (TODO can use an embed widget)
  //--------------------------------------
  public get criteria() {
    return 'PENDING';
  }

  //--------------------------------------
  // Is Approved?
  //--------------------------------------
  public get approved() {
    // must have approval and specific status.
    return this.approval ? true : false;
  }



  //--------------------------------------
  // valid - perform validation check
  //--------------------------------------
  public valid() {
    if (this.firmware == null) {
      console.log('firmware required');
      return false;
    }
    if (this.name == null || this.name.length < 3) {
      console.log('name required');
      return false;
    }
    return this.target.valid();
  }

  //--------------------------------------
  // Refresh from json/raw
  //--------------------------------------
  refresh(data) {
    this.identifier = data.identifier;
    this.target = new TargetStruct(data.target || {});
    this.status = data.status;
    this.release = data.release;
    this.query_key = data.query_key;
    this.owner = data.owner;
    this.notes = data.notes;
    this.name = data.name;
    this.created_on = data.created_on ? new Date(data.created_on) : null;
    this.modified_on = data.modified_on ? new Date(data.modified_on) : null;
    this.firmware = data.firmware;
    this.description = data.description;
    this.approval = data.approval;
    return super.refresh(data);
  }
} // end Package
