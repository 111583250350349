

    <div class="entity-set-footer-widget row mt-3 mb-4" *ngIf="entity.shown > 15">
      <div class="col">
        <div *ngIf="!entity.has_entries" class="alert m-0 alert-warning">No Entries<ng-container *ngIf="entity.filter"> For Filter [{{entity.filter}}]  <i class="text-danger fa fa-minus-circle" (click)="clearFilter($event)"> </i>, Not Shown {{entity.not_shown}}</ng-container></div>
        <div *ngIf="entity.has_entries" class="alert m-0 alert-info">
          <ng-container *ngIf="!entity.filter">Records {{entity.records}}</ng-container>
          <ng-container *ngIf="entity.filter">Filter [{{entity.filter}}] <i class="text-danger fa fa-minus-circle" (click)="clearFilter($event)"> </i>, Matches {{entity.shown}}, Hidden {{entity.not_shown}}</ng-container>
        </div>
      </div>
      <div class="col">
        <div *ngIf="entity.entity.queryable" class="btn btn-primary btn-lg" (click)="next($event)">More Results
          <new-spinner *ngIf="entity.fetching" [inline]="true" [size]="'sm'"></new-spinner>
        </div>
        <button *ngIf="options['edit']" (click)="add($event)" class="btn btn-lg ml-4 btn-warning" type="submit"><i class="fa fa-plus-circle"></i> New</button>
      </div>
    </div>

  