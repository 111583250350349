
    <div class="alert-constraint-trigger-state-widget card"  [ngClass]="{'card-accent-danger': entity.triggered, 'card-accent-succes': !entity.triggered}">
      <div class="card-header ">
        <div class="row">
          <div class="col"><b class="text-left">Constraint Trigger: #{{entity.handle}}</b></div>
          <div class="col-2 text-right p-0 text-middle">
            <toggle-widget [options]="expand_settings"></toggle-widget>
          </div>
        </div>
      </div>
      <div class="card-body"  [ngClass]="{'d-none': !expand_settings.toggle}" >
        <div class="row">
          <div class="col"><b>triggered?:</b></div><div class="col" [ngClass]="{'text-danger': entity.triggered, 'text-success': entity.triggered}" >{{entity.triggered| json}}</div>
        </div>
        <div class="row">
          <div class="col"><b>condition_met?:</b></div><div class="col" [ngClass]="{'text-warning': entity.condition_met, 'text-success': entity.condition_met}" >{{entity.condition_met| json}}</div>
        </div>
        <div class="row">
          <div class="col"><b>ticks since condition_met:</b></div><div class="col">{{entity.ticks| json}}</div>
        </div>
        <div class="row">
          <div class="col"><b>event start:</b></div><div class="col">{{entity.event_start| json}}</div>
        </div>
        <div class="row">
          <div class="col"><b>event_end:</b></div><div class="col">{{entity.event_end| json}}</div>
        </div>
        <div class="row">
          <div class="col"><b>reading:</b></div><div class="col">{{entity.reading?.value| json}} {{entity.reading?.unit.name}}</div>
        </div>
      </div>
    </div>
    