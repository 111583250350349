import {Component, Input, Inject, OnInit, Output, EventEmitter, TemplateRef, ContentChild, ViewChild} from '@angular/core';
import {WidgetBase} from './widget-base';
import {WidgetEventStruct} from '../widget-event.struct';
import {AppengineEntityList} from '../../noizu/structs/appengine-entity-list';
import {DeviceCategoryEnum, ModalWidgetEnum} from '../../enums';
import {FirmwareDefinitionEntity, ModalWidget} from '../../entities';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap/modal';
import {DomainObject} from '../../noizu/domain-object';
import {EntitySetWidgetEventStruct} from './entity-set';
import {GenericWidgetSetComponent} from './generic-set-widget';
import {EntityList} from '../../noizu/structs';

@Component({
  selector: 'appengine-set-widget',
  template: `
    <entity-set-header-widget [entity]="this" (widgetEvent)="entitySetEvent($event)" [layout]="layout" [options]="options"></entity-set-header-widget>

    <entity-set-list-widget [entity]="this" (widgetEvent)="forwardEvent($event)" [layout]="layout" [options]="options"></entity-set-list-widget>

    <entity-set-footer-widget [entity]="this" (widgetEvent)="entitySetEvent($event)" [layout]="layout" [options]="options"></entity-set-footer-widget>

    <!--                                                        -->
    <!--                    Pop-Up Menu                         -->
    <!--                                                        -->
    <ng-template #popUpModal>
      <modal-widget [entity]="currentModal" [layout]="'shadow_box'" [options]="{}"></modal-widget>
    </ng-template>

  `
})
export class AppengineWidgetSetComponent extends GenericWidgetSetComponent implements OnInit{
  @Input() entity: EntityList = null;
  @Input() options: any = {};
  @Input() layout: string = null;
  @ViewChild('popUpModal', {read: TemplateRef}) modalWizard: TemplateRef<any>;
  @Output() widgetEvent = new EventEmitter<WidgetEventStruct>();
  forwardEvent(widgetEvent: WidgetEventStruct) {
    if (widgetEvent.event_type == 'select_entity') {
      this.selected = widgetEvent.event_body;
    }
    this.widgetEvent.emit(widgetEvent);
  }

  constructor(public modalService: BsModalService) {
    super(modalService);
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public next(event = null) {
    if (event) event.preventDefault();
    this.fetching = true;
    this.entity.nextPromise(this.options).then(r => {
      this.filterRecords(this.filter);
      this.fetching = false;
    });
  }

  public saveEntity() {
    alert('pending');
    return true;
  }

  add(modal, e = null) {
    console.log('ADD MODAL', modal);
    if (e) e.preventDefault();

    let widget_options = {edit: this.options['edit'] || false};

    this.newEntity = this.entity.repo.entity({});
    this.newEntity.meta['new'] = true;
    let dialog = new ModalWidget(
      this.options['title'] || "Create",
      ModalWidgetEnum.MODAL_WIDGET__CREATE,
      this.newEntity,
      widget_options,
      'shadowbox');
    this.showModal(dialog, modal)
  }

}
