
import { Component, Inject, Input, NgZone } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location }               from '@angular/common';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { CmsAlertEntity } from '../../../entities/cms/alert.entity';
import { CmsAlertRepo } from '../../../repos/cms/alert.repo';

@Component({
  templateUrl: './list.component.html',
})
export class CmsAlertListComponent {
  public entries: Array<CmsAlertEntity>;
  public queue = null;
  public loading = false;

  constructor(public repo: CmsAlertRepo, public zone: NgZone) {
    this.zone.run(
      () => {
        this.repo.getCms().then((u: any) => {this.entries = u;});
      }
    )
  }



/*
    add(e) {
      e.preventDefault();
      this.entries["items"].push(this.repo.entity({id: "new"}));
    }*/
}
