
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { FirebaseAuthService }        from '../noizu/services/firebase-auth.service';
import { AppengineRepo }      from './appengine.repo';
import { AlertEntity } from '../entities/alert.entity';
import {AppengineEntityList} from '../noizu/structs/appengine-entity-list';
import {environment} from '../../environments/environment';

@Injectable()
export class AlertRepo extends AppengineRepo {
  public _kind = "alerts";
  public _singular = "alert";

  public _ingv_endpoint = environment.ingv2_url + "/api";

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  entity(record) {
    return new AlertEntity(this.client, this.auth, record, this);
  }

  multiStatusPromise(entry_set) {
    let ids = [];
    entry_set.items.forEach( (v) => {
        ids.push(v.sref());
      }
    );

    return this._put(`${this._ingv_endpoint}/v1.1/admin-tools/alerts/status`, {alerts: ids}, (response, resolve) => {
      resolve(response);
    })
  }


  multiDetailsPromise(entry_set) {
    let ids = [];
    entry_set.items.forEach( (v) => {
        ids.push(v.sref());
      }
    );

    return this._put(`${this._ingv_endpoint}/v1.1/admin-tools/alerts/details`, {alerts: ids}, (response, resolve) => {
      resolve(response);
    })
  }

  getUserAlertListPromise(userIdentifier:string, options = {}) {
    return this._get(
      `${this.apiBase()}/user/${userIdentifier}/alerts/as-templates`,
      (payload, resolve) =>
        {
          let entries = [];
          for(let alertSet in payload) {
            if(alertSet.indexOf("Alert") > -1) {
              const len = payload[alertSet].length;
              for(let x = 0; x < len; x++) {
                entries.push(this.entity(payload[alertSet][x]));
              }
            }
          }

          // Sort
          if (options['sort']) entries = entries.sort(options['sort']);
          else if (this._sort) entries = entries.sort(this._sort);
          else entries = entries.sort((a,b) => {
              if (a.weight == b.weight) return 0;
              if (a.weight == null) return -1;
              if (b.weight == null) return 1;
              return (a.weight < b.weight) ? -1 : 1;
            });
          let set = new AppengineEntityList(this._kind, entries, payload.cursor || null, this._containerContents, this, options);
          resolve(set);
        },
      {}
    );
  }

  getAlertStatePromise(alertIdentifier:string) {
    return this._get( this.ingvEndpoint() +`/user-alerts/ref.alert.${alertIdentifier}/status`,
      (payload, resolve) => {resolve(payload);},
      {}
    );
  }
}
