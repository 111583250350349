
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { FirebaseAuthService }        from '../../noizu/services/firebase-auth.service';
import { AppengineRepo }      from '../appengine.repo';
import { UserNotificationEntity } from '../../entities/user/notification.entity';

@Injectable()
export class UserNotificationRepo extends AppengineRepo {
  public _kind = "notifications";
  public _singular = "notification";

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  entity(record) {
    return new UserNotificationEntity(this.client, this.auth, record)
  }

  getUserListPromise(userIdentifier:string, options = {}) {
    let url = `${this.apiBase()}/user/${userIdentifier}/sensorNotifications`;
    return this._getListPromise(url, (record) => {return this.entity(record); }, options);
  }

  deleteNotification(uid:string, nid:string, options = {}) {
    let url = `${this.apiBase()}/user/${uid}/sensorNotification/${nid}`;
    return this._deleteItemPromise(url, (record) => {return record; }, options);
  }

  getNotificationsStatus(userIdentifier:string, options = {}) {
    let url = `${this.ingvEndpoint()}/users/ref.user.${userIdentifier}/notification-status`;
    return this._get(url,(record, resolve) => {resolve(record); },options);
  }
}
