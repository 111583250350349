import { NoizuStruct }        from '../noizu/structs/noizu-struct';
import { ValueAndUnitUnit } from './value-and-unit/unit';

export class ValueAndUnit extends NoizuStruct {
  public value: number;
  public unit: ValueAndUnitUnit;

  constructor(json: any) {
      super();

      this.value = json["value"];
      this.unit = new ValueAndUnitUnit(json);
  } // end constructor

  public toJson() {
    return {
      value: this.value,
      unit: this.unit.enum
    }
  }

}
