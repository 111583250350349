import {Component, Input, Inject, ChangeDetectorRef, NgZone, OnInit, Output, EventEmitter} from '@angular/core';
import {DeviceDefinitionRepo, DeviceRepo} from '../../../../repos';
import {ToasterService} from 'angular2-toaster';
import {AuthService} from '../../../../services/auth.service';
import {SimulatorWidget} from '../../../../entities/device/simulator-widget';
import {DeviceDefinitionEntity, FieldSimulatorWidget} from '../../../../entities/device';
// import {forEach} from '@angular/router/src/utils/collection';
import {DeviceEntity, DeviceFeedLogicalWidget} from '../../../../entities';
import {DeviceWidgetEventStruct} from '../../device/device-widget-event.struct';
import {LogicalDeviceWidgetEventStruct} from './logical-device-widget-event.struct';
import {environment} from '../../../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {FirebaseAuthService} from '../../../../noizu/services/firebase-auth.service';
import {UserPreferencesService} from '../../../../services/user-preferences.service';

@Component({
  selector: 'device-feed-widget',
  template: `
    <div *ngIf="!entity">
      <widget-spinner></widget-spinner>
    </div>



    <ng-container *ngIf="entity">

    <ng-container [ngSwitch]="layout">

      <div class="device-feed-widget" *ngSwitchDefault>

        <div class="row">
          <div class="col">
            <widget *ngIf="entity?.subject?.extended?.chart" (widgetEvent)="widgetEvent($event)" [entity]="entity.subject.extended.chart" [options]="options" [layout]="layout" ></widget>
          </div>
          <div class="col">
            <widget *ngIf="entity?.subject?.extended?.reading" (widgetEvent)="widgetEvent($event)" [entity]="entity.subject.extended.reading" [options]="options" [layout]="layout" ></widget>
          </div>
        </div>

      </div>


    </ng-container>



    </ng-container>
  `
})
export class DeviceFeedWidgetComponent implements OnInit{
  @Input() entity: DeviceFeedLogicalWidget | null = null;
  @Input() templateHint: string = null;
  @Input() options: any = null;
  @Input() layout = 'standard';
  @Output() widgetEvent = new EventEmitter<LogicalDeviceWidgetEventStruct>();
  public menu = false;
  public dude = false;


  public current_view = 0;
  public total_views = 3;

  public feed_version = '1.0';
  public feed_version_toggle = false;



  forwardEvent(e, widgetEvent: LogicalDeviceWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }

  nextView(e) {
    e.preventDefault()
    this.current_view++;
    if (this.current_view >= this.total_views) this.current_view = 0;
  }

  previousView(e) {
    e.preventDefault()
    this.current_view--;
    if (this.current_view < 0) this.current_view = this.total_views - 1;
  }

  constructor(
  ) {

  }

  tog(event) {
    this.dude=!this.dude;
  }

  ngOnInit(): void {

  }
}
