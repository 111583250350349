
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity } from '../../lacrosse.entity';
import { FirebaseAuthService }        from '../../../noizu/services/firebase-auth.service';
import { DeviceFieldDefinitionRepo }      from '../../../repos/device/field/definition.repo';
import { ValueAndUnitUnit }   from '../../../structs/value-and-unit/unit';
import { Transformation }   from '../../../structs/transformation';
import { DeviceFieldDefinitionSpotBinaryEncoding } from '../../../structs/device/field/definition/spot-binary-encoding';
import {DiagnosticReading} from '../../data-stream/diagnostic-card.entity';
import {UnitEnum} from '../../../enums/unit.enum';
import {WidgetEnum} from '../../../enums';

export class DeviceFieldDefinitionEntity extends LacrosseEntity {
  public _kind = "sensor-fields";
  public _singular = "sensor-field";

  public name: string;
  public type: number; // @TODO type
  public unit: any; // @TODO type
  public is_derived_type: boolean;
  public is_variant_type: boolean;
  public postProcessing: Transformation | null; // @TODO type
  public stageOneFormula: DeviceFieldDefinitionSpotBinaryEncoding; // @TODO type
  public stageTwoFormula: Transformation | null; // @TODO type
  public stageThreeFormula: Transformation | null; // @TODO type
  public spotBinaryEncoding: DeviceFieldDefinitionSpotBinaryEncoding; // @TODO type
  public precision: number;
  public accuracy: number;
  public rangeHigh: number;
  public rangeLow: number;
  public width: number;

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
  } // end constructor

  refresh(data: any) {
    super.refresh(data);
    //console.log("Field", data);
    let pp = data["postProcessing"] || data["post_processing"];
    this.postProcessing = pp ? new Transformation(pp) : null;
    let sbe = data["spotBinaryEncoding"] || data["spot_binary_encoding"];
    this.spotBinaryEncoding = sbe ? new DeviceFieldDefinitionSpotBinaryEncoding(sbe) : null;
    let t = data["stageOneFormula"] || data["stage_one_formula"];
    this.stageOneFormula = t ? new DeviceFieldDefinitionSpotBinaryEncoding(t) : null;
    let st = data["stageTwoFormula"] || data["stage_two_formula"];
    this.stageTwoFormula = st ? new Transformation(st) : null;
    let st2 = data["stageThreeFormula"] || data["stage_three_formula"];
    this.stageThreeFormula = st2 ? new Transformation(pp) : null;

    if (this.stageOneFormula) {
      this.width = this.stageOneFormula.width;
    } else {
      this.width = 0;
    }

    this.handle = data["handle"];
    this.name = data["name"];
    this.type = data["type"];
    this.unit = data["unit"];

    if (data['unit']) {
      // Unit names are in snake case while Enums are in Camel. Therefore we need a mapper method to get the true enum.
      this.unit = (<any>UnitEnum)[data['unit']];
      if (this.unit === undefined) {
        //console.log("Unsupported Unit " + this.handle + " " + data['unit'])
        this.unit = (<any>UnitEnum)[UnitEnum.None];
      }
    } else {
      this.unit = (<any>UnitEnum)[UnitEnum.None];
    }

    this.is_derived_type = data["isDerivedType"] || data["is_derived_type"] || false;
    this.is_variant_type = data["isVariantType"] || data["is_variant_type"] || false;
    this.precision = data["precision"];
    this.accuracy = data["accuracy"];

    if (data["rangeHigh"] || data["rangeHigh"] === 0) {
      this.rangeHigh = data["rangeHigh"];
    } else if (data["range_high"] || data["range_high"] === 0) {
      this.rangeHigh = data["range_high"];
    }

    if (data["rangeLow"] || data["rangeLow"] === 0) {
      this.rangeLow = data["rangeLow"];
    } else if (data["range_low"] || data["range_low"] === 0) {
      this.rangeLow = data["range_low"];
    }


    this.meta.include_previous = false;
    if (this.handle == 'rain-2.0') this.meta.include_previous = true;
    if (this.handle == 'rain-2.0b') this.meta.include_previous = true;

    return this;
  }

  unitType() {
    return UnitEnum[this.unit];
  }

  toJson(options: any = {}) {
    /* Most of this is probably wrong, but validation can go here.
    if (this.is_derived_type) {
      this.stageOneFormula = null;
      this.spotBinaryEncoding = null;
    } else {
      this.stageTwoFormula = null;
      this.postProcessing = null;
      if (this.stageOneFormula.width == 0) {
        this.stageOneFormula.width = 12;
      }
      if (this.spotBinaryEncoding.width == 0) {
        this.spotBinaryEncoding.width = 12;
      }
    }*/
    let json =  super.toJson(options);
    json["unit"] = this.unitType();
    return json;
  }

  filter(filter: string) {
    if (filter) {
      if (this.meta['filter'] == filter) return this.meta['filter_result'];

      this.meta['filter'] = filter;
      this.meta['filter_result'] = false;

      let name_filter = filter.match(/name:"([a-zA-Z\-_0-9 ]+)"/)
      if (name_filter) {
        name_filter.forEach((v) => {
          if (this.name == v) {
            this.meta.filter_result = true;
            return true;
          }
        });
      }

      name_filter = filter.match(/name:([a-zA-Z\-_0-9]+)/)
      if (name_filter) {
        name_filter.forEach((v) => {
          if (this.name == v) {
            this.meta.filter_result = true;
            return true;
          }
        });
      }


      let handle_filter = filter.match(/handle:"([a-zA-Z\-_0-9 ]+)"/)
      if (handle_filter) {
        handle_filter.forEach((v) => {
          if (this.handle == v) {
            this.meta.filter_result = true;
            return true;
          }
        });
      }

      handle_filter = filter.match(/handle:([a-zA-Z\-_0-9]+)/)
      if (handle_filter) {
        handle_filter.forEach((v) => {
          if (this.handle == v) {
            this.meta.filter_result = true;
            return true;
          }
        });
      }

      let type_filter = filter.match(/type:"([0-9]+)"/)
      if (type_filter) {
        type_filter.forEach((v) => {
          if (`${this.type}` == v) {
            this.meta.filter_result = true;
            return true;
          }
        });
      }

      let unit_filter = filter.match(/unit:([a-zA-Z\-_0-9]+)/)
      if (unit_filter) {
        unit_filter.forEach((v) => {
          console.log(this.unit);
          if (this.unit == v) {
            this.meta.filter_result = true;
            return true;
          }
        });
      }

      return this.meta.filter_result;
    }  else {
      return true;
    }
  }

  widget() {
    return "embed-device-field";
  }

  widget_type() {
    return WidgetEnum.EMBED_WIDGET__DEVICE_FIELD;
  }
  //-------------------------------------------------------
  // Encoders
  //-------------------------------------------------------
  binaryEncodeField__Humidity(value: number) {
    return Math.round(value).toString(16).padStart(3, '0');
  }

  binaryEncodeField__Rain(value: number) {
    let current = Math.round(value / 0.254).toString(16).padStart(3, '0');
    return current;
  }

  binaryEncodeField__Rain24(value: number) {
    let current = Math.round(value / 0.254).toString(16).padStart(4, '0');
    return current.substring(0,2) + "AA" + current.substring(2,5);
  }

  binaryEncodeField__Temperature(value: number) {
    return Math.round(((value + 40) * 10)).toString(16).padStart(3, '0');
  }

  binaryEncodeField__NotSupported(value: number) {
    return "AAA";
  }

  binaryEncodeField__WindSpeed(value: number) {
    return Math.round(value * 10).toString(16).padStart(3, '0');
  }

  binaryEncodeField__WindGust(value: number) {
    return Math.round(value * 10).toString(16).padStart(3, '0');
  }


  binaryEncodeField__WindHeading(value: number) {
    return Math.round(value ).toString(16).padStart(3, '0');
  }

  binaryEncodeField__Lightning(value: number) {
    return "AAA"; // pending
  }

  binaryEncodeField__TemperatureTX233(value: number) {
    return "AAA"; // pending
  }

  binaryEncodeField__OnOff(value: number) {
    return (value == 0) ? '0F0' : 'F0F';
  }

  binaryEncodeField__Humidity2(value: number) {
    return Math.round(value).toString(16).padStart(3, '0');
  }

  binaryEncodeField__Thermometer(value: number) {
    return Math.round(((value + 50) * 10)).toString(16).padStart(3, '0');
  }

  binaryEncodeField__RainV2(input: any) {
    let current = Math.round(input.current / 0.254).toString(16).padStart(4, '0');
    let previous = Math.round(input.previous / 0.254).toString(16).padStart(4, '0');
    return current.substring(0,2) + "AA" + current.substring(2,5) + previous.substring(0,2) + "AA" + previous.substring(2,5);
  }


  binaryEncodeField__RainV2b(input: any) {
    let current = Math.round(input.current / 0.254).toString(16).padStart(3, '0');
    let previous = Math.round(input.previous / 0.254).toString(16).padStart(3, '0');
    return current + previous;
  }


  binaryEncodeField__BarometricPressure(value: number) {
    return Math.round(((value + 800) * 1)).toString(16).padStart(3, '0');
  }

  binaryEncode(input) {
    switch(this.handle) {
      case 'humidity': return this.binaryEncodeField__Humidity(input.current);
      case 'windSpeed': return this.binaryEncodeField__WindSpeed(input.current);
      case 'rain': return this.binaryEncodeField__Rain(input.current);
      case 'rain24bit': return this.binaryEncodeField__Rain24(input.current);
      case 'temperature': return this.binaryEncodeField__Temperature(input.current);
      case 'windGust': return this.binaryEncodeField__WindGust(input.current);
      case 'notSupported': return this.binaryEncodeField__NotSupported(input.current);
      case 'windHeading': return this.binaryEncodeField__WindHeading(input.current);
      case 'lightning': return this.binaryEncodeField__Lightning(input.current);
      case 'temperature-tx233': return this.binaryEncodeField__TemperatureTX233(input.current);
      case 'onoff': return this.binaryEncodeField__OnOff(input.current);
      case 'humidity2': return this.binaryEncodeField__Humidity2(input.current);
      case 'motion': return this.binaryEncodeField__OnOff(input.current);
      case 'onOff': return this.binaryEncodeField__OnOff(input.current);
      case 'wetDry': return this.binaryEncodeField__OnOff(input.current);
      case 'contact': return this.binaryEncodeField__OnOff(input.current);
      case 'thermometer': return this.binaryEncodeField__Thermometer(input.current);
      case 'rain-2.0': return this.binaryEncodeField__RainV2(input);
      case 'rain-2.0b': return this.binaryEncodeField__RainV2b(input);
      case 'barometric_pressure': return this.binaryEncodeField__BarometricPressure(input.current);
      default: return '';
    }
  }


  /*

   //Get fields for type and convert fields into data to create chain of Hex values that the devices send
  //Types provide a chain of letters that signifies data in order. loop through that and get appropriate fields.
  formatData() {
    let returnData = "";
    let f = this.fields.split("");

    for (var i = 0; i < f.length; i++) {
      //For each field, in order, get the data in its textbox, convert units and remove decimal according to docs,
      //ensure its 3 Hex digits long, and append to the data string. (exception, 6 Hex digits for 24bit rain)
      switch(f[i]) {

        break;
        case "t" :
        	returnData += ("000" + ((40 + parseInt(this.simData["t"])) * 10).toString(16)).slice(-3);
        break;
        case "h" :
        	returnData += ("000" + (parseInt(this.simData["h"]).toString(16))).slice(-3);
        break;
        case "s" :
        	returnData += ("000" + (parseInt(this.simData["s"]) * 10).toString(16)).slice(-3);
        break;
        case "d" :
        	returnData += ("000" + (parseInt(this.simData["d"])).toString(16)).slice(-3);
        break;
        case "r" :
        	//Rain
        	returnData += ("000" + (Math.round(parseFloat(this.simData["r"]) * 100)).toString(16)).slice(-3);
        break;
        case "e" :
        	//24 bit Rain (2 hex characters first half of binary, + AA + last 2 Hex characters, second half of binary)
        	var n = parseFloat(this.simData["e"])*100;
          var b = ("0000000000000000" + n.toString(2)).slice(-16);
        	var s = ("00" + parseInt(b.slice(0,8),2).toString(16).toUpperCase()).slice(-2);
        	s+="AA";
        	s += ("00" + parseInt(b.slice(8),2).toString(16).toUpperCase()).slice(-2);
        	returnData += s;
        break;
        case "n":
          returnData += "AAA";
        break;
      }
    }
    return returnData.toUpperCase();
  }

   */

} // end Package
