
          <div class="embed-ds-device-card" *ngIf="!editMode">
              <div class="row">
                  <div class="col font-xl"><b>Text 1:</b> {{entity.messages.one}}</div>
                  <div class="col font-xl"><b>Text 2:</b> {{entity.messages.two}}</div>
              </div>
              <div class="row">
                  <div class="col"><b class="font-xl">Device:</b> {{entity.link.device_association}}</div>
                  <div class="col font-xl"><b>Reading:</b> {{entity.link.linkType()}}</div>
              </div>
          </div>
          <div class="embed-ds-device-card" *ngIf="editMode">
              <div class="row">
                  <div class="col-12">
                      <div class="card mb-0 pb-0 border-warning">
                          <div class="card-body" *ngIf="entity">
                              <!-- Type Specific -->
                              <div class="row"><div class="col-4"><b>Text One</b></div>
                                  <div class="col-8">
                                      <input
                                              type="text"
                                              [(ngModel)]="entity.messages.one"
                                              (ngModelChange)="editMessageOne($event)"
                                              name="[message]one"
                                              size="10"/>
                                  </div></div>
                              <div class="row"><div class="col-4"><b>Text Two</b></div><div class="col-8">
                                  <input
                                          type="text"
                                          [(ngModel)]="entity.messages.two"
                                          (ngModelChange)="editMessageTwo($event)"
                                          name="[message]two"
                                          size="10"/>
                              </div></div>


                              <div class="row"><div class="col-4"><b>Time Zone</b></div><div class="col-8">
                                <ng-select
                                  name="[link]tz"
                                  [multiple]="false"
                                  [(ngModel)]="entity.link.time_zone"
                                  bindLabel="text"
                                  bindValue="value"
                                  (ngModelChange)="setTimeZone($event)"
                                  [items]="timeZoneOptions"
                                ></ng-select>
                              </div></div>

                              <div class="row"><div class="col-4"><b>Device Association</b></div>
                                  <div class="col-8">
                                    <ng-select
                                      [multiple]="false"
                                      name="[link]device"
                                      [(ngModel)]="entity.link.device_association"
                                      bindLabel="text"
                                      bindValue="value"
                                      (ngModelChange)="editDeviceAssociation($event)"
                                      [items]="deviceAssociationOptions"
                                    ></ng-select>
                                  </div></div>

                              <div class="row"><div class="col-4"><b>Device</b></div>
                                  <div class="col-8">
                                      {{entity.link.device}}
                                  </div>
                              </div>

                              <div class="row"><div class="col-4"><b>Type</b></div><div class="col-8">
                                <ng-select
                                  [name]="'[link]type'"
                                  [multiple]="false"
                                  [(ngModel)]="entity.link.type"
                                  bindLabel="text"
                                  bindValue="value"
                                  (ngModelChange)="setLink($event)"
                                  [items]="linkOptions"
                                ></ng-select>


                              </div></div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
    