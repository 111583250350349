
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { DomainObjectRepo } from '../../noizu/domain-object-repo';
import { FirebaseAuthService }        from '../../noizu/services/firebase-auth.service';
import { EmailTemplateEntity }      from '../../entities/data/email-template.entity';
import { AppengineRepo }      from '../appengine.repo';

@Injectable()
export class EmailTemplateRepo extends AppengineRepo {
  public _kind = "null";
  public _singular = "null";
  public _appengine = false;

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  entity(record) {
    return new EmailTemplateEntity(this.client, this.auth, record)
  }

  getEmailTemplates(options = {}){
    options = options || {}
    options['set'] = true;
    return this._getListPromise(`${this.ingvEndpoint()}/email/templates`, (record) => {return this.entity(record); }, options);
  }

}
