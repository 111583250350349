
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { DomainObjectRepo } from '../noizu/domain-object-repo';
import { FirebaseAuthService }        from '../noizu/services/firebase-auth.service';
import { AppengineRepo }      from './appengine.repo';
import { GatewayEntity }      from '../entities/gateway.entity';

@Injectable()
export class GatewayRepo extends AppengineRepo {
  public _kind = "gateways";
  public _singular = "gateway";

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  entity(record) {
    return new GatewayEntity(this.client, this.auth, record)
  }

  getBySerialPromise(serial: string, options:any = {}) {
    if (this.auth.hasPermission("read-gateway",5)) {
      return this._getEntityPromise(`${this.apiBase()}/gatewayBySerial/V2/${serial.toUpperCase()}`, (record, resolve) => { resolve( this.entity(record) );}, options);
    } else {
      return new Promise((reject) => reject({success:false, msg:"You do not have permission"}));
    }
  }

}
