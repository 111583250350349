import {ValueAndUnit} from '../../../structs/value-and-unit';
import {AlertFacadeEntity} from '../facade.entity';
import {WidgetEnum_Alert_Facade} from '../../../enums/widget/alert';

export class AlertWetDryFacadeEntity extends AlertFacadeEntity {
  public triggerIfWet: any;

  public constructor(alert, json) {
    super(alert, json);
    this.triggerIfWet = json.triggerIfWet;
  }

  //-----------------------------------
  //
  //-----------------------------------
  public get template(): string | null {
    return 'wet-dry';
  }

  alert_facade_widget() {
    return WidgetEnum_Alert_Facade.ALERT_FACADE_WIDGET__WET_DRY;
  }
}
