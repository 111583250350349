import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { DomainObjectRepo } from '../../noizu/domain-object-repo';
import { FirebaseAuthService } from '../../noizu/services/firebase-auth.service';
import { ElixirRepo } from '../elixir.repo';
import { RequestLogSet } from '../../entities/log/request-log.set';

@Injectable()
export class RequestLogRepo extends ElixirRepo {
  public _kind = 'request-logs';
  public _singular = 'request-log';

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  entity(record) {
    return new RequestLogSet(this.client, this.auth, record)
  }

  apiBaseLogs() {
    return `${this._endpoint}/v1.2`;
  }

  query(subject: String, type: String, from: Date, to: Date) {
    console.log(`Query ${subject}, Type ${type}`, from, to);
    const fromUnix = Math.round(from.getTime() / 1000);
    const toUnix = Math.round(to.getTime() / 1000);
    let url: String = null;

    if (type === 'gateway') {
      url = `${this.apiBaseLogs()}/admin-tools/gateway/${subject}/request-logs?from=${fromUnix}&to=${toUnix}`;
    } else if (type === 'device') {
      url = `${this.apiBaseLogs()}/admin-tools/device/${subject}/request-logs?from=${fromUnix}&to=${toUnix}`;
    }

    if (url) {
      return this._get(url, (data, resolve) => {
          const r = new RequestLogSet(this.client, this.auth, data);
          resolve(r);
      })
    } else {
      return new Promise((reject) => reject({success: false, msg: 'Invalid Type'}));
    }
  }
}
