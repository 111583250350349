import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToasterService} from 'angular2-toaster';
import {AlertEntity} from '../../../entities/alert.entity';
import {WidgetComponent} from '../../widget.component';
import {WidgetEmbedComponent} from '../../../widgets/embed.component';
import {WidgetBase} from '../widget-base';
import {UserEntity} from '../../../entities';
import {SubscriberWidgetEventStruct} from '../subscriber-object/subscriber-widget-event.struct';
import {UserWidgetEventStruct} from './user-widget-event.struct';

@Component({
  selector: 'user-settings-widget',
  template: `
    <div class="card shadow" *ngIf="entity">
      <div class="card-header">
        <div class="row">
          <div class="col">
            User Settings
            <span *ngIf="options" class="float-right"><i (click)="toggleExpand()" class="fa fa-sm" [ngClass]="{'fa-chevron-down': !options.expand, 'fa-chevron-up': options.expand}"> </i></span>
          </div>
        </div>
      </div>
      <div class="card-body" *ngIf="entity && expandWidget()">
        <div class="card-block card-accent-dark">
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Value</th>
                <th scope="col">Is Null</th>
                <th scope="col">Modified On</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let set of mySettings">
                <td>{{set.setting}}</td>
                <td>{{set.value}}</td>
                <td>{{set.isNull}}</td>
                <td>{{set.modifiedOn}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  `
})
export class UserSettingsWidgetComponent extends WidgetBase implements OnInit {
  public editMode = false;
  @Input() layout: string = null;
  @Input() entity: UserEntity;
  @Input() options: any;
  @Output() widgetEvent = new EventEmitter<UserWidgetEventStruct>();
  forwardEvent(e, widgetEvent: UserWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }

  public mySettings: any = [];

  constructor(public toasterService: ToasterService) {
    super();
  }



  ngOnInit() {
    if (this.entity) {
      let z = this.entity.settings;
      this.mySettings = [];
      for (let x in z) {
        //console.log("key:", x, "value:", z[x]);
        this.mySettings.push({setting: z[x]["setting"], value: z[x]["value"], isNull: z[x]["isNull"], encodingType: z[x]["encodingType"], modifiedOn: z[x]["modifiedOn"]});
      }
      //console.log(this.mySettings);
    }
  }

}
