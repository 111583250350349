import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WidgetBase} from '../../widget-base';
import {UserSubscriptionEntity} from '../../../../entities';
import {UserWidgetEventStruct} from '../user-widget-event.struct';
import {SubscriptionDefinitionRepo} from '../../../../repos';
import {WidgetEventStruct} from '../../../widget-event.struct';
import {WidgetEnum} from '../../../../enums';
import {UserSubscriptionPaymentEntity} from '../../../../entities/user/extended/subscription.payment.entity';

@Component({
  selector: 'extended-user-subscription-payment',
  template: `


    <ng-container [ngSwitch]="layout">

      <!-- =============================================== -->
      <!-- Table View                                      -->
      <!-- =============================================== -->
      <ng-container *ngSwitchCase="'table'">
        [PAYMENT]
      </ng-container>

      <!-- =============================================== -->
      <!-- Default View (Card)                             -->
      <!-- =============================================== -->
      <ng-container *ngSwitchDefault>
        <ng-container>

          <div class="card">
            <div class="card-header p-1">
              Payment #{{entity.identifier}} ({{entity.source}}) <span class="badge rounded-pill {{status_style()}}">{{entity.payment_status}}</span>
              <div class="card-header-actions">
                <span class="pr-2"><i (click)="expandCard = !expandCard" class="fa fa-sm" [ngClass]="{'fa-chevron-down': !expandCard, 'fa-chevron-up': expandCard}"></i></span>
              </div>

            </div>
            <div class="card-body  p-2" *ngIf="expandCard">


              <div class="row">
                <div class="col-3">transaction_identifier</div>
                <div class="col">{{entity.transaction_identifier}}</div>
              </div>
              <div class="row">
                <div class="col-3">subscription_group_identifier</div>
                <div class="col">{{entity.subscription_group_identifier}}</div>
              </div>
              <div class="row">
                <div class="col-3">source</div>
                <div class="col">{{entity.source}}</div>
              </div>
              <div class="row">
                <div class="col-3">product_identifier</div>
                <div class="col">{{entity.product_identifier}}</div>
              </div>
              <div class="row">
                <div class="col-3">payment_status</div>
                <div class="col"><span class="badge rounded-pill {{status_style()}}">{{entity.payment_status}}</span></div>
              </div>
              <div class="row">
                <div class="col-3">package_name</div>
                <div class="col">{{entity.package_name}}</div>
              </div>
              <div class="row">
                <div class="col-3">owner</div>
                <div class="col">{{entity.owner}}</div>
              </div>
              <div class="row">
                <div class="col-3">original_transaction_identifier</div>
                <div class="col">{{entity.original_transaction_identifier}}</div>
              </div>
              <div class="row">
                <div class="col-3">purchase_date</div>
                <div class="col"><span *ngIf="entity.purchase_date">{{entity.purchase_date| date:'medium'}}</span><span *ngIf="!entity.original_purchase_date">[Blank]</span></div>
              </div>
              <div class="row">
                <div class="col-3">original_purchase_date</div>
                <div class="col"><span *ngIf="entity.original_purchase_date">{{entity.original_purchase_date| date:'medium'}}</span><span *ngIf="!entity.original_purchase_date">[Blank]</span></div>
              </div>
              <div class="row">
                <div class="col-3">modified_on</div>
                <div class="col"><span *ngIf="entity.modified_on">{{entity.modified_on| date:'medium'}}</span><span *ngIf="!entity.modified_on">[Blank]</span></div>
              </div>
              <div class="row">
                <div class="col-3">created_on</div>
                <div class="col"><span *ngIf="entity.created_on">{{entity.created_on| date:'medium'}}</span><span *ngIf="!entity.modified_on">[Blank]</span></div>
              </div>

            </div>
          </div>


        </ng-container>
      </ng-container>

    </ng-container>

  `
})
export class UserExtendedSubscriptionPaymentWidgetComponent extends WidgetBase implements OnInit {
  public editMode = false;
  public expandCard = false;
  @Input() layout: string = null;
  @Input() entity: UserSubscriptionPaymentEntity;
  @Input() options: any;
  @Output() widgetEvent = new EventEmitter<UserWidgetEventStruct>();
  forwardEvent(e, widgetEvent: UserWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }
  widgetEventHandler(event: WidgetEventStruct) {
  }

  constructor(public typeRepo: SubscriptionDefinitionRepo) {
    super();
  }

  status_style() {
    switch (this.entity.payment_status) {
      case 'valid': return "bg-success";
      default:
        return 'bg-secondary';
    }
  }

  ngOnInit(): void {
  }
}
