
import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from '../lacrosse.entity';
import { FirebaseAuthService }            from '../../noizu/services/firebase-auth.service';
import {EmailEntity} from './email.entity';
import {EmailWidgetEnum} from '../../enums';
//import { DeviceDefinitionRepo }           from '../../repos/device/definition.repo';


export class EmailTemplateSection {
  public kind: string;
  constructor(json) {
    this.kind = json.kind;
  }
}

export class EmailTemplateDynamicSelectorEntity extends EmailTemplateSection {
  public selector: any;
  constructor(json) {
    super(json);
    this.kind = 'Elixir.Noizu.EmailService.Email.Binding.Substitution.Dynamic.Selector';
    this.selector = json.selector && this.extract_selectors(json.selector);
  }

  public extract_selectors(json) {
    let selectors = [];
    for(let i = 0; i < json.length; i++) {
      if (json[i].tuple) {
        let entry = "";
        for(let j = 0; j < json[i].tuple.length; j++) {
          if (j == 0) {
            entry += json[i].tuple[j];
          } else {
            entry += "." + json[i].tuple[j];
          }
        }
        selectors.push(entry);
      } else {
        selectors.push(json[i]);
      }
    }
    return selectors;
  }
}

export class EmailTemplateDynamicFormulaIfThenEntity extends EmailTemplateSection {
  public identifier: any;
  public condition_clause: any;
  public then_clause: any;
  public else_clause: any;
  public selectors: any;
  constructor(json) {
    super(json);
    this.identifier = json.identifier;
    this.condition_clause = json.condition_clause && EmailTemplateDynamicSectionFactory.factory(json.condition_clause);
    this.then_clause = json.then_clause && EmailTemplateDynamicSectionFactory.factory(json.then_clause);
    this.else_clause = json.else_clause && EmailTemplateDynamicSectionFactory.factory(json.else_clause);
    this.selectors = [];
    if (json.selectors) {
      for (let i = 0; i < json.selectors.length; i++) {
        this.selectors.push(EmailTemplateDynamicSectionFactory.factory(json.selectors[i]));
      }
    }
  }
}

export class EmailTemplateDynamicFormulaEachEntity extends EmailTemplateSection {
  public identifier: any;
  public clause: any;
  public arguments: any;
  constructor(json) {
    super(json);
    this.identifier = json.identifier;
    this.clause = json.clause && EmailTemplateDynamicSectionFactory.factory(json.clause);
    this.arguments = [];
    if (json.arguments) {
      for (let i = 0; i < json.arguments.length; i++) {
        json.arguments.push(EmailTemplateDynamicSectionFactory.factory(json.arguments[i]));
      }
    }
  }
}

export class EmailTemplateDynamicSectionEntity extends EmailTemplateSection {
  public clause: any;
  public current_selector: any;
  public section: string;
  public match: any;
  public bind: any;
  public errors: any;
  public children: any;
  public meta: any;
  public vsn: string;

  constructor(json) {
    super(json);
    this.current_selector = json.current_selector && EmailTemplateDynamicSectionFactory.factory(json.current_selector);
    this.clause = json.clause && EmailTemplateDynamicSectionFactory.factory(json.clause);
    this.section = json.section;
    this.match = json.match;
    this.bind = [];
    if (json.bind) {
      for (let i = 0; i < json.bind.length; i++) {
        this.bind.push(EmailTemplateDynamicSectionFactory.factory(json.bind[i]));
      }
    }
    this.errors = json.errors;
    this.children = [];
    if (Array.isArray(json.children)) {
      for (let i = 0; i < json.children.length; i++) {
        let child = EmailTemplateDynamicSectionFactory.factory(json.children[i]);
        this.children.push(child);
      }
    }
    this.meta = json.meta;
    this.vsn = json.vsn;
  }
}

export class EmailTemplateDynamicSectionFactory {
  static factory(json) {
    switch(json.kind) {
      case 'Elixir.Noizu.EmailService.Email.Binding.Substitution.Dynamic.Section': return new EmailTemplateDynamicSectionEntity(json);
      case 'Elixir.Noizu.EmailService.Email.Binding.Substitution.Dynamic.Formula.IfThen': return new EmailTemplateDynamicFormulaIfThenEntity(json);
      case 'Elixir.Noizu.EmailService.Email.Binding.Substitution.Dynamic.Formula.Each': return new EmailTemplateDynamicFormulaEachEntity(json);
      case 'Elixir.Noizu.EmailService.Email.Binding.Substitution.Dynamic.Selector': return new EmailTemplateDynamicSelectorEntity(json);
      default:
        if (json.selector) return new EmailTemplateDynamicSelectorEntity(json);
        return json.kind;
    }
  }
}


export class EmailTemplateCacheEntity {
  public version: string;
  section_stack: any;
  constructor(json) {
    this.version = json.version;
    this.section_stack = []
    if (Array.isArray(json.section_stack)) {
      for (let i = 0; i < json.section_stack.length; i++) {
        let child = EmailTemplateDynamicSectionFactory.factory(json.section_stack[i]);
        this.section_stack.push(child);
      }
    }
  }
}

export class EmailTemplateEntity extends EmailEntity {
  public _kind = "null";
  public _singular = "null";
  public name: string;
  public external_template_id : string;
  public description: string;
  public cached_details: string;
  public cached: EmailTemplateCacheEntity;
  public binding_defaults: string;
  public synched_on: Date;
  public template_kind: string;

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    if (json) {
      this.refresh(json);
    }
  } // end constructor

  refresh(data) {
    super.refresh(data);
    if (typeof data.identifier == 'object') {
      this.identifier = "";
      for (let i = 0; i < data.identifier.tuple.length; i++) {
        if (i == 0) this.identifier += data.identifier.tuple[i];
        else this.identifier += "." + data.identifier.tuple[i];
      }
    }
    this.name = data.name;
    this.external_template_id = data.external_template_identifier.tuple[1];
    this.description = data.description;
    this.cached_details = data.cached_details;
    this.cached = data.cached && new EmailTemplateCacheEntity(data.cached);
    this.binding_defaults = data.binding_defaults;
    this.synched_on = data.synched_on && new Date(data.synched_on);
    this.template_kind = data.kind;
    return this;
  }

  email_widget_type(): EmailWidgetEnum| null {
    return EmailWidgetEnum.EMAIL_WIDGET__TEMPLATE;
  }
} // end Package
