
import {NoizuStruct} from '../../../noizu/structs/noizu-struct';

export class TargetConstraintStruct extends NoizuStruct {
  //------------------------------------
  // Members
  //------------------------------------
  public manufacturer: string = 'ref.manufacturer.14';
  public series: string = 'V2';
  public appengine: number = null;
  public gateway: string = null;
  public range_from: string = null;
  public range_to: string = null;
  public type: string = null;
  public batch: string = null;

  //-------------------------------
  //
  //-------------------------------
  public constructor(json) {
    super();
    this.manufacturer = json['manufacturer'] || 'ref.manufacturer.14',
      this.series = json['series'] || 'V2',
      this.appengine = json['appengine'],
      this.gateway = json['gateway'],
      this.range_from = json['range_from'],
      this.range_to = json['range_to'],
      this.type = json['type'],
      this.batch = json['batch']
  }

  //-------------------------------
  //
  //-------------------------------
  public isConstraintValid(constraint: string) {
    // @todo more robust validation;
    switch(constraint) {

      case 'manufacturer':
        // todo ref format check
        return this.manufacturer != null;

      case 'series':
        // todo lookup table.
        return (this.series == 'V2' || this.series == 'V3');

      case 'appengine':
        // todo ref format check
        return this.appengine != null;

      case 'gateway':
        // todo ref format check;
        return this.gateway != null;

      case 'range':
        // todo hex 6 digit heck
        return (this.range_from && this.range_to && true);

      case 'type':
        // todo ref format check
        return this.type != null;

      case 'batch':
        // todo ref format check
        return this.batch != null;

      default:
        return false;
    }
  }

} // end Package
