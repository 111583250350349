
import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from '../../lacrosse.entity';
import { FirebaseAuthService }            from '../../../noizu/services/firebase-auth.service';
import {ElixirEntity} from '../../elixir.entity';
import {UserExtendedWidgetEnum, WidgetEnum} from '../../../enums';
import {UserExtendedEntity} from './user-extended.entity';

export class UserSubscriptionPaymentEntity extends UserExtendedEntity {
  public _kind: "sub-payment";
  public user_subscription: any;
  public transaction_identifier: any;
  public subscription_group_identifier: any;
  public source: any;
  //public purchase_token: any;
  public purchase_date: any;
  public product_identifier: any;
  public payment_status: any;
  //public password: any;
  public package_name: any;
  public owner: any;
  public original_transaction_identifier: any;
  public original_purchase_date: any;
  public modified_on: any;
  public created_on: any;

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    this.user_subscription = json['user_subscription'];
    this.transaction_identifier = json['transaction_identifier'];
    this.subscription_group_identifier = json['subscription_group_identifier'];
    this.source = json['source'];
    this.purchase_date = json['purchase_date'] && new Date(json['purchase_date']);
    this.product_identifier = json['product_identifier'];
    this.payment_status = json['payment_status'];
    this.package_name = json['package_name'];
    this.original_transaction_identifier = json['original_transaction_identifier'];
    this.original_purchase_date = json['original_purchase_date'] && new Date(json['original_purchase_date']);
    this.modified_on = json['modified_on'] && new Date(json['modified_on']);
    this.created_on = json['created_on'] && new Date(json['created_on']);
    this.owner = json['owner'];
    this.raw = null;
  } // end constructor

  widget_type(): any {
    return WidgetEnum.EMBED_WIDGET__USER_EXTENDED;
  }

  user_extended_widget_type(): any {
    return UserExtendedWidgetEnum.USER_EXTENDED_WIDGET__SUBSCRIPTION_PAYMENT;
  }



  filter(filter: string) {
    if (filter) {
      if (this.meta['filter'] == filter) return this.meta['filter_result'];

      this.meta['filter'] = filter;
      this.meta['filter_result'] = false;

      let term_filter = filter.match(/package:"?([.\-a-zA-Z0-9]+)"?/)
      if (term_filter) {
        term_filter.forEach((v) => {
          if (`${this.package_name}`.includes(v)) {
            this.meta.filter_result = true;
            return true;
          }
        });
      }


      term_filter = filter.match(/txn:"?([.\-a-zA-Z0-9]+)"?/)
      if (term_filter) {
        term_filter.forEach((v) => {
          if (`${this.transaction_identifier}`.includes(v)) {
            this.meta.filter_result = true;
            return true;
          }
        });
      }

      term_filter = filter.match(/otxn:"?([.\-a-zA-Z0-9]+)"?/)
      if (term_filter) {
        term_filter.forEach((v) => {
          if (`${this.original_transaction_identifier}`.includes(v)) {
            this.meta.filter_result = true;
            return true;
          }
        });
      }



      term_filter = filter.match(/payment_status:"?([.\-a-zA-Z0-9]+)"?/)
      if (term_filter) {
        term_filter.forEach((v) => {
          if (`${this.payment_status}`.includes(v)) {
            this.meta.filter_result = true;
            return true;
          }
        });
      }



      term_filter = filter.match(/payment_group:"?([.\-a-zA-Z0-9]+)"?/)
      if (term_filter) {
        term_filter.forEach((v) => {
          if (`${this.subscription_group_identifier}`.includes(v)) {
            this.meta.filter_result = true;
            return true;
          }
        });
      }

      term_filter = filter.match(/source:"?([.\-a-zA-Z0-9]+)"?/)
      if (term_filter) {
        term_filter.forEach((v) => {
          if (`${this.source}`.includes(v)) {
            this.meta.filter_result = true;
            return true;
          }
        });
      }


      return this.meta.filter_result;
    }  else {
      return true;
    }
  }


} // end Package
