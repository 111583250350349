import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DeviceWidgetEventStruct} from './device-widget-event.struct';
import {WidgetBase} from '../widget-base';
import {GatewayEnvironmentTarget} from '../../../structs';
import {ElixirRepo, GenericRepo} from '../../../repos';
import {ToasterService} from 'angular2-toaster';


@Component({
  selector: 'device-target-widget',
  template: `
    <ng-container *ngIf="entity">
      <div class="device-target-widget card">
        <div class="card-header text-white" [ngClass]="{'bg-gray-400': entity.target_stage, 'bg-gray-600': !entity.target_stage}">
          <div class="row  pb-0 pt-0 mb-0 mt-0">
            <div class="col-1  pb-0 pt-0 mb-0 mt-0">
              <label class="switch switch-pill switch-dark pb-0 pt-0 mb-0 mt-0">
                <input [disabled]="!options?.edit" checked="" class="switch-input" (change)="toggleTarget($event)"  [(ngModel)]="entity.target_stage" type="checkbox">
                <span class="switch-slider"></span>
              </label>
            </div>
            <div class="col text-left  pb-0 pt-0 mb-0 mt-0">
              <div class="ml-4">
                <div class="lead">
                  <ng-container *ngIf="entity.target_stage">Stage</ng-container>
                  <ng-container *ngIf="!entity.target_stage">Production</ng-container>
                </div>
              </div>
            </div>
            <div class="col  pb-0 pt-0 mb-0 mt-0">
              <div class="card-header-actions">
                <span class="dragula-handle"><i class="dragula-handle text-black-50 fa fa-lg fa-arrows"></i></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
`
})
export class DeviceTargetWidgetComponent extends WidgetBase implements OnInit {
  @Input() entity: GatewayEnvironmentTarget = null;
  @Input() templateHint: string = null;
  @Input() options: any = {};
  @Input() layout = 'toggle';
  @Output() widgetEvent = new EventEmitter<DeviceWidgetEventStruct>();

  forwardEvent(e, widgetEvent: DeviceWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }

  constructor(public genericRepo: GenericRepo, public toasterService: ToasterService) {
    super();
  }

  toggleTarget(e) {
    e.preventDefault();
    if (this.entity.target_stage) {
      this.genericRepo._put(
        `${this.genericRepo.apiBase()}/admin-tools/gateway/${this.entity.gateway}/target-stage`,
        {value: '+90days'},
        (data, resolve) => {
          if (data['outcome']) {
            this.toasterService.pop('success',  'Gateway', `Target Stage`);
          } else {
            this.toasterService.pop('error',  'Gateway', `Target Stage Error: ${data['msg']}`);
          }
          resolve(data);
        });
    } else {
      this.genericRepo._delete(
        `${this.genericRepo.apiBase()}/admin-tools/gateway/${this.entity.gateway}/target-stage`,
        (data, resolve) => {
          if (data['outcome']) {
            this.toasterService.pop('success',  'Gateway', `Target Prod`);
          } else {
            this.toasterService.pop('error',  'Gateway', `Target Prod Error: ${data['msg']}`);
          }
          resolve(data);
        });
    }
  }

  ngOnInit(): void {

  }
}
