import {Component, Input, Inject, Output, EventEmitter} from '@angular/core';
import {LacrosseEntity} from "../../../entities/lacrosse.entity";
import {NoizuStruct} from "../../../noizu/structs/noizu-struct";
import {CallbackObject, SubscriberObject} from '../../../entities/alert';
import {WidgetBase} from '../widget-base';
import {CallbackWidgetEventStruct} from './callback-widget-event.struct';

@Component({
  selector: 'callback-object-widget',
  template: `
    <div class="callback-object-widget card" [ngClass]="{'mb-0': options?.nested}">
      <div class="card-header" [ngClass]="{'p-2 m-0': options?.nested}">
        <div class="row">
          <div class="col"><b class="text-left">CallBack: #{{entity.identifier}}</b></div>
          <div class="col-2 text-right p-0 text-middle">
            <toggle-widget [options]="expand_settings"></toggle-widget>
          </div>
        </div>
      </div>
      <div class="card-body"  [ngClass]="{'d-none': !expand_settings.toggle}" >
        <pre>{{entity.toJson()| json}}</pre>
      </div>
    </div>
    `
})
export class CallbackObjectWidgetComponent extends WidgetBase{
  @Input() entity: CallbackObject = null;
  @Input() options: any = null;
  @Input() layout: string = null;
  @Output() widgetEvent = new EventEmitter<CallbackWidgetEventStruct>();
  forwardEvent(e, widgetEvent: CallbackWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }
  constructor() {
    super();
  }
}
