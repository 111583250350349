<div class="col-12" *ngIf="group">
  <div class="overlayParent">
    <div class="overlay" *ngIf="saving"></div>
    <div class="card" *ngIf="!(editEntry==true) && !(group.identifier == 'new')">
      <div class="card-header" *ngIf="isUser">  <a href="" (click)="toGroup($event, group.identifier)">{{group.identifier}}</a></div>
      <div class="card-header" *ngIf="!isUser">  {{group.identifier}}</div>
      <div class="card-body card-block">

        <div class="alert alert-success">
          <div class="row" *ngIf="group.system === true"><div class="col-3">System Group: </div><div class="col-9">True</div></div>
          <div class="row"><div class="col-3">Name: </div><div class="col-9">{{group.name}}</div></div>
          <div class="row"><div class="col-3">Description: </div><div class="col-9">{{group.description}}</div></div>
          <div class="row" *ngIf="group.handle"><div class="col-3">Handle: </div><div class="col-9">{{group.handle}}</div></div>
        </div>

        <div class="alert alert-info" *ngIf="group.createdOn || group.modifiedOn">
          <div class="row" *ngIf="group.createdOn"><div class="col-3">Created On</div><div class="col-9">{{group.createdOn}}</div></div>
          <div class="row" *ngIf="group.modifiedOn"><div class="col-3">Modified On</div><div class="col-9">{{group.modifiedOn}}</div></div>
        </div>

        <div class="alert alert-warning" *ngIf="group.memberOf && group.memberOf.length > 0">

          <div class="row" *ngFor="let gid of group.memberOf">
            <div class="col-3">Member of group:</div>
            <div class="col-9" (click)="toGroup($event, gid)">
              <a href="/portal/groups/show/{{gid}}/{{uid}}">{{gid}}</a>
            </div>
          </div>
        </div>
        <button class="btn btn-primary pull-right" (click)="edit($event, entry)">Edit Group and Membership</button>
      </div>
    </div>
    <div class="card" *ngIf="editEntry==true || group.identifier=='new'">
      <div class="card-block row card-body">
        <div class="col-12">
          <form action="" class="form-horizontal" >
            <div class="form-group row">
              <label class="col-3 form-control-label" for="edit-handle">Name</label>
              <div class="col-9"><input class="form-control" id="group-name" name="group.name" placeholder="Name" type="text" [(ngModel)]="group.name" autocomplete="off"></div>
            </div>

            <div class="form-group row">
              <label class="col-3 form-control-label" for="edit-email">Description</label>
              <div class="col-9"><input class="form-control" id="group-description" name="group.description" placeholder="Description" type="text" [(ngModel)]="group.description" autocomplete="off"></div>
            </div>

            <div class="form-group row" ><!-- *ngIf="user.auth.permissionLevel <=1" -->
              <label class="col-3 form-control-label" for="edit-isSystem">Is System Group</label>
              <div class="col-9">
                <input type="checkbox"  [checked]="group.system" (change)="group.system = !group.system"/>
              </div>
            </div>
          </form>
        </div>
        <div class="col-6 mb-3">
          <input class="form-control" [(ngModel)]="gidSelect" placeholder="New Group ID" type="text" name="name">
        </div>
        <div class="col-6">
          <button class="btn btn-sm btn-primary offset-1" (click)="addGroup($event)">Add Group by ID</button>
        </div>
        <div class="col-6 mb-6" *ngIf="sysGroups.length > 0">
          <select name="sysgid" [(ngModel)]="sysGid" style="color:black" class="form-control">
            <option *ngFor="let sysGroup of sysGroups" [value]="sysGroup.id">{{sysGroup.name}}</option>
          </select>
        </div>
        <div class="col-6">
          <button class="btn btn-sm btn-primary offset-1" (click)="addSysGroup($event)">Add System Group</button>
        </div>
        <div class="col-12 row my-2">
          <div class="col-3 float-left">Member of Group:</div>
          <div class="col-9 float-left">
            <div class="row" *ngFor="let gid of groupsList">
              <div class="col-12 row my-2">
                <div class="col-6">
                  {{gid}}
                </div>
                <div class="col-6">
                  <button class="btn btn-sm btn-primary" (click)="leaveGroup($event, gid)">Remove Group</button>
                </div>
                <hr class="col-12 my-2">
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer col-12">
          <button (click)="cancel($event)" class="btn btn-sm btn-primary" type="submit"><i class="fa fa-dot-circle-o"></i> Cancel</button>
          <button (click)="update($event, entry)" class="btn btn-sm btn-primary float-right" type="submit"><i class="fa fa-dot-circle-o"></i>Update</button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="errors.show" class="col-12 alert alert-warning" (click)="errors.show = false">
    {{errors.msg}}
  </div>

</div>
