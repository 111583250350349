
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { FirebaseAuthService }        from '../../../noizu/services/firebase-auth.service';
import { AppengineRepo }      from '../../appengine.repo';
import {MCUFirmwareDefinitionEntity} from '../../../entities/mcu/firmware';
import {AppengineEntityList} from '../../../noizu/structs/appengine-entity-list';

@Injectable()
export class MCUFirmwareDefinitionRepo extends AppengineRepo {
  public _kind = "mcu-firmware-definitions";
  public _singular = "mcu-firmware-definition";

  public loading = false;
  public loaded = false;
  public firmware_by_sref: any = {};

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  entity(record) {
    return new MCUFirmwareDefinitionEntity(this.client, this.auth, record)
  }






  public get firmwareBySref() {
      if (!this.loaded) {
        if (!this.loading) {
          this.loading = true;

          this.getListPromise().then((v: AppengineEntityList) => {
            v.items.forEach((i: MCUFirmwareDefinitionEntity) => {
              this.firmware_by_sref[i.sref()] = i;
            });
            this.loaded = true;
          });
        }
      }
      return this.firmware_by_sref;
  }


}
