
<page-header [section]="'monitors-sys-health'">
  <page-title>TimeScale</page-title>
  <page-blurb>Postgres Health Reports.</page-blurb>
</page-header>



<ng-container *ngIf="pageVersion == '1.0'">

  <div class="alert alert-info">Switch to BetaView
    <span class="float-right">&nbsp;Old View</span>
    <label class="switch switch-3d switch-primary float-right">
      <input checked="" class="switch-input switch-primary" (change)="togglePageVersion($event)" [(ngModel)]="pageVersionToggle" type="checkbox">
      <span class="switch-slider"></span>
    </label>
  </div>


  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header" (click)="expand.pg.summary = !expand.pg.summary">
          PG Activity Summary&nbsp;
          <span class="float-right">
              <i class="fa fa-sm"
                 [ngClass]="{'fa-chevron-down': !expand.pg.summary, 'fa-chevron-up': expand.pg.summary}"> </i>
            </span>
        </div>
        <div class="card-body" *ngIf="expand.pg.summary">
          <ng-container *ngIf="!data.pg.summary">
            <div class="row">
              <div class="col text-center">
                <div class="btn btn-primary" (click)="pg_summary()">Fetch!</div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="data.pg.summary">
            <table class="table table-bordered">
              <thead>
              <tr>
                <th>Count</th>
                <th>Event</th>
                <th>Type</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let record of data.pg.summary">
                <td>{{record['count']}}</td>
                <td>{{record['wait_event'] || "null"}}</td>
                <td>{{record['wait_event_type'] || "null"}}</td>
              </tr>
              </tbody>
            </table>
          </ng-container>
        </div>
      </div>
    </div>
  </div>



  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header" (click)="expand.pg.queries = !expand.pg.queries">
          PG Query Summary&nbsp;
          <span class="float-right">
              <i class="fa fa-sm"
                 [ngClass]="{'fa-chevron-down': !expand.pg.queries, 'fa-chevron-up': expand.pg.queries}"> </i>
            </span>
        </div>
        <div class="card-body" *ngIf="expand.pg.queries">
          <ng-container *ngIf="!data.pg.queries">
            <div class="row">
              <div class="col text-center">
                <div class="btn btn-primary" (click)="pg_queries()">Fetch!</div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="data.pg.queries">
            <table class="table table-bordered">
              <thead>
              <tr>
                <th>Count</th>
                <th>Event</th>
                <th>Type</th>
                <th>State</th>
                <th>Backend</th>
                <th>Query</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let record of data.pg.queries">
                <td>{{record['count']}}</td>
                <td>{{record['wait_event'] || "null"}}</td>
                <td>{{record['wait_event_type'] || "null"}}</td>
                <td>{{record['state'] || "null"}}</td>
                <td>{{record['backend_type'] || "null"}}</td>
                <td>{{record['query'] || "null"}}</td>
              </tr>
              </tbody>
            </table>

          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header" (click)="expand.oban.jobs = !expand.oban.jobs">
          Oban Jobs&nbsp;
          <span class="float-right">
              <i class="fa fa-sm"
                 [ngClass]="{'fa-chevron-down': !expand.oban.jobs, 'fa-chevron-up': expand.oban.jobs}"> </i>
            </span>
        </div>
        <div class="card-body" *ngIf="expand.oban.jobs">
          <ng-container *ngIf="!data.oban.jobs">
            <div class="row">
              <div class="col text-center">
                <div class="btn btn-primary" (click)="oban_jobs()">Fetch!</div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="data.oban.jobs">
            <table class="table table-bordered">
              <thead>
              <tr>
                <th>id</th>
                <th>priority</th>
                <th>queue</th>
                <th>state</th>
                <th>tags</th>
                <th>attempt</th>
                <th>max_attempts</th>
                <th>attempted_at</th>
                <th>attempted_by</th>
                <th>args</th>
                <th>scheduled_at</th>
                <th>inserted_at</th>
                <th>completed_at</th>
                <th>cancelled_at</th>
                <th>discarded_at</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let record of data.oban.jobs">
                <td>{{record['id']}}</td>
                <td>{{record['priority'] || "null"}}</td>
                <td>{{record['queue'] || "null"}}</td>
                <td>{{record['state'] || "null"}}</td>
                <td>{{record['tags'] | json}}</td>
                <td>{{record['attempt']}}</td>
                <td>{{record['max_attempts']}}</td>
                <td>{{record['attempted_at']}}</td>
                <td>{{record['attempted_by']}}</td>
                <td>{{record['args'] | json}}</td>
                <td>{{record['scheduled_at']}}</td>
                <td>{{record['inserted_at']}}</td>
                <td>{{record['completed_at']}}</td>
                <td>{{record['cancelled_at']}}</td>
                <td>{{record['discarded_at']}}</td>
              </tr>
              </tbody>
            </table>

          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header" (click)="expand.hypertables.info = !expand.hypertables.info">
          Hyper Tables&nbsp;
          <span class="float-right">
              <i class="fa fa-sm"
                 [ngClass]="{'fa-chevron-down': !expand.hypertables.info, 'fa-chevron-up': expand.hypertables.info}"> </i>
            </span>
        </div>
        <div class="card-body" *ngIf="expand.hypertables.info">
          <ng-container *ngIf="!data.hypertables.info">
            <div class="row">
              <div class="col text-center">
                <div class="btn btn-primary" (click)="hypertables()">Fetch!</div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="data.hypertables.info">
            <table class="table table-bordered">
              <thead>
              <tr>
                <th>schema</th>
                <th>table</th>
                <th>owner</th>
                <th>disks</th>
                <th>dimensions</th>
                <th>chunks</th>
                <th>distributed</th>
                <th>compression?</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let record of data.hypertables.info">
                <td>{{record['hypertable_schema']}}</td>
                <td>{{record['hypertable_name'] || "null"}}</td>
                <td>{{record['owner'] || "null"}}</td>
                <td>{{record['tablespaces'] | json}}</td>
                <td>{{record['num_dimensions']}}</td>
                <td>{{record['num_chunks']}}</td>
                <td>{{record['is_distributed'] | json}}</td>
                <td>{{record['compression_enabled']}}</td>
              </tr>
              </tbody>
            </table>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header" (click)="expand.hypertables.chunks = !expand.hypertables.chunks">
          Hyper Tables: Chunks&nbsp;
          <span class="float-right">
              <i class="fa fa-sm"
                 [ngClass]="{'fa-chevron-down': !expand.hypertables.chunks, 'fa-chevron-up': expand.hypertables.chunks}"> </i>
            </span>
        </div>
        <div class="card-body" *ngIf="expand.hypertables.chunks">
          <ng-container *ngIf="!data.hypertables.chunks">
            <div class="row">
              <div class="col text-center">
                <div class="btn btn-primary" (click)="hypertables_chunks()">Fetch!</div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="data.hypertables.chunks">
            <table class="table table-bordered">
              <thead>
              <tr>
                <th>schema</th>
                <th>table</th>
                <th>chunk</th>
                <th>start</th>
                <th>end</th>
                <th>disk</th>
                <th>compresed?</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let record of data.hypertables.chunks">
                <td>{{record['hypertable_schema']}}</td>
                <td>{{record['hypertable_name'] || "null"}}</td>
                <td>{{record['chunk_name']}}</td>
                <td>{{record['range_start'] |  date:'MM/dd/yyyy @ h:mm:ssa' }}</td>
                <td>{{record['range_end'] |  date:'MM/dd/yyyy @ h:mm:ssa' }}</td>
                <td>{{record['chunk_tablespace']}}</td>
                <td>{{record['is_compressed']}}</td>
              </tr>
              </tbody>
            </table>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header" (click)="expand.hypertables.extended = !expand.hypertables.extended">
          Hyper Table: Extended&nbsp;
          <span class="float-right">
              <i class="fa fa-sm"
                 [ngClass]="{'fa-chevron-down': !expand.hypertables.extended, 'fa-chevron-up': expand.hypertables.extended}"> </i>
            </span>
        </div>
        <div class="card-body" *ngIf="expand.hypertables.extended">
          <ng-container *ngIf="!data.hypertables.extended">
            <div class="row">
              <div class="col text-center">
                <div class="btn btn-primary" (click)="hypertables_extended()">Fetch!</div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="data.hypertables.extended">
            <table class="table table-bordered">
              <thead>
              <tr>
                <th>schema</th>
                <th>table</th>
                <th>chunk</th>
                <th>start</th>
                <th>end</th>
                <th>disk</th>
                <th>compresed?</th>


                <th>total_bytes</th>
                <th>table_bytes</th>
                <th>index_bytes</th>
                <th>toast_bytes</th>

              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let record of data.hypertables.extended">
                <td>{{record['hypertable_schema']}}</td>
                <td>{{record['full_hypertable_name'] || "null"}}</td>
                <td>{{record['chunk_name']}}</td>
                <td>{{record['range_start'] |  date:'MM/dd/yyyy @ h:mm:ssa' }}</td>
                <td>{{record['range_end'] |  date:'MM/dd/yyyy @ h:mm:ssa' }}</td>
                <td>{{record['chunk_tablespace']}}</td>
                <td>{{record['is_compressed']}}</td>

                <td>{{record['total_bytes']}}</td>
                <td>{{record['table_bytes']}}</td>
                <td>{{record['index_bytes']}}</td>
                <td>{{record['toast_bytes']}}</td>


              </tr>
              </tbody>
            </table>


          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header" (click)="expand.jobs.info = !expand.jobs.info">
          TimeScale: Background Job&nbsp;
          <span class="float-right">
              <i class="fa fa-sm"
                 [ngClass]="{'fa-chevron-down': !expand.jobs.info, 'fa-chevron-up': expand.jobs.info}"> </i>
            </span>
        </div>
        <div class="card-body" *ngIf="expand.jobs.info">
          <ng-container *ngIf="!data.jobs.info">
            <div class="row">
              <div class="col text-center">
                <div class="btn btn-primary" (click)="time_scale_jobs()">Fetch!</div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="data.jobs.info">
            <table class="table table-bordered">
              <thead>
              <tr>
                <th>job</th>
                <th>check_name</th>
                <th>application_name</th>
                <th>scheduled</th>
                <th>schedule_interval</th>
                <th>retry_period</th>
                <th>proc_schema</th>
                <th>proc_name</th>
                <th>owner</th>
                <th>next_start</th>
                <th>max_runtime</th>
                <th>max_retries</th>
                <th>initial_start</th>
                <th>hypertable_name</th>
                <th>fixed_schedule</th>
                <th>config</th>
                <th>check_schema</th>

              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let record of data.jobs.info">
                <td>{{record['job_id']}}</td>
                <td>{{record['check_name']}}</td>
                <td>{{record['application_name']}}</td>
                <td>{{record['scheduled']}}</td>
                <td>{{record['schedule_interval'] | json}}</td>
                <td>{{record['retry_period'] | json}}</td>
                <td>{{record['proc_schema']}}</td>
                <td>{{record['proc_name']}}</td>
                <td>{{record['owner']}}</td>
                <td>{{record['next_start'] | date:'MM/dd/yyyy @ h:mm:ssa' }}</td>
                <td>{{record['max_runtime'] | json}}</td>
                <td>{{record['max_retries']}}</td>
                <td>{{record['initial_start'] | date:'MM/dd/yyyy @ h:mm:ssa'}}</td>
                <td>{{record['hypertable_name']}}</td>
                <td>{{record['fixed_schedule']}}</td>
                <td>{{record['config'] | json}}</td>
                <td>{{record['check_schema']}}</td>


              </tr>
              </tbody>
            </table>

          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header" (click)="expand.jobs.stats = !expand.jobs.stats">
          TimeScale: Background Job Stats&nbsp;
          <span class="float-right">
              <i class="fa fa-sm"
                 [ngClass]="{'fa-chevron-down': !expand.jobs.stats, 'fa-chevron-up': expand.jobs.stats}"> </i>
            </span>
        </div>
        <div class="card-body" *ngIf="expand.jobs.stats">
          <ng-container *ngIf="!data.jobs.stats">
            <div class="row">
              <div class="col text-center">
                <div class="btn btn-primary" (click)="time_scale_job_stats()">Fetch!</div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="data.jobs.stats">



            <table class="table table-bordered">
              <thead>
              <tr>
                <th>job</th>
                <th>job_status</th>
                <th>hypertable_name</th>
                <th>next_start</th>
                <th>total_runs</th>
                <th>total_successes</th>
                <th>total_failures</th>
                <th>last_run_status</th>
                <th>last_run_started_at</th>
                <th>last_run_duration</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let record of data.jobs.stats">
                <td>{{record['job_id']}}</td>
                <td>{{record['job_status']}}</td>
                <td>{{record['hypertable_name']}}</td>
                <td>{{record['next_start'] | date:'MM/dd/yyyy @ h:mm:ssa' }}</td>

                <td>{{record['total_runs']}}</td>
                <td>{{record['total_successes']}}</td>
                <td>{{record['total_failures']}}</td>

                <td>{{record['last_run_status']}}</td>
                <td>{{record['last_run_started_at'] | date:'MM/dd/yyyy @ h:mm:ssa' }}</td>
                <td>{{record['last_run_duration'] | json}}</td>

              </tr>
              </tbody>
            </table>
          </ng-container>
        </div>
      </div>
    </div>
  </div>


  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header" (click)="expand.jobs.errors = !expand.jobs.errors">
          TimeScale: Background Job Errors&nbsp;
          <span class="float-right">
              <i class="fa fa-sm"
                 [ngClass]="{'fa-chevron-down': !expand.jobs.errors, 'fa-chevron-up': expand.jobs.errors}"> </i>
            </span>
        </div>
        <div class="card-body" *ngIf="expand.jobs.errors">
          <ng-container *ngIf="!data.jobs.errors">
            <div class="row">
              <div class="col text-center">
                <div class="btn btn-primary" (click)="time_scale_job_errors()">Fetch!</div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="data.jobs.errors">
            <pre>{{data.jobs.errors| json}}</pre>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header" (click)="expand.jobs.extended = !expand.jobs.extended">
          TimeScale: Background Job Extended View&nbsp;
          <span class="float-right">
              <i class="fa fa-sm"
                 [ngClass]="{'fa-chevron-down': !expand.jobs.extended, 'fa-chevron-up': expand.jobs.extended}"> </i>
            </span>
        </div>
        <div class="card-body" *ngIf="expand.jobs.extended">
          <ng-container *ngIf="!data.jobs.extended">
            <div class="row">
              <div class="col text-center">
                <div class="btn btn-primary" (click)="time_scale_job_extended()">Fetch!</div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="data.jobs.extended">
            <pre>{{data.jobs.extended| json}}</pre>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="pageVersion == '2.0'">

  <div class="alert alert-info">Go back to classic view
    <span class="float-right">&nbsp;Beta View</span>
    <label class="switch switch-3d switch-primary float-right">
      <input checked="" class="switch-input switch-primary" (change)="togglePageVersion($event)" [(ngModel)]="pageVersionToggle" type="checkbox">
      <span class="switch-slider"></span>
    </label>
  </div>

  <ng-container *ngIf="liveViewLink | safe">
    <embed class="liveView" [src]="liveViewLink | safe"/>
  </ng-container>

</ng-container>

