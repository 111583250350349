
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { DomainObjectRepo } from '../../noizu/domain-object-repo';
import { FirebaseAuthService }        from '../../noizu/services/firebase-auth.service';
import { FirmwareLinkEntity }      from '../../entities/firmware/link.entity';
import { AppengineRepo }      from '../appengine.repo';
import {ElixirRepo} from '../elixir.repo';
import {DataStreamCardEntity} from '../../entities/data-stream-card.entity';
import {FirmwareLinkV2Entity} from '../../entities/firmware/link-v2.entity';
import {FirmwareReleaseEntity} from '../../entities/firmware/release.entity';
import {CacheService} from '../../services/cache.service';
import {FirmwareDefinitionRepo} from './definition.repo';
import {FirmwareApprovalRepo} from './approval.repo';

@Injectable()
export class FirmwareReleaseRepo extends ElixirRepo {
  public _kind = "firmware-release";
  public _singular = "firmware-release";
  public cache;
  public firmwareDefinitionRepo: FirmwareDefinitionRepo;
  public firmwareApprovalRepo: FirmwareApprovalRepo;

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService, @Inject(CacheService) cache: CacheService, @Inject(FirmwareDefinitionRepo) firmwareDefinitionRepo: FirmwareDefinitionRepo, @Inject(FirmwareApprovalRepo) firmwareApprovalRepo: FirmwareApprovalRepo) {
    super(client, auth);
    this.cache = cache;
    this.firmwareApprovalRepo = firmwareApprovalRepo;
    this.firmwareDefinitionRepo = firmwareDefinitionRepo
  }

  entity(record) {
    return new FirmwareReleaseEntity(this.client, this.auth, record, this.cache, this.firmwareDefinitionRepo, this.firmwareApprovalRepo);
  }

  getEntityPromise(id, options = {}) {
    return this._getEntityPromise(
      `${this.apiBase()}/firmware/releases/${id}`,
      (record, resolve) => {
        resolve( this.entity(record) );
      },
      options
    );
  }

  getListPromise( options = {}) {
    return this._get(
      `${this.apiBase()}/firmware/releases`,
      (json, resolve) => {
        const response = []; // @todo DataStreamCardSet
        if (json) {
          for (let i = 0; i < json.length; i++) {
            response.push(this.entity(json[i]));
          }
        } else {
          // error handling.
        }
        resolve(response);
      },
      options
    );
  }


  getReportPromise(options = {}) {
    return this._getEntityPromise(
      `${this.apiBase()}/admin-tools/firmware/report`,
      (record, resolve) => {
        resolve(  record );
      },
      options
    );
  }



}
