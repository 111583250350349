import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { FirebaseAuthService }            from '../../noizu/services/firebase-auth.service';
import {ElixirEntity} from '../elixir.entity';
import {CacheService} from '../../services/cache.service';
import {FirmwareDefinitionEntity} from './definition.entity';
import {FirmwareApprovalEntity} from './approval.entity';
import {FirmwareReleaseRepo} from '../../repos/firmware/release.repo';
import {FirmwareApprovalRepo} from '../../repos/firmware/approval.repo';
import {FirmwareDefinitionRepo} from '../../repos/firmware/definition.repo';
import {EntityReference} from '../../noizu/structs/entity-reference';
import {FirmwareWidgetEnum, WidgetEnum} from '../../enums';

export class FirmwareReleaseEntity extends ElixirEntity {
  public _kind = "firmware-releases";
  public _singular = "firmware-release";

  public identifier: any;

  public weight: BigInteger;
  public targets: any = [];
  public status: string;

  public release_note: string;
  public release_name: string;
  public release_description: string;
  public parameters: any;
  public modified_on: Date;
  public generated_on: Date;
  public meta: any = {};
  public firmware: EntityReference;
  public firmware_entity: FirmwareDefinitionEntity;
  public rollback: EntityReference;
  public rollback_entity: FirmwareDefinitionEntity;
  public approval: EntityReference;
  public approval_entity: FirmwareApprovalEntity;
  public _sref_module = "firmware-release";
  public created_on: Date;

  public cache: CacheService;

  public firmwareRepo: FirmwareDefinitionRepo;
  public approvalRepo: FirmwareApprovalRepo;

  public load_promise;

  public roll_out_report: any = null;

  constructor(client: HttpClient, auth: FirebaseAuthService, json, cache: CacheService, firmwareRepo: FirmwareDefinitionRepo, approvalRepo: FirmwareApprovalRepo) {
    super(client, auth, json);
    this.cache = cache;


    this.strip_from_json['_deleted'] = 1;
    this.strip_from_json['_modified'] = 1;
    this.strip_from_json['_new'] = 1;
    this.strip_from_json['meta'] = 1;
    this.strip_from_json['_ingv_version'] = 1;
    this.strip_from_json['_ingv_endpoint'] = 1;
    this.strip_from_json['_sref_module'] = 1;
    this.strip_from_json['kind'] = 1;
    this.strip_from_json['_kind'] = 1;
    this.strip_from_json['validation'] = 1;

    this.strip_from_json['cache'] = 1;
    this.strip_from_json['firmware_entity'] = 1;
    this.strip_from_json['approval_entity'] = 1;
    this.strip_from_json['rollback_entity'] = 1;

    this.strip_from_json['load_promise'] = 1;
    this.strip_from_json['roll_out_report'] = 1;
    this.strip_from_json['firmwareRepo'] = 1;
    this.strip_from_json['approvalRepo'] = 1;


    this.firmwareRepo = firmwareRepo;
    this.approvalRepo = approvalRepo;

    if (json) {
      this.refresh(json);
    }
  } // end constructor

  getRollOutReport(options = {}) {
    console.log("URL Report:" , `${this.apiBase()}/firmware/releases/${this.sref()}/report`);
    return this._get( `${this.apiBase()}/firmware/releases/${this.sref()}/report`,
      (data, resolve) => {
        console.log("GET", `${this.apiBase()}/firmware/releases/${this.sref()}/report`, data);
        if (data.outcome == true) {
          this.roll_out_report = data.response;

          this.generated_on = data.generated_on;


          if (this.roll_out_report && this.roll_out_report.release_status && this.roll_out_report.managed_workers) {
            let managed = 0;
            if (this.roll_out_report.release_status.active_group >= 1) managed += this.roll_out_report.managed_workers.group_totals["1"];
            if (this.roll_out_report.release_status.active_group >= 2) managed += this.roll_out_report.managed_workers.group_totals["2"];
            if (this.roll_out_report.release_status.active_group >= 3) managed += this.roll_out_report.managed_workers.group_totals["3"];
            if (this.roll_out_report.release_status.active_group >= 4) managed += this.roll_out_report.managed_workers.group_totals["4"];
            if (this.roll_out_report.release_status.active_group >= 5) managed += this.roll_out_report.managed_workers.group_totals["5"];
            if (this.roll_out_report.release_status.active_group >= 6) managed += this.roll_out_report.managed_workers.group_totals["6"];
            if (this.roll_out_report.release_status.active_group >= 7) managed += this.roll_out_report.managed_workers.group_totals["7"];
            if (this.roll_out_report.release_status.active_group >= 8) managed += this.roll_out_report.managed_workers.group_totals["8"];
            if (this.roll_out_report.release_status.active_group >= 9) managed += this.roll_out_report.managed_workers.group_totals["9"];
            if (this.roll_out_report.release_status.active_group >= 10) managed += this.roll_out_report.managed_workers.group_totals["10"];
            this.roll_out_report["managed_units"] = managed;
            this.roll_out_report["control_units"] = this.roll_out_report.managed_workers.group_totals["control"];
            this.roll_out_report["pending_units"] = this.roll_out_report.managed_workers.group_totals["pending"];
          } else {
            if (this.roll_out_report == null) this.roll_out_report = {};
            this.roll_out_report["managed_units"] = null;
            this.roll_out_report["control_units"] = null;
            this.roll_out_report["pending_units"] = null;
          }


          let count = {
            pending: 0,
            baseline: 0,
            revert: 0,
            first_install: 0,
            first_rollback: 0,
            second_install: 0,
            second_rollback: 0,
            final_install: 0,
            flagged: 0,
            error: 0
          }

          let by_group = {
            "1": {},
            "2": {},
            "3": {},
            "4": {},
            "5": {},
            "6": {},
            "7": {},
            "8": {},
            "9": {},
            "10": {},
          }


          let detailed_by_group = {
            "1": {},
            "2": {},
            "3": {},
            "4": {},
            "5": {},
            "6": {},
            "7": {},
            "8": {},
            "9": {},
            "10": {},
          }

          let phases = [
            'pending',
            'baseline',
            'revert',
            'first_install',
            'first_rollback',
            'second_install',
            'second_rollback',
            'final_install',
          ];

          if (this.roll_out_report && this.roll_out_report.roll_up_report) {
            this.roll_out_report.snapshot = {};
            this.roll_out_report.snapshot_by_stage = {};
            // :pending | :baseline | :revert | :first_install | :first_rollback | :second_install | :second_rollback | :final_install


            for (let i = 1; i <= 10; i++) {
              let index = `${i}`;
              by_group[index] = {
                pending: 0,
                baseline: 0,
                revert: 0,
                first_install: 0,
                first_rollback: 0,
                second_install: 0,
                second_rollback: 0,
                final_install: 0,
                flagged: 0,
                error: 0
              };
              detailed_by_group[index] = {}

              for (let p in phases) {
                let phase  = phases[p];
                detailed_by_group[index][phase] = {pending: 0, assigned: 0, requested: 0, online: 0,  validating:0, flagged: 0, error: 0, unknown: 0, complete: 0}

                if (this.roll_out_report.roll_up_report.group[index]) {
                  if (this.roll_out_report.roll_up_report.group[index].phase && this.roll_out_report.roll_up_report.group[index].phase[phase]) {
                    for (let step in this.roll_out_report.roll_up_report.group[index].phase[phase].step) {
                      detailed_by_group[index][phase][step] = this.roll_out_report.roll_up_report.group[index].phase[phase].step[step].total;

                      if (step == 'error') {
                        by_group[index][step] += this.roll_out_report.roll_up_report.group[index].phase[phase].step[step].total;
                        count[step] += this.roll_out_report.roll_up_report.group[index].phase[phase].step[step].total;
                      } else if (step == 'flagged') {
                        by_group[index][step] += this.roll_out_report.roll_up_report.group[index].phase[phase].step[step].total;
                        count[step] += this.roll_out_report.roll_up_report.group[index].phase[phase].step[step].total;
                      } else {
                        by_group[index][phase] += this.roll_out_report.roll_up_report.group[index].phase[phase].step[step].total;
                        count[phase] += this.roll_out_report.roll_up_report.group[index].phase[phase].step[step].total;
                      }
                    }
                  }
                }
              }
            }
          }

          this.roll_out_report["roll_up"] = count;
          this.roll_out_report["roll_up_by_group"] = by_group;
          this.roll_out_report["roll_up_detailed"] = detailed_by_group;
          console.log("Roll Ups", count, by_group);
        }
        resolve(this.roll_out_report);
      }
    );
  }



  updateEndpoint() {
    return `${this.apiBase()}/firmware/releases/${this.identifier}`;
  }

  createEndpoint() {
    return `${this.apiBase()}/firmware/releases`;
  }

  deleteEndpoint() {
    return `${this.apiBase()}/firmware/releases/${this.identifier}`;
  }

  refresh(data) {
    this.identifier = data.identifier;
    this.created_on = data.created_on ? new Date(data.created_on) : null;
    this.modified_on = data.modified_on ? new Date(data.modified_on) : null;
    this.weight = data.weight;
    this.status = data.status;

    this.targets = data.targets || []; // needs struct

    this.release_note = data.release_note;
    this.release_name = data.release_name;
    this.release_description = data.release_description;
    this.parameters = data.parameters;
    this.meta = data.meta || {};

    if (!(this.firmware instanceof EntityReference) || this.firmware.sref() != data.firmware) {
      this.firmware = data.firmware ? new EntityReference(this.client, this.auth, data.firmware) : null;
      this.firmware_entity = null;
    }
    if (!(this.rollback instanceof EntityReference) || this.rollback.sref() != data.rollback) {
      this.rollback = data.rollback ? new EntityReference(this.client, this.auth, data.rollback) : null;
      this.rollback_entity = null;
    }
    if (!(this.approval instanceof EntityReference) || this.approval.sref() != data.approval) {
      this.approval = data.approval ? new EntityReference(this.client, this.auth, data.approval) : null;
      this.approval_entity = null;
    }

    this.load_promise = new Promise(
      (resolve, reject) => {
        let remaining = 3;
        console.log(this.firmwareRepo, this.approvalRepo);
        let firmware_update_promise = ((this.firmwareRepo instanceof FirmwareDefinitionRepo) && (this.firmware instanceof EntityReference)) ? this.firmwareRepo.getEntityPromise(this.firmware.identifier()) : new Promise( (rs,rj) =>  {rs(null);});
        let rollback_update_promise = ((this.firmwareRepo instanceof FirmwareDefinitionRepo) && (this.rollback instanceof EntityReference)) ? this.firmwareRepo.getEntityPromise(this.rollback.identifier()) : new Promise( (rs,rj) =>  {rs(null);});
        let approval_update_promise = ((this.approvalRepo instanceof FirmwareApprovalRepo) && (this.approval instanceof EntityReference)) ? this.approvalRepo.getEntityPromise(this.approval.identifier()) : new Promise( (rs,rj) =>  {rs(null);});
        firmware_update_promise.then((result: FirmwareDefinitionEntity) => {
          console.log(result);
          if (result) this.firmware_entity = result;
          if (--remaining == 0)  resolve(true);
        });
        rollback_update_promise.then((result: FirmwareDefinitionEntity) => {
          if (result) this.rollback_entity = result;
          if (--remaining == 0)  resolve(true);
        });
        approval_update_promise.then((result: FirmwareApprovalEntity) => {
          if (result) this.approval_entity = result;
          if (--remaining == 0)  resolve(true);
        });
      }
    );

    return super.refresh(data);
  }
  // Sets the fields, used above, and to reset the values when canceling an edit

  widget_type() {
    return WidgetEnum.EMBED_WIDGET__FIRMWARE;
  }

  firmware_widget_type() {
    return FirmwareWidgetEnum.FIRMWARE_WIDGET__RELEASE
  }



  validate() {

    let valid = true;
    let messages = {};
    let error = "";
    if (!this.release_name) {
      valid = false;
      messages['release_name'] = "Release Name Required"
    }
    if (!this.release_description) {
      valid = false;
      messages['release_description'] = "Release Description Required"
    }

    if (!this.firmware) {
      valid = false;
      messages['firmware'] = "Target Firmware Required."
    }

    if (!this.rollback) {
      valid = false;
      messages['rollback'] = "Rollback Test Firmware Required."
    }

    let target_error_count = 0;
    if (this.targets.length == 0) {
      valid = false;
      messages['targets'] = "Target Required."
    } else {
      for(let i in this.targets) {
        this.targets[i].validate();
        if (!this.targets[i].validation.valid) {
          valid = false;
          target_error_count++;
          if (target_error_count == 1) {
            messages['targets'] = "1 Incomplete Target."
          } else {
            messages['targets'] = `${target_error_count} Incomplete Targets.`
          }
        }
      }
    }

    if (!valid) {
      messages['common'] = 'Release Definition Has Errors.'
      error = "Errors Found";
    }


    this.validation = {valid: valid,  validated: true, error: error, messages: messages}
    return this.validation;
  }

} // end Package
