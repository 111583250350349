
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
            <div class="col"><b class="text-left">Runtime Configuration </b></div>
            <div class="col-2 text-right p-0 text-middle">
              <toggle-widget [options]="expand_settings"></toggle-widget>
            </div>
          </div>
          </div>
          <div class="card-body"  [ngClass]="{'d-none': !expand_settings.toggle}" ><pre>{{entity.toJson()| json}}</pre></div>
        </div>
      </div>
    </div>
    