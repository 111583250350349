
import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from '../lacrosse.entity';
import { FirebaseAuthService }            from '../../noizu/services/firebase-auth.service';

export class DeviceDuplicatesEntity extends LacrosseEntity {
  public sensorSerial: string;
  public sensorIds: Array<string> | null;
  public purgedSensorIds: Array<string> | null;
  public lookup: String = null;

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    if (json) {
      this.sensorSerial = json.id;
      this.sensorIds = json.sensorIds;
      this.purgedSensorIds = json.purgedSensorIds;
    }
  } // end constructor

  migrate(id, remove) {
    const rs = remove ? 'true' : 'false';
    const response = {}
    for (let i = 0; i < this.sensorIds.length; i++) {
      if (this.sensorIds[i] !== id) {
        const p =       this._put(
          `${this.apiBase()}/migrate/${this.sensorIds[i]}/to/${id}?remove=${rs}`,
          (data, resolve) => {resolve(data); },
          {}
        );
        response[this.sensorIds[i]] = p;
      }
    }
    return response;
  }

  getLookup() {
    const re = /ref.device.(.*)@(.*)/i;
    const m = this.sensorSerial.match(re);
    const serial = m[1];
    const series = m[2];
    return this._get(
      `${this.apiBase()}/admin-tools/devices/legacy-mapping/${series}/${serial}`,
      (data, resolve) => {this.lookup = data['appengine_id']; resolve(data);},
      {}
    );
  }

  setLookup(id) {
    const re = /ref.device.(.*)@(.*)/i;
    const m = this.sensorSerial.match(re);
    const serial = m[1];
    const series = m[2];
    return this._put(
      `${this.apiBase()}/admin-tools/devices/legacy-mapping/${series}/${serial}/${id}`,
      {},
      (data, resolve) => {
        if (data['outcome'] === true) {
          this.lookup = id;
        }
        resolve(data);},
      {}
    );
  }

  clearLookup() {
    const re = /ref.device.(.*)@(.*)/i;
    const m = this.sensorSerial.match(re);
    const serial = m[1];
    const series = m[2];
    return this._delete(
      `${this.apiBase()}/admin-tools/devices/legacy-mapping/${series}/${serial}`,
      (data, resolve) => {
        if (data['outcome'] === true) {
          this.lookup = null;
        }
      resolve(data);},
      {}
    );
  }

} // end Package
