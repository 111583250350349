import {HttpClient} from '@angular/common/http';
import { FirebaseAuthService } from '../../noizu/services/firebase-auth.service';
import {NoizuStruct} from '../../noizu/structs';
import {
  AlertEntityLogEntity,
  DataStreamEntityLogEntity,
  DeviceEntityLogEntity,
  GatewayEntityLogEntity,
  NotificationEntityLogEntity
} from './entity-log';
import {EntityLogEntity} from './entity-log.entity';


export class EntityLogFactory extends NoizuStruct {

  public static factory(client: HttpClient, auth: FirebaseAuthService, json) {


    switch(json['type']) {
      case 'alert':
        return new AlertEntityLogEntity(client, auth, json);
        break;
      case 'notification':
        return new NotificationEntityLogEntity(client, auth, json);
        break;
      case 'Elixir.GoldenRatio.GatewayEntity':
        json['type'] = 'gateway';
      case 'gateway':
        return new GatewayEntityLogEntity(client, auth, json);
        break;
      case 'Elixir.GoldenRatio.DeviceEntity':
        json['type'] = 'device';
      case 'device':
        return new DeviceEntityLogEntity(client, auth, json);
        break;
      case 'data_stream':
        return new DataStreamEntityLogEntity(client, auth, json);

      default:
        return new EntityLogEntity(client, auth, json);
    }


  }


} // end Package
