
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { FirebaseAuthService }        from '../noizu/services/firebase-auth.service';
import { DomainObject }        from '../noizu/domain-object';
import { WidgetEnum} from '../enums';

export class LacrosseEntity extends DomainObject {
  public flagged_for_synch: boolean;
  public flagged_for_synch_vnext: boolean;
  public last_synched: Date;
  public modifiedOn: Date;
  public createdOn: Date;
  public handle: string;
  public _kind: string = null;
  public _singular: string = null;

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    this.strip_from_json["_singular"] = 1;
    this.strip_from_json["_version"] = 1;
    this.strip_from_json["_api"] = 1;
    this.strip_from_json["_endpoint"] = 1;
    this.strip_from_json["_ingv_endpoint"] = 1;
    this.strip_from_json["_ingv_version"] = 1;
    this.strip_from_json["_sref_module"] = 1;
  } // end constructor

  refresh(data) {
    if (data) {
      let ls = data["lastSynched"] || data["last_synched"];
      let dm = data["modifiedOn"] || data["modified_on"];
      let dc = data["createdOn"] || data["created_on"];
      this.handle = data["handle"] || null;

      if (ls) this.last_synched = new Date(ls);
      else this.last_synched = null;

//    date/string to readable date: {{someDate | date:'MM/dd/yyyy @ h:mm:ssa'}}
      if (dm) this.modifiedOn = new Date(dm);
      else this.modifiedOn = null;
      if (dc) this.createdOn = new Date(dc);
      else this.createdOn = null;

      if (data["flaggedForSynch"] != undefined)
       data["flagged_for_synch"] = data["flaggedForSynch"];

      this.flagged_for_synch = data["flaggedForSynch"] || data["flagged_for_synch"] || false;
      this.flagged_for_synch_vnext = data["flaggedForSynchVNext"] || false;
      this.meta['new'] = false;
    }
    return super.refresh(data);
  }

  singular(str) {
    this._singular = str;
  }

  update() {
      if (this.identifier == "new" || this.meta['new']) {
        let entity = this.toJson({for: "appengine"});
        console.log("SAVE FORMAT", entity)
        entity.id = this.identifier;
        if (entity.id == 'new') {
          // Allow specified identifier
          entity.id = null;
        }
        return this._post(
          `${this.apiBase()}/${this._kind}`,
          entity,
          (data, resolve) => {resolve(this.refresh(data));},
          {}
        );
      } else {
        console.log("Save", this.toJson({for: "appengine"}));
        let raw = this.toJson({for: "appengine"})
        console.log("put")
        return this._put(
          `${this.apiBase()}/${this._singular}/${this.identifier}`,
          raw,
          (data, resolve) => {resolve(this.refresh(data));},
          {}
        );
      }
  }

  delete() {
    return this._delete(
      `${this.apiBase()}/${this._singular}/${this.identifier}`,
      (_data, resolve) => {resolve(true)},
      {}
    );
  }

  public widget() {
    return `embed-${this._singular}`;
  }

} // end Package
