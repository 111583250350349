import { NoizuStruct }        from '../../../../noizu/structs/noizu-struct';
import { NodeContentTypeEnum }      from '../../../../enums/node-content-type.enum';

export class FormulaTreeNodeContentType extends NoizuStruct {
  public enum: any;
  public name: string;
  constructor(json: any) {
      super();
      this.name = NodeContentTypeEnum[json];
      this.enum = NodeContentTypeEnum[this.name];
  } // end constructor

  toJson(options = {}) {
    return {name: this.name, enum: this.enum};
  }
}
