

    <ng-template #inlineShow>
        <span [ngSwitch]="entity?.type">
          <span *ngSwitchCase="'by_gateway'"><span><strong>Gateway</strong>:{{entity?.constraints?.gateway}}</span>, <span><strong>Series</strong>:{{entity?.constraints?.series}}</span>, <span><strong>Manufacturer</strong>:{{entity?.constraints?.manufacturer}}</span></span>
          <span *ngSwitchCase="'by_appengine'"><span><strong>Appengine</strong>:{{entity?.constraints?.appengine}}</span>, <span><strong>Series</strong>:{{entity?.constraints?.series}}</span>, <span><strong>Manufacturer</strong>:{{entity?.constraints?.manufacturer}}</span></span>
          <span *ngSwitchCase="'by_type'"><span><strong>Type</strong>:{{entity?.constraints?.type}}</span>, <span><strong>Series</strong>:{{entity?.constraints?.series}}</span>, <span><strong>Manufacturer</strong>:{{entity?.constraints?.manufacturer}}</span></span>
          <span *ngSwitchCase="'by_batch'"><span><strong>Batch</strong>:{{entity?.constraints?.batch}}</span>, <span><strong>Series</strong>:{{entity?.constraints?.series}}</span>, <span><strong>Manufacturer</strong>:{{entity?.constraints?.manufacturer}}</span></span>
          <span *ngSwitchCase="'by_batch_and_type'"><span><strong>Type</strong>:{{entity?.constraints?.type}}</span>, <span><strong>Batch</strong>:{{entity?.constraints?.batch}}</span>, <span><strong>Series</strong>:{{entity?.constraints?.series}}</span>, <span><strong>Manufacturer</strong>:{{entity?.constraints?.manufacturer}}</span></span>
          <span *ngSwitchCase="'by_batch_and_type_and_range'"><span><strong>Range</strong>:[{{entity?.constraints?.range_from}},{{entity?.constraints?.range_to}}]</span>, <span><strong>Type</strong>:{{entity?.constraints?.type}}</span>, <span><strong>Batch</strong>:{{entity?.constraints?.batch}}</span>, <span><strong>Series</strong>:{{entity?.constraints?.series}}</span>, <span><strong>Manufacturer</strong>:{{entity?.constraints?.manufacturer}}</span></span>
          <span *ngSwitchCase="'by_batch_and_range'"><span><strong>Range</strong>:[{{entity?.constraints?.range_from}},{{entity?.constraints?.range_to}}]</span>, <span><strong>Batch</strong>:{{entity?.constraints?.batch}}</span>, <span><strong>Series</strong>:{{entity?.constraints?.series}}</span>, <span><strong>Manufacturer</strong>:{{entity?.constraints?.manufacturer}}</span></span>
          <span *ngSwitchCase="'by_type_and_range'"><span><strong>Range</strong>:[{{entity?.constraints?.range_from}},{{entity?.constraints?.range_to}}]</span>, <span><strong>Type</strong>:{{entity?.constraints?.type}}</span>, <span><strong>Series</strong>:{{entity?.constraints?.series}}</span>, <span><strong>Manufacturer</strong>:{{entity?.constraints?.manufacturer}}</span></span>
          <span *ngSwitchCase="'by_range'"><span><strong>Range</strong>:[{{entity?.constraints?.range_from}},{{entity?.constraints?.range_to}}]</span>, <span><strong>Series</strong>:{{entity?.constraints?.series}}</span>, <span><strong>Manufacturer</strong>:{{entity?.constraints?.manufacturer}}</span></span>
          <span *ngSwitchDefault>[Unknown]</span>
        </span>
    </ng-template>

    <ng-template #inlineEdit>
      <div class="card m-0 p-0 mb-2">
        <div class="card-header card-accent-dark m-0 p-0">
          <div class="row">
            <div class="col pr-0">
              <ng-container *ngTemplateOutlet="selectCriteria"></ng-container>
            </div>
            <div *ngIf="options['target_delete']" class="col-1 pl-0 text-center">
              <i (click)="removeTarget($event)"  class="fa fa-2x fa-minus text-danger"></i>
            </div>
          </div>
        </div>
        <div class="card-body m-0 p-1">
          <div class="row" *ngIf="!entity?.validation?.valid && entity?.validation?.messages['common']">
            <div class="col">
              <div class="alert alert-warning">{{entity?.validation?.messages['common']}}</div>
            </div>
          </div>
          <ng-container [ngSwitch]="entity.type">
            <ng-container *ngTemplateOutlet="selectSeries"></ng-container>
            <ng-container *ngTemplateOutlet="selectManufacturer"></ng-container>

            <ng-container *ngSwitchCase="'by_gateway'">
              <ng-container *ngTemplateOutlet="selectGateway"></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'by_appengine'">
              <ng-container *ngTemplateOutlet="selectAppengine"></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'by_type'">
              <ng-container *ngTemplateOutlet="selectType"></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'by_batch'">
              <ng-container *ngTemplateOutlet="selectBatch"></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'by_batch_and_type'">
              <ng-container *ngTemplateOutlet="selectBatch"></ng-container>
              <ng-container *ngTemplateOutlet="selectType"></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'by_batch_and_type_and_range'">
              <ng-container *ngTemplateOutlet="selectBatch"></ng-container>
              <ng-container *ngTemplateOutlet="selectType"></ng-container>
              <ng-container *ngTemplateOutlet="selectRange"></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'by_batch_and_range'">
              <ng-container *ngTemplateOutlet="selectBatch"></ng-container>
              <ng-container *ngTemplateOutlet="selectRange"></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'by_type_and_range'">
              <ng-container *ngTemplateOutlet="selectType"></ng-container>
              <ng-container *ngTemplateOutlet="selectRange"></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'by_range'">
              <ng-container *ngTemplateOutlet="selectRange"></ng-container>
            </ng-container>

          </ng-container>
        </div>
      </div>
    </ng-template>

    <ng-template #selectCriteria>
      <ng-select
        placeholder="Select Criteria"
        [items]="criteria_types"
        [multiple]="false"
        [(ngModel)]="entity.type"
        bindLabel="label"
        bindValue="value"
        (ngModelChange)="onChangeCriteria($event)"></ng-select>
    </ng-template>

    <ng-template #selectGateway>
      <div class="row" *ngIf="entity?.validation?.messages['gateway']">
        <div class="col">
          <div class="alert alert-danger">{{entity?.validation?.messages['gateway']}}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-4"><strong>Gateway:</strong></div>
        <div class="col-8">
          <input type="text" [(ngModel)]="entity.constraints.gateway" (ngModelChange)="updateDefinition()" size="6" />
        </div>
      </div>
    </ng-template>

    <ng-template #selectSeries>
      <div class="row" *ngIf="entity?.validation?.messages['series']">
        <div class="col">
          <div class="alert alert-danger">{{entity?.validation?.messages['series']}}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-4"><strong>Series: </strong></div>
        <div class="col-8">
          <logical-widget (widgetEvent)="eventHandler($event, 'series')" [entity]="series" [options]="options" [layout]="'default'"></logical-widget>
        </div>
      </div>
    </ng-template>

    <ng-template #selectManufacturer>
      <div class="row" *ngIf="entity?.validation?.messages['manufacturer']">
        <div class="col">
          <div class="alert alert-danger">{{entity?.validation?.messages['manufacturer']}}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-4"><strong>Manufacturer: </strong></div>
        <div class="col-8">
          <logical-widget (widgetEvent)="eventHandler($event, 'manufacturer')" [entity]="manufacturer" [options]="options" [layout]="'default'"></logical-widget>
        </div>
      </div>
    </ng-template>

    <ng-template #selectBatch>
      <div class="row" *ngIf="entity?.validation?.messages['batch']">
        <div class="col">
          <div class="alert alert-danger">{{entity?.validation?.messages['batch']}}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-4"><strong>Batch: </strong></div>
        <div class="col-8">
          <logical-widget (widgetEvent)="eventHandler($event, 'batch')" [entity]="batch" [options]="options" [layout]="'default'"></logical-widget>
        </div>
      </div>
    </ng-template>

    <ng-template #selectAppengine>
      <div class="row" *ngIf="entity?.validation?.messages['appengine']">
        <div class="col">
          <div class="alert alert-danger">{{entity?.validation?.messages['appengine']}}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <strong>Appengine Device: </strong>
        </div>
        <div class="col-8">
          <input type="text" [(ngModel)]="entity.constraints.appengine" (ngModelChange)="updateDefinition()" size="12" />
        </div>
      </div>
    </ng-template>


    <ng-template #selectType>
      <div class="row" *ngIf="entity?.validation?.messages['device_type']">
        <div class="col">
          <div class="alert alert-danger">{{entity?.validation?.messages['device_type']}}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <strong>Device Type: </strong>
        </div>
        <div class="col-8">
          <logical-widget (widgetEvent)="eventHandler($event, 'device_type')" [entity]="device_type" [options]="options" [layout]="'default'"></logical-widget>
        </div>
      </div>
    </ng-template>

    <ng-template #selectRange>
      <div class="row" *ngIf="entity?.validation?.messages['range']">
        <div class="col">
          <div class="alert alert-danger">{{entity?.validation?.messages['range']}}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <strong>Range: </strong>
        </div>
        <div class="col-8">
          <input type="text" [(ngModel)]="entity.constraints.range_from" (ngModelChange)="updateDefinition()" size="6" /> - <input type="text" [(ngModel)]="entity.constraints.range_to" (ngModelChange)="updateDefinition()" size="6" />
        </div>
      </div>
    </ng-template>

    <ng-container *ngIf="entity">

      <ng-container [ngSwitch]="layout">

        <!-- ========================================================================== -->
        <!-- Table Layout                                                               -->
        <!-- ========================================================================== -->
        <div class="firmware-target-widget" *ngSwitchCase="'mini'">
          <ng-container *ngTemplateOutlet="options?.edit ? inlineEdit : inlineShow"></ng-container>
        </div>

        <!-- ========================================================================== -->
        <!-- Inline Layout                                                               -->
        <!-- ========================================================================== -->
        <div class="firmware-target-widget" *ngSwitchCase="'inline'">
          <div class="card" *ngIf="!options?.edit">
            <div class="card-header p-1">{{entity?.type}}</div>
            <div class="card-body p-1 m-1">
              <ng-container *ngTemplateOutlet="inlineShow"></ng-container>
            </div>
          </div>
          <div *ngIf="options?.edit">
            <ng-container *ngTemplateOutlet="inlineEdit"></ng-container>
          </div>
        </div>

        <!-- ========================================================================== -->
        <!-- Default                                                                    -->
        <!-- ========================================================================== -->
        <div class="firmware-target-widget" *ngSwitchDefault>
          <div class="card" *ngIf="!options?.edit">
            <div class="card-header p-1">{{entity?.type}}</div>
            <div class="card-body p-1 m-1">
              <ng-container *ngTemplateOutlet="inlineShow"></ng-container>
            </div>
          </div>
          <div *ngIf="options?.edit">
            <ng-container *ngTemplateOutlet="inlineEdit"></ng-container>
          </div>
        </div>
        <!-- End Switch -->
      </ng-container>

    </ng-container>
  