
import { Component, Inject, Input, NgZone } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location }               from '@angular/common';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { FirmwareDefinitionEntity } from '../../../entities/firmware/definition.entity';
import { FirmwareDefinitionRepo } from '../../../repos/firmware/definition.repo';
import {FirmwareReleaseRepo} from '../../../repos/firmware/release.repo';
import {CacheService} from '../../../services/cache.service';

@Component({
  templateUrl: './list.component.html',
})
export class MCUFirmwareReleaseListComponent {
  public entries: any; //Map<String, Array<FirmwareDefinitionEntity>>;
  public selectedEntry: any;
  constructor(public cache: CacheService, public repo: FirmwareReleaseRepo, public zone: NgZone) {
    this.zone.run(
      () => {
        this.repo.getListPromise().then((u: any) => {
          this.entries = u;
          this.selectedEntry = this.entries[0];
        });
      }
    )
  }
}
