import {HttpClient} from '@angular/common/http';
import {FirebaseAuthService} from '../../../noizu/services/firebase-auth.service';
import {NotificationLogWidgetEnum, LogWidgetEnum, AlertLogWidgetEnum} from '../../../enums';
import {EntityLogEntity} from '../entity-log.entity';

export class NotificationEntityLogEntity extends EntityLogEntity {
  public log_type: NotificationLogWidgetEnum = NotificationLogWidgetEnum.NOTIFICATION_LOG_WIDGET__GENERIC;

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    switch(this.topic) {



      case 'send_trigger':
        this.log_type =  NotificationLogWidgetEnum.NOTIFICATION_LOG_WIDGET__SEND_TRIGGER;
        break;
      case 'send_trigger_warn':
        this.log_type =  NotificationLogWidgetEnum.NOTIFICATION_LOG_WIDGET__SEND_TRIGGER_WARN;
        break;
      case 'send_trigger_error':
        this.log_type =  NotificationLogWidgetEnum.NOTIFICATION_LOG_WIDGET__SEND_TRIGGER_ERROR;
        break;


      case 'send_recover':
        this.log_type =  NotificationLogWidgetEnum.NOTIFICATION_LOG_WIDGET__SEND_RECOVER;
        break;
      case 'send_recover_warn':
        this.log_type =  NotificationLogWidgetEnum.NOTIFICATION_LOG_WIDGET__SEND_RECOVER_WARN;
        break;
      case 'send_recover_error':
        this.log_type =  NotificationLogWidgetEnum.NOTIFICATION_LOG_WIDGET__SEND_RECOVER_ERROR;
        break;

      case 'send_refresh':
        this.log_type =  NotificationLogWidgetEnum.NOTIFICATION_LOG_WIDGET__SEND_REFRESH;
        break;
      case 'send_refresh_warn':
        this.log_type =  NotificationLogWidgetEnum.NOTIFICATION_LOG_WIDGET__SEND_REFRESH_WARN
        break;
      case 'send_refresh_error':
        this.log_type =  NotificationLogWidgetEnum.NOTIFICATION_LOG_WIDGET__SEND_REFRESH_ERROR;
        break;


      default:
        this.log_type = NotificationLogWidgetEnum.NOTIFICATION_LOG_WIDGET__GENERIC;
    }
  } // end constructor

  log_widget_type(): LogWidgetEnum | null {
    return LogWidgetEnum.LOG_WIDGET__NOTIFICATION;
  }

  notification_log_widget_type(): NotificationLogWidgetEnum  {
    return this.log_type;
  }


  public brief() {
    if (!this._brief) {

      switch (this.notification_log_widget_type()) {
        case NotificationLogWidgetEnum.NOTIFICATION_LOG_WIDGET__SEND_TRIGGER:
          this._brief = 'Send Trigger';
          break;
        case NotificationLogWidgetEnum.NOTIFICATION_LOG_WIDGET__SEND_TRIGGER_ERROR:
          this._brief = 'Send Trigger (Error)';
          break;
        case NotificationLogWidgetEnum.NOTIFICATION_LOG_WIDGET__SEND_TRIGGER_WARN:
          this._brief = 'Send Trigger (Warning)';
          break;

        case NotificationLogWidgetEnum.NOTIFICATION_LOG_WIDGET__SEND_RECOVER:
          this._brief = 'Send Recover';
          break;
        case NotificationLogWidgetEnum.NOTIFICATION_LOG_WIDGET__SEND_RECOVER_ERROR:
          this._brief = 'Send Recover (Error)';
          break;
        case NotificationLogWidgetEnum.NOTIFICATION_LOG_WIDGET__SEND_RECOVER_WARN:
          this._brief = 'Send Recover (Warning)';
          break;

        case NotificationLogWidgetEnum.NOTIFICATION_LOG_WIDGET__SEND_REFRESH:
          this._brief = 'Send Refresh';
          break;
        case NotificationLogWidgetEnum.NOTIFICATION_LOG_WIDGET__SEND_REFRESH_ERROR:
          this._brief = 'Send Refresh (Error)';
          break;
        case NotificationLogWidgetEnum.NOTIFICATION_LOG_WIDGET__SEND_REFRESH_WARN:
          this._brief = 'Send Refresh (Warn)';
          break;

        default:
          this._brief = 'Notification (Other)';
      }
    }
    return this._brief;
  }
} // end Package
