
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { DomainObjectRepo } from '../../noizu/domain-object-repo';
import { FirebaseAuthService }        from '../../noizu/services/firebase-auth.service';
import { FirmwareLinkEntity }      from '../../entities/firmware/link.entity';
import { AppengineRepo }      from '../appengine.repo';
import {ElixirRepo} from '../elixir.repo';
import {DataStreamCardEntity} from '../../entities/data-stream-card.entity';
import {FirmwareLinkV2Entity} from '../../entities/firmware/link-v2.entity';
import {MCUFirmwareLinkEntity} from '../../entities/mcu/firmware';

@Injectable()
export class FirmwareLinkV2Repo extends ElixirRepo {
  public _kind = "firmware-links";
  public _singular = "firmware-links";

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  entity(record) {
    return new FirmwareLinkV2Entity(this.client, this.auth, record)
  }

  createPromise(entity: FirmwareLinkV2Entity) {
    if (entity.identifier == 'new') {
      entity.identifier = null;
    }
    const data = entity.toJson();
    console.log("CREATE LINK", data);
    return this._post(`${this.apiBase()}/firmware/v2-links`, data, (v,r) => {
      r(this.entity(v));
    });
  }


  getEntityPromise(id, options = {}) {

    if (this.isSRef(id)) {
      id = this.srefToIdentifier('fw-link-v2', id)
    }

    return this._getEntityPromise(
      `${this.apiBase()}/firmware/v2-links/${id}`,
      (record, resolve) => {
        resolve( this.entity(record) );
      },
      options
    );
  }

  getListPromise( options = {}) {
    return this._get(
      `${this.apiBase()}/firmware/v2-links`,
      (json, resolve) => {
        const response = []; // @todo DataStreamCardSet
        if (json) {
          for (let i = 0; i < json.length; i++) {
            response.push(this.entity(json[i]));
          }
        } else {
          // error handling.
        }
        console.log(response);
        resolve(response);
      },
      options
    );
  }

}
