import { Component, Input, Inject } from '@angular/core';
import {DataStreamWeatherCardEntity, ForecastReading} from '../../../entities/data-stream/weather-card.entity';
import {DataStreamCardEntity} from '../../../entities/data-stream-card.entity';
import {DiagnosticReading} from '../../../entities/data-stream/diagnostic-card.entity';

@Component({
  selector: 'embed-ds-weather-card',
  template: `
      <div class="embed-ds-weather-card" *ngIf="!editMode">
          <div class="row">
              <div class="col font-xl"><b>Text 1:</b> {{entity.messages.one}}</div>
              <div class="col font-xl"><b>Text 2:</b> {{entity.messages.two}}</div>
          </div>
          <div class="row">
              <div class="col font-xl"><b>Link:</b> {{entity.linkType()}}</div>
          </div>
      </div>
      <div class="embed-ds-weather-card" *ngIf="editMode">
          <div class="row">
              <div class="col-12">
                  <div class="card mb-0 pb-0 border-warning">
                      <div class="card-body" *ngIf="entity">
                          <!-- Type Specific -->
                          <div class="row"><div class="col-4"><b>Text One</b></div>
                              <div class="col-8">
                                  <input
                                          type="text"
                                          name="[message]one"
                                          [(ngModel)]="entity.messages.one"
                                          (ngModelChange)="editMessageOne($event)"
                                          name="editMessageOne"
                                          size="10"/>
                              </div></div>
                          <div class="row"><div class="col-4"><b>Text Two</b></div><div class="col-8">
                              <input
                                      type="text"
                                      name="[message]two"
                                      [(ngModel)]="entity.messages.two"
                                      (ngModelChange)="editMessageTwo($event)"
                                      name="editMessageTwo"
                                      size="10"/>
                          </div></div>
                          <div class="row"><div class="col-4"><b>Link</b></div><div class="col-8">
                            <ng-select
                              [name]="'link'"
                              [multiple]="false"
                              [(ngModel)]="entity.link"
                              bindLabel="name"
                              bindValue="id"
                              (ngModelChange)="setLink($event)"
                              [items]="linkOptions"
                            ></ng-select>
                          </div></div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    `
})
export class EmbedDsWeatherCardComponent {
  public editMode = false;
  @Input() entity: DataStreamWeatherCardEntity = null;
  @Input() layout: string = null;
  public _options = {};
  @Input() set options(options: any) {
    this._options = options;
    if ('editMode' in options) {
      this.editMode = options['editMode'];
    }
  }
  get options() {return this._options; }
  linkOptions = [];


  constructor() {
    this.linkOptions = [];
    for (const key in ForecastReading) {
      if (typeof ForecastReading[key] === 'number') {
        this.linkOptions.push({id: ForecastReading[key], name: key});
      }
    }
  }

  editMessageOne(event) {
    // @todo consider mechanism for setting :auto flag.
    this.entity.modified = true;
  }

  editMessageTwo(event) {
    // @todo consider mechanism for setting :auto flag.
    this.entity.modified = true;
  }

  setLink(event) {
    this.entity.modified = true;
  }
}
