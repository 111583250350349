
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { FirebaseAuthService }        from '../noizu/services/firebase-auth.service';
import { AppNotificationEntity } from '../entities/app-notification.entity';
import { AppengineRepo }      from './appengine.repo';

@Injectable()
export class AppNotificationRepo extends AppengineRepo {
  public _kind = "";
  public _singular = "";

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  entity(record) {
    return new AppNotificationEntity(this.client, this.auth, record)
  }

}
