
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { DomainObjectRepo } from '../../noizu/domain-object-repo';
import { FirebaseAuthService } from '../../noizu/services/firebase-auth.service';
import { ElixirRepo } from '../elixir.repo';
import { EntityLogSet } from '../../entities/log/entity-log.set';

@Injectable()
export class EntityLogRepo extends ElixirRepo {
  public _kind = 'entity-logs';
  public _singular = 'entity-log';

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  getSettingsPromise(subject) {
    const url = `${this.apiBase()}/admin-tools/logs/${subject}/settings`;
    return this._get(url, (data, resolve) => {
      let settings = {};
      if (data == null) {
        // Set Defaults.
        settings = {
          'updating': false,
          'enabled': false,
          'retention_period': 604800,
          'retention_length': 25,
          'persist': 'settings',
          'level': {
            'warn': true,
            'trace': false,
            'life_cycle': false,
            'info': true,
            'error': true,
            'debug': false
          }
        }
      } else {
        data['updating'] = false;
        data['enabled'] = (
          data['level']['error'] ||
          data['level']['warn'] ||
          data['level']['info'] ||
          data['level']['trace'] ||
          data['level']['debug'] ||
          data['level']['life_cycle']
        );
        settings = data;
      }
      resolve(settings);
    }, {});
  }


  public filterOptions() {
    return "topic:* subtopic:* level:*";
  }

  refresh(subject) {
    const url = `${this.apiBase()}/admin-tools/logs/${subject}`;
    return this._get(url, (data, resolve) => {
      if (Array.isArray(data)) {
        const r = new EntityLogSet(this.client, this.auth, subject, data, this);
        r.loaded = true;
        resolve(r);
      } else {
        const r = new EntityLogSet(this.client, this.auth, subject, [], this);
        resolve(r);
      }
    }, {});
  }

  enable(subject, settings) {
    let url = `${this.apiBase()}/admin-tools/logs/${subject}/enable-logs?`;
    // retention
    url = url + `retention_length=${settings.retention_length}`;

    // (Disabled due to bug in elixir)
    // retention period
    // url = url + `&retention_period=${settings.retention_period}`;

    // Persistence
    url = url + `&persist=${settings.persist}`;

    // Levels
    let levels = '';
    if (settings.level.error) {
      levels += `error=on,`;
    } else {
      levels += `error=on,`;
    }

    if (settings.level.warn) {
      levels += `warn=on,`;
    } else {
      levels += `warn=off,`;
    }

    if (settings.level.info) {
      levels += `info=on,`;
    } else {
      levels += `info=off,`;
    }

    if (settings.level.debug) {
      levels += `debug=on,`;
    } else {
      levels += `debug=off,`;
    }

    if (settings.level.trace) {
      levels += `trace=on,`;
    } else {
      levels += `trace=off,`;
    }

    if (settings.level.life_cycle) {
      levels += `life_cycle=on`;
    } else {
      levels += `life_cycle=off`;
    }

    url = url + `&levels=${levels}`;

    return this._put(url, {}, (data, resolve) => {
      resolve(data);
    });
  }

  disable(subject, settings) {
    return this._put(`${this.apiBase()}/admin-tools/logs/${subject}/disable-logs`, {}, (data, resolve) => {
      resolve(data);
    });
  }

}
