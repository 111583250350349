export class DeviceStatusEntity {
  public last_update: String;
  public fall_back: boolean = false;
  public time_zone:String;
  public battery_status: String;
  public battery_level: String;
  public feed = [];
  public raw;

  constructor(data: any = {}) {
    this.refresh(data);
  } // end constructor

  refresh(data) {
    this.last_update = data.last_update;
    this.time_zone = data.time_zone;
    this.fall_back = false;
    if (data.status) {
      this.battery_status = data.status.battery_status;
      this.battery_level = data.status.battery_level;
    }
    this.feed = [];
    for (let key of Object.keys(data.data)) {
      let emp = Object.keys(data.data[key]).length === 0 ? true : false;
      let n = key;
      if (key.localeCompare("ai.minutes.5") == 0 || key.localeCompare("ai.minutes.1") == 0)
        n = "ai.minutes.0" + key.slice(-1);
      this.feed.push({"name":n, "show":false,"empty":emp,"data":data.data[key]})
    }

    var ordering = {},
    sortOrder = ['unbound','current','minutes','hours','days','weeks','months'];
    for (var i=0; i<sortOrder.length; i++)
        ordering[sortOrder[i]] = i;

    this.feed.sort( function(a, b) {
        let sa = a.name.split(".");
        let sb = b.name.split(".");
        let ca = sa.length > 1 ? sa[1] : a.name;
        let cb = sb.length > 1 ? sb[1] : b.name;
        return (ordering[ca] - ordering[cb]) || a.name.localeCompare(b.name);
    });
    for (let key of this.feed) {
      if (key.name.localeCompare("ai.minutes.05") == 0 || key.name.localeCompare("ai.minutes.01") == 0)
        key.name = "ai.minutes." + key.name.slice(-1);
    }
    this.raw = data;
  }


} // end Package
