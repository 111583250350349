import { Component,Input } from '@angular/core';
import { NotesRepo } from '../../repos/notes.repo';


@Component({
  selector: 'entity-notes',
  template: `
<div class="row card">
  <div class="card-header">
    <span>{{this.entity}}</span>
    <button (click)="retrieveNotes($event)" class="btn btn-sm btn-info pull-right">Get Notes</button>
  </div>

  <div class="card-block" style="max-height:250px; overflow-y:scroll">
    <div *ngIf="notes">
    <div *ngFor="let note of notes" class="my-2 col-12">
      <pre><b>{{note.account}} at {{note.timeStamp|date:'MM/dd/yyyy @ h:mm:ssa'}} Says: </b>
{{note.note}}</pre>
    </div>
    </div>
  </div>
  <div>
    <input class="form-control" id="entity-note" name="noteHolder" placeholder="Note" type="text" [(ngModel)]="newnote" autocomplete="off">
    <button (click)="saveNote($event)" class="btn btn-info btn-block">Add Note</button>
  </div>

</div>
`,
styles: [``]
})


export class EntityNotesComponent {
  @Input() entity: string = null;
  public notes = null;
  public newnote = "";

  //loggedIn;
  constructor(public repo: NotesRepo){
      //this.getNotes();
  }

  retrieveNotes($event){
    $event.preventDefault();
    this.getNotes();
  }

  getNotes(){
    this.repo.getNotes(this.entity).then(
      (ret) => {
        this.notes = ret["notes"];
      }
    );
  }

  saveNote($event){
    $event.preventDefault();
    let self = this;
    if (this.newnote.length > 2){
      this.repo.saveNote(this.entity, this.newnote).then((ret)=> {self.getNotes();});
    }
  }


}
