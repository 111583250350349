import {
  LogicalDeviceFieldWidgetEnum,
  LogicalDeviceWidgetEnum,
} from '../../../enums/widget';
import {DeviceEntity} from '../../device.entity';
import {DeviceLogicalWidget} from '../logical-widget';
import {DeviceDefinitionFieldEntry} from '../../../structs/device/definition/field-entry';

export class FieldSimulatorWidget extends DeviceLogicalWidget{
  public value: number = 0;
  public previous_value: number = 0;
  public field_type: LogicalDeviceFieldWidgetEnum = LogicalDeviceFieldWidgetEnum.LOGICAL_DEVICE_FIELD_WIDGET__NOT_SUPPORTED;

  constructor(field: DeviceDefinitionFieldEntry) {
    super(LogicalDeviceWidgetEnum.LOGICAL_DEVICE_WIDGET__FIELD_SIMULATOR);
    this.subject = field;
    this.field_type = LogicalDeviceFieldWidgetEnum[LogicalDeviceFieldWidgetEnum[field.field.type] || 0];
  } // end constructor

  public logical_device_field_type(): LogicalDeviceFieldWidgetEnum | number | string | null {
    return this.field_type;
  }



  binaryEncode(input) {
    return this.subject.binaryEncode(input);
  }


} // end Package
