import { NoizuStruct }        from '../../noizu/structs/noizu-struct';
import { FormulaTreeNodeType }  from './node/type';
import { FormulaTreeNodeContent } from './node/content';

export class FormulaTreeNode extends NoizuStruct {
  public node_identifier: string;
  public materialized_path: string;
  public content: FormulaTreeNodeContent; // @TODO type
  public children: Array<FormulaTreeNode>;
  public nodeType: number;
  public ufNode_type: FormulaTreeNodeType;
  constructor(json: any) {
      super();
      if (json) {
        let nt = json["nodeType"] || json["node_type"];
        this.nodeType = nt ;
        this.ufNode_type = nt ? new FormulaTreeNodeType(nt) : null;
        this.strip_from_json["ufNode_type"] = 1;
        this.content = json["content"] ? new FormulaTreeNodeContent(json["content"]) : null;

        this.node_identifier = json["nodeIdentifier"] || json["node_identifier"];
        this.materialized_path = json["materializedPath"] || json["materialized_path"];
        if (json["children"]) {
          this.children = [];
          for(let i = 0; i < json["children"].length; i++) {
            this.children.push(new FormulaTreeNode(json["children"][i]));
          }
        } else {
          this.children = [];
        }
      }

  } // end constructor
}
