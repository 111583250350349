
import { Component, Inject, Input, NgZone } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location }               from '@angular/common';
import { NgModel, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ChannelDefinitionEntity } from '../../entities/channel-definition.entity';
import { ChannelDefinitionRepo } from '../../repos/channel-definition.repo';

@Component({
  templateUrl: './list.component.html',
})
export class ChannelDefinitionListComponent {
  constructor(public repo: ChannelDefinitionRepo) {
  }
}
