import { Component, Input, Inject } from '@angular/core';
import {NoizuStruct} from '../../../noizu/structs/noizu-struct';

@Component({
  selector: 'ds-card-embed',
  template: `
<div *ngIf="!entity">
  <widget-spinner></widget-spinner>
</div>
<ng-container *ngIf="entity">
  <ng-container [ngSwitch]="template()">

    <!-- Data Stream Cards -->
    <embed-ds-device-card
      *ngSwitchCase="'embed-ds-device-card'"
      [entity]="entity" [layout]="layout" [options]="options"></embed-ds-device-card>
    <embed-ds-diagnostic-card
      *ngSwitchCase="'embed-ds-diagnostic-card'"
      [entity]="entity" [layout]="layout" [options]="options"></embed-ds-diagnostic-card>
    <embed-ds-media-card
      *ngSwitchCase="'embed-ds-media-card'"
      [entity]="entity" [layout]="layout" [options]="options"></embed-ds-media-card>
    <embed-ds-nws-card
      *ngSwitchCase="'embed-ds-nws-card'"
      [entity]="entity" [layout]="layout" [options]="options"></embed-ds-nws-card>
    <embed-ds-weather-card
      *ngSwitchCase="'embed-ds-weather-card'"
      [entity]="entity" [layout]="layout" [options]="options"></embed-ds-weather-card>
    <embed-ds-time-card
            *ngSwitchCase="'embed-ds-time-card'"
            [entity]="entity" [layout]="layout" [options]="options"></embed-ds-time-card>
    <embed-ds-generic-card
      *ngSwitchDefault
      [entity]="entity" [layout]="layout" [options]="options"></embed-ds-generic-card>
  </ng-container>
</ng-container>
`
})
export class WidgetEmbedDSCardComponent {
  @Input() entity: any = null;
  @Input() templateHint: string = null;
  @Input() options: any = null;
  @Input() layout = 'standard';

  constructor() {
  }

  public template() {
    if (this.entity instanceof NoizuStruct) {
      return this.entity.widget();
    } else {
      return this.templateHint ? this.templateHint : 'default';
    }
  }

}
