
    <div class="card shadow" *ngIf="entity">
      <div class="card-header">
        <div class="row">
          <div class="col">
            User Devices
            <span *ngIf="options" class="float-right"><i (click)="toggleExpand()" class="fa fa-sm" [ngClass]="{'fa-chevron-down': !options.expand, 'fa-chevron-up': options.expand}"> </i></span>
          </div>
        </div>
      </div>

      <div class="card-body p-1 m-0" *ngIf="entity && expandWidget()">

        <ng-container *ngIf="entity?._nested?.deviceAssociations?.items">
            <widget [entity]="entity._nested.deviceAssociations" [layout]="'table'" [options]="{}"></widget>
        </ng-container>

      </div>
    </div>
  