import {FirmwareLogicalWidgetEnum, LogicalWidgetEnum,} from '../../enums/widget';
import {LogicalWidget} from '../logical-widget';

export class FirmwareLogicalWidget extends LogicalWidget{
  public selected: any = null;

  logical_widget_type(): LogicalWidgetEnum | null {
    return LogicalWidgetEnum.LOGICAL_WIDGET__FIRMWARE;
  }

  firmware_logical_widget_type(): FirmwareLogicalWidgetEnum | null {
    return FirmwareLogicalWidgetEnum.FIRMWARE_LOGICAL_WIDGET__GENERIC
  }

  constructor() {
    super();
  } // end constructor
} // end Package

