<page-header [section]="'mcu-releases'">
  <page-title>Release Managers</page-title>
  <page-blurb> </page-blurb>
</page-header>

<spinner *ngIf="!entries"></spinner>

<div *ngIf="entries">

  <div class="row">

    <div class="col-2">
      <div class="row">
        <div class="col-12" *ngFor="let entry of entries">
          <widget-embed (click)="selectedEntry = entry" [entity]="entry" [options]="{}" [layout]="'thumb'"> </widget-embed>
        </div>
      </div>
    </div>

    <div class="col-10" *ngIf="selectedEntry">
      <div class="row">
        <div class="col-12">
          <widget-embed  [entity]="selectedEntry" [options]="{}" [layout]="'standard'"> </widget-embed>
        </div>
      </div>
    </div>



  </div>
</div>
