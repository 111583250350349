import { NoizuStruct }        from '../../../noizu/structs/noizu-struct';
import { AggregationLevel }   from '../../aggregation/level';
import { AggregationType }   from '../../aggregation/type';

export class DeviceDefinitionAggregationRule extends NoizuStruct {
  public count: number;
  public retention: any;
  public type: number;
  public ufType: AggregationType;
  public period: number;
  public ufPeriod: AggregationLevel;

  constructor(json: any) {
    super();
    this.count = json["count"];
    this.ufType = new AggregationType(json["type"]);
    this.retention = json["retention"];
    this.ufPeriod = new AggregationLevel(json["period"]);
    this.period = json["period"];
    this.type = json["type"];
    this.strip_from_json["ufPeriod"] = 1;
    this.strip_from_json["ufType"] = 1;
  } // end constructor

}
