import {AlertFacadeEntity} from '../facade.entity';
import {ValueAndUnit} from '../../../structs/value-and-unit';
import {WidgetEnum_Alert_Facade} from '../../../enums/widget/alert';
import {cloneDeep} from 'lodash';
import {AlertFormatEnum, UnitEnum} from '../../../enums';

export class AlertHumidityFacadeEntity extends AlertFacadeEntity {
  public humidityAbove: ValueAndUnit;
  public humidityBelow: ValueAndUnit;
  public indoorHumidityAbove: ValueAndUnit;
  public indoorHumidityBelow: ValueAndUnit;

  public editHumidityAbove: ValueAndUnit;
  public editHumidityBelow: ValueAndUnit;
  public editIndoorHumidityAbove: ValueAndUnit;
  public editIndoorHumidityBelow: ValueAndUnit;

  public constructor(alert, json) {
    super(alert, json);

    this.humidityAbove = null;
    if (json["humidityAbove"]) this.humidityAbove = new ValueAndUnit(json["humidityAbove"]);

    this.humidityBelow = null;
    if (json["humidityBelow"]) this.humidityBelow = new ValueAndUnit(json["humidityBelow"]);

    this.indoorHumidityAbove = null;
    if (json["indoorHumidityAbove"]) this.indoorHumidityAbove = new ValueAndUnit(json["indoorHumidityAbove"]);

    this.indoorHumidityBelow = null;
    if (json["indoorHumidityBelow"]) this.indoorHumidityBelow = new ValueAndUnit(json["indoorHumidityBelow"]);

    this.editHumidityAbove = this.humidityAbove ? cloneDeep(this.humidityAbove) : new ValueAndUnit({value: 0.0, enum: UnitEnum.DegreesCelsius});
    this.editHumidityBelow = this.humidityBelow ? cloneDeep(this.humidityBelow) : new ValueAndUnit({value: 0.0, enum: UnitEnum.DegreesCelsius});
    this.editIndoorHumidityAbove = this.indoorHumidityAbove ? cloneDeep(this.indoorHumidityAbove) : new ValueAndUnit({value: 0.0, enum: UnitEnum.DegreesCelsius});
    this.editIndoorHumidityAbove = this.indoorHumidityBelow ? cloneDeep(this.indoorHumidityBelow) : new ValueAndUnit({value: 0.0, enum: UnitEnum.DegreesCelsius});

    if (this.humidityAbove && this.humidityBelow) {
      if (this.humidityAbove.value < this.humidityBelow.value) this.subType = AlertFormatEnum.ValueBetween;
      else this.subType = AlertFormatEnum.ValueOutside;
    }
    else if (this.humidityAbove) this.subType = AlertFormatEnum.ValueAbove;
    else if (this.humidityBelow) this.subType = AlertFormatEnum.ValueBelow;
    else if (this.indoorHumidityAbove && this.indoorHumidityBelow) {
      if (this.indoorHumidityAbove.value < this.indoorHumidityBelow.value) this.subType = AlertFormatEnum.IndoorValueBetween;
      else this.subType = AlertFormatEnum.IndoorValueOutside;
    }
    else if (this.indoorHumidityAbove) this.subType = AlertFormatEnum.IndoorValueAbove;
    else if (this.indoorHumidityBelow) this.subType = AlertFormatEnum.IndoorValueBelow;
  }


  public updateValues() {
    this.humidityAbove = null;
    this.humidityBelow = null;
    this.indoorHumidityBelow = null;
    this.indoorHumidityBelow = null;

    if (this.subType == AlertFormatEnum.ValueAbove) {
      this.humidityAbove = cloneDeep(this.editHumidityAbove);
    }
    else if (this.subType == AlertFormatEnum.ValueBelow) {
      this.humidityBelow = cloneDeep(this.editHumidityBelow);
    }
    else if (this.subType == AlertFormatEnum.ValueBetween || this.subType == AlertFormatEnum.ValueOutside) {
      this.humidityAbove = cloneDeep(this.editHumidityAbove);
      this.humidityBelow = cloneDeep(this.editHumidityBelow);
    }
    else if (this.subType == AlertFormatEnum.IndoorValueAbove) {
      this.indoorHumidityBelow = cloneDeep(this.editIndoorHumidityBelow);
    }
    else if (this.subType == AlertFormatEnum.IndoorValueBelow) {
      this.indoorHumidityBelow = cloneDeep(this.editIndoorHumidityBelow);
    }
    else if (this.subType == AlertFormatEnum.IndoorValueBetween || this.subType == AlertFormatEnum.IndoorValueOutside) {
      this.indoorHumidityBelow = cloneDeep(this.editIndoorHumidityBelow);
      this.indoorHumidityBelow = cloneDeep(this.editIndoorHumidityBelow);
    }

  }


  //-----------------------------------
  //
  //-----------------------------------
  public get template(): string | null {
    return 'humidity';
  }

  alert_facade_widget() {
    return WidgetEnum_Alert_Facade.ALERT_FACADE_WIDGET__HUMIDITY;
  }
}
