import { Component, Inject, Input, OnInit} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { GroupEntity } from '../../entities/group.entity';
import { GroupRepo } from '../../repos/group.repo';

@Component({
  selector: 'group-entry',
  templateUrl: './group-entry.component.html',
})
export class GroupEntryComponent implements OnInit{
  @Input() group: GroupEntity = null;
  @Input() uid: String = null;
  @Input() source: Number = 0;
  public errors = {"show":false,"msg":""};
  public saving = false;
  public subscription;
  public unmodifiedEntry = null;
  public editEntry : boolean = false;
  public gidSelect;
  public groupsList = [];
  public sysGid;
  public sysGroups = [];
  public isUser = false;
  constructor(public groupRepo: GroupRepo, public router: Router, public activatedRoute: ActivatedRoute) {}

  toGroup(event, gid) {
    event.preventDefault();
    this.group = null;
    if (this.uid) {
      this.router.navigateByUrl(`/portal/groups/show/${gid}/${this.uid}`);
    } else {
      this.router.navigateByUrl(`/portal/groups/show/${gid}`);
    }
  }

  ngOnInit(): void {
    this.subscription = this.activatedRoute.params.subscribe(
      (params: any) => {
        if ("uid" in params) {
          this.uid = params.uid;
        }
      }
    );
    if (this.source === 1) {
      this.isUser = true;
    }
    if (this.group.identifier == "new") {
      this.getSysgroups()
    }
  }

  leaveGroup(e, gid) {
    e.preventDefault();
    this.groupsList.splice(this.groupsList.indexOf(gid), 1);
  }

  addGroup(e) {
    e.preventDefault();
    if (this.gidSelect.trim().length == 16) {
      this.groupsList.push(this.gidSelect.trim());
    }
  }
  addSysGroup(e) {
    e.preventDefault();
    if (this.sysGid.trim().length == 16) {
      this.groupsList.push(this.sysGid.trim());
    }
  }

  getSysgroups() {
    if (this.sysGroups.length == 0) {
      let options = {"system":true};
      this.groupRepo.getListPromise(options).then(
        (results) => {
          let vals = [];
          for(let x = 0;x < results["items"].length;x++) {
            vals.push({"id":results["items"][x]["identifier"], "name":results["items"][x]["name"]})
          }
          this.sysGroups = vals;
        }
      );
    }
  }

  update(e) {
    e.preventDefault();
    this.editEntry = false;
    this.errors.show=false;
    this.group.update().then((res:any) => {
      this.saving = false;
    }).catch((err:any) => {
      err = err.details;
      console.log(err);
      this.errors.show = true;
      this.errors.msg = err.error && err.error.message ? err.error.message : "An error occured and changes were likely not saved";;
      this.saving = false;
    });
  }

  edit(e) {
    e.preventDefault();
    this.groupsList = this.group.memberOf;
    this.editEntry = true;
    this.getSysgroups();
  }

  cancel(e) {
    e.preventDefault();
    this.editEntry = false;
    if (this.group.identifier == "new") {
      this.group = null;
    }
  }

}
