import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToasterService} from 'angular2-toaster';
import {AlertHumidityFacadeEntity} from '../../../../entities/alert';
import {AlertFormatEnum} from '../../../../enums';
import {AlertFacadeDefaultWidgetComponent} from './default.widget.component';
import {AlertFacadeWidgetEventStruct} from './alert-facade-widget-event.struct';

@Component({
  selector: 'alert-facade-humidity-widget',
  template: `
    <div class="card card-accent-dark alert-facade-humidity-widget">
      <div class="card-header">Humidity Alert
        <div class="card-header-actions">
          <ng-select
            placeholder="Sub Type"
            [items]="alert_format"
            [multiple]="false"
            bindLabel="label"
            bindValue="value"
            [(ngModel)]="temperatureFormatSelector"
            (ngModelChange)="subTypeChange($event)"
          ></ng-select>
      </div>
      <div class="card-body">
        <div class="row"><div class="col-4">Device</div><div class="col-8"><a [routerLink]="'/portal/devices/show/' + entity.device">{{entity.device}}</a></div></div>
        <div class="row"><div class="col-4">Association</div><div class="col-8">{{entity.deviceAssociation}}</div></div>
        <div class="row"><div class="col-12"><hr/></div></div>



        <div [ngSwitch]="entity.subType">
          <div *ngSwitchCase="FormatEnum.ValueAbove" class="row">
            <div class="col-5"><b>Humidity Above</b></div>
            <div *ngIf="!options?.edit" class="col-7"><b>{{entity.editHumidityAbove.value}}</b> <span> {{entity.editHumidityAbove.unit?.name}}</span></div>
            <div *ngIf="options?.edit" class="col-7">
              <input
                type="number"
                [(ngModel)]="entity.editHumidityAbove.value"
                (ngModelChange)="inputChange($event, 'temperatureAbove')"
                name="humidity_above"
              />
              <span> {{entity.editHumidityAbove.unit?.name}}</span>
            </div>
          </div>
          <div *ngSwitchCase="FormatEnum.ValueBelow" class="row">
            <div class="col-5"><b>Humidity Below</b></div>
            <div *ngIf="!options?.edit" class="col-7"><b>{{entity.editHumidityBelow.value}}</b> <span> {{entity.editHumidityBelow.unit?.name}}</span></div>
            <div *ngIf="options?.edit" class="col-7">
              <input
                type="number"
                [(ngModel)]="entity.editHumidityBelow.value"
                (ngModelChange)="inputChange($event, 'temperatureBelow')"
                name="humidity_below"
              />
              <span> {{entity.editHumidityBelow.unit?.name}}</span>
            </div>
          </div>

          <div *ngSwitchCase="FormatEnum.ValueBetween" class="row">
            <div class="col-5"><b>Humidity Between</b></div>
            <div *ngIf="!options?.edit" class="col-7"><b>{{entity.editHumidityAbove.value}}</b> <span> {{entity.editHumidityAbove.unit?.name}}</span> and <b>{{entity.editHumidityBelow.value}}</b> <span> {{entity.editHumidityBelow.unit?.name}}</span></div>
            <div *ngIf="options?.edit" class="col-7">
              <input
                type="number"
                [(ngModel)]="entity.editHumidityAbove.value"
                (ngModelChange)="inputChange($event, 'entity.editHumidityAbove')"
                name="humidity_above"
              />
              <span> {{entity.editHumidityAbove.unit?.name}}</span>
              and
              <input
                type="number"
                [(ngModel)]="entity.editHumidityBelow.value"
                (ngModelChange)="inputChange($event, 'entity.editHumidityBelow')"
                name="humidity_below"
              />
              <span> {{entity.editHumidityBelow.unit?.name}}</span>
            </div>
          </div>

          <div *ngSwitchCase="FormatEnum.ValueOutside" class="row">
            <div class="col-5"><b>Humidity Outside</b></div>
            <div *ngIf="!options?.edit" class="col-7"><b>{{entity.editHumidityBelow.value}}</b> <span> {{entity.editHumidityBelow.unit?.name}}</span> and <b>{{entity.editHumidityAbove.value}}</b> <span> {{entity.editHumidityAbove.unit?.name}}</span></div>
            <div *ngIf="options?.edit" class="col-7">
              <input
                type="number"
                [(ngModel)]="entity.editHumidityBelow.value"
                (ngModelChange)="inputChange($event, 'entity.editHumidityBelow')"
                name="humidity_below"
              />
              <span> {{entity.editHumidityBelow.unit?.name}}</span>
              and
              <input
                type="number"
                [(ngModel)]="entity.editHumidityAbove.value"
                (ngModelChange)="inputChange($event, 'entity.editHumidityAbove')"
                name="humidity_above"
              />
              <span> {{entity.editHumidityAbove.unit?.name}}</span>
            </div>
          </div>


          <div *ngSwitchCase="FormatEnum.IndoorValueAbove" class="row">
            <div class="col-5"><b>Indoor Humidity Above</b></div>
            <div *ngIf="!options?.edit" class="col-7"><b>{{entity.editIndoorHumidityAbove.value}}</b> <span> {{entity.editIndoorHumidityAbove.unit?.name}}</span></div>
            <div *ngIf="options?.edit" class="col-7">
              <input
                type="number"
                [(ngModel)]="entity.editIndoorHumidityAbove.value"
                (ngModelChange)="inputChange($event, 'entity.editIndoorHumidityAbove')"
                name="humidity_above"
              />
              <span> {{entity.editIndoorHumidityAbove.unit?.name}}</span>
            </div>
          </div>
          <div *ngSwitchCase="FormatEnum.IndoorValueBelow" class="row">
            <div class="col-5"><b>Indoor Humidity Below</b></div>
            <div *ngIf="!options?.edit" class="col-7"><b>{{entity.editIndoorHumidityBelow.value}}</b> <span> {{entity.editIndoorHumidityBelow.unit?.name}}</span></div>
            <div *ngIf="options?.edit" class="col-7">
              <input
                type="number"
                [(ngModel)]="entity.editIndoorHumidityBelow.value"
                (ngModelChange)="inputChange($event, 'entity.editIndoorHumidityBelow')"
                name="humidity_above"
              />
              <span> {{entity.editIndoorHumidityBelow.unit?.name}}</span>
            </div>
          </div>

          <div *ngSwitchCase="FormatEnum.IndoorValueBetween" class="row">
            <div class="col-5"><b>Indoor Humidity Between</b></div>
            <div *ngIf="!options?.edit" class="col-7"><b>{{entity.editIndoorHumidityAbove.value}}</b> <span> {{entity.editIndoorHumidityAbove.unit?.name}}</span> and <b>{{entity.editIndoorHumidityBelow.value}}</b> <span> {{entity.editIndoorHumidityBelow.unit?.name}}</span></div>
            <div *ngIf="options?.edit" class="col-7">
              <input
                type="number"
                [(ngModel)]="entity.editIndoorHumidityAbove.value"
                (ngModelChange)="inputChange($event, 'entity.editIndoorHumidityAbove')"
                name="humidity_above"
              />
              <span> {{entity.editIndoorHumidityAbove.unit?.name}}</span>
              and
              <input
                type="number"
                [(ngModel)]="entity.editIndoorHumidityBelow.value"
                (ngModelChange)="inputChange($event, 'entity.editIndoorHumidityBelow')"
                name="humidity_below"
              />
              <span> {{entity.editIndoorHumidityBelow.unit?.name}}</span>
            </div>
          </div>

          <div *ngSwitchCase="FormatEnum.IndoorValueOutside" class="row">
            <div class="col-5"><b>Indoor Humidity Outside</b></div>
            <div *ngIf="!options?.edit" class="col-7"><b>{{entity.editIndoorHumidityBelow.value}}</b> <span> {{entity.editIndoorHumidityBelow.unit?.name}}</span> and <b>{{entity.editIndoorHumidityAbove.value}}</b> <span> {{entity.editIndoorHumidityAbove.unit?.name}}</span></div>
            <div *ngIf="options?.edit" class="col-7">
              <input
                type="number"
                [(ngModel)]="entity.editIndoorHumidityBelow.value"
                (ngModelChange)="inputChange($event, 'entity.editIndoorHumidityBelow')"
                name="humidity_below"
              />
              <span> {{entity.editIndoorHumidityBelow.unit?.name}}</span>
              and
              <input
                type="number"
                [(ngModel)]="entity.editIndoorHumidityAbove.value"
                (ngModelChange)="inputChange($event, 'entity.editIndoorHumidityAbove')"
                name="humidity_above"
              />
              <span> {{entity.editIndoorHumidityAbove.unit?.name}}</span>
            </div>
          </div>
          <div *ngSwitchDefault></div>
        </div>
      </div>
    </div>
    </div>
    `
})
export class EmbedAlertHumidityFacadeComponent extends AlertFacadeDefaultWidgetComponent implements OnInit {
  public editMode = false;
  @Input() layout: string = null;
  @Input() entity: AlertHumidityFacadeEntity;
  @Input() options: any;
  @Output() widgetEvent = new EventEmitter<AlertFacadeWidgetEventStruct>();
  forwardEvent(e, widgetEvent: AlertFacadeWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }
  format : AlertFormatEnum = AlertFormatEnum.Unknown;
  FormatEnum = AlertFormatEnum;

  constructor(public toasterService: ToasterService) {
    super();
  }

  ngOnInit() {
    this.setFormat();

  }


  setFormat() {
    if (this.entity.humidityAbove || this.entity.humidityBelow) {
      if (this.entity.humidityAbove && this.entity.humidityBelow) {
        if (this.entity.humidityAbove.value < this.entity.humidityBelow.value) {
          this.format = AlertFormatEnum.HumidityInside;
        } else {
          this.format = AlertFormatEnum.HumidityOutside;
        }
      } else if (this.entity.humidityAbove) {
        this.format = AlertFormatEnum.HumidityAbove;
      } else if (this.entity.humidityBelow) {
        this.format = AlertFormatEnum.HumidityBelow;
      }
    }
    else if (this.entity.indoorHumidityAbove || this.entity.indoorHumidityBelow) {
      if (this.entity.indoorHumidityAbove && this.entity.indoorHumidityBelow) {
        if (this.entity.indoorHumidityAbove.value < this.entity.indoorHumidityBelow.value) {
          this.format = AlertFormatEnum.IndoorHumidityInside;
        } else {
          this.format = AlertFormatEnum.IndoorHumidityOutside;
        }
      } else if (this.entity.indoorHumidityAbove) {
        this.format = AlertFormatEnum.IndoorHumidityAbove;
      } else if (this.entity.indoorHumidityBelow) {
        this.format = AlertFormatEnum.IndoorHumidityBelow;
      }
    }
  }


}
