
import { Component, Inject, Input, NgZone } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location }               from '@angular/common';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { DataManufacturersEntity } from '../../entities/data-manufacturers.entity';
import { DataManufacturersRepo } from '../../repos/data/manufacturers.repo';
import {ManufacturerEntity} from '../../entities/manufacturer.entity';
import {ManufacturersEntryComponent} from '../data-management/manufacturers-entry.component';


@Component({
  template: `
    <page-header [section]="'manufacturers'">
      <page-title>Manufacturers</page-title>
      <page-blurb>View and manage the current list of manufacturers. Add new manufacturers to the list.</page-blurb>
    </page-header>

    <spinner *ngIf="!entries"></spinner>

    <div class="row">
      <div class="col">
        <widget [entity]="entries" [options]="{edit: edit, columns: 3}" [layout]="'table'"></widget>
      </div>
    </div>

    <div *ngIf="entries" class="row">

      <div class="row" *ngFor="let entry of entries.items">
        <div class="p-1" flex="grow" >
          <manufacturers-entry [entry]="entry" class="col-6"></manufacturers-entry>
        </div>
      </div>

      <div class="col-12">
        <button (click)="add($event)" class="btn btn-sm btn-primary" type="submit"><i class="fa fa-plus-circle"></i> New</button>
      </div>

    </div>
  `
})
export class DataManagementManufacturersComponent {
  public entries: Array<DataManufacturersEntity>;
  public edit = false;

  constructor(public repo: DataManufacturersRepo, public zone: NgZone) {
    this.zone.run(
      () => {
        this.repo.getListPromise().then((u: any) => {this.entries = u;});
      }
    )
  }

  add(e) {
    e.preventDefault();
    this.entries["items"].push(this.repo.entity({id: 0, new:true}));
  }
}
