
import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from '../lacrosse.entity';
import { FirebaseAuthService }            from '../../noizu/services/firebase-auth.service';
import {ElixirEntity} from '../elixir.entity';
import {NoizuStruct} from '../../noizu/structs/noizu-struct';

export class CmsArticleInfo extends NoizuStruct{

  public vsn: any;
  public version: any;
  public type: any;
  public tags: any;
  public status: any;
  public revision: any;
  public parent: any;
  public note: any;
  public name: any;
  public description: any;
  public module: any;
  public editor: any;

  public article: any;

  public created_on: any;
  public modified_on: any;

  constructor(json) {
    super();
    if (json) {
      this.refresh(json);
    }
  } // end constructor

  refresh(data) {
    this.vsn = data["vsn"];
    this.version = data["version"];
    this.type = data["type"];
    this.tags = data["tags"];
    this.status = data["status"];
    this.revision = data["revision"];
    this.parent = data["parent"];
    this.note = data["note"];
    this.name = data["name"];
    this.description = data["description"];
    this.module = data["module"];
    this.editor = data["editor"];
    this.article = data["article"];
    this.modified_on = data["modified_on"];
    this.created_on = data["created_on"];
  }


} // end Package
