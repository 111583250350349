import {Component, Input, Inject, Output, EventEmitter} from '@angular/core';
import {NoizuStruct} from '../../../noizu/structs/noizu-struct';
import {LogicalWidgetEnum, WidgetEnum} from '../../../enums/widget';
import {LogicalWidget} from "../../../entities";
import {LogicalWidgetEventStruct} from './logical-widget-event.struct';

@Component({
  selector: 'logical-widget',
  template: `
<div *ngIf="!entity">
  <widget-spinner></widget-spinner>
</div>
<ng-container *ngIf="entity">
  <ng-container [ngSwitch]="entity.logical_widget_type()">
    <batch-logical-widget *ngSwitchCase="WidgetType.LOGICAL_WIDGET__BATCH" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></batch-logical-widget>
    <device-type-logical-widget *ngSwitchCase="WidgetType.LOGICAL_WIDGET__DEVICE_TYPE" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></device-type-logical-widget>
    <device-logical-widget *ngSwitchCase="WidgetType.LOGICAL_WIDGET__DEVICE" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></device-logical-widget>
    <manufacturer-logical-widget *ngSwitchCase="WidgetType.LOGICAL_WIDGET__MANUFACTURER" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></manufacturer-logical-widget>


    <date-time-range-picker-logical-widget *ngSwitchCase="WidgetType.LOGICAL_WIDGET__DATE_TIME_RANGE" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></date-time-range-picker-logical-widget>
    <series-logical-widget *ngSwitchCase="WidgetType.LOGICAL_WIDGET__SERIES" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></series-logical-widget>

    <weather-category-logical-widget *ngSwitchCase="WidgetType.LOGICAL_WIDGET__WEATHER_CATEGORY" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></weather-category-logical-widget>

    <firmware-logical-widget *ngSwitchCase="WidgetType.LOGICAL_WIDGET__FIRMWARE" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></firmware-logical-widget>
    <pin-group-logical-widget *ngSwitchCase="WidgetType.LOGICAL_WIDGET__PIN_GROUP" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></pin-group-logical-widget>

    <date-time-picker-logical-widget *ngSwitchCase="WidgetType.LOGICAL_WIDGET__DATE_TIME" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></date-time-picker-logical-widget>
    <alert-logical-widget *ngSwitchCase="WidgetType.LOGICAL_WIDGET__ALERT" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></alert-logical-widget>


    <extended-device-logical-widget *ngSwitchCase="WidgetType.LOGICAL_WIDGET__EXTENDED_DEVICE" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></extended-device-logical-widget>

    <!--
    <generic-logical-widget *ngSwitchCase="WidgetType.LOGICAL_WIDGET__GENERIC" [entity]="entity" [layout]="layout" [options]="options"></generic-logical-widget>
    <user-logical-widget *ngSwitchCase="WidgetType.LOGICAL_WIDGET__USER" [entity]="entity" [layout]="layout" [options]="options"></user-logical-widget>
    <gateway-logical-widget *ngSwitchCase="WidgetType.LOGICAL_WIDGET__GATEWAY" [entity]="entity" [layout]="layout" [options]="options"></gateway-logical-widget>
    <cms-logical-widget *ngSwitchCase="WidgetType.LOGICAL_WIDGET__CMS" [entity]="entity" [layout]="layout" [options]="options"></cms-logical-widget>
    <lazy-load-logical-widget *ngSwitchCase="WidgetType.LOGICAL_WIDGET__LAZY_LOAD" [entity]="entity" [layout]="layout" [options]="options"></lazy-load-logical-widget>
    <approval-logical-widget *ngSwitchCase="WidgetType.LOGICAL_WIDGET__APPROVAL" [entity]="entity" [layout]="layout" [options]="options"></approval-logical-widget>
    <firmware-logical-widget *ngSwitchCase="WidgetType.LOGICAL_WIDGET__FIRMWARE" [entity]="entity" [layout]="layout" [options]="options"></firmware-logical-widget>
    <firmware-manager-logical-widget *ngSwitchCase="WidgetType.LOGICAL_WIDGET__FIRMWARE__MANAGER" [entity]="entity" [layout]="layout" [options]="options"></firmware-manager-logical-widget>
    <mcu-logical-widget *ngSwitchCase="WidgetType.LOGICAL_WIDGET__MCU" [entity]="entity" [layout]="layout" [options]="options"></mcu-logical-widget>
    <mcu-manager-logical-widget *ngSwitchCase="WidgetType.LOGICAL_WIDGET__MCU_MANAGER" [entity]="entity" [layout]="layout" [options]="options"></mcu-manager-logical-widget>
    <notes-logical-widget *ngSwitchCase="WidgetType.LOGICAL_WIDGET__NOTES" [entity]="entity" [layout]="layout" [options]="options"></notes-logical-widget>
    <forecast-logical-widget *ngSwitchCase="WidgetType.LOGICAL_WIDGET__FORECAST" [entity]="entity" [layout]="layout" [options]="options"></forecast-logical-widget>
    -->
    <!-- Default -->
    <div class="card" *ngSwitchDefault [ngClass]="{'mb-0': options?.nested}">
      <div class="card-header" [ngClass]="{'p-2 m-0': options?.nested}">LogicalWidget: {{ WidgetType[entity.logical_widget_type()] }}</div>
      <div class="card-body">
        <pre>{{entity.toJson()| json}}</pre>
      </div>
    </div>
  </ng-container>
</ng-container>
`
})
export class LogicalWidgetComponent {
  @Input() entity: LogicalWidget | null = null;
  @Input() templateHint: string = null;
  @Input() options: any = null;
  @Input() layout = 'standard';


  @Output() widgetEvent = new EventEmitter<LogicalWidgetEventStruct>();
  forwardEvent(widgetEvent: LogicalWidgetEventStruct) {
    this.widgetEvent.emit(widgetEvent);
  }

  public  WidgetType = LogicalWidgetEnum;

  constructor() {

  }
}
