
import { Component, Inject, Input, NgZone } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location }               from '@angular/common';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { CmsDataStreamEntity } from '../../../entities/cms/';
import { CmsDataStreamRepo } from '../../../repos/cms/';

@Component({
  templateUrl: './list.component.html',
})
export class CmsDataStreamListComponent {
  public entries: Array<CmsDataStreamEntity>;
  public queue = null;
  public loading = false;
  public expand: boolean = true;
  public legend: any = {

    details: {
     enabled: false,
      selectors: false,
      priority: false,
      options: false,
    },

    tags: {
       enabled: false,
       reading: false,
       padding: false,
       progressive_padding: false,
       direction: false,
       optional_string: false,
       time_of_day: false,
       moon_phase: false,
       category: false,
       short_unit: false,
       long_unit: false,
    },
    readings: {
      enabled: false,
      diagnostic: false,
      forecast: false,
      units: false,
    }
  }

  constructor(public repo: CmsDataStreamRepo, public zone: NgZone) {
    this.zone.run(
      () => {
        this.repo.getListPromise().then((u: any) => {this.entries = u;});
      }
    )
  }



/*
    add(e) {
      e.preventDefault();
      this.entries["items"].push(this.repo.entity({id: "new"}));
    }*/
}
