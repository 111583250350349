import {ValueAndUnit} from '../../../structs/value-and-unit';
import {AlertFacadeEntity} from '../facade.entity';
import {WidgetEnum_Alert_Facade} from '../../../enums/widget/alert';
import {AlertFormatEnum, UnitEnum} from '../../../enums';
import {cloneDeep} from 'lodash';

export class AlertTemperatureFacadeEntity extends AlertFacadeEntity {
  public temperatureAbove: ValueAndUnit;
  public temperatureBelow: ValueAndUnit;
  public indoorTemperatureAbove: ValueAndUnit;
  public indoorTemperatureBelow: ValueAndUnit;

  public hasHeatIndex: boolean;
  public heatIndexAbove: ValueAndUnit;
  public hasWindChill: boolean;
  public windChillBelow: ValueAndUnit;

  public editTemperatureAbove: ValueAndUnit;
  public editTemperatureBelow: ValueAndUnit;
  public editIndoorTemperatureAbove: ValueAndUnit;
  public editIndoorTemperatureBelow: ValueAndUnit;
  public editHeatIndexAbove: ValueAndUnit;
  public editWindChillBelow: ValueAndUnit;

  public subType: AlertFormatEnum;




  public updateValues() {
    this.temperatureAbove = null;
    this.temperatureBelow = null;
    this.indoorTemperatureBelow = null;
    this.indoorTemperatureBelow = null;
    this.heatIndexAbove = null;
    this.windChillBelow = null;

    if (this.subType == AlertFormatEnum.ValueAbove) {
      this.temperatureAbove = cloneDeep(this.editTemperatureAbove);
    }
    else if (this.subType == AlertFormatEnum.ValueBelow) {
      this.temperatureBelow = cloneDeep(this.editTemperatureBelow);
    }
    else if (this.subType == AlertFormatEnum.ValueBetween || this.subType == AlertFormatEnum.ValueOutside) {
      this.temperatureAbove = cloneDeep(this.editTemperatureAbove);
      this.temperatureBelow = cloneDeep(this.editTemperatureBelow);
    }
    else if (this.subType == AlertFormatEnum.IndoorValueAbove) {
      this.indoorTemperatureBelow = cloneDeep(this.editIndoorTemperatureBelow);
    }
    else if (this.subType == AlertFormatEnum.IndoorValueBelow) {
      this.indoorTemperatureBelow = cloneDeep(this.editIndoorTemperatureBelow);
    }
    else if (this.subType == AlertFormatEnum.IndoorValueBetween || this.subType == AlertFormatEnum.IndoorValueOutside) {
      this.indoorTemperatureBelow = cloneDeep(this.editIndoorTemperatureBelow);
      this.indoorTemperatureBelow = cloneDeep(this.editIndoorTemperatureBelow);
    }

    if (this.hasWindChill) {
      this.windChillBelow = cloneDeep(this.editWindChillBelow);
    }
    if (this.hasHeatIndex) {
      this.heatIndexAbove = cloneDeep(this.editHeatIndexAbove);
    }
  }

  public constructor(alert, json) {
    super(alert, json);

    this.temperatureAbove = null;
    if (json["temperatureAbove"]) this.temperatureAbove = new ValueAndUnit(json["temperatureAbove"]);

    this.temperatureBelow = null;
    if (json["temperatureBelow"]) this.temperatureBelow = new ValueAndUnit(json["temperatureBelow"]);


    this.indoorTemperatureAbove = null;
    if (json["indoorTemperatureAbove"]) this.indoorTemperatureAbove = new ValueAndUnit(json["indoorTemperatureAbove"]);

    this.indoorTemperatureBelow = null;
    if (json["indoorTemperatureBelow"]) this.indoorTemperatureBelow = new ValueAndUnit(json["indoorTemperatureBelow"]);

    this.heatIndexAbove = null;
    if (json["heatIndexAbove"]) this.heatIndexAbove = new ValueAndUnit(json["heatIndexAbove"]);

    this.windChillBelow = null;
    if (json["windChillBelow"]) this.windChillBelow = new ValueAndUnit(json["windChillBelow"]);


    this.hasHeatIndex = this.heatIndexAbove ? true : false;
    this.hasWindChill = this.windChillBelow ? true : false;
    this.editTemperatureAbove = this.temperatureAbove ? cloneDeep(this.temperatureAbove) : new ValueAndUnit({value: 0.0, enum: UnitEnum.DegreesCelsius});
    this.editTemperatureBelow = this.temperatureBelow ? cloneDeep(this.temperatureBelow) : new ValueAndUnit({value: 0.0, enum: UnitEnum.DegreesCelsius});
    this.editIndoorTemperatureAbove = this.indoorTemperatureAbove ? cloneDeep(this.indoorTemperatureAbove) : new ValueAndUnit({value: 0.0, enum: UnitEnum.DegreesCelsius});
    this.editIndoorTemperatureAbove = this.indoorTemperatureBelow ? cloneDeep(this.indoorTemperatureBelow) : new ValueAndUnit({value: 0.0, enum: UnitEnum.DegreesCelsius});
    this.editHeatIndexAbove = this.heatIndexAbove ? cloneDeep(this.heatIndexAbove) : new ValueAndUnit({value: 0.0, enum: UnitEnum.DegreesCelsius});
    this.editWindChillBelow = this.windChillBelow ? cloneDeep(this.windChillBelow) : new ValueAndUnit({value: 0.0, enum: UnitEnum.DegreesCelsius});



    if (this.temperatureAbove && this.temperatureBelow) {
      if (this.temperatureAbove.value < this.temperatureBelow.value) this.subType = AlertFormatEnum.ValueBetween;
      else this.subType = AlertFormatEnum.ValueOutside;
    }
    else if (this.temperatureAbove) this.subType = AlertFormatEnum.ValueAbove;
    else if (this.temperatureBelow) this.subType = AlertFormatEnum.ValueBelow;
    else if (this.indoorTemperatureAbove && this.indoorTemperatureBelow) {
      if (this.indoorTemperatureAbove.value < this.indoorTemperatureBelow.value) this.subType = AlertFormatEnum.IndoorValueBetween;
      else this.subType = AlertFormatEnum.IndoorValueOutside;
    }
    else if (this.indoorTemperatureAbove) this.subType = AlertFormatEnum.IndoorValueAbove;
    else if (this.indoorTemperatureBelow) this.subType = AlertFormatEnum.IndoorValueBelow;
  }


  //-----------------------------------
  //
  //-----------------------------------
  public get template(): string | null {
    return 'temperature';
  }


  alert_facade_widget() {
    return WidgetEnum_Alert_Facade.ALERT_FACADE_WIDGET__TEMPERATURE;
  }
}
