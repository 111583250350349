import {Component, Input, Inject, Output, EventEmitter} from '@angular/core';
import {LacrosseEntity} from "../../../entities/lacrosse.entity";
import {NoizuStruct} from "../../../noizu/structs/noizu-struct";
import {AlertStatusFacade, AlertTriggerStateObject, CallbackObject, SubscriberObject} from '../../../entities/alert';
import {WidgetBase} from '../widget-base';
import {AlertWidgetEventStruct} from './alert-widget-event.struct';

/*


  public constructor(alert, json) {
    super();
    this.alert = alert;
    this.settings = json.settings;
    this.globals = json.globals;
    this.device_cache = json.device_cahe;
    this.constraints = new AlertConstraintStatusSet(alert, json.constraints);
  }

 */

@Component({
  selector: 'alert-status-facade-widget',
  template: `
        <div class="alert-status-facade-widget card ">
          <div class="card-header">
            <div class="row">
              <div class="col"><b class="text-left">Generic Facade Status</b></div>
              <div class="col-2 text-right p-0 text-middle">
                <toggle-widget [options]="expand_settings"></toggle-widget>
              </div>
            </div>
          </div>

          <div class="card-body"  [ngClass]="{'d-none': !expand_settings.toggle}" >
            <div class="row">
              <div class="col-2"><b>Settings</b></div>
              <div class="col">
                <pre>{{entity?.settings|json}}</pre>
              </div>
            </div>

            <hr/>
            <div class="row">
              <div class="col-2"><b>Globals</b></div>
              <div class="col">
                <pre>{{entity?.globals|json}}</pre>
              </div>
            </div>

            <hr *ngIf="entity?.device_cache"/>
            <div class="row" *ngIf="entity?.device_cache">
              <div class="col-2"><b>Device Cache</b></div>
              <div class="col">
                <pre>{{entity?.device_cache|json}}</pre>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <alert-constraint-status-set-widget [entity]="entity.constraints" [layout]="layout" [options]="options"></alert-constraint-status-set-widget>
              </div>
            </div>
          </div>
        </div>
    `
})
export class AlertStatusFacadeWidgetComponent extends WidgetBase{
  @Input() entity: AlertStatusFacade = null;
  @Input() options: any = null;
  @Input() layout: string = null;
  @Output() widgetEvent = new EventEmitter<AlertWidgetEventStruct>();
  forwardEvent(e, widgetEvent: AlertWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }
  constructor() {
    super();
  }
}
