import { NoizuStruct }        from '../../../noizu/structs/noizu-struct';
import { FormulaTreeNodeContentType }      from './content/type';
import { FormulaTreeNodeContentProcessedField } from './content/processed-field';
import { ValueAndUnit } from '../../value-and-unit';
export class FormulaTreeNodeContent extends NoizuStruct {

  public type: string;
  public ufType: FormulaTreeNodeContentType;
  public scalarValue: any; // ValueAndUnit
  public ufScalar_value: any; // ValueAndUnit
  public rawValue: boolean; // Boolean
  public inputValue: FormulaTreeNodeContentProcessedField; // ProcessedField
  public previous_value_identifier: string; //

  constructor(json: any) {
      super();
      this.strip_from_json["ufType"] = 1;
      this.strip_from_json["ufScalar_value"] = 1;
      this.ufType = new FormulaTreeNodeContentType(json["type"]);
      this.type = json["type"];
      let sc = json["scalarValue"] || json["scalar_value"];
      let iv = json["inputValue"] || json["input_value"];

      this.scalarValue = sc ? sc : null;
      this.ufScalar_value = sc ? new ValueAndUnit(sc) : null;
      this.rawValue = json["rawValue"] || json["raw_value"];
      this.inputValue = iv ? new FormulaTreeNodeContentProcessedField(iv) : null;
      this.previous_value_identifier =  json["previousValueIdentifier"] || json["previous_value_identifier"];
  } // end constructor
}

/*
public class TransformationNodeContent {

    public Long type; // 1 - scalar, 2 - raw, 3 - inputValue - 4 previous value;

    // Constant value. Use no_unit type for scaler with out units.
    public ValueAndUnitEntity scalarValue;

    // Set if this is a transformation for a raw input. Not for json input these are not executed.
    // Not SensorField tracks if this is a raw or not raw input field (for tracking which ones need to be tracked).
    public Boolean rawValue;

    // Pull ValueAndUnit from other field in sensor payload.  In SensorType we associate a set of fields with specific fields. The index in that list is the inputIdentifier listed here.
    public ProcessedField inputValue;

    public String previousValueIdentifier;

    public static class ProcessedField {

        public String nodeIdentifier;
        public Long aggregationLevel; // Tick, 1m, 5m, 15m, 30m, 60m, 24, 1 day, etc.  [required if aggregate]
        public Long aggregationCount; // 1,2,3 ... {:ticks, 1} == spot
        public Long aggregationType; // Low, High, Average, Tally  [ required if aggregate]
        public Long asUnit; // Cast to value. [optional]
    }
}

*/
