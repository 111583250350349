
import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from '../lacrosse.entity';
import { FirebaseAuthService }            from '../../noizu/services/firebase-auth.service';
//import { DeviceDefinitionRepo }           from '../../repos/device/definition.repo';

export class FirmwareLinkEntity extends LacrosseEntity {
  public _kind = "firmware-links";
  public _singular = "firmware-link";
  public firmwareDefinitionId: String;
  public series: String;
  public serial: String;
  public manufacturerId: String;
  public batchId: String;
  public name: String;
  public notes: String;

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    if (json) {
      this.refresh(json);
    }
  } // end constructor
  refresh(data) {
    this.firmwareDefinitionId = data.firmwareDefinitionId;
    this.series = data.series;
    this.serial = data.serial;
    this.manufacturerId = data.manufacturerId;
    this.batchId = data.batchId;
    this.name = data.name;
    this.notes = data.notes;
    return super.refresh(data);
  }
  // Sets the fields, used above, and to reset the values when canceling an edit


} // end Package
