
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { FirebaseAuthService }        from '../noizu/services/firebase-auth.service';
import { DomainObject }        from '../noizu/domain-object';
import { ApiService } from '../services/api.service';

export class DeviceSimulatorEntity extends DomainObject {
  public api;

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    this.api = new ApiService(client);
  } // end constructor

//A fake simulation using admin API's (simpler and more practical)
  sendSimDataAdmin(id, data, gateway = "280001", onDisplay = false) {
    let payload = {"DisplaySerial":gateway,"Data":id+"00"+data,"GatewaySerial":gateway,"OnDisplay":onDisplay,"AutoSign":true,"Firmware":"2.1.16"};
    return this._post(
      `${this.ingvEndpoint()}/admin-tools/report/binary`,
      payload,
      (data, resolve) => {resolve(data);},
      {}
    );
  }

//Simulate device as best as possible, requires Special validation done on Node server
  sendSimData(payload) {
    return new  Promise(
      (resolve, reject) => {this.api.reportBinary(payload).then ( (res) => {
      console.log(res);
      resolve(res);
    })});

  }

//Get the gateway record to obtain the PSK if possible.
  getgateway(series,serial) {
    console.log(this.apiBase(), series, serial);
    return this._get(
      `${this.apiBase()}/gatewayBySerial/${series}/${serial}`,
      (data, resolve) => {resolve(data);},
      {}
    )
  }

} // end Package
