import {Component, Input, Inject, OnInit} from '@angular/core';
import {FirmwareLinkV2Entity} from "../../../entities/firmware/link-v2.entity";
import {MCUFirmwareDefinitionEntity, MCUFirmwareLinkEntity} from '../../../entities/mcu/firmware';
import {MCUFirmwareDefinitionRepo} from '../../../repos/mcu/firmware';
import {BatchRepo, DeviceDefinitionRepo, UserRepo} from '../../../repos';
import {Observable, Observer} from 'rxjs';
import {AppengineEntityList} from '../../../noizu/structs/appengine-entity-list';
import {BatchEntity, DeviceDefinitionEntity} from '../../../entities';
import {TargetStruct} from '../../../entities/firmware/target.struct';

@Component({
  selector: 'embed-firmware-target',
  template: `
  <firmware-target-widget [entity]="entity" [options]="options" [layout]="layout"></firmware-target-widget>
    `
})
export class EmbedFirmwareTarget implements OnInit {
  @Input() entity: TargetStruct = null;
  @Input() options: any = null;
  @Input() layout: string = null;

  public criteria_types = [];

  //-----------------------------
  //
  //-----------------------------
  constructor() {

  }


  //-----------------------------
  //
  //-----------------------------
  ngOnInit(): void {

  }


}
