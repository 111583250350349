import { Component, Inject, Input, NgZone } from '@angular/core';
import { FirmwareDefinitionRepo } from '../../../repos/firmware/definition.repo';
import {FirmwareReleaseRepo} from '../../../repos/firmware/release.repo';
import {CacheService} from '../../../services/cache.service';

@Component({
  templateUrl: './overview.component.html',
})
export class MCUFirmwareOverviewComponent {
  public entries: any; //Map<String, Array<FirmwareDefinitionEntity>>;
  public selectedEntry: any;

  public firmwareRollUpLabels: string[] = ['Querying'];
  public firmwareRollUpData: number[] = [1];
  public firmwareRollUp = [];

  public activeFirmwareRollUpByBatchLabels: string[] = ['Querying'];
  public activeFirmwareRollUpByBatchData: number[] = [1];

  public firmwareRollUpByBatch = {};
  public availableBatches = [{value: '1', label: 'loading'}];
  public activeBatch = "1";

  events: Event[] = [];

  constructor(public cache: CacheService, public firmwareDefinitionRepo: FirmwareDefinitionRepo, public releaseRepo: FirmwareReleaseRepo, public zone: NgZone) {
    this.zone.run(
      () => {

        releaseRepo.getReportPromise().then((report: any) => {

          console.log(report);
          if (report["outcome"]) {

            this.firmwareRollUpLabels = [];
            this.firmwareRollUpData = [];
            this.firmwareRollUp = [];
            for (let key in report.report.rollup) {
              this.firmwareRollUpLabels.push(key);
              this.firmwareRollUpData.push(report.report.rollup[key]);
              this.firmwareRollUp.push({firmware: key, count: report.report.rollup[key]});
            }



            this.firmwareRollUpByBatch = {};
            this.availableBatches = [];
            for (let batch in report.report.by_batch) {
              batch = `${batch}`;
              this.availableBatches.push( {value: batch, label: `Batch ${batch}`});
              this.firmwareRollUpByBatch[batch] = {labels: [], data: []};
              for (let firmware in report.report.by_batch[batch].rollup) {
                this.firmwareRollUpByBatch[batch].labels.push(firmware);
                this.firmwareRollUpByBatch[batch].data.push(report.report.by_batch[batch].rollup[firmware]);
              }
            }

            this.activeFirmwareRollUpByBatchLabels = this.firmwareRollUpByBatch[this.activeBatch].labels;
            this.activeFirmwareRollUpByBatchData = this.firmwareRollUpByBatch[this.activeBatch].data;
          }



        });

      }
    )
  }


  // events
  public chartClicked(chart: any, e: any): void {
    console.log(e);
  }

  public chartHovered(chart: any, e: any): void {
    console.log(e);
  }



  onChange($event) {
    this.activeFirmwareRollUpByBatchLabels = this.firmwareRollUpByBatch[this.activeBatch].labels;
    this.activeFirmwareRollUpByBatchData = this.firmwareRollUpByBatch[this.activeBatch].data;
  }


  ngOnInit() {

  }


}
