import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from '../lacrosse.entity';
import { FirebaseAuthService }            from '../../noizu/services/firebase-auth.service';
import { DeviceDefinitionRepo }           from '../../repos/device/definition.repo';
import { DeviceDefinitionCategory }       from '../../structs/device/definition/category';
import { DeviceDefinitionFieldEntry }     from '../../structs/device/definition/field-entry';
import {GatewayCycleOverrideEntity} from './cycle-override.entity';
import {GatewayScriptOverrideEntity} from './script-override.entity';

export class GatewayFirmwareReportEntity extends LacrosseEntity {
  public _kind = "firmware-report";
  public _singular = "firmware-report";

  public gateway: any = null;
  public gateway_entity: any = null;
  public last_reported: any = null;
  public assigned: any = null;
  public pending: any = null; // Will be Entity
  public firmware_links: any = null; // Will be Entity
  public cycle_override: GatewayCycleOverrideEntity = null; // Will be Entity
  public script_override: GatewayScriptOverrideEntity = null; // Will be Entity
  public report: any = null; // Will be Entity
  public history: any = null; // Will be Entity
  public worker_state: any = null;
  public roll_out: any = null;

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    this.refresh_content(json);
  } // end constructor


  refresh_content(json) {

    this.gateway = json['gateway'];
    this.gateway_entity = json['gateway_entity'];


    try {
      if (!this.gateway_entity["series"] && this.gateway_entity["identifier"]) {
        if (this.gateway_entity["identifier"]["tuple"] && this.gateway_entity["identifier"]["tuple"][0]) {
          this.gateway_entity["series"] = this.gateway_entity["identifier"]["tuple"][0];
          if (this.gateway_entity["series"]) this.gateway_entity["series"] = this.gateway_entity["series"].toUpperCase();
        }
      }
      if (!this.gateway_entity["serial"] && this.gateway_entity["identifier"]) {
        if (this.gateway_entity["identifier"]["tuple"] && this.gateway_entity["identifier"]["tuple"][1]) {
          this.gateway_entity["serial"] = this.gateway_entity["identifier"]["tuple"][1];
          // Just going to assume is_number ;)
          if (this.gateway_entity["serial"]) {
            let c = this.gateway_entity["serial"];
            this.gateway_entity["serial"] = c.toString(16).padStart(6, '0').toUpperCase();
          }

        }
      }
    } catch(e) {
      console.log("ISSUE REPAIRING SERIES,SERIAL", e);
    }


    this.last_reported = json.last_reported;
    this.assigned = json.assigned;
    this.pending = json.pending;
    this.firmware_links = json['links'];
    if (json['cycle_override']) {
      this.cycle_override = new GatewayCycleOverrideEntity(json['cycle_override']);
    } else {
      this.cycle_override = null;
    }

    // has_overide typo work around may be removed after August 2019
    if (json['script_override'] && (json['script_override']['has_overide'] || json['script_override']['has_override'])) {
      this.script_override = new GatewayScriptOverrideEntity(json['script_override']);
    } else {
      this.script_override = null;
    }
    this.report = json.report;
    this.history = json.history;
    this.worker_state = json['worker_firmware_details'];
    this.roll_out = json['roll_out'];
  }

  forceMcuRefresh() {
    const url = `${this.ingvEndpoint()}/admin-tools/gateway/${this.gateway}/mcu-info/force-check`;
    return this._put(url, {}, (json, resolve) => {
      this.refresh_content(json);
      resolve(this);
    });
  }

  forceRefresh() {
    const url = `${this.ingvEndpoint()}/admin-tools/gateway/${this.gateway}/firmware-info/force-check`;
    return this._put(url, {}, (json, resolve) => {
      this.refresh_content(json);
      resolve(this);
    });
  }


  setCycle(update) {
    const url = `${this.ingvEndpoint()}/admin-tools/gateway/${this.gateway}/cycle`;
    return this._post(
      url,
      update,
      (data, resolve) => {
        // todo parse response
        this.cycle_override = update;
        resolve(data);
        },
      {}
    );
  }

  clearCycle() {
    const url = `${this.ingvEndpoint()}/admin-tools/gateway/${this.gateway}/cycle`;
    return this._delete(
      url,
      (data, resolve) => {
        // todo parse response
        this.cycle_override = null;
        resolve(data);
      },
      {}
    );
  }

  setScript(update) {
    const url = `${this.ingvEndpoint()}/admin-tools/gateway/${this.gateway}/ota-sequence`;
    return this._post(
      url,
      update,
      (data, resolve) => {
        // todo parse response
        this.script_override = update;
        resolve(data);
      },
      {}
    );
  }

  clearScript() {
    const url = `${this.ingvEndpoint()}/admin-tools/gateway/${this.gateway}/ota-sequence`;
    return this._delete(
      url,
      (data, resolve) => {
        // todo parse response
        this.script_override = null;
        resolve(data);
      },
      {}
    );
  }

  setBatch(batch) {
    const url = `${this.ingvEndpoint()}/admin-tools/gateway/${this.gateway}/batch/${batch.batch}`;
    return this._put(
      url,
      {},
      (data, resolve) => {
        // Tempory Patch Force Kill Worker
        const kill_worker = `${this.ingvEndpoint()}/admin-tools/worker/ref.gateway-worker.${this.gateway_entity.serial}@${this.gateway_entity.series}/kill`;
        this._get(kill_worker, () => {});
        // todo parse response
        resolve(data);
      },
      {}
    );
  }


  targetStage() {
    const url = `${this.ingvEndpoint()}/admin-tools/gateway/${this.gateway}/target-stage`;
    return this._put(
      url,
      {"value": "2h"},
      (data, resolve) => {
        // todo parse response
        resolve(data);
      },
      {}
    );
  }

  targetProd() {
    const url = `${this.ingvEndpoint()}/admin-tools/gateway/${this.gateway}/target-stage`;
    return this._delete(
      url,
      (data, resolve) => {
        // todo parse response
        resolve(data);
      },
      {}
    );
  }


} // end Package
