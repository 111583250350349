import {
  LogicalDeviceWidgetEnum, LogicalWidgetEnum,
} from '../../enums/widget';
import {LogicalWidget} from '../logical-widget';
import {DeviceEntity} from '../device.entity';

export class DeviceSet extends LogicalWidget {
  constructor(public set: Array<DeviceEntity>) {
    super();
  } // end constructor

  logical_widget_type(): LogicalWidgetEnum | null {
    return LogicalWidgetEnum.LOGICAL_WIDGET__DEVICE;
  }

  logical_device_widget_type(): LogicalDeviceWidgetEnum | number | string | null {
    return LogicalDeviceWidgetEnum.LOGICAL_DEVICE_WIDGET__GROUP;
  }
} // end Package
