import {FirmwareLogicalWidget} from '../firmware-logical-entity';
import {FirmwareLogicalWidgetEnum} from '../../../enums';

export class FirmwareTargetSetLogicalWidget extends FirmwareLogicalWidget{
  public selected: any = null;


  firmware_logical_widget_type(): FirmwareLogicalWidgetEnum | null {
    return FirmwareLogicalWidgetEnum.FIRMWARE_LOGICAL_WIDGET__TARGET_SET
  }

  constructor() {
    super();
  } // end constructor
} // end Package
