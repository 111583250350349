import {Component} from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule }     from '@angular/core';
import { AppComponent } from '../../app.component';
import { Router, ActivatedRoute, Params}       from '@angular/router';

@Component({
  selector: 'page-not-found',
  template: `
  <div class="app flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card-group mb-0">
          <div class="card p-4">
            <div class="card-block">
              <h1>Page Not Found</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
`
})

export class PageNotFoundComponent {
  constructor(public route: Router){
    console.log(this.route);
  }
}
