import {HttpClient} from '@angular/common/http';
import {FirebaseAuthService} from '../../noizu/services/firebase-auth.service';
import {ElixirEntity} from '../elixir.entity';
import {EmailWidgetEnum, WidgetEnum} from '../../enums';

//import { DeviceDefinitionRepo }           from '../../repos/device/definition.repo';


export class EmailEntity extends ElixirEntity {
  public _kind = "null";
  public _singular = "null";
  public raw:any;

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
  } // end constructor

  widget_type() {
    return WidgetEnum.EMBED_WIDGET__EMAIL;
  }

  email_widget_type(): EmailWidgetEnum| null {
    return EmailWidgetEnum.EMAIL_WIDGET__GENERIC;
  }

} // end Package
