import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity } from '../lacrosse.entity';
import { FirebaseAuthService } from '../../noizu/services/firebase-auth.service';

export class RequestLogEntity extends LacrosseEntity {
  public span: any = null;
  public response_code: any = null;
  public request_token: any = null;
  public request_category: any = null;
  public time: any = null;
  public node: any = null;
  public gateway: any = null;
  public device: any = null;
  public content: any = null;
  public api_endpoint: any = null;

  public meta: any = null;
  public _kind = 'request-logs';
  public _singular = 'request-log';

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    this.span = json.span;
    this.response_code = json.response_code;
    this.request_token = json.request_token;
    this.request_category = json.request_category;
    this.time = json.recorded_on && new Date(json.recorded_on);
    this.node = json.node;
    this.gateway = json.gateway_serial_series;
    this.device = json.device_serial_series;
    try {
      this.content = json.content && JSON.parse(json.content);
    } catch (e) {
      this.content = {"parse_error": json.content};
    }
    console.log("ENTRY", this.content);
    this.api_endpoint = json.api_endpoint;
    this.meta = json.meta;
  } // end constructor

  public filter(filter) {
    if (filter == "") return true;
    if (this.meta['filter'] == filter) return this.meta['filter_result'];
    this.meta['filter'] = filter;
    this.meta['filter_result'] = false;
    console.log(this.request_category);
    if (this.request_category && this.request_category.includes(filter)) this.meta['filter_result'] = true;
    else if (this.request_token && this.request_token.includes(filter)) this.meta['filter_result'] = true;
    else if (JSON.stringify(this.toJson()).includes(filter)) this.meta['filter_result'] = true;
    return this.meta['request_type'];
  }
} // end Package
