import {ValueAndUnit} from '../../../structs/value-and-unit';
import {AlertFacadeEntity} from '../facade.entity';
import {WidgetEnum_Alert_Facade} from '../../../enums/widget/alert';

export class AlertWindFacadeEntity extends AlertFacadeEntity {
  public windAbove: ValueAndUnit;

  public windLevel: any; // enum

  public headingOne: any;
  public headingTwo: any;
  public headingThree: any;
  public headingFour: any;
  public headingFive: any;
  public headingSix: any;
  public headingSeven: any;
  public headingEight: any;

  public constructor(alert, json) {
    super(alert, json);
    this.windAbove = json["windAbove"] ? new ValueAndUnit(json["windAbove"]) : null;
    this.windLevel = json.windLevel || null;
    this.headingOne = !!json.headingOne;
    this.headingTwo = !!json.headingTwo;
    this.headingThree = !!json.headingThree;
    this.headingFour = !!json.headingFour;
    this.headingFive = !!json.headingFive;
    this.headingSix = !!json.headingSix;
    this.headingSeven = !!json.headingSeven;
    this.headingEight = !!json.headingEight;
  }

  //-----------------------------------
  //
  //-----------------------------------
  public get template(): string | null {
    return 'wind';
  }

  alert_facade_widget() {
    return WidgetEnum_Alert_Facade.ALERT_FACADE_WIDGET__WIND;
  }
}
