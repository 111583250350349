import {LogicalWidgetEnum,} from "../../enums/widget";
import {LogicalWidget} from '../logical-widget';

export class ChannelLogicalWidget extends LogicalWidget{
  public selected: any = null;
  public channelType: any = null;
  public user: any = null;

  logical_widget_type(): LogicalWidgetEnum | null {
    return LogicalWidgetEnum.LOGICAL_WIDGET__CHANNEL;
  }

  constructor() {
    super();
  } // end constructor
} // end Package

