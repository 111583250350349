import {ValueAndUnit} from '../../../structs/value-and-unit';
import {AlertFacadeEntity} from '../facade.entity';
import {WidgetEnum_Alert_Facade} from '../../../enums/widget/alert';
import {cloneDeep} from 'lodash';
import {AlertFormatEnum, UnitEnum} from '../../../enums';

export class AlertProbeFacadeEntity extends AlertFacadeEntity {
  public temperatureAbove: ValueAndUnit;
  public temperatureBelow: ValueAndUnit;
  public editTemperatureAbove: ValueAndUnit;
  public editTemperatureBelow: ValueAndUnit;
  public field: any;

  public constructor(alert, json) {
    super(alert, json);

    this.temperatureAbove = null;
    if (json["temperatureAbove"]) {
      this.temperatureAbove = new ValueAndUnit(json["temperatureAbove"]);
    }

    this.temperatureBelow = null;
    if (json["temperatureBelow"]) {
      this.temperatureBelow = new ValueAndUnit(json["temperatureBelow"]);
    }

    this.field = json.field;


    this.editTemperatureAbove = this.temperatureAbove ? cloneDeep(this.temperatureAbove) : new ValueAndUnit({value: 0.0, enum: UnitEnum.DegreesCelsius});
    this.editTemperatureBelow = this.temperatureBelow ? cloneDeep(this.temperatureBelow) : new ValueAndUnit({value: 0.0, enum: UnitEnum.DegreesCelsius});
    if (this.temperatureAbove) this.subType = AlertFormatEnum.ValueAbove;
    else if (this.temperatureBelow) this.subType = AlertFormatEnum.ValueBelow;

  }


  public updateValues() {
    this.temperatureAbove = null;
    this.temperatureBelow = null;

    if (this.subType == AlertFormatEnum.ValueAbove) {
      this.temperatureAbove = cloneDeep(this.editTemperatureAbove);
    }
    else if (this.subType == AlertFormatEnum.ValueBelow) {
      this.temperatureBelow = cloneDeep(this.editTemperatureBelow);
    }
  }


  //-----------------------------------
  //
  //-----------------------------------
  public get template(): string | null {
    return 'probe';
  }

  alert_facade_widget() {
    return WidgetEnum_Alert_Facade.ALERT_FACADE_WIDGET__PROBE;
  }
}
