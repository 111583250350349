<form action="" class="form-horizontal">
  <div class="col" *ngIf="!deleted">

    <div class="card" style="width: 18rem;" *ngIf="!(entry.new==true || editEntry==true)">
      <div class="card-header bg-info">{{entry.name}}</div>
      <div class="card-body bg-light ">
        <p class="card-text">{{entry.identifier}}</p>
        <p class="card-text">Created On: {{entry.createdOn}}</p>
        <p class="card-text">Modified On:{{entry.modifiedOn}}</p>
      </div>
      <div class="card-footer">
        <button (click)="edit($event, entry)" class="btn btn-sm btn-primary" type="submit"><i class="fa fa-dot-circle-o"></i> Edit</button>
      </div>
    </div>

    <div *ngIf="entry.new==true || editEntry==true" class="card">

      <div class="card-header bg-info border-info">Manufacturer Entry</div>
      <div class="card-body bg-light border-info">
        <div class="form-group row">
          <label class="col-md-2 form-control-label" for="{{entry.identifier}}-name">Id</label>
          <div class="col-md-10">
            <input class="form-control" required [(ngModel)]="entry.identifier" value="{{entry.identifier}}" id="identifier" name="identifier" placeholder="Manufacturer Id" type="text">
            <span class="help-block">Manufacturer Id</span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 form-control-label" for="{{entry.identifier}}-name">Name</label>
          <div class="col-md-10">
            <input class="form-control" required [(ngModel)]="entry.name" value="{{entry.name}}" id="manufacturer-name" name="manufacturer-name" placeholder="Manufacturer Name" type="text">
            <span class="help-block">Manufacturers Name</span>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button (click)="cancel($event)" class="btn btn-sm btn-primary" type="submit"><i class="fa fa-dot-circle-o"></i> Cancel</button>
        <button (click)="update($event, entry)" class="btn btn-sm btn-primary float-right" type="submit"><i class="fa fa-dot-circle-o"></i> Add/Update</button>
        <button (click)="delete($event, entry)" class="btn btn-sm btn-warning" type="submit"><i class="fa fa-minus-circle"></i> Delete</button>
      </div>

    </div>
  </div>
</form>
