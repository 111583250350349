import {Component, Input, Output, EventEmitter} from '@angular/core';
import {EntityLogEntity} from '../../../../entities/log/entity-log.entity';
import {WidgetBase} from '../../widget-base';
import {LogWidgetEventStruct} from '../log-widget-event.struct';
import {LogWidgetEnum} from '../../../../enums';
import {LogWidgetComponent} from '../log-widget.component';

@Component({
  selector: 'log-table-header-widget',
  template: `
    <div class="log-table-header-widget div-table-th" *ngIf="entity.options['index'] == 0 && entity.options['header']">
      <div class="row" >
        <ng-container *ngFor="let column of entity.options.columns">
          <ng-container [ngSwitch]="column">
            <div class="col-2" *ngSwitchCase="'time'">Time</div>
            <div class="col-1" *ngSwitchCase="'level'">Level</div>
            <div class="col-2" *ngSwitchCase="'topic'">Topic</div>
            <div class="col-1" *ngSwitchCase="'type'">Type</div>
            <div class="col-2" *ngSwitchCase="'subject'">Subject</div>
            <div class="col-3" *ngSwitchCase="'brief'">Brief</div>
            <div class="col-1" *ngSwitchCase="'actions'"></div>
          </ng-container>
        </ng-container>
      </div>
    </div>
    `
})
export class LogTableHeaderWidgetComponent extends WidgetBase{
  @Input() entity: LogWidgetComponent = null;
  @Input() options: any = {};
  @Input() layout: string = null;
  constructor() {
    super();
  }
}
