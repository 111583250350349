import {Component, Input, Inject, Output, EventEmitter} from '@angular/core';
import {LacrosseEntity} from "../../../entities/lacrosse.entity";
import {NoizuStruct} from "../../../noizu/structs/noizu-struct";
import {CallbackObject, CallbackObjectSet, SubscriberObject, SubscriberObjectSet} from '../../../entities/alert';
import {WidgetBase} from '../widget-base';
import {CallbackWidgetEnum} from '../../../enums';
import {FirmwareWidgetEventStruct} from '../firmware';
import {CallbackWidgetEventStruct} from './callback-widget-event.struct';

@Component({
  selector: 'callback-object-set-widget',
  template: `<set-widget [entity]="this.entity" [layout]="this.layout" [options]="options" [title]="'Incoming Callbacks'"></set-widget>
  `
})
export class CallbackObjectSetWidgetComponent extends WidgetBase{
  @Input() entity: CallbackObjectSet = null;
  @Input() options: any = null;
  @Input() layout: string = null;
  @Output() widgetEvent = new EventEmitter<CallbackWidgetEventStruct>();
  forwardEvent(e, widgetEvent: CallbackWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }


  has_entries = false;
  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.has_entries = !(this.entity.entities == []);
  }
}
