
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location }               from '@angular/common';
import { GroupEntity } from '../../entities/group.entity';
import { GroupRepo } from '../../repos/group.repo';

@Component({
  templateUrl: './show.component.html'
})
export class GroupShowComponent implements OnInit {
  group: GroupEntity = null;
  public subscription:any;
  public backUserID;
  public permissionsInit = {};
  constructor(
    public repo: GroupRepo,
    public router: Router,
    public activatedRoute: ActivatedRoute) {
  }

  back(event) {
    event.preventDefault();
    this.router.navigateByUrl(`/portal/groups/list`);
  }
  backUser(event) {
    event.preventDefault();
    this.router.navigateByUrl(`/portal/users/show/${this.backUserID}`);
  }

  ngOnInit(): void {
    this.subscription = this.activatedRoute.params.subscribe(
      (params: any) => {
        this.group = null;
        if ("id" in params) {
          this.repo.getSingularPromise(params.id).then(
            (group:any) => {
              this.group = group[0];
            }
          ).catch((error:any) =>{
            console.log(error);
          });
          this.permissionsInit = {init:true,type:"g",id:params.id};
        }
        if ("uid" in params) {
          this.backUserID = params.uid;
        }
      }
    );
  }
}
