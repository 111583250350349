import {WidgetEventStruct} from '../../widget-event.struct';
import {ModalWidgetEnum, UserWidgetEnum, WidgetEnum} from '../../../enums';


export class EntitySetWidgetEventStruct extends WidgetEventStruct {
  constructor(event_type, event_body = null, event_widget_type = null) {
    super();
    this.event_type = event_type;
    this.event_body = event_body;
    this.event_widget_type = WidgetEnum.EMBED_WIDGET__GENERIC_SET;
  }

} // end Package
