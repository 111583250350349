import {NoizuStruct} from '../../noizu/structs/noizu-struct';
import {AlertWidgetEnum, CallbackWidgetEnum, WidgetEnum, WidgetEnum_Alert_Facade, WidgetEnum_Alert_Status} from '../../enums';
import {ValueAndUnit} from '../../structs/value-and-unit';
import {BaseEntitySet} from '../base.entity-set';





export class AlertTriggerEvent extends NoizuStruct {
  public alert:any = null;
  public identifier: any = null;
  public inner:any = {};


  public constructor(alert, json) {
    super();
    this.identifier = json.identifier;
    this.alert = alert;
    this.inner = json;
  }

  widget_type() {
    return WidgetEnum.EMBED_WIDGET__ALERT_TRIGGER_EVENT;
  }
  alert_widget_type() {
    return AlertWidgetEnum.ALERT_WIDGET__ALERT_TRIGGER_EVENT;
  }

}



export class AlertTriggerEventSet extends BaseEntitySet {
  public handle: any;
  public alert: any;

  public entities: any = [];
  public constructor(handle, alert, json) {
    super();

    this.alert = alert;
    this.handle = handle;
    if (json && Array.isArray(json)) {
      let o = [];
      json.forEach((v) => {
        o.push(new AlertTriggerEvent(alert, v));
      });
      this.entities = o;
    }
  }

  widget_type() {
    return WidgetEnum.EMBED_WIDGET__ALERT_TRIGGER_EVENT_SET;
  }
  alert_widget_type() {
    return AlertWidgetEnum.ALERT_WIDGET__ALERT_TRIGGER_EVENT_SET;
  }

}



export class AlertConstraintTriggerState extends NoizuStruct {
  public handle: any;
  public alert: any;
  public triggered = false;
  public condition_met;
  public ticks;
  public reading;
  public event_start;
  public event_end;

  public inner;


  public constructor(handle, alert, json) {
    super();

    this.handle = handle;

    this.alert = json['alert'];
    this.triggered = json['triggered?'];
    this.condition_met = json['condition_met?'];
    this.ticks = json['ticks'];



    if (json['reading']) {


      this.reading = new ValueAndUnit(json['reading']);
    } else this.reading = null;

    this.event_start = json['event_start'];
    this.event_end = json['event_end'];

this.inner = json;
  }


  widget_type() {
    return WidgetEnum.EMBED_WIDGET__ALERT_CONSTRAINT_TRIGGER_STATE;
  }

  alert_widget_type() {
    return AlertWidgetEnum.ALERT_WIDGET__ALERT_CONSTRAINT_TRIGGER_STATE;
  }

}

export class AlertConstraintStatus extends NoizuStruct {
  public handle:any = null;
  public alert:any = null;
  public last_reported_value: ValueAndUnit = null;
  public constraint_met = false;
  public last_update: any = null;
  public triggered = false;
  public trigger_state:any = null;
  public trigger_events:any = null;
  public inner:any = {};

  public constructor(handle, alert, json) {
    super();
    this.alert = alert;
    this.handle = handle;
    this.last_reported_value = new ValueAndUnit(json['trigger_state']['last_reported_value']);
    this.constraint_met = json['trigger_state']['constraint_met'];
    this.last_update = json['trigger_state']['last_update'];
    this.triggered = json['trigger_state']['triggered'];
    this.trigger_state = new AlertConstraintTriggerState(handle, alert, json['trigger_state']['trigger_state']);
    this.trigger_events = new AlertTriggerEventSet(handle, alert, json['trigger_state']['trigger_events']);
  }

  widget_type() {
    return WidgetEnum.EMBED_WIDGET__ALERT_CONSTRAINT_STATUS;
  }

  alert_widget_type() {
    return AlertWidgetEnum.ALERT_WIDGET__ALERT_CONSTRAINT_STATUS;
  }
}

export class AlertConstraintStatusSet extends BaseEntitySet {
  public alert:any = null;
  public entities:any = {};

  public constructor(alert, json) {
    super();
    if (json) {
      this.alert = alert;
      this.entities = {};
      if (json && (typeof json === 'object')) {
        Object.keys(json).forEach(key => {
          this.entities[key] = new AlertConstraintStatus(key, alert, json[key]);
        });
      }
    }
  }

  widget_type() {
    return WidgetEnum.EMBED_WIDGET__ALERT_CONSTRAINT_STATUS_SET;
  }

  alert_widget_type() {
    return AlertWidgetEnum.ALERT_WIDGET__ALERT_CONSTRAINT_STATUS_SET;
  }
}



export class AlertStatusFacade extends NoizuStruct {
  public alert: any = null;
  public settings: any = {};
  public globals: any = {};
  public device_cache: any = {};
  public constraints: AlertConstraintStatusSet = null;


  public constructor(alert, json) {
    super();
    if (json) {
      this.alert = alert;
      this.settings = json.settings;
      this.globals = json.globals;
      this.device_cache = json.device_cache;
      this.constraints = new AlertConstraintStatusSet(alert, json.constraints);
    }
  }

  widget_type() {
    return WidgetEnum.EMBED_WIDGET__ALERT_STATUS_FACADE;
  }

  alert_widget_type() {
    return AlertWidgetEnum.ALERT_WIDGET__ALERT_STATUS_FACADE;
  }
}




export class CallbackObjectSet extends BaseEntitySet {
  public entities: any = {};

  public constructor(json) {
    super();
    this.entities = {};
    if (json && (typeof json === 'object')) {
      Object.keys(json).forEach(key => {
        this.entities[key] = new CallbackObject(key, json[key]);
      });
    }
  }

  widget_type() {
    return WidgetEnum.EMBED_WIDGET__CALLBACK_OBJECT_SET;
  }

  callback_widget_type() {
    return CallbackWidgetEnum.CALLBACK_WIDGET__EVENT_SET;
  }

}

export class CallbackObject extends NoizuStruct {
  public identifier: any;
  public inner: any;

  public constructor(identifier, json) {
    super();
    this.identifier = identifier;
    this.inner = json;
  }


  widget_type() {
    return WidgetEnum.EMBED_WIDGET__CALLBACK_OBJECT;
  }

  callback_widget_type() {
    return CallbackWidgetEnum.CALLBACK_WIDGET__EVENT;
  }

}

export class AlertTriggerStateObject extends NoizuStruct {
  public triggered = false;
  public time: any;

  public constructor(json) {
    super();
    this.triggered = json['triggered?'];
    this.time = json['time'];
  }


  widget_type() {
    return WidgetEnum.EMBED_WIDGET__ALERT_TRIGGER_STATE_OBJECT;
  }

  alert_widget_type() {
    return AlertWidgetEnum.ALERT_WIDGET__ALERT_TRIGGER_STATE;
  }

}

export class SubscriberObjectSet extends BaseEntitySet {
  public entities: any = {};

  public constructor(json) {
    super();
    this.entities = {};
    if (json && (typeof json === 'object')) {
      Object.keys(json).forEach(key => {
        this.entities[key] = new SubscriberObject(key, json[key]);
      });
    }
  }

  widget_type() {
    return WidgetEnum.EMBED_WIDGET__SUBSCRIBER_OBJECT_SET;
  }
}

export class SubscriberObject extends NoizuStruct {
  public identifier: any;
  public inner: any;

  public constructor(identifier, json) {
    super();
    this.identifier = identifier;
    this.inner = json;
  }


  widget_type() {
    return WidgetEnum.EMBED_WIDGET__SUBSCRIBER_OBJECT;
  }

}

export class RuntimeConfigurationObject extends NoizuStruct {
  public settings: any = {};
  public expires_on: any = null;
  public expires = false;
  public cache_index = 0;

  public constructor(json) {
    super();
    if (json) {
      this.settings = json['settings'];
      this.expires_on = json['expires_on'];
      this.expires = json['expires?'];
      this.cache_index = json['cache_index'];
    }
  }


  widget_type() {
    return WidgetEnum.EMBED_WIDGET__RUNTIME_CONFIGURATION_OBJECT;
  }

}




export class AlertStatusEntity extends NoizuStruct{
  public meta: any =  {refreshing: false, hasChange: false};
  public identifier: any;
  public alert: any;
  public trigger_state: AlertTriggerStateObject = null;
  public subscribers: SubscriberObjectSet = null;
  public runtime_configuration: RuntimeConfigurationObject = null;
  public callbacks: CallbackObjectSet = null;
  public facade: AlertStatusFacade = null; // sub type information


  public inner;

  public constructor(alert, json) {
    super();
    this.identifier = json['identifier'];
    this.alert = alert;
    this.trigger_state =  new AlertTriggerStateObject(json['trigger_state']);
    this.subscribers =  new SubscriberObjectSet(json['subscribers']);
    this.callbacks =  new CallbackObjectSet(json['callbacks']);
    this.runtime_configuration = new RuntimeConfigurationObject(json['runtime_configuration']);
    this.facade = new AlertStatusFacade( this.identifier, json['alert_state']);

    delete(json['identifier']);
    delete(json['trigger_state']);
    //delete(json['subscribers']);
    delete(json['runtime_configuration']);
    //delete(json['callbacks']);
    delete(json['alert_state']);
    this.inner = json;
  }

  widget_type() {
    return WidgetEnum.EMBED_WIDGET__ALERT_STATUS_ENTITY;
  }

  alert_status_widget() {
    return WidgetEnum_Alert_Status.ALERT_STATUS_WIDGET__DEFAULT;
  }
}
