

export enum MCUStatusCodes {
  MCU_NONE =0x0,
  MCU_PENDING = 0x01,
  MCU_DOWNLOADING = 0x02,
  MCU_READY = 0x04,
  MCU_RESUME_LATER = 0x08,
  MCU_ERROR = 0x10,
  MCU_CHECKING = 0x20,
  MCU_ALLOC_FAIL = 0x40,
  MCU_SENDING = 0x80,
  MCU_SENT = 0x100,
  MCU_BLANK_DEFINITION = 0x200,
}

export enum CodeSet3_FirmwareCodes {
  ACTION_NEW = 1,
  ACTION_DELAY = 2,
  ACTION_PROCESS = 3,
  ACTION_COMPLETE = 4,
  ACTION_CLEAN = 5,
  ACTION_CLEAN_ONLY = 6,
  ACTION_DEEP_SLEEP = 7,
  ACTION_RESTART = 8,
  ACTION_WAIT_FOR_SNTP = 9,
  ACTION_CONTINUE_WAIT_FOR_SNTP = 10,
  ACTION_FAIL_API_REQUEST = 11,
  ACTION_WATCHDOG_TIMEOUT = 12,
  ACTION_START_API_REQUEST = 13,
  ACTION_WAIT_API_RESPONSE = 14,
  ACTION_STREAM_API_REQUEST = 15,
  ACTION_CLOSE_API_REQUEST = 16,
  UART_CMD_AT = 17,
  UART_CMD_AT_CONSTATE = 18,
  UART_CMD_AT_RESTORE = 19,
  UART_CMD_AT_RESTORE_FACT = 20,
  UART_CMD_AT_DPSLEEP = 21,
  UART_CMD_AT_RSSI = 22,
  UART_CMD_AT_CIPSEND = 23,
  UART_CMD_AT_CIPSEND_END = 24,
  UART_CMD_DBG = 25,
  UART_CMD_DBG_MEM_LEAK = 26,
  UART_CMD_DBG_MEM_STATE = 27,
  UART_CMD_DBG_NET_CLOSE = 28,
  UART_CMD_DBG_NET_ABORT = 29,
  UART_CMD_DBG_UPG_REBOOT = 30,
  UART_CMD_DBG_GS_PURGE = 31,
  UART_CMD_DBG_GS_SET = 32,
  UART_CMD_DBG_GS_GET = 33,
  UART_CMD_DBG_FW_STATE = 34,
  UART_CMD_DBG_FORCE_CRASH = 35,
  UART_CMD_DBG_TOKEN_CALC = 36,
  UART_CMD_DBG_WIFI_SET = 37,
  UART_CMD_DBG_WIFI_GET = 38,
  UART_CMD_DBG_OTA_FORCE = 41,
  UART_CMD_DBG_FIREWALL_WORKAROUND = 42,
  UART_CMD_DBG_MCU_STATUS = 39,
  UART_CMD_DBG_MCU_RESUME = 40,
  UART_CMD_STAGE = 43,
  UART_CMD_BIN = 50,
  UART_CMD_GEO = 51,
  UART_CMD_NWS = 52,
  UART_CMD_OTA = 53,
  UART_CMD_SMA = 54,
  UART_CMD_WAK = 55,
  UART_CMD_WTH = 56,
  UART_CMD_DWN_ALARM = 57,
  UART_CMD_SET_ALARM = 58,
  UART_CMD_VER = 59,
  UART_CMD_SOFTAP = 60,
  UART_CMD_NIS = 61,
  UART_CMD_NIS_OK = 62,
  UART_CMD_NIS_RESEND = 63,
  UART_CMD_NIS_TIME = 64,
  UART_CMD_NIS_TIME_ONE = 65,
  UART_CMD_EVENT_1 = 66,
  UART_CMD_EVENT_2 = 67,
  UART_CMD_EVENT_3 = 68,
  SYSTEM__IAP_OUT = 70,
  SYSTEM__DOWNLOAD_MCU_DETAILS = 71,
  SYSTEM__DOWNLOAD_MCU_SEGMENTS = 72,
  SYSTEM__DOWNLOAD_MCU_SEGMENT = 73,
  SYSTEM__SPECIAL_ALARM_SET = 74,
  SYSTEM__OTA_START = 75,
  SYSTEM__OTA_WAIT = 76,
  SYSTEM__COMPLETE_UPGRADE = 77,
  SYSTEM__IAP_FORCE_OUT = 78,
  STORAGE_EVENT_SOFTAP_MERGED = 80,
  STORAGE_EVENT_SOFTAP_ERROR = 81,
  STORAGE_EVENT_LOCALE_UPDATED = 82,
  STORAGE_EVENT_LOCALE_ERROR = 83,
  LCE_WIFI_ENTER_SOFTAP = 85,
  LCE_WIFI_ENTER_SMART = 86,
  LCE_WIFI_ENTER_WPS = 87,
  LCE_WIFI_ENTER_SAVED = 88,
  LCE_WIFI_ENTER_DEBUG = 89,
  LCE_WIFI_BEGIN_SOFTAP = 90,
  LCE_WIFI_BEGIN_SMART = 91,
  LCE_WIFI_BEGIN_WPS = 92,
  LCE_WIFI_BEGIN_SAVED = 93,
  LCE_WIFI_BEGIN_DEBUG = 94,
  LCE_WIFI_EXIT_SOFTAP = 95,
  LCE_WIFI_EXIT_SMART = 96,
  LCE_WIFI_EXIT_WPS = 97,
  LCE_WIFI_EXIT_SAVED = 98,
  LCE_WIFI_EXIT_DEBUG = 99,
  LCE_WIFI_SOFTAP_WIFI_RESET = 100,
  LCE_WIFI_SOFTAP_WIFI_SET = 101,
  LCE_WIFI_SOFTAP_WIFI_COMMIT = 102,
  LCE_WIFI_SOFTAP_WIFI_CONFIRMED = 103,
  LCE_WIFI_SOFTAP_WIFI_GOT_IP = 104,
  LCE_WIFI_CONNSTATE_SOFTAP = 105,
  LCE_WIFI_CONNSTATE_SMART = 106,
  LCE_WIFI_CONNSTATE_WPS = 107,
  LCE_WIFI_CONNSTATE_SAVED = 108,
  LCE_WIFI_CONNSTATE_DEBUG = 109,
  LCE_WIFI_CONNSTATE_DEFAULT = 110,
  LCE_WIFI_CONNSTATE_OTA_UPDATE = 111,
  LCE_WIFI_FAILURE = 112,
  LCE_WIFI_FAILURE__REASON_UNSPECIFIED = 113,
  LCE_WIFI_FAILURE__REASON_AUTH_EXPIRE = 114,
  LCE_WIFI_FAILURE__REASON_AUTH_LEAVE = 115,
  LCE_WIFI_FAILURE__REASON_ASSOC_EXPIRE = 116,
  LCE_WIFI_FAILURE__REASON_ASSOC_TOOMANY = 117,
  LCE_WIFI_FAILURE__REASON_NOT_AUTHED = 118,
  LCE_WIFI_FAILURE__REASON_NOT_ASSOCED = 119,
  LCE_WIFI_FAILURE__REASON_ASSOC_LEAVE = 120,
  LCE_WIFI_FAILURE__REASON_ASSOC_NOT_AUTHED = 121,
  LCE_WIFI_FAILURE__REASON_DISASSOC_PWRCAP_BAD = 122,
  LCE_WIFI_FAILURE__REASON_DISASSOC_SUPCHAN_BAD = 123,
  LCE_WIFI_FAILURE__REASON_IE_INVALID = 124,
  LCE_WIFI_FAILURE__REASON_MIC_FAILURE = 125,
  LCE_WIFI_FAILURE__REASON_4WAY_HANDSHAKE_TIMEOUT = 126,
  LCE_WIFI_FAILURE__REASON_GROUP_KEY_UPDATE_TIMEOUT = 127,
  LCE_WIFI_FAILURE__REASON_IE_IN_4WAY_DIFFERS = 128,
  LCE_WIFI_FAILURE__REASON_GROUP_CIPHER_INVALID = 129,
  LCE_WIFI_FAILURE__REASON_PAIRWISE_CIPHER_INVALID = 130,
  LCE_WIFI_FAILURE__REASON_AKMP_INVALID = 131,
  LCE_WIFI_FAILURE__REASON_UNSUPP_RSN_IE_VERSION = 132,
  LCE_WIFI_FAILURE__REASON_INVALID_RSN_IE_CAP = 133,
  LCE_WIFI_FAILURE__REASON_802_1X_AUTH_FAILED = 134,
  LCE_WIFI_FAILURE__REASON_CIPHER_SUITE_REJECTED = 135,
  LCE_WIFI_FAILURE__REASON_BEACON_TIMEOUT = 136,
  LCE_WIFI_FAILURE__REASON_NO_AP_FOUND = 137,
  LCE_WIFI_FAILURE__REASON_AUTH_FAIL = 138,
  LCE_WIFI_FAILURE__REASON_ASSOC_FAIL = 139,
  LCE_WIFI_FAILURE__REASON_HANDSHAKE_TIMEOUT = 140,
  LCE_WIFI_GOT_IP = 141,
  LCE_WIFI_SNTP_READY = 142,
  LCE_RESTORE_SOFTAP_CLEAR = 145,
  LCE_RESTORE_MID_SOFTAP = 146,
  LCE_RESTORE_POST_SOFTAP_EXCEPTION = 147,
  LCE_RESTORE_POST_SOFTAP = 148,
  LCE_BOOT__REASON_DEFAULT_RST = 150,
  LCE_BOOT__REASON_WDT_RST = 151,
  LCE_BOOT__REASON_EXCEPTION_RST = 152,
  LCE_BOOT__REASON_SOFT_WDT_RST = 153,
  LCE_BOOT__REASON_SOFT_RESTART = 154,
  LCE_BOOT__REASON_DEEP_SLEEP_AWAKE = 155,
  LCE_BOOT__REASON_EXT_SYS_RST = 156,
  LCE_BOOT__REASON_OTHER = 157,
  LCE_API_CB_ACW = 160,
  LCE_API_CB_ACW_OK = 161,
  LCE_API_CB_ACW_ERR = 162,
  LCE_API_CB_GEO = 163,
  LCE_API_CB_GEO_OK = 164,
  LCE_API_CB_GEO_ERR = 165,
  LCE_API_CB_WTH = 166,
  LCE_API_CB_WTH_OK = 167,
  LCE_API_CB_WTH_ERR = 168,
  LCE_API_CB_ALARM_SET__OK = 169,
  LCE_API_CB_ALARM_SET__ERR = 170,
  LCE_API_CB_ALARM_SET__NULL = 171,
  LCE_API_CB_ALARM_SET__MALFORMED = 172,
  LCE_API_CB_DELAYED_ALARM_SET__OK = 173,
  LCE_API_CB_DELAYED_ALARM_SET__ERR = 174,
  LCE_API_CB_DELAYED_ALARM_SET__NULL = 175,
  LCE_API_CB_DELAYED_ALARM_SET__MALFORMED = 176,
  LCE_API_CB_ALARM_DOWN__OK = 177,
  LCE_API_CB_ALARM_DOWN__ERR = 178,
  LCE_API_CB_ALARM_DOWN__NULL = 179,
  LCE_API_CB_ALARM_DOWN__MALFORMED = 179,
  LCE_API_CB_NIS = 181,
  LCE_API_CB_NIS_OK = 182,
  LCE_API_CB_NIS_ERR = 183,
  LCE_API_CB_DATASTREAM__PARSE_FAILURE = 184,
  LCE_API_CB_DATASTREAM__PARSE_SUCCESS = 185,
  LCE_API_CB_DATASTREAM__PARSE_FAILURE_INPUT = 186,
  LCE_API_CB_DATASTREAM__PARSE_FAILURE_HTTP_REQUEST = 187,
  LCE_API_CB_BIN = 189,
  LCE_API_CB_BIN__OK = 190,
  LCE_API_CB_BIN__ERR = 191,
  LCE_API_CB_BIN__BASE = 192,
  LCE_API_CB_BIN__MCU0_ALM0_OTA0_GEO0_SMA1 = 193,
  LCE_API_CB_BIN__MCU0_ALM0_OTA0_GEO1_SMA0 = 194,
  LCE_API_CB_BIN__MCU0_ALM0_OTA0_GEO1_SMA1 = 195,
  LCE_API_CB_BIN__MCU0_ALM0_OTA1_GEO0_SMA0 = 196,
  LCE_API_CB_BIN__MCU0_ALM0_OTA1_GEO0_SMA1 = 197,
  LCE_API_CB_BIN__MCU0_ALM0_OTA1_GEO1_SMA0 = 198,
  LCE_API_CB_BIN__MCU0_ALM0_OTA1_GEO1_SMA1 = 199,
  LCE_API_CB_BIN__MCU0_ALM1_OTA0_GEO0_SMA0 = 200,
  LCE_API_CB_BIN__MCU0_ALM1_OTA0_GEO0_SMA1 = 201,
  LCE_API_CB_BIN__MCU0_ALM1_OTA0_GEO1_SMA0 = 202,
  LCE_API_CB_BIN__MCU0_ALM1_OTA0_GEO1_SMA1 = 203,
  LCE_API_CB_BIN__MCU0_ALM1_OTA1_GEO0_SMA0 = 204,
  LCE_API_CB_BIN__MCU0_ALM1_OTA1_GEO0_SMA1 = 205,
  LCE_API_CB_BIN__MCU0_ALM1_OTA1_GEO1_SMA0 = 206,
  LCE_API_CB_BIN__MCU0_ALM1_OTA1_GEO1_SMA1 = 207,
  LCE_API_CB_BIN__MCU1_ALM0_OTA0_GEO0_SMA0 = 208,
  LCE_API_CB_BIN__MCU1_ALM0_OTA0_GEO0_SMA1 = 209,
  LCE_API_CB_BIN__MCU1_ALM0_OTA0_GEO1_SMA0 = 210,
  LCE_API_CB_BIN__MCU1_ALM0_OTA0_GEO1_SMA1 = 211,
  LCE_API_CB_BIN__MCU1_ALM0_OTA1_GEO0_SMA0 = 212,
  LCE_API_CB_BIN__MCU1_ALM0_OTA1_GEO0_SMA1 = 213,
  LCE_API_CB_BIN__MCU1_ALM0_OTA1_GEO1_SMA0 = 214,
  LCE_API_CB_BIN__MCU1_ALM0_OTA1_GEO1_SMA1 = 215,
  LCE_API_CB_BIN__MCU1_ALM1_OTA0_GEO0_SMA0 = 216,
  LCE_API_CB_BIN__MCU1_ALM1_OTA0_GEO0_SMA1 = 217,
  LCE_API_CB_BIN__MCU1_ALM1_OTA0_GEO1_SMA0 = 218,
  LCE_API_CB_BIN__MCU1_ALM1_OTA0_GEO1_SMA1 = 219,
  LCE_API_CB_BIN__MCU1_ALM1_OTA1_GEO0_SMA0 = 220,
  LCE_API_CB_BIN__MCU1_ALM1_OTA1_GEO0_SMA1 = 221,
  LCE_API_CB_BIN__MCU1_ALM1_OTA1_GEO1_SMA0 = 222,
  LCE_API_CB_BIN__MCU1_ALM1_OTA1_GEO1_SMA1 = 223,
  LCE_API_CB_WAK = 224,
  LCE_API_CB_WAK_PASS = 225,
  LCE_API_CB_WAK_ERR = 226,
  LCE_API_CB_WAK_FAIL = 227,
  SYSTEM__DOWNLOAD_MCU_SEGMENTS_COMPLETE = 228,
  SYSTEM__DOWNLOAD_MCU_SEGMENT_COMPLETE = 229,
  SYSTEM__DOWNLOAD_MCU_SEGMENT_WAIT = 230,
  SYSTEM__DOWNLOAD_MCU_SEGMENT_ERROR = 231,
  LCE_API_CB_WTH_CACHED = 232,
  LCE_API_CB_GEO_CACHED = 233,
  LCE_API_CB_WTH_OUT_CACHED = 234,

  LCE_API_CB_SOFTAP = 235,

  LCE_API_IAP_FORCE = 236,
  LCE_API_IAP_BEGIN = 237,
  LCE_API_IAP_END = 238,
  LCE_API_IAP_ERROR = 239,
  LCE_API_IAP_ALLOC = 240,
  LCE_API_IAP_CHKFAIL = 241,
  LCE_API_IAP_DOWNLOAD = 242,
  LCE_API_IAP_ANON = 243,

  LCE_OTA_BEGIN = 245,
  LCE_OTA_FAIL = 246,
  LCE_OTA_NOP = 247,
  LCE_OTA_CHECK = 248,
  LCE_API_EVENT_SET_WIFI_SOFTAP = 250,
  LCE_API_EVENT_SET_WIFI_SMART = 251,
  LCE_SPI_HACK = 252,
}

export enum TrieFirmwareCommands {
  UART_CMD_AT_CONSTATE = 10,
  UART_CMD_AT_RESTORE = 11,
  UART_CMD_AT_RESTORE_FACT = 12,
  UART_CMD_AT_DPSLEEP = 13,
  UART_CMD_AT_RSSI = 14,
  UART_CMD_AT_CIPSEND = 15,
  UART_CMD_AT_CIPSEND_END = 16,
  UART_CMD_DBG_MEM_LEAK = 20,
  UART_CMD_DBG_MEM_STATE = 21,
  UART_CMD_DBG_NET_CLOSE = 22,
  UART_CMD_DBG_NET_ABORT = 23,
  UART_CMD_DBG_UPG_REBOOT = 24,
  UART_CMD_DBG_GS_PURGE = 25,
  UART_CMD_DBG_GS_SET = 26,
  UART_CMD_DBG_GS_GET = 27,
  UART_CMD_DBG_FW_STATE = 28,
  UART_CMD_DBG_FORCE_CRASH = 29,
  UART_CMD_DBG_TOKEN_CALC = 30,
  UART_CMD_DBG_WIFI_SET = 31,
  UART_CMD_DBG_WIFI_GET = 32,
  UART_CMD_DBG_OTA_FORCE = 33,
  UART_CMD_DBG_FIREWALL_WORKAROUND = 34,
  UART_CMD_BIN = 35,
  UART_CMD_GEO = 36,
  UART_CMD_NWS = 37,
  UART_CMD_OTA = 38,
  UART_CMD_SMA = 39,
  UART_CMD_WAK = 40,
  UART_CMD_WTH = 41,
  UART_CMD_DWN_ALARM = 42,
  UART_CMD_SET_ALARM = 43,
  UART_CMD_VER = 44,
  UART_CMD_SOFTAP = 45,
  UART_CMD_NIS = 46,
  UART_CMD_NIS_OK = 47,
  UART_CMD_NIS_RESEND = 48,
  UART_CMD_NIS_TIME = 49,
  UART_CMD_NIS_TIME_ONE = 50,
  UART_CMD_EVENT_1 = 51,
  UART_CMD_EVENT_2 = 52,
  UART_CMD_EVENT_3 = 53,

  STORAGE_EVENT_SOFTAP_MERGED = 54,
  STORAGE_EVENT_SOFTAP_ERROR= 55,
  STORAGE_EVENT_LOCALE_UPDATED= 56,
  STORAGE_EVENT_LOCALE_ERROR= 57,

  LCE_SPI_HACK = 59,
  LCE_WIFI_ENTER_SOFTAP = 60,
  LCE_WIFI_ENTER_SMART = 61,
  LCE_WIFI_ENTER_WPS = 62,
  LCE_WIFI_ENTER_SAVED = 63,
  LCE_WIFI_ENTER_DEBUG = 64,
  LCE_WIFI_BEGIN_SOFTAP = 65,
  LCE_WIFI_BEGIN_SMART = 66,
  LCE_WIFI_BEGIN_WPS = 67,
  LCE_WIFI_BEGIN_SAVED = 68,
  LCE_WIFI_BEGIN_DEBUG = 69,
  LCE_WIFI_EXIT_SOFTAP = 70,
  LCE_WIFI_EXIT_SMART = 71,
  LCE_WIFI_EXIT_WPS = 72,
  LCE_WIFI_EXIT_SAVED = 73,
  LCE_WIFI_EXIT_DEBUG = 74,
  LCE_WIFI_SOFTAP_WIFI_RESET = 75,
  LCE_WIFI_SOFTAP_WIFI_SET = 76,
  LCE_WIFI_SOFTAP_WIFI_COMMIT = 77,
  LCE_WIFI_SOFTAP_WIFI_CONFIRMED = 78,
  LCE_WIFI_SOFTAP_WIFI_GOT_IP = 79,
  LCE_WIFI_CONNSTATE_SOFTAP = 80,
  LCE_WIFI_CONNSTATE_SMART = 81,
  LCE_WIFI_CONNSTATE_WPS = 82,
  LCE_WIFI_CONNSTATE_SAVED = 83,
  LCE_WIFI_CONNSTATE_DEBUG = 84,
  LCE_WIFI_CONNSTATE_DEFAULT = 85,
  LCE_WIFI_CONNSTATE_OTA_UPDATE = 86,
  LCE_RESTORE_SOFTAP_CLEAR = 87,
  LCE_RESTORE_MID_SOFTAP = 88,
  LCE_RESTORE_POST_SOFTAP_EXCEPTION = 89,
  LCE_RESTORE_POST_SOFTAP = 90,
  LCE_WIFI_GOT_IP = 91,
  LCE_WIFI_SNTP_READY = 92,
  LCE_BOOT__REASON_DEFAULT_RST = 93,
  LCE_BOOT__REASON_WDT_RST = 94,
  LCE_BOOT__REASON_EXCEPTION_RST = 95,
  LCE_BOOT__REASON_SOFT_WDT_RST = 96,
  LCE_BOOT__REASON_SOFT_RESTART = 97,
  LCE_BOOT__REASON_DEEP_SLEEP_AWAKE = 98,
  LCE_BOOT__REASON_EXT_SYS_RST = 99,
  LCE_API_CB_ACW_OK = 101,
  LCE_API_CB_ACW_ERR = 102,
  LCE_API_CB_GEO_OK = 103,
  LCE_API_CB_GEO_ERR = 104,
  LCE_API_CB_WTH_OK = 105,
  LCE_API_CB_WTH_ERR = 106,
  LCE_API_CB_ALARM_SET = 107,
  LCE_API_CB_ALARM_SET__ERR = 108,
  LCE_API_CB_ALARM_SET__NULL = 109,
  LCE_API_CB_ALARM_SET__OK = 110,
  LCE_API_CB_DELAYED_ALARM_SET = 111,
  LCE_API_CB_DELAYED_ALARM_SET__ERR = 112,
  LCE_API_CB_DELAYED_ALARM_SET__NULL = 113,
  LCE_API_CB_DELAYED_ALARM_SET__OK = 114,
  LCE_API_CB_ALARM_DOWN = 115,
  LCE_API_CB_ALARM_DOWN__ERR = 116,
  LCE_API_CB_ALARM_DOWN__NULL = 117,
  LCE_API_CB_ALARM_DOWN__OK = 118,
  LCE_API_CB_NIS_OK = 119,
  LCE_API_CB_NIS_ERR = 120,
  LCE_API_CB_DATASTREAM__PARSE_FAILURE = 121,
  LCE_API_CB_DATASTREAM__PARSE_SUCCESS = 122,
  LCE_API_CB_DATASTREAM__PARSE_FAILURE_INPUT = 123,
  LCE_API_CB_DATASTREAM__PARSE_FAILURE_HTTP_REQUEST = 124,

  LCE_API_CB_BIN = 126,
  LCE_API_CB_BIN__OK = 127,
  LCE_API_CB_BIN__ERR = 128,
  LCE_API_CB_BIN__ALM0_OTA0_GEO0_SMA1 = 129,
  LCE_API_CB_BIN__ALM0_OTA0_GEO1_SMA0 = 130,
  LCE_API_CB_BIN__ALM0_OTA0_GEO1_SMA1 = 131,
  LCE_API_CB_BIN__ALM0_OTA1_GEO0_SMA0 = 132,
  LCE_API_CB_BIN__ALM0_OTA1_GEO0_SMA1 = 133,
  LCE_API_CB_BIN__ALM0_OTA1_GEO1_SMA0 = 134,
  LCE_API_CB_BIN__ALM0_OTA1_GEO1_SMA1 = 135,
  LCE_API_CB_BIN__ALM1_OTA0_GEO0_SMA0 = 136,
  LCE_API_CB_BIN__ALM1_OTA0_GEO0_SMA1 = 137,
  LCE_API_CB_BIN__ALM1_OTA0_GEO1_SMA0 = 138,
  LCE_API_CB_BIN__ALM1_OTA0_GEO1_SMA1 = 139,
  LCE_API_CB_BIN__ALM1_OTA1_GEO0_SMA0 = 140,
  LCE_API_CB_BIN__ALM1_OTA1_GEO0_SMA1 = 141,
  LCE_API_CB_BIN__ALM1_OTA1_GEO1_SMA0 = 142,
  LCE_API_CB_BIN__ALM1_OTA1_GEO1_SMA1 = 143,
  LCE_API_CB_WAK_PASS = 144,
  LCE_API_CB_WAK_FAIL = 145,
  LCE_API_CB_WAK_ERR = 146,
  LCE_OTA_BEGIN = 147,
  LCE_OTA_FAIL = 148,
  LCE_OTA_NOP = 149,
  LCE_OTA_CHECK = 150,
  LCE_WIFI_FAILURE = 151,
  LCE_WIFI_FAILURE__REASON_UNSPECIFIED = 152,
  LCE_WIFI_FAILURE__REASON_AUTH_EXPIRE = 153,
  LCE_WIFI_FAILURE__REASON_AUTH_LEAVE = 154,
  LCE_WIFI_FAILURE__REASON_ASSOC_EXPIRE = 155,
  LCE_WIFI_FAILURE__REASON_ASSOC_TOOMANY = 156,
  LCE_WIFI_FAILURE__REASON_NOT_AUTHED = 157,
  LCE_WIFI_FAILURE__REASON_NOT_ASSOCED = 158,
  LCE_WIFI_FAILURE__REASON_ASSOC_LEAVE = 159,
  LCE_WIFI_FAILURE__REASON_ASSOC_NOT_AUTHED = 160,
  LCE_WIFI_FAILURE__REASON_DISASSOC_PWRCAP_BAD = 161,
  LCE_WIFI_FAILURE__REASON_DISASSOC_SUPCHAN_BAD = 162,
  LCE_WIFI_FAILURE__REASON_IE_INVALID = 163,
  LCE_WIFI_FAILURE__REASON_MIC_FAILURE = 164,
  LCE_WIFI_FAILURE__REASON_4WAY_HANDSHAKE_TIMEOUT = 165,
  LCE_WIFI_FAILURE__REASON_GROUP_KEY_UPDATE_TIMEOUT = 166,
  LCE_WIFI_FAILURE__REASON_IE_IN_4WAY_DIFFERS = 167,
  LCE_WIFI_FAILURE__REASON_GROUP_CIPHER_INVALID = 168,
  LCE_WIFI_FAILURE__REASON_PAIRWISE_CIPHER_INVALID = 169,
  LCE_WIFI_FAILURE__REASON_AKMP_INVALID = 170,
  LCE_WIFI_FAILURE__REASON_UNSUPP_RSN_IE_VERSION = 171,
  LCE_WIFI_FAILURE__REASON_INVALID_RSN_IE_CAP = 172,
  LCE_WIFI_FAILURE__REASON_802_1X_AUTH_FAILED = 173,
  LCE_WIFI_FAILURE__REASON_CIPHER_SUITE_REJECTED = 174,
  LCE_WIFI_FAILURE__REASON_BEACON_TIMEOUT = 175,
  LCE_WIFI_FAILURE__REASON_NO_AP_FOUND = 176,
  LCE_WIFI_FAILURE__REASON_AUTH_FAIL = 177,
  LCE_WIFI_FAILURE__REASON_ASSOC_FAIL = 178,
  LCE_WIFI_FAILURE__REASON_HANDSHAKE_TIMEOUT = 179,
  LCE_API_CB_WTH_CACHED = 180,
  LCE_API_CB_GEO_CACHED = 181,
  LCE_API_CB_WTH_OUT_CACHED = 182,
  SPECIAL_SET_ALARM = 185,
  LCE_API_CB_SOFTAP = 186,
  SYSTEM_START_OTA = 200,
  SYSTEM_WAIT_OTA = 201,
  SYSTEM_UPGRADE_COMPLETE = 202,
}

export enum FirmwareCommands {
  //UART_CMD_AT = 213,
  UART_CMD_DBG = 365,
  UART_CMD_BIN = 377,
  UART_CMD_GEO = 379,
  UART_CMD_SMA = 385,
  UART_CMD_WAK = 387,
  UART_CMD_OTA = 388,
  UART_CMD_DWN_ALARM = 393,
  UART_CMD_NIS = 394,
  UART_CMD_SET_ALARM = 396,
  UART_CMD_VER = 397,
  UART_CMD_WTH = 403,
  UART_CMD_NWS = 408,
  UART_CMD_AT_RSSI = 534,
  UART_CMD_NIS_OK = 612,
  UART_CMD_AT_CIPSEND = 731,
  UART_CMD_AT_DPSLEEP = 738,
  UART_CMD_AT_RESTORE = 761,
  UART_CMD_DBG_WIFI = 796,
  UART_CMD_AT_CONSTATE = 822,
  UART_CMD_NIS_TIME = 825,
  UART_CMD_NIS_TIME_ONE = 874,
  UART_CMD_DBG_GS_GET = 903,
  UART_CMD_DBG_GS_SET = 915,
  UART_CMD_AT_CIPSEND_END = 946,


  UART_CMD_NIS_RESEND = 1035,
  UART_CMD_DBG_MEM_LEAK = 1097,
  UART_CMD_DBG_WIFI_GET = 1116,
  UART_CMD_DBG_WIFI_SET = 1128,
  UART_CMD_DBG_GS_PURGE = 1130,
  UART_CMD_DBG_FW_STATE = 1131,
  UART_CMD_AT_RESTORE_FACT = 1142,
  UART_CMD_DBG_OTA_FORCE = 1216,
  UART_CMD_DBG_NET_CLOSE = 1226,
  UART_CMD_DBG_NET_ABORT = 1228,
  UART_CMD_DBG_MEM_STATE = 1229,
  UART_CMD_DBG_TOKEN_CALC = 1313,
  UART_CMD_DBG_UPG_REBOOT = 1348,
  UART_CMD_DBG_FORCE_CRASH = 1421,
  LCE_WIFI_ENTER_SOFTAP = 2100,
  LCE_WIFI_ENTER_SMART = 2101,
  LCE_WIFI_ENTER_WPS = 2102,
  LCE_WIFI_ENTER_SAVED = 2103,
  LCE_WIFI_ENTER_DEBUG = 2104,
  LCE_WIFI_BEGIN_SOFTAP = 2105,
  LCE_WIFI_BEGIN_SMART = 2106,
  LCE_WIFI_BEGIN_WPS = 2107,
  LCE_WIFI_BEGIN_SAVED = 2108,
  LCE_WIFI_BEGIN_DEBUG = 2109,
  LCE_WIFI_EXIT_SOFTAP = 2110,
  LCE_WIFI_EXIT_SMART = 2111,
  LCE_WIFI_EXIT_WPS = 2112,
  LCE_WIFI_EXIT_SAVED = 2113,
  LCE_WIFI_EXIT_DEBUG = 2114,
  LCE_WIFI_SOFTAP_WIFI_RESET = 2120,
  LCE_WIFI_SOFTAP_WIFI_SET = 2121,
  LCE_WIFI_SOFTAP_WIFI_COMMIT = 2122,
  LCE_WIFI_SOFTAP_WIFI_CONFIRMED = 2123,
  LCE_WIFI_SOFTAP_WIFI_GOT_IP = 2124,
  LCE_RESTORE_MID_SOFTAP = 2150,
  LCE_RESTORE_POST_SOFTAP_EXCEPTION = 2151,
  LCE_RESTORE_POST_SOFTAP = 2152,
  LCE_WIFI_GOT_IP = 2153,
  LCE_WIFI_SNTP_READY = 2160,
  LCE_BOOT__REASON_DEFAULT_RST = 2200,
  LCE_BOOT__REASON_WDT_RST = 2201,
  LCE_BOOT__REASON_EXCEPTION_RST = 2202,
  LCE_BOOT__REASON_SOFT_WDT_RST = 2203,
  LCE_BOOT__REASON_SOFT_RESTART = 2204,
  LCE_BOOT__REASON_DEEP_SLEEP_AWAKE = 2205,
  LCE_BOOT__REASON_EXT_SYS_RST = 2206,
  LCE_WIFI_CONNSTATE_SOFTAP = 2225,
  LCE_WIFI_CONNSTATE_SMART = 2226,
  LCE_WIFI_CONNSTATE_WPS = 2227,
  LCE_WIFI_CONNSTATE_SAVED = 2228,
  LCE_WIFI_CONNSTATE_DEBUG = 2229,
  LCE_WIFI_CONNSTATE_DEFAULT = 2230,
  LCE_WIFI_CONNSTATE_OTA_UPDATE = 2231,
  LCE_API_CB_ACW_OK = 2300,
  LCE_API_CB_ACW_ERR = 2301,
  LCE_API_CB_GEO_OK = 2302,
  LCE_API_CB_GEO_ERR = 2303,
  LCE_API_CB_WTH_OK = 2304,
  LCE_API_CB_WTH_ERR = 2305,
  LCE_API_CB_ALARM_SET = 2306,
  LCE_API_CB_ALARM_SET__ERR = 2306,
  LCE_API_CB_ALARM_SET__NULL = 2307,
  LCE_API_CB_ALARM_SET__OK = 2308,
  LCE_API_CB_DELAYED_ALARM_SET = 2309,
  LCE_API_CB_DELAYED_ALARM_SET__ERR = 2309,
  LCE_API_CB_DELAYED_ALARM_SET__NULL = 2310,
  LCE_API_CB_DELAYED_ALARM_SET__OK = 2311,
  LCE_API_CB_ALARM_DOWN__ERR = 2312,
  LCE_API_CB_ALARM_DOWN__NULL = 2313,
  LCE_API_CB_ALARM_DOWN__OK = 2314,
  LCE_API_CB_NIS_OK = 2315,
  LCE_API_CB_NIS_ERR = 2316,
  LCE_API_CB_DATASTREAM = 2320,
  LCE_API_CB_DATASTREAM__PARSE_FAILURE = 2320,
  LCE_API_CB_DATASTREAM__PARSE_SUCCESS = 2321,
  LCE_API_CB_DATASTREAM__PARSE_FAILURE_INPUT = 2322,
  LCE_API_CB_DATASTREAM__PARSE_FAILURE_HTTP_REQUEST = 2323,
  LCE_API_CB_BIN__OK = 2400,
  LCE_API_CB_BIN__ALM0_OTA0_GEO0_SMA1 = 2401,
  LCE_API_CB_BIN__ALM0_OTA0_GEO1_SMA0 = 2402,
  LCE_API_CB_BIN__ALM0_OTA0_GEO1_SMA1 = 2403,
  LCE_API_CB_BIN__ALM0_OTA1_GEO0_SMA0 = 2404,
  LCE_API_CB_BIN__ALM0_OTA1_GEO0_SMA1 = 2405,
  LCE_API_CB_BIN__ALM0_OTA1_GEO1_SMA0 = 2406,
  LCE_API_CB_BIN__ALM0_OTA1_GEO1_SMA1 = 2407,
  LCE_API_CB_BIN__ALM1_OTA0_GEO0_SMA0 = 2408,
  LCE_API_CB_BIN__ALM1_OTA0_GEO0_SMA1 = 2409,
  LCE_API_CB_BIN__ALM1_OTA0_GEO1_SMA0 = 2410,
  LCE_API_CB_BIN__ALM1_OTA0_GEO1_SMA1 = 2411,
  LCE_API_CB_BIN__ALM1_OTA1_GEO0_SMA0 = 2412,
  LCE_API_CB_BIN__ALM1_OTA1_GEO0_SMA1 = 2413,
  LCE_API_CB_BIN__ALM1_OTA1_GEO1_SMA0 = 2414,
  LCE_API_CB_BIN__ALM1_OTA1_GEO1_SMA1 = 2415,
  LCE_API_CB_BIN__ERR = 2416,
  LCE_API_CB_WAK_PASS = 2420,
  LCE_API_CB_WAK_FAIL = 2421,
  LCE_API_CB_WAK_ERR = 2422,
  LCE_OTA_BEGIN = 2423,
  LCE_OTA_FAIL = 2424,
  LCE_OTA_NOP = 2425,
  LCE_OTA_CHECK = 2426,
  LCE_WIFI_FAILURE__REASON_UNSPECIFIED = 3001,
  LCE_WIFI_FAILURE__REASON_AUTH_EXPIRE = 3002,
  LCE_WIFI_FAILURE__REASON_AUTH_LEAVE = 3003,
  LCE_WIFI_FAILURE__REASON_ASSOC_EXPIRE = 3004,
  LCE_WIFI_FAILURE__REASON_ASSOC_TOOMANY = 3005,
  LCE_WIFI_FAILURE__REASON_NOT_AUTHED = 3006,
  LCE_WIFI_FAILURE__REASON_NOT_ASSOCED = 3007,
  LCE_WIFI_FAILURE__REASON_ASSOC_LEAVE = 3008,
  LCE_WIFI_FAILURE__REASON_ASSOC_NOT_AUTHED = 3009,
  LCE_WIFI_FAILURE__REASON_DISASSOC_PWRCAP_BAD = 3010,
  LCE_WIFI_FAILURE__REASON_DISASSOC_SUPCHAN_BAD = 3011,
  LCE_WIFI_FAILURE__REASON_IE_INVALID = 3013,
  LCE_WIFI_FAILURE__REASON_MIC_FAILURE = 3014,
  LCE_WIFI_FAILURE__REASON_4WAY_HANDSHAKE_TIMEOUT = 3015,
  LCE_WIFI_FAILURE__REASON_GROUP_KEY_UPDATE_TIMEOUT = 3016,
  LCE_WIFI_FAILURE__REASON_IE_IN_4WAY_DIFFERS = 3017,
  LCE_WIFI_FAILURE__REASON_GROUP_CIPHER_INVALID = 3018,
  LCE_WIFI_FAILURE__REASON_PAIRWISE_CIPHER_INVALID = 3019,
  LCE_WIFI_FAILURE__REASON_AKMP_INVALID = 3020,
  LCE_WIFI_FAILURE__REASON_UNSUPP_RSN_IE_VERSION = 3021,
  LCE_WIFI_FAILURE__REASON_INVALID_RSN_IE_CAP = 3022,
  LCE_WIFI_FAILURE__REASON_802_1X_AUTH_FAILED = 3023,
  LCE_WIFI_FAILURE__REASON_CIPHER_SUITE_REJECTED = 3024,
  LCE_WIFI_FAILURE__REASON_BEACON_TIMEOUT = 3200,
  LCE_WIFI_FAILURE__REASON_NO_AP_FOUND = 3201,
  LCE_WIFI_FAILURE__REASON_AUTH_FAIL = 3202,
  LCE_WIFI_FAILURE__REASON_ASSOC_FAIL = 3203,
  LCE_WIFI_FAILURE__REASON_HANDSHAKE_TIMEOUT = 3204,

  UART_CMD_EVE = 8576,
  UART_CMD_EVENT_1 = 8625,
  UART_CMD_EVENT_2 = 8626,
  UART_CMD_EVENT_3 = 8627,
}

export enum CodeSet4_FirmwareCodes {
  ACTION_NEW = 1,
  ACTION_DELAY = 2,
  ACTION_PROCESS = 3,
  ACTION_COMPLETE = 4,
  ACTION_CLEAN = 5,
  ACTION_CLEAN_ONLY = 6,
  ACTION_DEEP_SLEEP = 7,
  ACTION_RESTART = 8,
  ACTION_WAIT_FOR_SNTP = 9,
  ACTION_CONTINUE_WAIT_FOR_SNTP = 10,
  ACTION_FAIL_API_REQUEST = 11,
  ACTION_WATCHDOG_TIMEOUT = 12,
  ACTION_START_API_REQUEST = 13,
  ACTION_WAIT_API_RESPONSE = 14,
  ACTION_STREAM_API_REQUEST = 15,
  ACTION_CLOSE_API_REQUEST = 16,
  UART_CMD_AT = 17,
  UART_CMD_AT_CONSTATE = 18,
  UART_CMD_AT_RESTORE = 19,
  UART_CMD_AT_RESTORE_FACT = 20,
  UART_CMD_AT_DPSLEEP = 21,
  UART_CMD_AT_RSSI = 22,
  UART_CMD_AT_CIPSEND = 23,
  UART_CMD_AT_CIPSEND_END = 24,
  UART_CMD_DBG = 25,
  UART_CMD_DBG_MEM_LEAK = 26,
  UART_CMD_DBG_MEM_STATE = 27,
  UART_CMD_DBG_NET_CLOSE = 28,
  UART_CMD_DBG_NET_ABORT = 29,
  UART_CMD_DBG_UPG_REBOOT = 30,
  UART_CMD_DBG_GS_PURGE = 31,
  UART_CMD_DBG_GS_SET = 32,
  UART_CMD_DBG_GS_GET = 33,
  UART_CMD_DBG_FW_STATE = 34,
  UART_CMD_DBG_FORCE_CRASH = 35,
  UART_CMD_DBG_I8N_VERSION = 36,
  UART_CMD_DBG_WIFI_SET = 37,
  UART_CMD_DBG_WIFI_GET = 38,
  UART_CMD_DBG_OTA_FORCE = 42,
  UART_CMD_DBG_FIREWALL_WORKAROUND = 43,
  UART_CMD_DBG_MCU_STATUS = 39,
  UART_CMD_DBG_MCU_RESUME = 40,
  UART_CMD_STAGE = 44,
  UART_CMD_QUIET = 45,
  LCE_INVALID_ID = 46,
  LCE_INVALID_PSK = 47,
  LCE_RUNTIME_ID_PSK_UPDATE = 48,
  LCE_STORED_ID_PSK_UPDATE = 49,
  UART_CMD_BIN = 51,
  UART_CMD_GEO = 52,
  UART_CMD_NWS = 53,
  UART_CMD_OTA = 54,
  UART_CMD_SMA = 55,
  UART_CMD_WAK = 56,
  UART_CMD_WTH = 57,
  UART_CMD_DWN_ALARM = 58,
  UART_CMD_SET_ALARM = 59,
  UART_CMD_VER = 60,
  UART_CMD_SOFTAP = 61,
  UART_CMD_NIS = 62,
  UART_CMD_NIS_OK = 63,
  UART_CMD_NIS_RESEND = 64,
  UART_CMD_NIS_TIME = 65,
  UART_CMD_NIS_TIME_ONE = 66,
  UART_CMD_EVENT_1 = 67,
  UART_CMD_EVENT_2 = 68,
  UART_CMD_EVENT_3 = 69,
  SYSTEM__IAP_OUT = 70,
  SYSTEM__DOWNLOAD_MCU_DETAILS = 71,
  SYSTEM__DOWNLOAD_MCU_SEGMENTS = 72,
  SYSTEM__DOWNLOAD_MCU_SEGMENT = 73,
  SYSTEM__SPECIAL_ALARM_SET = 74,
  SYSTEM__OTA_START = 75,
  SYSTEM__OTA_WAIT = 76,
  SYSTEM__COMPLETE_UPGRADE = 77,
  SYSTEM__IAP_FORCE_OUT = 78,
  STORAGE_EVENT_SOFTAP_MERGED = 80,
  STORAGE_EVENT_SOFTAP_ERROR = 81,
  STORAGE_EVENT_LOCALE_UPDATED = 82,
  STORAGE_EVENT_LOCALE_ERROR = 83,
  LCE_WIFI_ENTER_SOFTAP = 85,
  LCE_WIFI_ENTER_SMART = 86,
  LCE_WIFI_ENTER_WPS = 87,
  LCE_WIFI_ENTER_SAVED = 88,
  LCE_WIFI_ENTER_DEBUG = 89,
  LCE_WIFI_BEGIN_SOFTAP = 90,
  LCE_WIFI_BEGIN_SMART = 91,
  LCE_WIFI_BEGIN_WPS = 92,
  LCE_WIFI_BEGIN_SAVED = 93,
  LCE_WIFI_BEGIN_DEBUG = 94,
  LCE_WIFI_EXIT_SOFTAP = 95,
  LCE_WIFI_EXIT_SMART = 96,
  LCE_WIFI_EXIT_WPS = 97,
  LCE_WIFI_EXIT_SAVED = 98,
  LCE_WIFI_EXIT_DEBUG = 99,
  LCE_WIFI_SOFTAP_WIFI_RESET = 100,
  LCE_WIFI_SOFTAP_WIFI_SET = 101,
  LCE_WIFI_SOFTAP_WIFI_COMMIT = 102,
  LCE_WIFI_SOFTAP_WIFI_CONFIRMED = 103,
  LCE_WIFI_SOFTAP_WIFI_GOT_IP = 104,
  LCE_WIFI_CONNSTATE_SOFTAP = 105,
  LCE_WIFI_CONNSTATE_SMART = 106,
  LCE_WIFI_CONNSTATE_WPS = 107,
  LCE_WIFI_CONNSTATE_SAVED = 108,
  LCE_WIFI_CONNSTATE_DEBUG = 109,
  LCE_WIFI_CONNSTATE_DEFAULT = 110,
  LCE_WIFI_CONNSTATE_OTA_UPDATE = 111,
  LCE_WIFI_FAILURE = 112,
  LCE_WIFI_FAILURE__REASON_UNSPECIFIED = 113,
  LCE_WIFI_FAILURE__REASON_AUTH_EXPIRE = 114,
  LCE_WIFI_FAILURE__REASON_AUTH_LEAVE = 115,
  LCE_WIFI_FAILURE__REASON_ASSOC_EXPIRE = 116,
  LCE_WIFI_FAILURE__REASON_ASSOC_TOOMANY = 117,
  LCE_WIFI_FAILURE__REASON_NOT_AUTHED = 118,
  LCE_WIFI_FAILURE__REASON_NOT_ASSOCED = 119,
  LCE_WIFI_FAILURE__REASON_ASSOC_LEAVE = 120,
  LCE_WIFI_FAILURE__REASON_ASSOC_NOT_AUTHED = 121,
  LCE_WIFI_FAILURE__REASON_DISASSOC_PWRCAP_BAD = 122,
  LCE_WIFI_FAILURE__REASON_DISASSOC_SUPCHAN_BAD = 123,
  LCE_WIFI_FAILURE__REASON_IE_INVALID = 124,
  LCE_WIFI_FAILURE__REASON_MIC_FAILURE = 125,
  LCE_WIFI_FAILURE__REASON_4WAY_HANDSHAKE_TIMEOUT = 126,
  LCE_WIFI_FAILURE__REASON_GROUP_KEY_UPDATE_TIMEOUT = 127,
  LCE_WIFI_FAILURE__REASON_IE_IN_4WAY_DIFFERS = 128,
  LCE_WIFI_FAILURE__REASON_GROUP_CIPHER_INVALID = 129,
  LCE_WIFI_FAILURE__REASON_PAIRWISE_CIPHER_INVALID = 130,
  LCE_WIFI_FAILURE__REASON_AKMP_INVALID = 131,
  LCE_WIFI_FAILURE__REASON_UNSUPP_RSN_IE_VERSION = 132,
  LCE_WIFI_FAILURE__REASON_INVALID_RSN_IE_CAP = 133,
  LCE_WIFI_FAILURE__REASON_802_1X_AUTH_FAILED = 134,
  LCE_WIFI_FAILURE__REASON_CIPHER_SUITE_REJECTED = 135,
  LCE_WIFI_FAILURE__REASON_BEACON_TIMEOUT = 136,
  LCE_WIFI_FAILURE__REASON_NO_AP_FOUND = 137,
  LCE_WIFI_FAILURE__REASON_AUTH_FAIL = 138,
  LCE_WIFI_FAILURE__REASON_ASSOC_FAIL = 139,
  LCE_WIFI_FAILURE__REASON_HANDSHAKE_TIMEOUT = 140,
  LCE_WIFI_GOT_IP = 141,
  LCE_WIFI_SNTP_READY = 142,
  LCE_RESTORE_SOFTAP_CLEAR = 145,
  LCE_RESTORE_MID_SOFTAP = 146,
  LCE_RESTORE_POST_SOFTAP_EXCEPTION = 147,
  LCE_RESTORE_POST_SOFTAP = 148,
  LCE_BOOT__REASON_DEFAULT_RST = 150,
  LCE_BOOT__REASON_WDT_RST = 151,
  LCE_BOOT__REASON_EXCEPTION_RST = 152,
  LCE_BOOT__REASON_SOFT_WDT_RST = 153,
  LCE_BOOT__REASON_SOFT_RESTART = 154,
  LCE_BOOT__REASON_DEEP_SLEEP_AWAKE = 155,
  LCE_BOOT__REASON_EXT_SYS_RST = 156,
  LCE_BOOT__REASON_OTHER = 157,
  LCE_API_CB_ACW = 160,
  LCE_API_CB_ACW_OK = 161,
  LCE_API_CB_ACW_ERR = 162,
  LCE_API_CB_GEO = 163,
  LCE_API_CB_GEO_OK = 164,
  LCE_API_CB_GEO_ERR = 165,
  LCE_API_CB_WTH = 166,
  LCE_API_CB_WTH_OK = 167,
  LCE_API_CB_WTH_ERR = 168,
  LCE_API_CB_ALARM_SET__OK = 169,
  LCE_API_CB_ALARM_SET__ERR = 170,
  LCE_API_CB_ALARM_SET__NULL = 171,
  LCE_API_CB_ALARM_SET__MALFORMED = 172,
  LCE_API_CB_DELAYED_ALARM_SET__OK = 173,
  LCE_API_CB_DELAYED_ALARM_SET__ERR = 174,
  LCE_API_CB_DELAYED_ALARM_SET__NULL = 175,
  LCE_API_CB_DELAYED_ALARM_SET__MALFORMED = 176,
  LCE_API_CB_ALARM_DOWN__OK = 177,
  LCE_API_CB_ALARM_DOWN__ERR = 178,
  LCE_API_CB_ALARM_DOWN__NULL = 179,
  LCE_API_CB_ALARM_DOWN__MALFORMED = 179,
  LCE_API_CB_NIS = 181,
  LCE_API_CB_NIS_OK = 182,
  LCE_API_CB_NIS_ERR = 183,
  LCE_API_CB_DATASTREAM__PARSE_FAILURE = 184,
  LCE_API_CB_DATASTREAM__PARSE_SUCCESS = 185,
  LCE_API_CB_DATASTREAM__PARSE_FAILURE_INPUT = 186,
  LCE_API_CB_DATASTREAM__PARSE_FAILURE_HTTP_REQUEST = 187,
  LCE_API_CB_BIN = 189,
  LCE_API_CB_BIN__OK = 190,
  LCE_API_CB_BIN__ERR = 191,
  LCE_API_CB_BIN__BASE = 192,
  LCE_API_CB_BIN__MCU0_ALM0_OTA0_GEO0_SMA1 = 193,
  LCE_API_CB_BIN__MCU0_ALM0_OTA0_GEO1_SMA0 = 194,
  LCE_API_CB_BIN__MCU0_ALM0_OTA0_GEO1_SMA1 = 195,
  LCE_API_CB_BIN__MCU0_ALM0_OTA1_GEO0_SMA0 = 196,
  LCE_API_CB_BIN__MCU0_ALM0_OTA1_GEO0_SMA1 = 197,
  LCE_API_CB_BIN__MCU0_ALM0_OTA1_GEO1_SMA0 = 198,
  LCE_API_CB_BIN__MCU0_ALM0_OTA1_GEO1_SMA1 = 199,
  LCE_API_CB_BIN__MCU0_ALM1_OTA0_GEO0_SMA0 = 200,
  LCE_API_CB_BIN__MCU0_ALM1_OTA0_GEO0_SMA1 = 201,
  LCE_API_CB_BIN__MCU0_ALM1_OTA0_GEO1_SMA0 = 202,
  LCE_API_CB_BIN__MCU0_ALM1_OTA0_GEO1_SMA1 = 203,
  LCE_API_CB_BIN__MCU0_ALM1_OTA1_GEO0_SMA0 = 204,
  LCE_API_CB_BIN__MCU0_ALM1_OTA1_GEO0_SMA1 = 205,
  LCE_API_CB_BIN__MCU0_ALM1_OTA1_GEO1_SMA0 = 206,
  LCE_API_CB_BIN__MCU0_ALM1_OTA1_GEO1_SMA1 = 207,
  LCE_API_CB_BIN__MCU1_ALM0_OTA0_GEO0_SMA0 = 208,
  LCE_API_CB_BIN__MCU1_ALM0_OTA0_GEO0_SMA1 = 209,
  LCE_API_CB_BIN__MCU1_ALM0_OTA0_GEO1_SMA0 = 210,
  LCE_API_CB_BIN__MCU1_ALM0_OTA0_GEO1_SMA1 = 211,
  LCE_API_CB_BIN__MCU1_ALM0_OTA1_GEO0_SMA0 = 212,
  LCE_API_CB_BIN__MCU1_ALM0_OTA1_GEO0_SMA1 = 213,
  LCE_API_CB_BIN__MCU1_ALM0_OTA1_GEO1_SMA0 = 214,
  LCE_API_CB_BIN__MCU1_ALM0_OTA1_GEO1_SMA1 = 215,
  LCE_API_CB_BIN__MCU1_ALM1_OTA0_GEO0_SMA0 = 216,
  LCE_API_CB_BIN__MCU1_ALM1_OTA0_GEO0_SMA1 = 217,
  LCE_API_CB_BIN__MCU1_ALM1_OTA0_GEO1_SMA0 = 218,
  LCE_API_CB_BIN__MCU1_ALM1_OTA0_GEO1_SMA1 = 219,
  LCE_API_CB_BIN__MCU1_ALM1_OTA1_GEO0_SMA0 = 220,
  LCE_API_CB_BIN__MCU1_ALM1_OTA1_GEO0_SMA1 = 221,
  LCE_API_CB_BIN__MCU1_ALM1_OTA1_GEO1_SMA0 = 222,
  LCE_API_CB_BIN__MCU1_ALM1_OTA1_GEO1_SMA1 = 223,
  LCE_API_CB_WAK = 224,
  LCE_API_CB_WAK_PASS = 225,
  LCE_API_CB_WAK_ERR = 226,
  LCE_API_CB_WAK_FAIL = 227,
  LCE_API_CB_WAK_CACHE = 249,
  SYSTEM__DOWNLOAD_MCU_SEGMENTS_COMPLETE = 228,
  SYSTEM__DOWNLOAD_MCU_SEGMENT_COMPLETE = 229,
  SYSTEM__DOWNLOAD_MCU_SEGMENT_WAIT = 230,
  SYSTEM__DOWNLOAD_MCU_SEGMENT_ERROR = 231,
  LCE_API_CB_WTH_CACHED = 232,
  LCE_API_CB_GEO_CACHED = 233,
  LCE_API_CB_WTH_OUT_CACHED = 234,
  LCE_API_IAP_FORCE = 236,
  LCE_API_IAP_BEGIN = 237,
  LCE_API_IAP_END = 238,
  LCE_API_IAP_ERROR = 239,
  LCE_API_IAP_ALLOC = 240,
  LCE_API_IAP_CHKFAIL = 241,
  LCE_API_IAP_DOWNLOAD = 242,
  LCE_API_IAP_ANON = 243,
  LCE_API_CB_SOFTAP = 235,
  LCE_OTA_BEGIN = 245,
  LCE_OTA_FAIL = 246,
  LCE_OTA_NOP = 247,
  LCE_OTA_CHECK = 248,
  LCE_API_EVENT_SET_WIFI_SOFTAP = 250,
  LCE_API_EVENT_SET_WIFI_SMART = 251,
  LCE_SPI_HACK = 252,
}
