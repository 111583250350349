
    <div class="card card-accent-dark alert-facade-temperature-widget">
      <div class="card-header">
        <div class="row">
        <div class="col-6">Temperature Alert</div>
          <div class="col-6">
            <ng-select
              placeholder="Sub Type"
              [items]="alert_format"
              [multiple]="false"
              bindLabel="label"
              bindValue="value"

              [(ngModel)]="temperatureFormatSelector"
              (ngModelChange)="subTypeChange($event)"
            ></ng-select>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row"><div class="col-4">Device</div><div class="col-8"><a [routerLink]="'/portal/devices/show/' + entity.device">{{entity.device}}</a></div></div>
        <div class="row"><div class="col-4">Association</div><div class="col-8">{{entity.deviceAssociation}}</div></div>
        <div class="row"><div class="col-12"><hr/></div></div>



        <div [ngSwitch]="entity.subType">
          <div *ngSwitchCase="FormatEnum.ValueAbove" class="row">
            <div class="col-5"><b>Temperature Above</b></div>
            <div *ngIf="!options?.edit" class="col-7"><b>{{entity.editTemperatureAbove.value}}</b> <span> {{entity.editTemperatureAbove.unit?.name}}</span></div>
            <div *ngIf="options?.edit" class="col-7">
              <input
                type="number"
                [(ngModel)]="entity.editTemperatureAbove.value"
                (ngModelChange)="inputChange($event, 'temperatureAbove')"
                name="temp_above"
              />
              <span> {{entity.editTemperatureAbove.unit?.name}}</span>
            </div>
          </div>
          <div *ngSwitchCase="FormatEnum.ValueBelow" class="row">
            <div class="col-5"><b>Temperature Below</b></div>
            <div *ngIf="!options?.edit" class="col-7"><b>{{entity.editTemperatureBelow.value}}</b> <span> {{entity.editTemperatureBelow.unit?.name}}</span></div>
            <div *ngIf="options?.edit" class="col-7">
              <input
                type="number"
                [(ngModel)]="entity.editTemperatureBelow.value"
                (ngModelChange)="inputChange($event, 'temperatureBelow')"
                name="temp_below"
              />
              <span> {{entity.editTemperatureBelow.unit?.name}}</span>
            </div>
          </div>

          <div *ngSwitchCase="FormatEnum.ValueBetween" class="row">
            <div class="col-5"><b>Temperature Between</b></div>
            <div *ngIf="!options?.edit" class="col-7"><b>{{entity.editTemperatureAbove.value}}</b> <span> {{entity.editTemperatureAbove.unit?.name}}</span> and <b>{{entity.editTemperatureBelow.value}}</b> <span> {{entity.editTemperatureBelow.unit?.name}}</span></div>
            <div *ngIf="options?.edit" class="col-7">
              <input
                type="number"
                [(ngModel)]="entity.editTemperatureAbove.value"
                (ngModelChange)="inputChange($event, 'entity.editTemperatureAbove')"
                name="temp_above"
              />
              <span> {{entity.editTemperatureAbove.unit?.name}}</span>
              and
              <input
                type="number"
                [(ngModel)]="entity.editTemperatureBelow.value"
                (ngModelChange)="inputChange($event, 'entity.editTemperatureBelow')"
                name="temp_below"
              />
              <span> {{entity.editTemperatureBelow.unit?.name}}</span>
            </div>
          </div>

          <div *ngSwitchCase="FormatEnum.ValueOutside" class="row">
            <div class="col-5"><b>Temperature Outside</b></div>
            <div *ngIf="!options?.edit" class="col-7"><b>{{entity.editTemperatureBelow.value}}</b> <span> {{entity.editTemperatureBelow.unit?.name}}</span> and <b>{{entity.editTemperatureAbove.value}}</b> <span> {{entity.editTemperatureAbove.unit?.name}}</span></div>
            <div *ngIf="options?.edit" class="col-7">
              <input
                type="number"
                [(ngModel)]="entity.editTemperatureBelow.value"
                (ngModelChange)="inputChange($event, 'entity.editTemperatureBelow')"
                name="temp_below"
              />
              <span> {{entity.editTemperatureBelow.unit?.name}}</span>
              and
              <input
                type="number"
                [(ngModel)]="entity.editTemperatureAbove.value"
                (ngModelChange)="inputChange($event, 'entity.editTemperatureAbove')"
                name="temp_above"
              />
              <span> {{entity.editTemperatureAbove.unit?.name}}</span>
            </div>
          </div>


          <div *ngSwitchCase="FormatEnum.IndoorValueAbove" class="row">
            <div class="col-5"><b>Indoor Temperature Above</b></div>
            <div *ngIf="!options?.edit" class="col-7"><b>{{entity.editIndoorTemperatureAbove.value}}</b> <span> {{entity.editIndoorTemperatureAbove.unit?.name}}</span></div>
            <div *ngIf="options?.edit" class="col-7">
              <input
                type="number"
                [(ngModel)]="entity.editIndoorTemperatureAbove.value"
                (ngModelChange)="inputChange($event, 'entity.editIndoorTemperatureAbove')"
                name="temp_above"
              />
              <span> {{entity.editIndoorTemperatureAbove.unit?.name}}</span>
            </div>
          </div>
          <div *ngSwitchCase="FormatEnum.IndoorValueBelow" class="row">
            <div class="col-5"><b>Indoor Temperature Below</b></div>
            <div *ngIf="!options?.edit" class="col-7"><b>{{entity.editIndoorTemperatureBelow.value}}</b> <span> {{entity.editIndoorTemperatureBelow.unit?.name}}</span></div>
            <div *ngIf="options?.edit" class="col-7">
              <input
                type="number"
                [(ngModel)]="entity.editIndoorTemperatureBelow.value"
                (ngModelChange)="inputChange($event, 'entity.editIndoorTemperatureBelow')"
                name="temp_above"
              />
              <span> {{entity.editIndoorTemperatureBelow.unit?.name}}</span>
            </div>
          </div>

          <div *ngSwitchCase="FormatEnum.IndoorValueBetween" class="row">
            <div class="col-5"><b>Indoor Temperature Between</b></div>
            <div *ngIf="!options?.edit" class="col-7"><b>{{entity.editIndoorTemperatureAbove.value}}</b> <span> {{entity.editIndoorTemperatureAbove.unit?.name}}</span> and <b>{{entity.editIndoorTemperatureBelow.value}}</b> <span> {{entity.editIndoorTemperatureBelow.unit?.name}}</span></div>
            <div *ngIf="options?.edit" class="col-7">
              <input
                type="number"
                [(ngModel)]="entity.editIndoorTemperatureAbove.value"
                (ngModelChange)="inputChange($event, 'entity.editIndoorTemperatureAbove')"
                name="temp_above"
              />
              <span> {{entity.editIndoorTemperatureAbove.unit?.name}}</span>
              and
              <input
                type="number"
                [(ngModel)]="entity.editIndoorTemperatureBelow.value"
                (ngModelChange)="inputChange($event, 'entity.editIndoorTemperatureBelow')"
                name="temp_below"
              />
              <span> {{entity.editIndoorTemperatureBelow.unit?.name}}</span>
            </div>
          </div>

          <div *ngSwitchCase="FormatEnum.IndoorValueOutside" class="row">
            <div class="col-5"><b>Indoor Temperature Outside</b></div>
            <div *ngIf="!options?.edit" class="col-7"><b>{{entity.editIndoorTemperatureBelow.value}}</b> <span> {{entity.editIndoorTemperatureBelow.unit?.name}}</span> and <b>{{entity.editIndoorTemperatureAbove.value}}</b> <span> {{entity.editIndoorTemperatureAbove.unit?.name}}</span></div>
            <div *ngIf="options?.edit" class="col-7">
              <input
                type="number"
                [(ngModel)]="entity.editIndoorTemperatureBelow.value"
                (ngModelChange)="inputChange($event, 'entity.editIndoorTemperatureBelow')"
                name="temp_below"
              />
              <span> {{entity.editIndoorTemperatureBelow.unit?.name}}</span>
              and
              <input
                type="number"
                [(ngModel)]="entity.editIndoorTemperatureAbove.value"
                (ngModelChange)="inputChange($event, 'entity.editIndoorTemperatureAbove')"
                name="temp_above"
              />
              <span> {{entity.editIndoorTemperatureAbove.unit?.name}}</span>
            </div>
          </div>


          <div *ngSwitchDefault></div>
        </div>


        <div *ngIf="options?.edit || entity.hasHeatIndex" class="row">
          <div class="col-4"><b>HeatIndex Above</b></div>
          <div *ngIf="options?.edit" class="col-1">
            <label class="switch switch-3d switch-primary">
              <input checked="" class="switch-input switch-primary" (change)="toggleOptional($event)" [(ngModel)]="entity.hasHeatIndex" type="checkbox">
              <span class="switch-slider"></span>
            </label>
          </div>
          <div *ngIf="!options?.edit" class="col-8"><b>{{entity.editHeatIndexAbove.value}}</b><span> {{entity.editHeatIndexAbove.unit?.name}}</span></div>
          <div *ngIf="options?.edit" class="col-7">
            <ng-container  *ngIf="entity.hasHeatIndex">
              <input
                type="number"
                [(ngModel)]="entity.editHeatIndexAbove.value"
                (ngModelChange)="inputChange($event, 'heatIndexAbove')"
                name="heat_index"
              />
              <span> {{entity.editHeatIndexAbove.unit?.name}}</span>
            </ng-container>
          </div>
        </div>


        <div *ngIf="options?.edit || entity.hasWindChill" class="row">
          <div class="col-4"><b>WindChill Below</b></div>
          <div *ngIf="options?.edit" class="col-1">
            <label class="switch switch-3d switch-primary">
              <input checked="" class="switch-input switch-primary" (change)="toggleOptional($event)" [(ngModel)]="entity.hasWindChill" type="checkbox">
              <span class="switch-slider"></span>
            </label>
          </div>
          <div *ngIf="!options?.edit" class="col-8"><b>{{entity.editWindChillBelow.value}}</b><span> {{entity.editWindChillBelow.unit?.name}}</span></div>
          <div *ngIf="options?.edit" class="col-7">
            <ng-container  *ngIf="entity.hasWindChill">
              <input
                type="number"
                [(ngModel)]="entity.editWindChillBelow.value"
                (ngModelChange)="inputChange($event, 'windChillBelow')"
                name="wind_chill"
              />
              <span> {{entity.editWindChillBelow.unit?.name}}</span>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    