
    <div class="data-management-generic-widget  card">
      <div class="card-header">
        <div class="row mb-5">
          <div class="col text-center border-bottom">
            <span class="display-4">{{ DataManagementLogicalWidgetEnum[entity.data_management_logical_widget_type()] }}</span>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <pre>{{entity.toJson()| json}}</pre>
          </div>
        </div>
      </div>
    </div>