import { Component, Inject, Input } from '@angular/core';
import { AppNotificationEntity }    from '../../entities/app-notification.entity';
import { AppNotificationRepo }      from '../../repos/app-notification.repo';
import { DeviceCategoryEnum }       from '../../enums/device-category.enum';
import { AuthService }              from '../../services/auth.service';
import { DeviceDefinitionRepo }     from '../../repos/device/definition.repo';

@Component({
  templateUrl: './show.component.html',
})
export class AppNotificationComponent {
  public e: AppNotificationEntity;
  public categories = [];
  public selectedCategory = 1;
  public selectedColor = "white";
  public message = "";
  public notifications = null;
  public dc = null;
  public catArrayObj = {};

  constructor(public repo: AppNotificationRepo, public aus: AuthService, public deviceRepo: DeviceDefinitionRepo) {
    this.e = repo.entity(null);
    //Turn enums into a more useable format
    let keys = Object.keys(DeviceCategoryEnum);
    let array = keys.slice(keys.length/2);
    let array2= keys.splice(0,keys.length/2)
    array.forEach((item,index) => {
      this.categories.push({"val":array2[index],"display":array2[index]+") "+item});
      this.catArrayObj[item] = [];
    });
    this.categories.push({"val":"all","display":"All Categories"});
    this.updateNotifications();
  }

  //Connect to Firebase and get the values
  updateNotifications() {
    this.aus.getDatabaseObject("notifications/category").snapshotChanges().subscribe(items => {
        let keys = items.map(item=>item.key); let vals = items.map(item=>item.payload.val().info); let colors = items.map(item=>item.payload.val()["info-color"]);
        let obj = {};
        obj["#)CategoryName"] = {"Color": "Message"};
        for(let x=0;x<keys.length;x++) {
          //Creating arrays and formatting

          //If category is the custom added category in constructor, handle it differently.
          let v = keys[x] == "all" ? "All) All Categories" : keys[x]+ ") "+ DeviceCategoryEnum[keys[x]];
          let c = colors[x] ? colors[x] : "white";
          let o = {};
          o[c] = vals[x];
          obj[v] = o;
        }
        this.notifications = obj;
    });
  }

  postCategoryMessage(e) {
    e.preventDefault();
    this.e.postMessage(this.selectedCategory, this.message, this.selectedColor).then((res)=> {
      this.updateNotifications();
    })
  }

  //Get device categories and such for a list of what devices are in what category
  listDevices(e) {
    e.preventDefault();
    let list = this.categories.slice(0);
    this.deviceRepo.getListPromise().then((u: any) => {
      for(let x=0;x<u["items"].length;x++) {
        //If category has no devices, its undefined, define it
        if (!this.catArrayObj[u["items"][x].category.name]) {
          this.catArrayObj[u["items"][x].category.name] = [u["items"][x].internalName];
        } else { //Its defined as an array with 1 element, push the new one on
          this.catArrayObj[u["items"][x].category.name].push(u["items"][x].internalName);
        }
      }
    this.dc = true;
    });
  }

}
