<page-header [section]="'device-type-mapping'">
  <page-title>Device Type Mapping</page-title>
  <page-blurb>View device types entries and manage device type mapping entries by creating new range mapping entries depending on mapping series / batch, or edit existing mapping entries.</page-blurb>
</page-header>

<spinner *ngIf="!entries"></spinner>

<div *ngIf="entries">

  <div class="row" *ngFor="let s of series">
    <div class="col">
      <div class="card">
        <div class="card-header bg-primary">

          <span class="display-4">Device Type Mapping: Series [{{s}}]</span>

        </div>
        <div class="card-body m-2">


          <div class="row" *ngFor="let b of batches[s]">
            <div class="col">
              <div class="card">
                <div class="card-header bg-success">

                  <span class="display-5">Device Type Mapping: Series [{{s}}]: Batch [{{b}}]</span>


                  <div class="card-header-actions">
                    <span class=""><i (click)="add($event, newMapModal, s, b)" class="text-black-50 fa fa-lg fa-plus"></i></span>
                  </div>


                </div>
                <div class="card-body m-1">
                  <div class="row mb-4">
                    <div class="col text-center">
                      <button class="btn btn-primary" (click)="add($event, newMapModal, s, b)" type="button">
                        <i class="pr-2 fa fa-lg fa-plus"></i>&nbsp;Add Mapping
                      </button>
                    </div>
                  </div>

                  <device-range-widget *ngFor="let entry of entries[s][b]; let i = index" (widgetEvent)="eventHandler($event, newMapModal)"  [entity]="entry" [layout]="'table'" [options]="{'batch': b, 'series': s, 'index': i}"></device-range-widget>
                </div>
                <div class="card-footer">


                  <div class="row">
                    <div class="col text-center">
                      <button class="btn btn-primary" (click)="add($event, newMapModal, s, b)" type="button">
                        <i class="pr-2 fa fa-lg fa-plus"></i>&nbsp;Add Mapping
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</div>




<!--                                                        -->
<!--                    Pop-Up Menu                         -->
<!--                                                        -->
<ng-template #newMapModal>
  <modal-widget [entity]="currentModal" [layout]="'shadow_box'" [options]="{}"></modal-widget>
</ng-template>
