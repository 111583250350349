
<div *ngIf="!entity">
  <widget-spinner></widget-spinner>
</div>
<div *ngIf="entity">
  <div [ngSwitch]="template()">
    <!-- User Related -->

    <!-- CMS -->
    <embed-cms-ds *ngSwitchCase="'embed-cms-ds'" [entity]="entity" [layout]="layout" [options]="options"></embed-cms-ds>
    <embed-cms-alert *ngSwitchCase="'embed-cms-alert'" [entity]="entity" [layout]="layout" [options]="options"></embed-cms-alert>

    <!-- Device Related -->
    <embed-device *ngSwitchCase="'embed-device'" [entity]="entity" [layout]="layout" [options]="options"></embed-device>
    <embed-device-type *ngSwitchCase="'embed-device-type'" [entity]="entity" [layout]="layout" [options]="options"></embed-device-type>
    <embed-device-type-field-entry
      *ngSwitchCase="'embed-device-type-field-entry'"
      [entity]="entity"
      [layout]="layout"
      [options]="options">
    </embed-device-type-field-entry>
    <embed-device-field *ngSwitchCase="'embed-device-field'" [entity]="entity" [layout]="layout" [options]="options"></embed-device-field>

    <!-- Logs -->
    <log-set-widget *ngSwitchCase="'embed-logs'" [entity]="entity" [layout]="layout" [options]="options"></log-set-widget>
    <log-widget *ngSwitchCase="'embed-entity-log'" [entity]="entity" [layout]="layout" [options]="options"></log-widget>

    <embed-entity-log-report-binary
      *ngSwitchCase="'embed-entity-log-report-binary'"
      [entity]="entity" [layout]="layout" [options]="options"></embed-entity-log-report-binary>
    <embed-entity-log-report-binary-details
      *ngSwitchCase="'embed-entity-log-report-binary-details'"
      [entity]="entity" [layout]="layout" [options]="options"></embed-entity-log-report-binary-details>
    <embed-entity-log-stage-one-data
      *ngSwitchCase="'embed-entity-log-stage-one-data'"
      [entity]="entity" [layout]="layout" [options]="options"></embed-entity-log-stage-one-data>
    <embed-entity-log-wake
      *ngSwitchCase="'embed-entity-log-wake'"
      [entity]="entity" [layout]="layout" [options]="options"></embed-entity-log-wake>
    <embed-entity-log-stream
      *ngSwitchCase="'embed-entity-log-stream'"
      [entity]="entity" [layout]="layout" [options]="options"></embed-entity-log-stream>
    <embed-entity-log-extraction-warn
      *ngSwitchCase="'embed-entity-log-extraction-warn'"
      [entity]="entity" [layout]="layout" [options]="options"></embed-entity-log-extraction-warn>
    <embed-request-logs
      *ngSwitchCase="'embed-request-logs'"
      [entity]="entity" [layout]="layout" [options]="options"></embed-request-logs>
    <embed-request-log
      *ngSwitchCase="'embed-request-log'"
      [entity]="entity" [layout]="layout" [options]="options"></embed-request-log>

    <!-- Firmware -->


    <firmware-link-widget
      *ngSwitchCase="'embed-firmware-link-v2'"
      [entity]="entity" [layout]="layout" [options]="options"></firmware-link-widget>
    <firmware-definition-widget
      *ngSwitchCase="'embed-firmware-definition'"
      [entity]="entity" [layout]="layout" [options]="options"></firmware-definition-widget>
    <firmware-approval-widget
      *ngSwitchCase="'embed-firmware-approval'"
      [entity]="entity" [layout]="layout" [options]="options"></firmware-approval-widget>
    <firmware-release-widget
      *ngSwitchCase="'embed-firmware-release'"
      [entity]="entity" [layout]="layout" [options]="options"></firmware-release-widget>
    <embed-firmware-report
      *ngSwitchCase="'embed-firmware-report'"
      [entity]="entity" [layout]="layout" [options]="options"></embed-firmware-report>
    <embed-mcu-report
      *ngSwitchCase="'embed-mcu-report'"
      [entity]="entity" [layout]="layout" [options]="options"></embed-mcu-report>
    <embed-wifi-and-mcu-report
      *ngSwitchCase="'embed-wifi-and-mcu-report'"
      [entity]="entity" [layout]="layout" [options]="options"></embed-wifi-and-mcu-report>

    <!-- MCU -->
    <embed-mcu-fw-link-v2
      *ngSwitchCase="'embed-mcu-fw-link-v2'"
      [entity]="entity" [layout]="layout" [options]="options"></embed-mcu-fw-link-v2>
    <embed-mcu-firmware-definition
      *ngSwitchCase="'embed-mcu-firmware-definition'"
      [entity]="entity" [layout]="layout" [options]="options"></embed-mcu-firmware-definition>
    <embed-mcu-firmware-release
      *ngSwitchCase="'embed-mcu-firmware-release'"
      [entity]="entity" [layout]="layout" [options]="options"></embed-mcu-firmware-release>



    <embed-firmware-target
      *ngSwitchCase="'embed-firmware-target'"
      [entity]="entity" [layout]="layout" [options]="options"></embed-firmware-target>

    <!-- Data Stream Cards -->
    <embed-ds-card
      *ngSwitchCase="'embed-ds-card'"
      [entity]="entity" [layout]="layout" [options]="options"></embed-ds-card>
    <embed-ds-device-card
      *ngSwitchCase="'embed-ds-device-card'"
      [entity]="entity" [layout]="layout" [options]="options"></embed-ds-device-card>
    <embed-ds-diagnostic-card
      *ngSwitchCase="'embed-ds-diagnostic-card'"
      [entity]="entity" [layout]="layout" [options]="options"></embed-ds-diagnostic-card>
    <embed-ds-media-card
      *ngSwitchCase="'embed-ds-media-card'"
      [entity]="entity" [layout]="layout" [options]="options"></embed-ds-media-card>
    <embed-ds-nws-card
      *ngSwitchCase="'embed-ds-nws-card'"
      [entity]="entity" [layout]="layout" [options]="options"></embed-ds-nws-card>
    <embed-ds-weather-card
      *ngSwitchCase="'embed-ds-weather-card'"
      [entity]="entity" [layout]="layout" [options]="options"></embed-ds-weather-card>
    <embed-ds-time-card
            *ngSwitchCase="'embed-ds-time-card'"
            [entity]="entity" [layout]="layout" [options]="options"></embed-ds-time-card>
    <embed-ds-generic-card
      *ngSwitchCase="'embed-ds-generic-card'"
      [entity]="entity" [layout]="layout" [options]="options"></embed-ds-generic-card>



    <!-- Default -->
    <embed-default *ngSwitchDefault [entity]="entity" [layout]="layout" [options]="options"></embed-default>
  </div>
</div>
