import {HttpClient} from '@angular/common/http';
import {FirebaseAuthService} from '../../../noizu/services/firebase-auth.service';
import {DataStreamLogWidgetEnum, LogWidgetEnum, NotificationLogWidgetEnum} from '../../../enums';
import {EntityLogEntity} from '../entity-log.entity';

export class DataStreamEntityLogEntity extends EntityLogEntity {
  public log_type: DataStreamLogWidgetEnum = DataStreamLogWidgetEnum.DATA_STREAM_LOG_WIDGET__GENERIC;
  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    switch (this.topic) {
      case 'data_stream':
        this.log_type = DataStreamLogWidgetEnum.DATA_STREAM_LOG_WIDGET__DATA_STREAM;
        break;
      default:
        this.log_type = DataStreamLogWidgetEnum.DATA_STREAM_LOG_WIDGET__GENERIC;
    }

  } // end constructor

  log_widget_type(): LogWidgetEnum | null {
    return LogWidgetEnum.LOG_WIDGET__DATA_STREAM;
  }

  data_stream_log_widget_type(): DataStreamLogWidgetEnum  {
    return this.log_type;
  }

  public brief() {
    if (!this._brief) {
      switch (this.data_stream_log_widget_type()) {
        case DataStreamLogWidgetEnum.DATA_STREAM_LOG_WIDGET__DATA_STREAM:
          this._brief = `Data Stream (${this.subtopic})`;
          break;
        default:
          this._brief = 'Notification Other';
      }
    }
    return this._brief;
  }

} // end Package
