import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToasterService} from 'angular2-toaster';
import {WidgetBase} from '../widget-base';
import {PinEntity, PinGroupLogicalWidget, UserEntity} from '../../../entities';
import {PinWidgetEventStruct} from './pin-widget-event.struct';
import {WidgetEventStruct} from '../../widget-event.struct';
import {LogicalWidgetEventStruct} from '../logical';

@Component({
  selector: 'pin-widget',
  template: `
     <ng-container [ngSwitch]="layout">
       <ng-container *ngSwitchCase="'table'">

         <div class="pin-widget row" *ngIf="options['index'] == 0 && options['header']">
           <ng-container *ngFor="let column of options.columns">
             <ng-container [ngSwitch]="column">
               <div class="col div-table-th" *ngSwitchCase="'identifier'">Id</div>
               <div class="col div-table-th" *ngSwitchCase="'name'">Name</div>
               <div class="col div-table-th" *ngSwitchCase="'description'">Description</div>
               <div class="col div-table-th" *ngSwitchCase="'group'">Group</div>
               <div class="col-6 border-left border-right div-table-th" *ngSwitchCase="'pin'">
                 <div class="nested">
                   <widget (widgetEvent)="forwardInnerEvent($event)"  [entity]="entity.subject" [options]="options" [layout]="'nested-header'"></widget>
                 </div>
               </div>
               <div class="col div-table-th" *ngSwitchCase="'actions'">Actions</div>
             </ng-container>
           </ng-container>
         </div>


         <div class="row">
         <ng-container *ngFor="let column of options.columns">
           <ng-container [ngSwitch]="column">
             <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'identifier'">
               <a [routerLink]="['/portal/pins/show/'  + entity.identifier]">{{entity?.identifier}}</a>
             </div>

             <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'name'">
               {{entity.name}}
             </div>

             <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'description'">
               {{entity.description}}
             </div>

             <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'group'">
               {{entity.group}}
             </div>

             <div class="col-6 border-left border-right" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'pin'" >
                <widget (widgetEvent)="forwardInnerEvent($event)"  [entity]="entity.subject" [options]="options" [layout]="'nested-table'"></widget>
             </div>

             <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'actions'" >
               [Actions]
             </div>


           </ng-container>
         </ng-container>
         </div>
       </ng-container>

       <ng-container *ngSwitchCase="'shadowbox'">

         <ng-container *ngIf="!options?.edit">
           <div class="row" *ngIf="entity.identifier">
             <div class="col-3"><strong>Identifier</strong></div>
             <div class="col">{{entity.identifier}}</div>
           </div>
           <div class="row">
             <div class="col-3"><strong>Name</strong></div>
             <div class="col">{{entity.name}}</div>
           </div>
           <div class="row">
             <div class="col-3"><strong>Description</strong></div>
             <div class="col"><textarea class="form-control" readonly [ngModel]="entity.description"></textarea></div>
           </div>
           <div class="row">
             <div class="col-3"><strong>Group</strong></div>
             <div class="col">{{entity.group}}</div>
           </div>
           <div class="row">
             <div class="col-3"><strong>Created On</strong></div>
             <div class="col">{{entity.created_on | date: 'short'}}</div>
           </div>
         </ng-container>

         <ng-container *ngIf="options?.edit">

           <div class="row" *ngIf="entity.validation?.messages['common']">
             <div class="col">
               <div class="alert alert-warning">{{entity.validation.messages.common}}</div>
             </div>
           </div>

           <div class="row mb-2" *ngIf="entity.identifier">
             <div class="col-3"><strong>Identifier</strong></div>
             <div class="col">{{entity.identifier}}</div>
           </div>

           <div class="row" *ngIf="entity.validation?.messages['name']">
             <div class="col">
               <div class="alert alert-warning">{{entity.validation.messages.name}}</div>
             </div>
           </div>
           <div class="row mb-2">
             <div class="col-3"><strong>Name <i class="fa fa-warning text-danger" *ngIf="entity.validation?.messages['name']"></i></strong></div>
             <div class="col"><input class="form-control" type="text" [(ngModel)]="entity.name" /></div>
           </div>

           <div class="row" *ngIf="entity.validation?.messages['description']">
             <div class="col">
               <div class="alert alert-warning">{{entity.validation.messages.description}}</div>
             </div>
           </div>
           <div class="row mb-2">
             <div class="col-3"><strong>Description <i class="fa fa-warning text-danger" *ngIf="entity.validation?.messages['description']"></i></strong></div>
             <div class="col"><textarea class="form-control" [(ngModel)]="entity.description"></textarea></div>
           </div>

           <div class="row" *ngIf="entity.validation?.messages['group']">
             <div class="col">
               <div class="alert alert-warning">{{entity.validation.messages.group}}</div>
             </div>
           </div>
           <div class="row mb-2">
             <div class="col-3"><strong>Group <i class="fa fa-warning text-danger" *ngIf="entity.validation?.messages['group']"></i></strong></div>
             <div class="col">
               <widget [entity]="select_group"  (widgetEvent)="handleEvent($event)" [layout]="'standard'" [options]="{edit: true}"></widget>
             </div>
           </div>

           <div class="row" *ngIf="entity.created_on">
             <div class="col-3"><strong>Created On</strong></div>
             <div class="col">{{entity.created_on | date: 'short'}}</div>
           </div>
         </ng-container>




       </ng-container>

       <ng-container *ngSwitchCase="'thumb'">
         <div class="pin-widget card">
           <div class="card-header text-center p-1 m-0">
             #{{entity.identifier}} {{entity.name}} <span class="float-right card-header-action"><i (click)="delete($event)" class="fa fa-trash"></i></span>
           </div>
           <div class="card-body p-1 m-0">
             <div class="row">
               <div class="col">
                 <div *ngIf="isString(entity.subject)">
                   {{entity.subject}}
                 </div>
                 <div *ngIf="entity.subject && !isString(entity.subject)">
                   <widget [entity]="entity.subject" [layout]="'mini'" [options]="{}"></widget>
                 </div>
               </div>
             </div>
             <div class="row" *ngIf="entity.description">
               <div class="col"><textarea readonly class="form-control" [ngModel]="entity.description"></textarea> </div>
             </div>
           </div>
         </div>
       </ng-container>

       <ng-container *ngSwitchDefault>
         <div class="pin-widget card">
           <div class="card-header text-center p-1 m-0">
             #{{entity.identifier}} {{entity.name}} <span class="float-right card-header-action"><i (click)="delete($event)" class="fa fa-trash"></i></span>
           </div>
           <div class="card-body p-1 m-0">


             <div class="row" *ngIf="entity.description">
               <div class="col"><textarea readonly class="form-control" [ngModel]="entity.description"></textarea> </div>
             </div>
             <div class="row">
               <div class="col">
                 <div *ngIf="isString(entity.subject)">
                   {{entity.subject}}
                 </div>
                 <div *ngIf="entity.subject && !isString(entity.subject)">
                   <widget [entity]="entity.subject" [layout]="'mini'" [options]="{}"></widget>
                 </div>
               </div>
             </div>

           </div>
         </div>
       </ng-container>
     </ng-container>
  `
})
export class PinWidgetComponent extends WidgetBase implements OnInit {
  public editMode = false;
  @Input() layout: string = null;
  @Input() entity: PinEntity;
  @Input() options: any;
  @Output() widgetEvent = new EventEmitter<PinWidgetEventStruct>();
  forwardEvent(e, widgetEvent: PinWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }
  forwardInnerEvent(widgetEvent: WidgetEventStruct) {
    //this.widgetEvent.emit(widgetEvent);
  }
  select_group = null;

  constructor(public toasterService: ToasterService) {
    super();
  }

  handleEvent(event) {
    if (event instanceof LogicalWidgetEventStruct) {
      if (event.event_type == 'pin_group_selection_made') {
        this.entity.group = event.event_body.sref;
      }
    }
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.select_group = new PinGroupLogicalWidget();
    this.select_group.selected = this.entity.group;

  }

  delete(event){
    event.preventDefault();
    this.entity.delete()
  }

}
