import {NoizuStruct} from '../../noizu/structs/noizu-struct';
import {DataStreamTimeLink} from './link/time-link';

export class DataStreamTimeCardEntity extends NoizuStruct {
  public _kind = 'GoldenRatio.DataStream.Card.TimeCard.V1_1';
  public link: DataStreamTimeLink;
  public modified = false;

  constructor(json) {
    super();
    if (json['link']) {
      this.link = new DataStreamTimeLink(json['link']);
    } else {
      this.link = new DataStreamTimeLink({});
    }
  } // end constructor

  toJson(options: any = {}) {
    return {
      link: this.link ? this.link.toJson() : null,
    }
  }

  validate() {
    if (this.link) {
      return this.link.validate();
    } else  {
      return [{error: 'NoLink', message: 'Time Link Details Missing'}];
    }
  }

  widget() {
    return 'embed-ds-time-card';
  }
} // End Package
