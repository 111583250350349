
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { ElixirEntity } from './elixir.entity';
import { FirebaseAuthService } from '../noizu/services/firebase-auth.service';
import {DataStreamGenericCardEntity} from './data-stream/generic-card.entity';
import {DataStreamMediaCardEntity} from './data-stream/media-card.entity';
import {DataStreamWeatherCardEntity} from './data-stream/weather-card.entity';
import {DataStreamDiagnosticCardEntity} from './data-stream/diagnostic-card.entity';
import {DataStreamNwsCardEntity} from './data-stream/nws-card.entity';
import {DataStreamDeviceCardEntity} from './data-stream/device-card.entity';
import {DataStreamTimeCardEntity} from './data-stream/time-card.entity';

export class DataStreamCardEntity extends ElixirEntity {
  public _singular = 'card';
  public _kind = 'GoldenRatio.DataStream.CardEntity';
  public _sref_module = 'card';
  public definition: any = null; // data-stream/card...
  public weight: any = null;
  public see_app: any = null;
  public scheduling: any = null;
  public owner: any = null;
  public name: any = null;
  public feed: any = null;
  public enabled: any = null;
  public featured: any = null;
  public description: any = null;

  public _ingv_version = 'v1.2';

  public get modified() {
    if (this._modified) {
      return true;
    } else if (this.definition) {
      return this.definition.modified;
    } else {
      return false;
    }
  }
  public set modified(v: boolean) {this._modified = v; }

  static DefinitionKind(type) {
    switch (type) {
      case 'media':
        return 'Elixir.GoldenRatio.DataStream.Card.MediaCard.V1_1';
      case 'weather':
        return 'Elixir.GoldenRatio.DataStream.Card.WeatherReadingCard.V1_1';
      case 'diagnostic':
        return 'Elixir.GoldenRatio.DataStream.Card.DiagnosticCard.V1_1';
      case 'nws':
        return 'Elixir.GoldenRatio.DataStream.Card.NationalWeatherServiceCard.V1_1';
      case 'device':
        return 'Elixir.GoldenRatio.DataStream.Card.VirtualDeviceCard.V1_1';
      case 'time':
        return 'Elixir.GoldenRatio.DataStream.Card.TimeCard.V1_1';
      default:
        return 'Elixir.GoldenRatio.DataStream.CardEntity';
    }
  }

  static DefinitionFactory(json) {
    switch (json['kind']) {
      case 'GoldenRatio.DataStream.Card.MediaCard.V1_1':
      case 'Elixir.GoldenRatio.DataStream.Card.MediaCard.V1_1':
        return new DataStreamMediaCardEntity(json);

      case 'GoldenRatio.DataStream.Card.WeatherReadingCard.V1_1':
      case 'Elixir.GoldenRatio.DataStream.Card.WeatherReadingCard.V1_1':
        return new DataStreamWeatherCardEntity(json);

      case 'GoldenRatio.DataStream.Card.DiagnosticCard.V1_1':
      case 'Elixir.GoldenRatio.DataStream.Card.DiagnosticCard.V1_1':
        return new DataStreamDiagnosticCardEntity(json);

      case 'GoldenRatio.DataStream.Card.NationalWeatherServiceCard.V1_1':
      case 'Elixir.GoldenRatio.DataStream.Card.NationalWeatherServiceCard.V1_1':
        return new DataStreamNwsCardEntity(json);

      case 'GoldenRatio.DataStream.Card.VirtualDeviceCard.V1_1':
      case 'Elixir.GoldenRatio.DataStream.Card.VirtualDeviceCard.V1_1':
        return new DataStreamDeviceCardEntity(json);

      case 'GoldenRatio.DataStream.Card.TimeCard.V1_1':
      case 'Elixir.GoldenRatio.DataStream.Card.TimeCard.V1_1':
        return new DataStreamTimeCardEntity(json);

      default:
        return new DataStreamGenericCardEntity(json);
    }
  }

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    this.identifier = json['identifier'];
    this.weight = json['weight'];
    this.see_app = json['see_app?'];
    this.scheduling = json['scheduling'];
    this.owner = json['owner'];
    this.name = json['name'];
    this.feed = json['feed'];
    this.enabled = json['enabled?'];
    this.featured = json['featured?'];
    this.description = json['description'];
    this.definition = DataStreamCardEntity.DefinitionFactory(json);
  } // end constructor

  refresh(data) {
    this.identifier = data['identifier'];
    this.weight = data['weight']
    return super.refresh(data);
  }

  toJson(options: any = {}) {
    let json = {};
    if (this.definition) {
      json = this.definition.toJson(options);
    }
    json['kind'] = DataStreamCardEntity.DefinitionKind(this.type());
    json['identifier'] = this.identifier;
    json['weight'] = this.weight;
    json['see_app?'] = this.see_app;
    json['scheduling'] = this.scheduling;
    json['owner'] = this.owner;
    json['name'] = this.name;
    json['feed'] = this.feed;
    json['enabled?'] = this.enabled;
    json['featured?'] = this.featured;
    json['description'] = this.description;
    return json;
  }

  updateEndpoint() {
    return `${this.apiBase()}/displays/${this.feed}/data-stream/${this.sref()}`;
  }

  createEndpoint() {
    return `${this.apiBase()}/displays/${this.feed}/data-stream`;
  }

  deleteEndpoint() {
    return `${this.apiBase()}/displays/${this.feed}/data-stream/${this.sref()}`;
  }

  actionEndpoint(action) {
    return `${this.apiBase()}/displays/${this.feed}/data-stream/${this.sref()}/${action}`
  }

  enable() {
    return this._put(this.actionEndpoint('enable'), {}, (data, resolve) => {
        this.enabled = true;
        this.weight = data['weight'] ? data['weight'] : this.weight;
        resolve(data);
      }, {}
    );
  }

  disable() {
    return this._put(this.actionEndpoint('disable'), {}, (data, resolve) => {
        this.enabled = false;
      this.weight = data['weight'] ? data['weight'] : this.weight;
        resolve(data);
      }, {}
    );
  }

  feature() {
    return this._put(this.actionEndpoint('feature'), {}, (data, resolve) => {
        this.featured = false;
      this.weight = data['weight'] ? data['weight'] : this.weight;
        resolve(data);
      }, {}
    );
  }

  unfeature() {
    return this._put(this.actionEndpoint('unfeature'), {}, (data, resolve) => {
        this.featured = false;
      this.weight = data['weight'] ? data['weight'] : this.weight;
        resolve(data);
      }, {}
    );
  }

  validate() {
    const errors = [];
    if (!this.owner) {
      errors.push({error: 'NoOwner', message: 'Owner Not set'});
    }

    if (!this.definition) {
      errors.push({error: 'NoDef', message: 'Content Not Set'});
    }

    if (this.definition) {
      return errors.concat(this.definition.validate());
    } else {
      return errors;
    }
  }

  type() {
    if (this.definition) {
      if (this.definition instanceof DataStreamMediaCardEntity) {
        return 'media';
      }
      if (this.definition instanceof DataStreamWeatherCardEntity) {
        return 'weather';
      }
      if (this.definition instanceof DataStreamGenericCardEntity) {
        return 'generic';
      }
      if (this.definition instanceof DataStreamDiagnosticCardEntity) {
        return 'diagnostic';
      }
      if (this.definition instanceof DataStreamNwsCardEntity) {
        return 'nws';
      }
      if (this.definition instanceof DataStreamDeviceCardEntity) {
        return 'device';
      }
      if (this.definition instanceof DataStreamTimeCardEntity) {
        return 'time';
      }
    }
    return 'none';
  }

  widget() {
    return 'embed-ds-card';
  }

} // End Package
