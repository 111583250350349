

    <div class="modal-header">
      <h4 class="modal-title pull-left">{{entity?.title}}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="entity?.modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <widget-spinner *ngIf="entity?.processing"></widget-spinner>
      <div  *ngIf="!entity?.processing">
        <widget [entity]="entity?.subject" [layout]="entity?.embed_layout" [options]="entity?.embed_options"></widget>
      </div>
    </div>
    <div class="modal-footer">
      <button
        *ngIf="entity['callbacks']['cancel']"
        type="button"
        class="btn btn-secondary"
        (click)="entity.callbacks['cancel'].cb(entity, $event, entity?.modalRef, toasterService)"
        data-dismiss="modal">{{entity['callbacks']['cancel']['msg']}}</button>
      <button
        *ngIf="entity['callbacks']['confirm']"
        type="button"
        class="btn btn-danger"
        (click)="entity.callbacks['confirm'].cb(entity, $event, entity?.modalRef, toasterService)"
      >{{entity['callbacks']['confirm']['msg']}}</button>
    </div>
  