
    <div class="card ">
      <div class="card-header">
        <div class="row">
          <div class="col"><b class="text-left">{{title}}</b></div>
          <div class="col-2 text-right p-0 text-middle">
            <toggle-widget [options]="expand_settings"></toggle-widget>
          </div>
        </div>
      </div>
      <div class="card-body p-0"  [ngClass]="{'d-none': !expand_settings.toggle, 'pl-5': has_entries}" >
        <div *ngIf="is_map">
          <div class="row"   *ngFor="let s of entity.entities | keyvalue">
            <div class="col">
              <widget (widgetEvent)="forwardEvent($event)"  [entity]="s.value" [options]="{id: s.key, nested: true}" [layout]="layout"></widget>
            </div>
          </div>
        </div>
        <div *ngIf="!is_map">
          <div class="row"   *ngFor="let e of entity.entities">
            <div class="col">
              <widget (widgetEvent)="forwardEvent($event)"  [entity]="e" [options]="{id: e.identifier, nested: true}" [layout]="layout"></widget>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="!has_entries">
          <div class="col">
            <div class="alert m-0 alert-warning">No Entries</div>
          </div>
        </div>
      </div>
    </div>
  