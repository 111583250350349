import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ToasterService} from 'angular2-toaster';
import {AlertWindFacadeEntity} from '../../../../entities/alert';
import {AlertFacadeDefaultWidgetComponent} from './default.widget.component';
import {AlertFacadeWidgetEventStruct} from './alert-facade-widget-event.struct';

@Component({
  selector: 'alert-facade-wind-widget',
  template: `
    <div class="card card-accent-dark alert-facade-wind-widget">
      <div class="card-header">Wind Alert</div>
      <div class="card-body">
        <div class="row"><div class="col-4">Device</div><div class="col-8"><a [routerLink]="'/portal/devices/show/' + entity.device">{{entity.device}}</a></div></div>
        <div class="row"><div class="col-4">Association</div><div class="col-8">{{entity.deviceAssociation}}</div></div>
        <div class="row"><div class="col-12"><hr/></div></div>

        <div class="row" *ngIf="entity.windAbove"><div class="col-4">Wind Above</div>
          <div *ngIf="!options?.edit" class="col-8"><b>{{entity.windAbove.value}}</b><span> {{entity.windAbove.unit?.name}}</span></div>
          <div *ngIf="options?.edit" class="col-8">
            <input
              type="number"
              [(ngModel)]="entity.windAbove.value"
              (ngModelChange)="facadeChange($event)"
              name="wind_above"
            />
            <span> {{entity.windAbove.unit?.name}}</span>
          </div>
        </div>
        <div class="row" *ngIf="entity.windLevel != null">
          <div class="col-4">Wind Level</div>
            <div *ngIf="!options?.edit" class="col-8"><b>{{entity.windLevel}}</b></div>
            <div *ngIf="options?.edit" class="col-8">
              <ng-select
                placeholder="Wind Level"
                [items]="wind_levels"
                bindLabel="label"
                bindValue="value"
                [multiple]="false"
                [(ngModel)]="entity.windLevel"
                (ngModelChange)="facadeChange($event)"
              ></ng-select>
            </div>
        </div>

        <div *ngIf="entity.headingOne || entity.headingTwo || entity.headingThree || entity.headingFour || entity.headingFive || entity.headingSix || entity.headingSeven || entity.headingEight">


          <div *ngIf="entity.headingOne && entity.headingTwo && entity.headingThree && entity.headingFour && entity.headingFive && entity.headingSix && entity.headingSeven && entity.headingEight">
            <div class="row"><div class="col"><b>Any Heading</b></div></div>
          </div>

          <div *ngIf="!(entity.headingOne && entity.headingTwo && entity.headingThree && entity.headingFour && entity.headingFive && entity.headingSix && entity.headingSeven && entity.headingEight)">
            <div class="row"><div class="col-4">Heading One</div><div class="col-4"> <b>{{entity.headingOne|json}}</b>       </div></div>
            <div class="row"><div class="col-4">Heading Two</div><div class="col-4"> <b>{{entity.headingTwo|json}}</b>       </div></div>
            <div class="row"><div class="col-4">Heading Three</div><div class="col-4"> <b>{{entity.headingThree|json}}</b>       </div></div>
            <div class="row"><div class="col-4">Heading Four</div><div class="col-4"> <b>{{entity.headingFour|json}}</b>       </div></div>
            <div class="row"><div class="col-4">Heading Five</div><div class="col-4"> <b>{{entity.headingFive|json}}</b>       </div></div>
            <div class="row"><div class="col-4">Heading Six</div><div class="col-4"> <b>{{entity.headingSix|json}}</b>       </div></div>
            <div class="row"><div class="col-4">Heading Seven</div><div class="col-4"> <b>{{entity.headingSeven|json}}</b>       </div></div>
            <div class="row"><div class="col-4">Heading Eight</div><div class="col-4"> <b>{{entity.headingEight|json}}</b>       </div></div>
          </div>
        </div>


      </div>
    </div>
    `
})
export class EmbedAlertWindFacadeComponent extends AlertFacadeDefaultWidgetComponent{
  public editMode = false;
  @Input() layout: string = null;
  @Input() entity: AlertWindFacadeEntity;
  @Input() options: any;
  @Output() widgetEvent = new EventEmitter<AlertFacadeWidgetEventStruct>();
  forwardEvent(e, widgetEvent: AlertFacadeWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }

  public wind_levels: any = [
    {value: 0, label: 'Calm'},
    {value: 1, label: 'Light'},
    {value: 2, label: 'Windy'},
    {value: 3, label: 'Very Windy'},


  ]

  constructor(public toasterService: ToasterService) {
    super();
  }


}
