import {NoizuStruct} from '../../noizu/structs/noizu-struct';
import {NwsReading} from './nws-card.entity';

export class DataStreamGenericCardEntity extends NoizuStruct {
  public _kind = 'GoldenRatio.DataStream.CardEntity';
  public raw: any = null;
  public modified = false;

  constructor(json) {
    super();
    this.raw = json;
  } // end constructor

  validate() {
    const errors = [{error: 'Generic', message: 'Invalid Card Type'}];
    return errors;
  }

  toJson(options: any = {}) {
    // not fully supported;
    return this.raw;
  }

  widget() {
    return 'embed-ds-generic-card';
  }
} // End Package
