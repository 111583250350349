
import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from '../lacrosse.entity';
import { FirebaseAuthService }            from '../../noizu/services/firebase-auth.service';
import {NoizuStruct} from '../../noizu/structs/noizu-struct';


export enum SettingEncodingType {
  ENCODING_TYPE_STRING = 1,
  ENCODING_TYPE_LONG = 2,
  ENCODING_TYPE_DOUBLE = 3,
  ENCODING_TYPE_DATE = 4,
}

export class UserSettingEntity extends NoizuStruct {

  public encodingType: string; // enum
  public isNull: boolean;
  public longValue: number;
  public modifiedOn: Date;
  public setting: string;
  public value: string;


  constructor(json) {
    super();
    this.refresh(json)
  } // end constructor


  refresh(data) {
    this.encodingType = SettingEncodingType[data['encodingType']];
    this.isNull = data['isNull'];
    this.longValue = data['long'];
    this.modifiedOn = data['modifiedOn'];
    this.setting = data['setting'];
    this.value = data['value'];

    return this;
  }

} // end Package
