
import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from './lacrosse.entity';
import { FirebaseAuthService }            from '../noizu/services/firebase-auth.service';

export class ReportingEntity extends LacrosseEntity {
  public _kind = '';
  public data = {"gateways":null,"sensors":null};

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
  } // end constructor

  getGatewaysTally() {
    this.data.gateways = -1;
    this._get(`${this.ingvEndpoint()}/admin-tools/worker_tally/gateways`, (data) =>{
        console.log(data);
        this.data.gateways = data;
      }, {})
    .catch((error) => {
      console.log(error);
      this.data.gateways = null;
      });
  }

  getSensorsTally() {
    this.data.sensors = -1;
    this._get(`${this.ingvEndpoint()}/admin-tools/worker_tally/sensors`, (data) =>{
        console.log(data);
        this.data.sensors = data;
      }, {})
    .catch((error) => {
      console.log(error);
      this.data.sensors = null;
      });
  }


} // end Package
