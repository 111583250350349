
import { Component, Inject, Input, NgZone } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location }               from '@angular/common';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { DeviceDefinitionEntity } from '../../../entities/device/definition.entity';
import { DeviceDefinitionRepo } from '../../../repos/device/definition.repo';
import { DeviceFieldDefinitionRepo } from '../../../repos/device/field/definition.repo';
import { DeviceFieldDefinitionEntity } from '../../../entities/device/field/definition.entity';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap/modal';
import {AuthService} from "../../../services/auth.service";
import {ElixirEntityList} from '../../../noizu/structs';

@Component({
  templateUrl: './list.component.html',
})
export class DeviceDefinitionListComponent {
  public activeEntry: DeviceDefinitionEntity = null;
  public entries: ElixirEntityList;
  public fields: Array<DeviceFieldDefinitionEntity>;

  public deviceTypeListModalRef: BsModalRef;
  public currentModal: any;

  public clone: DeviceDefinitionEntity;
  public sidebar_options = {expand: true, collapsible: false, attributes: {expand: true, edit: false}, edit: false};
  public device_type_admin = false;

  public options = {header: true, sideBarWidth: 6, columns: 2, headers: ['identifier', 'internal', 'name', 'handle', 'description', 'actions']};

  public cloneTypeModal = {
    title: 'Clone Device Definition',
    msg: `Clone Definition.`,
    errorMsg: null,
    processing: false,
    form: 'new-attribute',
    confirmMsg: 'Save',
    confirm: ()  => {
      if (this.cloneDefinition()) {
        this.deviceTypeListModalRef.hide();
      }
    },

    cancelMsg: 'Cancel',
    cancel: ()  => {
      this.cloneTypeModal.errorMsg = null;
      this.deviceTypeListModalRef.hide()
    },
  };



  constructor(public repo: DeviceDefinitionRepo, public fieldRepo: DeviceFieldDefinitionRepo, public zone: NgZone, public modalService: BsModalService,  public authService: AuthService) {
    this.zone.run(
      () => {
        if (this.authService.loadedPromise) {
          this.authService.loadedPromise.then( () => {
            if (this.authService.userHasPermission('device_type_admin') || this.authService.userHasPermission('firmware_admin')) {
              this.device_type_admin = true;
              this.sidebar_options.edit = true;
              this.sidebar_options.attributes.edit = true;
            }
          });
        }

        this.authService.userHasPermissionPromise("device_type_admin").then((r) => {
          this.options['edit']  = r;
        });

        this.repo.getListPromise({"resultsPerPage": 1024, set: true}).then((u: any) => {this.entries = u;});
        this.fieldRepo.getListPromise().then((u: any) => {this.fields = u.items;});
      }
    )
  }

  add(e) {
    e.preventDefault();
    this.entries["items"].push(this.repo.entity({id: "new"}));
  }


  public cloneDefinition() {
    this.clone.update();
    return true;
  }

  public showCloneModal(modal, entry: DeviceDefinitionEntity, event) {
    event.preventDefault();
    this.clone = entry.deepCopy();
    this.clone.identifier = "new";
    this.clone.handle = null;
    this.currentModal = this.cloneTypeModal;
    let config: ModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    this.deviceTypeListModalRef = this.modalService.show(modal, config);
  }

  public viewDefinition(entry, event) {
    event.preventDefault();
    //this.router.navigateByUrl(`/portal/devices/show/${device.identifier}`);
    if (this.activeEntry == entry) {
      this.activeEntry = null;
    } else {
      this.activeEntry = null;
      this.activeEntry = entry;
    }
  }

}
