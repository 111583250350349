
import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from '../lacrosse.entity';
import { FirebaseAuthService }            from '../../noizu/services/firebase-auth.service';
import {UserSettingEntity} from './setting.entity';

export class UserSettingsEntity extends LacrosseEntity {
  public owner;
  public settings: Array<UserSettingEntity>;

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    this.refresh(json)
  } // end constructor


  refresh(data) {
    super.refresh(data);
    this.owner = data['ownerId'];
    let settings = [];
    data['settings'].forEach((v) => {
      settings.push(new UserSettingEntity(v))
    });
    this.settings = settings;
    return this;
  }

} // end Package
