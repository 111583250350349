import { Component, Input, Inject } from '@angular/core';
import {DataStreamTimeCardEntity} from '../../../entities/data-stream/time-card.entity';
import {DataStreamCardEntity} from '../../../entities/data-stream-card.entity';
import {TimeZoneOptions} from '../../../structs/time_zone_options';
import {TimeOption, LanguageOption} from '../../../entities/data-stream/link/time-link';

@Component({
  selector: 'embed-ds-time-card',
  template: `
      <div class="embed-ds-time-card" *ngIf="!editMode">
          <div class="row">
              <div class="col font-xl"><b>Type:</b> {{entity.link.linkType()}}</div>
              <div class="col font-xl"><b>Language:</b> {{entity.link.linkLanguage()}}</div>
          </div>
          <div class="row">
              <div class="col font-xl"><b>TimeZone:</b> {{entity.link.time_zone}}</div>
          </div>
      </div>
      <div class="embed-ds-time-card" *ngIf="editMode">
          <div class="row">
              <div class="col-12">
                  <div class="card mb-0 pb-0 border-warning">
                      <div class="card-body" *ngIf="entity">
                          <!-- Type Specific -->
                          <div class="row"><div class="col-4"><b>Type</b></div><div class="col-8">
                            <ng-select
                              placeholder="Link Type"
                              [name]="'[link]type'"
                              [items]="linkOptions"
                              [multiple]="false"
                              [(ngModel)]="entity.link.type"
                              bindLabel="text"
                              bindValue="value"
                              (ngModelChange)="setLink($event)"
                            ></ng-select>


                          </div></div>

                          <div class="row"><div class="col-4"><b>Language</b></div><div class="col-8">

                            <ng-select
                              placeholder="Link Language"
                              [name]="'[link]language'"
                              [multiple]="false"
                              [(ngModel)]="entity.link.language"
                              bindLabel="text"
                              bindValue="value"
                              (ngModelChange)="setLanguage($event)"
                              [items]="languageOptions"
                            ></ng-select>

                          </div></div>

                          <div class="row"><div class="col-4"><b>Time Zone</b></div><div class="col-8">

                            <ng-select
                              placeholder="Link TimeZone"
                              [name]="'[link]tz'"
                              [multiple]="false"
                              [(ngModel)]="entity.link.time_zone"
                              bindLabel="text"
                              bindValue="value"
                              (ngModelChange)="setTimeZone($event)"
                              [items]="timeZoneOptions"
                            ></ng-select>

                          </div></div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    `
})
export class EmbedDsTimeCardComponent {
  @Input() entity: DataStreamTimeCardEntity = null;
  @Input() layout: string = null;
  @Input() set options(options: any) {
    this._options = options;
    if ('editMode' in options) {
      this.editMode = options['editMode'];
    }
  }
  get options() {return this._options; }
  public timeZoneOptions = TimeZoneOptions;
  public editMode = false;
  public _options = {};
  public linkOptions = [];
  public languageOptions = [];

  constructor() {
    this.linkOptions = [];
    for (const key in TimeOption) {
      if (typeof TimeOption[key] === 'number') {
        this.linkOptions.push({value: TimeOption[key], text: key});
      }
    }

    this.languageOptions = [];
    for (const key in LanguageOption) {
      if (typeof LanguageOption[key] === 'number') {
        this.languageOptions.push({value: LanguageOption[key], text: key});
      }
    }

  }

  inlineEdit(event) {
    this.entity.modified = true;
  }

  setLink(event) {
    this.entity.modified = true;
  }

  setLanguage(event) {
    this.entity.modified = true;
  }

  setTimeZone(event) {
    this.entity.modified = true;
  }
}
