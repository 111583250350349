<spinner *ngIf="!entries"></spinner>

<div *ngIf="entries">



  <div class="row mb-5">
    <div class="col text-center border-bottom">
      <span class="display-4">Release Managers</span>
    </div>
  </div>

  <div class="row">
     <div class="col p-1">
       <div class="card">
         <div class="card-header">
           Release Managers
           <div class="card-header-actions">
             <i class="text-success fa fa-2x fa-plus-circle" (click)="createManager($event, mainModal)"></i>
           </div>
         </div>
         <div class="card-body">
           <div class="row">
             <div class="p-1" flex="grow" [ngClass]="{'col-3': !selectedEntity, 'col-6': selectedEntity}"  *ngFor="let entry of entries">
               <firmware-release-widget (widgetEvent)="eventHandler($event, mainModal)" [entity]="entry" [options]="{}" [layout]="'thumb'"> </firmware-release-widget>
             </div>
           </div>
         </div>
       </div>
     </div>

     <div class="col-6 p-1" *ngIf="selectedEntity">
       <firmware-release-widget   (widgetEvent)="eventHandler($event, mainModal)" [entity]="selectedEntity" [options]="{}" [layout]="'sidebar'"> </firmware-release-widget>
     </div>
  </div>
</div>



<!--                                                        -->
<!--                    Pop-Up Menu                         -->
<!--                                                        -->
<ng-template #mainModal>
  <modal-widget *ngIf="currentModal" (widgetEvent)="eventHandler($event, mainModal)" [entity]="currentModal" [layout]="'shadowbox'" [options]="{}"></modal-widget>
</ng-template>
