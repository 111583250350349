
    <div class="firmware-generic-widget row mb-5">
      <div class="col text-center border-bottom">
        <span class="display-4">{{ FirmwareWidgetEnum[entity.firmware_widget_type()] }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <pre>{{entity.toJson()| json}}</pre>
      </div>
    </div>
  