
import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from '../lacrosse.entity';
import { FirebaseAuthService }            from '../../noizu/services/firebase-auth.service';
import {WidgetEnum} from '../../enums';

export class UserChannelEntity extends LacrosseEntity {
  public default = "Default for None";
  public name: string | null = null;
  public note: string | null = null;
  public channelType = null;
  public fields : any = null;

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    this.refresh(json);
  } // end constructor

  refresh(data){
    super.refresh(data);
    this.name = data['name'];
    this.fields = data['fields'];
    this.channelType = data['type'];
    return this;
  }


  filter(filter: string) {
    console.log('filter' , filter);
    if (filter) {
      if (this.meta['filter'] == filter) return this.meta['filter_result'];

      this.meta['filter'] = filter;
      this.meta['filter_result'] = false;
      if (this.name && this.name.includes(filter)) this.meta.filter_result = true;
      else if (this.note && this.note.includes(filter)) this.meta.filter_result = true;
      else if (this.channelType && this.channelType.toString().includes(filter)) this.meta.filter_result = true; // todo should be entity pass in filter call.
      else if (this.fields) {
        for (const key of Object.keys(this.fields)) {
          if (key.includes(filter)) {
            this.meta.filter_result = true;
            break;
          }
          if (this.fields[key].includes(filter)) {
            this.meta.filter_result = true;
            break;
          }
        }
      }
      return this.meta.filter_result;
    }  else {
      return true;
    }
  }

  widget_type() {
    return WidgetEnum.EMBED_WIDGET__COMMUNICATION_CHANNEL_ENTITY;
  }

} // end Package
