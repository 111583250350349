
    <page-header [section]="'email-templates'">
      <page-title>Email Templates</page-title>
      <page-blurb>Initiate a send request to users, and send an email notification depending on the kind of alert.</page-blurb>
    </page-header>

    <new-spinner *ngIf="!queue" [size]="'sm'"></new-spinner>
    <div *ngIf="queue" class="row">
      <div class="col">
        <generic-set-widget [entity]="queue" [layout]="'table'" [options]="queue_list_options"></generic-set-widget>
      </div>
    </div>

  