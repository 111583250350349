import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DeviceDefinitionRepo} from '../../repos';
import {DeviceDefinitionEntity} from '../../entities/device';
import {DeviceEntity} from '../../entities';
import {DeviceWidgetEventStruct} from './device/device-widget-event.struct';
import {WidgetBase} from './widget-base';
import {DeviceWidgetEnum, WidgetEnum} from '../../enums';

@Component({
  selector: 'device-widget',
  template: `
    <ng-container *ngIf="entity">
      <ng-container [ngSwitch]="entity.device_widget_type()">
        <device-entity-widget *ngSwitchCase="DeviceWidgetEnum.DEVICE_WIDGET__ENTITY" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></device-entity-widget>
        <device-target-widget *ngSwitchCase="DeviceWidgetEnum.DEVICE_WIDGET__ENVIRONMENT_TARGET" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></device-target-widget>
      </ng-container>
    </ng-container>
`
})
export class DeviceWidgetComponent extends WidgetBase implements OnInit {
  @Input() entity: any = null;
  @Input() templateHint: string = null;
  @Input() options: any = {};
  @Input() layout = 'standard';
  @Output() widgetEvent = new EventEmitter<DeviceWidgetEventStruct>();


  public  DeviceWidgetEnum = DeviceWidgetEnum;
  forwardEvent(e, widgetEvent: DeviceWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }

  constructor() {
    super();
  }

  ngOnInit(): void {

  }

}
