import {DeviceWidgetEnum, LogicalDeviceWidgetEnum, LogicalWidgetEnum,} from '../../../enums/widget';
import {LogicalWidget} from '../../logical-widget';
import {DeviceLogicalWidget} from '../../device/logical-widget';
import {DeviceEntity} from '../../device.entity';

export class DeviceReadingLogicalWidget extends DeviceLogicalWidget {

  logical_widget_type(): LogicalWidgetEnum | null {
    return LogicalWidgetEnum.LOGICAL_WIDGET__DEVICE;
  }

  constructor(subject: DeviceEntity) {
    super(LogicalDeviceWidgetEnum.LOGICAL_DEVICE_WIDGET__READINGS);
    this.subject = subject;
  } // end constructor
} // end Package

