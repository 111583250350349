import {NoizuStruct}        from '../../noizu/structs/noizu-struct';
import {EntityLogSet} from '../../entities/log/entity-log.set';
import {AlertRepo} from '../../repos/alert.repo';
import {HttpClient} from '@angular/common/http';
import {FirebaseAuthService} from '../../noizu/services/firebase-auth.service';
import {AppengineEntityList} from '../../noizu/structs/appengine-entity-list';
import {ElixirEntity} from '../../entities/elixir.entity';
import {ExtendedUserSubscriptionDetailsEntity} from '../../entities/user/extended/subscription-details.entity';
import {cloneDeep} from 'lodash';
import {AlertEntity, UserSubscriptionEntitySet} from '../../entities';

export class ExtendedUserDetails extends ElixirEntity {
  public loading: boolean;
  public loading_component: {alerts: boolean} = {alerts: false};
  public identifier: string;
  public logs: {user: EntityLogSet} = null;
  public alerts: AppengineEntityList = null;
  public subscriptions: ExtendedUserSubscriptionDetailsEntity | null = null;
  constructor(public client: HttpClient, public auth: FirebaseAuthService, json) {
    super(client, auth, json);
    this.loading = true;
    this.logs = {
      user: null,
    }
    this.meta.subscriptions_loaded = false;
    this.meta.subscriptions_loading = false;
  } // end constructor

  getAlertsPromise(options = {}) {
    this.loading_component.alerts = true;
    let repo = new AlertRepo(this.client, this.auth);
    return repo.getUserAlertListPromise(this.identifier, options).then((alert_set: AppengineEntityList) => {
      this.loading_component.alerts = false;
      this.alerts = alert_set;
      repo.multiStatusPromise(this.alerts).then((statuses: any) => {
        this.alerts.items.forEach((v) => {
          v.extended.status_loaded = statuses.alert_details[v.identifier] ? true : false;
          v.extended.status = v.extended.status_loaded ? statuses.alert_details[v.identifier] : {};
        });

        repo.multiDetailsPromise(this.alerts).then((details: any) => {
          this.alerts.items.forEach((v: AlertEntity) => {
            if (details.alert_details[v.identifier]) {
              v.setState(details.alert_details[v.identifier]);
            }
          });
        })

      });
    });
  }

  getSubscriptionDetailsPromise(refresh = false, force = false) {
    if (!force && this.meta['subscriptionPromise']) {
      if (!refresh || this.meta.subscriptions_loading) return this.meta['subscriptionPromise'];
    }
    this.meta['subscriptionPromise'] = new Promise((resolve, reject) => {
      this.meta.subscriptions_loading = true;
      this._get(this.ingvEndpoint()+`/users/${this.identifier}/subscriptions?expand-refs=sub-payment`,(response, inner_resolve) => {
        let sd = new ExtendedUserSubscriptionDetailsEntity(this.client, this.auth, `ref.user.${this.identifier}`, response['response']);
        inner_resolve(sd);
      }, {}).then((response: ExtendedUserSubscriptionDetailsEntity) => {
        this.subscriptions = response;
        console.log("Subscription Details", response);
        this.meta.subscriptions_loaded = true;
        this.meta.subscriptions_loading = false;
        this.meta.subscriptions_load_error = null;
        resolve(this.subscriptions);
      }).catch((r) => {
        console.log("Request Failed");
        this.meta.subscriptions_load_error = r;
        this.meta.subscriptions_loading = false;
        resolve(this.subscriptions);
      });
    });

    return this.meta['subscriptionPromise'];
  }
}
