


    <ng-container [ngSwitch]="layout">

      <!-- =============================================== -->
      <!-- Table View                                      -->
      <!-- =============================================== -->
      <ng-container *ngSwitchCase="'table'">
        <div class="row" *ngIf="options['index'] == 0 && options['header']">
          <ng-container *ngFor="let column of options.columns">
            <ng-container [ngSwitch]="column">
              <div class="col div-table-th" *ngSwitchCase="'identifier'">Id</div>
              <div class="col div-table-th" *ngSwitchCase="'handle'">Handle</div>
              <div class="col div-table-th" *ngSwitchCase="'type'">Type</div>
              <div class="col div-table-th" *ngSwitchCase="'status'">Status</div>
              <div class="col div-table-th" *ngSwitchCase="'payment'">Source</div>
              <div class="col div-table-th" *ngSwitchCase="'start'">Start</div>
              <div class="col div-table-th" *ngSwitchCase="'end'">End</div>
              <div class="col div-table-th" *ngSwitchCase="'actions'">Actions</div>
            </ng-container>
          </ng-container>
        </div>
        <div class="row">
          <ng-container *ngFor="let column of options.columns">
            <ng-container [ngSwitch]="column">
              <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'identifier'">
                <!-- <a [routerLink]="['/portal/pins/show/'  + entity.identifier]">{{entity?.identifier}}</a> -->
                {{entity?.identifier}}
              </div>

              <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'handle'">
                {{entity.handle}}
              </div>

              <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'status'">
                {{entity.subscription_status}}
              </div>

              <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'payment'">
                {{entity.subscription_payment?.source || "internal"}}
              </div>

              <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'type'">
                {{entity.subscription_type}}
              </div>

              <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'start'">
                <span *ngIf="entity.subscription_start">{{entity.subscription_start| date:'longDate'}}</span>
              </div>

              <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'end'">
                <span *ngIf="entity.subscription_end">{{entity.subscription_end| date:'longDate'}}</span>
              </div>

              <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'actions'" >
                [Actions]
              </div>


            </ng-container>
          </ng-container>
        </div>
      </ng-container>

      <!-- =============================================== -->
      <!-- Default View (Card)                             -->
      <!-- =============================================== -->
      <ng-container *ngSwitchDefault>
        <ng-container *ngIf="entity?.new">

          <div class="row">
            <div class="col-2">
              Handle
            </div>
            <div class="col">
              <input type="text"  class="form-control" [(ngModel)]="entity.handle" (ngModelChange)="editEntity()" size="32" />
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              Owner
            </div>
            <div class="col">
              {{entity.owner}}
            </div>
          </div>

          <div class="row" *ngIf="entity.validation?.messages['subscription_type']">
            <div class="col">
              <div class="alert alert-warning">{{entity.validation.messages.subscription_type}}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              Type
            </div>
            <div class="col">
              <widget [entity]="typeRepo" (widgetEvent)="widgetEventHandler($event)" [options]="{edit: options['edit'], selected: entity.subscription_type}" [layout]="'select'"></widget>
            </div>
          </div>

          <div class="row" *ngIf="entity.validation?.messages['subscription_status']">
            <div class="col">
              <div class="alert alert-warning">{{entity.validation.messages.subscription_status}}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-2">Status</div>
            <div class="col">
              <ng-select
                [name]="'subscription_status'"
                [multiple]="false"
                [(ngModel)]="entity.subscription_status"
                bindLabel="text"
                bindValue="value"
                (ngModelChange)="setStatus($event)"
                [items]="status_options"
              ></ng-select>
            </div>
          </div>

          <div class="row" *ngIf="entity.validation?.messages['subscription_start']">
            <div class="col">
              <div class="alert alert-warning">{{entity.validation.messages.subscription_start}}</div>
            </div>
          </div>
          <div class="row" *ngIf="entity.validation?.messages['subscription_end']">
            <div class="col">
              <div class="alert alert-warning">{{entity.validation.messages.subscription_end}}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              Period
            </div>
            <div class="col">
              <ng-select
                placeholder="Select TimeSpan"
                [items]="time_span_options"
                [multiple]="false"
                [(ngModel)]="time_span"
                bindLabel="text"
                bindValue="value"
                (ngModelChange)="selectSpan($event)"
              ></ng-select>
            </div>
          </div>


        </ng-container>
        <ng-container *ngIf="!entity?.new">
          <div class="card">
            <div class="card-header">
              Subscription #{{entity?.identifier}}
              <div class="card-header-actions" *ngIf="!inAppPurchase() && options['edit']">
                <div class="card-header-action">
                  <i class="text-danger fa fa-minus-circle" (click)="delete($event)"> </i>
                </div>
              </div>
            </div>
            <div class="card-body m-1 p-0">
              <div class="row">
                <div class="col-9">
                  <div class="row">
                    <div class="col-2">
                      Type
                    </div>
                    <div class="col">
                      <widget [entity]="typeRepo" (widgetEvent)="widgetEventHandler($event)" [options]="{edit: !inAppPurchase() && (options['edit'] || false), selected: entity.subscription_type}" [layout]="'select'"></widget>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-2">
                      Handle
                    </div>
                    <div class="col">
                      <span *ngIf="!(entity && options && options['edit'])">{{entity.handle}}</span>
                      <span *ngIf="entity && options && options['edit']"><input type="text" class="form-control" [(ngModel)]="entity.handle" (ngModelChange)="editEntity()" size="32" /></span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-2">
                      Owner
                    </div>
                    <div class="col">
                      {{entity.owner}}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-2">
                      Status
                    </div>
                    <div class="col" >

                      <ng-container *ngIf="!inAppPurchase() && options['edit']">
                        <ng-select
                          [name]="'subscription_status'"
                          [multiple]="false"
                          [(ngModel)]="entity.subscription_status"
                          bindLabel="text"
                          bindValue="value"
                          (ngModelChange)="setStatus($event)"
                          [items]="status_options"
                        ></ng-select>
                      </ng-container>
                      <ng-container *ngIf="!(!inAppPurchase() && options['edit'])">
                        {{entity.subscription_status}}
                      </ng-container>
                    </div>
                  </div>
                  <div class="row" *ngIf="entity.subscription_payment">
                    <div class="col-2">Payment Details</div>
                    <div class="col">
                      <widget (widgetEvent)="forwardEvent($event)" [entity]="entity.subscription_payment" [layout]="layout" [options]="options"></widget>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-2">
                      Period
                    </div>
                    <div class="col">
                      <span *ngIf="entity.subscription_start">{{entity.subscription_start| date:'medium'}}</span> - <span *ngIf="entity.subscription_end">{{entity.subscription_end | date:'medium'}}</span> <i *ngIf="!inAppPurchase() && options['edit']" class="fa text-primary fa-pencil" (click)="editPeriod($event, pickerModal)"> </i>
                    </div>
                  </div>
                </div>
                <div class="col">

                  <entity-notes-v2
                    [title] = "'Subscription Notes'"
                    [entity]="entity.sref()"
                    [options]="{'expand': true, 'dragula': false}"
                    [placeholder] = "'Subscription Note.'"
                  ></entity-notes-v2>

                </div>
              </div>


            </div>
            <div class="card-footer text-center m-0 p-1" *ngIf="entity?.meta?.hasChanges">
              <button (click)="save($event)" type="button" class="btn btn-danger rounded">Update</button>
            </div>
          </div>
        </ng-container>
      </ng-container>

    </ng-container>


    <ng-template #pickerModal>
      <modal-widget *ngIf="currentModal" (widgetEvent)="eventHandler($event, 'pop-up', pickerModal)" [entity]="currentModal" [layout]="'shadowbox'" [options]="{}"></modal-widget>
    </ng-template>

  