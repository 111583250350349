
import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from '../../lacrosse.entity';
import { FirebaseAuthService }            from '../../../noizu/services/firebase-auth.service';
import {ElixirEntity} from '../../elixir.entity';
import {UserExtendedWidgetEnum, WidgetEnum} from '../../../enums';
import {UserExtendedEntity} from './user-extended.entity';
import {UserSubscriptionPaymentEntity} from './subscription.payment.entity';

export class UserSubscriptionEntity extends UserExtendedEntity {
  //public remote_entity: any;
  public subscription_type: any;
  public subscription_start: any;
  public subscription_end: any;
  public subscription_status: any;
  public subscription_payment: UserSubscriptionPaymentEntity;
  public owner: any;
  public last_synched: any;
  public handle: any;
  public _sref_module = 'user-subscription';
  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    //this.remote_entity = json['remote_entity'];
    this.subscription_type = json['subscription_type'];
    this.handle = json['handle'] || null;
    this.owner = json['owner'];
    this.subscription_status = json['subscription_status'];
    this.subscription_start = json['subscription_start'] && new Date(json['subscription_start']);
    this.subscription_end = json['subscription_end'] && new Date(json['subscription_end']);
    this.subscription_payment = json['subscription_payment'] && new UserSubscriptionPaymentEntity(client, auth, json['subscription_payment']);

    this.last_synched = json['last_synched'] && new Date(json['last_synched']);
    this.raw = null;
  } // end constructor


  toJson(options) {
    let json = super.toJson(options);
    json["subscription_payment"] = this.subscription_payment && this.subscription_payment.ref();
    return json;
  }

  updateEndpoint() {
    return `${this.apiBase()}/users/${this.owner}/subscriptions/${this.identifier}`;
  }

  createEndpoint() {
    return `${this.apiBase()}/users/${this.owner}/subscriptions`;
  }

  deleteEndpoint() {
    return `${this.apiBase()}/users/${this.owner}/subscriptions/${this.identifier}`;
  }




  filter(filter: string) {
    if (filter) {
      if (this.meta['filter'] == filter) return this.meta['filter_result'];

      this.meta['filter'] = filter;
      this.meta['filter_result'] = false;

      let handle_filter = filter.match(/handle:"([a-zA-Z\-_0-9 ]+)"/)
      if (handle_filter) {
        handle_filter.forEach((v) => {
          if (this.handle == v) {
            this.meta.filter_result = true;
            return true;
          }
        });
      }
      handle_filter = filter.match(/handle:([a-zA-Z\-_0-9]+)/)
      if (handle_filter) {
        handle_filter.forEach((v) => {
          if (this.handle == v) {
            this.meta.filter_result = true;
            return true;
          }
        });
      }

      let type_filter = filter.match(/type:"?([.\-a-zA-Z0-9]+)"?/)
      if (type_filter) {
        type_filter.forEach((v) => {
          if (`${this.subscription_type}` == `ref.subscription.${v}`) {
            this.meta.filter_result = true;
            return true;
          }
          let m = "";
          switch (this.subscription_type) {
            case "ref.subscription.5662168018059264": m = "trial"; break;
            case "ref.subscription.5707762182914048": m = "enhanced"; break;
            case "ref.subscription.5361034439163904": m = "sms"; break;
            case "ref.subscription.5749285255643136": m = "advanced"; break;
            default: m = "other";
          }
          if (m == v) {
            this.meta.filter_result = true;
            return true;
          }
        });
      }


      let status_filter = filter.match(/status:"?([.\-a-zA-Z0-9]+)"?/)
      if (status_filter) {
        status_filter.forEach((v) => {
          if (`${this.subscription_status}` == v) {
            this.meta.filter_result = true;
            return true;
          }
        });
      }

      let owner_filter = filter.match(/owner:"?([.\-a-zA-Z0-9]+)"?/)
      if (owner_filter) {
        owner_filter.forEach((v) => {
          if (`${this.owner}` == `ref.owner.${v}`) {
            this.meta.filter_result = true;
            return true;
          }
        });
      }

      if (this.subscription_payment) {
        this.meta.filter_result = this.subscription_payment.filter(filter);
      }

      return this.meta.filter_result;
    }  else {
      return true;
    }
  }


  validate() {
    let messages = {};
    let valid = true;

    if (!this.subscription_status) {
      valid = false;
      messages['subscription_status'] = 'Subscription Status Required';
    }

    if (!this.subscription_type) {
      valid = false;
      messages['subscription_type'] = 'Subscription Type Required';
    }
    if (!this.subscription_start) {
      valid = false;
      messages['subscription_start'] = 'Subscription Start Required';
    }
    if (!this.subscription_start) {
      valid = false;
      messages['subscription_start'] = 'Subscription End Required';
    }

    this.validation = {valid: valid,  validated: true, error: (valid ? null : 'Validation Errors Found'), messages: messages}
    return this.validation;
  }

  widget_type(): any {
    return WidgetEnum.EMBED_WIDGET__USER_EXTENDED;
  }

  user_extended_widget_type(): any {
    return UserExtendedWidgetEnum.USER_EXTENDED_WIDGET__SUBSCRIPTION;
  }

} // end Package
