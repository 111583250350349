import {HttpClient} from '@angular/common/http';
import { FirebaseAuthService }            from '../noizu/services/firebase-auth.service';
import {ElixirEntity} from './elixir.entity';
import {WidgetEnum} from '../enums';

export class NoteEntity extends ElixirEntity {
  public _kind = "note";
  public _sref_module = "note";
  public owner;
  public created_on;
  public modified_on;
  public note;
  public title;
  public subject;

  constructor(client: HttpClient, public auth: FirebaseAuthService, json) {
    super(client, auth, json);
    this.refresh(json);
  } // end constructor

  refresh(json) {
    super.refresh(json);
    this.owner = json.owner;
    this.created_on = json.created_on && new Date(json.created_on);
    this.modified_on = json.modified_on && new Date(json.modified_on);
    this.note = json.note;
    this.title = json.title;
    this.subject = json.subject;
    return this;
  }

  widget_type() {
    return WidgetEnum.EMBED_WIDGET__NOTE_ENTITY;
  }

  update() {
    let update = {
      identifier: this.identifier,
      created_on: this.created_on,
      subject: this.subject,
      modified_on: this.modified_on,
      owner: `ref.user.${this.owner.identifier}`,
      note: this.note
    };
    return this._put(`${this.apiBase()}/admin-tools/entity-notes/${this.subject}/note/${this.identifier}`, update, (record, resolve) => {
      if (record.response) {
        this.refresh(record.response);
        resolve(this);
      } else {
        resolve(null);
      }
    }, {});
  }

  delete() {
    return this._delete(`${this.apiBase()}/admin-tools/entity-notes/${this.subject}/note/${this.identifier}`, (record, resolve) => {
      if (record.response && record.deleted) {
        this.refresh(record.response);
        resolve(this);
      } else {
        resolve(null);
      }
    }, {});
  }

} // end Package
