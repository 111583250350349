import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToasterService} from 'angular2-toaster';
import {WidgetBase} from '../widget-base';
import {WidgetEventStruct} from '../../widget-event.struct';
import {BatchEntity} from '../../../entities/batch.entity';
import {WidgetEnum} from '../../../enums';
import {NoteEntity} from '../../../entities';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'note-widget',
  template: `
<ng-container [ngSwitch]="layout">
  <ng-container *ngSwitchDefault>
    <div class="card">
      <div class="card-header pl-1 pt-0 pb-0 pr-1">
          <span *ngIf="entity.created_on"><b>Time:</b> {{entity.created_on |date:'MM/dd/yyyy @ h:mm:ssa'}}</span>
          <i *ngIf="entity.owner.identifier == authService.user.identifier" (click)="edit = !edit" class="fa fa-edit float-right"></i>
      </div>
      <div class="card-body p-1 pt-0">
        <div class="row pt-0 pb-0">
          <div class="col-12 pt-0 pb-0">
            <div><b>User:</b><a [routerLink]="'/portal/users/show/' + entity.owner.identifier" > {{entity.owner.name?.first || 'first'}} {{entity.owner.name?.last || 'last'}}</a></div>
          </div>
        </div>
        <div class="row">
          <div class="col  pt-0 pb-0">
            <span *ngIf="!edit">
              <textarea readonly [(ngModel)]="entity.note" class="form-control"></textarea>
            </span>
            <span *ngIf="edit">
              <textarea (ngModelChange)="noteChanged($event)" class="form-control" [(ngModel)]="entity.note"></textarea>
            </span>
          </div>
        </div>
      </div>
      <div class="card-footer p-1" *ngIf="edit">
        <button *ngIf="entity.note && entity.meta.changed && entity.note.length > 2" class="btn btn-warning" (click)="updateNote($event)">Update</button>
        <button class="btn btn-danger" (click)="deleteNote($event)">Delete</button>
      </div>
    </div>
  </ng-container>
</ng-container>`
})
export class NoteWidgetComponent extends WidgetBase {
  @Input() entity: NoteEntity;
  @Input() layout: string = null;
  @Input() options: any;
  @Output() widgetEvent = new EventEmitter<WidgetEventStruct>();
  forwardEvent(e, widgetEvent: WidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }
  edit = false;

  constructor(public toasterService: ToasterService, public authService: AuthService) {
    super();
  }

  noteChanged(event) {
    this.entity.meta.changed = true;
  }

  updateNote(event) {
    event.preventDefault();
    this.entity.update().then((e) => {
      this.entity.meta.changed = false;
      this.edit = false;
      let event = new WidgetEventStruct();
      event.event_widget_type = this.entity.widget_type();
      event.event_type = 'updated';
      event.event_body = {note: this.entity};
      this.widgetEvent.emit(event);
    });
  }

  deleteNote(event) {
    event.preventDefault();
    this.entity.delete().then((e) => {
      if (e) {
        let event = new WidgetEventStruct();
        event.event_widget_type = this.entity.widget_type();
        event.event_type = 'deleted';
        event.event_body = {note: this.entity};
        this.widgetEvent.emit(event);
      } else {
        let event = new WidgetEventStruct();
        event.event_widget_type = this.entity.widget_type();
        event.event_type = 'deleted_failed';
        event.event_body = {note: this.entity};
        this.widgetEvent.emit(event);
      }
    });
  }

}
