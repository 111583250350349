
import { Component, Inject, Input, NgZone } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location }               from '@angular/common';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { DeviceDuplicatesEntity } from '../../../entities/device/duplicates.entity';
import { DeviceDuplicatesRepo } from '../../../repos/device/duplicates.repo';

@Component({
  templateUrl: './list.component.html',
})
export class DeviceDuplicatesListComponent {
  public entries;
  public searchResults:any;
  public searchTerm = {id: null};
  public pagination = {show:false, next: null, page: 0, cursors: {0: ""}, cursorArray: [""]};
  public state = {loading: null, hasResults: false, error: false, errorMsg: ""}
  public searchButton = {state:0, text:{0:"Get All", 1:"Search"}};
  public searchSerial = "";

  constructor(public repo: DeviceDuplicatesRepo, public zone: NgZone) {
  }

  toggleSearchState(e) {
    e.preventDefault();
    this.searchButton.state = this.searchButton.state == 0 ? 1 : 0;
  }

  search(e) {
    e.preventDefault();
    this.state.loading = true;
    if (this.searchButton.state) {
      this.repo.getDuplicatesBySerial(this.searchSerial).then((u: any) =>
      {
        this.state.loading=false;
        this.pagination.show = false;
        if (u.identifier != "nyi") {
          this.entries = {items:[u]};
        }
        this.state.loading = false;
      });
    } else {
      this.repo.getListPromise().then((u: any) =>
      {
        this.state.loading=false;
        this.pagination.show = true;
        this.pagination.page = 0;
        this.pagination.next = u.cursor;
        this.pagination.cursorArray[0] = u.cursor;
        this.pagination.cursors[0] = u.cursor;
        this.entries = u;
        this.state.loading = false;
        this.setDisplayResults();
      });
    }
  }

  searchAt(index, event) {
      event.preventDefault();
      let options = {"cursor":this.pagination.cursors[index]};
      this.state.loading = true;
      this.repo.getListPromise(options).then(
        (results) => {
          this.pagination.page = index;
          this.entries = results;
          this.pagination.cursorArray[this.pagination.page] = options.cursor;
          this.pagination.cursors[this.pagination.page] = options.cursor;
          this.state.loading = false;
          this.setDisplayResults();
        }
      );
    }

    searchNext(event) {
      event.preventDefault();
      if (this.pagination.cursors[this.pagination.page + 1]) {
        this.searchAt(this.pagination.page + 1, event);
      } else {
        let options = {"cursor":this.pagination.next};
        this.state.loading = true;
        this.repo.getListPromise(options).then(
          (results) => {
            this.pagination.page++;
            this.entries = results;
            this.pagination.next = this.entries.cursor;
            this.pagination.cursorArray[this.pagination.page] = options.cursor;
            this.pagination.cursors[this.pagination.page] = options.cursor;
            this.state.loading = false;
            this.setDisplayResults();
          }
        );
      }
    }

    setDisplayResults() {
      if (this.entries.items.length < 500) {
        this.pagination.next = null;
      }
    }
}
