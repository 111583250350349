<page-header [section]="'services-sys-health'">
  <page-title>Services</page-title>
  <page-blurb>Display active and inactive services. Manage and initiate service actions and requests.</page-blurb>
</page-header>


<div class="row">
  <div class="col">
    <h1>Worker Tallies</h1>
    <div class="row" >
      <div class="col-12" *ngIf="loading">  <new-spinner [size]="'sm'"></new-spinner> </div>
      <div class="col-6" *ngFor="let k of objectKeys(worker_tallies)">
        <widget [entity]="worker_tallies[k]" [options]="{}" [layout]="'card'"></widget>
      </div>
    </div>
  </div>
</div>
