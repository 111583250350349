import {
  LogicalDeviceWidgetEnum, LogicalWidgetEnum,
} from '../../enums/widget';
import {LogicalWidget} from '../logical-widget';

export class DeviceLogicalWidget extends LogicalWidget{
  public sub_type: LogicalDeviceWidgetEnum | null;

  constructor(sub_type: LogicalDeviceWidgetEnum = null) {
    super();
    this.sub_type = sub_type;
  } // end constructor

  logical_widget_type(): LogicalWidgetEnum | null {
    return LogicalWidgetEnum.LOGICAL_WIDGET__DEVICE;
  }

  logical_device_widget_type(): LogicalDeviceWidgetEnum | number | string | null {
    return this.sub_type;
  }
} // end Package
