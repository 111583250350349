
import { Component, Inject, Input, NgZone } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location }               from '@angular/common';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { EmailTemplateEntity } from '../../../entities/data/email-template.entity';
import { EmailTemplateRepo } from '../../../repos/data/email-template.repo';

@Component({
  template: `
    <page-header [section]="'email-templates'">
      <page-title>Email Templates</page-title>
      <page-blurb>Initiate a send request to users, and send an email notification depending on the kind of alert.</page-blurb>
    </page-header>

    <new-spinner *ngIf="!queue" [size]="'sm'"></new-spinner>
    <div *ngIf="queue" class="row">
      <div class="col">
        <generic-set-widget [entity]="queue" [layout]="'table'" [options]="queue_list_options"></generic-set-widget>
      </div>
    </div>

  `,
})
export class EmailTemplateListComponent {
  public queue: any;
  public queue_list_options = {};

  constructor(public repo: EmailTemplateRepo, public zone: NgZone) {
    this.queue_list_options = {'header': true, 'time_range_query': true,  'headers': ['identifier',  'created_on', 'state', 'template', 'email', 'actions'], 'nested': {}};
    this.zone.run(
      () => {
        this.repo.getEmailTemplates().then((u: any) => {this.queue = u;});
      }
    )
  }


/*
    add(e) {
      e.preventDefault();
      this.entries["items"].push(this.repo.entity({id: "new"}));
    }*/
}
