import {NoizuStruct} from '../../../noizu/structs/noizu-struct';
import {DiagnosticReading} from '../diagnostic-card.entity';

export enum DeviceReading {
  temperature,
  temperature_humidity,
  wind_speed,
  wind_direction,
  hourly_rain,
  daily_rain,
  weekly_rain,
  monthly_rain,
  yearly_rain,
  total_rain,
  disabled,
  unknown
}

export class DataStreamDeviceLink extends NoizuStruct {
  public type: DeviceReading | undefined = DeviceReading.unknown;
  public device: any = null;
  public device_association: any = null;
  public time_zone: any = null;

  constructor(json) {
    super();

    if (json['type']) {
      this.type = (<any>DeviceReading)[json['type']];
      if (this.type === undefined) {
        console.log(`Unsupported Forecast Link: ${json['type']}`);
        this.type = DeviceReading.unknown;
      }
    } else {
      this.type = DeviceReading.unknown;
    }

    this.device = json['device'];
    this.device_association = json['device_association'];
    this.time_zone = json['time_zone'];
  }

  linkType() {
    return DeviceReading[this.type];
  }

  toJson(options: any = {}) {
    return {
      device_association: this.device_association,
      type: this.linkType(),
      time_zone: this.time_zone
    }
  }

  validate() {
    const errors = [];
    if (this.type === undefined
      || this.type === null
      || this.type === DeviceReading.unknown
      || this.type === DeviceReading.disabled) {
      errors.push({error: 'NoLink', message: 'Device Link Type Required'});
    }

    if (this.device_association === undefined || this.device_association === null) {
      errors.push({error: 'NoDevice', message: 'DeviceAssociation Must Be Set'});
    }

    if (this.time_zone === undefined || this.time_zone === null) {
      errors.push({error: 'NoTimeZone', message: 'TimeZone Not Set.'});
    }

    return errors;
  }

}
