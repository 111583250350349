
import { Component, Inject, Input, NgZone } from '@angular/core';
import {HealthReportRepo} from '../../../repos/health-report.repo';

@Component({
  templateUrl: './services.component.html',
})
export class SystemHealthServicesComponent {
  public worker_tallies = {};
  public loading = true;
  public objectKeys(o) {
    return Object.keys(o).sort();
  }

  constructor(reportRepo: HealthReportRepo) {
    for(let i = 0; i < reportRepo.components.length; i++) {
      let component = reportRepo.components[i];
      reportRepo.getWorkerTally(component).then((tally) => {
        this.worker_tallies[component] = tally;
        this.loading = false;
      });
    }
  }
}
