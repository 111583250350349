/*
var CATEGORY_ENUM = {
  TEMP: 1,
  TEMP_HUMIDITY: 2,
  TEMP_RAIN: 3,
  TEMP_WIND_HUMIDITY: 4,
  ALL_IN_ONE: 5,
  TX60: 6,
  TX70: 7,
  TX71: 8,
  WS2816: 9,
  INDOOR_TEMP_HUMIDITY: 10,
  RAIN: 11,
  WIND_RAIN_LIGHTNING: 20,
  COMPOSITE: 30
};
*/
export enum DeviceCategoryEnum {
  Temperature = 1,
  TemperatureAndHumidity,
  TemperatureAndRain,
  WindTemperatureAndHumidity,
  AllInOne,
  TX60,
  TX70,
  TX71,
  WS2816,
  DisplayTemperatureAndHumidity,
  Rain,
  Wetness,
  WindRainAndLightning = 20,
  Bbq,
  Pool,
  Link,
  NewType25 = 25,
  Composite = 30,
  WindSpeed = 99
}
