
<div *ngIf="!entity">
  <new-spinner [size]="'sm'"></new-spinner>
</div>
<ng-container *ngIf="entity">
  <ng-container [ngSwitch]="entity.email_widget_type()">
    <email-queue-entry-widget *ngSwitchCase="EmailWidgetEnum.EMAIL_WIDGET__QUEUE_ENTRY" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></email-queue-entry-widget>
    <email-template-widget *ngSwitchCase="EmailWidgetEnum.EMAIL_WIDGET__TEMPLATE" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></email-template-widget>
    <!-- Default -->
    <div *ngSwitchDefault>
        <div [ngSwitch]="layout">
          <div *ngSwitchCase="'shadowbox'">
            <div class="email-widget row mb-5">
              <div class="col text-center border-bottom">
                <span class="display-4">{{ EmailWidgetEnum[entity.email_widget_type()] }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <pre>{{entity.toJson()| json}}</pre>
              </div>
            </div>
          </div>
          <div *ngSwitchDefault>
            <div class="email=widget card" [ngClass]="{'mb-0': options?.nested}">
              <div class="card-header" [ngClass]="{'p-2 m-0': options?.nested}"> {{ EmailWidgetEnum[entity.email_widget_type()] }}</div>
              <div class="card-body">
                <pre>{{entity.toJson()| json}}</pre>
              </div>
            </div>
          </div>
        </div>
    </div>

  </ng-container>
</ng-container>
