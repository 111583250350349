import { Component, Input, Inject } from '@angular/core';
import {NoizuStruct} from '../../../noizu/structs/noizu-struct';

@Component({
  selector: 'log-sub-type-embed',
  template: `
<div *ngIf="!entity">
  <widget-spinner></widget-spinner>
</div>
<div *ngIf="entity">
  <div [ngSwitch]="template()">

    <!-- Logs -->
    <log-widget *ngSwitchCase="'embed-entity-log'" [entity]="entity" [layout]="layout" [options]="options"></log-widget>
    <embed-entity-log-report-binary
      *ngSwitchCase="'embed-entity-log-report-binary'"
      [entity]="entity" [layout]="layout" [options]="options"></embed-entity-log-report-binary>
    <embed-entity-log-report-binary-details
      *ngSwitchCase="'embed-entity-log-report-binary-details'"
      [entity]="entity" [layout]="layout" [options]="options"></embed-entity-log-report-binary-details>
    <embed-entity-log-stage-one-data
      *ngSwitchCase="'embed-entity-log-stage-one-data'"
      [entity]="entity" [layout]="layout" [options]="options"></embed-entity-log-stage-one-data>
    <embed-entity-log-wake
      *ngSwitchCase="'embed-entity-log-wake'"
      [entity]="entity" [layout]="layout" [options]="options"></embed-entity-log-wake>
    <embed-entity-log-stream
      *ngSwitchCase="'embed-entity-log-stream'"
      [entity]="entity" [layout]="layout" [options]="options"></embed-entity-log-stream>
    <embed-entity-log-extraction-warn
      *ngSwitchCase="'embed-entity-log-extraction-warn'"
      [entity]="entity" [layout]="layout" [options]="options"></embed-entity-log-extraction-warn>
    <embed-request-log
      *ngSwitchCase="'embed-request-log'"
      [entity]="entity" [layout]="layout" [options]="options"></embed-request-log>

    <!-- Default -->
    <embed-default *ngSwitchDefault [entity]="entity" [layout]="layout" [options]="options"></embed-default>
  </div>
</div>
`
})
export class WidgetEmbedLogSubTypeComponent {
  @Input() entity: any = null;
  @Input() templateHint: string = null;
  @Input() options: any = null;
  @Input() layout = 'standard';

  constructor() {
  }

  public template() {
    if (this.entity instanceof NoizuStruct) {
      return this.entity.widget();
    } else {
      return this.templateHint ? this.templateHint : 'default';
    }
  }

}
