import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToasterService} from 'angular2-toaster';
import {AlertEntity} from '../../../entities/alert.entity';
import {WidgetComponent} from '../../widget.component';
import {WidgetBase} from '../widget-base';
import {UserEntity} from '../../../entities';
import {SubscriberWidgetEventStruct} from '../subscriber-object/subscriber-widget-event.struct';
import {UserWidgetEventStruct} from './user-widget-event.struct';

@Component({
  selector: 'user-devices-widget',
  template: `
    <div class="card shadow" *ngIf="entity">
      <div class="card-header">
        <div class="row">
          <div class="col">
            User Devices
            <span *ngIf="options" class="float-right"><i (click)="toggleExpand()" class="fa fa-sm" [ngClass]="{'fa-chevron-down': !options.expand, 'fa-chevron-up': options.expand}"> </i></span>
          </div>
        </div>
      </div>

      <div class="card-body p-1 m-0" *ngIf="entity && expandWidget()">

        <ng-container *ngIf="entity?._nested?.deviceAssociations?.items">
            <widget [entity]="entity._nested.deviceAssociations" [layout]="'table'" [options]="{}"></widget>
        </ng-container>

      </div>
    </div>
  `
})
export class UserDevicesWidgetComponent extends WidgetBase implements OnInit {
  public editMode = false;
  @Input() layout: string = null;
  @Input() entity: UserEntity;
  @Input() options: any;
  @Output() widgetEvent = new EventEmitter<UserWidgetEventStruct>();
  forwardEvent(e, widgetEvent: UserWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }

  constructor(public toasterService: ToasterService) {
    super();
  }



  toggleExpand() {
    super.toggleExpand();
    if (this.expandWidget() && !this.entity._nested['deviceAssociations']) {
      this.entity.getDeviceAssociationsPromise().then();
    }
  }

  ngOnInit(): void {
  }

}
