import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from '../../lacrosse.entity';
import { FirebaseAuthService }            from '../../../noizu/services/firebase-auth.service';
import {DataManagementLogicalWidget} from '../data-management-logical-entity';
import {DataManagementLogicalWidgetEnum} from '../../../enums';

export class VerificationCodeUploadEntity extends DataManagementLogicalWidget {
  public _kind = "null";
  public _singular = "null";

  public upload_type = 'csv';
  public flags = {has_header: true, objectify: false, overwrite: false};
  public series = 'V2';
  public initialized = false;

  constructor(json = {}) {
    super();
    this.refresh(json);
  } // end constructor

  refresh(data) {
    this.upload_type = data['upload_type'] || 'csv';
    let flags = data['flags'] || {has_header: true, objectify: false, overwrite: false};
    flags['has_header'] = ('has_header' in flags) ? flags['has_header'] : this.flags['has_header'];
    flags['objectify'] = ('objectify' in flags) ? flags['objectify'] : this.flags['objectify'];
    flags['overwrite'] = ('overwrite' in flags) ? flags['overwrite'] : this.flags['overwrite'];
    this.flags = flags;
    this.series = ('series' in data) ? data.series : this.series;
    this.initialized = false;
    return this;
  }

  data_management_logical_widget_type(): DataManagementLogicalWidgetEnum | null {
    return DataManagementLogicalWidgetEnum.DATA_MANAGEMENT_LOGICAL_WIDGET__VERIFICATION_CODE;
  }

} // end Package
