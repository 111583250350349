
import { Component, Inject, Input, NgZone } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location }               from '@angular/common';
import { NgModel, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { DeviceLookupTableEntryEntity } from '../../../entities/device/lookup-table-entry.entity';
import { DeviceLookupTableRepo } from '../../../repos/device/lookup-table.repo';
import { AppengineEntityList } from '../../../noizu/structs/appengine-entity-list';

@Component({
  templateUrl: './lookup-table.component.html',
})
export class DeviceAutoLoadLookupTableComponent {
  public entries: AppengineEntityList;

  constructor(public repo: DeviceLookupTableRepo,  public zone: NgZone) {
    this.zone.run(
      () => {
        this.repo.getListPromise().then((u: any) => {this.entries = u;});
      }
    )
  }

  add(e) {
    e.preventDefault();
    this.entries.items.push(this.repo.entity({id: "new"}));
  }
}
