import {Component, Input, Inject, ChangeDetectorRef, NgZone, OnInit, Output, EventEmitter} from '@angular/core';
import {DeviceDefinitionRepo, DeviceRepo} from '../../../repos';
import {ToasterService} from 'angular2-toaster';
import {AuthService} from '../../../services/auth.service';
import {SimulatorWidget} from '../../../entities/device/simulator-widget';
import {DeviceDefinitionEntity, FieldSimulatorWidget} from '../../../entities/device';
// import {forEach} from '@angular/router/src/utils/collection';
import {
  BatchLogicalWidget,
  DeviceTypeLogicalWidget,
  ManufacturerLogicalWidget,
  SensorTypeRangeEntity,
  SeriesLogicalWidget
} from '../../../entities';
import {NoizuStruct} from '../../../noizu/structs/noizu-struct';
import {DeviceWidgetEventStruct} from './device-widget-event.struct';
import {WidgetEventStruct} from '../../widget-event.struct';

@Component({
  selector: 'device-range-widget',
  template: `
<div class="device-range-widget" *ngIf="!entity">
  <widget-spinner></widget-spinner>
</div>
<ng-container *ngIf="entity">

  <ng-container [ngSwitch]="layout">
    <div class="device-range-widget row" *ngSwitchCase="'table'">

      <div *ngIf="options['index'] % 35 == 0" class="col-12" >
        <div class="row" *ngIf="options['index'] != 0">
          <div class="col mt-3"><hr/></div>
        </div>


        <div class="row" [ngClass]="{'div-table-tr': (((options['index'] || 0)) % 36)  % 2 == 1, 'div-table-tr-alt': (((options['index'] || 0)) % 36)  % 2 == 0}">
          <div class="col-1">#</div>
          <div class="col-3">Name</div>
          <div class="col-2">Range</div>
          <div class="col">Series</div>
          <div class="col">Man</div>
          <div class="col">Batch</div>
          <div class="col-2">Sensor Type</div>
        </div>
      </div>

      <!--  [ngClass]="{'font-md': entity.cover >= 250 && entity.cover <= 2500, ' font-xl': entity.cover >= 5000, 'font-lg': entity.cover < 5000 && entity.cover > 2500}"  -->
      <div class="col-12" [ngClass]="{  'div-table-tr': (((options['index'] || 0) + 1) % 36)  % 2   == 1, 'div-table-tr-alt': (((options['index'] || 0) + 1) % 36)  % 2  == 0}">
            <div class="row" >
              <div class="col-1"><a class="text-dark" href="#" (click)="sendShowEntry($event, entity)">#{{entity.identifier}}</a></div>
              <div class="col-3"><b>{{entity.name || '?'}}</b> - <span>{{entity.description || '?'}}</span></div>
              <div class="col-2">
              <span class="float-left">(<b>{{entity.range_start || '?'}}</b> ... <b>{{entity.range_end || '?'}}</b>)</span>  <span  class="float-right"> <b>{{entity.cover}}</b> Units</span>
              </div>
              <div class="col">{{entity.range_series || '?'}}</div>
              <div class="col">{{entity.manufacturer || '?'}}</div>
              <div class="col">{{entity.batch || '?'}}</div>
              <div class="col-2"><a class="text-dark" href="#" (click)="sendShowDeviceType($event, entity._sensor_type_id)">#{{entity.device_type}}</a></div>
            </div>
      </div>


    </div>
    <div class="device-range-widget" *ngSwitchCase="'shadowbox'">

      <div *ngIf="options?.edit" >

        <div class="row" *ngIf="entity?.validation?.messages['common']">
          <div class="col">
            <div class="alert alert-warning alert-dismissible">{{entity?.validation?.messages['common']}}</div>
          </div>
        </div>

        <div class="row">
          <div class="col-4">Identifier</div>
          <div class="col pl-2"><b>{{entity.identifier || 'new'}}</b></div>
        </div>


        <!-- Name Warning and Input -->
        <div class="row p-0 mt-3 " *ngIf="entity?.validation?.messages['name']">
          <div class="col">
            <div class="alert alert-danger alert-dismissible p-1 m-0">{{entity?.validation?.messages['name']}}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-4"><i class="fa fa-exclamation text-danger" *ngIf="entity?.validation?.messages['name']"> </i>Name</div>
          <div class="col"><input type="text" [(ngModel)]="entity.name" (ngModelChange)="recordChange()" size="16"/></div>
        </div>

        <!-- Description Warning and Input -->
        <div class="row p-0 mt-3" *ngIf="entity?.validation?.messages['description']">
          <div class="col">
            <div class="alert alert-danger alert-dismissible p-1 m-0">{{entity?.validation?.messages['description']}}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-4"><i class="fa fa-exclamation text-danger" *ngIf="entity?.validation?.messages['description']"></i>Description</div>
          <div class="col"><input type="text" [(ngModel)]="entity.description" (ngModelChange)="recordChange()" size="32"/></div>
        </div>

        <!-- RangeStart Warning and Input -->
        <div class="row p-0 mt-3" *ngIf="entity?.validation?.messages['range_start']">
          <div class="col">
            <div class="alert alert-danger alert-dismissible p-1 m-0">{{entity?.validation?.messages['range_start']}}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-4"><i class="fa fa-exclamation text-danger" *ngIf="entity?.validation?.messages['range_start']"></i>Range Start</div>
          <div class="col"><input type="text" [(ngModel)]="entity.range_start" (ngModelChange)="recordChange()" size="32"/></div>
        </div>

        <!-- RangeEnd Warning and Input -->
        <div class="row p-0 mt-3" *ngIf="entity?.validation?.messages['range_end']">
          <div class="col">
            <div class="alert alert-danger alert-dismissible p-1 m-0">{{entity?.validation?.messages['range_end']}}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-4"><i class="fa fa-exclamation text-danger" *ngIf="entity?.validation?.messages['range_end']"></i>Range End</div>
          <div class="col"><input type="text" [(ngModel)]="entity.range_end" (ngModelChange)="recordChange()" size="32"/></div>
        </div>

        <!-- Device Type Warning and Input -->
        <div class="row p-0 mt-3" *ngIf="entity?.validation?.messages['device_type']">
          <div class="col">
            <div class="alert alert-danger alert-dismissible p-1 m-0">{{entity?.validation?.messages['device_type']}}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-4"><i class="fa fa-exclamation text-danger" *ngIf="entity?.validation?.messages['device_type']"></i>Device Type</div>
          <div class="col"> <device-type-logical-widget *ngIf="device_type" (widgetEvent)="eventHandler($event, 'device_type')"  [entity]="device_type" [options]="options" [layout]="'standard'"></device-type-logical-widget> </div>
        </div>

        <!-- Series Warning and Input -->
        <div class="row p-0 mt-3" *ngIf="entity?.validation?.messages['range_series']">
          <div class="col">
            <div class="alert alert-danger alert-dismissible p-1 m-0">{{entity?.validation?.messages['range_series']}}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-4"><i class="fa fa-exclamation text-danger" *ngIf="entity?.validation?.messages['range_series']"></i>Range Series</div>
          <div class="col"> <series-logical-widget *ngIf="series" (widgetEvent)="eventHandler($event, 'series')"  [entity]="series" [options]="options" [layout]="'standard'"></series-logical-widget> </div>
        </div>

        <!-- Manufacturer Warning and Input -->
        <div class="row p-0 mt-3" *ngIf="entity?.validation?.messages['manufacturer']">
          <div class="col">
            <div class="alert alert-danger alert-dismissible p-1 m-0">{{entity?.validation?.messages['manufacturer']}}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-4"><i class="fa fa-exclamation text-danger" *ngIf="entity?.validation?.messages['manufacturer']"></i>Manufacturer</div>
          <div class="col"> <manufacturer-logical-widget  *ngIf="manufacturer"  (widgetEvent)="eventHandler($event, 'manufacturer')" [entity]="manufacturer" [options]="options" [layout]="'standard'"></manufacturer-logical-widget> </div>
        </div>

        <!-- Batch Warning and Input -->
        <div class="row p-0 mt-3" *ngIf="entity?.validation?.messages['batch']">
          <div class="col">
            <div class="alert alert-danger alert-dismissible p-1 m-0">{{entity?.validation?.messages['batch']}}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-4"><i class="fa fa-exclamation text-danger" *ngIf="entity?.validation?.messages['batch']"></i>Batch</div>
          <div class="col"> <batch-logical-widget  *ngIf="batch" (widgetEvent)="eventHandler($event, 'batch')"  [entity]="batch" [options]="options" [layout]="'standard'"></batch-logical-widget> </div>
        </div>

      </div>

      <div *ngIf="!options?.edit" >
        <div class="row">
          <div class="col-4">Identifier</div>
          <div class="col pl-2"><b>{{entity.identifier || 'new'}}</b></div>
        </div>
        <div class="row">
          <div class="col-4">Name</div>
          <div class="col">{{entity.name}}</div>
        </div>
        <div class="row">
          <div class="col-4">Description</div>
          <div class="col">{{entity.description}}</div>
        </div>

        <div class="row">
          <div class="col-4">Range Start</div>
          <div class="col">{{entity.range_start}}</div>
        </div>
        <div class="row">
          <div class="col-4">Range End</div>
          <div class="col">{{entity.range_end}}</div>
        </div>

        <div class="row">
          <div class="col-4">Device Type</div>
          <div class="col"> <device-type-logical-widget *ngIf="device_type" [entity]="device_type" [options]="options" [layout]="'standard'"></device-type-logical-widget> </div>
        </div>

        <div class="row">
          <div class="col-4">Range Series</div>
          <div class="col"> <series-logical-widget *ngIf="series"   [entity]="series" [options]="options" [layout]="'standard'"></series-logical-widget> </div>
        </div>
        <div class="row">
          <div class="col-4">Manufacturer</div>
          <div class="col"> <manufacturer-logical-widget  *ngIf="manufacturer"  [entity]="manufacturer" [options]="options" [layout]="'standard'"></manufacturer-logical-widget> </div>
        </div>
        <div class="row">
          <div class="col-4">Batch</div>
          <div class="col"> <batch-logical-widget  *ngIf="batch"  [entity]="batch" [options]="options" [layout]="'standard'"></batch-logical-widget> </div>
        </div>

      </div>


    </div>
    <div class="device-range-widget row" *ngSwitchDefault>
      SIDEBAR
    </div>

  </ng-container>
</ng-container>
`
})
export class DeviceRangeWidgetComponent extends  NoizuStruct implements OnInit {
  _entity: SensorTypeRangeEntity | null = null;
  @Input() set entity(v: SensorTypeRangeEntity) {
     this._entity = v;

    if (this.layout == 'shadowbox') {
      // let  series =  this._entity.range_series
      this._entity.batch =  (this._entity.batch && this.isSRef(this._entity.batch)) ? this._entity.batch : (this._entity.batch ? this.identifierToSref('batch', this._entity.batch) : null)
      this._entity.manufacturer =  (this._entity.manufacturer && this.isSRef(this._entity.manufacturer)) ? this._entity.manufacturer : (this._entity.manufacturer ? this.identifierToSref('manufacturer', this._entity.manufacturer) : null)
      this._entity.device_type =  (this._entity.device_type && this.isSRef(this._entity.device_type)) ? this._entity.device_type : (this._entity.device_type ? this.identifierToSref('device-type', this._entity.device_type) : null)

      this.series.selected = this.series.subject = this._entity.range_series
      this.batch.selected = this.batch.subject = this._entity.batch
      this.manufacturer.selected = this.manufacturer.subject = this._entity.manufacturer
      this.device_type.selected = this.device_type.subject = this._entity.device_type
    }



  }
  get entity() {
    return this._entity;
  }

  @Input() templateHint: string = null;
  @Input() options: any = {};
  @Input() layout = 'standard';
  @Output() widgetEvent = new EventEmitter<DeviceWidgetEventStruct>();
  forwardEvent(e, widgetEvent: DeviceWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }

  sendShowEntry(e, entry: SensorTypeRangeEntity) {
    e.preventDefault()
    let event = new DeviceWidgetEventStruct();
    event.event_widget_type = this.entity.widget_type();
    event.event_device_widget_type = this.entity.device_widget_type()
    event.event_type = 'range_modal';
    event.event_body = {entity: entry}
    this.widgetEvent.emit(event);
  }

  sendShowDeviceType(e, entry: string) {
    e.preventDefault()
    let event = new DeviceWidgetEventStruct();
    event.event_widget_type = this.entity.widget_type();
    event.event_device_widget_type = this.entity.device_widget_type()
    event.event_type = 'device_definition_modal';

    if (this.deviceTypeCache[entry]) {
      event.event_body = {entity: this.deviceTypeCache[entry]}
      this.widgetEvent.emit(event);
    } else {
      this.device_type_repo.getEntityPromise(entry).then((entity) => {
        this.deviceTypeCache[entry] = entity;
        event.event_body = {entity: this.deviceTypeCache[entry]}
        this.widgetEvent.emit(event);
      })
    }
  }

  public deviceTypeCache: any= {};


  public series: SeriesLogicalWidget;
  public manufacturer: ManufacturerLogicalWidget;
  public batch: BatchLogicalWidget;
  public device_type: DeviceTypeLogicalWidget;
  // Sensor Type


  eventHandler(event: WidgetEventStruct, section) {
    console.log(event);

    if (event.event_type == 'device_type_selection_made') {
      this.entity.device_type = event.event_body.sref;
      this.entity._sensor_type_id = this.srefToIdentifier('device-type', event.event_body.sref, true)
      if (!this.entity.validation.valid && this.entity.validation.validated) this.entity.validate()
    }

    if (event.event_type == 'series_selection_made') {
      this.entity.range_series = event.event_body.series; // this.srefToIdentifier('series', newValue, false);
      if (!this.entity.validation.valid && this.entity.validation.validated) this.entity.validate()
    }

    if (event.event_type == 'batch_selection_made') {
      this.entity.batch = event.event_body.sref;
      if (!this.entity.validation.valid && this.entity.validation.validated) this.entity.validate()
    }

    if (event.event_type == 'manufacturer_selection_made') {
      this.entity.manufacturer = event.event_body.sref;
      if (!this.entity.validation.valid && this.entity.validation.validated) this.entity.validate()
    }

  }


  constructor(public device_type_repo: DeviceDefinitionRepo) {
    super();
    this.series = new SeriesLogicalWidget();
    this.manufacturer = new ManufacturerLogicalWidget();
    this.batch = new BatchLogicalWidget();
    this.device_type = new DeviceTypeLogicalWidget();
  }

  recordChange() {
    this.entity.meta.pending = true;
    if (this.entity.range_start) {
      this.entity.range_start = this.entity.range_start.trim()
    }
    if (this.entity.range_end) {
      this.entity.range_end = this.entity.range_end.trim()
    }
    if (this.entity.range_series) {
      this.entity.range_series = this.entity.range_series.trim()
    }
    if (!this.entity.validation.valid && this.entity.validation.validated) this.entity.validate()
  }

  ngOnInit(): void {
    if (this.layout == 'shadowbox') {
      // let  series =  this.entity.range_series
      this.entity.batch =  (this.entity.batch && this.isSRef(this.entity.batch)) ? this.entity.batch : (this.entity.batch ? this.identifierToSref('batch', this.entity.batch) : null)
      this.entity.manufacturer =  (this.entity.manufacturer && this.isSRef(this.entity.manufacturer)) ? this.entity.manufacturer : (this.entity.manufacturer ? this.identifierToSref('manufacturer', this.entity.manufacturer) : null)
      this.entity.device_type =  (this.entity.device_type && this.isSRef(this.entity.device_type)) ? this.entity.device_type : (this.entity.device_type ? this.identifierToSref('device-type', this.entity.device_type) : null)

      this.series.subject = this.entity.range_series
      this.batch.subject = this.entity.batch
      this.manufacturer.subject = this.entity.manufacturer
      this.device_type.subject = this.entity.device_type
    }

  }

}
