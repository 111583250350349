import {Component, Input, Inject, Output, EventEmitter, OnInit, AfterViewInit, ChangeDetectorRef, NgZone} from '@angular/core';
import {
  FirmwareLogicalWidgetEnum,
  LogicalDeviceWidgetEnum,
  LogicalExtendedDeviceWidgetEnum,
  LogicalWidgetEnum, ModalWidgetEnum,
  WidgetEnum
} from '../../../../enums/widget';
import {ChartFeed, DateTimeIntervalEntity, ExtendedDeviceChartEntity, ExtendedDeviceLogicalWidget, ModalWidget} from '../../../../entities';
import {FirmwareLogicalWidgetEventStruct, LogicalWidgetEventStruct} from '../../logical';
import {ValuesPipe} from 'ngx-pipes';
import {ToasterService} from 'angular2-toaster';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap/modal';
import {AuthService} from '../../../../services/auth.service';
import {FirmwareApprovalRepo, FirmwareDefinitionRepo, FirmwareLinkV2Repo} from '../../../../repos';
import {EntityReference} from '../../../../noizu/structs';


@Component({
  selector: 'extended-device-chart-logical-widget',
  template: `

    <ng-template #dataSource let-source>
      <div class="btn-group mr-1 ml-1">
        <button class="btn" type="button"
                [ngClass]="{
                'btn-success' : (selectedFeed && selectedFeed.key == source.key),
                'btn-info' : !(selectedFeed && selectedFeed.key == source.key) }"
                (click)="select($event, source.key)"
        >{{source.name}}</button>
        <button class="btn" type="button"
                [ngClass]="{
                'btn-success' : (selectedFeed && selectedFeed.key == source.key),
                'btn-info' : !(selectedFeed && selectedFeed.key == source.key) }"
                (click)="remove($event, source.key)"
        ><i class="fa fa-minus-circle"></i></button>
      </div>
    </ng-template>

    <ng-template #graphSource let-source>
      <div class="btn-group mr-1 ml-1">
        <button class="btn" type="button"
                [ngClass]="{
                'btn-success' : selected[source.key],
                'btn-info' : ! selected[source.key] }"
                (click)="toggleGraph($event, source.key)"
        >{{source.name}}</button>
        <button class="btn btn-lg btn-primary"
                (click)="fetchAndRefresh($event, source.key)"
        ><i class="fa fa-plus-circle"></i></button>
      </div>
    </ng-template>

    <ng-container [ngSwitch]="layout">
      <!-- ===================================== -->
      <!-- Default (Card)                        -->
      <!-- ===================================== -->
      <ng-container *ngSwitchDefault>
        <div class="extended-device-chart-logical-widget card">
          <div class="card-header">Feed</div>
          <div class="card-body">
            <div class="row mb-4">
              <div class="col-auto">
                <select class="form-control" name="aggregate" [(ngModel)]="time_period">
                  <option *ngFor="let entry of time_options" value="{{entry.value}}">
                    {{entry.text}}
                  </option>
                </select>
              </div>
              <div class="col-auto" *ngIf="entity">
                <select class="form-control" name="aggregate" [(ngModel)]="time_zone">
                  <option *ngFor="let entry of time_zone_options" value="{{entry.value}}">
                    {{entry.text}}
                  </option>
                </select>
              </div>
              <div class="col-auto" *ngIf="entity">
                <select class="form-control" name="aggregate" [(ngModel)]="for_field">
                  <option *ngFor="let entry of field_options" value="{{entry.value}}">
                    {{entry.text}}
                  </option>
                </select>
              </div>

              <div class="col-auto">
                <label class="switch switch-3d switch-primary float-right">
                  <input checked="" class="switch-input switch-primary" [(ngModel)]="metric_units" type="checkbox">
                  <span class="switch-slider"></span>
                </label>
                <span class="pl-2" *ngIf="metric_units">Metric</span>
                <span class="pl-2" *ngIf="!metric_units">Imperial</span>
              </div>


              <div class="col-1 text-center pt-1"><i class="fa fa-lg fa-2x fa-plus-circle text-success" (click)="addRange($event)"></i>
              </div>
              <div class="col-1 text-center pt-1"><i class="fa fa-lg fa-2x fa-calendar text-success"
                                                     (click)="customRange($event, chartModal)"></i></div>
            </div>


            <div class="row" *ngIf="entity">
              <tabset #view class="col">
                <tab heading="Table View">


                  <div class="row" *ngIf="entity">
                    <div class="col">
                      <ng-container *ngFor="let source of objectValues(entity.feeds)">
                        <ng-container *ngTemplateOutlet="dataSource; context: {$implicit: source}"></ng-container>
                      </ng-container>
                    </div>
                  </div>

                  <ng-container *ngIf="selectedFeed">


                    <div class="row mt-4">
                      <div class="col">
                        <h1>{{selectedFeed.name}} ({{selectedFeed.units}})</h1>
                      </div>
                    </div>


                    <div *ngIf="selectedFeed.variant_entries" class="row mt-4 border-bottom bg-dark text-white">
                      <div class="col-3"> Date</div>
                      <div class="col"> Variant</div>
                      <div class="col"> Spot</div>
                      <div class="col"> Average</div>
                      <div class="col"> High</div>
                      <div class="col"> Low</div>
                      <div class="col"> Tally</div>
                    </div>
                    <div *ngIf="!selectedFeed.variant_entries" class="row mt-4 border-bottom bg-dark text-white">
                      <div class="col-3"> Date</div>
                      <div class="col"> Spot</div>
                      <div class="col"> Average</div>
                      <div class="col"> High</div>
                      <div class="col"> Low</div>
                      <div class="col"> Tally</div>
                    </div>


                    <div class="row">
                      <div class="col text-center">
                        <new-spinner *ngIf="selectedFeed.fetching" [size]="'sm'"></new-spinner>
                        <div class="btn-group" *ngIf="!selectedFeed.fetching">
                          <button class="btn btn-primary p-1"
                                  (click)="fetchRecent($event, false, selectedFeed.period, selectedFeed.time_zone, selectedFeed.metric, selectedFeed.field)">
                            Fetch Newer
                          </button>
                          <button class="btn btn-primary p-1"
                                  (click)="fetchRecent($event, true, selectedFeed.period, selectedFeed.time_zone, selectedFeed.metric, selectedFeed.field)"
                          ><i class="fa fa-plus-circle"></i></button>
                        </div>
                      </div>
                    </div>

                    <ng-container *ngIf="selectedFeed.variant_entries" >
                      <div class="row border-bottom" *ngFor="let entry of selectedFeed.entries | values">
                        <div class="col-3"> {{entry.time | date : 'short'}} </div>
                        <div class="col"> {{entry.variant}} </div>
                        <div class="col"> {{entry.spot_value}} </div>
                        <div class="col"> {{entry.average_value}} </div>
                        <div class="col"> {{entry.maximum_value}} </div>
                        <div class="col"> {{entry.minimum_value}} </div>
                        <div class="col"> {{entry.tally_value}} </div>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="!selectedFeed.variant_entries" >
                      <div class="row border-bottom" *ngFor="let entry of selectedFeed.entries | values">
                        <div class="col-3"> {{entry.time | date : 'short'}} </div>
                        <div class="col"> {{entry.spot_value}} </div>
                        <div class="col"> {{entry.average_value}} </div>
                        <div class="col"> {{entry.maximum_value}} </div>
                        <div class="col"> {{entry.minimum_value}} </div>
                        <div class="col"> {{entry.tally_value}} </div>
                      </div>
                    </ng-container>

                    <div class="row mt-2">
                      <div class="col text-center">
                        <new-spinner *ngIf="selectedFeed.fetching" [size]="'sm'"></new-spinner>
                        <div class="btn-group" *ngIf="!selectedFeed.fetching">
                          <button class="btn btn-primary p-1"
                                  (click)="fetch($event, false, selectedFeed.period, selectedFeed.time_zone, selectedFeed.metric, selectedFeed.field)"
                          >Fetch More| {{selectedFeed.feed_coverage_start| date: 'short'}}</button>
                          <button class="btn btn-primary p-1"
                                  (click)="fetch($event, true, selectedFeed.period, selectedFeed.time_zone, selectedFeed.metric, selectedFeed.field)"
                          ><i class="fa fa-plus-circle"></i></button>
                        </div>
                      </div>
                    </div>

                  </ng-container>

                </tab>
                <tab heading="Graph">

                  <div class="row" *ngIf="entity">
                    <div class="col">
                      <ng-container *ngFor="let source of objectValues(entity.feeds)">
                        <ng-container *ngTemplateOutlet="graphSource; context: {$implicit: source}"></ng-container>
                      </ng-container>
                    </div>
                  </div>


                  <div style="height:600px;" class="col-12 my-3">
                    <div class="row col-12">
                      <div class="col-12" class="scrollCanvasWrapper">
                        <div class="scrollCanvas">


                          <plotly-plot [data]="graph.data" [layout]="graph.layout"
                                       [useResizeHandler]="true" [style]="{position: 'relative', width: '100%', height: '100%'}">
                          </plotly-plot>

                        </div>
                      </div>
                    </div>
                  </div>


                </tab>
              </tabset>
            </div>


          </div>
        </div>

      </ng-container>

    </ng-container>

    <!--                                                        -->
    <!--                    Pop-Up Menu                         -->
    <!--                                                        -->
    <ng-template #chartModal>
      <modal-widget *ngIf="currentModal" (widgetEvent)="eventHandler($event, 'pop-up', chartModal)" [entity]="currentModal"
                    [layout]="'shadowbox'" [options]="{}"></modal-widget>
    </ng-template>

  `
})
export class ExtendedDeviceChartLogicalWidgetComponent implements OnInit {
  @Input() entity: ExtendedDeviceChartEntity | null = null;
  @Input() templateHint: string = null;
  @Input() options: any = null;
  @Input() layout = 'standard';
  @Output() widgetEvent = new EventEmitter<LogicalWidgetEventStruct>();
  forwardEvent(e, widgetEvent: LogicalWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }

  public objectValues = Object.values;

  public  LogicalExtendedDeviceWidgetEnum = LogicalExtendedDeviceWidgetEnum;
  public selectedFeed: ChartFeed | null = null;
  public selected = {};
  public chart_type = 'line';
  public chart_data: Array<any> = [{data: [1,2,3,4,5,6,5,4,3,2,1], label: "loading"}];
  public chart_labels: Array<any> = ["loading"];
  public chart_options: any = {responsive: true, maintainAspectRatio: false};
  public chart_legend = false;
  public modalRef: BsModalRef;
  public currentModal: any = null;
  public time_period = 'ai.ticks.1';
  public time_options = [
    {value: 'ai.ticks.1', text: 'Plot'},
    {value: 'ai.minutes.1', text: '1 Minute'},
    {value: 'ai.minutes.5', text: '5 Minute'},
    {value: 'ai.minutes.15', text: '15 Minute'},
    {value: 'ai.minutes.30', text: '30 Minute'},
    {value: 'ai.hours.1', text: '1 Hour'},
    {value: 'ai.days.1', text: '1 Day'},
    {value: 'ai.weeks.1', text: '1 Week'},
    {value: 'ai.months.1', text: '1 Month'},
    {value: 'ai.years.1', text: '1 Year'},
  ];

  public time_zone = 'America/Chicago';
  public time_zone_options = [
    {value: 'America/Chicago', text: 'America/Chicago'},
    {value: 'Pacific/Honolulu', text: 'America/Honolulu'},
    {value: 'America/Anchorage', text: 'America/Anchorage'},
    {value: 'America/Los_Angeles', text: 'America/Los_Angeles'},
    {value: 'America/Denver', text: 'America/Denver'},
    {value: 'America/New_York', text: 'America/Chicago'},
    {value: 'Asia/Bangkok', text: 'Asia/Bangkok'},
    {value: 'Europe/Berlin', text: 'Europe/Berlin'},
    {value: 'Europe/Paris', text: 'Europe/Paris'},
    {value: 'Asia/Chongqing', text: 'Asia/Chongqing'},
  ]

  public for_field = null;
  public metric_units = false;
  public field_options = [];
  public range_selected = false;

  constructor(public toasterService: ToasterService, public modalService: BsModalService) {
  }


  eventHandler(event, field, modal) {
    console.log("Release Unhandled Event", field, event);
  }

  showModal(current, modal, clickOut = false, customClass="") {
    this.currentModal = current;
    if (!clickOut) {
      let config: ModalOptions = {
        backdrop : 'static',
        keyboard : false,
        class: customClass
      };
      this.currentModal.modalRef = this.modalService.show(modal, config);
    } else {
      let config: ModalOptions = {
        class: customClass
      };
      this.currentModal.modalRef = this.modalService.show(modal, config);
    }
  }

  addRange(e) {
    e.preventDefault();
    this.entity.fetchFeed(this.time_period, this.time_zone, this.metric_units, this.for_field).then((feed: ChartFeed) => {
      if (!this.range_selected) {
        this.selectedFeed = feed;
        this.range_selected = true;
      }
    });
  }

  customRange(e, modal) {
    let to = new Date();
    let from = new Date(to.getTime() - (24 * 60 * 60 * 1000));
    let time_range = new DateTimeIntervalEntity(from, to);
    let dialog = new ModalWidget(`Pull Range`, ModalWidgetEnum.MODAL_WIDGET__CONFIRM, time_range, {edit: true}, 'shadowbox', time_range.overrides());
    this.showModal(dialog, modal, false, 'md-modal');
    time_range.promise.then((r) => {
      this.entity.fetchFeed(this.time_period, this.time_zone, this.metric_units, this.for_field, {from: r.from, to: r.to}).then((feed: ChartFeed) => {
        if (!this.range_selected) {
          this.selectedFeed = feed;
          this.range_selected = true;
        }
      });

    }).catch(() => {
      // swallow
    });
  }

  public chartClicked(e: any): void {
    // console.log(e);
  }

  public chartHovered(e: any): void {
    // console.log(e);
  }


  select(e, key) {
    e.preventDefault();
    this.selectedFeed = this.entity.feeds[key];
  }

  remove(e, key) {
    e.preventDefault();
    if (this.selectedFeed && this.selectedFeed.key == key) {
      this.selectedFeed = null;
    }
    delete this.entity.feeds[key];
    this.selected[key] = false;
  }


  public graph = {
    data: [
      { x: [], y: [], type: 'scatter', mode: 'lines+points', marker: {color: 'red'} },

    ],
    layout: {width: 640, height: 480, title: 'Plot'}
  };

  public toggleGraph(e, key) {
    e.preventDefault();
    this.selected[key] = !(this.selected[key] || false);
    this.buildGraph()
  }

  public buildGraph() {
    let data = [];
    for(let k in this.selected) {
      if (this.selected[k]) {
        let feed = this.entity.feeds[k];
        let x = [];
        let y = [];
        for (let i = 0; i < feed.entries.length; i++) {
          x.push(feed.entries[i].time);
          y.push(feed.entries[i].spot_value || feed.entries[i].average_value || feed.entries[i].tally_value || 0);
        }
        data.push({x: x, y: y, type: 'line', mode: 'lines', name: this.entity.feedName(feed.period, feed.time_zone, feed.metric, feed.field)})
      }
    }
    this.graph.data = data;
  }

  public fetchRecent(e, more, time_period, time_zone, metric, for_field) {
    e.preventDefault();
    this.entity.fetchFeed(time_period, time_zone, metric, for_field, {recent: true, more: more});
  }

  public fetch(e, more, time_period, time_zone, metric, for_field) {
    e.preventDefault();
    this.entity.fetchFeed(time_period, time_zone, metric, for_field, {more: more});
  }

  public fetchAndRefresh(e, key) {
    e.preventDefault();
    let feed = this.entity.feeds[key];
    this.entity.fetchFeed(feed.period, feed.time_zone, feed.metric, feed.field, {more: true}).then(() =>
      this.entity.fetchFeed(feed.period, feed.time_zone, feed.metric, feed.field, {recent: true}).then(() => {
          this.buildGraph();
        }
      )
    );
  }

  ngOnInit() {
    if (this.entity && this.entity.device_type && this.entity.device_type.fields) {
      this.field_options = [];
      for (let key of Object.keys(this.entity.device_type.fields)) {
        if (key != 'notSupported' && key != 'NotSupported') {
          if (!this.for_field) this.for_field = key;
          this.field_options.push({value: key, text: key});
        }
      }
    }
  }

}
