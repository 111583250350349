
import { Component, Inject, Input, NgZone } from '@angular/core';
import {HealthReportRepo} from '../../../repos/health-report.repo';

@Component({
  templateUrl: './nodes.component.html',
})
export class SystemHealthNodesComponent {
  public nodes = [];
  public node_reports = {};
  public loading = true;
  public objectKeys(o) {
    return Object.keys(o).sort();
  }

  constructor(reportRepo: HealthReportRepo) {
    reportRepo.getNodes().then(
      (v: any) => {
        let nodes = v;
        this.nodes = [];
        let count = nodes.length;
        for(let k = 0; k < nodes.length; k++) {
          let node = nodes[k];
          this.nodes.push({value: node, label: node});
          reportRepo.getNodeReport(node).then((v) => {
            this.node_reports[node] = v;
              this.loading = !(--count == 0);
          });
        }
      }
    );
  }
}
