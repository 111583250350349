import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToasterService} from 'angular2-toaster';
import {AlertEntity} from '../../../entities/alert.entity';
import {WidgetComponent} from '../../widget.component';
import {WidgetBase} from '../widget-base';
import {UserEntity} from '../../../entities';
import {SubscriberWidgetEventStruct} from '../subscriber-object/subscriber-widget-event.struct';
import {UserWidgetEventStruct} from './user-widget-event.struct';
import {UserPreferencesService} from '../../../services/user-preferences.service';

@Component({
  selector: 'user-preferences-widget',
  template: `
    <div class="card shadow" *ngIf="entity">
      <div class="card-header bg-success">
        <div class="row">
          <div class="col">
            Saved Preferences
            <span *ngIf="options" class="float-right"><i (click)="toggleExpand()" class="fa fa-sm" [ngClass]="{'fa-chevron-down': !options.expand, 'fa-chevron-up': options.expand}"> </i></span>
          </div>
        </div>
      </div>
      <div class="card-body p-1 m-0 bg-light" *ngIf="entity && expandWidget()">
        <div class="card-body">
          <div class="row"><div class="col">Feature Versions</div></div>
          <pre>{{preferences.feature_version| json}}</pre>
          <div class="row"><div class="col">Heading Minimization ({{this.preferences.minimize_default ? 'minimize default' : 'expand default'}})</div></div>
          <div class="col-12" >
            <div class="table-responsive">
              <table class="table">
                <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Value</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let pref of user_preferences">
                  <td>{{pref.key}}</td>
                  <td>{{pref.value}}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class UserPreferencesWidgetComponent extends WidgetBase implements OnInit {
  public editMode = false;
  @Input() layout: string = null;
  @Input() entity: UserEntity;
  @Input() options: any;
  @Output() widgetEvent = new EventEmitter<UserWidgetEventStruct>();
  forwardEvent(e, widgetEvent: UserWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }

  public user_preferences: any = [];

  constructor(public toasterService: ToasterService, public preferences: UserPreferencesService) {
    super();
  }



  toggleExpand() {
    super.toggleExpand();
    if (this.expandWidget() && !this.entity.settings) {
      this.entity.getUserSettingsPromise().then();
    }
  }

  public ngOnInit() {
    this.getUserPreferences();
  }

  getUserPreferences() {
    if (this.preferences) {
      let p = this.preferences.minimize_section;
      this.user_preferences = [];
      for (let o in p) {
        //console.log("key:", o, "value:", p[o]);
        this.user_preferences.push({key: o, value: p[o]});
      }
      //console.log(this.myPreferences);
    }
  }

}
