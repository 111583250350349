export enum NodeTypeEnum {
  NullNode =  0,
  ContentNode =  1,
  OrNode =  2,
  AndNot =  3,
  XorNode =  4,
  NotAndNode =  5,
  NotOrNode =  6,
  NotXorNode =  7,
  IfNode =  8,
  SwitchNode =  9,
  AdditionNode =  10,
  SubtractionNode =  11,
  MultiplicationNode =  12,
  DivisionNode =  13,

  EqualsNode =  14,
  ApproximatelyEqualsNode =  15,

  LessThanNode =  16,
  LessThanOrEqualNode =  17,

  GreaterThanNode =  18,
  GreaterThanOrEqualNode =  19,

  BetweenRangeNode =  20,
  OutsideRangeNode =  21,

  PowerNode =  22,

  RemoveFillerNode =  23,

  HeatIndexFormulaNode =  100,
  WindChillFormulaNode =  101,
  FeelsLikeFormulaNode =  102,

  HumidityNode =  200,
  TemperatureNode =  201,
  WindHeadingNode =  202,
  WindSpeedNode =  203,
  RainAccumulationNode =  204,
  LightningNode =  205,

  PrecipitationLevelNode =  206,
  WindLevelNode =  207,



  LegacyFieldNode =  300,



  NotSupportedNode =  400
}
