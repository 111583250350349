
import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from '../lacrosse.entity';
import { FirebaseAuthService }            from '../../noizu/services/firebase-auth.service';
import {WidgetEnum} from '../../enums';

export class UserDeviceAssociationEntity extends LacrosseEntity {
  public status = null;
  public expanded = false;
  public editMode = false;
  public saving = false;
  public isStation = false;
  public sensor_serial = "";
  public locationName = "";
  public location_id = 0;
  public device_name = "Default";
  public model_number = "";
  public type_name = "";
  public sensor_identifier= "";
  public geo = {"anonymous":false,"pc":"","timezone":"","countryCode":"US"};
  public showReloc = false;
  public newPos = null;
  public pos = 0;
  public last = false;
  public isfact23 = false;
  public _sref_module = 'user-device';

  public editBackup = {};

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    this.reload(json);
  } // end constructor

  reload(json) {
    if (json.name) {
      this.device_name = json.name;
    }
    if(json.sensor) {
      this.model_number = json.sensor.type.internalName;
      this.sensor_serial = json.sensor.serial;
      if (json.sensor && json.sensor.attributes && json.sensor.attributes.display == "1")
        this.isStation = true;
      if (json.sensor && json.sensor.attributes && json.sensor.attributes.factory == "23")
        this.isfact23 = true;
      if (json.sensor.geo && this.isfact23){
        this.geo.pc = json.sensor.geo.zip;
        this.geo.timezone = json.sensor.geo.timezone;
        this.geo.anonymous = json.sensor.geo.anonymous;
        this.geo.countryCode = json.sensor.geo.countryCode;
      } else {
        if (this.isfact23) {
          this.geo = null;
        }
      }
      this.model_number = json.sensor.type.internalName;
      this.type_name = json.sensor.type.name;
      this.sensor_identifier = json.sensor.id;
    }
  }

  getGeo() {
    return this._get(`${this.ingvEndpoint()}/forecast/admin/id/${this.sensor_serial}?details=false`, (data, resolve) => {
      if (data.zip) {
        this.geo.pc = data.zip;
      } else if (data.city){
        this.geo.pc = data.city;
      }else {
        this.geo.anonymous = true;
      }
      this.geo["called"] = true;
      resolve(data);
    }, {}).catch((e)=> {
      console.log(e);
    })
  }


  getStatusPromise() {
      return this._get(`${this.ingvEndpoint()}/active-user/device-association/ref.device.${this.sensor_serial}@${this.raw.sensor.series}/status?tz=America/Chicago`, (data, resolve) => {
          this.status = {"lastUpdate":data.last_update}; resolve(true);
      }, {}).catch((err)=> {
        this.status={"lastUpdate":null};
      });
  }

  deleteAssociation() {
    let assocID = this.raw.id;
    let locationID = this.raw.locationId;
    let ownerID = this.raw.ownerId;
    let url = `${this.apiBase()}/user/${ownerID}/location/${locationID}/sensorAssociation/${assocID}`;

    return this._delete(
      url,
      (_data, resolve) => {resolve(true)},
      {}
    );
  }

  createAssociation(uid) {
    if(this.device_name.length == 0) {
      this.device_name = "Default";
    }
    let data = {
      "name":this.device_name,
      "sensorId":this.sensor_identifier,
    	"locationId":this.location_id,
    	"ownerId":uid
    };

    let url = `${this.apiBase()}/user/${uid}/location/${this.location_id}/sensorAssociations`;
    return this._post(url, data, (_data, resolve) => {resolve(true)},{} );
  }

  reposition(uid, direction) {
    let d = "increment"
    if (direction === 1) {
      d = "decrement"
    }
    let url = `${this.apiBase()}/user/${uid}/location/${this.location_id}/sensorAssociation/${this.identifier}/rank/${d}`;
    return this._post(
      url,{},
      (_data, resolve) => {resolve(true)},
      {}
    );
  }

  editPrep() {
    this.editBackup["name"] = this.device_name;
    if (this.isStation) {
      this.editBackup["timezone"] = this.geo.timezone;
      this.editBackup["pc"] = this.geo.pc;
      this.editBackup["anon"] = this.geo.anonymous;
    }
  }

  cancelEdit() {
    this.device_name = this.editBackup["name"] ;
    if (this.isStation) {
      this.geo.timezone = this.editBackup["timezone"];
      this.geo.pc = this.editBackup["pc"];
      this.geo.anonymous = this.editBackup["anon"];
    }
  }

  updateAssociation(uid) {
    let data = {
      "name":this.device_name,
    	"locationId":this.location_id
    };
    let url = `${this.apiBase()}/user/${uid}/location/${this.location_id}/sensorAssociation/${this.identifier}`;
    return this._put(url, data, (_data, resolve) => {resolve(true)},{} );
  }

  updateGeoLegacy() {
    let data = {
      "zip":this.geo.pc,
    	"timezone":this.geo.timezone,
      "anonymous":this.geo.anonymous,
      "countryCode":this.geo.countryCode
    };
    let url = `${this.apiBase()}/sensor/${this.raw.sensorId}/setGeo`;
    return this._put(url, data, (_data, resolve) => {resolve(true)},{} );
  }

  searchGeoCode() {
    let url = `${this.ingvEndpoint()}/forecast/client/cityorpc/${this.geo.pc}/cc/${this.geo.countryCode}`;

    return this._get(url,(data, resolve)=> {
      resolve(data);
    })
    /*  //Sample locations for testing
    return new Promise((resolve, reject) => {
      resolve({"locations":[
        {"city_name":"New York","country_code":"US","acw_key":"3697_PC","administrative_area":"NY","tz_name":"America/New_York"},
        {"city_name":"Beverly Hills","country_code":"US","acw_key":"37935_PC","administrative_area":"NY","tz_name":"America/New_York"}
      ]})
    });
    */
  }

  assignGeo(key,ccode) {
    if (key == "") {
      this.geo.pc = "";
      ccode = "";
    }
    let data = {
      "city_or_postal_code":this.geo.pc,
    	"acw_key":key,
      "country_code":ccode
    };
    //https://ingv2.lacrossetechnology.com/api/v1.1/forecast/admin/location/device/20022E
    let url = `${this.ingvEndpoint()}/forecast/admin/location/device/${this.sensor_serial}`;
    //console.log(url, data);
    return this._post(url, data, (_data, resolve) => {resolve(true)},{} );
  }


  widget_type() {
    return WidgetEnum.EMBED_WIDGET__USER_DEVICE_ASSOCIATION_ENTITY;
  }

} // end Package
