import {HttpClient} from '@angular/common/http';
import { FirebaseAuthService }            from '../../noizu/services/firebase-auth.service';
import {ElixirEntity} from '../elixir.entity';
import {PinEntity} from './pin.entity';
import {WidgetEnum} from '../../enums';
import {DeviceEntity} from '../device.entity';
import {DeviceSet} from '../device';

import {PinSet} from './pin.set';

export class PinGroupEntity extends ElixirEntity {
  public name: string;
  public description: string;
  public pins: Array<any> = []
  public created_on: Date;
  public modified_on: Date;
  public _singular = "entity-pin-group";
  public _kind = "entity-pin-groups";
  public _sref_module = 'entity-pin-group';
  public groups: any = {};
  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    this.refresh(json);
  } // end constructor

  refresh(json) {
    this.identifier = json["identifier"]
    this.name = json["name"];
    this.description = json["description"];
    this.created_on = json["created_on"] && new Date(json["created_on"])
    this.modified_on = json["modified_on"] && new Date(json["modified_on"])
    this.pins = [];
    this.groups = {};
    if (json["pins"]) {
      for (let pin in json["pins"]) {
        let p = new PinEntity(this.client, this.auth, json["pins"][pin])
        this.pins.push(p)
      }
      this.pins.sort((a: PinEntity, b: PinEntity) => {return a.identifier <= b.identifier ? -1 : 1})
    }
    return this;
  }

  get device_pins() {
    if (this.groups['device_pins']) {
      return this.groups['device_pins'];
    } else {
      let set = new Array<PinEntity>();
      for (let p in this.pins) {
        let pin = this.pins[p];
        if (pin instanceof PinEntity && pin.subject instanceof DeviceEntity) {
            set.push(pin);
        }
      }
      this.groups['device_pins'] = new PinSet(set);
      return this.groups['device_pins'];
    }
  }

  get devices() {
      if (this.groups['devices']) {
        return this.groups['devices'];
      } else {
        let set = new Array<DeviceEntity>();
        for (let p in this.pins) {
          let pin = this.pins[p];
          if (pin.subject instanceof DeviceEntity) {
            set.push(pin.subject);
          }
        }
        this.groups['devices'] = new DeviceSet(set);
        return this.groups['devices'];
      }
  }

  updateEndpoint() {
    return `${this.apiBase()}/admin-tools/user/entity-pin-groups/${this.identifier}`;
  }

  createEndpoint() {
    return `${this.apiBase()}/admin-tools/user/entity-pin-groups`;
  }

  deleteEndpoint() {
    return `${this.apiBase()}/admin-tools/user/entity-pin-groups/${this.identifier}`;
  }

  validate() {

    let valid = true;
    this.validation = {valid: false, validated: true,  error: null, messages: {common: null}}

    if (!this.name) {
      valid = false;
      this.validation.messages['name'] = "Name Field is Required."
    }
    if (!this.description) {
      valid = false;
      this.validation.messages['description'] = "Description Field is Required."
    }

    if (valid) {
      this.validation.valid = true;
    } else {
      this.validation.valid = false;
      this.validation.error = "Validation Failed."
      this.validation.messages.common = "Please Resolve Following Validation Errors."
    }

    return this.validation;
  }

  widget_type() {
    return WidgetEnum.EMBED_WIDGET__PIN_GROUP;
  }


  filter(filter: string) {
    if (filter) {
      filter = filter.toLowerCase();
      if (this.meta['filter'] == filter) return this.meta['filter_result'];

      // Prep Lower Case Data
      if (!this.meta['filter_set']) {
        this.meta['filter_set'] = {};
        this.meta['filter_set']['name'] = this.name || '';
        this.meta['filter_set']['description'] = this.description  || '';
        this.meta['filter_set']['identifier'] = `${this.identifier}`;
      }

      this.meta['filter'] = filter;
      this.meta['filter_result'] = false;
      if (this.meta.filter_set.name.includes(filter)) this.meta['filter_result'] = true;
      else if (this.meta.filter_set.description.includes(filter)) this.meta['filter_result'] = true;
      else if (this.meta.filter_set.identifier.includes(filter)) this.meta['filter_result'] = true;
      return this.meta.filter_result;
    }  else {
      return true;
    }
  }


} // end Package
