export const PermissionsDD = Object.freeze({
    list: [
      "super-user",
      "sys-admin",
      "support-admin",
      "modify-groups",
      "user-search",
      "device-assign",
      "device-search",
      "user-update",
      "read-gateway",
      "read-groups",
      "update-groups",
      "read-appNotifications",
      "user-list",
      "call-center",
      "view_user_subscriptions",
      "edit_user_subscriptions",
      "device_type_admin",
      "firmware_admin",
    ]
});
