import {Component, Input, Output, EventEmitter} from '@angular/core';
import {EntityLogEntity} from '../../../../entities/log/entity-log.entity';
import {WidgetBase} from '../../widget-base';
import {LogWidgetEventStruct} from '../log-widget-event.struct';
import {GatewayLogWidgetEnum, LogWidgetEnum} from '../../../../enums';
import {GatewayEntityLogEntity} from '../../../../entities';

@Component({
  selector: 'gateway-log-details-widget',
  template: `
    <ng-container [ngSwitch]="entity.gateway_log_widget_type()">
        <gateway-wake-log-details-widget *ngSwitchCase="GatewayLogWidgetEnum.GATEWAY_LOG_WIDGET__WAKE" [entity]="entity" [options]="options" [layout]="layout"> </gateway-wake-log-details-widget>
        <gateway-extract-binary-log-details-widget *ngSwitchCase="GatewayLogWidgetEnum.GATEWAY_LOG_WIDGET__EXTRACT_BINARY" [entity]="entity" [options]="options" [layout]="layout"> </gateway-extract-binary-log-details-widget>
        <gateway-extract-binary-error-log-details-widget *ngSwitchCase="GatewayLogWidgetEnum.GATEWAY_LOG_WIDGET__EXTRACT_BINARY_ERROR" [entity]="entity" [options]="options" [layout]="layout"> </gateway-extract-binary-error-log-details-widget>
        <gateway-report-binary-log-details-widget *ngSwitchCase="GatewayLogWidgetEnum.GATEWAY_LOG_WIDGET__REPORT_BINARY" [entity]="entity" [options]="options" [layout]="layout"> </gateway-report-binary-log-details-widget>
        <gateway-stage-one-log-details-widget *ngSwitchCase="GatewayLogWidgetEnum.GATEWAY_LOG_WIDGET__STAGE_ONE" [entity]="entity" [options]="options" [layout]="layout"> </gateway-stage-one-log-details-widget>
        <log-details-generic-widget *ngSwitchDefault [entity]="entity" [options]="options" [layout]="layout"> </log-details-generic-widget>
    </ng-container>
    `
})
export class GatewayLogDetailsWidgetComponent extends WidgetBase{
  @Input() entity: GatewayEntityLogEntity = null;
  @Input() options: any = {};
  @Input() layout: string = null;
  @Output() widgetEvent = new EventEmitter<LogWidgetEventStruct>();
  forwardEvent(e, widgetEvent: LogWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }
  public GatewayLogWidgetEnum = GatewayLogWidgetEnum;
  public expand = false;
  public expandRaw = false;

  constructor() {
    super();

  }
}
