
    <ng-container *ngIf="entity">
      <div class="device-target-widget card">
        <div class="card-header text-white" [ngClass]="{'bg-gray-400': entity.target_stage, 'bg-gray-600': !entity.target_stage}">
          <div class="row  pb-0 pt-0 mb-0 mt-0">
            <div class="col-1  pb-0 pt-0 mb-0 mt-0">
              <label class="switch switch-pill switch-dark pb-0 pt-0 mb-0 mt-0">
                <input [disabled]="!options?.edit" checked="" class="switch-input" (change)="toggleTarget($event)"  [(ngModel)]="entity.target_stage" type="checkbox">
                <span class="switch-slider"></span>
              </label>
            </div>
            <div class="col text-left  pb-0 pt-0 mb-0 mt-0">
              <div class="ml-4">
                <div class="lead">
                  <ng-container *ngIf="entity.target_stage">Stage</ng-container>
                  <ng-container *ngIf="!entity.target_stage">Production</ng-container>
                </div>
              </div>
            </div>
            <div class="col  pb-0 pt-0 mb-0 mt-0">
              <div class="card-header-actions">
                <span class="dragula-handle"><i class="dragula-handle text-black-50 fa fa-lg fa-arrows"></i></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
