import {Component, Input, Inject, NgZone} from '@angular/core';
import {FirmwareLinkV2Entity} from "../../../entities/firmware/link-v2.entity";
import {FirmwareReleaseEntity} from '../../../entities/firmware/release.entity';
import {EntityArchiveRepo} from '../../../repos/entity-archive.repo';

@Component({
  selector: 'embed-mcu-firmware-release',
  template: `
    <div [ngSwitch]="layout">

      <div *ngSwitchCase="'thumb'" class="card text-white" [ngClass]="releaseStatusStyle()">
        <div *ngIf="!entity" class="card-header p-1">Release| (Loading)</div>
        <div *ngIf="!entity" class="card-body bg-white">
          <widget-spinner></widget-spinner>
        </div>
        <div *ngIf="entity" class="card-header p-1 text-center">Release| {{entity.release_name}}</div>
        <div *ngIf="entity" class="card-body text-black-50 bg-white p-1">
          <div class="row">
            <div class="col-12 text-center">(Status {{releaseStatus()}})</div>
          </div>
          <div class="row" *ngIf="entity.status">
            <div class="col-12" >
          <canvas baseChart class="chart"
                  [data]="releaseRollOutPieData"
                  [labels]="releaseRollOutPieLabels"
                  [legend]="false"
                  [chartType]="'pie'"
                  (chartHover)="chartHovered('releaseRollout', $event)"
                  (chartClick)="chartClicked('releaseRollout', $event)"></canvas>
            </div>
          </div>
        </div>
      </div>

      <div *ngSwitchCase="'full-screen'">
        [Pending]
      </div>

      <div *ngSwitchDefault class="card">
        <div *ngIf="!entity" class="card-header text-white p-1" [ngClass]="releaseStatusStyle()">Release| (Loading)</div>
        <div *ngIf="!entity" class="card-body bg-white text-body">
          <widget-spinner></widget-spinner>
        </div>
        <div *ngIf="entity" class="card-header text-white p-1" [ngClass]="releaseStatusStyle()">Release| {{entity.release_name}} (Status {{releaseStatus()}})</div>
        <div *ngIf="entity" class="card-body bg-white text-body p-1">



          <div class="row">
            <div class="col-7">
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body bg-white text-body">
                      <div class="row">
                        <div class="col-5"><strong>Approval:</strong></div><div class="col-7"><a href="/firmware/approvals/{{entity.approval_entity?.sref()}}">{{entity.approval_entity?.sref()}}</a></div>
                      </div>
                      <div class="row">
                        <div class="col-5"><strong>Firmware:</strong></div><div class="col-7"><a href="/firmware/definitions/{{entity.firmware_entity?.sref()}}">{{entity.firmware_entity?.sref()}}</a> ({{entity.firmware_entity?.firmwareVersion}})</div>
                      </div>
                      <div class="row">
                        <div class="col-5"><strong>Alternate:</strong></div><div class="col-7"><a href="/firmware/definitions/{{entity.rollback_entity?.sref()}}">{{entity.rollback_entity?.sref()}}</a> ({{entity.rollback_entity?.firmwareVersion}})</div>
                      </div>
                      <div class="row" *ngIf="entity.roll_out_report?.release_status?.managed_firmware_links">
                        <div class="col-5"><strong>Firmware Associations:</strong></div>
                        <div class="col-7">
                        <span *ngFor="let link of entity.roll_out_report?.release_status?.managed_firmware_links">
                            <a href="/firmware/links/{{link}}">{{link}}</a>
                        </span>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body bg-white text-body">
                      <div class="row">
                        <div class="col-6"><strong>Worker Status:</strong></div><div class="col-6">{{entity.roll_out_report?.release_status?.status}}</div>
                      </div>

                      <div class="row">
                        <div class="col-6"><strong>Current Stage:</strong></div><div class="col-6">{{entity.roll_out_report?.release_status?.active_group}}</div>
                      </div>

                      <div class="row">
                        <div class="col-6"><strong>Active Units:</strong></div><div class="col-6">{{entity.roll_out_report?.managed_units}}</div>
                      </div>
                      <div class="row">
                        <div class="col-6"><strong>Control Units:</strong></div><div class="col-6">{{entity.roll_out_report?.control_units}}</div>
                      </div>


                      <div class="row">
                        <div class="col-6">Started:</div><div class="col-6">(...)</div>
                      </div>
                      <div class="row">
                        <div class="col-6">Completed:</div><div class="col-6">(...)</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-header bg-secondary">Group Assignment22</div>
                    <div class="card-body bg-white mt-1 pt-1">
                      <div class="row mb-1"><div class="col-6"><strong class="text-black-50">Control Group</strong></div><div class="col-6">{{entity.roll_out_report?.managed_workers?.group_totals["control"]| json}}</div></div>
                      <div class="row"><div class="col-6"><strong [ngClass]="{'text-black-50': (entity.roll_out_report?.release_status?.active_group  && entity.roll_out_report?.release_status?.active_group < 1)}">Stage 1 Group</strong></div><div class="col-6">{{entity.roll_out_report?.managed_workers?.group_totals["1"]| json}}</div></div>
                      <div class="row"><div class="col-6"><strong [ngClass]="{'text-black-50': (entity.roll_out_report?.release_status?.active_group  && entity.roll_out_report?.release_status?.active_group < 2)}">Stage 2 Group</strong></div><div class="col-6">{{entity.roll_out_report?.managed_workers?.group_totals["2"]| json}}</div></div>
                      <div class="row"><div class="col-6"><strong [ngClass]="{'text-black-50': (entity.roll_out_report?.release_status?.active_group  && entity.roll_out_report?.release_status?.active_group < 3)}">Stage 3 Group</strong></div><div class="col-6">{{entity.roll_out_report?.managed_workers?.group_totals["3"]| json}}</div></div>
                      <div class="row"><div class="col-6"><strong [ngClass]="{'text-black-50': (entity.roll_out_report?.release_status?.active_group  && entity.roll_out_report?.release_status?.active_group < 4)}">Stage 4 Group</strong></div><div class="col-6">{{entity.roll_out_report?.managed_workers?.group_totals["4"]| json}}</div></div>
                      <div class="row"><div class="col-6"><strong [ngClass]="{'text-black-50': (entity.roll_out_report?.release_status?.active_group  && entity.roll_out_report?.release_status?.active_group < 5)}">Stage 5 Group</strong></div><div class="col-6">{{entity.roll_out_report?.managed_workers?.group_totals["5"]| json}}</div></div>
                      <div class="row"><div class="col-6"><strong [ngClass]="{'text-black-50': (entity.roll_out_report?.release_status?.active_group  && entity.roll_out_report?.release_status?.active_group < 6)}">Stage 6 Group</strong></div><div class="col-6">{{entity.roll_out_report?.managed_workers?.group_totals["6"]| json}}</div></div>
                      <div class="row"><div class="col-6"><strong [ngClass]="{'text-black-50': (entity.roll_out_report?.release_status?.active_group  && entity.roll_out_report?.release_status?.active_group < 7)}">Stage 7 Group</strong></div><div class="col-6">{{entity.roll_out_report?.managed_workers?.group_totals["7"]| json}}</div></div>
                      <div class="row"><div class="col-6"><strong [ngClass]="{'text-black-50': (entity.roll_out_report?.release_status?.active_group  && entity.roll_out_report?.release_status?.active_group < 8)}">Stage 8 Group</strong></div><div class="col-6">{{entity.roll_out_report?.managed_workers?.group_totals["8"]| json}}</div></div>
                      <div class="row"><div class="col-6"><strong [ngClass]="{'text-black-50': (entity.roll_out_report?.release_status?.active_group  && entity.roll_out_report?.release_status?.active_group < 9)}">Stage 9 Group</strong></div><div class="col-6">{{entity.roll_out_report?.managed_workers?.group_totals["9"]| json}}</div></div>
                      <div class="row"><div class="col-6"><strong [ngClass]="{'text-black-50': (entity.roll_out_report?.release_status?.active_group  && entity.roll_out_report?.release_status?.active_group < 10)}">Stage 10 Group</strong></div><div class="col-6">{{entity.roll_out_report?.managed_workers?.group_totals["10"]| json}}</div></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-header bg-primary">Targets</div>
                    <div class="card-body bg-white text-body">
                      <pre>{{entity?.targets| json}}</pre>
                    </div>
                  </div>
                </div>
              </div>



              <div class="row">
                <div class="col-12">
                  <pre>
                    {{entity.roll_out_report?.release_status |json}}
                  </pre>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <pre>
                    {{entity.roll_out_report?.managed_workers |json}}
                  </pre>
                </div>
              </div>


            </div>

            <div class="col-5">
              <!-- Charts -->
              <!-- Pie -->
              <div class="row">
                <div class="col-12" >
                  <div class="card bg-info">
                    <div class="card-header bg-info">Rollout Snapshot</div>
                    <div class="card-body bg-white">
                      <canvas baseChart class="chart"
                              [data]="releaseRollOutPieData"
                              [labels]="releaseRollOutPieLabels"
                              [chartType]="'pie'"
                              (chartHover)="chartHovered('releaseRollout', $event)"
                              (chartClick)="chartClicked('releaseRollout', $event)"></canvas>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Detailed Status: Bar -->
              <div class="row">
                <div class="col-12" >
                  <div class="card bg-info">
                    <div class="card-header bg-info">Release Stages</div>
                    <div class="card-body bg-white">
                      <canvas baseChart class="chart"
                              [datasets]="groupStageChartData"
                              [labels]="groupStageChartLabels"
                              [legend]="false"
                              [options]="groupStageChartOptions"
                              [chartType]="'bar'"
                              (chartHover)="chartHovered('groupStageChat', $event)"
                              (chartClick)="chartClicked('groupStageChat', $event)"></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>`
})
export class EmbedMCUFirmwareReleaseComponent {
  @Input() entity: FirmwareReleaseEntity = null;
  @Input() options: any = null;
  @Input() layout: string = null;

  // Pie
  public releaseRollOutPieLabels: string[] = ['Querying'];
  public releaseRollOutPieData: number[] = [1];

  public groupStageChartOptions = {
    scales: {
      xAxes: [{
        stacked: true
      }],
      yAxes: [{
        stacked: true
      }]
    }
  };
  public groupStageChartLabels: string[] = ['Querying'];
  public groupStageChartData: any[] = [
    {data: [0], label: 'Group1'},
  ];

  constructor() {

  }


  ngOnInit() {
    console.log("LOAD RollOutReport", this.entity);
    if (this.entity) {
      this.entity.getRollOutReport().then((report: any) => {
        console.log("Report", report);


        if (report['roll_up']) {
          this.releaseRollOutPieLabels = ['Pending', 'Baseline', 'Revert', 'First Install', 'First Rollback', 'Second Install', 'Second Rollback', 'Final Install', 'Flagged'];
          this.releaseRollOutPieData = [
            report.roll_up.pending,
            report.roll_up.baseline,
            report.roll_up.revert,
            report.roll_up.first_install,
            report.roll_up.first_rollback,
            report.roll_up.second_install,
            report.roll_up.second_rollback,
            report.roll_up.final_install,
            report.roll_up.error + report.roll_up.flagged
          ];
        } else {
          this.releaseRollOutPieLabels = ['Error'];
          this.releaseRollOutPieData = [1];
        }

        if (report['roll_up_detailed']) {
          this.groupStageChartLabels =  ['Baseline', '1st Install', '1st RollBack', '2nd Install', '2nd Rollback', 'Final'];
          let temp = {};
          for (let group in report.roll_up_detailed) {
            for (let phase in report.roll_up_detailed[group]) {
              for (let step in report.roll_up_detailed[group][phase]) {
                if (temp[step] == undefined || temp[step] == null) temp[step] = {};
                if (temp[step][phase] == undefined || temp[step][phase] == null) temp[step][phase] = 0;
                  temp[step][phase] += report.roll_up_detailed[group][phase][step];
              }
            }
          }
          let final = [];
          for (let step in temp) {
            let baseline = temp[step]['baseline'] || 0;
            let first_install = temp[step]['first_install'] || 0;
            let first_rollback = temp[step]['first_rollback'] || 0;
            let second_install = temp[step]['second_install'] || 0;
            let second_rollback = temp[step]['second_rollback'] || 0;
            let final_install = temp[step]['final_install'] || 0;
            let entry = {data: [baseline, first_install, first_rollback, second_install, second_rollback, final_install], label: step};
            final.push(entry);
          }
          console.log("Final Chart", final)
          this.groupStageChartData = final;

        } else {
          this.groupStageChartLabels =  ['Error'];
          this.groupStageChartData =[{data: [0], label: 'Group1'},];
        }
      });
    }
  }


  releaseStatusStyle() {
    if (this.entity === null || this.entity === undefined) return 'bg-info';
    if (this.entity.status === 'error') return 'bg-danger';
    if (this.entity.status === 'pending') return 'bg-secondary';
    if (this.entity.status === 'running') return 'bg-primary';
    if (this.entity.status === 'rejected') return 'bg-danger';
    if (this.entity.status === 'approved') return 'bg-success';
    return 'bg-warning';
  }

  releaseStatus() {
    if (this.entity === null || this.entity === undefined) return 'Loading';
    if (this.entity.status === 'error') return 'Error';
    if (this.entity.status === 'pending') return 'Pending';
    if (this.entity.status === 'running') return 'Running';
    if (this.entity.status === 'rejected') return 'Rejected';
    if (this.entity.status === 'approved') return 'Approved';
    return this.entity.status;
  }

  // events
  public chartClicked(chart: any, e: any): void {
    console.log(e);
  }

  public chartHovered(chart: any, e: any): void {
    console.log(e);
  }

}
