import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ToasterService} from 'angular2-toaster';
import {AlertContactFacadeEntity} from '../../../../entities/alert';
import {AlertFacadeDefaultWidgetComponent} from './default.widget.component';
import {FirmwareWidgetEventStruct} from '../../firmware';
import {AlertFacadeWidgetEventStruct} from './alert-facade-widget-event.struct';

@Component({
  selector: 'alert-facade-contact-widget',
  template: `
    <div class="card card-accent-dark alert-facade-contact-widget">
      <div class="card-header">Contact Alert</div>
      <div class="card-body">
        <div class="row"><div class="col-4">Device</div><div class="col-8"><a [routerLink]="'/portal/devices/show/' + entity.device">{{entity.device}}</a></div></div>
        <div class="row"><div class="col-4">Association</div><div class="col-8">{{entity.deviceAssociation}}</div></div>
        <div class="row"><div class="col-12"><hr/></div></div>
        <div *ngIf="!options?.edit" class="row"><div class="col-4">On Contact</div><div class="col-8">{{entity.triggerOnContact|json}}</div></div>
        <div *ngIf="options?.edit" class="row"><div class="col-4">On Contact</div>
            <div class="col-8">
              <label class="switch switch-3d switch-primary">
                <input checked="" class="switch-input switch-primary" (change)="facadeChange($event)" [(ngModel)]="entity.triggerOnContact" type="checkbox">
                <span class="switch-slider"></span>
              </label>
            </div>
        </div>
      </div>
    </div>
    `
})
export class EmbedAlertContactFacadeComponent extends AlertFacadeDefaultWidgetComponent{
  public editMode = false;
  @Input() layout: string = null;
  @Input() entity: AlertContactFacadeEntity;
  @Input() options: any;

  @Output() widgetEvent = new EventEmitter<AlertFacadeWidgetEventStruct>();
  forwardEvent(e, widgetEvent: AlertFacadeWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }

  constructor(public toasterService: ToasterService) {
    super();
  }

}
