import {NoizuStruct} from '../../noizu/structs/noizu-struct';
import {NwsReading} from './nws-card.entity';

export enum DiagnosticReading {
  disabled,
  firmware_version,
  memory,
  psk,
  ssid,
  api_failures,
  restarts,
  unknown,
  mcu = 8,
  mcu_next = 9
}

export class DataStreamDiagnosticCardEntity extends NoizuStruct {
  public _kind = 'GoldenRatio.DataStream.Card.DiagnosticCard.V1_1';
  public messages = {one: ':auto', two: ':auto'};
  public link: DiagnosticReading | undefined = DiagnosticReading.unknown;
  public modified = false;

  constructor(json) {
    super();
    if (json['messages']) {
      this.messages.one = json['messages']['one'];
      this.messages.two = json['messages']['two'];
    }

    if (json['link']) {
      this.link = (<any>DiagnosticReading)[json['link']];
      if (this.link === undefined) {
        console.log(`Unsupported Forecast Link: ${json['link']}`);
        this.link = DiagnosticReading.unknown;
      }
    } else {
      this.link = DiagnosticReading.unknown;
    }
  } // end constructor

  linkType() {
    return DiagnosticReading[this.link];
  }

  toJson(options: any = {}) {
    return {
      messages: this.messages,
      link: this.linkType(),
    }
  }

  validate() {
    const errors = [];
    if (this.link === undefined
      || this.link === null
      || this.link === DiagnosticReading.unknown
      || this.link === DiagnosticReading.disabled) {
      errors.push({error: 'NoLink', message: 'Diagnostic Link Required'});
    }
    return errors;
  }

  widget() {
    return 'embed-ds-diagnostic-card';
  }
} // End Package
