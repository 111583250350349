import {Component, Input, Inject, Output, EventEmitter} from '@angular/core';

import {FirmwareLogicalWidgetEnum, LogicalWidgetEnum, WidgetEnum} from '../../../../enums/widget';
import {FirmwareLogicalWidget, LogicalWidget} from '../../../../entities';
import {FirmwareLogicalWidgetEventStruct} from './firmware-logical-widget-event.struct';

@Component({
  selector: 'firmware-logical-widget',
  template: `
<div *ngIf="!entity">
  <widget-spinner></widget-spinner>
</div>
<div *ngIf="entity">
  <div [ngSwitch]="entity.firmware_logical_widget_type()">
    <firmware-definition-logical-widget *ngSwitchCase="WidgetType.FIRMWARE_LOGICAL_WIDGET__DEFINITION" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></firmware-definition-logical-widget>
    <firmware-target-logical-widget *ngSwitchCase="WidgetType.FIRMWARE_LOGICAL_WIDGET__TARGET" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></firmware-target-logical-widget>
    <firmware-target-set-logical-widget *ngSwitchCase="WidgetType.FIRMWARE_LOGICAL_WIDGET__TARGET_SET" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></firmware-target-set-logical-widget>

    <!-- Default -->
    <firmware-generic-logical-widget *ngSwitchDefault (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></firmware-generic-logical-widget>
  </div>
</div>
`
})
export class FirmwareLogicalWidgetComponent {
  @Input() entity: FirmwareLogicalWidget | null = null;
  @Input() templateHint: string = null;
  @Input() options: any = null;
  @Input() layout = 'standard';


  @Output() widgetEvent = new EventEmitter<FirmwareLogicalWidgetEventStruct>();
  forwardEvent(widgetEvent: FirmwareLogicalWidgetEventStruct) {
    this.widgetEvent.emit(widgetEvent);
  }

  public  WidgetType = FirmwareLogicalWidgetEnum;

  constructor() {

  }
}
