import { Component, Input, Inject } from '@angular/core';
import {SensorTypeRangeEntity} from "../../../entities/sensor-type-range.entity";

@Component({
  selector: 'embed-device-range',
  template: `
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">Range</div>
          <div class="card-body"><pre>{{entity?.toJson(options) | json}}</pre></div>
        </div>
      </div>
    </div>
    `
})
export class EmbedDeviceRangeComponent {
  @Input() entity: SensorTypeRangeEntity = null;
  @Input() options: any = null;
  @Input() layout: string = null;
  constructor() {
  }

}
