import { Component, Input, Inject } from '@angular/core';
import {BatchEntity} from "../../entities/batch.entity";

@Component({
  selector: 'embed-batch',
  template: `
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">Batch</div>
          <div class="card-body"><pre>{{entity?.toJson(options) | json}}</pre></div>
        </div>
      </div>
    </div>
    `
})
export class EmbedBatchComponent {
  @Input() entity: BatchEntity = null;
  @Input() options: any = null;
  @Input() layout: string = null;
  constructor() {
  }

}
