<spinner *ngIf="!entries"></spinner>
<div *ngIf="entries" class="row">
  <div class="col-2"></div>
  <div class="col-8">
    <div class="card">
      <div class="card-header">
        <div>Device Data Simulator</div>
        <div class="pull-right" *ngIf="auth.permissionLevel <=1">
          Use Admin API <input type="checkbox" [checked]="useAdmin" (change)="toggleUseAdmin(useAdmin)"/>
        </div>
      </div>
      <div class="card-block row overlayParent">
        <div class="overlay" *ngIf="saving">
          <widget-spinner></widget-spinner>
        </div>
        <div class="col-12 text-center">
          <select class="col-8" [(ngModel)]="selected" (ngModelChange)="onChange($event)">
            <option *ngFor="let entry of entries.items; let i = index" [ngValue]="('00' + (i.toString())).slice(-2) + entry.internalName">{{entry.internalName}}</option>
          </select>
        </div>
        <div class="col-3 my-2">
          Serial/ID
        </div>
        <div class="col-9 my-2">
          <input maxlength="6" type="text" placeholder="Device Serial" [(ngModel)]="simData['id']">
        </div>
        <div class="col-12">
          <div *ngIf="fields">
            <div *ngIf="fields.indexOf('t') != -1" class="row">
              <div class="col-3 my-2">
              Temp (celcius):
              </div>
              <div class="col-9 my-2">
                <input type="text" placeholder="Temperature" [(ngModel)]="simData['t']">
              </div>
            </div>
            <div *ngIf="fields.indexOf('h') != -1" class="row">
              <div class="col-3 my-2">
              Humidity (%):
              </div>
              <div class="col-9 my-2">
                <input type="text" placeholder="Humidity" [(ngModel)]="simData['h']">
              </div>
            </div>
            <div *ngIf="fields.indexOf('r') != -1" class="row">
              <div class="col-3 my-2">
              Rain (inches):
              </div>
              <div class="col-9 my-2">
                <input type="text" placeholder="Rain" [(ngModel)]="simData['r']">
              </div>
            </div>
            <div *ngIf="fields.indexOf('e') != -1" class="row">
              <div class="col-3 my-2">
              Rain (inches):
              </div>
              <div class="col-9 my-2">
                <input type="text" placeholder="Rain" [(ngModel)]="simData['e']">
              </div>
            </div>
            <div *ngIf="fields.indexOf('s') != -1" class="row">
              <div class="col-3 my-2">
              Wind Speed (KM/H):
              </div>
              <div class="col-9 my-2">
                <input type="text" placeholder="Wind Speed" [(ngModel)]="simData['s']">
              </div>
            </div>
            <div *ngIf="fields.indexOf('d') != -1" class="row">
              <div class="col-3 my-2">
              Wind Heading (degrees):
              </div>
              <div class="col-9 my-2">
                <input type="text" placeholder="Wind Heading" [(ngModel)]="simData['d']">
              </div>
            </div>
            <div *ngIf="fields.indexOf('w') != -1" class="row">
              <div class="col-3 my-2">
              Wetness:
              </div>
              <div class="col-9 my-2">
                <input    type="text" placeholder="Wetness" [(ngModel)]="simData['w']">
              </div>
            </div>
            <!--
            <div *ngIf="fields.indexOf('p') != -1" class="row">
              <div class="col-3 my-2">
              Probe:
              </div>
              <div class="col-9 my-2">
                <input    type="text" placeholder="Probe" [(ngModel)]="simData['p']">
              </div>
            </div>

            <div *ngIf="fields.indexOf('c') != -1" class="row">
              <div class="col-3 my-2">
              Wind Chill:
              </div>
              <div class="col-9 my-2">
                <input    type="text" placeholder="Wind Chill" [(ngModel)]="simData['c']">
              </div>
            </div>
            <div *ngIf="fields.indexOf('i') != -1" class="row">
              <div class="col-3 my-2">
              Heat Index:
              </div>
              <div class="col-9 my-2">
                <input    type="text" placeholder="Heat Index" [(ngModel)]="simData['i']">
              </div>
            </div>
            <div *ngIf="fields.indexOf('f') != -1" class="row">
              <div class="col-3 my-2">
              Feels Like:
              </div>
              <div class="col-9 my-2">
                <input    type="text" placeholder="Feels Like" [(ngModel)]="simData['f']">
              </div>
            </div>
            <div *ngIf="fields.indexOf('g') != -1" class="row">
              <div class="col-3 my-2">
              Wind Gust:
              </div>
              <div class="col-9 my-2">
                <input    type="text" placeholder="Wind Gust" [(ngModel)]="simData['g']">
              </div>
            </div>
            <div *ngIf="fields.indexOf('a') != -1" class="row">
              <div class="col-3 my-2">
              Indoor Temp:
              </div>
              <div class="col-9 my-2">
                <input    type="text" placeholder="Indoor Temp" [(ngModel)]="simData['a']">
              </div>
            </div>
            <div *ngIf="fields.indexOf('b') != -1" class="row">
              <div class="col-3 my-2">
              Indoor Humidity:
              </div>
              <div class="col-9 my-2">
                <input    type="text" placeholder="Indoor Humidity" [(ngModel)]="simData['b']">
              </div>
            </div> -->
            <div class="row">
              <div class="col-3 my-2">
              Gateway Id:
              </div>
              <div class="col-9 my-2">
                <input    type="text" placeholder="Gateway" [(ngModel)]="gateway">
              </div>
            </div>
            <div class="row">
              <div class="col-3 my-2">
              On Display:
              </div>
              <div class="col-9 my-2">
                <input type="checkbox"  [checked]="onDisplay" (change)="onDisplay = !onDisplay"/>
              </div>
            </div>

            <div class="row" *ngIf="!useAdmin">
              <div class="col-3 my-2">
              PSK:
              </div>
              <div class="col-9 my-2" *ngIf="!pskFound">
                <input  type="text" placeholder="PSK" [(ngModel)]="psk">
              </div>
              <div class="col-9 my-2" *ngIf="pskFound">
                Backend has a saved PSK
              </div>
            </div>

          </div>
        </div>
        <div class="col-12 text-center">
          <button (click)="simDevice()" class="btn btn-primary">Send Simulated Reading</button>
        </div>
        <div *ngIf="success['show']" class="col-12 text-center alert alert-info">
          {{success['message'] | json}}
        </div>
        <div *ngIf="error['show']" class="col-12 text-center alert alert-warning">
          {{error['message']}}
        </div>
        <!--
          (w)et (r)ain (t)emp (h)umidity
          heat(i)ndex (f)eelsLike (p)robe
          wind(s)peed wind(c)hill wind(g)ust windhea(d)ing
          (a)IndoorTemperature (b)IndoorHumidity
        -->
      </div>
    </div>
  </div>
  <div class="col-4"></div>
</div>
