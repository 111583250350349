
import { Component, Inject, Input } from '@angular/core';
import { ToolsEntity } from '../../entities/tools.entity';
import { ToolsRepo } from '../../repos/tools.repo';
import * as crypto from 'crypto-js';

@Component({
  templateUrl: './show.component.html',
})
export class ToolsComponent {
  public ent: ToolsEntity;

  public hmacstr;
  public hmacsecret;
  public hmac = null;
  public fromVal;
  public toVal;
  public codes = null;
  public codeError = null;

  constructor(public repo: ToolsRepo) {
    this.ent = repo.entity(null);
    this.hmacstr = `[GET] https://ingv2.lacrossetechnology.com/api/v1.1/forecast/id/2CF2F7\\n----- body -----\\n`
    this.hmacsecret = "SECRET_2CF2F7PSK";
  }

  calcHmac() {
    var hash = crypto.HmacSHA256(this.hmacstr.replace(/\\n/g, '\n'), this.hmacsecret);
    var hashInBase64 = crypto.enc.Base64.stringify(hash);
    this.hmac = hashInBase64;
  }

  generateCodes(event) {
    event.preventDefault();
    this.codeError = null;
    let codes = [];
    let start = parseInt("0x" + this.fromVal);
    let end = parseInt("0x" + this.toVal);


    if (isNaN(start) || isNaN(end)) {
      this.codeError = "Unable to convert both inputs to numbers";
      return;
    }
    if (Math.abs(start - end) > 10000000) {
      this.codeError = "More than 10 million records at once";
      return;
    }
    if (start > end) {
      let temp = start;
      start = end;
      end = temp;
    }

    if (end > 16777215) {
      this.codeError = "Input out of range, greater than FFFFFF";
      return;
    }
    let zerofill = "00000";

    for (var i = start; i<=end; i++) {
      codes.push(( zerofill + i.toString(16).toUpperCase()).slice(-6) + "-" + this.genRawCode(i.toString()));
    }
    this.codes = codes;

    let filename = this.fromVal + "-" + this.toVal + ".csv";
    let text = this.codes.toString();
    text = text.replace(/,/g, '\n')
    var csvData = new Blob([text], { type: 'text/csv' });

    var element = document.createElement('a');
    element.setAttribute('href', URL.createObjectURL(csvData));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);

  }

   genRawCode(seed) {

    var text = "";
    var possible = "ABCDEFGHJKMNOPQRSTUVWXYZabcdefghjkmnopqrstuvwxyz023456789";

    let a = parseInt(seed.substring(seed.length-5,seed.length-3));
    let b = parseInt(seed.substring(seed.length-4,seed.length-2));
    let c = parseInt(seed.substring(seed.length-3,seed.length-1));

    let m = Math.pow(2,16)-seed%50;
    /*
    a = ((a+seed*a+1*(27869+a+1))%m)% possible.length;
    b = ((b+seed*b+2*(27869+b+2))%m)% possible.length;
    c = ((c+seed*c+3*(27869+c+3))%m)% possible.length;
    */
    a = ((a+seed*(((a+2)*231)%1000)*27869)%m)% possible.length;
    b = ((b+seed*(((b+3)*321)%1000)*27869)%m)% possible.length;
    c = ((c+seed*(((c+4)*132)%1000)*27869)%m)% possible.length;


    text = possible.charAt(a) + possible.charAt(b) + possible.charAt(c)

    return text;
  }

}
