import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AlertEntity} from '../../../entities/alert.entity';
import {WidgetComponent} from '../../widget.component';
import {WidgetBase} from '../widget-base';
import {UserEntity} from '../../../entities';
import {UserWidgetEventStruct} from './user-widget-event.struct';
import {WidgetEventStruct} from '../../widget-event.struct';
import {WidgetEnum} from '../../../enums';
import {Router} from '@angular/router';
import {ToasterService} from 'angular2-toaster';

@Component({
  selector: 'user-widget',
  template: `
    <ng-container [ngSwitch]="layout">
      <ng-container *ngSwitchCase="'table'">
        <div class="div-table-th" *ngIf="options['header']">
          <div class="row">
            <div class="col-2"><span class="pl-1">#Id</span></div>
            <div class="col-2">First Name</div>
            <div class="col-2">Last Name</div>
            <div class="col-2">Display Name</div>
            <div class="col-2">Email</div>
            <div class="col">Actions</div>
          </div>
        </div>
        <div [ngClass]="{'div-table-tr': (options['index'] % 2) == 1, 'div-table-tr-alt': (options['index'] % 2) == 0}">
          <div class="row">
            <div class="col-2 text-truncate">
              <span class="pl-1"><a [routerLink]="'/portal/users/show/' + entity.identifier"  title="{{entity.identifier}}" ><i class="pr-1 text-dark fa fa-link"> </i></a><a title="{{entity.identifier}}" href="/portal/users/show/{{entity.identifier}}" (click)="select($event)" >{{entity.identifier}}</a></span>
            </div>
            <div class="col-2 text-truncate" title="{{entity.first_name}}">{{entity.first_name}}</div>
            <div class="col-2 text-truncate "title="{{entity.last_name}}">{{entity.last_name}}</div>
            <div class="col-2 text-truncate" title="{{entity.display_name}}">{{entity.display_name}}</div>
            <div class="col-2 text-truncate" title="{{entity.email}}">{{entity.email}}</div>
            <div class="col">
              <span class="badge badge-info">Send Password Reset Email</span>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'mini'">
        <div class="row">
          <div class="col-auto"><strong>User:</strong></div>
          <div class="col text-truncate"><a [routerLink]="'/portal/users/show/' + entity.identifier"  class="text-truncate" title="{{entity.identifier}}" >{{entity.identifier}}</a></div>
        </div>
        <div class="row" *ngIf="entity.first_name || entity.last_name">
          <div class="col-auto"><strong>Name:</strong></div>
          <div class="col">{{entity.first_name}} {{entity.last_name}}</div>
        </div>
        <div class="row">
          <div class="col-auto"><strong>Email:</strong></div>
          <div class="col">{{entity.email}}</div>
        </div>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <div class="card shadow" *ngIf="entity">
          <div class="card-header">
            <div class="row">
              <div class="col">
                User Widget| #<a [routerLink]="'/portal/users/show/' + entity.identifier">{{entity.identifier}}</a>
                <span *ngIf="options" class="float-right"><i (click)="toggleExpand()" class="fa fa-sm" [ngClass]="{'fa-chevron-down': !options.expand, 'fa-chevron-up': options.expand}"> </i></span>
              </div>
            </div>
          </div>
          <div class="card-body p-1 m-0" *ngIf="entity && expandWidget()">
            <div class="row">
              <div class="col-4">Name</div>
              <div class="col">{{entity.last_name}}, {{entity.first_name}}</div>
            </div>
            <div class="row">
              <div class="col-4">Is Staff?</div>
              <div class="col">{{entity.isStaff | json}}</div>
            </div>
            <div class="row">
              <div class="col-4">Email</div>
              <div class="col">{{entity.email}}</div>
            </div>
            <div class="row">
              <div class="col-4">mobile</div>
              <div class="col">{{entity.mobile}}</div>
            </div>
            <div class="row">
              <div class="col-4">group_id</div>
              <div class="col">{{entity.group_id}}</div>
            </div>
            <div class="row">
              <div class="col-12">adminPermissions</div>
              <div class="col-12" *ngFor="let perm of user_permissions">
                <div class="col-12">Name: {{perm.name}}</div>
                <div class="col-12">Access: {{perm.grant}}</div>
              </div>
            </div>
            <div class="row">
              <tabset #userTabs class="col">
                <tab heading="Settings">
                  <div class="row">
                    <div class="col">
                      <user-settings-widget [entity]="entity" [options]="options"></user-settings-widget>
                    </div>
                  </div>
                </tab>
                <tab heading="Devices">
                  <div class="row">
                    <div class="col">
                      <user-devices-widget [entity]="entity" [options]="options"></user-devices-widget>
                    </div>
                  </div>
                </tab>
                <tab heading="Locations">
                  <div class="row">
                    <div class="col">
                      [PENDING]
                    </div>
                  </div>
                </tab>
                <tab heading="Channels">
                  <div class="row">
                    <div class="col">
                      [PENDING]
                    </div>
                  </div>
                </tab>
                <tab heading="Alerts">
                  <div class="row">
                    <div class="col">
                      [PENDING]
                    </div>
                  </div>
                </tab>
                <tab heading="Events">
                  <div class="row">
                    <div class="col">
                      [PENDING]
                    </div>
                  </div>
                </tab>
                <tab heading="Notifications">
                  <div class="row">
                    <div class="col">
                      [PENDING]
                    </div>
                  </div>
                </tab>
                <tab heading="Subscriptions">
                  <div class="row">
                    <div class="col">
                      [PENDING]
                    </div>
                  </div>
                </tab>
                <tab heading="Logs">
                  <div class="row">
                    <div class="col">
                      [PENDING]
                    </div>
                  </div>
                </tab>
                <tab heading="Groups">
                  <div class="row">
                    <div class="col">
                      [PENDING]
                    </div>
                  </div>
                </tab>
              </tabset>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  `
})
export class UserWidgetComponent extends WidgetBase implements OnInit {
  public editMode = false;
  @Input() layout: string = null;
  @Input() entity: UserEntity;
  @Input() options: any;
  @Output() widgetEvent = new EventEmitter<UserWidgetEventStruct>();
  forwardEvent(e, widgetEvent: UserWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }

  public user_permissions: any = [];

  constructor(public toasterService: ToasterService) {
    super();
  }

  public  ngOnInit() {
    this.getUserPermissions();
  }

  getUserPermissions() {
    if (this.entity) {
        let y = this.entity.adminPermissions;
        this.user_permissions = [];
        for (let w in y) {
        this.user_permissions.push({name: w, grant: y[w]});
      }
      //console.log(this.myPermissions);
    }
  }


  public select(event) {
    let a = new UserWidgetEventStruct();
    a.event_body = this.entity;
    a.event_type = 'select_entity';
    a.event_widget_type = WidgetEnum.EMBED_WIDGET__USER_ENTITY;
    this.forwardEvent(event, a);
  }

}
