import { Component, Input, Inject } from '@angular/core';
import {DeviceFieldDefinitionEntity} from "../../../entities/device/field/definition.entity";

@Component({
  selector: 'embed-device-field',
  template: `
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">Field Definition <b>{{entity?.identifier}}</b></div>
          <div class="card-body p-2">

            <!-- Name -->
            <div class="row mb-1">
              <div class="col-3"><b>Name:</b></div>
              <div class="col-9">{{entity.name}}</div>
            </div>

            <!-- Handle -->
            <div class="row mb-1">
              <div class="col-3"><b>Handle:</b></div>
              <div class="col-9">{{entity.handle}}</div>
            </div>

            <!-- Type -->
            <div class="row mb-1">
              <div class="col-3"><b>Type:</b></div>
              <div class="col-9">{{entity.type}}</div>
            </div>

            <!-- Unit -->
            <div class="row mb-1">
              <div class="col-3"><b>Unit:</b></div>
              <div class="col-9">{{entity.unit}}</div>
            </div>

            <!-- Device Type -->
            <div class="row mb-1">
              <div class="col-3"><b>Derived Type:</b></div>
              <div class="col-9">{{entity.is_derived_type}}</div>
            </div>


            <!-- Precision -->
            <div class="row mb-1">
              <div class="col-3"><b>Precision:</b></div>
              <div class="col-9">{{entity.precision}}</div>
            </div>

            <!-- Accuracy -->
            <div class="row mb-1">
              <div class="col-3"><b>Accuracy:</b></div>
              <div class="col-9">{{entity.accuracy}}</div>
            </div>

            <!-- Range High -->
            <div class="row mb-1">
              <div class="col-3"><b>R.High:</b></div>
              <div class="col-9">{{entity.rangeHigh}}</div>
            </div>

            <!-- Accuracy -->
            <div class="row mb-1">
              <div class="col-3"><b>R.Low:</b></div>
              <div class="col-9">{{entity.rangeLow}}</div>
            </div>

            <!-- Stage 1 -->
            <div class="row mb-1">
              <div class="col-3"><b>Stage 1:</b></div>
              <div class="col-9"><pre *ngIf="entity.stageOneFormula">{{entity.stageOneFormula.toJson() | json}}</pre></div>
            </div>

            <!-- Stage 2 -->
            <div class="row mb-1">
              <div class="col-3"><b>Stage 2:</b></div>
              <div class="col-9"><pre *ngIf="entity.stageTwoFormula">{{entity.stageTwoFormula.toJson() | json}}</pre></div>
            </div>

            <!-- Stage 3 -->
            <div class="row mb-1">
              <div class="col-3"><b>Stage 3:</b></div>
              <div class="col-9"><pre *ngIf="entity.stageThreeFormula">{{entity.stageThreeFormula.toJson() | json}}</pre></div>
            </div>

          </div>
        </div>
      </div>
    </div>
    `
})
export class EmbedDeviceFieldComponent {
  @Input() entity: DeviceFieldDefinitionEntity = null;
  @Input() options: any = null;
  @Input() layout: string = null;
  constructor() {
  }

}
