import {
  WidgetEnum,
} from '../../enums/widget';
import {PinEntity} from './pin.entity';
import {EntityList} from '../../noizu/structs';

export class PinSet extends EntityList {
  public loaded = false;
  constructor(public set: Array<PinEntity>) {
    super('entity-pins', [], null, null, null, []);
    this.loaded = true;
    this.items = set;
  } // end constructor

  widget_type(): any {
    return WidgetEnum.EMBED_WIDGET__GENERIC_SET;
  }
} // end Package
