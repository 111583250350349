import {LogicalWidgetEnum,} from "../../enums/widget";
import {LogicalWidget} from '../logical-widget';

export class BatchLogicalWidget extends LogicalWidget{
  public selected: any = null;

  logical_widget_type(): LogicalWidgetEnum | null {
    return LogicalWidgetEnum.LOGICAL_WIDGET__BATCH;
  }

  constructor() {
    super();
  } // end constructor
} // end Package

