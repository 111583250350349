import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from '../lacrosse.entity';
import { FirebaseAuthService }            from '../../noizu/services/firebase-auth.service';
import { DeviceDefinitionRepo }           from '../../repos/device/definition.repo';
import { DeviceDefinitionCategory }       from '../../structs/device/definition/category';
import { DeviceDefinitionFieldEntry }     from '../../structs/device/definition/field-entry';
import {NoizuStruct} from '../../noizu/structs/noizu-struct';

export class GatewayCycleOverrideEntity extends NoizuStruct {
  public interval: any = null;
  public firmware_version: any = null;
  public enabled: any = null; // Will be Entity

  constructor(json) {
    super();
    if (json) {
      this.interval = json.interval;
      this.firmware_version = json.firmware_version;
      this.enabled = json.enabled;
    }
  } // end constructor

} // end Package
