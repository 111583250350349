import {ValueAndUnit} from '../../../structs/value-and-unit';
import {AlertFacadeEntity} from '../facade.entity';
import {WidgetEnum_Alert_Facade} from '../../../enums/widget/alert';
import {cloneDeep} from 'lodash';
import {AlertFormatEnum, UnitEnum} from '../../../enums';

export class AlertRainfallFacadeEntity extends AlertFacadeEntity {
  public hasHourlyAccumulationAbove: boolean;
  public hourlyAccumulationAbove: ValueAndUnit;
  public editHourlyAccumulationAbove: ValueAndUnit;

  public hasDailyAccumulationAbove: boolean;
  public dailyAccumulationAbove: ValueAndUnit;
  public editDailyAccumulationAbove: ValueAndUnit;

  public hasWeeklyAccumulationAbove: boolean;
  public weeklyAccumulationAbove: ValueAndUnit;
  public editWeeklyAccumulationAbove: ValueAndUnit;

  public hasPrecipitationLevel: boolean;
  public precipitationLevel: any;
  public editPrecipitationLevel: any;

  public hasNoRain: boolean;
  public noRainForXDays: any;
  public editNoRainForXDays: any;

  public constructor(alert, json) {
    super(alert, json);

    this.hourlyAccumulationAbove = null;
    if (json["hourlyAccumulationAbove"]) this.hourlyAccumulationAbove = new ValueAndUnit(json["hourlyAccumulationAbove"]);

    this.dailyAccumulationAbove = null;
    if (json["dailyAccumulationAbove"]) this.dailyAccumulationAbove = new ValueAndUnit(json["dailyAccumulationAbove"]);

    this.weeklyAccumulationAbove = null;
    if (json["weeklyAccumulationAbove"]) this.weeklyAccumulationAbove = new ValueAndUnit(json["weeklyAccumulationAbove"]);

    this.precipitationLevel = json.precipitationLevel;
    this.noRainForXDays = json.noRainForXDays;

    this.editHourlyAccumulationAbove = this.hourlyAccumulationAbove ? cloneDeep(this.hourlyAccumulationAbove) : new ValueAndUnit({value: 0.0, enum: UnitEnum.Millimeters});
    this.editDailyAccumulationAbove = this.dailyAccumulationAbove ? cloneDeep(this.dailyAccumulationAbove) : new ValueAndUnit({value: 0.0, enum: UnitEnum.Millimeters});
    this.editWeeklyAccumulationAbove = this.dailyAccumulationAbove ? cloneDeep(this.weeklyAccumulationAbove) : new ValueAndUnit({value: 0.0, enum: UnitEnum.Millimeters});
    this.editPrecipitationLevel = this.precipitationLevel ? this.precipitationLevel : 0;
    this.editNoRainForXDays = this.noRainForXDays ? this.noRainForXDays : 0;
    this.hasHourlyAccumulationAbove = this.hourlyAccumulationAbove ? true : false;
    this.hasDailyAccumulationAbove = this.dailyAccumulationAbove ? true : false;
    this.hasWeeklyAccumulationAbove = this.weeklyAccumulationAbove ? true : false;
    this.hasPrecipitationLevel = this.precipitationLevel != null ? true : false;
    this.hasNoRain = this.noRainForXDays != null ? true : false;
  }


  public updateValues() {
    this.hourlyAccumulationAbove = null;
    this.dailyAccumulationAbove = null;
    this.weeklyAccumulationAbove = null;
    this.noRainForXDays = null;

    if (this.hasHourlyAccumulationAbove) this.hourlyAccumulationAbove = cloneDeep(this.editHourlyAccumulationAbove);
    if (this.hasDailyAccumulationAbove) this.dailyAccumulationAbove = cloneDeep(this.editDailyAccumulationAbove);
    if (this.hasWeeklyAccumulationAbove) this.weeklyAccumulationAbove = cloneDeep(this.editWeeklyAccumulationAbove);
    if (this.hasPrecipitationLevel) this.precipitationLevel = cloneDeep(this.editPrecipitationLevel);
    if (this.hasNoRain) this.noRainForXDays = cloneDeep(this.editNoRainForXDays);
  }


  //-----------------------------------
  //
  //-----------------------------------
  public get template(): string | null {
    return 'rainfall';
  }

  alert_facade_widget() {
    return WidgetEnum_Alert_Facade.ALERT_FACADE_WIDGET__RAINFALL;
  }
}
