
    <div *ngIf="!entity">
      <widget-spinner></widget-spinner>
    </div>



    <ng-container *ngIf="entity">

    <ng-container [ngSwitch]="layout">

      <div class="device-feed-widget" *ngSwitchDefault>

        <div class="row">
          <div class="col">
            <widget *ngIf="entity?.subject?.extended?.chart" (widgetEvent)="widgetEvent($event)" [entity]="entity.subject.extended.chart" [options]="options" [layout]="layout" ></widget>
          </div>
          <div class="col">
            <widget *ngIf="entity?.subject?.extended?.reading" (widgetEvent)="widgetEvent($event)" [entity]="entity.subject.extended.reading" [options]="options" [layout]="layout" ></widget>
          </div>
        </div>

      </div>


    </ng-container>



    </ng-container>
  