import {Component, Input, Inject, OnInit, Output, EventEmitter} from '@angular/core';
import {NoizuStruct} from '../../../noizu/structs/noizu-struct';
import {ModalWidgetEnum, WidgetEnum} from '../../../enums/widget';
import {BatchEntity, BatchLogicalWidget, DeviceDefinitionEntity, DeviceTypeLogicalWidget, ModalWidget} from '../../../entities/';
import {BatchRepo, DeviceDefinitionRepo} from '../../../repos';
import {Observable} from 'rxjs';
import {AppengineEntityList} from '../../../noizu/structs/appengine-entity-list';
import {LogicalWidgetEventStruct} from './logical-widget-event.struct';

@Component({
  selector: 'device-type-logical-widget',
  template: `
<div *ngIf="!entity">
  <div class="row"><div class="col">
    <new-spinner [size]="'sm'"></new-spinner>
  </div></div>
</div>
<div *ngIf="entity">
  <div *ngIf="options?.edit">
    <div *ngIf="valueSubscriber | async as values; else loading">
      <ng-select
        placeholder="Select Device Type"
        [items]="values"
        bindLabel="label"
        bindValue="value"
        [multiple]="false"
        [(ngModel)]="entity.selected"
        (ngModelChange)="updateOutput()"
      ></ng-select>
    </div>

    <ng-template #loading>
      <div class="row"><div class="col">
        <new-spinner [size]="'sm'"></new-spinner>
      </div></div>
    </ng-template>

  </div>
  <div *ngIf="!options?.edit">
    {{entity.selected | json}}
  </div>
</div>
`
})
export class DeviceTypeLogicalWidgetComponent implements OnInit {
  @Input() entity: DeviceTypeLogicalWidget | null = null;
  @Input() templateHint: string = null;
  @Input() options: any = null;
  @Input() layout = 'standard';

  @Output() widgetEvent = new EventEmitter<LogicalWidgetEventStruct>();
  forwardEvent(e, widgetEvent: LogicalWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }

  updateOutput() {
    let event = new LogicalWidgetEventStruct()
    event.event_widget_type = this.entity.widget_type();
    event.event_logical_widget_type = this.entity.logical_widget_type();
    event.event_type = "device_type_selection_made"
    event.event_body = {sref: this.entity.selected}
    this.widgetEvent.emit(event);
  }



  public valueSubscriber: any = null;

  constructor(public repo: DeviceDefinitionRepo) {

  }

  public ngOnInit() {
    this.entity.selected = this.entity.subject
    this.valueSubscriber =  new Observable<any>(observer => {
      this.repo.getListPromise({"page": 0, "resultsPerPage":1024}).then((v: AppengineEntityList) => {
        const response = [];

        let i = v.items.sort((a,b) => {
          return a.internalName <= b.internalName ? -1 : 1
        })

        v.items.forEach((i: DeviceDefinitionEntity) => {
          const entry = {value: i.sref(), label: `${i.identifier} - ${i.internalName}`};
          response.push(entry);
        });
        observer.next(response)
      });
    });

  }
}
