
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { DomainObjectRepo } from '../../noizu/domain-object-repo';
import { FirebaseAuthService }        from '../../noizu/services/firebase-auth.service';
import { FirmwareDefinitionEntity }      from '../../entities/firmware/definition.entity';
import { AppengineRepo }      from '../appengine.repo';
import {EntityReference} from '../../noizu/structs/entity-reference';
import {AppengineEntityList} from '../../noizu/structs/appengine-entity-list';
import {MCUFirmwareDefinitionEntity} from '../../entities/mcu/firmware';

@Injectable()
export class FirmwareDefinitionRepo extends AppengineRepo {
  public _kind = "firmware-definitions";
  public _singular = "firmware-definition";

  public loading = false;
  public loaded = false;
  public firmware_by_sref: any = {};

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  entity(record) {
    return new FirmwareDefinitionEntity(this.client, this.auth, record)
  }


  getEntityPromise(id, options = {}) {
    if (this.isSRef(id)) {
      let fixed_id = this.srefToIdentifier('firmware', id)
      return super.getEntityPromise(fixed_id, options)
    } else {
      return super.getEntityPromise(id, options)
    }
  }




  public get firmwareBySref() {
    if (!this.loaded) {
      if (!this.loading) {
        this.loading = true;

        this.getListPromise().then((v: AppengineEntityList) => {
          v.items.forEach((i: FirmwareDefinitionEntity) => {
            this.firmware_by_sref[i.sref()] = i;
          });
          this.loaded = true;
        });
      }
    }
    return this.firmware_by_sref;
  }


}
