
import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from './lacrosse.entity';
import { FirebaseAuthService }            from '../noizu/services/firebase-auth.service';

export class GroupEntity extends LacrosseEntity {
  public name: string;
  public system: boolean;
  public description: string;
  public handle: string;
  public memberOf: Array<number>;
  //public attributes:Object;

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    //Defined in Lacrosse.entity.ts, critical part of API URL
    this._singular = "group";
    this._kind = "groups";
    this.refresh(json);
  } // end constructor

  refresh(json) {
    let r = super.refresh(json);
    this.system = json["system"];
    this.name = json["name"];
    this.description = json["description"];
    if (json["memberOf"]) {
    this.memberOf = json["memberOf"];
    } else {
      this.memberOf=[];
    }
    this.handle = json["handle"];
    return r;
  }

} // end Package
