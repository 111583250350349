import { NoizuStruct }        from '../../noizu/structs/noizu-struct';

import {GatewayEnvironmentTarget} from './gateway';
import {DeviceWidgetEnum, WidgetEnum} from '../../enums';
import {EntityLogSet} from '../../entities/log/entity-log.set';
import {RequestLogSet} from '../../entities/log/request-log.set';
import {DeviceStatusEntity} from '../../entities/device-status.entity';
import {ExtendedDeviceReadingEntity, ExtendedDeviceChartEntity} from '../../entities/device/extended/';
import {HttpClient} from '@angular/common/http';
import {FirebaseAuthService} from '../../noizu/services/firebase-auth.service';


export class ExtendedDeviceDetails extends NoizuStruct {
  public device_identifier: number;
  public is_gateway: boolean;
  public loading: boolean;
  public logs: {device: EntityLogSet, gateway: EntityLogSet, request: RequestLogSet} = null;
  public environment_target: GatewayEnvironmentTarget = null;
  public status: DeviceStatusEntity = null;
  public attributes: any = null;
  public reading: ExtendedDeviceReadingEntity;
  public chart: ExtendedDeviceChartEntity;

  constructor(client: HttpClient, auth: FirebaseAuthService, settings: any) {
    super();
    this.loading = true;
    this.device_identifier = settings["id"] || settings["identifier"]
    this.environment_target = new GatewayEnvironmentTarget({});
    this.is_gateway = ('is_gateway' in settings) ? settings['is_gateway'] : false;
    this.logs = {
      device: null,
      gateway: null,
      request: null,
    }
    this.attributes = settings['attributes'];
    this.reading = new ExtendedDeviceReadingEntity(client, auth, settings);
    this.chart = new ExtendedDeviceChartEntity(client, auth, settings);
  } // end constructor

}
