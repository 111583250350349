
import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from '../lacrosse.entity';
import { FirebaseAuthService }            from '../../noizu/services/firebase-auth.service';
import { DeviceDefinitionRepo }           from '../../repos/device/definition.repo';
import { DeviceDefinitionCategory }       from '../../structs/device/definition/category';
import { DeviceDefinitionFieldEntry }     from '../../structs/device/definition/field-entry';

export class DeviceLookupTableEntryEntity extends LacrosseEntity {
  public _kind = "device-lookup";
  public _singular = "device-lookup";

  public series: string;
  public serial: string;
  public verification_code: string;

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
  } // end constructor

  refresh(data) {
    this.series = data["series"];
    this.serial = data["serial"];
    this.verification_code = data["verification_code"];
    return super.refresh(data);
  }

  public toJson(options: any = {for: "appengine"}) {
    return {
      id: this.identifier,
      series: this.series,
      serial: this.serial,
      verification_code: this.verification_code
    }
  }

} // end Package
