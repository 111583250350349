
import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from '../lacrosse.entity';
import { FirebaseAuthService }            from '../../noizu/services/firebase-auth.service';

export class CmsAlertEntity extends LacrosseEntity {
  public _kind = "null";
  public _singular = "null";
  public version_record: String;
  public version:String;
  public type: String;
  public tags = [];
  public status: String;
  public name: String;
  public description: String;
  public record;

  public history = null;
  public historyVisibility = true;

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    if (json) {
      this.refresh(json);
    }
  } // end constructor

  refresh(data) {
    this.name = data.name;
    this.version_record = data.version_record;
    this.version = data.version;
    this.type = data.type;
    this.tags = data.tags;
    this.status = data.status;
    this.name = data.name;
    this.description = data.description;
    this.record = data.record;
    return super.refresh(data);
  }


} // end Package
