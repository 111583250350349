
    <div class="card shadow" *ngIf="entity">
      <div class="card-header bg-success">
        <div class="row">
          <div class="col">
            Saved Preferences
            <span *ngIf="options" class="float-right"><i (click)="toggleExpand()" class="fa fa-sm" [ngClass]="{'fa-chevron-down': !options.expand, 'fa-chevron-up': options.expand}"> </i></span>
          </div>
        </div>
      </div>
      <div class="card-body p-1 m-0 bg-light" *ngIf="entity && expandWidget()">
        <div class="card-body">
          <div class="row"><div class="col">Feature Versions</div></div>
          <pre>{{preferences.feature_version| json}}</pre>
          <div class="row"><div class="col">Heading Minimization ({{this.preferences.minimize_default ? 'minimize default' : 'expand default'}})</div></div>
          <div class="col-12" >
            <div class="table-responsive">
              <table class="table">
                <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Value</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let pref of user_preferences">
                  <td>{{pref.key}}</td>
                  <td>{{pref.value}}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  