
          <div class="embed-ds-card" *ngIf="!editMode">
              <div class="row">
                  <div class="col-12">
                      <div class="card border-primary ">
                          <div class="card-header ">
                              <div class="row">
                                  <div *ngIf="_entity && !_entity.new" class="col-3"><b class="font-xl">#{{_entity?.identifier}}</b></div>
                                  <div *ngIf="_entity && _entity.new" class="col-3"><b class="font-xl">[NEW]</b></div>
                                  <div class="col-3 text-center">
                                      <span *ngIf="_entity && !_entity.new" class="font-xl">W={{_entity?.weight}}</span>
                                  </div>
                                  <div class="col-5"><span class="font-xl">{{current}}</span></div>
                                  <div class="col-1" *ngIf="_entity">
                                      <label class="switch switch-3d switch-primary float-right" *ngIf="!_entity.new">
                                          <input (change)="toggleCard($event)" class="switch-input" type="checkbox" [(ngModel)]="_entity.enabled" name="toggleCard">
                                        <span class="switch-slider"></span>
                                      </label>
                                  </div>
                              </div>
                          </div>
                          <div class="card-body p-2">
                              <div class="row">
                                  <div class="col-12">
                                      <ds-card-embed *ngIf="_entity.definition"
                                                    [entity]="_entity.definition"
                                                    [options]="options"
                                                    [layout]="layout">
                                      </ds-card-embed>
                                  </div>
                              </div>
                          </div>
                          <div class="card-footer p-1"  *ngIf="_entity">
                              <div class="btn-group-lg">
                                  <button
                                          type="button"
                                          class="btn btn-lg btn-primary float-left"
                                          (click)="editCard($event)">Edit
                                  </button>
                                  <button type="button" (click)="deleteCard($event)" class="btn btn-lg btn-danger float-right">
                                      <span *ngIf="_entity.new">Cancel</span>
                                      <span *ngIf="!_entity.new">Delete</span>
                                  </button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="embed-ds-card" *ngIf="editMode">
              <div class="row">
                  <div class="col-12">
                      <div class="card border-primary p-1">
                          <div class="card-header border-primary">
                              <div class="row">
                                  <div *ngIf="_entity && !_entity.new" class="col-3"><b class="font-xl">#{{_entity?.identifier}}</b></div>
                                  <div *ngIf="_entity && _entity.new" class="col-3"><b class="font-xl">[NEW]</b></div>
                                  <div class="col-3 text-center">
                                      <span *ngIf="_entity && !_entity.new" class="font-xl">W={{_entity?.weight}}</span>
                                  </div>
                                  <div class="col-5">
                                      <div>
                                          <select  name="toggleCardType" (change)="changeCard($event)" class="form-control" [(ngModel)]="current">
                                              <option [ngValue]="'weather'">Weather</option>
                                              <option [ngValue]="'nws'">NWS</option>
                                              <option [ngValue]="'device'">Device</option>
                                              <option [ngValue]="'diagnostic'">Diagnostic</option>
                                              <option [ngValue]="'media'">Media</option>
                                              <option [ngValue]="'time'">Time</option>
                                          </select>
                                      </div>
                                  </div>
                                  <div class="col-1" *ngIf="_entity">
                                      <label class="switch switch-3d switch-primary">
                                          <input checked="" class="switch-input" (change)="toggleCard($event)"  [(ngModel)]="_entity.enabled" type="checkbox">
                                          <span class="switch-slider"></span>
                                      </label>
                                  </div>
                              </div>
                          </div>
                          <div class="card-body p-1" *ngIf="_entity">
                              <!-- Validation Errors -->
                              <div *ngIf="validationErrors.length > 0">
                                  <div class="row">
                                      <div class="col-12" *ngFor="let msg of validationErrors">
                                          <div class="alert alert-danger">{{msg.error}}: {{msg.message}}</div>
                                      </div>
                                  </div>
                              </div>

                              <div class="row">
                                  <div class="col">
                                      <!-- Data -->
                                      <div class="row">
                                          <div class="col-4"><b>Name</b></div>
                                          <div class="col-8">
                                              <input
                                                      type="text"
                                                      [(ngModel)]="_entity.name"
                                                      (ngModelChange)="inlineEdit($event)"
                                                      name="name"
                                                      size="8"/>
                                          </div>
                                      </div>
                                      <div class="row">
                                          <div class="col-4"><b>Featured?</b></div>
                                          <div class="col-8">
                                              <label class="switch switch-3d switch-secondary">
                                                  <input checked="" class="switch-input" (change)="inlineEdit($event)"  [(ngModel)]="_entity.featured" type="checkbox">
                                                  <span class="switch-slider"></span>
                                              </label>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              <div class="row">
                                  <div class="col m-1">


                                      <div class="row">
                                          <div class="col-12">
                                              <ds-card-embed *ngIf="_entity.definition"
                                                            [entity]="_entity.definition"
                                                            [options]="childOptions"
                                                            [layout]="layout">
                                              </ds-card-embed>
                                          </div>
                                      </div>

                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col m-1">
                                      <div class="card border-info mb-0 pb-0">
                                          <div class="card-header">
                                              <div class="row">
                                                  <div class="col-10">
                                                      Extended Details
                                                  </div>
                                                  <div class="col-2 text-center">
                                                      <i (click)="extendedExpand = !extendedExpand" class="fa fa-sm"
                                                         [ngClass]="{'fa-chevron-down': !extendedExpand, 'fa-chevron-up': extendedExpand}"
                                                      > </i>
                                                  </div>
                                              </div>
                                          </div>
                                          <div class="card-body" *ngIf="extendedExpand">
                                              <div class="row">
                                                  <div class="col-4"><b>Description</b></div><div class="col-8">{{_entity.description}}</div>
                                              </div>
                                              <div class="row">
                                                  <div class="col-4"><b>See App?</b></div><div class="col-8">{{_entity.see_app}}</div>
                                              </div>
                                              <div class="row">
                                                  <div class="col-4"><b>Weight</b></div><div class="col-8">{{_entity.weight}}</div>
                                              </div>
                                              <div class="row">
                                                  <div class="col-4"><b>Enabled?</b></div><div class="col-8">{{_entity.enabled}}</div>
                                              </div>
                                              <div class="row">
                                                  <div class="col-4"><b>Featured?</b></div><div class="col-8">{{_entity.featured}}</div>
                                              </div>
                                              <div class="row">
                                                  <div class="col-4"><b>Owner</b></div><div class="col-8">{{_entity.owner}}</div>
                                              </div>
                                              <div class="row">
                                                  <div class="col-4"><b>Feed</b></div><div class="col-8">{{_entity.feed}}</div>
                                              </div>
                                              <div class="row">
                                                  <div class="col-4"><b>Scheduling</b></div><div class="col-8">{{_entity.scheduling}}</div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <div class="card-footer p-1"  *ngIf="_entity">
                              <div class="btn-group-lg">
                                  <button
                                          type="button"
                                          class="btn btn-lg float-left"
                                          (click)="updateCard($event)"
                                          [ngClass]="{'btn-warning': _entity.modified, 'btn-secondary': !_entity.modified}">
                                      <span *ngIf="_entity.new">Create</span>
                                      <span *ngIf="!_entity.new">Update</span>

                                  </button>


                                  <button type="button" (click)="cancelEdit($event)" class="btn btn-lg btn-info">
                                      Cancel
                                  </button>

                                  <button type="button"
                                          (click)="deleteCard($event)"
                                          class="btn btn-lg btn-danger float-right">
                                      Delete
                                  </button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
    