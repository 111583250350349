import {Component, Input, Inject, Output, EventEmitter} from '@angular/core';
import {NoizuStruct} from '../../../../noizu/structs/noizu-struct';
import {AlertWidgetEnum, LogicalWidgetEnum, WidgetEnum} from '../../../../enums/widget';
import {LogicalAlertWidget, LogicalWidget} from '../../../../entities';
import {LogicalWidgetEventStruct} from '../logical-widget-event.struct';
import {LogicalAlertWidgetEventStruct} from './logical-alert-widget-event.struct';

@Component({
  selector: 'alert-logical-widget',
  template: `
  <div class="alert-logical-widget" *ngIf="!entity">
    <widget-spinner></widget-spinner>
  </div>
  <ng-container *ngIf="entity">
    <ng-container [ngSwitch]="entity.alert_widget_type()">
      <alert-facade-selector-logical-widget *ngSwitchCase="WidgetType.ALERT_WIDGET__ALERT_FACADE_SELECTOR" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></alert-facade-selector-logical-widget>
      <!-- Default -->
      <div class="card alert-logical-widget" *ngSwitchDefault [ngClass]="{'mb-0': options?.nested}">
        <div class="card-header" [ngClass]="{'p-2 m-0': options?.nested}">LogicalAlertWidget: {{ WidgetType[entity.alert_widget_type()] }}</div>
        <div class="card-body">
          <pre>{{entity.toJson()| json}}</pre>
        </div>
      </div>
    </ng-container>
  </ng-container>
`
})
export class LogicalAlertWidgetComponent {
  @Input() entity: LogicalAlertWidget | null = null;
  @Input() templateHint: string = null;
  @Input() options: any = null;
  @Input() layout = 'standard';


  @Output() widgetEvent = new EventEmitter<LogicalAlertWidgetEventStruct>();
  forwardEvent(widgetEvent: LogicalAlertWidgetEventStruct) {
    this.widgetEvent.emit(widgetEvent);
  }

  public  WidgetType = AlertWidgetEnum;

  constructor() {

  }
}
