
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { FirebaseAuthService }        from '../../noizu/services/firebase-auth.service';
import { AppengineRepo }      from '../appengine.repo';
import { UserChannelEntity } from '../../entities/user/channel.entity';


@Injectable()
export class UserChannelRepo extends AppengineRepo {
  public _kind = "channels";
  public _singular = "channel";

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  entity(record) {
    return new UserChannelEntity(this.client, this.auth, record)
  }

  getUserListPromise(userIdentifier:string, options = {}) {
    let url = `${this.apiBase()}/user/${userIdentifier}/channels`;
    return this._getListPromise(url, (record) => {return this.entity(record); }, options);
  }

}
