import { Component, Input, Inject } from '@angular/core';
import {DataStreamGenericCardEntity} from '../../../entities/data-stream/generic-card.entity';
import {DataStreamCardEntity} from '../../../entities/data-stream-card.entity';

@Component({
  selector: 'embed-ds-generic-card',
  template: `
      <div class="row embed-ds-generic-card">
          <div class="col-12">
              <div class="card mb-0 pb-0 border-warning">
                  <div class="card-body">
                      <pre>{{entity.raw | json}}</pre>
                  </div>
              </div>
          </div>
      </div>
    `
})
export class EmbedDsGenericCardComponent {
  @Input() entity: DataStreamGenericCardEntity = null;
  @Input() options: any = null;
  @Input() layout: string = null;
  constructor() {
  }

}
