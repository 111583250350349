<page-header [section]="'alerts'">
  <page-title>Alerts</page-title>
  <page-blurb> </page-blurb>
</page-header>

<div>
  <spinner *ngIf="!entries"></spinner>

  <div *ngIf="entries">
    <cms-show *ngFor="let entry of entries" [entry]="entry"></cms-show>
  <!--
    <div class="row">
      <div class="col-12">
        <button (click)="add($event)" class="btn btn-sm btn-primary" type="submit"><i class="fa fa-plus-circle"></i> New</button>
      </div>
    </div>-->
  </div>
</div>
