
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { DomainObjectRepo } from '../noizu/domain-object-repo';
import { FirebaseAuthService }        from '../noizu/services/firebase-auth.service';
import { environment } from '../../environments/environment';
import {ElixirEntity} from '../entities';
import {NoizuStruct} from '../noizu/structs';

export class ElixirRepo extends DomainObjectRepo {
  public _kind;
  public _singular;
  public _version = "v1.1";
  public _endpoint = environment.ingv2_url + "/api";
  public page = 0;

  constructor(client: HttpClient, auth: FirebaseAuthService) {
    super(client, auth);
  }

  apiBase() {
      return `${this._endpoint}/${this._version}`;
  }

  entity(record) : NoizuStruct {
    throw "Must Implement entity(record) method";
  }

  getEntityPromise(id, options = {}) {
    return this._getEntityPromise(`${this.apiBase()}/${this._kind}/${id}`, (record, resolve) => { resolve( this.entity(record) );}, options);
  }

  getListPromise(options = {}) {
    return this._getListPromise(`${this.apiBase()}/${this._kind}`, (record) => {return this.entity(record); }, options);
  }

}
