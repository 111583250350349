
import { Component, Inject, Input, NgZone } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location }               from '@angular/common';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

//import { DeviceDefinitionEntity } from '../../../entities/device/definition.entity';
//import { DeviceDefinitionRepo } from '../../../repos/device/definition.repo';

@Component({
  templateUrl: './synch-queue.component.html',
})

export class DataManagementWipfliSynchQueueComponent {
  public entries: Array<any>;

  constructor(/*public repo: DeviceDefinitionRepo,*/ public zone: NgZone) {
    this.zone.run(
      () => {
        //this.repo.getListPromise().then((u: any) => {this.entries = u;});
      }
    )
  }
}
