
import { Component, Inject, Input, NgZone,OnInit } from '@angular/core';
import { FirebaseAuthService }        from '../../../noizu/services/firebase-auth.service';
import {HttpClient} from '@angular/common/http';
import { DeviceDefinitionEntity } from '../../../entities/device/definition.entity';
import { DeviceCategoryEnum }      from '../../../enums/device-category.enum';
import { DeviceFieldDefinitionEntity } from '../../../entities/device/field/definition.entity';
import { DeviceDefinitionFieldEntry }  from '../../../structs/device/definition/field-entry';
import { DeviceDefinitionAggregationRule } from '../../../structs/device/definition/aggregation-rule';
import {AuthService} from "../../../services/auth.service";

@Component({
  selector: 'device-definition',
  templateUrl: './show.component.html',
})
export class DeviceDefinitionShowComponent {
  @Input() entry: DeviceDefinitionEntity = null;
  @Input() fields: DeviceFieldDefinitionEntity = null;
  init: DeviceDefinitionEntity = null;
  showFields = false;
  categories = [];
  public unmodifiedEntry = null;
  public editEntry : boolean = false;
  public deleted: boolean = false;
  public types = {"spot":{},"high":{},"average":{},"low":{}, "tally":{}};
  public disp = {"spot":false,"high":false,"average":false,"low":false, "tally":false};
  public aggregates = [];
  public fieldSelector;
  public customAttributes = [];
  public custtomAttManager = false;
  public customAttName = "";
  public client: any;
  public auth: any;
  public sidebar_options = {expand: true, collapsible: false, attributes: {expand: true, edit: false}, edit: false};

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService, public zone: NgZone, public authService: AuthService) {
    this.client = client;
    this.auth = auth;
    let keys = Object.keys(DeviceCategoryEnum);
    let array = keys.slice(keys.length/2);
    let array2= keys.splice(0,keys.length/2)
    array.forEach((item,index) => {
      this.categories.push({"val":array2[index],"display":item});
    });

    this.zone.run(
      () => {
        if (this.authService.loadedPromise) {
          this.authService.loadedPromise.then(() => {
            if (this.authService.userHasPermission('device_type_admin') || this.authService.userHasPermission('firmware_admin')) {
              this.sidebar_options.edit = true;
              this.sidebar_options.attributes.edit = true;
            }
          });
        }

      })

      }

  ngOnInit() {
    let defaults = ["Composite", "device-glyph", "display", "factory", "data-stream"];
    let t = [];
    for(let key in this.entry.attributes) {
      let key_str = key.toString();
      if (defaults.indexOf(key_str) == -1) {
        t.push(key_str);
      }
    }
    this.customAttributes = t;


    if (this.entry.fieldEntries) {
      let temp = [];
      let x=1;
      for(let f of this.entry.fieldEntries) {
        temp.push({"name":f.identifier, "id":f.sensorFieldId,"pos":x, "source":(x-1)});
        x++;
      }
      this.aggregates = temp;
    }

  }

  update(e, entity) {
    e.preventDefault();
    let rules = this.getDefaultAggregates();
    let newEntries = [];
    this.sortAggregates();
    for(let agg of this.aggregates) {
      if (agg.id != "4909239533305856" && agg.source == -1) {
        let t = new DeviceDefinitionFieldEntry(this.client, this.auth, {"sensorFieldId":agg.id,"json_name":agg.name,"identifier":agg.name});
        t.aggregationRules = [];
        for (let r of rules) {
          t.aggregationRules.push(new DeviceDefinitionAggregationRule(r));
        }
        newEntries.push(t);
      } else if (agg.id == "4909239533305856" && agg.source == -1) {
        let t = new DeviceDefinitionFieldEntry(this.client, this.auth, {"sensorFieldId":"4909239533305856","json_name":"notSupported","identifier":"notSupported"});
        newEntries.push(t);console.log("New Not Supported");
      } else {
        newEntries.push(this.entry.fieldEntries[agg.source]);
      }
    }
    this.entry.fieldEntries = newEntries;

    this.editEntry = false;
    entity.update();
  }

  edit(e, entity) {
    e.preventDefault();
    this.unmodifiedEntry = this.entry.toJson();
    this.editEntry = true;
  }

  cancel(e) {
    e.preventDefault();
    if (this.unmodifiedEntry) {
      this.entry.refresh(this.unmodifiedEntry);
      this.editEntry = false;
    } else {
      this.deleted = true;
    }
  }

  reorderAgg(i,d) {
    //d = boolean, false = up, true = down
    if(d) {
      this.aggregates[i].pos = (i+1);
      this.aggregates[(i+1)].pos = i;
      this.sortAggregates();
    } else {
      this.aggregates[i].pos = (i-1);
      this.aggregates[(i-1)].pos = i;
      this.sortAggregates();
    }

  }

  deleteAgg(i) {
    this.aggregates.splice(i,1);
    for(let x = i;x<this.aggregates.length;x++) {
      this.aggregates[x].pos = x;
    }
  }

  sortAggregates() {
    this.aggregates.sort(function(a,b) {return (a.pos > b.pos) ? 1 : ((b.pos > a.pos) ? -1 : 0);} );
  }

  addCustomAttribute(e) {
    e.preventDefault();
    if (this.custtomAttManager) {
      this.customAttributes.push(this.customAttName);
      /*let t = this.customAttributes.slice();
      t.push(this.customAttName);
      this.customAttributes = t;*/
    }
    this.custtomAttManager = !this.custtomAttManager

  }

  addAgg(e) {
    e.preventDefault();
    var t = this.aggregates.slice();
    t.push({"name":this.fieldSelector["handle"], "id":this.fieldSelector.identifier,"pos":this.aggregates.length, "source":-1})
    this.aggregates = t;
  }

  delete(e, entity) {
    /*
    e.preventDefault();
    // todo remove from parent so it vanishes after success
    this.editEntry = false;
    entity.delete().then( (res:any) => {
      if(res) {
        this.deleted=true;
      }
    });*/
  }


  public handleError(error: any): Promise<any> {
    console.error('A caught error occurred', error); // for demo purposes only
    if (error.toString().includes("Authorization Header Missing or Malformed")) {
      return new Promise((resolve, reject) => {
        resolve({"id":"authFail", "success":false});
      })
    }
    else { //Generic error
      return new Promise((resolve, reject) => {
        resolve({"success":"false", "msg":error});
      });
    }
    //return Promise.reject(error.message || error);
  }

  getDefaultAggregates() {
    return [{"type": 3,"period": 1,"count": 1,"retention": 1440},
    {"type": 2,"period": 1,"count": 1,"retention": 1440},
    {"type": 1,"period": 1,"count": 1,"retention": 1440},
    {"type": 3,"period": 2,"count": 1,"retention": 576},
    {"type": 2,"period": 2,"count": 1,"retention": 576},
    {"type": 1,"period": 2,"count": 1,"retention": 576},
    {"type": 3,"period": 3,"count": 1,"retention": 672},
    {"type": 2,"period": 3,"count": 1,"retention": 672},
    {"type": 1,"period": 3,"count": 1,"retention": 672},
    {"type": 3,"period": 4,"count": 1,"retention": 672},
    {"type": 2,"period": 4,"count": 1,"retention": 672},
    {"type": 1,"period": 4,"count": 1,"retention": 672},
    {"type": 3,"period": 5,"count": 1,"retention": 768},
    {"type": 2,"period": 5,"count": 1,"retention": 768},
    {"type": 1,"period": 5,"count": 1,"retention": 768},
    {"type": 3,"period": 6,"count": 1,"retention": 731},
    {"type": 2,"period": 6,"count": 1,"retention": 731},
    {"type": 1,"period": 6,"count": 1,"retention": 731},
    {"type": 3,"period": 7,"count": 1,"retention": 144},
    {"type": 2,"period": 7,"count": 1,"retention": 144},
    {"type": 1,"period": 7,"count": 1,"retention": 144},
    {"type": 3,"period": 8,"count": 1,"retention": 120},
    {"type": 2,"period": 8,"count": 1,"retention": 120},
    {"type": 1,"period": 8,"count": 1,"retention": 120},
    {"type": 3,"period": 9,"count": 1,"retention": 100},
    {"type": 2,"period": 9,"count": 1,"retention": 100},
    {"type": 1,"period": 9,"count": 1,"retention": 100},
    {"type": 3,"period": 10,"count": 1,"retention": 1},
    {"type": 2,"period": 10,"count": 1,"retention": 1},
    {"type": 1,"period": 10,"count": 1,"retention": 1}
    ];
  }
}
