
    <div class="card card-accent-dark alert-facade-probe-widget">
      <div class="card-header">Probe Alert</div>
      <div class="card-body">
        <div class="row"><div class="col-4">Device</div><div class="col-8"><a [routerLink]="'/portal/devices/show/' + entity.device">{{entity.device}}</a></div></div>
        <div class="row"><div class="col-4">Association</div><div class="col-8">{{entity.deviceAssociation}}</div></div>
        <div class="row"><div class="col-4">Field</div><div class="col-8">{{entity.field}}</div></div>
        <div class="row"><div class="col-12"><hr/></div></div>
        <div class="row">
          <div [ngSwitch]="temperatureFormat">
            <div *ngSwitchCase="FormatEnum.ValueAbove" class="row">
              <div class="col-5"><b>Temperature Above</b></div>
              <div *ngIf="!options?.edit" class="col-7"><b>{{entity.temperatureAbove.value}}</b> <span> {{entity.temperatureAbove.unit?.name}}</span></div>
              <div *ngIf="options?.edit" class="col-7">
                <input
                  type="number"
                  [(ngModel)]="entity.temperatureAbove.value"
                  (ngModelChange)="facadeChange($event)"
                  name="temp_above"
                />
                <span> {{entity.temperatureAbove.unit?.name}}</span>
              </div>


            </div>
            <div *ngSwitchCase="FormatEnum.ValueBelow" class="row">
              <div class="col-5"><b>Temperature Below</b></div>
              <div *ngIf="!options?.edit" class="col-7"><b>{{entity.temperatureBelow.value}}</b> <span> {{entity.temperatureBelow.unit?.name}}</span></div>
              <div *ngIf="options?.edit" class="col-7">
                <input
                  type="number"
                  [(ngModel)]="entity.temperatureBelow.value"
                  (ngModelChange)="facadeChange($event)"
                  name="temp_above"
                />
                <span> {{entity.temperatureBelow.unit?.name}}</span>
              </div>
            </div>

            <div *ngSwitchCase="FormatEnum.ValueBetween" class="row">
              <div class="col-5"><b>Temperature Between</b></div>
              <div *ngIf="!options?.edit" class="col-7"><b>{{entity.temperatureAbove.value}}</b> <span> {{entity.temperatureAbove.unit?.name}}</span> and <b>{{entity.temperatureBelow.value}}</b> <span> {{entity.temperatureBelow.unit?.name}}</span></div>
              <div *ngIf="options?.edit" class="col-7">
                <input
                  type="number"
                  [(ngModel)]="entity.temperatureAbove.value"
                  (ngModelChange)="facadeChange($event)"
                  name="temp_above"
                />
                <span> {{entity.temperatureAbove.unit?.name}}</span>
                and
                <input
                  type="number"
                  [(ngModel)]="entity.temperatureBelow.value"
                  (ngModelChange)="facadeChange($event)"
                  name="temp_above"
                />
                <span> {{entity.temperatureBelow.unit?.name}}</span>
              </div>
            </div>

            <div *ngSwitchCase="FormatEnum.ValueOutside" class="row">
              <div class="col-5"><b>Temperature Outside</b></div>
              <div *ngIf="!options?.edit" class="col-7"><b>{{entity.temperatureBelow.value}}</b> <span> {{entity.temperatureBelow.unit?.name}}</span> and <b>{{entity.temperatureAbove.value}}</b> <span> {{entity.temperatureAbove.unit?.name}}</span></div>
              <div *ngIf="options?.edit" class="col-7">
                <input
                  type="number"
                  [(ngModel)]="entity.temperatureBelow.value"
                  (ngModelChange)="facadeChange($event)"
                  name="temp_above"
                />
                <span> {{entity.temperatureBelow.unit?.name}}</span>
                and
                <input
                  type="number"
                  [(ngModel)]="entity.temperatureAbove.value"
                  (ngModelChange)="facadeChange($event)"
                  name="temp_above"
                />
                <span> {{entity.temperatureAbove.unit?.name}}</span>
              </div>
            </div>
            <div *ngSwitchDefault></div>
          </div>
        </div>
      </div>
    </div>
    