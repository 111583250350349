<form action="" class="form-horizontal">
  <div class="card border-info" style="max-width: 18rem;" *ngIf="entry">

    <div class="card-header bg-info">{{entry.identifier}}</div>
    <div class="card-body">

      <div class="form-group row">
        <label class="col-6 form-control-label" for="{{entry.identifier}}-series">Series</label>
        <div class="col-9">
          <input class="form-control" required [(ngModel)]="entry.series" value="{{entry.series}}" id="{{entry.identifier}}-series" name="{{entry.identifier}}-series" placeholder="Device Series" type="text">
          <span class="help-block">Please enter device series. (Usually V2)</span>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-6 form-control-label" for="{{entry.identifier}}-serial">Serial</label>
        <div class="col-9">
          <input class="form-control" required [(ngModel)]="entry.serial" id="{{entry.identifier}}-serial" name="{{entry.identifier}}-serial" placeholder="Device Serial" type="text">
          <span class="help-block">Please enter device serial.</span>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-6 form-control-label" for="{{entry.identifier}}-code">Verification Code</label>
        <div class="col-9">
          <input class="form-control" required [(ngModel)]="entry.verification_code" id="{{entry.identifier}}-code" name="{{entry.identifier}}-code" placeholder="Device Verification Code" type="text">
          <span class="help-block">3 Digit Barcode Verification Code</span>
        </div>
      </div>

      <!--
              <div class="form-group row">
                <label class="col-6 form-control-label">Created On</label>
                <div class="col-9">
                  <span>{{entry.createdOn|date:'MM/dd/yyyy @ h:mm:ssa'}}</span>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-6 form-control-label">Modified On</label>
                <div class="col-9">
                  <span>{{entry.modifiedOn|date:'MM/dd/yyyy @ h:mm:ssa'}}</span>
                </div>
              </div>
      -->

    </div>
    <div class="card-footer bg-transparent border-info">
      <button (click)="update($event, entry)" class="btn btn-sm btn-primary" type="submit"><i class="fa fa-dot-circle-o"></i> Update</button>
      <button (click)="delete($event, entry)" class="btn btn-sm btn-warning" type="submit"><i class="fa fa-minus-circle"></i> Delete</button>
    </div>

  </div>
</form>
