
    <div class="row">
      <div class="col-12">
        <div class="card m-0 p-0 mb-1" *ngIf="entity">
          <div class="card-header">
            <div class="row">
              <div class="col-1">
            <span class="badge badge-pill" [ngClass]="{
            'badge-info': entity.level == 'info',
            'badge-warning': entity.level == 'warn',
            'badge-danger': entity.level == 'error',
            'badge-secondary': !(entity.level == 'error' || entity.level == 'warn' || entity.level == 'info')
            }">{{entity.level}}</span>
              </div>
              <div class="col-11">
                <strong>
              <span>Wake Request</span>
                </strong>
                <span> ({{entity?.message?.firmware}})</span>
                <span> : {{entity.date_time | date:'MM/dd/yyyy HH:mm:ss'}}</span>
                <span class="float-right">
              <i
                (click)="expand = !expand"
                class="fa fa-sm"
                [ngClass]="{'fa-chevron-down': !expand, 'fa-chevron-up': expand}"> </i>
            </span>
              </div>
            </div>
          </div>
          <div class="card-body m-0 p-1" *ngIf="expand">
            <div class="row mb-1">

              <div class="col-3">
                <div class="row">
                  <div class="col-12">
                  <b>Topic</b>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                  {{entity.topic}}
                  </div>
                </div>
              </div>

              <div class="col-3">
                <div class="row">
                  <div class="col-12">
                    <b>Level</b>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    {{entity.level}}
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div class="row">
                  <div class="col-12">
                    <b>Time</b>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    {{entity.date_time | date:'MM/dd/yyyy HH:mm:ss'}}
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <div class="row mb-4">
              <div class="col-12">
                <pre>
Firmware: {{entity.message.firmware | json}}
Report: {{entity.message.gateway_report | json}}
                </pre>
              </div>
            </div>




            <div class="row mt-2" *ngIf="entity?.message?.payload?.ExtMCU">
              <div class="col-12">
                <div class="row">
                  <div class="col"><b>MCU OTA Details</b></div>
                </div>
                <div class="alert alert-success">
                  <div class="row">
                    <div class="col-4">Last Reported MCU</div><div class="col-8">{{entity?.message?.payload?.MCU}}</div>
                    <div class="col-4">Next Status</div><div class="col-8">{{entity.StatusCode(entity?.message?.payload?.ExtMCU?.S)}}</div>
                    <div class="col-4">Next Version</div><div class="col-8">{{entity?.message?.payload?.ExtMCU?.N}}</div>
                    <div class="col-4">Next MCU Identifier</div><div class="col-8">{{entity?.message?.payload?.ExtMCU?.F}}</div>
                    <div class="col-4">Current ForceRef</div><div class="col-8">{{entity?.message?.payload?.ExtMCU?.Ic}}</div>
                    <div class="col-4">Pending ForceRef</div><div class="col-8">{{entity?.message?.payload?.ExtMCU?.Ip}}</div>
                    <div class="col-4">Current Ref</div><div class="col-8">{{entity?.message?.payload?.ExtMCU?.R}}</div>
                    <div class="col-4">Pending Ref</div><div class="col-8">{{entity?.message?.payload?.ExtMCU?.P}}</div>
                    <div class="col-4">Update Status</div><div class="col-8">{{entity.StatusCode(entity?.message?.payload?.ExtMCU?.US)}}</div>
                    <div class="col-4">Resume Download After</div><div class="col-8">{{entity?.message?.payload?.ExtMCU?.D}}</div>
                    <div class="col-4">Last IAP Sent</div><div class="col-8">{{entity?.message?.payload?.ExtMCU?.Sent}}</div>
                    <div class="col-4">Processing Segment</div><div class="col-8">{{entity?.message?.payload?.ExtMCU?.Seg}}</div>
                    <div class="col-4">Total Segments</div><div class="col-8">{{entity?.message?.payload?.ExtMCU?.Segs}}</div>
                  </div>
                </div>

              </div>
            </div>

            <!-- Command History -->
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                    <b>Command History</b>
                    <span class="float-right">
              <i
                (click)="expandCommands = !expandCommands"
                class="fa fa-sm"
                [ngClass]="{'fa-chevron-down': !expandCommands, 'fa-chevron-up': expandCommands}"> </i>
            </span>
                  </div>
                  <div class="card-body" *ngIf="expandCommands">
                    <div class="div-table-th">
                      <div class="row">
                        <div class="col-1">#</div>
                        <div class="col-4">Code</div>
                        <div class="col-7">Command</div>
                      </div>
                    </div>
                    <div
                      *ngFor="let command of entity.commandLog(); let i = index;"
                      [ngClass]="{'div-table-tr': (i % 2) == 1, 'div-table-tr-alt': (i % 2) == 0}">
                      <div class="row">
                        <div class="col-1">{{i}}</div>
                        <div class="col-4">{{command.code}}</div>
                        <div class="col-7">{{command.command}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>





            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                        Raw Data<span class="float-right">
              <i
                (click)="expandRaw = !expandRaw"
                class="fa fa-sm"
                [ngClass]="{'fa-chevron-down': !expandRaw, 'fa-chevron-up': expandRaw}"> </i>
            </span>
                  </div>
                  <div class="card-body" *ngIf="expandRaw">
                    <div class="row">
                      <div class="col-12">
                        <pre>Message: {{entity.message| json}}</pre>
                        <pre>Context: {{entity.context| json}}</pre>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
    