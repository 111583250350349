
import { Component, Inject, Input, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location }               from '@angular/common';

import { FirmwareDefinitionEntity } from '../../../entities/firmware/definition.entity';
import {FirmwareApprovalRepo, FirmwareDefinitionRepo, FirmwareLinkV2Repo} from '../../../repos';
import {FirmwareApprovalEntity, FirmwareLinkV2Entity} from '../../../entities';

@Component({
  template: `


    <div *ngIf="entity">
      <firmware-link-widget [entity]="entity" [layout]="'full'" [options]="{}" ></firmware-link-widget>
    </div>
  `
})
export class FirmwareLinkViewComponent implements OnInit {
  public entity: FirmwareLinkV2Entity = null;
  public subscription: any;

  constructor(public activatedRoute: ActivatedRoute, public repo: FirmwareLinkV2Repo) {

  }

  ngOnInit() {
    this.subscription = this.activatedRoute.params.subscribe(
      (params: any)  => {
        if ('id' in params) {
          this.repo.getEntityPromise(params['id']).then((entity: FirmwareLinkV2Entity) => this.entity = entity);
        }
      }
    );
  }
}
