import {HttpClient} from '@angular/common/http';
import {FirebaseAuthService} from '../../../noizu/services/firebase-auth.service';
import {DataStreamLogWidgetEnum, DeviceLogWidgetEnum, GatewayLogWidgetEnum, LogWidgetEnum} from '../../../enums';
import {EntityLogEntity} from '../entity-log.entity';

export class DeviceEntityLogEntity extends EntityLogEntity {
  public log_type: DeviceLogWidgetEnum = DeviceLogWidgetEnum.DEVICE_LOG_WIDGET__GENERIC;
  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);

    switch (this.topic) {
      case 'broadcast_error':
        this.log_type = DeviceLogWidgetEnum.DEVICE_LOG_WIDGET__BROADCAST_ERROR;
        break;
      case 'record_plot_error':
        this.log_type = DeviceLogWidgetEnum.DEVICE_LOG_WIDGET__RECORD_PLOT_ERROR;
        break;
      case 'update_binary':
        this.log_type = DeviceLogWidgetEnum.DEVICE_LOG_WIDGET__UPDATE_BINARY;
        break;
      case 'update_binary_error':
        this.log_type = DeviceLogWidgetEnum.DEVICE_LOG_WIDGET__UPDATE_BINARY_ERROR;
        break;
      case 'shallow_persist_error':
        this.log_type = DeviceLogWidgetEnum.DEVICE_LOG_WIDGET__SHALLOW_PERSIST_ERROR;
        break;
      case 'payload':
        this.log_type = DeviceLogWidgetEnum.DEVICE_LOG_WIDGET__STAGE_ONE;
        break;
      default:
        this.log_type = DeviceLogWidgetEnum.DEVICE_LOG_WIDGET__GENERIC;
    }

  } // end constructor

  log_widget_type(): LogWidgetEnum | null {
    return LogWidgetEnum.LOG_WIDGET__DEVICE;
  }

  device_log_widget_type(): DeviceLogWidgetEnum  {
    return this.log_type;
  }

  public brief() {
    if (!this._brief) {

      switch (this.device_log_widget_type()) {
        case DeviceLogWidgetEnum.DEVICE_LOG_WIDGET__STAGE_ONE:
          this._brief = 'Stage One';
          break;

        case DeviceLogWidgetEnum.DEVICE_LOG_WIDGET__BROADCAST_ERROR:
          this._brief = 'Broadcast (Error)';
          break;

        case DeviceLogWidgetEnum.DEVICE_LOG_WIDGET__RECORD_PLOT_ERROR:
          this._brief = 'Record Plot (Error)';
          break;

        case DeviceLogWidgetEnum.DEVICE_LOG_WIDGET__UPDATE_BINARY:
          this._brief = 'Update Binary';
          break;

        case DeviceLogWidgetEnum.DEVICE_LOG_WIDGET__UPDATE_BINARY_ERROR:
          this._brief = 'Update Binary (Error)';
          break;

        case DeviceLogWidgetEnum.DEVICE_LOG_WIDGET__SHALLOW_PERSIST_ERROR:
          this._brief = 'Shallow Persist (Error)';
          break;

        default:
          this._brief = 'Device (Other)';
      }
    }
    return this._brief;
  }


} // end Package
