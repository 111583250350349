import { Component,Input } from '@angular/core';
import { NotesRepo } from '../../repos/notes.repo';


@Component({
  selector: 'device-reading',
  template: `
    <div *ngIf="status?.feed">

      <div class="row mb-4">
        <div class="col-12 col-md-6">
          <select class="form-control" name="aggregate" [(ngModel)]="aggregate">
            <option *ngFor="let entry of selectors()" value={{entry.value}}>
              {{entry.name}}
            </option>
          </select>
        </div>
        
        <div class="col-12 col-md-6">
          <select class="form-control" name="pullType" [(ngModel)]="pullType">
            <option value="head">Running Average</option>
            <option value="pending">Current Period</option>
            <option value="trail">Previous Period</option>
          </select>
        </div>
      </div>
        
      <div *ngIf="status.feed[sequence[aggregate]?.index]?.data" >
        <div *ngIf="aggregate != 'current' && aggregate != 'unbound'">
          <div *ngIf="status.feed[sequence[aggregate].index].data[pullType]" >

            
            <div class="row">
              <div class="col-12">

                <div class="card">
                  <div class="card-header">Pull Ranges</div>
                  <div class="card-block">

                    <div class="col-12" *ngIf="status.feed[sequence[aggregate].index].data[pullType]?.cover?.u_from">
                      First Record: {{ status.feed[sequence[aggregate].index].data[pullType].cover.u_from * 1000 | date:'MM/dd/yyyy @ h:mm:ssa' }}
                    </div>
                    <div class="col-12" *ngIf="!status.feed[sequence[aggregate].index].data[pullType]?.cover?.u_from">
                      First Record: N/A
                    </div>

                    <div class="col-12" *ngIf="status.feed[sequence[aggregate].index].data[pullType]?.cover?.u_to">
                      Last Record: {{ status.feed[sequence[aggregate].index].data[pullType].cover.u_to * 1000 | date:'MM/dd/yyyy @ h:mm:ssa' }}
                    </div>
                    <div class="col-12" *ngIf="!status.feed[sequence[aggregate].index].data[pullType]?.cover?.u_to">
                      Last Record: N/A
                    </div>

                    <div class="col-12" *ngIf="status.feed[sequence[aggregate].index].data[pullType]?.block?.u_from">
                      Block Start: {{ status.feed[sequence[aggregate].index].data[pullType].block.u_from * 1000 | date:'MM/dd/yyyy @ h:mm:ssa' }}
                    </div>
                    <div class="col-12" *ngIf="!status.feed[sequence[aggregate].index].data[pullType]?.block?.u_from">
                      Block Start: N/A
                    </div>

                    <div class="col-12" *ngIf="status.feed[sequence[aggregate].index].data[pullType]?.block?.u_to">
                      Block End: {{ status.feed[sequence[aggregate].index].data[pullType].block.u_to * 1000 | date:'MM/dd/yyyy @ h:mm:ssa' }}
                    </div>
                    <div class="col-12" *ngIf="!status.feed[sequence[aggregate].index].data[pullType]?.block?.u_to">
                      Block End: N/A
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
            
          </div>
        </div>
        
        
        <div class="row m-0 p-0" *ngFor="let field of extractFields(aggregate, pullType, status.feed[sequence[aggregate].index].data)" >
          <div class="col-12 m-0 p-0">
          <div class="card m-0 p-0">
            <div class="card-header  m-1 p-1"><b>{{field.key}}</b> ({{field.value.unit}})</div>
            <div class="card-block  m-1 p-1">
              <div *ngFor="let reading of readings">
                <div *ngIf="field.value[reading]">
                  <div class="row" >
                    <div class="col-3"><b>{{reading}}</b></div>
                    <div class="col-3">{{field.value[reading].value| number}}</div>
                    <div class="col-6" *ngIf="field.value[reading]['time']">{{field.value[reading].time * 1000| date:'MM/dd/yyyy @ h:mm:ssa'}}</div>
                    <div class="col-12" *ngIf="hasAttributes(field.value[reading])">Attributes: {{field.value[reading].attributes | json}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      </div>
    </div>
`,
styles: [``]
})


export class DeviceReadingComponent {
  @Input() status: any = null;
  aggregate = 'current';
  pullType = 'head';
  sequence = {
    'current': {value: 'current', name: 'Plot', weight: 1, index: 0},
    'ai.minutes.1': {value: 'ai.minutes.1', name: '1 Minute', weight: 2, index: 0},
    'ai.minutes.5': {value: 'ai.minutes.5', name: '5 Minute', weight: 3, index: 0},
    'ai.minutes.15': {value: 'ai.minutes.15', name: '15 Minute', weight: 4, index: 0},
    'ai.minutes.30': {value: 'ai.minutes.30', name: '30 Minute', weight: 5, index: 0},
    'ai.hours.1': {value: 'ai.hours.1', name: 'Hour', weight: 6, index: 0},
    'ai.days.1': {value: 'ai.days.1', name: 'Day', weight: 7, index: 0},
    'ai.weeks.1': {value: 'ai.weeks.1', name: 'Week', weight: 8, index: 0},
    'ai.months.1': {value: 'ai.months.1', name: 'Month', weight: 9, index: 0},
    'ai.years.1': {value: 'ai.years.1', name: 'Year', weight: 10, index: 0},
    'unbound': {value: 'unbound', name: 'All Time', weight: 11, index: 0},
  };
  readings = ['spot', 'high', 'low', 'tally', 'average'];

  constructor(){

  }

  hasAttributes(obj) {
    return !(!obj['attributes'] || ( Object.keys(obj['attributes']).length === 0 && obj['attributes'].constructor === Object));
  }

  extractFields(ai, pt, data) {
    let response = [];

    if (ai == 'current' || ai == 'unbound') {
      for (let key in data) {
        let value = data[key];
        response.push({key: key, value: value});
      }
    } else {
      for (let key in data[pt].fields) {
        let value = data[pt].fields[key];
        response.push({key: key, value: value});
      }
    }

    return response;
  }

  selectors() {
    let response = [];
    let feedLength =  this.status.feed.length;


    for(let i = 0; i < feedLength; i++) {
      if (this.sequence[this.status.feed[i].name]) {
        this.sequence[this.status.feed[i].name].index = i;
        response.push(this.sequence[this.status.feed[i].name]);
      }
    }

    response.sort((a, b) => a.weight - b.weight );
    return response;
  }

}
