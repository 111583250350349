import {Component, Input, Inject, Output, EventEmitter} from '@angular/core';
import {WidgetEnum, WidgetEnum_Alert_Facade} from '../../../../enums/widget';
import {AlertFacadeEntity} from '../../../../entities/alert';
import {AlertFacadeWidgetEventStruct} from './alert-facade-widget-event.struct';
import {AlertFormatEnum} from '../../../../enums';

@Component({
  selector: 'alert-facade-default-widget',
  template: `

    <div class="alert-facade-default-widget">
        <pre *ngIf="entity">
            {{entity.toJson()| json}}
        </pre>
    </div>
`
})

export class AlertFacadeDefaultWidgetComponent{
  @Input() entity: AlertFacadeEntity | null = null;
  @Input() templateHint: string = null;
  @Input() options: any = null;
  @Input() layout = 'standard';
  @Output() widgetEvent = new EventEmitter<AlertFacadeWidgetEventStruct>();
  forwardEvent(e, widgetEvent: AlertFacadeWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }
  public temperatureFormatSelector: any;
  public FormatEnum = AlertFormatEnum;
  public alert_format: any = [
    {value: AlertFormatEnum.ValueAbove, label: 'Value Above'},
    {value: AlertFormatEnum.ValueBelow, label: 'Value Below'},
    {value: AlertFormatEnum.ValueBetween, label: 'Value Between'},
    {value: AlertFormatEnum.ValueOutside, label: 'Value Outside'},
    {value: AlertFormatEnum.IndoorValueAbove, label: 'Indoor Value Above'},
    {value: AlertFormatEnum.IndoorValueBelow, label: 'Indoor Value Below'},
    {value: AlertFormatEnum.IndoorValueBetween, label: 'Indoor Value Between'},
    {value: AlertFormatEnum.IndoorValueOutside, label: 'Indoor Value Outside'},
  ]
  constructor() {
  }

  public inputChange(event, input) {
    this.entity.updateValues();
    this.facadeChange(event);
  }

  public subTypeChange(event) {
    this.entity.subType = this.temperatureFormatSelector;
    this.entity.updateValues();
    this.facadeChange(event);
  }

  public toggleOptional(event) {
    this.entity.updateValues();
    this.facadeChange(event);
  }

  public facadeChange(_event) {
    let f = new AlertFacadeWidgetEventStruct();
    f.event_alert_facade_widget_type = this.entity.alert_facade_widget();
    f.event_alert_widget_type = this.entity.alert_widget_type();
    f.event_type = 'facade_change';
    f.event_body = {facade: this.entity};
    f.event_widget_type = this.entity.widget_type();
    this.widgetEvent.emit(f);
  }

}
