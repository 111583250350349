
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { DomainObjectRepo } from '../../noizu/domain-object-repo';
import { FirebaseAuthService }        from '../../noizu/services/firebase-auth.service';
import { FirmwareLinkEntity }      from '../../entities/firmware/link.entity';
import { AppengineRepo }      from '../appengine.repo';
import {ElixirRepo} from '../elixir.repo';
import {DataStreamCardEntity} from '../../entities/data-stream-card.entity';
import {FirmwareLinkV2Entity} from '../../entities/firmware/link-v2.entity';
import {FirmwareApprovalEntity} from '../../entities/firmware/approval.entity';
import {EntityReference} from '../../noizu/structs/entity-reference';

@Injectable()
export class FirmwareApprovalRepo extends ElixirRepo {
  public _kind = "firmware-approvals";
  public _singular = "firmware-approval";

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  entity(record) {
    return new FirmwareApprovalEntity(this.client, this.auth, record)
  }



  getEntityPromise(id, options = {}) {
    return this._getEntityPromise(
      `${this.apiBase()}/firmware/approvals/${id}`,
      (record, resolve) => {
        resolve( this.entity(record) );
      },
      options
    );
  }

  getListPromise( options = {}) {
    return this._get(
      `${this.apiBase()}/firmware/approvals`,
      (json, resolve) => {
        const response = []; // @todo DataStreamCardSet
        if (json) {
          for (let i = 0; i < json.length; i++) {
            response.push(this.entity(json[i]));
          }
        } else {
          // error handling.
        }
        console.log(response);
        resolve(response);
      },
      options
    );
  }

}
