import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ToasterService} from 'angular2-toaster';
import {AlertLightningFacadeEntity} from '../../../../entities/alert';
import {AlertFacadeDefaultWidgetComponent} from './default.widget.component';
import {AlertFacadeWidgetEventStruct} from './alert-facade-widget-event.struct';

@Component({
  selector: 'alert-facade-lightning-widget',
  template: `
    <div class="card card-accent-dark alert-facade-lightning-widget">
      <div class="card-header">Lightning Alert</div>
      <div class="card-body">
        <div class="row"><div class="col-4">Device</div><div class="col-8"><a [routerLink]="'/portal/devices/show/' + entity.device">{{entity.device}}</a></div></div>
        <div class="row"><div class="col-4">Association</div><div class="col-8">{{entity.deviceAssociation}}</div></div>
        <div class="row"><div class="col-12"><hr/></div></div>
        <div class="row">
          <pre>{{entity|json}}</pre>
        </div>
      </div>
    </div>
    `
})
export class EmbedAlertLightningFacadeComponent extends AlertFacadeDefaultWidgetComponent{
  public editMode = false;
  @Input() layout: string = null;
  @Input() entity: AlertLightningFacadeEntity;
  @Input() options: any;
  @Output() widgetEvent = new EventEmitter<AlertFacadeWidgetEventStruct>();
  forwardEvent(e, widgetEvent: AlertFacadeWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }
  constructor(public toasterService: ToasterService) {
    super();
  }


}
