
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { FirebaseAuthService }        from '../noizu/services/firebase-auth.service';
import { AppengineRepo }      from './appengine.repo';
import { ChannelDefinitionEntity } from '../entities/channel-definition.entity';

@Injectable()
export class ChannelDefinitionRepo extends AppengineRepo {
  public _kind = "alerts";
  public _singular = "alert";

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  entity(record) {
    return new ChannelDefinitionEntity(this.client, this.auth, record)
  }
}
