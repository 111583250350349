<div class="col-12 row">
  <div class="card col-12">
    <div class="card-block" *ngIf="!(entry.identifier=='new' || editEntry==true)">
      <button class="btn btn-primary pull-right" (click)="edit($event, entry)">Edit</button>
      <h4 class="card-title">{{entry.serial}}</h4>
      <h6 class="card-subtitle mb-2 text-muted">{{entry.identifier}}</h6>
      <p class="card-text">Handle: {{entry.handle}}</p>
      <p class="card-text">Series: {{entry.series}}</p>
      <p class="card-text">Manufacturer: {{entry.manufacturer}}</p>
      <p class="card-text">Factory Mode: {{entry.factoryMode}}</p>
      <p class="card-text">Batch: {{entry.batch}}</p>
      <p class="card-text">Secret/PSK: {{entry.secret}}</p>

      <p class="card-text">Activated On: {{entry.activatedOn}}</p>
      <p class="card-text">Created On: {{entry.createdOn}}</p>
      <p class="card-text">Modified On: {{entry.modifiedOn}}</p>
    </div>

    <div class="card-block" *ngIf="entry.identifier=='new' || editEntry==true">
      <h4 class="card-title">{{entry.identifier}}</h4>
      <p class="card-text" *ngIf="isNew">Serial:
        <input class="form-control" [(ngModel)]="entry.serial" placeholder="name" type="text" name="serial">
      </p>
      <p class="card-text">Series:
        <input class="form-control" [(ngModel)]="entry.series" placeholder="name" type="text" name="series">
      </p>
      <p class="card-text">Handle
        <input class="form-control" [(ngModel)]="entry.handle" placeholder="name" type="text" name="handle">
      </p>
      <p class="card-text">Factory Mode
        <input class="form-control" [(ngModel)]="entry.factoryMode" placeholder="name" type="text" name="factoryMode">
      </p>
      <p class="card-text">Batch:
        <input class="form-control" [(ngModel)]="entry.batch" placeholder="name" type="text" name="batch">
      </p>
      <p class="card-text">Manufacturer:
        <select name="manufacturer" [(ngModel)]="entry.manufacturer" style="color:black">
          <option *ngFor="let manu of manufacturers" [value]="manu.value">{{manu.display}}</option>
        </select>
      </p>

      <div class="card-footer">
        <button (click)="cancel($event)" class="btn btn-sm btn-primary" type="submit"><i class="fa fa-dot-circle-o"></i> Cancel</button>
        <button (click)="update($event, entry)" class="btn btn-sm btn-primary float-right" type="submit"><i class="fa fa-dot-circle-o"></i> Add/Update</button>
        <button (click)="delete($event, entry)" class="btn btn-sm btn-warning float-right mx-2" type="submit"><i class="fa fa-minus-circle"></i> Delete</button>
      </div>
    </div>
  </div>
</div>
