import { NoizuStruct }        from './noizu-struct';
import {WidgetEnum} from '../../enums';
import {AppengineRepo} from '../../repos';
import {EntityList} from './entity-list';

export class AppengineEntityList extends EntityList {
  public repo: AppengineRepo = null;

  constructor(kind, items, cursor, containerContents:WidgetEnum = null, repo = null, options) {
    super(kind, items, cursor, containerContents, repo, options);
    this.repo = repo;
  } // end constructor

  nextPromise(options = {}) {
    if (this.repo && this.cursor) {
      let o = this.options || {};
      o['cursor'] = this.cursor;

      if (options['resultsPerPage']) o['resultsPerPage'] = options['resultsPerPage'];
      else if (options['rpp']) o['resultsPerPage'] = options['rpp'];

      let r =  this.repo.getListPromise(o);
      r.then((u: AppengineEntityList) => {
        if (u) {
          this.items = this.items.concat(u.items);
          if (u.cursor == this.cursor) this.endOfList = true;
          this.cursor = u.cursor;
          this.options = u.options;
          if (this.repo._sort) this.items = this.items.sort(this.repo._sort);
        }
      });
      return r;
    }
    return new Promise(
      (resolve, reject) => {
        reject(null);
      }
    );
  }

  widget_type() {
    return this.containerContents || WidgetEnum.EMBED_WIDGET__APPENGINE_SET;
  }

}
