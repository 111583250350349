
import { Component, Inject, Input, NgZone } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location }               from '@angular/common';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { SensorTypeRangeEntity } from '../../../entities/sensor-type-range.entity';
import { SensorTypeRangeRepo } from '../../../repos/sensor-type-range.repo';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap/modal';
import {ModalWidget} from '../../../entities/modal';
import {ModalWidgetEnum} from '../../../enums/widget';
import {ToasterService} from 'angular2-toaster';
import {WidgetEventStruct} from '../../../widgets';

@Component({
  templateUrl: './sensor-type-mapping.component.html',
})
export class DeviceAutoLoadSensorTypeMappingComponent {
  public entries: any = {};
  public series: any = [];
  public batches: any = {};
  public newMapModalRef: BsModalRef;
  public currentModal: any;
  public newMapModal = {
    title: 'Add New Device Mapping',
    msg: `Assign range of serial numbers to batch, series, type..`,
    errorMsg: null,
    processing: false,
    form: 'new-map',
    confirmMsg: 'Save',
    confirm: ()  => {
      if (this.saveNewMap()) {
        this.newMapModalRef.hide();
      }
    },

    cancelMsg: 'Cancel',
    cancel: ()  => {
      this.cancelNewMap();
      this.newMapModal.errorMsg = null;
      this.newMapModalRef.hide()
    },
  };


  constructor(public repo: SensorTypeRangeRepo, public zone: NgZone , public modalService: BsModalService ) {
    this.zone.run(
      () => {
        this.repo.getListPromise({"page": 0, "resultsPerPage":1024}).then((u: any) => {
          this.entries = {};
          for (let i in u.items) {
            let entry = u.items[i]
            let b = entry.batch || "NONE"
            let s = entry.range_series || "NONE"
            if (!this.entries[s]) {
              this.entries[s] = {}
            }
            if (!this.entries[s][b]) {
              this.entries[s][b] = []
            }
            this.entries[s][b].push(entry)
          }

          this.series = []
          this.batches = {}

          Object.keys(this.entries).forEach(
            (key) => {
              this.series.push(key)
              this.batches[key] = []

              Object.keys(this.entries[key]).forEach(
                (bkey) => {
                  this.batches[key].push(bkey)

                  this.entries[key][bkey] = this.entries[key][bkey].sort((n1,n2) => {
                    if (n1.name > n2.name) {
                      return 1;
                    }

                    if (n1.name < n2.name) {
                      return -1;
                    }

                    if (n1.range_start > n2.range_start) {
                      return 1;
                    }

                    if (n1.range_start < n2.range_start) {
                      return -1;
                    }


                    return 0;
                  });

                }
              );

            }
          );
        });
      }
    )
  }


  showModal(current, modal, clickOut = false) {
    this.currentModal = current;
    if (!clickOut) {
      let config: ModalOptions = {
        backdrop : 'static',
        keyboard : false,
        class: "lg-modal"
      };
      this.currentModal.modalRef = this.modalService.show(modal, config);
    } else {
      let config: ModalOptions = {
        class: "lg-modal"
      };
      this.currentModal.modalRef = this.modalService.show(modal, config);
    }
  }

  eventHandler(event: WidgetEventStruct, modal) {
    if (event.event_type == 'range_modal') {

      let method_overrides = {
        actions: [
          {
            msg: 'Fix Type',
            class: 'btn btn-info',
            cb: (self, event, modalRef, toaster) => {


              let result = confirm("Repair Range Device Type?");
              if (result) {
                toaster.pop('info', 'Repair', 'Repairing Range.');
                self.subject.repairRange('type').then(()=> {
                  toaster.pop('success', 'Range Repair', 'Queued.');
                  modalRef.hide()
                })
              }

            }
          },
          {
            msg: 'Fix Batch',
            class: 'btn btn-info ml-1',
            cb: (self, event, modalRef, toaster) => {


              let result = confirm("Repair Range Batch?");
              if (result) {
                toaster.pop('info', 'Repair', 'Repairing Range.');
                self.subject.repairRange('batch').then(()=> {
                  toaster.pop('success', 'Range Repair', 'Queued.');
                  modalRef.hide()
                })
              }

            }
          },
          {
            msg: 'Fix Series',
            class: 'btn btn-info ml-1',
            cb: (self, event, modalRef, toaster) => {


              let result = confirm("Repair Range Series?");
              if (result) {
                toaster.pop('info', 'Repair', 'Repairing Range.');
                self.subject.repairRange('series').then(()=> {
                  toaster.pop('success', 'Range Repair', 'Queued.');
                  modalRef.hide()
                })
              }

            }
          }

        ]
      };
      let dialog = new ModalWidget(
        "Edit Range Mapping Entry",
        ModalWidgetEnum.MODAL_WIDGET__UPDATE,
        event.event_body.entity,
        {edit: true},
        'shadowbox',
        method_overrides);
      this.showModal(dialog, modal)
    }

    if (event.event_type == 'device_definition_modal') {
      let dialog = new ModalWidget(
        "Device Type",
        ModalWidgetEnum.MODAL_WIDGET__SHOW,
        event.event_body.entity,
        {edit: false},
        'shadowbox');
      this.showModal(dialog, modal, true)
    }

  }


  add(e, modal, series, batch) {
    e.preventDefault();

    let newRecord = this.repo.entity({})
    newRecord.batch = batch ? `ref.batch.${batch}` : 'ref.batch.6'
    newRecord.range_series = series ? series : "V3"
    newRecord.manufacturer = 'ref.manufacturer.14'
    newRecord.identifier = 'new'
    let dialog = new ModalWidget(
      "Create Range Mapping Entry",
      ModalWidgetEnum.MODAL_WIDGET__CREATE,
      newRecord,
      {edit: true},
      'shadowbox');

    this.showModal(dialog, modal)
  }



  saveNewMap() {
    return true;
  }

  cancelNewMap() {
    return true;
  }

}
