import { Component, Input, Inject } from '@angular/core';
import {DataStreamDeviceCardEntity} from '../../../entities/data-stream/device-card.entity';
import {DataStreamCardEntity} from '../../../entities/data-stream-card.entity';
import {DeviceReading} from '../../../entities/data-stream/link/device-link';
import {DiagnosticReading} from '../../../entities/data-stream/diagnostic-card.entity';
import {CacheService} from '../../../services/cache.service';
import {UserDeviceAssociationRepo} from '../../../repos/user/device-association.repo';
import {TimeZoneOptions} from '../../../structs/time_zone_options';

@Component({
  selector: 'embed-ds-device-card',
  template: `
          <div class="embed-ds-device-card" *ngIf="!editMode">
              <div class="row">
                  <div class="col font-xl"><b>Text 1:</b> {{entity.messages.one}}</div>
                  <div class="col font-xl"><b>Text 2:</b> {{entity.messages.two}}</div>
              </div>
              <div class="row">
                  <div class="col"><b class="font-xl">Device:</b> {{entity.link.device_association}}</div>
                  <div class="col font-xl"><b>Reading:</b> {{entity.link.linkType()}}</div>
              </div>
          </div>
          <div class="embed-ds-device-card" *ngIf="editMode">
              <div class="row">
                  <div class="col-12">
                      <div class="card mb-0 pb-0 border-warning">
                          <div class="card-body" *ngIf="entity">
                              <!-- Type Specific -->
                              <div class="row"><div class="col-4"><b>Text One</b></div>
                                  <div class="col-8">
                                      <input
                                              type="text"
                                              [(ngModel)]="entity.messages.one"
                                              (ngModelChange)="editMessageOne($event)"
                                              name="[message]one"
                                              size="10"/>
                                  </div></div>
                              <div class="row"><div class="col-4"><b>Text Two</b></div><div class="col-8">
                                  <input
                                          type="text"
                                          [(ngModel)]="entity.messages.two"
                                          (ngModelChange)="editMessageTwo($event)"
                                          name="[message]two"
                                          size="10"/>
                              </div></div>


                              <div class="row"><div class="col-4"><b>Time Zone</b></div><div class="col-8">
                                <ng-select
                                  name="[link]tz"
                                  [multiple]="false"
                                  [(ngModel)]="entity.link.time_zone"
                                  bindLabel="text"
                                  bindValue="value"
                                  (ngModelChange)="setTimeZone($event)"
                                  [items]="timeZoneOptions"
                                ></ng-select>
                              </div></div>

                              <div class="row"><div class="col-4"><b>Device Association</b></div>
                                  <div class="col-8">
                                    <ng-select
                                      [multiple]="false"
                                      name="[link]device"
                                      [(ngModel)]="entity.link.device_association"
                                      bindLabel="text"
                                      bindValue="value"
                                      (ngModelChange)="editDeviceAssociation($event)"
                                      [items]="deviceAssociationOptions"
                                    ></ng-select>
                                  </div></div>

                              <div class="row"><div class="col-4"><b>Device</b></div>
                                  <div class="col-8">
                                      {{entity.link.device}}
                                  </div>
                              </div>

                              <div class="row"><div class="col-4"><b>Type</b></div><div class="col-8">
                                <ng-select
                                  [name]="'[link]type'"
                                  [multiple]="false"
                                  [(ngModel)]="entity.link.type"
                                  bindLabel="text"
                                  bindValue="value"
                                  (ngModelChange)="setLink($event)"
                                  [items]="linkOptions"
                                ></ng-select>


                              </div></div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
    `
})
export class EmbedDsDeviceCardComponent {
  public editMode = false;
  @Input() entity: DataStreamDeviceCardEntity = null;
  @Input() layout: string = null;
  @Input() set options(options: any) {
    this._options = options;
    if ('editMode' in options) {
      this.editMode = options['editMode'];
    }
    if (options['owner']) {
      const cacheKey = `${options['owner']}.devices`;
      console.log('Attempt to load device associations from cache');
      const userDevices = this.cache.getCache(cacheKey, () => {
        console.log('CacheMiss - user devices');
        // @todo repo query.
        const userId = options['owner'].substring(9,  options['owner'].length);
        this.associationRepo.getUserListPromise(userId).then((response) => {
          this.updateDeviceAssociations(cacheKey, response);
        });
        return {items: []};
      });
      // Placeholder.
      if (userDevices) {
        console.log('Got Device List', userDevices);

        this.deviceAssociationOptions = [];
        // tslint:disable-next-line:forin
        for (const entry in userDevices.value.items) {
          const a = userDevices.value.items[entry];
          this.deviceAssociationOptions.push(
            {value: `ref.device-association.${a.identifier}`, text: `${a.device_name} - ${a.sensor_serial}`}
          );
        }
        console.log('UPDATE Associations', this.deviceAssociationOptions);
      } else {
        console.log('Failed to Get Device List');
        this.deviceAssociationOptions = [];
      }
    } else {
      console.log('owner not set');
    }
  }
  get options() {
    return this._options;
  }

  public _options: any = null;
  public timeZoneOptions = TimeZoneOptions;
  public linkOptions = [];
  public deviceAssociations = {};
  public deviceAssociationOptions = [];

  constructor(public cache: CacheService, public associationRepo: UserDeviceAssociationRepo) {
    this.linkOptions = [];
    for (const key in DeviceReading) {
      if (typeof DeviceReading[key] === 'number') {
        this.linkOptions.push({value: DeviceReading[key], text: key});
      }
    }
  }

  updateDeviceAssociations(key, value) {
    this.cache.setCache(key, value);
    if (this.options['owner']) {
      const cacheKey = `${this.options['owner']}.devices`;
      if (cacheKey === key) {
        this.deviceAssociationOptions = [];
        // tslint:disable-next-line:forin
        for (const entry in value.items) {
          const a = value.items[entry];
          this.deviceAssociationOptions.push({value: a.sref(), text: `${a.device_name} - ${a.sensor_serial}`});
        }
      }
    }
  }

  editDeviceAssociation(event) {
    this.entity.modified = true;
  }

  setTimeZone(event) {
    this.entity.modified = true;
  }

  setLink(event) {
    this.entity.modified = true;
  }

  editMessageOne(event) {
    // @todo consider mechanism for setting :auto flag.
    this.entity.modified = true;
  }

  editMessageTwo(event) {
    // @todo consider mechanism for setting :auto flag.
    this.entity.modified = true;
  }

}
