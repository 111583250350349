import {HttpClient} from '@angular/common/http';
import {FirebaseAuthService} from '../../../noizu/services/firebase-auth.service';
import {UserLogWidgetEnum, LogWidgetEnum, NotificationLogWidgetEnum} from '../../../enums';
import {EntityLogEntity} from '../entity-log.entity';

export class UserEntityLogEntity extends EntityLogEntity {
  public log_type: UserLogWidgetEnum = UserLogWidgetEnum.USER_LOG_WIDGET__GENERIC;

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
  } // end constructor

  log_widget_type(): LogWidgetEnum | null {
    return LogWidgetEnum.LOG_WIDGET__USER;
  }

  user_log_widget_type(): UserLogWidgetEnum  {
    return this.log_type;
  }
} // end Package
