import {Component, Input, Inject, OnInit, Output, EventEmitter} from '@angular/core';
import {LacrosseEntity} from "../../entities/lacrosse.entity";
import {NoizuStruct} from "../../noizu/structs/noizu-struct";
import {AlertTriggerStateObject, CallbackObject, RuntimeConfigurationObject, SubscriberObject} from '../../entities/alert';
import {BaseEntitySet} from '../../entities';
import {WidgetBase} from './widget-base';
import {SubscriberWidgetEventStruct} from './subscriber-object/subscriber-widget-event.struct';
import {WidgetEventStruct} from '../widget-event.struct';

@Component({
  selector: 'set-widget',
  template: `
    <div class="card ">
      <div class="card-header">
        <div class="row">
          <div class="col"><b class="text-left">{{title}}</b></div>
          <div class="col-2 text-right p-0 text-middle">
            <toggle-widget [options]="expand_settings"></toggle-widget>
          </div>
        </div>
      </div>
      <div class="card-body p-0"  [ngClass]="{'d-none': !expand_settings.toggle, 'pl-5': has_entries}" >
        <div *ngIf="is_map">
          <div class="row"   *ngFor="let s of entity.entities | keyvalue">
            <div class="col">
              <widget (widgetEvent)="forwardEvent($event)"  [entity]="s.value" [options]="{id: s.key, nested: true}" [layout]="layout"></widget>
            </div>
          </div>
        </div>
        <div *ngIf="!is_map">
          <div class="row"   *ngFor="let e of entity.entities">
            <div class="col">
              <widget (widgetEvent)="forwardEvent($event)"  [entity]="e" [options]="{id: e.identifier, nested: true}" [layout]="layout"></widget>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="!has_entries">
          <div class="col">
            <div class="alert m-0 alert-warning">No Entries</div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class WidgetSetComponent extends  WidgetBase implements OnInit{
  @Input() entity: BaseEntitySet = null;
  //@Input() options: any = null;
  @Input() layout: string = null;
  public has_entries = false;
  public is_map = false;
  @Input() title:any = null;



  @Output() widgetEvent = new EventEmitter<WidgetEventStruct>();
  forwardEvent(widgetEvent: WidgetEventStruct) {
    this.widgetEvent.emit(widgetEvent);
  }

  constructor() {
    super();

  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.has_entries = this.entity && this.entity.hasEntities();
    this.title = this.title || this.options['title'] || this.entity.name();
    this.is_map = this.entity.isMap();
  }
}
