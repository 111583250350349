

    <div class="card" *ngIf="entity">
      <div class="card-header"><b>Health Report:</b> {{entity.identifier}}

        <div class="card-header-actions">
          <i class="fa fa-refresh" (click)="refresh($event)"></i>
        </div>
      </div>
      <div class="card-body">

        <div class="row mb-4">
          <div class="col-4"><b>Status</b></div>
          <div class="col">{{entity.status}}</div>
        </div>

        <div class="card">
          <div class="card-header">
            <strong>Server</strong>
            <div class="card-header-actions">
                <i class="fa" [ngClass]="{'fa-expand': !expand_server, 'fa-compress': expand_server}" (click)="expand_server = !expand_server"></i>
            </div>
          </div>
          <div class="card-body" *ngIf="expand_server">
            <div class="row mb-4">
              <div class="col">

                <div class="row">
                  <div class="col">
                    <b>Memory</b>
                  </div>
                </div>

                <div class="row">
                  <div class="col-4">worst.process</div>
                  <div class="col">{{entity.server.memory.worst.process}}</div>
                </div>
                <div class="row">
                  <div class="col-4">worst.consumption</div>
                  <div class="col">{{entity.server.memory.worst.consumption}}</div>
                </div>

                <div class="row">
                  <div class="col-4">total</div>
                  <div class="col">{{entity.server.memory.total}}</div>
                </div>

                <div class="row">
                  <div class="col-4">allocated</div>
                  <div class="col">{{entity.server.memory.allocated}}</div>
                </div>


                <div class="row mt-2">
                  <div class="col">
                    <b>Memory.System</b>
                  </div>
                </div>

                <div class="row">
                  <div class="col-4">system.total_swap</div>
                  <div class="col">{{entity.server.memory.system.total_swap}}</div>
                </div>


                <div class="row">
                  <div class="col-4">total_swap</div>
                  <div class="col">{{entity.server.memory.system.total_swap}}</div>
                </div>
                <div class="row">
                  <div class="col-4">total_memory</div>
                  <div class="col">{{entity.server.memory.system.total_memory}}</div>
                </div>
                <div class="row">
                  <div class="col-4">system_total_memory</div>
                  <div class="col">{{entity.server.memory.system.system_total_memory}}</div>
                </div>
                <div class="row">
                  <div class="col-4">free_swap</div>
                  <div class="col">{{entity.server.memory.system.free_swap}}</div>
                </div>
                <div class="row">
                  <div class="col-4">free_memory</div>
                  <div class="col">{{entity.server.memory.system.free_memory}}</div>
                </div>
                <div class="row">
                  <div class="col-4">cached_memory</div>
                  <div class="col">{{entity.server.memory.system.cached_memory}}</div>
                </div>
                <div class="row">
                  <div class="col-4">buffered_memory</div>
                  <div class="col">{{entity.server.memory.system.buffered_memory}}</div>
                </div>

                <div class="row mt-4">
                  <div class="col">
                    <b>CPU</b>
                  </div>
                </div>

                <div class="row">
                  <div class="col-4">nprocs</div>
                  <div class="col">{{entity.server.cpu.nprocs}}</div>
                </div>
                <div class="row">
                  <div class="col-4">load_1_min</div>
                  <div class="col">{{entity.server.cpu.load_1_min}}</div>
                </div>
                <div class="row">
                  <div class="col-4">load_5_min</div>
                  <div class="col">{{entity.server.cpu.load_5_min}}</div>
                </div>
                <div class="row">
                  <div class="col-4">load_15_min</div>
                  <div class="col">{{entity.server.cpu.load_15_min}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <strong>Mnesia</strong>
            <div class="card-header-actions">
                <i class="fa" [ngClass]="{'fa-expand': !expand_mnesia, 'fa-compress': expand_mnesia}"  (click)="expand_mnesia = !expand_mnesia"></i>
            </div>
          </div>
          <div class="card-body" *ngIf="expand_mnesia">
            <div class="row mb-4">
              <div class="col">
                <div class="row">
                  <div class="col-4">is_running</div>
                  <div class="col">{{entity.mnesia.is_running}}</div>
                </div>
                <div class="row">
                  <div class="col-4">TXN</div>
                  <div class="col"><ngx-select-dropdown [options]="entity.mnesia.transactions"></ngx-select-dropdown></div>
                </div>
                <div class="row">
                  <div class="col-4">Tables</div>
                  <div class="col"><ngx-select-dropdown [options]="entity.mnesia.local_tables"></ngx-select-dropdown></div>
                </div>
                <div class="row">
                  <div class="col-4">Nodes</div>
                  <div class="col"><ngx-select-dropdown [options]="entity.mnesia.db_nodes"></ngx-select-dropdown></div>
                </div>
                <div class="row">
                  <div class="col-4">Extra Nodes</div>
                  <div class="col"><ngx-select-dropdown [options]="entity.mnesia.extra_db_nodes"></ngx-select-dropdown></div>
                </div>
                <div class="row">
                  <div class="col-4">Locks</div>
                  <div class="col"><ngx-select-dropdown [options]="entity.mnesia.held_locks"></ngx-select-dropdown></div>
                </div>
                <div class="row">
                  <div class="col-4">TXN Restarts</div>
                  <div class="col">{{entity.mnesia.transaction_restarts}}</div>
                </div>
                <div class="row">
                  <div class="col-4">TXN Log Write</div>
                  <div class="col">{{entity.mnesia.transaction_log_writes}}</div>
                </div>
                <div class="row">
                  <div class="col-4">TXN Failures</div>
                  <div class="col">{{entity.mnesia.transaction_failures}}</div>
                </div>
                <div class="row">
                  <div class="col-4">#TXN</div>
                  <div class="col">{{entity.mnesia.num_transactions}}</div>
                </div>
                <div class="row">
                  <div class="col-4">#Locks</div>
                  <div class="col">{{entity.mnesia.num_held_locks}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <strong>Endpoint</strong>
            <div class="card-header-actions">
                <i class="fa" [ngClass]="{'fa-expand': !expand_endpoints, 'fa-compress': expand_endpoints}" (click)="expand_endpoints = !expand_endpoints"></i>
            </div>
          </div>
          <div class="card-body" *ngIf="expand_endpoints">
            <div class="row mb-4">
              <div class="col">

                <div class="row">
                  <div class="col-4">Status</div>
                  <div class="col">{{entity.endpoint.status | json}}</div>
                </div>
                <div class="row">
                  <div class="col-4">State</div>
                  <div class="col">{{entity.endpoint.state}}</div>
                </div>

                <div class="row">
                  <div class="col-4">Node</div>
                  <div class="col">{{entity.endpoint.node}}</div>
                </div>

                <div class="row">
                  <div class="col-4">Load</div>
                  <div class="col">{{entity.endpoint.load | json}}</div>
                </div>
                <div class="row">
                  <div class="col-4">Environment</div>
                  <div class="col">{{entity.endpoint.environment}}</div>
                </div>

                <div class="row">
                  <div class="col-4">Details</div>
                  <div class="col">{{entity.endpoint.details | json}}</div>
                </div>

                <div class="row">
                  <div class="col-4">Capacity</div>
                  <div class="col">{{entity.endpoint.capacity | json}}</div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <strong>Timeouts</strong>
            <div class="card-header-actions">
                <i class="fa" [ngClass]="{'fa-expand': !expand_timeouts, 'fa-compress': expand_timeouts}" (click)="expand_timeouts = !expand_timeouts"></i>
            </div>
          </div>
          <div class="card-body" *ngIf="expand_timeouts">
            <div class="row mb-4">
              <div class="col">
                <div class="row">
                  <div class="col-4">Last 1 Minute</div>
                  <div class="col">{{entity.process_timeouts.last_1_min}}</div>
                </div>
                <div class="row">
                  <div class="col-4">Last 5 Minute</div>
                  <div class="col">{{entity.process_timeouts.last_5_min}}</div>
                </div>
                <div class="row">
                  <div class="col-4">Last 15 Minute</div>
                  <div class="col">{{entity.process_timeouts.last_15_min}}</div>
                </div>
                <div class="row">
                  <div class="col-4">Last 60 Minute</div>
                  <div class="col">{{entity.process_timeouts.last_60_min}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer text-center">
        <button class="btn btn-danger" (click)="lock($event)">Lock</button>
        <button class="btn btn-danger" (click)="release($event)">Release</button>
        <button class="btn btn-danger" (click)="balance($event)">Balance</button>
        <button class="btn btn-danger" (click)="offload($event)">Offload</button>
      </div>
    </div>

