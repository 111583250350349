import { NoizuStruct }        from '../../noizu/structs/noizu-struct';
import {EntityLogSet} from '../../entities/log/entity-log.set';
import {AlertRepo} from '../../repos';
import {HttpClient} from '@angular/common/http';
import {FirebaseAuthService} from '../../noizu/services/firebase-auth.service';
import {AlertFacadeEntity} from '../../entities/alert/facade.entity';
import {
  AlertContactFacadeEntity,
  AlertHumidityFacadeEntity,
  AlertLightningFacadeEntity,
  AlertMotionFacadeEntity,
  AlertOnOffFacadeEntity,
  AlertProbeFacadeEntity,
  AlertRainfallFacadeEntity,
  AlertTemperatureFacadeEntity,
  AlertThermometerFacadeEntity, AlertWetDryFacadeEntity, AlertWindFacadeEntity
} from '../../entities/alert/facade';

export class ExtendedAlertDetails extends NoizuStruct {
  public identifier: number;
  public loading: boolean;
  public loading_component: any = {};
  public triggered = false;
  public hasChanges = false;

  public status: any = null;
  public status_loaded: boolean = false;
  public state: any = null;
  public state_valid: boolean = false;
  public state_promise: any = null;
  public state_promise_valid: boolean = false;

  public logs: {alert: EntityLogSet} = null;
  public repo: AlertRepo = null;

  public new: boolean = false;
  public saving = false;
  public expanded = false;
  public editMode = false;

  public factory: Record<string, (number, any) => any> = {};



  constructor(private client: HttpClient, private auth: FirebaseAuthService, user) {
    super();

    this.status = {};
    this.factory = {
      "lacrosseAlerts#Alert.Contact": (identifier, json) => { return new  AlertContactFacadeEntity(identifier, json);},
      "lacrosseAlerts#Alert.Humidity": (identifier, json) => { return new AlertHumidityFacadeEntity(identifier, json);},
      "lacrosseAlerts#Alert.LightningProximity": (identifier, json) => { return new  AlertLightningFacadeEntity(identifier, json);},
      "lacrosseAlerts#Alert.Motion": (identifier, json) => { return new  AlertMotionFacadeEntity(identifier, json);},
      "lacrosseAlerts#Alert.OnOff": (identifier, json) => { return new  AlertOnOffFacadeEntity(identifier, json);},
      "lacrosseAlerts#Alert.Probe": (identifier, json) => { return new  AlertProbeFacadeEntity(identifier, json);},
      "lacrosseAlerts#Alert.Rainfall": (identifier, json) => { return new  AlertRainfallFacadeEntity(identifier, json);},
      "lacrosseAlerts#Alert.Temperature": (identifier, json) => { return new  AlertTemperatureFacadeEntity(identifier, json);},
      "lacrosseAlerts#Alert.Thermometer": (identifier, json) => { return new  AlertThermometerFacadeEntity(identifier, json);},
      "lacrosseAlerts#Alert.WetDry": (identifier, json) => { return new  AlertWetDryFacadeEntity(identifier, json);},
      "lacrosseAlerts#Alert.Wind": (identifier, json) => { return new  AlertWindFacadeEntity(identifier, json);},
      "lacrosseAlerts#Generic": (identifier, json) => { return new  AlertFacadeEntity(identifier, json);},
    };

    this.loading = false;
    this.identifier = user['id'] || user['identifier'] || null;
    this.logs = {
      alert: new EntityLogSet(client, auth, null,[])
    }
  } // end constructor


  public setState(json) {
    this.state = json;
    this.state_valid = true;
    this.status_loaded = false;
    if (json && json['trigger_state']) this.triggered = json.trigger_state['triggered?'];
  }

  public refreshAlertState(alert, force = false) {
    if (!force && this.state_promise && this.state_promise_valid) {
      return this.state_promise;
    } else {
      this.state_promise_valid = true;
      // @ts-ignore
      this.state_promise = this.repo.getAlertStatePromise(this.identifier).then((this_state) => {
        alert.setState(this_state);
      }).catch((e) => {
        this.state_promise_valid = false;
      });
      return this.state_promise;
    }
  }

  alertStatePromise(alert) {
    if (this.state && this.state.valid) return new Promise((resolve) => {resolve(this.state);});
    else if (this.state_promise && this.state_promise_valid) return this.state_promise;
    else return this.refreshAlertState(alert);
  }

}
