import {Component, Input, Output, EventEmitter} from '@angular/core';
import {EntityLogEntity} from '../../../../entities/log/entity-log.entity';
import {WidgetBase} from '../../widget-base';
import {LogWidgetEventStruct} from '../log-widget-event.struct';
import {LogWidgetEnum} from '../../../../enums';
import {AlertEntityLogEntity} from '../../../../entities';

@Component({
  selector: 'alert-generic-log-widget',
  template: `

    <ng-container *ngIf="entity">
      <ng-container [ngSwitch]="layout">
        <ng-container *ngSwitchCase="'table'">
          <div class="alert-generic-log-widget div-table-th" *ngIf="options['index'] == 0 && options['header']">
            <div class="row" >
              <ng-container *ngFor="let column of options.columns">
                <ng-container [ngSwitch]="column">
                  <div class="col-2" *ngSwitchCase="'time'">Time</div>
                  <div class="col-1" *ngSwitchCase="'level'">Level</div>
                  <div class="col-1" *ngSwitchCase="'topic'">Topic</div>
                  <div class="col-1" *ngSwitchCase="'topic'">Subtopic</div>
                  <div class="col-1" *ngSwitchCase="'type'">Type</div>
                  <div class="col-2" *ngSwitchCase="'subject'">Subject</div>
                  <div class="col-3" *ngSwitchCase="'brief'">Brief</div>
                  <div class="col-1" *ngSwitchCase="'actions'"></div>
                </ng-container>
              </ng-container>
            </div>
          </div>
          <div class="alert-generic-log-widget" [ngClass]="{'div-table-tr': (options['index'] % 2) == 1, 'div-table-tr-alt': (options['index'] % 2) == 0}">
            <div class="row">
              <ng-container *ngFor="let column of options.columns">
                <ng-container [ngSwitch]="column">

                  <div *ngSwitchCase="'time'" class="col-2 text-truncate" title="{{entity.date_time | date:'MM/dd/yyyy HH:mm:ss'}}">{{entity.date_time | date:'MM/dd/yyyy HH:mm:ss'}}</div>
                  <div *ngSwitchCase="'level'" class="col-1 text-truncate" title="{{entity.level}}">
                    <span class="badge badge-pill" [ngClass]="{
            'badge-info': entity.level == 'info',
            'badge-warning': entity.level == 'warn',
            'badge-danger': entity.level == 'error',
            'badge-secondary': !(entity.level == 'error' || entity.level == 'warn' || entity.level == 'info')
            }">{{entity.level}}</span>
                  </div>
                  <div *ngSwitchCase="'topic'" class="col-1 text-truncate" title="{{entity.topic}}">{{entity.topic}}</div>
                  <div *ngSwitchCase="'subtopic'" class="col-1 text-truncate" title="{{entity.subtopic}}">{{entity.subtopic}}</div>
                  <div *ngSwitchCase="'type'" class="col-1 text-truncate" title="{{entity.type}}">{{entity.type}}</div>
                  <div *ngSwitchCase="'subject'" class="col-2 text-truncate" title="{{entity.subject}}">{{entity.subject}}</div>
                  <div *ngSwitchCase="'brief'" class="col-3 text-truncate" title="{{entity.brief()}}">{{entity.brief()}}</div>
                  <div *ngSwitchCase="'actions'" class="col-1 text-center">
                    <i
                      (click)="openRow = !openRow"
                      class="fa fa-sm"
                      [ngClass]="{'fa-chevron-down': !openRow, 'fa-chevron-up': openRow}"> </i>
                  </div>
                </ng-container>
              </ng-container>

            </div>
            <div class="row" *ngIf="openRow">
              <div class="col-12">
                <div class="alert alert-light m-1 p-2 shadow rounded text-dark">


                  <log-details-widget [entity]="entity" [options]="options" [layout]="'table-row-details'"></log-details-widget>




                </div>


              </div>
            </div>
          </div>
        </ng-container>

        <div class="alert-generic-log-widget" *ngSwitchDefault>
          <div class="row">
            <div class="col-12">
              <div class="card m-0 p-0 mb-1" *ngIf="entity">
                <div class="card-header">
                  <div class="row">
                    <div class="col-1"><b class="text-left">
                <span class="badge badge-pill" [ngClass]="{
            'badge-info': entity.level == 'info',
            'badge-warning': entity.level == 'warn',
            'badge-danger': entity.level == 'error',
            'badge-secondary': !(entity.level == 'error' || entity.level == 'warn' || entity.level == 'info')
            }">{{entity.level}}</span>
                    </b></div>

                    <div class="col-10">
                      <strong><span>Alert Log Entry ({{entity.topic}})</span></strong>
                      <span> : {{entity.date_time | date:'MM/dd/yyyy HH:mm:ss'}}</span>
                    </div>

                    <div class="col-1 text-right p-0 text-middle">
                      <toggle-widget [options]="expand_settings"></toggle-widget>
                    </div>
                  </div>

                </div>
                <div class="card-body m-0 p-1"  [ngClass]="{'d-none': expand_settings.toggle}" >
                  <div class="row mb-1">

                    <div class="col-3">
                      <div class="row">
                        <div class="col-12">
                          <b>Topic</b>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          {{entity.topic}}
                        </div>
                      </div>
                    </div>


                    <div class="col-3">
                      <div class="row">
                        <div class="col-12">
                          <b>Subtopic</b>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          {{entity.subtopic}}
                        </div>
                      </div>
                    </div>

                    <div class="col-3">
                      <div class="row">
                        <div class="col-12">
                          <b>Level</b>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          {{entity.level}}
                        </div>
                      </div>
                    </div>

                    <div class="col-6">
                      <div class="row">
                        <div class="col-12">
                          <b>Time</b>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          {{entity.date_time | date:'MM/dd/yyyy HH:mm:ss'}}
                        </div>
                      </div>
                    </div>
                  </div>



                  <log-details-widget [entity]="entity" [options]="options" [layout]="'card-details'"></log-details-widget>






                </div>
              </div>
            </div>
          </div>
        </div>

      </ng-container>
    </ng-container>
    `
})
export class AlertGenericLogWidgetComponent extends WidgetBase{
  @Input() entity: AlertEntityLogEntity = null;
  @Input() options: any = {};
  @Input() layout: string = null;
  @Output() widgetEvent = new EventEmitter<LogWidgetEventStruct>();
  forwardEvent(e, widgetEvent: LogWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }
  public expandCommands = false;
  public LogWidgetEnum = LogWidgetEnum;
  public expand = false;
  public expandRaw = false;
  public openRow = false;
  constructor() {
    super();

  }
}
