
        <div class="card p-0 m-0">
          <div class="card-header p-0 m-0">
            <div class="row">
              <div class="col-3">
                {{entity.time | date:'MM/dd/yyyy HH:mm:ss'}} @ {{entity.span}}
              </div>
              <div class="col-3">
                {{entity.request_category}}
              </div>
              <div class="col-5">
                <div [ngSwitch]="entity.request_category">
                  <div *ngSwitchCase="'report_binary'">
                    [HEX: {{entity.content?.request_body?.Data}}]
                  </div>
                  <div *ngSwitchDefault>
                    [Expand For Additional Details]
                  </div>
                </div>
              </div>
              <div class="col-1 text-center">
                <span>
                  <i
                    (click)="expand = !expand"
                    class="fa fa-sm"
                    [ngClass]="{'fa-chevron-down': !expand, 'fa-chevron-up': expand}"> </i>
                </span>
              </div>
            </div>
          </div>
          <div class="card-body" *ngIf="expand">
             <pre><b>Time:</b> {{entity.time}}</pre>
            <pre><b>Response Code:</b> {{entity.response_code}}</pre>
            <pre><b>Request Token:</b> {{entity.request_token}}</pre>
            <pre><b>Span:</b> {{entity.span}}</pre>
            <pre><b>Type:</b> {{entity.request_category}}</pre>
            <pre><b>Endpoint:</b> {{entity.api_endpoint}}</pre>
            <pre><b>Gateway:</b> {{entity.gateway}} </pre>
            <pre><b>Device:</b> {{entity.device}} </pre>
            <pre><b>Request:</b>
              <div><b>URL:</b>{{entity.content?.url}}?{{entity.content?.query}}</div>
              <div><b>Request:</b>{{entity.content?.request_body || entity.content?.body | json}}</div>
              <div><b>Response:</b>{{entity.content?.response_body | json}}</div>
            </pre>
          </div>
        </div>
    