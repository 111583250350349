import {Component, Input, Inject, Output, EventEmitter} from '@angular/core';
import {NoizuStruct} from '../../../noizu/structs/noizu-struct';
import {FirmwareWidgetEnum, ModalWidgetEnum, WidgetEnum} from '../../../enums/widget';
import {
  FirmwareApprovalEntity,
  FirmwareDefinitionEntity,
  FirmwareLinkV2Entity,
  FirmwareReleaseEntity,
  ModalWidget, SensorTypeRangeEntity,
  TargetStruct
} from '../../../entities/';
import {ToasterService} from 'angular2-toaster';
import {WidgetEventStruct} from '../../widget-event.struct';
import {FirmwareWidgetEventStruct} from './firmware-widget-event.struct';
import {FirmwareDefinitionWidgetComponent} from './firmware-definition-widget.component';
import {FirmwareLinkWidgetComponent} from './firmware-link-widget.component';
import {FirmwareReleaseWidgetComponent} from './firmware-release-widget.component';
import {FirmwareTargetWidgetComponent} from './firmware-target-widget.component';

@Component({
  selector: 'firmware-widget',
  template: `
    <div *ngIf="!entity">
      <widget-spinner></widget-spinner>
    </div>
    <ng-container *ngIf="entity">
      <ng-container [ngSwitch]="entity.firmware_widget_type()">
        <firmware-approval-widget *ngSwitchCase="WidgetType.FIRMWARE_WIDGET__APPROVAL" (widgetEvent)="forwardEvent(widgetEvent)" [entity]="entity" [layout]="layout" [options]="options"></firmware-approval-widget>
        <firmware-definition-widget *ngSwitchCase="WidgetType.FIRMWARE_WIDGET__DEFINITION" (widgetEvent)="forwardEvent(widgetEvent)" [entity]="entity" [layout]="layout" [options]="options"></firmware-definition-widget>
        <firmware-link-widget *ngSwitchCase="WidgetType.FIRMWARE_WIDGET__LINK" (widgetEvent)="forwardEvent(widgetEvent)" [entity]="entity" [layout]="layout" [options]="options"></firmware-link-widget>
        <firmware-release-widget *ngSwitchCase="WidgetType.FIRMWARE_WIDGET__RELEASE" (widgetEvent)="forwardEvent(widgetEvent)" [entity]="entity" [layout]="layout" [options]="options"></firmware-release-widget>
        <firmware-target-widget *ngSwitchCase="WidgetType.FIRMWARE_WIDGET__TARGET" (widgetEvent)="forwardEvent(widgetEvent)" [entity]="entity" [layout]="layout" [options]="options"></firmware-target-widget>
        <firmware-generic-widget *ngSwitchCaseDefault (widgetEvent)="forwardEvent(widgetEvent)" [entity]="entity" [layout]="layout" [options]="options"></firmware-generic-widget>
      </ng-container>
    </ng-container>

  `
})
export class FirmwareWidgetComponent {
  @Input() entity: FirmwareReleaseEntity | FirmwareDefinitionEntity | FirmwareLinkV2Entity | TargetStruct | FirmwareApprovalEntity | null = null;
  @Input() options: any = null;
  @Input() layout = 'standard';

  @Output() widgetEvent = new EventEmitter<FirmwareWidgetEventStruct>();
  forwardEvent(widgetEvent: FirmwareWidgetEventStruct) {
    this.widgetEvent.emit(widgetEvent);
  }

  public  WidgetType = FirmwareWidgetEnum;

  constructor(public toasterService: ToasterService) {

  }
}
