import {NoizuStruct} from '../../noizu/structs/noizu-struct';
import {WidgetEnum} from '../../enums/widget';

export class NotificationSettings extends NoizuStruct {

  public smsEnabled: boolean = false;
  public smsUserChannel: any = null;

  public emailEnabled: boolean = false;
  public emailUserChannel: any = null;

  public pushEnabled: boolean = false;
  public pushUserChannel: any = null;

  public webhookEnabled: boolean = false;
  public webhookUserChannel: any = null;

  public onceHourlyEnabled: boolean = false;
  public onceDailyEnabled: boolean = false;

  public continuousEnabled: boolean = false;
  public continuousTimeSpan: any = null;

  public timeFrameEnabled: boolean = false;
  public timeFrameStart: any = null;
  public timeFrameEnd: any = null;


  public toJson(options = {}) {
    let response = {
      smsEnabled: this.smsEnabled,
      emailEnabled: this.emailEnabled,
      pushEnabled: this.pushEnabled,
      webhookEnabled: this.webhookEnabled,
      onceHourlyEnabled: this.onceHourlyEnabled,
      onceDailyEnabled: this.onceDailyEnabled,
      continuousEnabled: this.continuousEnabled,
    }
    if (this.smsUserChannel) response['smsUserChannel'] = this.smsUserChannel;
    if (this.emailUserChannel) response['emailUserChannel'] = this.emailUserChannel;
    if (this.pushUserChannel) response['pushUserChannel'] = this.pushUserChannel;
    if (this.webhookUserChannel) response['webhookUserChannel'] = this.webhookUserChannel;


    return response;
  }

  public constructor(json) {
    super();

    this.smsEnabled = json['smsEnabled'] || false;
    this.smsUserChannel = json['smsUserChannel'] || null;

    this.emailEnabled = json['emailEnabled'] || false;
    this.emailUserChannel = json['emailUserChannel'] || null;

    this.pushEnabled = json['pushEnabled'] || false;
    this.pushUserChannel = json['pushUserChannel'] || null;

    this.webhookEnabled = json['webhookEnabled'] || false;
    this.webhookUserChannel = json['webhookUserChannel'] || null;

    this.onceHourlyEnabled = json['onceHourlyEnabled'] || false;
    this.onceDailyEnabled = json['onceDailyEnabled'] || false;
    this.continuousEnabled = json['continuousEnabled'] || false;

    this.continuousTimeSpan = json['continuousTimeSpan'] || null;

    this.timeFrameEnabled = json['timeFrameEnabled'] || null;
    this.timeFrameStart = json['timeFrameStart'] || null;
    this.timeFrameEnd = json['timeFrameEnd'] || null;
  }

  setFreq(i: number) {
    this.continuousEnabled = i == 0;
    this.onceHourlyEnabled = i == 1;
    this.onceDailyEnabled = i == 2;
  }


  widget() {
    return "embed-notification-settings";
  }

  widget_type() {
    return WidgetEnum.EMBED_WIDGET__NOTIFICATION_SETTINGS_ENTITY;
  }

}
