
import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from '../lacrosse.entity';
import { FirebaseAuthService }            from '../../noizu/services/firebase-auth.service';

export class UserLocationEntity extends LacrosseEntity {
  public linkedDevices = [];
  public name = "Default";
  public ownerId = "";
  public saving = false;
  public showExtra = false;
  public position = 0;
  public newPos = null;
  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    this.name = json.name ? json.name : "Default";
    this.ownerId = json.ownerId;
  } // end constructor

  deleteLocation() {
    let locationID = this.raw.id;
    let ownerID = this.raw.ownerId;
    let url = `${this.apiBase()}/user/${ownerID}/location/${locationID}`;

    return this._delete(
      url,
      (_data, resolve) => {resolve(true)},
      {}
    );
  }

  createLocation() {
    if (this.identifier == "new" && this.linkedDevices.length == 0) {
      let ownerId = this.ownerId;
      let name = this.name ? this.name : "defaultName";
      let data = {
        "ownerId":ownerId,
        "name": name
      }
      let url = `${this.apiBase()}/user/${ownerId}/locations`;

      return this._post(
        url,
        data,
        (_data, resolve) => {resolve(true)},
        {}
      );
    } else {
      alert("Location not valid for creation");
      return null;
    }

  }

  reposition(beforeId) {
    let url = `${this.apiBase()}/user/${this.ownerId}/location/${this.identifier}/rank/before/${beforeId}`;
    return this._post(
      url,{},
      (_data, resolve) => {resolve(true)},
      {}
    );
  }
} // end Package
