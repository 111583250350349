
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { FirebaseAuthService }        from '../../noizu/services/firebase-auth.service';
import {ElixirRepo} from '../elixir.repo';
import {ElixirEntity} from '../../entities/elixir.entity';
import {UserSubscriptionEntity} from '../../entities/user/extended/subscription.entity';

@Injectable()
export class UserSubscriptionRepo extends ElixirRepo {
  public _kind = "subscriptions";
  public _singular = "subscription";
  public user = "";
  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  entity(record = {}) : ElixirEntity {
    let e = new UserSubscriptionEntity(this.client, this.auth, record);
    if (!e.owner) e.owner = this.user;
    if (!e.subscription_start) e.subscription_start = new Date();
    if (!e.subscription_end) e.subscription_end = new Date(e.subscription_start.getTime() + (1000 * 60 * 60 * 24 * 365) );
    return e;
  }


  filterOptions() {
    return "handle, type, status, owner, txn, otxn, payment_status, payment_group, source";
  }

}
