
import { Component, TemplateRef, NgZone, OnInit, OnDestroy}      from '@angular/core';
import { ActivatedRoute, Router,  Params } from '@angular/router';

import { GatewayEntity } from '../../entities/gateway.entity';
import { GatewayRepo } from '../../repos/gateway.repo';

import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  templateUrl: './list.component.html'
})
export class GatewayListComponent {
  public searchTerm: string;
  public state = {loading: false, hasResults: false, error: false, errorMsg: ""};
  public notification = {"showLoading":false, "message":"","iserror":false};
  public gatewayEntity = null;
  public currentModal:any;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public gatewayRepo: GatewayRepo,
    public modalService: BsModalService
  ) {}

  hideMessage() {
    this.notification.message="";
    this.notification.iserror=false;
  }

  clearSearchResults() {
    this.state.error = false;
    this.state.loading = true;
    this.gatewayEntity = null;
  }

  searchGateway(event) {
    event.preventDefault();
    this.clearSearchResults();
    if (this.searchTerm == null || this.searchTerm.length !=6) {
      this.showError("You must provide 6 digit search term.");
    } else {
      this.gatewayRepo.getBySerialPromise(this.searchTerm)
        .then((entity:any) => {
          console.log(entity);
          if (entity["success"] == false) {
            throw(entity);
          }
          this.gatewayEntity = entity
          this.state.loading = false;
        })
      .catch(this.handleError.bind(this));
    }
  }

  handleError(error:any) {this.showError(error.msg);}

  showError(error:string) {
    this.state.loading = false;
    this.state.errorMsg = error;
    this.state.error = true;
  }
}
