
import { Component, Inject, Input, NgZone } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location }               from '@angular/common';
import { NgModel, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { SubscriptionsEntity } from '../../entities/subscriptions.entity';
import { SubscriptionsRepo } from '../../repos/data/subscriptions.repo';

@Component({
  selector: 'subscription-entry',
  templateUrl: './subscription-entry.component.html',
})
export class SubscriptionEntryComponent {
  @Input() entry: SubscriptionsEntity = null;
  public unmodifiedEntry = null;
  public editEntry : boolean = false;
  public deleted: boolean = false;
  constructor(public repo: SubscriptionsRepo) {
  }

  update(e, entity) {
    e.preventDefault();
    this.editEntry = false;
    entity.update();
  }

  edit(e, entity) {
    e.preventDefault();
    this.unmodifiedEntry = this.entry.toJson();
    this.editEntry = true;
  }

  cancel(e) {
    e.preventDefault();
    this.entry.refresh(this.unmodifiedEntry);
    this.editEntry = false;
  }

  delete(e, entity) {
    e.preventDefault();
    // todo remove from parent so it vanishes after success
    this.editEntry = false;
    entity.delete().then( (res:any) => {
      if(res) {
        this.deleted=true;
      }
    });
  }

  onSelectionChange(e) {
    if (this.entry.features.notifications[e] !== "unlimited"){
      this.entry.features.notifications[e] = "unlimited";
    } else {
      this.entry.features.notifications[e] = null;
    }
  }

}
