import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { FirebaseAuthService }            from '../../noizu/services/firebase-auth.service';
import {ElixirEntity} from '../elixir.entity';
import {CacheService} from '../../services/cache.service';
import {FirmwareDefinitionEntity} from './definition.entity';
import {FirmwareApprovalEntity} from './approval.entity';
import {FirmwareReleaseRepo} from '../../repos/firmware/release.repo';
import {FirmwareApprovalRepo} from '../../repos/firmware/approval.repo';
import {FirmwareDefinitionRepo} from '../../repos/firmware/definition.repo';
import {EntityReference} from '../../noizu/structs/entity-reference';
import {NoizuStruct} from '../../noizu/structs/noizu-struct';
import {TargetConstraintStruct} from './target/constraints.struct';
import {FirmwareWidgetEnum, WidgetEnum} from '../../enums';

export class TargetStruct extends NoizuStruct {
  //------------------------------------
  // Static
  //------------------------------------
  static constraint_criteria = {
    by_gateway: {gateway: true, series: true, manufacturer: true},
    by_appengine: {appengine: true, series: true, manufacturer: true},
    by_type: {type: true, series: true, manufacturer: true},
    by_batch: {batch: true, series: true, manufacturer: true},
    by_batch_and_type: {batch: true, type: true, series: true, manufacturer: true},
    by_batch_and_type_and_range: {batch: true, type: true, range: true, series: true, manufacturer: true},
    by_batch_and_range: {batch: true, range: true, series: true, manufacturer: true},
    by_type_and_range: {type: true, range: true, series: true, manufacturer: true},
    by_range: {range: true, series: true, manufacturer: true},
  }

  static type_options = [
    {value: 'by_gateway', label: 'By Gateway'},
    {value: 'by_appengine', label: 'By Appengine'},
    {value: 'by_type', label: 'By Type'},
    {value: 'by_batch', label: 'By Batch'},
    {value: 'by_batch_and_type', label: 'By Batch & Type'},
    {value: 'by_batch_and_type_and_range', label: 'By Batch, Type & Range'},
    {value: 'by_batch_and_range', label: 'By Batch & Range'},
    {value: 'by_type_and_range', label: 'By Type & Range'},
    {value: 'by_range', label: 'By Range'},
  ];

  //-------------------------------
  // Members
  //-------------------------------
  public type: string;
  public constraints: TargetConstraintStruct;
  public meta: any = {};

  //-------------------------------
  // constructor
  //-------------------------------
  public constructor(json) {
    super();
    this.type = json['type']; // @todo enum
    this.constraints = new TargetConstraintStruct(json['constraints'] || {});
  }

  //-------------------------------
  // typeHasConstraint
  //-------------------------------
  public typeHasConstraint(constraint: string) {
    if (this.type && this.type in TargetStruct.constraint_criteria) {
      return TargetStruct.constraint_criteria[this.type][constraint];
    } else {
      return false;
    }
  }



  public widget() {
    return `embed-firmware-target`;
  }

  widget_type() {
    return WidgetEnum.EMBED_WIDGET__FIRMWARE;
  }

  firmware_widget_type() {
    return FirmwareWidgetEnum.FIRMWARE_WIDGET__TARGET
  }


  validate() {
    let valid = true;
    let messages = {};
    let error = "";

    if (this.typeHasConstraint('gateway')) {
      if (!this.constraints['gateway']) {
        valid = false;
        messages['gateway'] = "Gateway Required";
      } else {
        if (!(this.constraints.gateway.endsWith('@V2') || this.constraints.gateway.endsWith('@V3'))) {
          valid = false;
          messages['gateway'] = "Gateway Serial number must end in @V2 or @V3";
        } else if (this.constraints.gateway.length != 9) {
          valid = false;
          messages['gateway'] = "Gateway Serial number must be six hex digits.";
        } // @todo @kimi verify valid hex string
      }
    }

    if (this.typeHasConstraint('series')) {
      if (!this.constraints['series']) {
        valid = false;
        messages['series'] = "Series Required";
      }
    }

    if (this.typeHasConstraint('manufacturer')) {
      if (!this.constraints['manufacturer']) {
        valid = false;
        messages['manufacturer'] = "Manufacturer Required";
      }
    }

    if (this.typeHasConstraint('appengine')) {
      if (!this.constraints['appengine']) {
        valid = false;
        messages['appengine'] = "Appengine Required";
      } // @todo @kimi verify is number format.
    }

    if (this.typeHasConstraint('batch')) {
      if (!this.constraints['batch']) {
        valid = false;
        messages['batch'] = "Batch Required";
      }
    }

    if (this.typeHasConstraint('range')) {
      if (!(this.constraints.range_from && this.constraints.range_to)) {
        valid = false;
        messages['range'] = "Range required";
      } // @todo @kimi hexdecimal six digit check and verify range from is smaller than range_to
    }


    if (this.typeHasConstraint('type')) {
      if (!this.constraints['type']) {
        valid = false;
        messages['device_type'] = "Device Type Required";
      }
    }

    if (!valid) {
      messages['common'] = "Target is not correct."
      error = "Target Error(s) Found"
    }

    this.validation = {valid: valid,  validated: true, error: error, messages: messages}
    return this.validation;
  }

  //-------------------------------
  // valid()
  //-------------------------------
  public valid() {
    // @todo use enum
    if (this.type && this.type in TargetStruct.constraint_criteria) {
      let outcome = true;
      const constraints = TargetStruct.constraint_criteria[this.type];
      for(const constraint in constraints) {
        const line_outcome = this.constraints.isConstraintValid(constraint);
        if (!line_outcome) {
          console.log(`invalid ${constraint}`);
        }
        outcome = outcome && line_outcome;
      }
      return outcome;
    } else {
      console.log(`type required ${this.type}`);
      return false;
    }
  }

} // end Package
