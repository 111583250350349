import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WidgetBase} from '../widget-base';
import {SubscriptionDefinitionEntity} from '../../../entities';
import {WidgetEventStruct} from '../../widget-event.struct';

@Component({
  selector: 'subscription-definition-widget',
  template: `
  <ng-container [ngSwitch]="layout">

    <ng-container *ngSwitchDefault>
      <div class="card">
        <div class="card-header">Subscription Definition #{{entity.identifier}} - {{entity.code}}</div>
        <div class="card-body p-0 m-1">
          <div class="row"><div class="col-2"><b>Name</b></div><div class="col">{{entity.name}}</div></div>
          <div class="row"><div class="col-2"><b>Desc</b></div><div class="col">{{entity.description}}</div></div>
          <div class="row"><div class="col-2"><b>Code</b></div><div class="col">{{entity.code}}</div></div>
          <div class="row pt-1 pb-0 mb-0">
            <div class="col">
              <div class="alert alert-info p-1 pb-0 mb-0">
                <div class="row" *ngFor="let key of objectKeys(entity.features)">
                  <div class="col">
                    <div class="row" *ngFor="let key2 of objectKeys(entity.features[key])">
                      <div class="col">
                        <b>{{key}}.{{key2}}</b> = {{entity.features[key][key2]}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  `
})
export class SubscriptionDefinitionWidgetComponent extends WidgetBase implements OnInit {
  public editMode = false;
  @Input() layout: string = null;
  @Input() entity: SubscriptionDefinitionEntity;
  @Input() options: any;
  @Output() widgetEvent = new EventEmitter<WidgetEventStruct>();
  public objectKeys = Object.keys;
  forwardEvent(e, widgetEvent: WidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
  }
}
