import {NoizuStruct} from '../../noizu/structs/noizu-struct';

export enum ForecastReading {
precipitation_chance,
wind_speed_12hr,
wind_dir_12hr,
wind_gust_12hr,
snow_accumulation_12hr,
tornado_prob_12hr,
hail_prob_12hr,
thunder_storm_prob_12hr,
sky_cover_12hr,
air_quality,
ragweed,
mold_risk,
grass,
tree,
uv_index,
sunrise_time,
sunset_time,
moonrise_time,
moonset_time,
hours_of_sunlight,
moon_phase,
disabled,
unknown
}

export class DataStreamWeatherCardEntity extends NoizuStruct {
  public _kind = 'GoldenRatio.DataStream.Card.WeatherReadingCard.V1_1';
  public messages = {one: ':auto', two: ':auto'};
  public link: ForecastReading | undefined = ForecastReading.unknown;
  public modified = false;

  constructor(json) {
    super();
    if (json['messages']) {
      this.messages.one = json['messages']['one'];
      this.messages.two = json['messages']['two'];
    }
    if (json['link']) {
      this.link = (<any>ForecastReading)[json['link']];
      if (this.link === undefined) {
        console.log(`Unsupported Forecast Link: ${json['link']}`);
        this.link = ForecastReading.unknown;
      }
    } else {
      this.link = ForecastReading.unknown;
    }
  } // end constructor

  linkType() {
    return ForecastReading[this.link];
  }

  toJson(options: any = {}) {
    return {
      messages: this.messages,
      link: this.linkType(),
    }
  }

  validate() {
    const errors = [];
    if (this.link === undefined || this.link === null || this.link === ForecastReading.unknown || this.link === ForecastReading.disabled) {
      errors.push({error: 'NoLink', message: 'Weather Link Required'});
    }
    return errors;
  }

  widget() {
    return 'embed-ds-weather-card';
  }
} // End Package
