
<div class="device-entity-widget" *ngIf="!entity">
  <widget-spinner></widget-spinner>
</div>
<ng-container *ngIf="entity">
  <ng-container [ngSwitch]="layout">
    <!-- ================================================= -->
    <!-- Mini                                              -->
    <!-- ================================================= -->
    <div class="device-entity-widget" *ngSwitchCase="'mini'">

      <div class="row">
        <div class="col-4"><b>Device:</b></div>
        <div class="col"><a [routerLink]="'/portal/devices/show/' + entity.identifier">{{entity.serial}}@{{entity.series}}</a></div>
      </div>
      <div class="row">
        <div class="col-4"><b>Type:</b></div>
        <div class="col" *ngIf="entity.type && entity.type.identifier"><a routerLink="/portal/device/definitions/{{entity.type.identifier}}">{{entity.type.identifier}}</a></div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="device-status alert alert-success m-0 p-0">
            <div class="row">
              <div class="col-10">
                <div *ngIf="!entity?.meta?.fetching_status && entity.status">
                  <div>Battery status/level: <span>{{entity.status.battery_status}}/{{entity.status.battery_level}}</span></div>
                  <div>Last Update: {{entity.status.last_update * 1000| date:'MM/dd/yyyy @ h:mm:ssa'}}</div>
                </div>
                <div *ngIf="!entity?.meta?.fetching_status && !entity?.status">
                  <div> Status Unknown</div>
                </div>
              </div>
              <div class="col-2 justify-content-center align-self-center">
                <i class="fa fa-lg fa-repeat float-right" (click)="refresh($event)"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- ================================================= -->
    <!-- Brief                                             -->
    <!-- ================================================= -->
    <div class="device-entity-widget" *ngSwitchCase="'brief'">

      <div class="card m-0 mb-4">
        <div class="card-header p-1">Device <a [routerLink]="'/portal/devices/show/' + entity.identifier">{{entity.identifier}}</a></div>
        <div class="card-body m-1 p-1">

          <div class="row mb-2">
            <div class="col-12">
              <div class="device-status alert alert-success m-0 p-0">
                <div class="row">
                  <div class="col-10">
                    <div *ngIf="!entity?.meta?.fetching_status && entity.status">
                      <div>Battery status/level: <span>{{entity.status.battery_status}}/{{entity.status.battery_level}}</span></div>
                      <div>Last Update: {{entity.status.last_update * 1000| date:'MM/dd/yyyy @ h:mm:ssa'}}</div>
                    </div>
                    <div *ngIf="!entity?.meta?.fetching_status && !entity?.status">
                      <div> Status Unknown</div>
                    </div>
                  </div>
                  <div class="col-2 justify-content-center align-self-center">
                    <i class="fa fa-lg fa-repeat float-right" (click)="refresh($event)"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="row m-0 mb-1">
            <div class="col-7 p-0 pr-1">


              <div class="card m-0 p-0">
                <div class="card-header">Details</div>
                <div class="card-body m-0 p-1">
                  <!-- Serial -->
                  <div class="row mb-1" >
                    <div class="col-4"><b>Serial:</b></div>
                    <div class="col">
                      <div class="float-left">{{entity.serial}}-{{entity.verificationCode}}</div>
                    </div>
                  </div>

                  <!-- Device Category -->
                  <div class="row mb-1">
                    <div class="col-4"><b>Category:</b></div>
                    <div class="col">{{entity.category}}</div>
                  </div>


                  <!-- Appengine Identifier -->
                  <div class="row mb-1" >
                    <div class="col-4"><b>Identifier:</b></div>
                    <div class="col">{{entity.identifier}}</div>
                  </div>

                  <!-- Batch -->
                  <div class="row mb-1" >
                    <div class="col-4"><b>Batch:</b></div>
                    <div class="col">{{entity.batch}}</div>
                  </div>

                  <!-- Geo -->
                  <div class="row mb-1" *ngIf="entity.geo">

                    <div class="col-4">
                      <b>
                        <ng-container *ngIf="entity?.isfact23">Legacy Geo:</ng-container>
                        <ng-container *ngIf="!(entity?.isfact23)">Accu. Geo:</ng-container>
                      </b>
                    </div>

                    <div class="col">
                      <ng-container *ngIf="!entity.geo?.anonymous">
                        <!-- <show-object [O]="device.geo" [F]="['anonymous']"></show-object> -->
                        <render-object [object]="entity?.geo" [exclude]="['anonymous']"></render-object>
                      </ng-container>
                      <ng-container *ngIf="entity.geo?.anonymous">
                        <div>Anonymous</div>
                      </ng-container>
                    </div>
                  </div>

                  <div class="row mb-1" *ngIf="entity.internals">
                    <div class="col-4"><b>Created On</b></div>
                    <div class="col">{{entity.createdOn | date:'MM/dd/yyyy @ h:mm:ssa'}}</div>
                  </div>

                  <div class="row mb-1" *ngIf="entity.internals">
                    <div class="col-4"><b>Modified On</b></div>
                    <div class="col">{{entity.modifiedOn | date:'MM/dd/yyyy @ h:mm:ssa'}}</div>
                  </div>

                </div>
              </div>


            </div>
            <div class="col-5 p-0 pl-1" *ngIf="entity.type">
              <widget [entity]="entity.type" [layout]="layout" [options]="{expand: false}"> </widget>
            </div>
          </div>


          <div class="row m-0 mb-1">
            <div class="col-12 p-0">
              <div class="card mb-1">
                <div class="card-header p-1">Users

                  <div class="card-header-actions">
                  <span><i (click)="toggleUsers($event)" class="fa fa-sm pr-2"
                           [ngClass]="{'fa-chevron-down': !expandUsers, 'fa-chevron-up': expandUsers}"> </i></span>
                  </div>

                </div>
                <div class="card-body" *ngIf="expandUsers">

                  <div *ngIf="entity?.users" class="row">
                    <div *ngFor="let u of entity.users.items" class="col-12">
                      <div class="card">
                        <div class="card-header">User: {{u.display_name}}</div>
                        <div class="card-body row">
                          <label class="col-3 form-control-label">User Id:</label>
                          <div class="col-9"><a [routerLink]="['/portal/users/show/' + u.identifier]">{{u.identifier}}</a></div>
                          <label class="col-3 form-control-label">Name:</label>
                          <div class="col-9">{{u.first_name}} {{u.last_name}}</div>
                          <label class="col-3 form-control-label">Created on:</label>
                          <div class="col-9">{{u.createdOn}}</div>
                          <label class="col-3 form-control-label">Modified on:</label>
                          <div class="col-9">{{u.modifiedOn}}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="row m-0">
            <div class="col-12 p-0">
                <div class="card mb-1">
              <div class="card-header p-1">
                Logs

                <div class="card-header-actions">
                  <span><i (click)="expandLogs = !expandLogs" class="fa fa-sm pr-2"
                           [ngClass]="{'fa-chevron-down': !expandLogs, 'fa-chevron-up': expandLogs}"> </i></span>
                </div>

              </div>
              <div class="card-body p-1" *ngIf="expandLogs">
                <tabset #logTabs>


                  <!-- ================ -->
                  <!-- Raw Request Logs -->
                  <!-- ================ -->
                  <tab heading="Request" class="row">
                    <div class="col-12" *ngIf="entity?.attributes?.display === '1'">
                      <widget-embed
                        *ngIf="entity?.requestLogs"
                        [entity]="entity.requestLogs"
                        [options]="{subject: 'ref.gateway.' + entity.serial + '@' + entity.series, type: 'gateway'}"
                        [layout]="'standard'"
                      ></widget-embed>
                    </div>
                    <div class="col-12" *ngIf="entity?.attributes?.display !== '1'">
                      <widget-embed
                        *ngIf="entity?.requestLogs"
                        [entity]="entity.requestLogs"
                        [options]="{subject: 'ref.device.' + entity.serial + '@' + entity.series, type: 'device'}"
                        [layout]="'standard'"
                      ></widget-embed>
                    </div>
                  </tab>

                  <!-- ================== -->
                  <!-- Device Entity Logs -->
                  <!-- ================== -->
                  <tab heading="Device" class="row">
                    <div class="col-12">
                      <widget-embed
                        *ngIf="entity?.deviceLogs"
                        [entity]="entity.deviceLogs"
                        [options]="{subject: 'ref.device.' + entity.serial + '@' + entity.series}"
                        [layout]="'standard'"
                      ></widget-embed>
                    </div>
                  </tab>

                  <!-- =================== -->
                  <!-- Gateway Entity Logs -->
                  <!-- =================== -->
                  <tab heading="Gateway" class="row" *ngIf="entity?.attributes?.display === '1'">
                    <div class="col-12">
                      <widget-embed
                        *ngIf="entity?.gatewayLogs"
                        [entity]="entity.gatewayLogs"
                        [options]="{subject: 'ref.gateway.' + entity.serial + '@' + entity.series}"
                        [layout]="'standard'"
                      ></widget-embed>
                    </div>
                  </tab>

                </tabset>
              </div>
            </div>
            </div>
          </div>



        </div>
      </div>

    </div>

    <!-- ================================================= -->
    <!-- Table                                             -->
    <!-- ================================================= -->

    <ng-container *ngSwitchCase="'nested-header'">
      <div class="device-entity-widget row nested-th" *ngIf="options['nested'] && options['nested']['devices'] && options['nested']['devices']['header']">
        <ng-container *ngFor="let column of options.nested.devices.headers">
          <ng-container [ngSwitch]="column">
            <div class="col  div-table-th" *ngSwitchCase="'identifier'">D.Id</div>
            <div class="col  div-table-th" *ngSwitchCase="'serial-with-verification'">D.Serial</div>
            <div class="col  div-table-th" *ngSwitchCase="'serial'">D.Serial</div>
            <div class="col  div-table-th" *ngSwitchCase="'series'">D.Series</div>
            <div class="col  div-table-th" *ngSwitchCase="'type'">D.Type</div>
            <div class="col  div-table-th" *ngSwitchCase="'last_update'">D.Last Update</div>
            <div class="col  div-table-th" *ngSwitchCase="'actions'">D.Actions</div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'table'">

      <div class="device-entity-widget row" *ngIf="options['index'] == 0 && options['header']">
        <ng-container *ngFor="let column of options.columns">
          <ng-container [ngSwitch]="column">
            <div class="col  div-table-th" *ngSwitchCase="'identifier'">Id</div>
            <div class="col  div-table-th" *ngSwitchCase="'serial-with-verification'">Serial</div>
            <div class="col  div-table-th" *ngSwitchCase="'serial'">Serial</div>
            <div class="col  div-table-th" *ngSwitchCase="'series'">Series</div>
            <div class="col  div-table-th" *ngSwitchCase="'type'">Type</div>
            <div class="col  div-table-th" *ngSwitchCase="'last_update'">Last Update</div>
            <div class="col  div-table-th" *ngSwitchCase="'actions'">Actions</div>
          </ng-container>
        </ng-container >
      </div>

        <div class="row">
          <ng-container *ngFor="let column of options.columns">
            <ng-container [ngSwitch]="column">
              <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'identifier'">
                <a [routerLink]="['/portal/devices/show/'  + entity.identifier]">{{entity?.identifier}}</a>
              </div>
              <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'serial-with-verification'">{{entity.serial}}-{{entity.verificationCode}}</div>
              <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'serial'">{{entity.serial}}</div>
              <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'series'">{{entity.series}}</div>
              <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'type'">{{ deviceType() }}</div>
              <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'last_update'">

                <div *ngIf="entity.status">
                  <div *ngIf="entity.status.last_update!=null">
                    {{entity.status.last_update * 1000 | date:'MMM d, y, h:mm:ss a'}}&nbsp;<i class="fa fa-lg fa-repeat text-primary" (click)="refresh($event)"></i>
                  </div>
                  <div *ngIf="entity.status.last_update==null">
                    Unknown/Never&nbsp;<i class="fa fa-lg fa-repeat text-info" (click)="refresh($event)"></i>
                  </div>
                </div>
                <div *ngIf="!entity.status">
                  <!-- to do distinguish between loading and not populated -->
                  <i class="fa fa-lg fa-repeat text-warning" (click)="refresh($event)"></i>
                </div>

              </div>
              <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'actions'">

                <span (click)="factoryReset($event)" *ngIf="options['allowed-actions'] && options['allowed-actions']['factory-reset']" class="badge badge-danger">Factory Reset</span>
                <span (click)="permissionReset($event)" *ngIf="options['allowed-actions'] && options['allowed-actions']['permission-reset']" class="badge badge-warning">Set Claimable</span>

              </div>
            </ng-container>
          </ng-container>

        </div>





    </ng-container>


    <ng-container *ngSwitchCase="'nested-table'">
      <div class="device-entity-widget row">
        <ng-container *ngFor="let column of options.nested.devices.headers">
          <ng-container [ngSwitch]="column">
            <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'identifier'">
              <a [routerLink]="['/portal/devices/show/'  + entity.identifier]">{{entity?.identifier}}</a>
            </div>
            <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'serial-with-verification'">{{entity.serial}}-{{entity.verificationCode}}</div>
            <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'serial'">{{entity.serial}}</div>
            <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'series'">{{entity.series}}</div>
            <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'type'">{{ deviceType() }}</div>
            <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'last_update'">

              <div *ngIf="entity.status">
                <div *ngIf="entity.status.last_update!=null">
                  {{entity.status.last_update * 1000 | date:'MMM d, y, h:mm:ss a'}}&nbsp;<i class="fa fa-lg fa-repeat text-primary" (click)="refresh($event)"></i>
                </div>
                <div *ngIf="entity.status.last_update==null">
                  Unknown/Never&nbsp;<i class="fa fa-lg fa-repeat text-info" (click)="refresh($event)"></i>
                </div>
              </div>
              <div *ngIf="!entity.status">
                <!-- to do distinguish between loading and not populated -->
                <i class="fa fa-lg fa-repeat text-warning" (click)="refresh($event)"></i>
              </div>

            </div>
            <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'actions'">

              <span (click)="factoryReset($event)" *ngIf="options['nested'] && options['nested']['allowed-actions'] && options['nested']['allowed-actions']['factory-reset']" class="badge badge-danger">Factory Reset</span>
              <span (click)="permissionReset($event)" *ngIf="options['nested'] && options['nested']['allowed-actions'] && options['nested']['allowed-actions']['permission-reset']" class="badge badge-warning">Set Claimable</span>

            </div>
          </ng-container>
        </ng-container>

      </div>


    </ng-container>


    <div class="device-entity-widget" *ngSwitchDefault>
      [Device Default]
    </div>
  </ng-container>
</ng-container>
