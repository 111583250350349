import {Component, Input, Inject, Output, EventEmitter} from '@angular/core';
import {NoizuStruct} from '../../../noizu/structs/noizu-struct';
import {ModalWidgetEnum, WidgetEnum} from '../../../enums/widget';
import {ModalWidget} from "../../../entities/";
import {FirmwareWidgetEventStruct} from '../firmware/firmware-widget-event.struct';
import {ModalWidgetEventStruct} from './modal-widget-event.struct';

@Component({
  selector: 'modal-widget',
  template: `
<div *ngIf="!entity">
  <widget-spinner></widget-spinner>
</div>
<div *ngIf="entity">
  <div [ngSwitch]="entity.modal_widget_type()">
    <bare-modal-widget *ngSwitchCase="WidgetType.MODAL_WIDGET__BARE" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></bare-modal-widget>
    <generic-modal-widget *ngSwitchCase="WidgetType.MODAL_WIDGET__GENERIC" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></generic-modal-widget>
    <confirm-modal-widget *ngSwitchCase="WidgetType.MODAL_WIDGET__CONFIRM" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></confirm-modal-widget>
    <create-modal-widget *ngSwitchCase="WidgetType.MODAL_WIDGET__CREATE" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></create-modal-widget>
    <edit-modal-widget *ngSwitchCase="WidgetType.MODAL_WIDGET__UPDATE" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></edit-modal-widget>
    <mini-modal-widget *ngSwitchCase="WidgetType.MODAL_WIDGET__MINI" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></mini-modal-widget>
    <!-- Default -->
    <generic-modal-widget *ngSwitchDefault [entity]="entity" [layout]="layout" [options]="options"></generic-modal-widget>
  </div>
</div>
`
})
export class ModalWidgetComponent {
  @Input() entity: ModalWidget | null = null;
  @Input() templateHint: string = null;
  @Input() options: any = null;
  @Input() layout = 'standard';

  @Output() widgetEvent = new EventEmitter<ModalWidgetEventStruct>();
  forwardEvent(widgetEvent: ModalWidgetEventStruct) {
    this.widgetEvent.emit(widgetEvent);
  }


  public  WidgetType = ModalWidgetEnum;

  constructor() {

  }
}

