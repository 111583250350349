
    <div class="alert-trigger-event-widget card" [ngClass]="{'mb-0': options?.nested}">
      <div class="card-header" [ngClass]="{'p-2 m-0': options?.nested}">
        <div class="row">
          <div class="col"><b class="text-left">Event: #{{entity.identifier}}</b></div>
          <div class="col-2 text-right p-0 text-middle">
            <toggle-widget [options]="expand_settings"></toggle-widget>
          </div>
        </div>
      </div>
      <div class="card-body"  [ngClass]="{'d-none': !expand_settings.toggle}" >
        <pre>{{entity.toJson()| json}}</pre>
      </div>
    </div>
    