
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { DomainObjectRepo } from '../../noizu/domain-object-repo';
import { FirebaseAuthService }        from '../../noizu/services/firebase-auth.service';
import { EmailQueueEntity }      from '../../entities/data/email-queue.entity';
import { AppengineRepo }      from '../appengine.repo';
import {ElixirRepo} from '../elixir.repo';

@Injectable()
export class EmailQueueRepo extends ElixirRepo {
  public _kind = "null";
  public _singular = "null";
  public _appengine = false;
  public page = 0;
  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  entity(record) {
    return new EmailQueueEntity(this.client, this.auth, record)
  }

  public getListPromise(options = {}) {
    options = options || {}
    options['set'] = true;
    let query = "";
    if (options['pg'] && options['rpp']) query = `?pg=${options['pg']}&rpp=${options['rpp']}`
    else if (options['pg']) query = `?pg=${options['pg']}`
    else if (options['rpp']) query = `?rpp=${options['rpp']}`
    console.log("Options", options);
    console.log("URL", `${this.apiBase()}/email/queue${query}`);
    return super._getListPromise(`${this.apiBase()}/email/queue${query}`, (record) => {
      return this.entity(record);
    }, options);
  }

}
