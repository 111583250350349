import {HttpClient} from '@angular/common/http';
import { FirebaseAuthService }            from '../../noizu/services/firebase-auth.service';
import {ReportWidgetEnum, WidgetEnum} from '../../enums';
import {ReportEntity} from './report.entity';



export class HealthReportEntityServerMemoryWorst {
  public process: string;
  public consumption: string;

  constructor(json) {
    this.process = json['process'];
    this.consumption = json['consumption'];
  }

}

export class HealthReportEntityServerMemorySystem {
  public total_swap: number;
  public total_memory: number;
  public system_total_memory: number;
  public free_swap: number;
  public free_memory: number;
  public cached_memory: number;
  public buffered_memory: number;


  constructor(json) {
    this.total_swap = json['total_swap'];
    this.total_memory = json['total_memory'];
    this.system_total_memory = json['system_total_memory'];
    this.free_swap = json['free_swap'];
    this.free_memory = json['free_memory'];
    this.cached_memory = json['cached_memory'];
    this.buffered_memory = json['buffered_memory'];
  }
}

export class HealthReportEntityServerMemory {
  public worst: HealthReportEntityServerMemoryWorst;
  public total;
  public system: HealthReportEntityServerMemorySystem;
  public allocated;

  constructor(json) {
    this.worst = new HealthReportEntityServerMemoryWorst(json['worst'] || {});
    this.total = json['total'];
    this.system = new HealthReportEntityServerMemorySystem(json['system'] || {});
    this.allocated = json['allocated'];
  }

}


export class HealthReportEntityServerCPU {
  public nprocs: number;
  public load_1_min: number;
  public load_5_min: number;
  public load_15_min: number;

  constructor(json) {
    this.nprocs = json['nprocs'];
    this.load_1_min = json['load_1_min'];
    this.load_5_min = json['load_5_min'];
    this.load_15_min = json['load_15_min'];
  }

}

export class HealthReportEntityServer {
  public memory: HealthReportEntityServerMemory;
  public cpu: HealthReportEntityServerCPU;

  constructor(json) {
    this.memory = new HealthReportEntityServerMemory(json['memory'] || {});
    this.cpu = new HealthReportEntityServerCPU(json['cpu'] || {});
  }
}


export class HealthReportEntityProcessTimeouts {
  public last_1_min: number;
  public last_5_min: number;
  public last_15_min: number;
  public last_60_min: number;


  constructor(json) {
    this.last_1_min = json['last_1_min'];
    this.last_5_min = json['last_5_min'];
    this.last_15_min = json['last_15_min'];
    this.last_60_min = json['last_60_min'];
  }
}


export class HealthReportEntityMnesia {
  public transactions = [];
  public transaction_restarts: number;
  public transaction_log_writes: number;
  public transaction_failures: number;
  public num_transactions: number;
  public num_held_locks: number;
  public local_tables = [];
  public is_running: string;
  public held_locks = [];
  public extra_db_nodes = [];
  public db_nodes = [];
  public auto_report: string;

  constructor(json) {
    this.transactions = json['transactions'];
    this.transaction_restarts = json['transaction_restarts'];
    this.transaction_log_writes = json['transaction_log_writes'];
    this.transaction_failures = json['transaction_failures'];
    this.num_transactions = json['num_transactions'];
    this.num_held_locks = json['num_held_locks'];
    this.local_tables = json['local_tables'];
    this.is_running = json['is_running'];
    this.held_locks = json['held_locks'];
    this.extra_db_nodes = json['extra_db_nodes'];
    this.db_nodes = json['db_nodes'];
    this.auto_report = json['auto_report'];
  }

}


export class HealthReportEntityEndpoint {
  public status: any;
  public state: string;
  public node: string;
  public load: any;
  public environment: string;
  public details: any;
  public capacity: any;

  constructor(json) {
    this.status = json['status'];
    this.state = json['state'];
    this.node = json['node'];
    this.load = json['load'];
    this.environment = json['environment'];
    this.details = json['details'];
    this.capacity = json['capacity'];
  }

}


export class HealthReportEntity extends ReportEntity {
  public _kind = '';
  public status: string;
  public server: HealthReportEntityServer;
  public process_timeouts: HealthReportEntityProcessTimeouts;
  public mnesia: HealthReportEntityMnesia;
  public endpoint: HealthReportEntityEndpoint;

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    this.refresh(json);
  } // end constructor


  public refresh(json) {
    super.refresh(json);
    this.status = json['status'];
    this.server = new HealthReportEntityServer(json['server'] || {});
    this.process_timeouts = new HealthReportEntityProcessTimeouts(json['process_timeouts'] || {});
    this.mnesia = new HealthReportEntityMnesia(json['mnesia'] || {});
    this.endpoint = new HealthReportEntityEndpoint(json['endpoint'] || {});
    return this;
  }

  widget_report_type(): any {
    return ReportWidgetEnum.REPORT_WIDGET__HEALTH_REPORT;
  }


  reload() {

    return this._get(`${this.apiBase()}/admin-tools/${this.identifier}/health-check`, (record, resolve) => {
      record['identifier'] = this.identifier;
      this.refresh(record);
      resolve(this);
    }, {});

  }

  lock() {
    alert('Pending - Lock Endpoint To Prevent New Process Spawns');
  }

  release() {
    alert('Pending - Unlock Endpoint To Allow New Process Spawns');
  }

  balance() {
    alert('Pending - redistribute processes so node is within target process count');
  }

  offload() {
    alert('Pending - Lock node, distribute processes to other available nodes');
  }

} // end Package
