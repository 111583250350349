
    <div class="row justify-content-center" *ngIf="entity">
      <div class="col-auto">
        <div class="row">
          <div class="col text-center">
            <strong>From</strong>
          </div>
        </div>
        <div class="row">
          <div class="col text-left">
            <ngb-datepicker #fdp [(ngModel)]="entity.select_from_date"></ngb-datepicker>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-auto text-left">
            <span><ngb-timepicker #ftp [meridian]="true" [(ngModel)]="entity.select_from_time"></ngb-timepicker></span>
          </div>
        </div>
      </div>
      <div class="col-auto">
        <div class="row">
          <div class="col text-center">
            <strong>To</strong>
          </div>
        </div>
        <div class="row">
          <div class="col text-left">
            <ngb-datepicker #tdp [(ngModel)]="entity.select_to_date"></ngb-datepicker>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-auto text-left">
            <span><ngb-timepicker #ttp [meridian]="true" [(ngModel)]="entity.select_to_time"></ngb-timepicker></span>
          </div>
        </div>
      </div>
    </div>
  