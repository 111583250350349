
import { Component, Inject, Input, NgZone } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location }               from '@angular/common';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { FirmwareDefinitionEntity } from '../../../entities/firmware/definition.entity';
import { FirmwareDefinitionRepo } from '../../../repos/firmware/definition.repo';
import {FirmwareApprovalRepo} from '../../../repos/firmware/approval.repo';

@Component({
  templateUrl: './list.component.html',
})
export class FirmwareApprovalListComponent {
  public entries: any; //Map<String, Array<FirmwareDefinitionEntity>>;

  constructor(public repo: FirmwareApprovalRepo, public zone: NgZone) {
    this.zone.run(
      () => {
        this.repo.getListPromise().then((u: any) => {
          this.entries = u;
        });
      }
    )
  }
}
