

    <div class="modal-header">
      <h4 class="modal-title pull-left">{{entity?.title}}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="entity?.modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <widget [entity]="entity?.subject" [layout]="entity?.embed_layout" [options]="entity?.embed_options"></widget>
    </div>
  