
<div *ngIf="!user || notification.showLoading" class="overlayParent spinner-holder">
  <div  class="overlay">
    <widget-spinner></widget-spinner>
  </div>
</div>
<div *ngIf="notification.message.length > 0" class="row">
  <div class="alert col-12" [ngClass]="{'alert-info': !notification.iserror, 'alert-danger': notification.iserror}" (click)="setNotification('',false,true)">
    {{notification.message}}. Click to close
  </div>
</div>
<div *ngIf="user">
  <div class="row my-3">
    <div class="col-1"></div>
    <div class="col-11 halfColNMargin"><button (click)="back($event)" class="btn btn-primary">Back</button></div>
  </div>

  <div class="row">
    <div class="col-1"></div>
    <div class="col-11 halfColNMargin">
      <div class="card">
        <div class="card-header">Tool Bar</div>
        <div class="card-body card-block">
          <div class="row">
            <div *ngIf="!state.edit" class="col-6">
              <button (click)="edit($event, 1)" class="btn btn-info">Edit Account Details</button>
              <button (click)="edit($event, 2)" class="btn btn-info mx-3">Edit Email</button>
            </div>
            <div *ngIf="state.accountEdit" class="col-6">
              <button *ngIf="state.edit" (click)="save(confirm, $event)" class="btn btn-danger">Save</button>
              <button *ngIf="state.edit" (click)="cancel($event)" class="btn btn-primary">Cancel</button>
            </div>
            <div *ngIf="state.emailEdit" class="col-6">
              <button *ngIf="state.edit" (click)="saveEmail(confirm, $event)" class="btn btn-danger">Save</button>
              <button *ngIf="state.edit" (click)="cancel($event)" class="btn btn-primary">Cancel</button>
            </div>
            <div class="pull-right col-6">
              <button (click)="showNotes=!showNotes" class="btn btn-info mx-3 pull-right">Toggle Notes</button>
              <button (click)="deleteThisUser(confirm, $event)" *ngIf="user.deleteable" class="btn btn-danger pull-right">Delete User</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="error.error">
    <div class="col-1"></div>
    <div class="col-11 alert alert-danger halfColNMargin" (click)="setError()"> {{error.msg}} (click to hide)</div>
  </div>

  <div class="row" *ngIf="showNotes">
    <div class="col-1"></div>
    <div class="col-11 halfColNMargin">
      <div class="col-12">
        <entity-notes-v2 [entity]="'ref.user.' + user.identifier"></entity-notes-v2>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-1"></div>
    <div class="col-11 halfColNMargin">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-1">
              <i class="fa fa-thumb-tack" [ngClass]="{'fa-rotate-135 text-black-50': !pinned, 'fa-rotate--45': pinned}" (click)="pinDevice($event, pageModal)"></i>
            </div>
            <div class="col">
              <strong>{{user.identifier}}</strong> | {{user.email}}
            </div>
          </div>
        </div>
        <div class="card-body card-block">
          <div class="alert alert-success">
            <div *ngIf="!state.edit">
              <div class="row" *ngIf="user.handle"><div class="col-3">Handle</div><div class="col-9">{{user.handle}}</div></div>
              <div class="row"><div class="col-3">Account Name</div><div class="col-9">{{user.display_name}}</div></div>
              <div class="row"><div class="col-3">Notification Email</div><div class="col-9">{{user.email}}</div></div>
              <div class="row"><div class="col-3">First Name</div><div class="col-9">{{user.first_name}}</div></div>
              <div class="row"><div class="col-3">Last Name</div><div class="col-9">{{user.last_name}}</div></div>
              <div class="row" *ngIf="user.isStaff"><div class="col-3">Is Staff</div><div class="col-9">{{user.isStaff}}</div></div>
            </div>

            <div *ngIf="state.emailEdit">
              <form action="" class="form-horizontal" >
                <div class="form-group row">
                  <label class="col-3 form-control-label" for="edit-display-name">Account Name</label>
                  <div class="col-9"><input class="form-control" id="edit-display-name" name="user[display_name]" placeholder="Display Name" type="text" [(ngModel)]="userEdit.display_name" autocomplete="off"></div>
                </div>
                <div class="form-group row">
                  <label class="col-3 form-control-label" for="edit-email">Notification Email</label>
                  <div class="col-9"><input class="form-control" id="edit-email" name="user[email]" placeholder="Email" type="email" [(ngModel)]="userEdit.email" autocomplete="off"></div>
                </div>
              </form>
            </div>

            <div *ngIf="state.accountEdit">
              <form action="" class="form-horizontal" >
                <div class="form-group row">
                  <label class="col-3 form-control-label" for="edit-handle">Handle</label>
                  <div class="col-9"><input class="form-control" id="edit-handle" name="user[handle]" placeholder="Unique Internal Handle" type="text" [(ngModel)]="userEdit.handle" autocomplete="off"></div>
                </div>

                <div class="form-group row">
                  <label class="col-3 form-control-label" for="edit-email">Notification Email</label>
                  <div class="col-9"><input class="form-control" id="edit-email" name="user[email]" placeholder="Email" type="email" [(ngModel)]="userEdit.email" autocomplete="off"></div>
                </div>

                <div class="form-group row">
                  <label class="col-3 form-control-label" for="edit-first-name">First Name</label>
                  <div class="col-9"><input class="form-control" id="edit-first-name" name="user[first_name]" placeholder="First Name" type="text" [(ngModel)]="userEdit.first_name" autocomplete="off"></div>
                </div>

                <div class="form-group row">
                  <label class="col-3 form-control-label" for="edit-last-name">Last Name</label>
                  <div class="col-9"><input class="form-control" id="edit-last-name" name="user[last_name]" placeholder="Last Name" type="text" [(ngModel)]="userEdit.last_name" autocomplete="off"></div>
                </div>

                <div class="form-group row"><div class="col-3">Group</div><div class="col-9">{{user.group_id}}</div></div>
                <div class="form-group row" *ngIf="user.auth.permissionLevel <=1">
                  <label class="col-3 form-control-label" for="edit-isStaff">Is Staff</label>
                  <div class="col-9">
                    <input type="checkbox"  [checked]="userEdit.isStaff" (change)="userEdit.isStaff = !userEdit.isStaff"/>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="alert alert-info">
            <div class="row"><div class="col-3">Created On</div><div class="col-9">{{user.createdOn}}</div></div>
            <div class="row"><div class="col-3">Modified On</div><div class="col-9">{{user.modifiedOn}}</div></div>
          </div>

          <div class="alert alert-warning">
            <div class="row"><div class="col-3">Flagged For Synch</div><div class="col-9">{{user.flagged_for_synch}}</div></div>
            <div class="row"><div class="col-3">Last Synched</div><div class="col-9">{{user.last_synched}}</div></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-1"></div>
    <div class="col-11 halfColNMargin">
      <div *ngIf="notification.message.length > 0" class="row">
        <div class="alert col-12" [ngClass]="{'alert-info': !notification.iserror, 'alert-danger': notification.iserror}">
          {{notification.message}}.
        </div>
      </div>


      <tabset #staticTabs>
        <tab heading="Devices">


          <new-spinner *ngIf="!user._nested.deviceAssociations"  [size]="'lg'"></new-spinner>

          <div *ngIf="user._nested.deviceAssociations && user._nested.deviceAssociations.error==null" class="row">
            <div *ngFor="let device of user._nested.deviceAssociations['items']" class="col-6 overlayParent">
              <div class="overlay" *ngIf="device.saving">
                <widget-spinner></widget-spinner>
              </div>
              <div *ngIf="!(device.identifier == 'new')">


                <div *ngIf="!device.editMode" class="card">
                  <div class="card-header">
                    <label> {{device.raw.name}}</label>
                    <button *ngIf="!device.editMode" class="btn-sm btn-primary float-right" (click)="toggleDeviceDetails(device)">
                       <i class="fa fa-sm"  [ngClass]="{'fa-chevron-down': !device.expanded, 'fa-chevron-up': device.expanded}"></i>
                    </button>
                    <button *ngIf="!device.editMode"class="btn-sm btn-primary float-right" (click)="device.showReloc =!device.showReloc">Reposition</button>
                  </div>
                  <div class="card-block">
                    <div *ngIf="device.showReloc" class="row my-3">
                      <div class="col-12">
                        <!--
                        <span>Move Infront of</span>
                        <select name="move" [(ngModel)]="device.newPos" style="color:black;min-width:150px">
                          <ng-container *ngFor="let itm of user.weightTree[device.locationName]['devs']; let i = index">
                            <option *ngIf="itm.name!=device.raw.name && device.pos != i" [value]="itm">{{user.weightTree[device.locationName].pos}}.{{i+1}}: {{itm.name}}</option>
                          </ng-container>
                        </select>
                        <button class="btn btn-sm btn-warning"(click)="reposDevice($event, device)">Move</button>
                        -->
                        <button class="btn btn-sm btn-warning" (click)="reposDevice($event, device, 0)" *ngIf="device.pos!= 1">Move Forward</button>
                        <button class="btn btn-sm btn-warning pull-right"(click)="reposDevice($event, device, 1)" *ngIf="device.last == false">Move Backward</button>
                        <div class="alert alert-info" *ngIf="device.pos == 1 && device.last == true">Only Device in Location</div>
                      </div>
                    </div>

                    <div>
                      <label>Device Id: </label> {{device.sensor_serial}}
                      <a class="float-right" (click)="viewDevice(device, $event)" href="/portal/devices/show/{{device.sensor_identifier}}">Go To Device Page</a>
                    </div>
                    <div><label>Model Number: </label> {{device.model_number}} </div>
                    <div><label>Device: </label> {{device.type_name}} </div>
                    <div *ngIf="device.expanded">
                      <div> <label>Location: </label> {{device.locationName}}</div>
                      <div *ngIf="device.isStation">
                        <div class="overlayParent row" *ngIf="device.geo">

                          <new-spinner *ngIf="!(device.geo.pc || device.geo.anonymous)"  [size]="'lg'"></new-spinner>

                          <div *ngIf="device.isfact23" class="col-12">
                            <div *ngIf="!device.geo.anonymous">
                              <div> <label>Device Timezone: </label> {{device.geo.timezone}} </div>
                              <div> <label>Postal Code: </label> {{device.geo.pc}} </div>
                            </div>
                            <div *ngIf="device.geo.anonymous">
                              <div> <label>Device Timezone: </label> Anonymous </div>
                            </div>
                          </div>
                          <div *ngIf="!device.isfact23" class="col-12">
                            <div *ngIf="device.geo.pc">
                              <div> <label>Postal Code: </label> {{device.geo.pc}} </div>
                            </div>
                            <div *ngIf="device.geo.pc == ''">
                              <div> <label>Device Timezone: </label> Anonymous </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div> <label>Device Created: </label> {{device.createdOn|date:'MM/dd/yyyy @ h:mm:ssa'}} </div>
                      <div> <label>Device Modified: </label> {{device.modifiedOn|date:'MM/dd/yyyy @ h:mm:ssa'}} </div>
                    </div>
                    <div><label>Association ID: </label> {{device.identifier}}</div>
                    <div *ngIf="device.status">
                      <div *ngIf="device.status.lastUpdate!=null">
                        Last Seen: {{device.status.lastUpdate * 1000 | date:'medium'}}
                      </div>
                      <div *ngIf="device.status.lastUpdate==null">
                        Last Seen: Unknown/Never
                      </div>
                    </div>
                    <div>
                      <button type="button" class="btn btn-info btn-small" (click)="getDeviceStatus(device, $event)">Get Status</button>
                      <button type="button" class="btn btn-success btn-small" (click)="editDevice(device, $event)">Edit</button>
                      <button type="button" class="btn btn-danger btn-small float-right" (click)="deleteAssoc(confirm,device, $event)">Delete</button>
                    </div>
                  </div>
                </div>

                <div *ngIf="device.editMode" class="card">
                  <div class="card-header">
                    <label>{{device.raw.name}}</label>
                  </div>
                  <div class="card-block">
                    <div class="form-group row">
                      <label class="col-3 form-control-label" for="edit-id">Name</label>
                      <div class="col-9"><input class="form-control" id="edit-Name" name="name" placeholder="Device Name" type="text" [(ngModel)]="device.device_name" autocomplete="off"></div>
                    </div>

                    <div class="form-group row">
                      <label class="col-3 form-control-label" for="edit-handle">Location</label>
                      <div class="col-9">
                        <select class="form-control" name="location" [(ngModel)]="device.location_id">
                          <option *ngFor="let loc of user._nested.locations.items" value="{{loc.identifier}}">{{loc.name}}</option>
                        </select>
                      </div>
                    </div>
                    <div>
                      <button type="button" class="btn btn-info" (click)="updateDeviceAssociation(device, $event)">Update Details</button>
                    </div>
                    <div *ngIf="device.isStation && !device.isfact23" class="mt-3 row">

                      <div *ngIf="geoData.search" class="col-12">
                        <div>Geo:</div>
                        <div class="form-group" *ngIf="!device.geo.pc">
                          <div>Device is Anonymous</div>
                        </div>
                        <div>
                          <div class="form-group row">
                            <label class="col-3 form-control-label" for="edit-name">Postal Code</label>
                            <div class="col-9"><input class="form-control" id="edit-name" name="deviceZip" placeholder="Code" type="text" [(ngModel)]="device.geo.pc" autocomplete="off"></div>
                          </div>
                          <div class="form-group row"> Change Country Code:<br>
                            <ngx-select-dropdown style="width:100%;padding:0 15px" (change)="selectionChanged($event,device)" [(ngModel)]="ddr" [config]="ddc" [options]="clist"></ngx-select-dropdown>
                          </div>
                        </div>
                        <div>
                          <button type="button" class="btn btn-info" (click)="getGeoCode(device, $event)">Search For location</button>
                        </div>
                        <div class="col-12"><button type="button" class="btn btn-warning float-right" (click)="confirmSetAnon(confirm,device, $event)">Set anonymous</button></div>
                      </div>
                      <div *ngIf="!geoData.search" class="col-12">
                        <div class="row">
                          <div class="col-12">Choose the location</div>
                          <div *ngFor="let l of geoData.locs" class="col-12 my-2">
                            <button type="button" class="btn btn-sm btn-info" (click)="assignGeo(device, l,$event)">Choose</button> {{l.city_name}}, {{l.administrative_area}}
                          </div>
                          <div *ngIf="geoData.locs.length == 0"> No Locations Found</div>
                          <div class="col-12">
                            <button type="button" class="btn btn-warning float-right" (click)="geoData.search=true">Clear Location Search</button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="device.isStation && device.isfact23" class="mt-3">
                      <div>Geo:</div>
                      <div class="form-group">
                        Check for no Timezone information:<input type="checkbox" [checked]="device.geo.anonymous" (change)="device.geo.anonymous = !device.geo.anonymous"/>
                      </div>
                      <div *ngIf="!device.geo.anonymous">
                        <div class="form-group row">
                          <label class="col-3 form-control-label" for="edit-name">Zip Code</label>
                          <div class="col-9"><input class="form-control" id="edit-name" name="deviceZip" placeholder="name" type="text" [(ngModel)]="device.geo.pc" autocomplete="off"></div>
                        </div>
                        <div class="form-group row">
                          <label class="col-3 form-control-label" for="edit-name">Timezone</label>

                          <!--<div class="col-9"><input class="form-control" id="edit-name" name="deviceTimezone" placeholder="name" type="text" [(ngModel)]="device.raw.sensor.geo.timezone" autocomplete="off"></div>
                          -->
                          <div class="col-9">
                            <select class="form-control" name="timezone" [(ngModel)]="device.geo.timezone">
                              <option *ngFor="let tz of timezones.current" [value]="tz">{{tz}}</option>
                              <option *ngFor="let tz of timezones.defaults | keys" [value]="tz">{{timezones.defaults[tz]}}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div>
                        <button type="button" class="btn btn-info" (click)="updateDeviceGeoLegacy(device, $event)">Update Geo Info</button>
                      </div>
                    </div>


                    <div class="mt-3">
                      <button type="button" class="btn btn-info" (click)="cancelEditDevice(device, $event)">Cancel</button>
                    </div>
                  </div>
                </div>


              </div>

              <div *ngIf="device.identifier == 'new'" class="card">
                <div class="card-header">
                  <label>New Device</label>
                </div>
                <div class="card-block" *ngIf="user._nested.locations?.items.length > 0">
                  <form action="" class="form-horizontal" >

                    <div class="form-group row" *ngIf="!device.status">
                      <label class="col-3 form-control-label" for="edit-id">Serial</label>
                      <div class="col-9"><input class="form-control" id="edit-id" name="deviceID" placeholder="6 digit Serial" type="text" [(ngModel)]="device.sensor_serial" autocomplete="off"></div>
                    </div>

                    <div class="form-group row" *ngIf="device.status">
                      <label class="col-3 form-control-label" for="edit-id">Serial</label>
                      <div class="col-9">{{device.sensor_serial}}</div>
                    </div>

                    <div class="form-group row">
                      <label class="col-3 form-control-label" for="edit-handle">Location</label>
                      <div class="col-9">
                        <select class="form-control" name="location" [(ngModel)]="device.location_id">
                          <option *ngFor="let loc of user._nested.locations.items" value="{{loc.identifier}}">{{loc.name}}</option>
                        </select>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-3 form-control-label" for="edit-name">Device Name</label>
                      <div class="col-9"><input class="form-control" id="edit-name" name="device[name]" placeholder="name" type="text" [(ngModel)]="device.device_name" autocomplete="off"></div>
                    </div>
                    <button *ngIf="!device.status" type="button" class="btn btn-info" (click)="checkDevice(device,$event)">Check Device</button>
                    <button *ngIf="device.status" type="button" class="btn btn-info" (click)="createDevice(device,$event)">Link Device To Account</button>

                  </form>
                </div>
                <div class="card-block" *ngIf="!(user._nested.locations?.items.length > 0)">
                  Create Locations First
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="user._nested.deviceAssociations && user._nested.deviceAssociations.error==true" class="row">
            <div class="col-12">
                Error loading:
              <button type="button" class="btn btn-info" (click)="reloadAssocDevices()">Try Again</button>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
                <button type="button" class="btn btn-info" (click)="addDevice()">Add Device</button>
            </div>
          </div>

        </tab>
        <tab heading="Locations">
          <div *ngIf="!user._nested.locations" class="overlayParent spinner-holder">
            <div  class="overlay">
              <widget-spinner></widget-spinner>
            </div>
          </div>

          <div class="card-block" *ngIf="user._nested.locations?.error">
            Error Loading Locations <button type="button" class="btn btn-info" (click)="reloadLocations($event)">retry</button>
          </div>
          <div *ngIf="user._nested.locations?.items" class="row">
            <div *ngFor="let l of user._nested.locations.items" class="col-6">
              <div class="card overlayParent">
                <div class="overlay" *ngIf="l.saving">
                  <widget-spinner></widget-spinner>
                </div>

                <div *ngIf="!(l.identifier == 'new')">
                  <div class="card-header">
                    {{l.name}}
                    <button class="btn btn-primary btn-sm pull-right" (click)="l.showExtra = true">Reposition</button>
                  </div>
                  <div class="card-block">

                    <div>CreatedOn: {{l.raw.createdOn |date:'MM/dd/yyyy @ h:mm:ssa'}}</div>
                    <div>Modified: {{l.raw.createdOn |date:'MM/dd/yyyy @ h:mm:ssa'}}</div>
                    <div>id: {{l.identifier}} </div>
                    <div *ngIf="l.showExtra===true">
                      <span>Move Infront of</span>
                      <select name="move" [(ngModel)]="l.newPos" style="color:black;min-width:150px">
                        <ng-container *ngFor="let itm of user._nested.locations.items"><option *ngIf="itm.identifier!=l.identifier && itm.position != l.position+1" [value]="itm.identifier">{{itm.position}}: {{itm.name}}</option></ng-container>
                      </select>
                      <button class="btn btn-sm btn-warning"(click)="reposLocation($event, l)">Move</button>
                    </div>

                    <div *ngIf="l.linkedDevices.length === 0">
                      <button type="button" class="btn btn-danger float-right" (click)="deleteLocation(confirm,l, $event)">Delete</button>
                    </div>
                    <div *ngIf="!(l.linkedDevices.length === 0)">
                      <div class="alert alert-warning float-right" (click)="listLinkedDevices(l)">Devices linked to Location</div>
                    </div>
                  </div>
                </div>
                <div *ngIf="l.identifier == 'new'">
                  <div class="card-header">{{l.identifier}}</div>
                  <div class="card-block">
                    <div class="form-group row">
                      <label class="col-3 form-control-label" for="edit-email">Name</label>
                      <div class="col-9"><input class="form-control" id="edit-name" name="l[name]" placeholder="name" type="name" [(ngModel)]="l.name" autocomplete="off"></div>
                    </div>
                    <div>
                      <button type="button" class="btn btn-info" (click)="createLocation(l, $event)">Create</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 text-center">
                <button type="button" class="btn btn-info" (click)="addLocation()">Add Location</button>
            </div>
          </div>
        </tab>

        <tab heading="Channels" (selectTab)="loadUserChannels()">
          <new-spinner *ngIf="user.meta?.channels?.loading"  [size]="'lg'"></new-spinner>
          <widget *ngIf="user.meta?.channels?.list" [entity]="user.meta.channels.list" [layout]="'standard'" [options]="{columns: 2, complete: true}"></widget>
        </tab>

        <tab heading="Group" (selectTab)="loadInfo('g')" *ngIf="user.auth.permissionLevel <=1 || user.auth.permissionsList['read-groups'] == 1">
          <new-spinner *ngIf="!user._nested.group"  [size]="'lg'"></new-spinner>
          <div *ngIf="user._nested.group">
            <group-entry [group]="user._nested.group" [uid]="user.identifier" [source]="1"></group-entry>
          </div>
        </tab>

        <tab heading="Alerts" (selectTab)="loadAlerts()">
          <widget (widgetEvent)="handleAlertEvent($event)" [entity]="user.extended.alerts" [layout]="'standard'" [options]="{sideBarWidth: 5, dpp: 250, expand: true, columns: 2, headers: ['identifier', 'name', 'type', 'status', 'modified_on', 'last_event', 'actions'], edit: true}"></widget>
        </tab>
        <tab heading="Events" (selectTab)="loadInfo('e')">
          <new-spinner *ngIf="!user._nested.alertEvents"  [size]="'lg'"></new-spinner>

          <div *ngIf="user._nested.alertEvents"><pre>{{user._nested.alertEvents | json}}</pre></div>
        </tab>

        <tab heading="Notifications" (selectTab)="loadInfo('n')">


          <new-spinner *ngIf="!user._nested.notifications.data"  [size]="'lg'"></new-spinner>


          <div *ngIf="user._nested.notifications.data" class="row">
            <div class="col-12 mb-4">




                <div class="card" >
                  <div class="card-header">
                    Notification Status
                    <button  *ngIf="user._nested.notifications.status" (click)="user._nested.notifications.status.show = !user._nested.notifications.status.show" class="btn btn-primary pull-right">
                      <i class="fa fa-sm"  [ngClass]="{'fa-chevron-down': !user._nested.notifications.status.show, 'fa-chevron-up': user._nested.notifications.status.show}"></i>
                    </button>
                    <button (click)="getNotificationStatus()" class="btn btn-primary text-center pull-right">Get Notification Status</button>
                  </div>
                  <div class="card-body" *ngIf="user._nested.notifications.status?.loading || user._nested.notifications.status?.show">
                    <new-spinner *ngIf="user._nested.notifications.status?.loading"  [size]="'lg'"></new-spinner>
                    <pre *ngIf="user._nested.notifications.status?.show">{{user._nested.notifications.status|json}}</pre>
                  </div>
                </div>




            </div>

            <div *ngFor="let n of user._nested.notifications.data.items"class="col-6 card overlayParent">
              <div class="card">
                <div class="card-header">
                  Identifier: {{n.identifier}}
                  <button class="btn-sm btn-primary float-right" (click)="toggleNotificationRaw(n)">
                    <i class="fa fa-sm"  [ngClass]="{'fa-chevron-down': !n.showRaw, 'fa-chevron-up': n.showRaw}"></i>
                  </button>
                </div>
                <div class="card-body">
                  <!-- use toasters for change confirmations -->
                  <new-spinner *ngIf="!user._nested.notifications.data"  [size]="'md'"></new-spinner>
                  <div><label>Record Updated on:</label> {{n.raw.modifiedOn |date:'MM/dd/yyyy @ h:mm:ssa'}} </div>
                  <div><label>User Responded:</label> {{n.raw.respondedOn |date:'MM/dd/yyyy @ h:mm:ssa'}} </div>
                  <div><label>Sensor Origional Name:</label> {{n.raw.suggestedName}} </div>
                  <div *ngIf="n.raw.sensor"><label>Sensor Serial:</label> {{n.raw.sensor.serial}} </div>
                  <div *ngIf="n.raw.sender"><label>Sender ID:</label> {{n.raw.sender.id}} </div>
                  <div *ngIf="n.raw.sender"><label>Sender First Name:</label> {{n.raw.sender.firstName}} </div>
                  <div *ngIf="n.raw.sender"><label>Sender Last Name:</label> {{n.raw.sender.lastName}} </div>
                  <div><label>Notify Accept:</label> {{n.raw.notifyAccept}} </div>
                  <div *ngIf="n.showRaw">
                    <pre>{{n.raw | json}}</pre>
                  </div>
                  <div><button (click)="n.loading = true; deleteNotification($event, n)" class="btn btn-danger pull-right">Delete</button></div>
                </div>
              </div>
            </div>
          </div>
        </tab>

        <tab heading="Settings" (selectTab)="loadSettings()">
          <user-settings-widget [entity]="user" [options]="{}"></user-settings-widget>
        </tab>

        <tab heading="Subscription" (selectTab)="loadSubscriptions()">
          <!--                                       -->
          <!--           User Subs                   -->
          <!--                                       -->
          <ng-container [ngSwitch]="user_sub_version" >
            <ng-container *ngSwitchDefault>
              <div class="row">
                <div class="col">
                  <ng-container *ngIf="!user?.extended?.subscriptions">
                    <new-spinner *ngIf="user?.extended?.meta?.subscriptions_loading"  [size]="'sm'"></new-spinner>
                    <div class="alert alert-warning" *ngIf="!user?.extended?.meta?.subscriptions_loading">
                      Subscription Request Failure: <pre>{{user?.extended?.meta?.subscriptions_load_error | json}}</pre>
                    </div>
                  </ng-container>


                  <div *ngIf="user?.extended?.subscriptions">
                    <widget
                      [entity]="user.extended.subscriptions"
                      [options]="{edit: true, header: true, headers: ['identifier', 'handle', 'type', 'start', 'end', 'actions']}"
                      [layout]="'standard'"
                    ></widget>
                  </div>
                </div>
              </div>
            </ng-container>

          </ng-container>





        </tab>


        <tab heading="Logs">
          <div class="row">
            <div class="col-12">
              <widget
                *ngIf="user?.logs"
                [entity]="user.logs"
                [options]="{subject: 'ref.user.' + user.identifier}"
                [layout]="'standard'"
              ></widget>
            </div>
          </div>
        </tab>


      </tabset>
    </div>
  </div>
</div>

<div *ngIf="notification.message.length > 0" class="row">
  <div class="alert col-12" [ngClass]="{'alert-info': !notification.iserror, 'alert-danger': notification.iserror}" (click)="setNotification('',false,true)">
    {{notification.message}}. Click to close
  </div>
</div>

<ng-template #confirm>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{currentModal.title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {{currentModal.msg}}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="currentModal.cancel()" data-dismiss="modal">{{currentModal.cancelMsg}}</button>
    <button type="button" class="btn btn-danger" (click)="currentModal.confirm()">{{currentModal.confirmMsg}}</button>
  </div>
</ng-template>

<ng-template #pageModal>
  <modal-widget [entity]="currentModal" (widgetEvent)="handleEvent($event)" [layout]="'shadowbox'" [options]="{}"></modal-widget>
</ng-template>
