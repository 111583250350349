import { Component, Input, Inject, OnInit } from '@angular/core';
import {FirmwareLinkV2Entity} from "../../../entities/firmware/link-v2.entity";

@Component({
  selector: 'embed-firmware-link-v2',
  template: `
    <firmware-link-widget [entity]="entity" [layout]="layout" [options]="options"></firmware-link-widget>
  `
})
export class EmbedFirmwareLinkV2Component {
  @Input() entity: FirmwareLinkV2Entity = null;
  @Input() options: any = null;
  @Input() layout: string = null;




}
