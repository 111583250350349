import {Component, Input, Inject, OnInit, Output, EventEmitter} from '@angular/core';
import {WeatherCategoryLogicalWidget} from '../../../entities/';
import {Observable} from 'rxjs';
import {LogicalWidgetEventStruct} from './logical-widget-event.struct';

@Component({
  selector: 'weather-category-logical-widget',
  template: `
    <div *ngIf="!entity">
      <div class="row"><div class="col">
        <new-spinner [size]="'sm'"></new-spinner>
      </div></div>
    </div>
    <div *ngIf="entity">
      <div *ngIf="options?.edit">
        <div *ngIf="valueSubscriber | async as values; else loading">
          <ng-select
            placeholder="Select Weather Category"
            [items]="values"
            bindLabel="label"
            bindValue="value"
            [multiple]="false"
            [(ngModel)]="entity.selected"
            (ngModelChange)="updateOutput()"
          ></ng-select>
        </div>

        <ng-template #loading>
          <div class="row"><div class="col">
            <new-spinner [size]="'sm'"></new-spinner>
          </div></div>
        </ng-template>

      </div>
      <div *ngIf="!options?.edit">
        {{entity.selected | json}}
      </div>
    </div>
  `
})
export class WeatherCategoryLogicalWidgetComponent implements OnInit{
  @Input() entity: WeatherCategoryLogicalWidget | null = null;
  @Input() templateHint: string = null;
  @Input() options: any = null;
  @Input() layout = 'standard';

  @Output() widgetEvent = new EventEmitter<LogicalWidgetEventStruct>();
  forwardEvent(e, widgetEvent: LogicalWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }
  updateOutput() {
    let event = new LogicalWidgetEventStruct()
    event.event_widget_type = this.entity.widget_type();
    event.event_logical_widget_type = this.entity.logical_widget_type();
    event.event_type = "weather_category_selection_made"
    event.event_body = {weather_category: this.entity.selected}
    this.widgetEvent.emit(event);
  }


  public valueSubscriber: any  = null;

  constructor() {
  }



  ngOnInit() {
    this.entity.selected = this.entity.subject
    this.valueSubscriber = new Observable<any>(observer => {
      const response = [];
      response.push({value: 1, label: "Weather Category 1"})
      response.push({value: 2, label: "Weather Category 2"})
      response.push({value: 3, label: "Weather Category 3"})
      observer.next(response)
    });
  }

}

