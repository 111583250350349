import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity } from '../lacrosse.entity';
import { FirebaseAuthService } from '../../noizu/services/firebase-auth.service';
import {LogWidgetEnum, NotificationLogWidgetEnum, WidgetEnum, MCUStatusCodes, CodeSet3_FirmwareCodes, CodeSet4_FirmwareCodes, TrieFirmwareCommands, FirmwareCommands} from '../../enums';

export class EntityLogEntity extends LacrosseEntity {
  public topic: String = null;
  public subtopic: String = null;
  public _brief: String = null;
  public type: String = null;
  public subject: String = null;
  public context: any = null;
  public date_time: any = null;
  public level: String = null;
  public message: any = null;
  public _kind = 'entity-logs';
  public _singular = 'entity-log';


  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    this.topic = json.topic;
    this.subtopic = json.subtopic;
    this.context = json.context;
    if (json.date_time) {
      this.date_time = new Date(json.date_time);
    }
    this.type = json['type'];
    this.subject = json['subject'];
    this.level = json.level;
    this.message = json.message;
  } // end constructor

  public brief() {
    if (!this._brief) {
      this._brief = 'log type specific summary.'
    }
    return this._brief;
  }

  public readings() {
    const r = [];
    if (this.message && this.message.post) {
      for (const key in this.message.post) {
        const value =  this.message.post[key];
        const entry = {
          field: key,
          value: value.reading && value.reading.value,
          unit: value.reading && value.reading.unit,
          attributes: value.attributes
        }
        r.push(entry);
      }
    } else if (
      this.message &&
      this.message.encoding &&
      this.message.encoding.reading) {
      for (const key in this.message.encoding.reading) {
        const value =  this.message.encoding.reading[key];
        const entry = {
          field: key,
          value: value.reading && value.reading.value,
          unit: value.reading && value.reading.unit,
          attributes: value.attributes
        }
        r.push(entry);
      }
    }
    return r;
  }

  public commandLog() {
    const r = [];

    if (this.message && this.message.payload && this.message.payload['command_history']) {
      const version = this.message.payload['version'] || 1;
      for (const id in this.message.payload['command_history']) {
        const value = this.message.payload['command_history'][id];
        let command = '';


        if (version == 3) {
          if (value in CodeSet3_FirmwareCodes) {
            command = CodeSet3_FirmwareCodes[value];
          } else {
            command = 'UNKNOWN_' + value;
          }
        } else if (version == 4) {
          if (value in CodeSet4_FirmwareCodes) {
            command = CodeSet4_FirmwareCodes[value];
          } else {
            command = 'UNKNOWN_' + value;
          }
        }else {
          if (value in FirmwareCommands) {
            command = FirmwareCommands[value];
          } else if (value in TrieFirmwareCommands) {
            command = TrieFirmwareCommands[value];
          } else {
            command = 'UNKNOWN_' + value;
          }
        }

        r.push({code: value, command: command});
      }
    }
    return r;
  }

  widget_type() {
    return WidgetEnum.EMBED_WIDGET__LOG_ENTITY;
  }

  log_widget_type(): LogWidgetEnum | null {
    return LogWidgetEnum.LOG_WIDGET__GENERIC;
  }


  filter(filter: string) {
    if (filter) {
      if (this.meta['filter'] == filter) return this.meta['filter_result'];

      this.meta['filter'] = filter;
      this.meta['filter_result'] = false;


      let topic_filters = filter.match(/topic:([a-z_0-9]*)/)
      if (topic_filters) {
        topic_filters.forEach((v) => {
          if (this.topic == v) {
            this.meta.filter_result = true;
            return true;
          }
        });
      }


      let subtopic_filters = filter.match(/subtopic:([a-z_0-9]*)/)
      if (subtopic_filters) {
        subtopic_filters.forEach((v) => {
          if (this.subtopic == v) {
            this.meta.filter_result = true;
            return true;
          }
        });
      }


      let level_filters = filter.match(/level:([a-z_0-9]*)/)
      if (level_filters) {
        level_filters.forEach((v) => {
          if (this.level == v) {
            this.meta.filter_result = true;
            return true;
          }
        });
      }

      return this.meta.filter_result;
    }  else {
      return true;
    }
  }

  public StatusCode(code) {
    let command = '';
    if (code in MCUStatusCodes) {
      command = MCUStatusCodes[code];
    } else {
      command = `UNKNOWN_${code}`;
    }
    return command;
  }
} // end Package
