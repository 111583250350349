import {LogicalWidgetEnum,} from '../../enums';
import {LogicalWidget} from '../logical-widget';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';

export class DateTimeLogicalWidget extends LogicalWidget{
  public selected: Date = null;
  public select_date: NgbDate;
  public select_time: any = {hour: 1, minute: 1, second: 1}

  public has_value = false;
  public unset_label = '';
  public unset = true;


  private _resolve;
  private _reject;
  public promise;

  logical_widget_type(): LogicalWidgetEnum | null {
    return LogicalWidgetEnum.LOGICAL_WIDGET__DATE_TIME;
  }

  constructor(default_date: Date = null, unset_label = 'Unbound') {
    super();
    this.selected = default_date || new Date();
    this.has_value = default_date != null;
    this.unset_label = unset_label;

    this.select_date = new NgbDate(this.selected.getFullYear(), this.selected.getMonth() + 1, this.selected.getDate());
    this.select_time = {
      hour: this.selected.getHours(),
      minute: this.selected.getMinutes(),
      second: this.selected.getSeconds(),
    };


    this._resolve = null;
    this._reject = null;
    this.promise = new Promise(
      (resolve, reject) => {
        this._resolve = resolve;
        this._reject = reject;
      });

  } // end constructor



  public selectedDate() {
    return new Date(this.select_date.year,this.select_date.month - 1,this.select_date.day,this.select_time.hour, this.select_time.minute, this.select_time.second);
  }

  public overrides() {
    let overrides = {
      "confirm": {
        msg: "Select",
        cb: (self, event, modalRef, toaster) => {
          this.unset = false;
          this._resolve(self.subject.selectedDate());
          modalRef.hide();
        }
      },
      "cancel": {
        msg: "Cancel",
        cb: (self, event, modalRef, toaster) => {
          this._reject(null);
          modalRef.hide();
        }
      }
    };
    return overrides;
  }


} // end Package

