import {ValueAndUnit} from '../../../structs/value-and-unit';
import {AlertFacadeEntity} from '../facade.entity';
import {WidgetEnum_Alert_Facade} from '../../../enums/widget/alert';

export class AlertThermometerFacadeEntity extends AlertFacadeEntity {
  public targetTemperature: ValueAndUnit;
  public targetDelta: ValueAndUnit;
  public targetFuse: any;

  public approachingTargetDelta: ValueAndUnit;
  public approachingTargetFuse: any;

  public minimumAcceptableTemperature: ValueAndUnit;
  public maximumAcceptableTemperature: ValueAndUnit;

  public constructor(alert, json) {
    super(alert, json);

    this.targetTemperature = null;
    if (json["targetTemperature"]) {
      this.targetTemperature = new ValueAndUnit(json["targetTemperature"]);
    }

    this.targetDelta = null;
    if (json["targetDelta"]) {
      this.targetDelta = new ValueAndUnit(json["targetDelta"]);
    }
    this.targetFuse = json.targetFuse;



    this.approachingTargetDelta = null;
    if (json["approachingTargetDelta"]) {
      this.approachingTargetDelta = new ValueAndUnit(json["approachingTargetDelta"]);
    }
    this.approachingTargetFuse = json.approachingTargetFuse;



    this.minimumAcceptableTemperature = null;
    if (json["minimumAcceptableTemperature"]) {
      this.minimumAcceptableTemperature = new ValueAndUnit(json["minimumAcceptableTemperature"]);
    }


    this.maximumAcceptableTemperature = null;
    if (json["maximumAcceptableTemperature"]) {
      this.maximumAcceptableTemperature = new ValueAndUnit(json["maximumAcceptableTemperature"]);
    }



  }

  //-----------------------------------
  //
  //-----------------------------------
  public get template(): string | null {
    return 'thermometer';
  }

  alert_facade_widget() {
    return WidgetEnum_Alert_Facade.ALERT_FACADE_WIDGET__THERMOMETER;
  }
}
