import {NgSelectModule} from '@ng-select/ng-select';

declare module "@angular/core" {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: Provider[];
  }
}


import * as PlotlyJS from 'plotly.js-dist-min';
import { PlotlyModule } from 'angular-plotly.js';
PlotlyModule.plotlyjs = PlotlyJS;

import {NgPipesModule} from 'ngx-pipes';

// to build for production
// ngE build --prod --aot=false
import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { DragulaModule } from 'ng2-dragula';

import {ChartsModule} from 'ng2-charts';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule } from '@angular/forms';
import {LocationStrategy, HashLocationStrategy, APP_BASE_HREF, CommonModule} from '@angular/common';

import {MyDatePickerModule} from 'mydatepicker';
import {PersistenceModule} from 'angular-persistence';

import {environment} from '../environments/environment';

import {AppComponent} from './app.component';
import {UserShowComponent} from './components/user';
import {UserListComponent} from './components/user';
import {DeviceDashboardComponent} from './components/device';
import {GatewayShowComponent} from './components/gateway';
import {GatewayListComponent} from './components/gateway';
import {LoginComponent} from './components/misc';
import {LogoutComponent} from './components/misc';
import {PageNotFoundComponent} from './components/misc';
import {GroupListComponent} from './components/group';
import {GroupShowComponent} from './components/group';
import {ReportingShowComponent} from './components/reporting';
import {ToolsComponent} from './components/tools';
import {AppNotificationComponent} from './components/app-notifications';

import { CookieModule } from 'ngx-cookie';
// New Widget System
import {
  WidgetComponent,
  AlertWidgetComponent,
  PermissionWidgetComponent,
  UserWidgetComponent,
  UserChannelWidgetComponent,
  UserPreferencesWidgetComponent,
  UserExtendedWidgetComponent,
  UserExtendedEffectiveSubscriptionWidgetComponent,
  UserExtendedSubscriptionWidgetComponent,
  UserExtendedSubscriptionPaymentWidgetComponent,
  SubscriptionDefinitionWidgetComponent,
  SubscriptionDefinitionsWidgetComponent,
  UserExtendedSubscriptionDetailsWidgetComponent,
  PinWidgetComponent,
  PinGroupWidgetComponent,
  UserSettingsWidgetComponent,
  UserDevicesWidgetComponent,
  BatchWidgetComponent,
  ManufacturerWidgetComponent,
  AlertFacadeWidgetComponent,
  AlertFacadeDefaultWidgetComponent,
  AlertTriggerStateObjectWidgetComponent,
  AlertStatusFacadeWidgetComponent,
  SubscriberObjectSetWidgetComponent,
  SubscriberObjectWidgetComponent,
  CallbackObjectSetWidgetComponent,
  CallbackObjectWidgetComponent,
  RuntimeConfigurationObjectWidgetComponent,
  AlertConstraintStatusWidgetComponent,
  AlertConstraintStatusSetWidgetComponent,
  DeviceSimulatorWidgetComponent,
  DeviceSetLogicalWidgetComponent,
  DeviceLogicalWidgetComponent,
  ExtendedDeviceLogicalWidgetComponent,
  DeviceFieldSimulatorWidgetComponent,
  DeviceFeedWidgetComponent,
  DeviceReadingWidgetComponent,
  DeviceRangeWidgetComponent,
  DeviceWidgetComponent,
  DeviceEntityWidgetComponent,
  DeviceTargetWidgetComponent,
  DeviceLinkWidgetComponent,
  BackButtonComponent,
  FirmwareLogicalWidgetComponent,
  PageHeaderComponent,
  ExtendedDeviceChartLogicalWidgetComponent,
  ExtendedDeviceReadingLogicalWidgetComponent,
  HealthReportWidgetComponent,
  ReportWidgetComponent,
  EmailTemplateWidgetComponent,
  EmailTemplateSectionEachWidgetComponent,
  EmailTemplateSectionIfThenWidgetComponent,
  EmailTemplateSectionSectionWidgetComponent,
  EmailTemplateSectionSelectorWidgetComponent,
  NoteWidgetComponent,
  BareModalWidgetComponent,
  AlertGenericLogWidgetComponent,
  AlertCreatedLogDetailsWidgetComponent,
  AlertDeletedLogDetailsWidgetComponent,
  AlertDeregisterCallbackLogDetailsWidgetComponent,
  AlertLoadErrorLogDetailsWidgetComponent,
  AlertLoadLogDetailsWidgetComponent,
  AlertPrepareCallbacksLogDetailsWidgetComponent,
  AlertRecoveredErrorLogDetailsWidgetComponent,
  AlertRecoveredLogDetailsWidgetComponent,
  AlertRefreshCallbackLogDetailsWidgetComponent,
  AlertRefreshErrorLogDetailsWidgetComponent,
  AlertRefreshLogDetailsWidgetComponent,
  AlertRegisterCallbackLogDetailsWidgetComponent,
  AlertSetDisabledLogDetailsWidgetComponent,
  AlertSetEnabledLogDetailsWidgetComponent,
  AlertTriggeredErrorLogDetailsWidgetComponent,
  AlertTriggeredLogDetailsWidgetComponent,
  AlertUnchangedLogDetailsWidgetComponent,
  AlertUnloadedLogDetailsWidgetComponent,
  AlertUpdatedLogDetailsWidgetComponent, AlertLogDetailsWidgetComponent, LogDetailsGenericWidgetComponent
} from './widgets';

// Logical Widgets
import {
  LogicalWidgetComponent, BatchLogicalWidgetComponent, ManufacturerLogicalWidgetComponent, SeriesLogicalWidgetComponent, DeviceTypeLogicalWidgetComponent, WeatherCategoryLogicalWidgetComponent,
  FirmwareDefinitionLogicalWidgetComponent,
  FirmwareGenericLogicalWidgetComponent,
  FirmwareTargetLogicalWidgetComponent,
  FirmwareTargetSetLogicalWidgetComponent,
  PinGroupLogicalWidgetComponent, DateTimePickerLogicalWidgetComponent, DateTimeRangePickerLogicalWidgetComponent, ChannelLogicalWidgetComponent,
  AlertFacadeSelectorLogicalWidgetComponent, LogicalAlertWidgetComponent,
}  from './widgets';


// Common Elements
import {
  WidgetToggleComponent, WidgetSetComponent, AppengineWidgetSetComponent, GenericWidgetSetComponent
} from './widgets';

// Embed Widgets
import {WidgetEmbedComponent, EmbedDefaultComponent} from './widgets';

// Entity Set
import {EntitySetListWidgetComponent, EntitySetFooterWidgetComponent, EntitySetHeaderWidgetComponent} from './widgets/widget/entity-set';

// Batch Widgets
import {EmbedBatchComponent} from './widgets';

// Device Widgets
import {EmbedDeviceComponent, EmbedDeviceTypeComponent, EmbedDeviceFieldComponent, EmbedDeviceRangeComponent,
  EmbedDeviceAttributesComponent, EmbedDeviceTypeFieldEntryComponent} from './widgets';

// Firmware Widgets
import {EmbedFirmwareDefinitionComponent, EmbedFirmwareLinkComponent, EmbedFirmwareLinkV2Component,
  EmbedFirmwareApprovalComponent} from './widgets';

// MCU Firmware Widgets
import {EmbedMCUFirmwareDefinitionComponent, EmbedMCUFirmwareLinkComponent, EmbedMCUFirmwareReleaseComponent, EmbedFirmwareTarget} from './widgets';

// Gateway Widgets
import {EmbedGatewayComponent, EmbedGatewayFirmwareComponent, EmbedGatewayFirmwareReportComponent, EmbedWifiAndMcuReportComponent, EmbedGatewayMcuReportComponent}  from './widgets';

// Log Widgets
import {
  LogSetWidgetComponent,
  LogWidgetComponent,
  AlertLogWidgetComponent,
  DataStreamLogWidgetComponent,
  DeviceLogWidgetComponent,
  GatewayLogWidgetComponent,
  NotificationLogWidgetComponent,
  UserLogWidgetComponent,
  GenericLogWidgetComponent,
  LogDetailsWidgetComponent,
  GatewayLogDetailsWidgetComponent,
}  from './widgets/widget/log';

// Log Widget Common
import {LogTableHeaderWidgetComponent} from './widgets/widget/log/common/';

// Log Widget Gateway
import {
  GatewayWakeLogDetailsWidgetComponent,
  GatewayExtractBinaryErrorLogDetailsWidgetComponent,
  GatewayExtractBinaryLogDetailsWidgetComponent,
  GatewayReportBinaryLogDetailsWidgetComponent,
  GatewayStageOneLogDetailsWidgetComponent

} from './widgets/widget/log/gateway/';


import {EmbedRequestLogComponent,
  EmbedRequestLogsComponent,
  EmbedEntityLogExtractionWarnComponent,
  EmbedEntityLogReportBinaryComponent,
  EmbedEntityLogReportBinaryDetailsComponent,
  EmbedEntityLogStageOneDataComponent,
  EmbedEntityLogStreamComponent,
  EmbedEntityLogWakeComponent,
  WidgetEmbedLogSubTypeComponent
} from './widgets';


// Log Widget Device
import {DeviceStageOneLogDetailsWidgetComponent,
  DeviceLogDetailsWidgetComponent} from './widgets/widget/log/device';

// Log Datastream Widgets
import {
  DataStreamFetchLogDetailsWidgetComponent,
  DataStreamLogDetailsWidgetComponent
} from './widgets/widget/log/data_stream';

// Data Stream Widgets
import {EmbedCmsDataStreamComponent, EmbedCmsAlertComponent, EmbedDsCardComponent, EmbedDsDeviceCardComponent, EmbedDsDiagnosticCardComponent, EmbedDsTimeCardComponent,
  EmbedDsMediaCardComponent, EmbedDsNwsCardComponent, EmbedDsWeatherCardComponent, EmbedDsGenericCardComponent, WidgetEmbedDSCardComponent} from './widgets';

// Alert Widgets
import {EmbedAlertContactFacadeComponent, EmbedAlertHumidityFacadeComponent, EmbedAlertLightningFacadeComponent,
  EmbedAlertMotionFacadeComponent, EmbedAlertOnOffFacadeComponent, EmbedAlertProbeFacadeComponent, EmbedAlertRainfallFacadeComponent,
  EmbedAlertTemperatureFacadeComponent, EmbedAlertWetDryFacadeComponent, EmbedAlertWindFacadeComponent, EmbedAlertThermometerFacadeComponent, EmbedNotificationSettingsComponent
} from './widgets';

// Modal Widgets
import {ModalWidgetComponent, GenericModalWidgetComponent, ConfirmModalWidgetComponent, CreateModalWidgetComponent, EditModalWidgetComponent, MiniModalWidgetComponent} from './widgets'

// Firmware Widgets
import {FirmwareApprovalWidgetComponent, FirmwareDefinitionWidgetComponent,
  FirmwareLinkWidgetComponent, FirmwareReleaseWidgetComponent,
  FirmwareTargetWidgetComponent, FirmwareWidgetComponent, FirmwareGenericWidgetComponent}  from './widgets'

// New Style . . .
import {ModalModule} from 'ngx-bootstrap/modal';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {AdminHomePanelComponent} from './components/admin-home';
import {EmailTemplateListComponent} from './components/data-management';
import {EmailTemplateShowComponent} from './components/data-management';
import {EmailQueueListComponent} from './components/data-management';
import {EmailQueueShowComponent} from './components/data-management';
import {CmsAlertListComponent, CmsAlertShowComponent, CmsDataStreamListComponent} from './components/cms';
import {DeviceShowComponent} from './components/device';
import {DeviceDefinitionListComponent} from './components/device';
import {DeviceDefinitionViewComponent} from './components/device';
import {DeviceFieldDefinitionListComponent} from './components/device';
import {DeviceFieldDefinitionViewComponent} from './components/device';
import {DeviceDefinitionShowComponent} from './components/device';
import {DeviceAutoLoadLookupTableEntryComponent} from './components/device';
import {SensorTypeRangeEntryComponent} from './components/device';
import {ManufacturersEntryComponent} from './components/data-management';
import {SubscriptionsComponent} from './components/subscriptions';
import {SubscriptionEntryComponent} from './components/subscriptions';
import {DeviceAutoLoadLookupTableComponent} from './components/device';
import {GatewayAutoLoadLookupTableComponent} from './components/gateway';
import {DataManagementSynchQueueComponent} from './components/data-management';
import {DataManagementEntityArchiveComponent} from './components/data-management';
import {DeviceAutoLoadSensorTypeMappingComponent} from './components/device';
import {ReportingAuditHistoryComponent} from './components/reporting';
import {DataManagementWipfliSynchQueueComponent, DataManagementUploadComponent} from './components/data-management';
import {FormulaNodeComponent} from './components/device';
import {FormulaNodeEditComponent} from './components/device';
import {ChannelDefinitionListComponent, ChannelDefinitionShowComponent} from './components/channel-definition';
import {GroupEntryComponent} from './components/group';
import {PermissionsEntryComponent} from './components/permissions';

import {
  FirmwareDefinitionListComponent,
  FirmwareDefinitionShowComponent,
  FirmwareLinkListComponent,
  FirmwareLinkShowComponent,
  FirmwareOverviewComponent,
  FirmwareReleaseListComponent,
  FirmwareApprovalListComponent,
  FirmwareApprovalShowComponent, FirmwareReleaseShowComponent
} from './components/firmware';
import {MCUFirmwareDefinitionListComponent, MCUFirmwareLinkListComponent, MCUFirmwareOverviewComponent, MCUFirmwareReleaseListComponent} from './components/mcu';

import {DeviceDuplicatesListComponent} from './components/device';
import {DataManagementBatchesComponent} from './components/data-management';
import {DataManagementManufacturersComponent} from './components/data-management';



// System Health
import {SystemHealthWorkersComponent, SystemHealthUserReportComponent, SystemHealthTelemetryComponent, SystemHealthNodesComponent,
  SystemHealthOverviewComponent, SystemHealthServicesComponent, SystemHealthTimeScaleComponent} from './components/system-health';

import {EntityNotesComponent} from './components/misc';
import {EntityNotesV2Component} from './components/misc';
import {DeviceReadingComponent} from './components/misc';
import {ShowObjectComponent} from './components/misc';
import {DeviceSimulatorComponent} from './components/device';
import {WidgetSpinnerComponent, SpinnerComponent } from './widgets';
import {WidgetTimeStampComponent } from './widgets';
import {DataStreamItemComponent} from './components/device';

import {KeysPipe} from './components/pipes';
import {SelectDropDownModule} from 'ngx-select-dropdown'


// . . . New Style
import {UserPreferencesService} from './services/user-preferences.service';
import {AuthService} from './services/auth.service';
import {FirebaseAuthService} from './noizu/services/firebase-auth.service';
import {CacheService} from './services/cache.service';
// import {GatewayService} from './services/gateway.service';

// Repos
import {AdminRepo, BatchRepo, ElixirRepo, GenericRepo, SubscriptionDefinitionRepo} from './repos';
import {DeviceDefinitionRepo} from './repos';
import {DeviceFieldDefinitionRepo} from './repos';
import {DeviceRepo } from './repos';
import {GatewayRepo} from './repos';
import {UserRepo } from './repos';
import {DeviceLookupTableRepo } from './repos';
import {GatewayLookupTableRepo} from './repos';
import {SensorTypeRangeRepo} from './repos';
import {WipfliSynchQueueRepo} from './repos';
import {GatewayBackendLookupTableRepo} from './repos';
import {AuditHistoryRepo} from './repos';
import {EntityArchiveRepo} from './repos';
import {SynchQueueRepo} from './repos';
import {GroupRepo} from './repos';
import {AlertRepo} from './repos';
import {ChannelDefinitionRepo} from './repos';
import {UserLocationRepo} from './repos';
import {UserDeviceAssociationRepo} from './repos';
import {UserChannelRepo} from './repos';
import {UserNotificationRepo} from './repos';
import {UserAlertEventRepo} from './repos';
import {FirmwareDefinitionRepo, FirmwareReleaseRepo, FirmwareApprovalRepo, FirmwareLinkRepo, FirmwareLinkV2Repo} from './repos/firmware';
import {MCUFirmwareDefinitionRepo, MCUFirmwareReleaseRepo, MCUFirmwareLinkRepo} from './repos/mcu';
import {DeviceDuplicatesRepo} from './repos';
import {DataManufacturersRepo} from './repos';
import {SubscriptionsRepo} from './repos';
import {DataBatchesRepo} from './repos';
import {ReportingRepo} from './repos';
import {PermissionsRepo} from './repos';
import {ToolsRepo} from './repos';
import {AppNotificationRepo} from './repos';
import {NotesRepo} from './repos';
import {EmailTemplateRepo} from './repos';
import {CmsAlertRepo, CmsDataStreamRepo} from './repos';
import {EmailQueueRepo} from './repos';
import {DataStreamRepo} from './repos';
import {DataStreamCardRepo} from './repos';
import {EntityLogRepo} from './repos';
import {RequestLogRepo} from './repos';
import {PinGroupRepo, PinRepo} from './repos';

// . . . New Style Repos
import {LayoutModule} from './modules/layout.module';
import {AuthenticatedUserModule} from './modules/authenticated-user.module';
import {RouterModule, Routes} from '@angular/router';
import {D3Service} from 'd3-ng2-service';
import {AppBreadcrumbModule} from '@coreui/angular';


// Third Party Libraries
import {
  DataManagementGenericWidgetComponent,
  DataManagementWidgetComponent,
  DataManagementPSKUploadComponent,
  DataManagementVerificationCodeUploadWidget
} from './widgets/widget/data-management';
import {HttpClientModule} from '@angular/common/http';
import {AlertTriggerEventSetWidgetComponent} from './widgets/widget/alert/alert-trigger-event-set.widget.component';
import {AlertTriggerEventWidgetComponent} from './widgets/widget/alert/alert-trigger-event.widget.component';
import {AlertConstraintTriggerStateWidgetComponent} from './widgets/widget/alert/alert-constraint-trigger-state.widget.component';

import {FirmwareDefinitionViewComponent} from './components/firmware/definition/view.component';
import {FirmwareLinkViewComponent} from './components/firmware/link/view.component';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFireModule} from '@angular/fire';
import {NgxEditInlineModule} from 'ngx-edit-inline';
import {Chart} from 'chart.js';
import {ToasterModule, ToasterService} from 'angular2-toaster';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {HealthReportRepo} from './repos/health-report.repo';
import {WorkerReportWidgetComponent} from './widgets/widget/report/worker-report-widget.component';
import {NgbModule, NgbTimepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {EmailWidgetComponent} from './widgets/widget/email/email-widget.component';
import {EmailQueueWidgetComponent} from './widgets/widget/email/email-queue-entry-widget.component';
import {EmailTemplateSectionWidgetComponent} from './widgets/email-template';
import {DeviceFieldWidgetComponent} from './widgets/widget/device/device-field-widget.component';
import {DeviceDefinitionWidgetComponent} from './widgets/widget/device/device-type-widget.component';
import {RenderObjectComponent} from './components/misc/render-object.component';
import {SystemReportRepo} from './repos/system-report.repo';
import {SafePipe} from './pipes';
import {CachedSrcDirective} from './directives';


//===================================================
// Component Groups
//===================================================
const DEVICE_COMPONENTS = [
  DeviceShowComponent, DeviceDashboardComponent, DeviceAutoLoadLookupTableComponent, DeviceAutoLoadSensorTypeMappingComponent,
  DeviceDefinitionShowComponent, DeviceDefinitionListComponent, DeviceDefinitionViewComponent,
  DeviceFieldDefinitionListComponent, DeviceFieldDefinitionViewComponent, DeviceAutoLoadLookupTableEntryComponent,
  DeviceDuplicatesListComponent, DeviceReadingComponent, DeviceSimulatorComponent,
];

const GATEWAY_COMPONENTS = [
  GatewayListComponent, GatewayShowComponent, GatewayAutoLoadLookupTableComponent, DataManagementUploadComponent,
];

const SYSTEM_HEALTH_COMPONENTS = [
  SystemHealthWorkersComponent, SystemHealthUserReportComponent, SystemHealthTelemetryComponent, SystemHealthNodesComponent,
  SystemHealthOverviewComponent, SystemHealthServicesComponent, SystemHealthTimeScaleComponent
];

const FIRMWARE_COMPONENTS = [
  FirmwareDefinitionListComponent, FirmwareDefinitionShowComponent, FirmwareLinkListComponent, FirmwareLinkShowComponent,
  FirmwareOverviewComponent, FirmwareReleaseListComponent, FirmwareApprovalListComponent,
  FirmwareApprovalShowComponent, FirmwareReleaseShowComponent, FirmwareDefinitionViewComponent, FirmwareLinkViewComponent
];

const MCU_FIRMWARE_COMPONENTS = [
  MCUFirmwareDefinitionListComponent, MCUFirmwareLinkListComponent, MCUFirmwareOverviewComponent, MCUFirmwareReleaseListComponent
];

const MISC_COMPONENTS = [
  AdminHomePanelComponent,
  FormulaNodeComponent,
  FormulaNodeEditComponent,
  UserShowComponent,
  UserListComponent,
  SensorTypeRangeEntryComponent,
  DataManagementSynchQueueComponent,
  DataManagementEntityArchiveComponent,
  ManufacturersEntryComponent,
  DataManagementManufacturersComponent,
  SubscriptionEntryComponent,
  SubscriptionsComponent,
  ReportingAuditHistoryComponent,
  DataManagementWipfliSynchQueueComponent,
  DataManagementBatchesComponent,
  GroupListComponent,
  GroupShowComponent,
  GroupEntryComponent,
  ChannelDefinitionListComponent,
  ChannelDefinitionShowComponent,
  PermissionsEntryComponent,
  EntityNotesComponent,
  EntityNotesV2Component,
  DataStreamItemComponent,
  ShowObjectComponent,
  RenderObjectComponent,
  ReportingShowComponent,
  EmailTemplateListComponent,
  EmailTemplateShowComponent,
  EmailQueueShowComponent,
  EmailQueueListComponent,
  CmsAlertListComponent,
  CmsAlertShowComponent,
  CmsDataStreamListComponent,
  ToolsComponent,
  AppNotificationComponent,
  LoginComponent,
  LogoutComponent,
  PageNotFoundComponent,
  KeysPipe,
];

//===================================================
// Widgets
//===================================================
const EMAIL_WIDGETS = [
  EmailWidgetComponent,
  EmailQueueWidgetComponent,
  EmailTemplateWidgetComponent,
  EmailTemplateSectionWidgetComponent,
  EmailTemplateSectionEachWidgetComponent,
  EmailTemplateSectionIfThenWidgetComponent,
  EmailTemplateSectionSectionWidgetComponent,
  EmailTemplateSectionSelectorWidgetComponent
];

const FILE_UPLOAD_WIDGETS = [
  DataManagementWidgetComponent,
  DataManagementPSKUploadComponent,
  DataManagementVerificationCodeUploadWidget,
  DataManagementGenericWidgetComponent,
];

const DATA_STREAM_WIDGETS = [
  EmbedCmsDataStreamComponent, EmbedCmsAlertComponent,
  EmbedDsCardComponent, EmbedDsDeviceCardComponent, EmbedDsTimeCardComponent, EmbedDsDiagnosticCardComponent,
  EmbedDsMediaCardComponent, EmbedDsNwsCardComponent, EmbedDsNwsCardComponent, EmbedDsWeatherCardComponent, EmbedDsGenericCardComponent,
  WidgetEmbedDSCardComponent
];

const REPORT_WIDGETS = [
  ReportWidgetComponent, HealthReportWidgetComponent, WorkerReportWidgetComponent
];

const DEVICE_WIDGETS = [
  EmbedDeviceComponent, EmbedDeviceTypeComponent, EmbedDeviceFieldComponent, EmbedDeviceRangeComponent,
  EmbedDeviceAttributesComponent, EmbedDeviceTypeFieldEntryComponent
];

const FIRMWARE_WIDGETS = [
  EmbedFirmwareDefinitionComponent, EmbedFirmwareLinkComponent, EmbedFirmwareLinkV2Component,
  EmbedFirmwareApprovalComponent
];

const VNEXT_FIRMWARE_WIDGETS = [
  FirmwareApprovalWidgetComponent, FirmwareDefinitionWidgetComponent,
  FirmwareLinkWidgetComponent, FirmwareReleaseWidgetComponent,
  FirmwareTargetWidgetComponent, FirmwareWidgetComponent, FirmwareGenericWidgetComponent
]

const GATEWAY_WIDGETS = [
  EmbedGatewayComponent,
  EmbedGatewayFirmwareComponent,
  EmbedGatewayFirmwareReportComponent,
  EmbedWifiAndMcuReportComponent,
  EmbedGatewayMcuReportComponent
];


const LOG_COMMON_WIDGETS = [
  LogTableHeaderWidgetComponent
];

const LOG_ALERT_WIDGETS = [
  AlertLogDetailsWidgetComponent,
  AlertGenericLogWidgetComponent,
  AlertCreatedLogDetailsWidgetComponent,
  AlertDeletedLogDetailsWidgetComponent,
  AlertDeregisterCallbackLogDetailsWidgetComponent,
  AlertLoadErrorLogDetailsWidgetComponent,
  AlertLoadLogDetailsWidgetComponent,
  AlertPrepareCallbacksLogDetailsWidgetComponent,
  AlertRecoveredErrorLogDetailsWidgetComponent,
  AlertRecoveredLogDetailsWidgetComponent,
  AlertRefreshCallbackLogDetailsWidgetComponent,
  AlertRefreshErrorLogDetailsWidgetComponent,
  AlertRefreshLogDetailsWidgetComponent,
  AlertRegisterCallbackLogDetailsWidgetComponent,
  AlertSetDisabledLogDetailsWidgetComponent,
  AlertSetEnabledLogDetailsWidgetComponent,
  AlertTriggeredErrorLogDetailsWidgetComponent,
  AlertTriggeredLogDetailsWidgetComponent,
  AlertUnchangedLogDetailsWidgetComponent,
  AlertUnloadedLogDetailsWidgetComponent,
  AlertUpdatedLogDetailsWidgetComponent
];

const LOG_GATEWAY_WIDGETS = [
  GatewayWakeLogDetailsWidgetComponent,
  GatewayExtractBinaryErrorLogDetailsWidgetComponent,
  GatewayExtractBinaryLogDetailsWidgetComponent,
  GatewayReportBinaryLogDetailsWidgetComponent,
  GatewayStageOneLogDetailsWidgetComponent,
  GatewayLogDetailsWidgetComponent
];

const LOG_DEVICE_WIDGETS = [
  DeviceStageOneLogDetailsWidgetComponent,
  DeviceLogDetailsWidgetComponent
]

const LOG_DATA_STREAM_WIDGETS = [
  DataStreamFetchLogDetailsWidgetComponent,
  DataStreamLogDetailsWidgetComponent
];

const LOG_WIDGETS = [
  LogDetailsWidgetComponent,
  LogDetailsWidgetComponent,
  LogSetWidgetComponent, LogWidgetComponent,
  AlertLogWidgetComponent,
  DataStreamLogWidgetComponent,
  DeviceLogWidgetComponent,
  GatewayLogWidgetComponent,
  NotificationLogWidgetComponent,
  UserLogWidgetComponent,
  GenericLogWidgetComponent,
  EmbedRequestLogComponent, EmbedRequestLogsComponent,
  EmbedEntityLogExtractionWarnComponent,
  EmbedEntityLogReportBinaryComponent,
  EmbedEntityLogReportBinaryDetailsComponent,
  EmbedEntityLogStageOneDataComponent,
  EmbedEntityLogStreamComponent,
  EmbedEntityLogWakeComponent,
  WidgetEmbedLogSubTypeComponent,
  LogDetailsGenericWidgetComponent,
  ...LOG_COMMON_WIDGETS,
  ...LOG_GATEWAY_WIDGETS,
  ...LOG_DEVICE_WIDGETS,
  ...LOG_DATA_STREAM_WIDGETS,
  ...LOG_ALERT_WIDGETS
];

const MCU_FIRMWARE_WIDGETS = [
  EmbedMCUFirmwareDefinitionComponent, EmbedMCUFirmwareLinkComponent, EmbedMCUFirmwareReleaseComponent, EmbedFirmwareTarget
];

const ALERT_WIDGETS = [
  EmbedAlertContactFacadeComponent,
  EmbedAlertHumidityFacadeComponent,
  EmbedAlertLightningFacadeComponent,
  EmbedAlertMotionFacadeComponent,
  EmbedAlertOnOffFacadeComponent,
  EmbedAlertProbeFacadeComponent,
  EmbedAlertRainfallFacadeComponent,
  EmbedAlertTemperatureFacadeComponent,
  EmbedAlertThermometerFacadeComponent,
  EmbedAlertWetDryFacadeComponent,
  EmbedAlertWindFacadeComponent,
  EmbedNotificationSettingsComponent
];

const MODAL_WIDGET_COMPONENTS = [
  ModalWidgetComponent,
  GenericModalWidgetComponent,
  ConfirmModalWidgetComponent,
  CreateModalWidgetComponent,
  EditModalWidgetComponent,
  MiniModalWidgetComponent,
  BareModalWidgetComponent
]

const LOGICAL_WIDGET_COMPONENTS = [
  LogicalWidgetComponent, BatchLogicalWidgetComponent, ManufacturerLogicalWidgetComponent, SeriesLogicalWidgetComponent, DeviceTypeLogicalWidgetComponent,
  FirmwareDefinitionLogicalWidgetComponent,
  FirmwareGenericLogicalWidgetComponent,
  FirmwareTargetLogicalWidgetComponent,
  FirmwareTargetSetLogicalWidgetComponent,
  FirmwareLogicalWidgetComponent, PinGroupLogicalWidgetComponent, WeatherCategoryLogicalWidgetComponent, DateTimePickerLogicalWidgetComponent, DateTimeRangePickerLogicalWidgetComponent,
  ChannelLogicalWidgetComponent,
  AlertFacadeSelectorLogicalWidgetComponent, LogicalAlertWidgetComponent,
]

const ENTITY_SET_WIDGET_COMPONENTS = [
  EntitySetFooterWidgetComponent,
  EntitySetHeaderWidgetComponent,
  EntitySetListWidgetComponent,
]

const WIDGET_COMPONENTS = [
  WidgetEmbedComponent,
  EmbedBatchComponent,
  EmbedDefaultComponent,
  WidgetSpinnerComponent,
  SpinnerComponent,
  PageHeaderComponent,
  WidgetTimeStampComponent,
  SubscriptionDefinitionWidgetComponent,
  SubscriptionDefinitionsWidgetComponent,
  NoteWidgetComponent,
  ...VNEXT_FIRMWARE_WIDGETS,
  ...LOGICAL_WIDGET_COMPONENTS,
  ...ALERT_WIDGETS,
  ...DATA_STREAM_WIDGETS,
  ...DEVICE_WIDGETS,
  ...FIRMWARE_WIDGETS,
  ...GATEWAY_WIDGETS,
  ...LOG_WIDGETS,
  ...MCU_FIRMWARE_WIDGETS,
  ...FILE_UPLOAD_WIDGETS,
  ...MODAL_WIDGET_COMPONENTS,
  ...REPORT_WIDGETS,
  ...ENTITY_SET_WIDGET_COMPONENTS,
  ...EMAIL_WIDGETS
];


const USER_WIDGET_COMPONENTS = [
  PermissionWidgetComponent, UserWidgetComponent, UserSettingsWidgetComponent, UserDevicesWidgetComponent, UserChannelWidgetComponent, UserPreferencesWidgetComponent,
  UserExtendedWidgetComponent,
  UserExtendedEffectiveSubscriptionWidgetComponent,
  UserExtendedSubscriptionWidgetComponent,
  UserExtendedSubscriptionPaymentWidgetComponent,
  UserExtendedSubscriptionDetailsWidgetComponent,
];

const ALERT_WIDGET_COMPONENTS = [
  AlertWidgetComponent,
  AlertFacadeWidgetComponent,
  AlertFacadeDefaultWidgetComponent,
  AlertTriggerStateObjectWidgetComponent,
  AlertStatusFacadeWidgetComponent,
  AlertConstraintStatusWidgetComponent, AlertConstraintStatusSetWidgetComponent,
  AlertTriggerEventSetWidgetComponent,
  AlertTriggerEventWidgetComponent,
  AlertConstraintTriggerStateWidgetComponent
];

const LOGICAL_WIDGETS = [
  ModalWidgetComponent,
  DeviceSimulatorWidgetComponent,DeviceSetLogicalWidgetComponent,
  DeviceLogicalWidgetComponent, DeviceFeedWidgetComponent, DeviceReadingWidgetComponent,
  ExtendedDeviceLogicalWidgetComponent, ExtendedDeviceChartLogicalWidgetComponent, ExtendedDeviceReadingLogicalWidgetComponent,
  DeviceFieldSimulatorWidgetComponent,
  LogicalAlertWidgetComponent,
]

const VNEXT_WIDGET_COMPONENTS = [
  WidgetComponent,
  WidgetToggleComponent, WidgetSetComponent, AppengineWidgetSetComponent, GenericWidgetSetComponent,
  DeviceRangeWidgetComponent,DeviceWidgetComponent,DeviceEntityWidgetComponent,DeviceLinkWidgetComponent,DeviceTargetWidgetComponent,
  DeviceFieldWidgetComponent,
  DeviceDefinitionWidgetComponent,
  SubscriberObjectSetWidgetComponent,
  SubscriberObjectWidgetComponent,
  CallbackObjectSetWidgetComponent,
  CallbackObjectWidgetComponent,
  RuntimeConfigurationObjectWidgetComponent,
  PinWidgetComponent, PinGroupWidgetComponent,
  BatchWidgetComponent,
  ManufacturerWidgetComponent,
  ...LOGICAL_WIDGETS,
  ...ALERT_WIDGET_COMPONENTS,
  ...USER_WIDGET_COMPONENTS,
];

//===================================================
// Repos
//===================================================
const ADMIN_REPOS = [
  AdminRepo
]

const FIRMWARE_REPOS = [
  FirmwareDefinitionRepo,
  FirmwareLinkRepo,
  FirmwareLinkV2Repo,
  FirmwareReleaseRepo,
  FirmwareApprovalRepo,
];

const MCU_FIRMWARE_REPOS = [
  MCUFirmwareDefinitionRepo, MCUFirmwareReleaseRepo, MCUFirmwareLinkRepo
];

const USER_REPOS = [
  UserRepo,
  UserLocationRepo,
  UserDeviceAssociationRepo,
  UserChannelRepo,
  UserNotificationRepo,
  UserAlertEventRepo,
];

const DEVICE_REPOS = [
  DeviceDefinitionRepo,
  DeviceFieldDefinitionRepo,
  DeviceLookupTableRepo,
  DeviceRepo,
  DeviceDuplicatesRepo,
];

const GATEWAY_REPOS = [
  GatewayRepo,
  GatewayLookupTableRepo,
  GatewayBackendLookupTableRepo,
];

const REPOS = [
  GenericRepo,
  ...ADMIN_REPOS,
  ...DEVICE_REPOS,
  ...GATEWAY_REPOS,
  ...USER_REPOS,
  ...FIRMWARE_REPOS,
  ...MCU_FIRMWARE_REPOS,
  BatchRepo,
  GroupRepo,
  SensorTypeRangeRepo,
  WipfliSynchQueueRepo,
  AuditHistoryRepo,
  EntityArchiveRepo,
  SynchQueueRepo,
  AlertRepo,
  PermissionsRepo,
  ChannelDefinitionRepo,
  ToolsRepo,
  AppNotificationRepo,
  EmailQueueRepo,
  ReportingRepo,
  DataManufacturersRepo,
  SubscriptionsRepo,
  EmailTemplateRepo,
  CmsAlertRepo,
  CmsDataStreamRepo,
  DataBatchesRepo,
  NotesRepo,
  DataStreamCardRepo,
  EntityLogRepo,
  RequestLogRepo,
  DataStreamRepo, SubscriptionDefinitionRepo,
  PinGroupRepo, PinRepo,
  HealthReportRepo,
  SystemReportRepo
];

const BOOTSTRAP_ANGULAR = [
  NgbTimepickerModule
];

@NgModule({
  declarations: [
    AppComponent,
    ...GATEWAY_COMPONENTS,
    ...DEVICE_COMPONENTS,
    ...FIRMWARE_COMPONENTS,
    ...MCU_FIRMWARE_COMPONENTS,
    ...SYSTEM_HEALTH_COMPONENTS,
    ...MISC_COMPONENTS,
    // Widgets,
    ...VNEXT_WIDGET_COMPONENTS,
    ...WIDGET_COMPONENTS,
    SafePipe,
    CachedSrcDirective
  ],
  imports: [
    BrowserModule,
    CommonModule,
    NgPipesModule,
    BrowserAnimationsModule,
    CollapseModule.forRoot(),
    HttpClientModule,
    CookieModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    ...BOOTSTRAP_ANGULAR,
    MyDatePickerModule,
    SelectDropDownModule,
    NgxEditInlineModule,
    ChartsModule,
    TabsModule.forRoot(),
    NgSelectModule,
    RouterModule.forRoot(
      [
        {path: '', redirectTo: '/login', pathMatch: 'full'},
        {path: 'login', component: LoginComponent},
        {path: 'logout', component: LogoutComponent},
        {path: 'portal', canActivate: [AuthService], component: AuthenticatedUserModule},
        {path: '**', component: PageNotFoundComponent}
      ]
    ),
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    LayoutModule,
    AuthenticatedUserModule,
    PersistenceModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    DragulaModule.forRoot(),
    ToasterModule.forRoot(),
    AppBreadcrumbModule.forRoot(),
    TooltipModule,
    PlotlyModule,
    NgbModule
  ],
  providers: [
    // GatewayService,
    D3Service,
    AngularFireAuth,
    FirebaseAuthService,
    AuthService,
    UserPreferencesService,
    CacheService,
    ToasterService,
    ...REPOS,
    {provide: APP_BASE_HREF, useValue: '/'}
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
