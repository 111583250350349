
import { Component, Inject, Input, NgZone } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location }               from '@angular/common';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { EmailQueueEntity } from '../../../entities/data/email-queue.entity';
import { EmailQueueRepo } from '../../../repos/data/email-queue.repo';
import {AppengineEntityList, ElixirEntityList} from '../../../noizu/structs';

@Component({
  template: `
    <page-header [section]="'email-queue'">
      <page-title>Email Queue</page-title>
      <page-blurb>View email queue status if active / inactive / pending, and initiate an action to manage / send / resend email queue requests.</page-blurb>
    </page-header>


    <new-spinner *ngIf="!queue" [size]="'sm'"></new-spinner>
    <div *ngIf="queue" class="row">
      <div class="col">
        <generic-set-widget [entity]="queue" [layout]="'table'" [options]="queue_list_options"></generic-set-widget>
      </div>
    </div>

  `,
})
export class EmailQueueListComponent {
  public queue: ElixirEntityList;
  public queue_list_options = {};
  constructor(public repo: EmailQueueRepo, public zone: NgZone) {
    this.queue_list_options = {'header': true, 'time_range_query': true,  'headers': ['identifier',  'created_on', 'state', 'template', 'email', 'actions'], 'nested': {}};
    this.zone.run(
      () => {
        this.repo.getListPromise().then((u:any) => {
          this.queue = u;
          this.queue.repo = this.repo;
        });
      }
    )
  }
}
