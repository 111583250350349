
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { DomainObjectRepo } from '../../noizu/domain-object-repo';
import { FirebaseAuthService }        from '../../noizu/services/firebase-auth.service';
import { AppengineRepo }      from '../appengine.repo';
import { DeviceDefinitionEntity }      from '../../entities/device/definition.entity';

@Injectable()
export class DeviceDefinitionRepo extends AppengineRepo {
  public _kind = "sensor-types";
  public _singular = "sensor-type";

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  entity(record) {
    return new DeviceDefinitionEntity(this.client, this.auth, record)
  }

  filterOptions() {
    return "name, internalName, handle, identifier, description contains filter"
  }
}
