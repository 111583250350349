
import { Component, Inject, Input, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location }               from '@angular/common';

import { FirmwareDefinitionEntity } from '../../../entities/firmware/definition.entity';
import {FirmwareApprovalRepo, FirmwareDefinitionRepo} from '../../../repos';
import {FirmwareApprovalEntity} from '../../../entities';

@Component({
  template: `
    <div class="row my-3">
      <div class="col text-left "><button (click)="back($event)" class="btn btn-primary">Back</button></div>
    </div>

    <div *ngIf="entity">
      <firmware-definition-widget [entity]="entity" [layout]="'full'" [options]="{}" ></firmware-definition-widget>
    </div>
  `
})
export class FirmwareDefinitionViewComponent implements OnInit {
  public entity: FirmwareDefinitionEntity = null;
  public subscription: any;

  constructor(public activatedRoute: ActivatedRoute, public repo: FirmwareDefinitionRepo, private locationService: Location) {

  }

  back(e) {
    e.preventDefault()
    this.locationService.back()
  }

  ngOnInit() {
    this.subscription = this.activatedRoute.params.subscribe(
      (params: any)  => {
        if ('id' in params) {
          this.repo.getEntityPromise(params['id']).then((entity: FirmwareDefinitionEntity) => this.entity = entity);
        }
      }
    );
  }
}
