
import { Component, Inject, Input, NgZone } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location }               from '@angular/common';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { EntityArchiveEntity } from '../../entities/entity-archive.entity';
import { EntityArchiveRepo } from '../../repos/entity-archive.repo';

@Component({
  templateUrl: './entity-archive.component.html',
})
export class DataManagementEntityArchiveComponent {
  public entries: Array<EntityArchiveEntity>;

  constructor(public repo: EntityArchiveRepo, public zone: NgZone) {
    this.zone.run(
      () => {
        this.repo.getListPromise().then((u: any) => {this.entries = u;});
      }
    )
  }
}
