import {Component, Input, Inject, Output, EventEmitter} from '@angular/core';
import {DataManagementLogicalWidgetEnum, FirmwareWidgetEnum} from '../../../enums/widget';
import {
  PSKUploadEntity, VerificationCodeUploadEntity
} from '../../../entities/';
import {ToasterService} from 'angular2-toaster';
import {DataManagementWidgetEventStruct} from './data-management-widget-event.struct';

@Component({
  selector: 'data-management-widget',
  template: `
    <div class="data-management-widget" *ngIf="!entity">
      <widget-spinner></widget-spinner>
    </div>
    <div class="data-management-widget" *ngIf="entity">
      <div [ngSwitch]="entity.data_management_logical_widget_type()">
        <data-management-psk-upload-widget *ngSwitchCase="WidgetType.DATA_MANAGEMENT_LOGICAL_WIDGET__PSK" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></data-management-psk-upload-widget>
        <data-management-verification-code-upload-widget *ngSwitchCase="WidgetType.DATA_MANAGEMENT_LOGICAL_WIDGET__VERIFICATION_CODE" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></data-management-verification-code-upload-widget>
        <data-management-generic-widget *ngSwitchCaseDefault (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></data-management-generic-widget>
      </div>
    </div>

  `
})
export class DataManagementWidgetComponent {
  @Input() entity: PSKUploadEntity | VerificationCodeUploadEntity | null = null;
  @Input() options: any = null;
  @Input() layout = 'standard';

  @Output() widgetEvent = new EventEmitter<DataManagementWidgetEventStruct>();
  forwardEvent(widgetEvent: DataManagementWidgetEventStruct) {
    this.widgetEvent.emit(widgetEvent);
  }

  public  WidgetType = DataManagementLogicalWidgetEnum;

  constructor(public toasterService: ToasterService) {
  }
}
