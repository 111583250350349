<page-header [section]="'firmware-approvals'">
  <page-title>Approvals</page-title>
  <page-blurb> </page-blurb>
</page-header>

<spinner *ngIf="!entries"></spinner>

<div *ngIf="entries">

  <div class="m-0 p-0">
    <div class="card">
      <div class="card-block">
        <div class="div-table-th rounded mb-2 p-1">
          <div class="row">
            <div class="col">Approval #</div>
            <div class="col">Reference</div>
            <div class="col">Comment</div>
            <div class="col">Approver</div>
            <div class="col">Approved On</div>
          </div>
        </div>

        <div class="row" *ngFor="let entry of entries">
          <widget-embed [entity]="entry" [options]="{}"> </widget-embed>
        </div>
      </div>
    </div>

    <!-- <div class="row" *ngFor="let entry of entries">
      <div class="col-12">
        <widget-embed [entity]="entry" [options]="{}"> </widget-embed>
      </div> -->
  </div>
</div>
