
    <ng-container *ngIf="entity">
      <ng-container [ngSwitch]="layout">

        <ng-container *ngSwitchCase="'shadowbox'">




          <div class="row justify-content-center" *ngIf="entity">
            <div class="col-auto">
              <div class="row">
                <div class="col text-left">
                  <ngb-datepicker #tdp [(ngModel)]="entity.select_date"></ngb-datepicker>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-auto text-left">
                  <span><ngb-timepicker #ttp [meridian]="true" [(ngModel)]="entity.select_time"></ngb-timepicker></span>
                </div>
              </div>
            </div>
          </div>




        </ng-container>
        <ng-container *ngSwitchDefault>
          <span>[DateTime Picker]</span>
        </ng-container>
      </ng-container>
    </ng-container>

    <!--                                                        -->
    <!--                    Pop-Up Menu                         -->
    <!--                                                        -->
    <ng-template #pickerModal>
      <modal-widget [entity]="currentModal" [layout]="'shadow_box'" [options]="{}"></modal-widget>
    </ng-template>


  