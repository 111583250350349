
    <div class="card card-accent-dark alert-facade-lightning-widget">
      <div class="card-header">Lightning Alert</div>
      <div class="card-body">
        <div class="row"><div class="col-4">Device</div><div class="col-8"><a [routerLink]="'/portal/devices/show/' + entity.device">{{entity.device}}</a></div></div>
        <div class="row"><div class="col-4">Association</div><div class="col-8">{{entity.deviceAssociation}}</div></div>
        <div class="row"><div class="col-12"><hr/></div></div>
        <div class="row">
          <pre>{{entity|json}}</pre>
        </div>
      </div>
    </div>
    