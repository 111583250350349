import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToasterService} from 'angular2-toaster';
import {AlertEntity} from '../../../entities/alert.entity';
import {WidgetComponent} from '../../widget.component';
import {WidgetBase} from '../widget-base';
import {UserEntity, UserPermissionEntity} from '../../../entities';
import {NotificationSettingsWidgetEventStruct} from '../notification-settings';
import {PermissionWidgetEventStruct} from './permission-widget-event.struct';

@Component({
  selector: 'permission-widget',
  template: `

    <div class="card shadow" *ngIf="entity">
      <div class="card-header">
        <div class="row">
          <div class="col">
            Permission Widget
            <span *ngIf="options" class="float-right"><i (click)="toggleExpand()" class="fa fa-sm" [ngClass]="{'fa-chevron-down': !options.expand, 'fa-chevron-up': options.expand}"> </i></span>
          </div>
        </div>
      </div>
      <div class="card-body p-1 m-0" *ngIf="entity && expandWidget()">
              <div class="row" *ngFor="let perm of effective; let i = index">
                <div class="col-1">#{{i}}</div>
                <div class="col"><b>{{perm.name}}</b></div>
                <div class="col"><span class="btn-pill pl-2 pr-2" [ngClass]="{'bg-success' : perm.grant == 'granted'}" >{{perm.grant}}</span></div>
              </div>
      </div>



    </div>
  `
})
export class PermissionWidgetComponent extends WidgetBase implements OnInit {
  public editMode = false;
  @Input() layout: string = null;
  @Input() entity: UserPermissionEntity;
  @Input() options: any;
  @Output() widgetEvent = new EventEmitter<PermissionWidgetEventStruct>();
  forwardEvent(widgetEvent: PermissionWidgetEventStruct) {
    this.widgetEvent.emit(widgetEvent);
  }
  public effective: any = [];

  constructor(public toasterService: ToasterService) {
    super();
  }

  ngOnInit() {
    if (this.entity) {
      let m = this.entity.effective_permissions;
      this.effective = [];
      for (let k in m) {
        this.effective.push({name: k, grant: m[k]});
      }

    }
  }

}
