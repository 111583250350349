
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { DomainObjectRepo } from '../../noizu/domain-object-repo';
import { FirebaseAuthService }        from '../../noizu/services/firebase-auth.service';
import { ElixirRepo }      from '../elixir.repo';
import { WipfliSynchQueueEntryEntity }      from '../../entities/wipfli/synch-queue-entry.entity';

@Injectable()
export class WipfliSynchQueueRepo extends ElixirRepo {
  public _kind = "wipfli/synch-queue";

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  entity(record) {
    return new WipfliSynchQueueEntryEntity(this.client, this.auth, record)
  }
}
