import { Component, Input, Inject } from '@angular/core';
import {FirmwareDefinitionEntity} from '../../../entities/firmware/definition.entity';

@Component({
  selector: 'embed-firmware-definition',
  template: `
    <firmware-definition-widget [entity]="entity" [options]="options" [layout]="layout"></firmware-definition-widget>

    `
})
export class EmbedFirmwareDefinitionComponent {
  @Input() entity: FirmwareDefinitionEntity = null;
  @Input() options: any = null;
  @Input() layout: string = null;

}
