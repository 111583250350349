import {HttpClient} from '@angular/common/http';
import {FirebaseAuthService} from '../../../noizu/services/firebase-auth.service';
import {GatewayLogWidgetEnum, LogWidgetEnum} from '../../../enums';
import {EntityLogEntity} from '../entity-log.entity';

export class GatewayEntityLogEntity extends EntityLogEntity {
  public log_type: GatewayLogWidgetEnum = GatewayLogWidgetEnum.GATEWAY_LOG_WIDGET__GENERIC;

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);

    switch(this.topic) {



      case 'load':
        this.log_type =GatewayLogWidgetEnum.GATEWAY_LOG_WIDGET__LOAD;
        break;
      case 'load_error':
        this.log_type =GatewayLogWidgetEnum.GATEWAY_LOG_WIDGET__LOAD_ERROR;
        break;
      case 'show_geo':
        this.log_type =GatewayLogWidgetEnum.GATEWAY_LOG_WIDGET__SHOW_GEO;
        break;
      case 'update_geo':
        this.log_type =GatewayLogWidgetEnum.GATEWAY_LOG_WIDGET__UPDATE_GEO;
        break;


      case 'wake':
        this.log_type =GatewayLogWidgetEnum.GATEWAY_LOG_WIDGET__WAKE;
        break;
      case 'wake_error':
        this.log_type =GatewayLogWidgetEnum.GATEWAY_LOG_WIDGET__WAKE_ERROR;
        break;



      case 'report_binary':
        this.log_type = GatewayLogWidgetEnum.GATEWAY_LOG_WIDGET__REPORT_BINARY;
        break;
      case 'extract_binary':
        this.log_type = GatewayLogWidgetEnum.GATEWAY_LOG_WIDGET__EXTRACT_BINARY;
        break;
      case 'extract_binary_error':
        this.log_type = GatewayLogWidgetEnum.GATEWAY_LOG_WIDGET__EXTRACT_BINARY_ERROR;
        break;


      case 'mcu_details':
        this.log_type =GatewayLogWidgetEnum.GATEWAY_LOG_WIDGET__MCU_DETAILS;
        break;
      case 'notifications':
        this.log_type =GatewayLogWidgetEnum.GATEWAY_LOG_WIDGET__NOTIFICATIONS;
        break;

      case 'payload':
        this.log_type = GatewayLogWidgetEnum.GATEWAY_LOG_WIDGET__STAGE_ONE;
        break;

      default:
        this.log_type = GatewayLogWidgetEnum.GATEWAY_LOG_WIDGET__GENERIC;
    }

  } // end constructor






  log_widget_type(): LogWidgetEnum | null {
    return LogWidgetEnum.LOG_WIDGET__GATEWAY;
  }



  gateway_log_widget_type(): GatewayLogWidgetEnum  {
    return this.log_type;
  }



  public brief() {
    if (!this._brief) {

      switch (this.gateway_log_widget_type()) {
        case GatewayLogWidgetEnum.GATEWAY_LOG_WIDGET__LOAD:
          this._brief = 'Gateway Load';
          break;

        case GatewayLogWidgetEnum.GATEWAY_LOG_WIDGET__LOAD_ERROR:
          this._brief = 'Gateway Load (Error)';
          break;

        case GatewayLogWidgetEnum.GATEWAY_LOG_WIDGET__SHOW_GEO:
          this._brief = 'Show Geo';
          break;

        case GatewayLogWidgetEnum.GATEWAY_LOG_WIDGET__WAKE:
          this._brief = 'Wake';
          break;
        case GatewayLogWidgetEnum.GATEWAY_LOG_WIDGET__UPDATE_GEO:
          this._brief = 'Set GEO';
          break;

        case GatewayLogWidgetEnum.GATEWAY_LOG_WIDGET__MCU_DETAILS:
          this._brief = 'MCU OTA Details';
          break;

        case GatewayLogWidgetEnum.GATEWAY_LOG_WIDGET__NOTIFICATIONS:
          this._brief = `Notifications (${this.subtopic})`;
          break;

        case GatewayLogWidgetEnum.GATEWAY_LOG_WIDGET__STAGE_ONE:
          this._brief = 'Stage One';
          break;

        case GatewayLogWidgetEnum.GATEWAY_LOG_WIDGET__REPORT_BINARY:
          this._brief = 'Report Binary';
          break;

        case GatewayLogWidgetEnum.GATEWAY_LOG_WIDGET__EXTRACT_BINARY:
          this._brief = 'Extract Binary';
          break;

        case GatewayLogWidgetEnum.GATEWAY_LOG_WIDGET__EXTRACT_BINARY_ERROR:
          this._brief = `Extract Binary (error) - ${this.subtopic}`;
          break;

        default:
          this._brief = 'Gateway (Other)';
      }
    }
    return this._brief;
  }
} // end Package
