
    <div *ngIf="entity">
      <div class="card mt-2">
        <div class="card-header">Targets <div class="card-header-actions"><i *ngIf="options?.edit" (click)="addTarget($event)" class="fa fa-2x fa-plus text-success"></i></div></div>
        <div class="card-body p-1 m-1">
          <div *ngIf="entity.selected">
            <div class="row" *ngFor="let target of entity.selected; let i = index">
              <div class="col-12">
                <firmware-target-widget (widgetEvent)="eventHandler($event, i)" [entity]="target" [layout]="'inline'" [options]="options"></firmware-target-widget>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  