
    <div [ngSwitch]="layout">

      <div *ngSwitchCase="'thumb'" class="card text-white" [ngClass]="releaseStatusStyle()">
        <div *ngIf="!entity" class="card-header p-1">Release| (Loading)</div>
        <div *ngIf="!entity" class="card-body bg-white">
          <widget-spinner></widget-spinner>
        </div>
        <div *ngIf="entity" class="card-header p-1 text-center">Release| {{entity.release_name}}</div>
        <div *ngIf="entity" class="card-body text-black-50 bg-white p-1">
          <div class="row">
            <div class="col-12 text-center">(Status {{releaseStatus()}})</div>
          </div>
          <div class="row" *ngIf="entity.status">
            <div class="col-12" >
          <canvas baseChart class="chart"
                  [data]="releaseRollOutPieData"
                  [labels]="releaseRollOutPieLabels"
                  [legend]="false"
                  [chartType]="'pie'"
                  (chartHover)="chartHovered('releaseRollout', $event)"
                  (chartClick)="chartClicked('releaseRollout', $event)"></canvas>
            </div>
          </div>
        </div>
      </div>

      <div *ngSwitchCase="'full-screen'">
        [Pending]
      </div>

      <div *ngSwitchDefault class="card">
        <div *ngIf="!entity" class="card-header text-white p-1" [ngClass]="releaseStatusStyle()">Release| (Loading)</div>
        <div *ngIf="!entity" class="card-body bg-white text-body">
          <widget-spinner></widget-spinner>
        </div>
        <div *ngIf="entity" class="card-header text-white p-1" [ngClass]="releaseStatusStyle()">Release| {{entity.release_name}} (Status {{releaseStatus()}})</div>
        <div *ngIf="entity" class="card-body bg-white text-body p-1">



          <div class="row">
            <div class="col-7">
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body bg-white text-body">
                      <div class="row">
                        <div class="col-5"><strong>Approval:</strong></div><div class="col-7"><a href="/firmware/approvals/{{entity.approval_entity?.sref()}}">{{entity.approval_entity?.sref()}}</a></div>
                      </div>
                      <div class="row">
                        <div class="col-5"><strong>Firmware:</strong></div><div class="col-7"><a href="/firmware/definitions/{{entity.firmware_entity?.sref()}}">{{entity.firmware_entity?.sref()}}</a> ({{entity.firmware_entity?.firmwareVersion}})</div>
                      </div>
                      <div class="row">
                        <div class="col-5"><strong>Alternate:</strong></div><div class="col-7"><a href="/firmware/definitions/{{entity.rollback_entity?.sref()}}">{{entity.rollback_entity?.sref()}}</a> ({{entity.rollback_entity?.firmwareVersion}})</div>
                      </div>
                      <div class="row" *ngIf="entity.roll_out_report?.release_status?.managed_firmware_links">
                        <div class="col-5"><strong>Firmware Associations:</strong></div>
                        <div class="col-7">
                        <span *ngFor="let link of entity.roll_out_report?.release_status?.managed_firmware_links">
                            <a href="/firmware/links/{{link}}">{{link}}</a>
                        </span>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body bg-white text-body">
                      <div class="row">
                        <div class="col-6"><strong>Worker Status:</strong></div><div class="col-6">{{entity.roll_out_report?.release_status?.status}}</div>
                      </div>

                      <div class="row">
                        <div class="col-6"><strong>Current Stage:</strong></div><div class="col-6">{{entity.roll_out_report?.release_status?.active_group}}</div>
                      </div>

                      <div class="row">
                        <div class="col-6"><strong>Active Units:</strong></div><div class="col-6">{{entity.roll_out_report?.managed_units}}</div>
                      </div>
                      <div class="row">
                        <div class="col-6"><strong>Control Units:</strong></div><div class="col-6">{{entity.roll_out_report?.control_units}}</div>
                      </div>


                      <div class="row">
                        <div class="col-6">Started:</div><div class="col-6">(...)</div>
                      </div>
                      <div class="row">
                        <div class="col-6">Completed:</div><div class="col-6">(...)</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-header bg-secondary">Group Assignment22</div>
                    <div class="card-body bg-white mt-1 pt-1">
                      <div class="row mb-1"><div class="col-6"><strong class="text-black-50">Control Group</strong></div><div class="col-6">{{entity.roll_out_report?.managed_workers?.group_totals["control"]| json}}</div></div>
                      <div class="row"><div class="col-6"><strong [ngClass]="{'text-black-50': (entity.roll_out_report?.release_status?.active_group  && entity.roll_out_report?.release_status?.active_group < 1)}">Stage 1 Group</strong></div><div class="col-6">{{entity.roll_out_report?.managed_workers?.group_totals["1"]| json}}</div></div>
                      <div class="row"><div class="col-6"><strong [ngClass]="{'text-black-50': (entity.roll_out_report?.release_status?.active_group  && entity.roll_out_report?.release_status?.active_group < 2)}">Stage 2 Group</strong></div><div class="col-6">{{entity.roll_out_report?.managed_workers?.group_totals["2"]| json}}</div></div>
                      <div class="row"><div class="col-6"><strong [ngClass]="{'text-black-50': (entity.roll_out_report?.release_status?.active_group  && entity.roll_out_report?.release_status?.active_group < 3)}">Stage 3 Group</strong></div><div class="col-6">{{entity.roll_out_report?.managed_workers?.group_totals["3"]| json}}</div></div>
                      <div class="row"><div class="col-6"><strong [ngClass]="{'text-black-50': (entity.roll_out_report?.release_status?.active_group  && entity.roll_out_report?.release_status?.active_group < 4)}">Stage 4 Group</strong></div><div class="col-6">{{entity.roll_out_report?.managed_workers?.group_totals["4"]| json}}</div></div>
                      <div class="row"><div class="col-6"><strong [ngClass]="{'text-black-50': (entity.roll_out_report?.release_status?.active_group  && entity.roll_out_report?.release_status?.active_group < 5)}">Stage 5 Group</strong></div><div class="col-6">{{entity.roll_out_report?.managed_workers?.group_totals["5"]| json}}</div></div>
                      <div class="row"><div class="col-6"><strong [ngClass]="{'text-black-50': (entity.roll_out_report?.release_status?.active_group  && entity.roll_out_report?.release_status?.active_group < 6)}">Stage 6 Group</strong></div><div class="col-6">{{entity.roll_out_report?.managed_workers?.group_totals["6"]| json}}</div></div>
                      <div class="row"><div class="col-6"><strong [ngClass]="{'text-black-50': (entity.roll_out_report?.release_status?.active_group  && entity.roll_out_report?.release_status?.active_group < 7)}">Stage 7 Group</strong></div><div class="col-6">{{entity.roll_out_report?.managed_workers?.group_totals["7"]| json}}</div></div>
                      <div class="row"><div class="col-6"><strong [ngClass]="{'text-black-50': (entity.roll_out_report?.release_status?.active_group  && entity.roll_out_report?.release_status?.active_group < 8)}">Stage 8 Group</strong></div><div class="col-6">{{entity.roll_out_report?.managed_workers?.group_totals["8"]| json}}</div></div>
                      <div class="row"><div class="col-6"><strong [ngClass]="{'text-black-50': (entity.roll_out_report?.release_status?.active_group  && entity.roll_out_report?.release_status?.active_group < 9)}">Stage 9 Group</strong></div><div class="col-6">{{entity.roll_out_report?.managed_workers?.group_totals["9"]| json}}</div></div>
                      <div class="row"><div class="col-6"><strong [ngClass]="{'text-black-50': (entity.roll_out_report?.release_status?.active_group  && entity.roll_out_report?.release_status?.active_group < 10)}">Stage 10 Group</strong></div><div class="col-6">{{entity.roll_out_report?.managed_workers?.group_totals["10"]| json}}</div></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-header bg-primary">Targets</div>
                    <div class="card-body bg-white text-body">
                      <pre>{{entity?.targets| json}}</pre>
                    </div>
                  </div>
                </div>
              </div>



              <div class="row">
                <div class="col-12">
                  <pre>
                    {{entity.roll_out_report?.release_status |json}}
                  </pre>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <pre>
                    {{entity.roll_out_report?.managed_workers |json}}
                  </pre>
                </div>
              </div>


            </div>

            <div class="col-5">
              <!-- Charts -->
              <!-- Pie -->
              <div class="row">
                <div class="col-12" >
                  <div class="card bg-info">
                    <div class="card-header bg-info">Rollout Snapshot</div>
                    <div class="card-body bg-white">
                      <canvas baseChart class="chart"
                              [data]="releaseRollOutPieData"
                              [labels]="releaseRollOutPieLabels"
                              [chartType]="'pie'"
                              (chartHover)="chartHovered('releaseRollout', $event)"
                              (chartClick)="chartClicked('releaseRollout', $event)"></canvas>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Detailed Status: Bar -->
              <div class="row">
                <div class="col-12" >
                  <div class="card bg-info">
                    <div class="card-header bg-info">Release Stages</div>
                    <div class="card-body bg-white">
                      <canvas baseChart class="chart"
                              [datasets]="groupStageChartData"
                              [labels]="groupStageChartLabels"
                              [legend]="false"
                              [options]="groupStageChartOptions"
                              [chartType]="'bar'"
                              (chartHover)="chartHovered('groupStageChat', $event)"
                              (chartClick)="chartClicked('groupStageChat', $event)"></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>