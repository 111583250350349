
import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from './lacrosse.entity';
import { FirebaseAuthService }            from '../noizu/services/firebase-auth.service';
//import { DeviceDefinitionRepo }           from '../../repos/device/definition.repo';

export class ManufacturerEntity extends LacrosseEntity {
  public notes: String = "";
  public name: String;
  public new: boolean = false;

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    this._kind = "manufacturers";
    this._singular = "manufacturer";
    this.name = json.name
    if (json.new) {
      this.new = json.new;
    }
  } // end constructor

  // Sets the fields, used above, and to reset the values when canceling an edit
  refresh(data) {
    let res = super.refresh(data);
    this.identifier = data.id;
    this.name = data.name;
    return res;
  }

  ManufacturerUpdate() {
      if (this.new) {
        let entity = this.toJson({for: "appengine"});
        entity.id = entity.identifier;
        return this._post(
          `${this.apiBase()}/${this._kind}`,
          entity,
          (data, resolve) => {resolve(this.refresh(data));},
          {}
        );
      } else {
        return this._put(
          `${this.apiBase()}/${this._singular}/${this.identifier}`,
          this.toJson({for: "appengine"}),
          (data, resolve) => {resolve(this.refresh(data));},
          {}
        );
      }
  }

} // end Package
