
import {HttpClient} from '@angular/common/http';
import { FirebaseAuthService }            from '../../../noizu/services/firebase-auth.service';
import {DataManagementLogicalWidgetEnum, WidgetEnum} from '../../../enums';
import {DataManagementLogicalWidget} from '../data-management-logical-entity';
import {FormGroup} from '@angular/forms';

export class PSKUploadEntity extends DataManagementLogicalWidget {
  public _kind = "null";
  public _singular = "null";

  public initialized = false;
  public upload_type: string = 'csv';
  public flags = {has_header: true, update_redis: false, objectify: false, overwrite: false, overwrite_redis: false};
  public series: string = 'V2';
  public manufacturer = 'ref.manufacturer.14';
  public batch: string = 'ref.batch.25';
  public weather_category = 3;

  constructor(json = {}) {
    super();
    this.refresh(json);
  } // end constructor

  refresh(data) {
    this.upload_type = data['upload_type'] || 'csv';
    let flags = data['flags'] || {has_header: true, update_redis: false, objectify: false, overwrite: false, overwrite_redis: false};
    flags['has_header'] = ('has_header' in flags) ? flags['has_header'] : this.flags['has_header'];
    flags['update_redis'] = ('update_redis' in flags) ? flags['update_redis'] : this.flags['update_redis'];
    flags['objectify'] = ('objectify' in flags) ? flags['objectify'] : this.flags['objectify'];
    flags['overwrite'] = ('overwrite' in flags) ? flags['overwrite'] : this.flags['overwrite'];
    flags['overwrite_redis'] = ('overwrite_redis' in flags) ? flags['overwrite_redis'] : this.flags['overwrite_redis'];
    this.flags = flags;
    this.series = ('series' in data) ? data.series : this.series;
    this.manufacturer = ('manufacturer' in data) ? data.manufacturer : this.manufacturer;
    this.batch = ('batch' in data) ? data.batch : this.batch;
    this.weather_category = ('weather_category' in data) ? data.weather_category : this.weather_category;
    this.initialized = false;
    return this;
  }

  data_management_logical_widget_type(): DataManagementLogicalWidgetEnum | null {
    return DataManagementLogicalWidgetEnum.DATA_MANAGEMENT_LOGICAL_WIDGET__PSK;
  }

} // end Package
