
    <ng-container [ngSwitch]="layout">

      <ng-container *ngSwitchCase="'select'">
        <ng-container *ngIf="entity?.types">
          <ng-container *ngIf="options && options['edit']">

            <ng-select
              [multiple]="false"
              [(ngModel)]="options['selected']"
              (ngModelChange)="selectType($event)"
              [items]="entity.select_map"
              bindLabel="text"
              bindValue="value"
            ></ng-select>


          </ng-container>
          <ng-container *ngIf="!(options && options['edit'])">
            <ng-container *ngIf="entity.types.has(options['selected'])">
              {{entity.types.get(options.selected).code}} - {{entity.types.get(options.selected).name}} ({{entity.types.get(options.selected).identifier}})
            </ng-container>
            <ng-container *ngIf="!entity.types.has(options['selected'])">{{options['selected']}}</ng-container>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <ng-container *ngIf="entity?.types">
          <div class="row" *ngFor="let entity_type of entity.types.values()">
            <div class="col">
              <div class="card">
                <div class="card-header">Subscription Definition #{{entity_type.identifier}} - {{entity_type.code}}</div>
                <div class="card-body p-0 m-1">
                  <div class="row"><div class="col-2"><b>Name</b></div><div class="col">{{entity_type.name}}</div></div>
                  <div class="row"><div class="col-2"><b>Desc</b></div><div class="col">{{entity_type.description}}</div></div>
                  <div class="row"><div class="col-2"><b>Code</b></div><div class="col">{{entity_type.code}}</div></div>
                  <div class="row pt-1 pb-0 mb-0">
                    <div class="col">
                      <div class="alert alert-info p-1 pb-0 mb-0">
                        <div class="row" *ngFor="let key of objectKeys(entity_type.features)">
                          <div class="col">
                            <div class="row" *ngFor="let key2 of objectKeys(entity_type.features[key])">
                              <div class="col">
                                <b>{{key}}.{{key2}}</b> = {{entity_type.features[key][key2]}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  