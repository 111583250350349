
import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from '../../lacrosse.entity';
import { FirebaseAuthService }            from '../../../noizu/services/firebase-auth.service';

export class MCUFirmwareDefinitionEntity extends LacrosseEntity {
  public _kind = "mcu-firmware-definitions";
  public _singular = "mcu-firmware-definition";
  public notes: String;
  public firmwareVersion: String;
  public checksum: String;
  public size: Number;
  public file: String;
  public series: String;
  public manufacturerId: number;
  public _sref_module = "mcu-firmware"
  public pending = false;

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    this.strip_from_json["pending"] = 1;
  } // end constructor

  // Sets the fields, used above, and to reset the values when canceling an edit
  refresh(data) {
    console.log(data);
    this.checksum = data.checksum;
    this.firmwareVersion = data.firmwareVersion;
    this.size = data.size;
    this.file = data.file;
    this.series = data.series;
    this.manufacturerId = parseInt(data.manufacturerId);
    this.notes = data.notes;
    this.pending = false;
    return super.refresh(data);
  }

} // end Package
