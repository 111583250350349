


    <ng-container *ngIf="entity">
      <ng-container [ngSwitch]="layout">
        <!-- ================================================= -->
        <!-- Mini                                              -->
        <!-- ================================================= -->
        <div class="alert-widget" *ngSwitchCase="'mini'">
          <div class="alert text-white leading " [ngClass]="{'alert-danger': entity.isTriggered(), 'alert-success': !entity.isGreen()}">
            <div class="row">
              <div class="col-4"><b>Alert: </b></div>
              <div class="col"><a [routerLink]="'/portal/alerts/show/' + entity.identifier">{{entity.identifier}} [{{entity.name}}]</a></div>
            </div>
            <div class="row">
              <div class="col-4"><b>Owner:</b></div>
              <div class="col" *ngIf="entity.owner"><a [routerLink]="'/portal/users/show/' + entity.owner">{{entity.owner}}</a></div>
          </div>
          </div>
        </div>

        <!-- ================================================= -->
        <!-- Brief                                             -->
        <!-- ================================================= -->
        <div class="alert-widget" *ngSwitchCase="'brief'">
          <div class="card m-0 mb-4">
            <div class="card-header p-1">Alert <a [routerLink]="'/portal/alerts/show/' + entity.identifier">{{entity.identifier}}</a></div>
            <div class="card-body m-1 p-1">
              <div class="row mb-2">
                <div class="col-12">
                  <div class="alert leading m-0 p-0" [ngClass]="{'alert-danger': entity.isTriggered(), 'alert-success': !entity.isGreen()}">
                    <div class="row">
                      <div class="col-4"><b>Alert: </b></div>
                      <div class="col"><a [routerLink]="'/portal/alerts/show/' + entity.identifier">{{entity.identifier}} [{{entity.name}}]</a></div>
                    </div>
                    <div class="row">
                      <div class="col-4"><b>Owner:</b></div>
                      <div class="col" *ngIf="entity.owner"><a [routerLink]="'/portal/users/show/' + entity.owner">{{entity.owner}}</a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- ================================================= -->
        <!-- Table                                             -->
        <!-- ================================================= -->
        <ng-container *ngSwitchCase="'table'">

          <div class="alert-widget div-table-th" *ngIf="options['index'] == 0 && options['header']">
            <div class="row" >
              <div class="col" *ngFor="let column of options.columns">
                <ng-container [ngSwitch]="column">
                  <ng-container *ngSwitchCase="'identifier'">Id</ng-container>
                  <ng-container *ngSwitchCase="'name'">Name</ng-container>
                  <ng-container *ngSwitchCase="'type'">Type</ng-container>
                  <ng-container *ngSwitchCase="'owner'">Owner</ng-container>
                  <ng-container *ngSwitchCase="'status'">Status</ng-container>
                  <ng-container *ngSwitchCase="'last_event'">Last Event</ng-container>
                  <ng-container *ngSwitchCase="'modified_on'">Modified On</ng-container>
                  <ng-container *ngSwitchCase="'actions'">Actions</ng-container>
                </ng-container>
              </div>
            </div>
          </div>

          <div [ngClass]="{'div-table-tr': (options['index'] % 2) == 1, 'div-table-tr-alt': (options['index'] % 2) == 0}">
            <div class="row">
              <ng-container *ngFor="let column of options.columns">
                <ng-container [ngSwitch]="column">
                  <div *ngSwitchCase="'identifier'" class="col text-truncate">
                    <span class="pl-1">
                        <a [routerLink]="'/portal/alerts/show/' + entity.identifier"  title="{{entity.identifier}}" ><i class="pr-1 text-dark fa fa-link"> </i></a>
                        <a title="{{entity.identifier}}" href="/portal/alerts/show/{{entity.identifier}}" (click)="select($event)" >{{entity.identifier}}</a>
                    </span>
                  </div>
                  <div *ngSwitchCase="'name'" class="col-2 text-truncate" title="{{entity.name}}">{{entity.name}}</div>
                  <div *ngSwitchCase="'type'" class="col-2 text-truncate" title="{{facade_types[entity.facade.alert_facade_widget()]}}">{{facade_types[entity.facade.alert_facade_widget()]}}</div>
                  <div *ngSwitchCase="'owner'" class="col-2 text-truncate" title="{{entity.owner}}">
                    <a [routerLink]="['/portal/users/show/'  + entity.owner]">{{entity.owner}}</a>
                  </div>
                  <div *ngSwitchCase="'status'" class="col-2 text-truncate" title="{{entity.statusLabel()}}">
                    <div class="badge " [ngClass]="{'badge-danger': entity.isTriggered(), 'badge-success': entity.isGreen() , 'badge-warning': !entity.isTriggered() && !entity.isGreen() }">
                      {{entity.statusLabel()}}
                    </div>
                  </div>

                  <ng-container *ngSwitchCase="'modified_on'">
                    <div *ngIf="entity?.modifiedOn" class="col text-truncate" title="{{entity.modifiedOn}}">{{entity.modifiedOn| date:'MM/dd/yyyy @ h:mm:ssa'}}</div>
                    <div *ngIf="!entity?.modifiedOn" class="col text-truncate" title="[NULL]"><b>[NULL]</b></div>
                  </ng-container>


                  <div *ngSwitchCase="'last_event'" class="col text-truncate" title="[FEATURE PENDING]">[FEATURE PENDING]</div>


                  <div class="col" *ngSwitchCase="'actions'">
                    <label class="switch switch-label switch-success">
                      <input class="switch-input" type="checkbox" checked="" (change)="toggleAlert($event)" [(ngModel)]="entity.enabled" ><span class="switch-slider" data-checked="On" data-unchecked="Off"></span>
                    </label>
                  </div>




                </ng-container>
              </ng-container>

            </div>
          </div>


        </ng-container>

        <!-- ================================================= -->
        <!-- Standard(Default)                                 -->
        <!-- ================================================= -->
        <div class="alert-widget" *ngSwitchDefault>
          <div class="card shadow" *ngIf="entity" [ngClass]="{'card-accent-danger border-danger': entity.isTriggered(), 'border-primary card-accent-primary': !entity.isGreen()}">
            <div class="card-header">
              <div class="row">

                <div *ngIf="!entity?.extended?.new" class="col-4"><b>Alert #{{entity?.identifier}}</b><span class="pl-2"><input (ngModelChange)="alertChange()" type="text" [(ngModel)]="entity.name" size="16" /></span></div>
                <div *ngIf="entity?.extended?.new" class="col-4"><b>Alert [NEW]</b> <span class="pl-2"><input (ngModelChange)="alertChange()" type="text" [(ngModel)]="entity.name" size="8" /></span></div>


                <div class="col-4">
                  <logical-widget (widgetEvent)="alertTemplateEvent($event)" [entity]="facade_selector" [layout]="layout" [options]="{}"></logical-widget>
                </div>

                <div class="col-4 text-right">

                  <div class="row px-0">
                    <div class="col-auto">
                      <ng-container *ngIf="entity?.meta?.hasChanges">
                        <div (click)="revert($event)" class="btn btn-pill btn-primary">Revert</div>
                        <div (click)="save($event)" class="ml-1 mr-1 btn btn btn-pill btn-warning">Save</div>
                      </ng-container>
                    </div>

                    <div class="col pt-1">
                      <div class="pl-2 pr-2 py-0 m-0 fa-2x text-danger" style="display: inline-block; position: relative; top: -10px;"><i (click)="deleteAlert($event)" class="fa fa-minus-circle"></i></div>

                      <div style="display: inline-block">
                        <label class="switch switch-3d switch-primary">
                          <input checked="" class="switch-input switch-primary" (change)="toggleAlert($event)" [(ngModel)]="entity.enabled" type="checkbox">
                          <span class="switch-slider"></span>
                        </label>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="card-body p-1 m-0">
              <!--- ================================================ -->
              <!--  Expand                                           -->
              <!--- ================================================ -->
              <div class="row">
                <div class="col-12" (click)="entity.hasStatus() && toggleAlertExpand()">
                  <div class="text-center text-primary"><i class="fa fa-lg" [ngClass]="{'spinner sm': cloning || !entity.hasStatus() ,  'fa-chevron-down': !cloning && entity.hasStatus() && !options.expandAlert, 'fa-chevron-up': !cloning && entity.hasStatus() && options.expandAlert}"> </i></div>
                </div>
              </div>

              <!--- ================================================ -->
              <!--  Facade                                           -->
              <!--- ================================================ -->
              <div class="" [ngClass]="{'d-none': !options.expandAlert}" *ngIf="expanded">
                <div *ngIf="entity.facade" class="row">
                  <div class="col mt-3">
                    <alert-facade-widget (widgetEvent)="alertTemplateEvent($event)" [entity]="entity.facade" [options]="options" [layout]="layout"></alert-facade-widget> <!-- Todo Change Facade Selector -->
                  </div>
                </div>

                <!--- ================================================ -->
                <!--  Details & Settings                               -->
                <!--- ================================================ -->
                <div class="row">
                  <div class="col">
                    <div class="card">
                      <div class="card-header">
                        <div class="row">
                          <div class="col"><b class="text-left">Details: #{{entity.identifier}}</b></div>
                          <div class="col-2 text-right p-0 text-middle">
                            <toggle-widget [options]="detail_expand_settings"></toggle-widget>
                          </div>
                        </div>
                      </div>
                      <div class="card-body"  [ngClass]="{'d-none': !detail_expand_settings.toggle}" >

                        <div class="row">
                          <!--- ================================================ -->
                          <!--  Details                                          -->
                          <!--- ================================================ -->
                          <div class="col-12">
                            <div class="row">
                              <div class="col-4">ID:</div>
                              <div class="col-8">{{entity.identifier}}</div>
                            </div>
                            <div class="row">
                              <div class="col-4">Owner:</div>
                              <div class="col-8"><a [routerLink]="'/portal/users/show/' + entity.owner">{{entity.owner}}</a></div>
                            </div>
                            <div class="row">
                              <div class="col-4">Enabled:</div>
                              <div class="col-8">{{entity.enabled |json}}</div>
                            </div>
                            <div class="row">
                              <div class="col-4">Name:</div>
                              <div class="col-8"><input (ngModelChange)="alertChange()"  type="text" [(ngModel)]="entity.name" size="16" /></div> <!-- Todo Editor -->
                            </div>
                            <div class="row">
                              <div class="col-4">Weight:</div>
                              <div class="col-8"><input (ngModelChange)="alertChange()" type="number" [(ngModel)]="entity.weight"/></div>
                            </div>
                            <div class="row">
                              <div class="col-4">TimeZone:</div>
                              <div class="col-8">{{entity.timeZone}}</div> <!-- Todo Selector -->
                            </div>
                            <div class="row">
                              <div class="col-4">Language:</div>
                              <div class="col-8">
                                <ng-select
                                  placeholder="Alert Language"
                                  [items]="alert_language"
                                  [multiple]="false"
                                  bindLabel="label"
                                  bindValue="value"
                                  [(ngModel)]="entity.language"
                                  (ngModelChange)="alertChange()"
                                ></ng-select>
                              </div>
                            </div>

                          </div>
                          <!--- ================================================ -->
                          <!--  Notification Settings                            -->
                          <!--- ================================================ -->
                          <div class="col-12 mt-3 mb-0 pb-0">
                            <div *ngIf="entity.notificationSettings">
                              <notification-settings-widget (widgetEvent)="notificationEvent($event)" [entity]="entity.notificationSettings" [options]="options" [layout]="layout"></notification-settings-widget>
                            </div>
                          </div>
                        </div>



                        <!--- ================================================ -->
                        <!--  Process                                          -->
                        <!--- ================================================ -->
                        <div class="row mt-4" >
                          <div class="col">
                            <div class="card">
                              <div class="card-header">
                                <div class="row">
                                  <div class="col"><b class="text-left">Alert Worker</b></div>
                                  <div class="col-2 text-right p-0 text-middle">
                                    <toggle-widget [options]="worker_expand_settings"></toggle-widget>
                                  </div>
                                </div>
                              </div>
                              <div class="card-body"  [ngClass]="{'d-none': !worker_expand_settings.toggle}" >


                                <div class="row" >
                                  <div class="col" *ngIf="entity?.facade?.status">


                                    <widget [entity]="entity.facade.status.trigger_state" [options]="{id: entity.identifier}" [layout]="layout"></widget>
                                    <widget [entity]="entity.facade.status.subscribers" [options]="{id: entity.identifier}" [layout]="layout"></widget>
                                    <widget [entity]="entity.facade.status.callbacks" [options]="{id: entity.identifier}" [layout]="layout"></widget>
                                    <widget [entity]="entity.facade.status.runtime_configuration" [options]="{id: entity.identifier}" [layout]="layout"></widget>
                                    <widget [entity]="entity.facade.status.facade" [options]="{id: entity.identifier}" [layout]="layout"></widget>





                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>




                      </div>
                    </div>
                  </div>
                </div>


                <!--- ================================================ -->
                <!--  Logs                                             -->
                <!--- ================================================ -->
                <div class="row" *ngIf="expanded">
                  <div class="col">
                    <log-set-widget
                      *ngIf="entity?.extended?.logs?.alert"
                      [entity]="entity?.extended?.logs?.alert"
                      [options]="{subject: 'ref.alert.' + entity.identifier, expand: false}"
                      [layout]="'standard'"
                    ></log-set-widget>
                  </div>
                </div>

              </div>
            </div>

          </div>

        </div>
      </ng-container>
    </ng-container>


  