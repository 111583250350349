
      <div class="embed-ds-nws-card" *ngIf="!editMode">
          <div class="row">
              <div class="col font-xl"><b>Text 1:</b> {{entity.messages.one}}</div>
              <div class="col font-xl"><b>Text2:</b> {{entity.messages.two}}</div>
          </div>
          <div class="row">
              <div class="col font-xl"><b>Link:</b> {{entity.linkType()}}</div>
          </div>
      </div>
      <div class="embed-ds-nws-card" *ngIf="editMode">
          <div class="row">
              <div class="col-12">
                  <div class="card  border-warning">
                      <div class="card-body mb-0 pb-0" *ngIf="entity">
                          <!-- Type Specific -->
                          <div class="row"><div class="col-4"><b>Text One</b></div>
                              <div class="col-8">
                                  <input
                                          type="text"
                                          name="[message]one"
                                          [(ngModel)]="entity.messages.one"
                                          (ngModelChange)="editMessageOne($event)"
                                          name="editMessageOne"
                                          size="10"/>
                              </div></div>
                          <div class="row"><div class="col-4"><b>Text Two</b></div><div class="col-8">
                              <input
                                      type="text"
                                      name="[message]two"
                                      [(ngModel)]="entity.messages.two"
                                      (ngModelChange)="editMessageTwo($event)"
                                      name="editMessageTwo"
                                      size="10"/>
                          </div></div>
                          <div class="row"><div class="col-4"><b>Link</b></div><div class="col-8">

                            <ng-select
                              [name]="'link'"
                              [multiple]="false"
                              [(ngModel)]="entity.link"
                              bindLabel="text"
                              bindValue="value"
                              (ngModelChange)="setLink($event)"
                              [items]="linkOptions"
                            ></ng-select>

                          </div></div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    