import {Component, Input, Output, EventEmitter} from '@angular/core';
import {EntityLogEntity} from '../../../entities/log/entity-log.entity';
import {WidgetBase} from '../widget-base';
import {LogWidgetEventStruct} from './log-widget-event.struct';
import {LogWidgetEnum} from '../../../enums';

@Component({
  selector: 'log-details-widget',
  template: `


    <ng-container [ngSwitch]="entity.log_widget_type()">
        <log-details-generic-widget *ngSwitchCase="LogWidgetEnum.LOG_WIDGET__NOTIFICATION" (widgetEvent)="forward($event)" [entity]="entity" [options]="options" [layout]="layout"> </log-details-generic-widget>
        <log-details-generic-widget *ngSwitchCase="LogWidgetEnum.LOG_WIDGET__USER" (widgetEvent)="forward($event)" [entity]="entity" [options]="options" [layout]="layout"> </log-details-generic-widget>
        <data-stream-log-details-widget *ngSwitchCase="LogWidgetEnum.LOG_WIDGET__DATA_STREAM" (widgetEvent)="forward($event)" [entity]="entity" [options]="options" [layout]="layout"> </data-stream-log-details-widget>
        <device-log-details-widget *ngSwitchCase="LogWidgetEnum.LOG_WIDGET__DEVICE" (widgetEvent)="forward($event)" [entity]="entity" [options]="options" [layout]="layout"> </device-log-details-widget>
        <gateway-log-details-widget *ngSwitchCase="LogWidgetEnum.LOG_WIDGET__GATEWAY"  (widgetEvent)="forward($event)" [entity]="entity" [options]="options" [layout]="layout"> </gateway-log-details-widget>
        <alert-log-details-widget *ngSwitchCase="LogWidgetEnum.LOG_WIDGET__ALERT" (widgetEvent)="forward($event)" [entity]="entity" [options]="options" [layout]="layout"> </alert-log-details-widget>
        <log-details-generic-widget *ngSwitchDefault (widgetEvent)="forward($event)" [entity]="entity" [options]="options" [layout]="layout"> </log-details-generic-widget>
    </ng-container>



    `
})
export class LogDetailsWidgetComponent extends WidgetBase{
  @Input() entity: EntityLogEntity = null;
  @Input() options: any = {};
  @Input() layout: string = null;
  @Output() widgetEvent = new EventEmitter<LogWidgetEventStruct>();
  forward(widgetEvent: LogWidgetEventStruct) {
    this.widgetEvent.emit(widgetEvent);
  }
  public LogWidgetEnum = LogWidgetEnum;
  public expand = false;
  public expandRaw = false;

  constructor() {
    super();

  }
}
