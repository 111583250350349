
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { FirebaseAuthService }        from '../../../noizu/services/firebase-auth.service';
import {ElixirRepo} from '../../elixir.repo';
import {CacheService} from '../../../services/cache.service';
import {FirmwareApprovalRepo} from '../../firmware';
import {MCUFirmwareReleaseEntity} from '../../../entities/mcu/firmware';
import {MCUFirmwareDefinitionRepo} from './definition.repo';

@Injectable()
export class MCUFirmwareReleaseRepo extends ElixirRepo {
  public _kind = "mcu-firmware-release";
  public _singular = "mcu-firmware-release";
  public cache;
  public firmwareDefinitionRepo: MCUFirmwareDefinitionRepo;
  public firmwareApprovalRepo: FirmwareApprovalRepo;

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService, @Inject(CacheService) cache: CacheService, @Inject(MCUFirmwareDefinitionRepo) firmwareDefinitionRepo: MCUFirmwareDefinitionRepo, @Inject(FirmwareApprovalRepo) firmwareApprovalRepo: FirmwareApprovalRepo) {
    super(client, auth);
    this.cache = cache;
    this.firmwareApprovalRepo = firmwareApprovalRepo;
    this.firmwareDefinitionRepo = firmwareDefinitionRepo
  }

  entity(record) {
    return new MCUFirmwareReleaseEntity(this.client, this.auth, record, this.cache, this.firmwareDefinitionRepo, this.firmwareApprovalRepo);
  }

  getEntityPromise(id, options = {}) {
    return this._getEntityPromise(
      `${this.apiBase()}/mcu/firmware/releases/${id}`,
      (record, resolve) => {
        resolve( this.entity(record) );
      },
      options
    );
  }

  getListPromise( options = {}) {
    return this._get(
      `${this.apiBase()}/mcu/firmware/releases`,
      (json, resolve) => {
        const response = []; // @todo DataStreamCardSet
        if (json) {
          for (let i = 0; i < json.length; i++) {
            response.push(this.entity(json[i]));
          }
        } else {
          // error handling.
        }
        resolve(response);
      },
      options
    );
  }


  getReportPromise(options = {}) {
    return this._getEntityPromise(
      `${this.apiBase()}/admin-tools/mcu/firmware/report`,
      (record, resolve) => {
        resolve(  record );
      },
      options
    );
  }



}
