
    <ng-container [ngSwitch]="entity.alert_log_widget_type()">
      <alert-load-log-details-widget *ngSwitchCase="AlertLogWidgetEnum.ALERT_LOG_WIDGET__LOAD" (widgetEvent)="forward($event)" [entity]="entity" [options]="options" [layout]="layout"></alert-load-log-details-widget>
      <alert-load-error-log-details-widget *ngSwitchCase="AlertLogWidgetEnum.ALERT_LOG_WIDGET__LOAD_ERROR" (widgetEvent)="forward($event)" [entity]="entity" [options]="options" [layout]="layout"></alert-load-error-log-details-widget>
      <alert-set-enabled-log-details-widget *ngSwitchCase="AlertLogWidgetEnum.ALERT_LOG_WIDGET__SET_ENABLED" (widgetEvent)="forward($event)" [entity]="entity" [options]="options" [layout]="layout"></alert-set-enabled-log-details-widget>
      <alert-set-disabled-log-details-widget *ngSwitchCase="AlertLogWidgetEnum.ALERT_LOG_WIDGET__SET_DISABLED" (widgetEvent)="forward($event)" [entity]="entity" [options]="options" [layout]="layout"></alert-set-disabled-log-details-widget>
      <alert-prepare-callbacks-log-details-widget *ngSwitchCase="AlertLogWidgetEnum.ALERT_LOG_WIDGET__PREPARE_CALLBACKS" (widgetEvent)="forward($event)" [entity]="entity" [options]="options" [layout]="layout"></alert-prepare-callbacks-log-details-widget>
      <alert-register-callback-log-details-widget *ngSwitchCase="AlertLogWidgetEnum.ALERT_LOG_WIDGET__REGISTER_CALLBACK" (widgetEvent)="forward($event)" [entity]="entity" [options]="options" [layout]="layout"></alert-register-callback-log-details-widget>
      <alert-deregister-callback-log-details-widget *ngSwitchCase="AlertLogWidgetEnum.ALERT_LOG_WIDGET__DEREGISTER_CALLBACK" (widgetEvent)="forward($event)" [entity]="entity" [options]="options" [layout]="layout"></alert-deregister-callback-log-details-widget>
      <alert-refresh-callback-log-details-widget *ngSwitchCase="AlertLogWidgetEnum.ALERT_LOG_WIDGET__REFRESH_CALLBACK" (widgetEvent)="forward($event)" [entity]="entity" [options]="options" [layout]="layout"></alert-refresh-callback-log-details-widget>
      <alert-triggered-log-details-widget *ngSwitchCase="AlertLogWidgetEnum.ALERT_LOG_WIDGET__TRIGGERED" (widgetEvent)="forward($event)" [entity]="entity" [options]="options" [layout]="layout"></alert-triggered-log-details-widget>
      <alert-triggered-error-log-details-widget *ngSwitchCase="AlertLogWidgetEnum.ALERT_LOG_WIDGET__TRIGGERED_ERROR" (widgetEvent)="forward($event)" [entity]="entity" [options]="options" [layout]="layout"></alert-triggered-error-log-details-widget>
      <alert-recovered-log-details-widget *ngSwitchCase="AlertLogWidgetEnum.ALERT_LOG_WIDGET__RECOVERED" (widgetEvent)="forward($event)" [entity]="entity" [options]="options" [layout]="layout"></alert-recovered-log-details-widget>
      <alert-recovered-error-log-details-widget *ngSwitchCase="AlertLogWidgetEnum.ALERT_LOG_WIDGET__RECOVERED_ERROR" (widgetEvent)="forward($event)" [entity]="entity" [options]="options" [layout]="layout"></alert-recovered-error-log-details-widget>
      <alert-refresh-log-details-widget *ngSwitchCase="AlertLogWidgetEnum.ALERT_LOG_WIDGET__REFRESH" (widgetEvent)="forward($event)" [entity]="entity" [options]="options" [layout]="layout"></alert-refresh-log-details-widget>
      <alert-refresh-error-log-details-widget *ngSwitchCase="AlertLogWidgetEnum.ALERT_LOG_WIDGET__REFRESH_ERROR" (widgetEvent)="forward($event)" [entity]="entity" [options]="options" [layout]="layout"></alert-refresh-error-log-details-widget>
      <alert-unchanged-log-details-widget *ngSwitchCase="AlertLogWidgetEnum.ALERT_LOG_WIDGET__UNCHANGED" (widgetEvent)="forward($event)" [entity]="entity" [options]="options" [layout]="layout"></alert-unchanged-log-details-widget>
      <alert-unloaded-log-details-widget *ngSwitchCase="AlertLogWidgetEnum.ALERT_LOG_WIDGET__UNLOADED" (widgetEvent)="forward($event)" [entity]="entity" [options]="options" [layout]="layout"></alert-unloaded-log-details-widget>
      <alert-created-log-details-widget *ngSwitchCase="AlertLogWidgetEnum.ALERT_LOG_WIDGET__CREATED" (widgetEvent)="forward($event)" [entity]="entity" [options]="options" [layout]="layout"></alert-created-log-details-widget>
      <alert-updated-log-details-widget *ngSwitchCase="AlertLogWidgetEnum.ALERT_LOG_WIDGET__UPDATED" (widgetEvent)="forward($event)" [entity]="entity" [options]="options" [layout]="layout"></alert-updated-log-details-widget>
      <alert-deleted-log-details-widget *ngSwitchCase="AlertLogWidgetEnum.ALERT_LOG_WIDGET__DELETED" (widgetEvent)="forward($event)" [entity]="entity" [options]="options" [layout]="layout"></alert-deleted-log-details-widget>
      <log-details-generic-widget *ngSwitchDefault (widgetEvent)="forward($event)" [entity]="entity" [options]="options" [layout]="layout"> </log-details-generic-widget>
    </ng-container>
    