import {Component, Input, Inject, Output, EventEmitter} from '@angular/core';
import {NoizuStruct} from '../../../noizu/structs/noizu-struct';
import {EmailWidgetEnum, WidgetEnum} from '../../../enums/widget';
import {EmailWidgetEventStruct} from './email-widget-event.struct';
import {EmailEntity} from '../../../entities/data/email.entity';
import {EmailQueueEntity, EmailTemplateEntity} from '../../../entities';

@Component({
  selector: 'email-template-widget',
  template: `

    <div class="card" *ngIf="entity">
      <div class="card-header">{{entity.identifier}}</div>
      <div class="card-body">
        <div class="row">
          <div class="col"><strong>Name</strong></div>
          <div class="col">{{entity.name}}</div>
        </div>
        <div class="row">
          <div class="col"><strong>Description</strong></div>
          <div class="col">{{entity.description}}</div>
        </div>
        <div class="row">
          <div class="col"><strong>Identifier</strong></div>
          <div class="col">{{entity.identifier}}</div>
        </div>
        <div class="row">
          <div class="col"><strong>Sendgrid Id</strong></div>
          <div class="col">{{entity.external_template_id}}</div>
        </div>
        <div class="row">
          <div class="col"><strong>Synced</strong></div>
          <div class="col"><span *ngIf="entity.synched_on">{{entity.synched_on | date : 'short'}}</span></div>
        </div>
        <ng-container *ngIf="entity.cached?.section_stack">
          <div class="row">
            <div class="col"><strong>Stack</strong></div>
          </div>
          <div class="row" *ngFor="let stack of entity.cached.section_stack">
            <div class="col"><email-template-section-widget [entity]="stack" [layout]="layout" [options]="options"></email-template-section-widget></div>
          </div>
        </ng-container>
      </div>
    </div>
`
})
export class EmailTemplateWidgetComponent {
  @Input() entity: EmailTemplateEntity | null = null;
  @Input() templateHint: string = null;
  @Input() options: any = null;
  @Input() layout = 'standard';
  expand = false;
  expandRaw = false;
  openRow = false;

  @Output() widgetEvent = new EventEmitter<EmailWidgetEventStruct>();
  forwardEvent(widgetEvent: EmailWidgetEventStruct) {
    this.widgetEvent.emit(widgetEvent);
  }
  constructor() {
  }
}
