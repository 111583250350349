
    <ng-container [ngSwitch]="entity.device_log_widget_type()">

      <ng-container *ngSwitchCase="DeviceLogWidgetEnum.DEVICE_LOG_WIDGET__STAGE_ONE">
        <device-stage-one-log-details-widget [entity]="entity" [options]="options" [layout]="layout"> </device-stage-one-log-details-widget>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <log-details-generic-widget [entity]="entity" [options]="options" [layout]="layout"> </log-details-generic-widget>
      </ng-container>

    </ng-container>
    