import {Component, Input, Output, EventEmitter} from '@angular/core';
import {EntityLogEntity} from '../../../../entities/log/entity-log.entity';
import {WidgetBase} from '../../widget-base';
import {LogWidgetEventStruct} from '../log-widget-event.struct';
import {LogWidgetEnum} from '../../../../enums';
import {DeviceEntityLogEntity} from '../../../../entities';

@Component({
  selector: 'data-stream-fetch-log-details-widget',
  template: `
    <ng-container *ngIf="entity">
      <ng-container [ngSwitch]="layout">
        <ng-container *ngSwitchCase="'card-details'">


          <div class="data-stream-fetch-log-details-widget row mb-1">

            <div class="col-3">
              <div class="row">
                <div class="col-12">
                  <b>Ref</b>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  {{entity?.message?.response?.ref}}
                </div>
              </div>
            </div>

            <div class="col-3">
              <div class="row">
                <div class="col-12">
                  <b>Units</b>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  {{entity?.message?.response?.units}}
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="row">
                <div class="col-12">
                  <b>Format</b>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  {{entity?.message?.response?.format}}
                </div>
              </div>
            </div>
          </div>



          <div class="row mb-1">

            <div class="col-3">
              <div class="row">
                <div class="col-12">
                  <b>Enabled</b>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  {{entity?.message?.response?.enabled}}
                </div>
              </div>
            </div>

            <div class="col-3">
              <div class="row">
                <div class="col-12">
                  <b>@sma+end+</b>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  {{entity?.message?.response?.sma_end| json}}
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="row">
                <div class="col-12">
                  <b>I8N Synch</b>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <span>Pending</span>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-4">

            <div class="col-12">
              <div class="card m-0 p-0 mb-1" *ngIf="entity">
                <div class="card-header">Data Stream</div>
                <div class="card-body">
                  <div class="row" *ngFor="let card of entity?.message?.response?.cards">
                    <div class="col-12">

                      <div class="card m-0 p-0 mb-1">
                        <div class="card-header">Card #{{card.id}} <span>enabled: {{card.enabled | json}}, </span> <span>featured: {{card.featured | json}}</span></div>
                        <div class="card-body">
                          <div class="row">
                            <div class="col-4">type: </div><div class="col-8">{{card.contents?.type}}</div>
                          </div>
                          <div class="row">
                            <div class="col-4">field 1: </div><div class="col-8">{{card.contents?.fields?.one?.value}}</div>
                            <div class="col-4">field 2: </div><div class="col-8">{{card.contents?.fields?.two?.value}}</div>
                            <div class="col-4">field 3: </div><div class="col-8">{{card.contents?.fields?.three?.value}}</div>
                          </div>
                          <div class="row">
                            <div class="col-4">readings: </div><div class="col-8"><pre>{{card.contents?.readings| json}}</pre></div>
                          </div>
                        </div>
                      </div>



                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div class="row mb-4">
            <div class="col-12"><strong>[Raw Data]</strong></div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  Raw Data<span class="float-right">
              <i
                (click)="expandRaw = !expandRaw"
                class="fa fa-sm"
                [ngClass]="{'fa-chevron-down': !expandRaw, 'fa-chevron-up': expandRaw}"> </i>
            </span>
                </div>
                <div class="card-body" *ngIf="expandRaw">
                  <div class="row">
                    <div class="col-12">
                      <pre>Message: {{entity.message| json}}</pre>
                      <pre>Context: {{entity.context| json}}</pre>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </ng-container>
        <ng-container *ngSwitchCase="'table-row-details'">


          <div class="data-stream-fetch-log-details-widget row mb-1">

            <div class="col-3">
              <div class="row">
                <div class="col-12">
                  <b>Ref</b>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  {{entity?.message?.response?.ref}}
                </div>
              </div>
            </div>

            <div class="col-3">
              <div class="row">
                <div class="col-12">
                  <b>Units</b>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  {{entity?.message?.response?.units}}
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="row">
                <div class="col-12">
                  <b>Format</b>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  {{entity?.message?.response?.format}}
                </div>
              </div>
            </div>
          </div>



          <div class="row mb-1">

            <div class="col-3">
              <div class="row">
                <div class="col-12">
                  <b>Enabled</b>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  {{entity?.message?.response?.enabled}}
                </div>
              </div>
            </div>

            <div class="col-3">
              <div class="row">
                <div class="col-12">
                  <b>@sma+end+</b>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  {{entity?.message?.response?.sma_end| json}}
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="row">
                <div class="col-12">
                  <b>I8N Synch</b>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <span>Pending</span>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-4">

            <div class="col-12">
              <div class="card m-0 p-0 mb-1" *ngIf="entity">
                <div class="card-header">Data Stream</div>
                <div class="card-body">
                  <div class="row" *ngFor="let card of entity?.message?.response?.cards">
                    <div class="col-12">

                      <div class="card m-0 p-0 mb-1">
                        <div class="card-header">Card #{{card.id}} <span>enabled: {{card.enabled | json}}, </span> <span>featured: {{card.featured | json}}</span></div>
                        <div class="card-body">
                          <div class="row">
                            <div class="col-4">type: </div><div class="col-8">{{card.contents?.type}}</div>
                          </div>
                          <div class="row">
                            <div class="col-4">field 1: </div><div class="col-8">{{card.contents?.fields?.one?.value}}</div>
                            <div class="col-4">field 2: </div><div class="col-8">{{card.contents?.fields?.two?.value}}</div>
                            <div class="col-4">field 3: </div><div class="col-8">{{card.contents?.fields?.three?.value}}</div>
                          </div>
                          <div class="row">
                            <div class="col-4">readings: </div><div class="col-8"><pre>{{card.contents?.readings| json}}</pre></div>
                          </div>
                        </div>
                      </div>



                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div class="row mb-4">
            <div class="col-12"><strong>[Raw Data]</strong></div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  Raw Data<span class="float-right">
              <i
                (click)="expandRaw = !expandRaw"
                class="fa fa-sm"
                [ngClass]="{'fa-chevron-down': !expandRaw, 'fa-chevron-up': expandRaw}"> </i>
            </span>
                </div>
                <div class="card-body" *ngIf="expandRaw">
                  <div class="row">
                    <div class="col-12">
                      <pre>Message: {{entity.message| json}}</pre>
                      <pre>Context: {{entity.context| json}}</pre>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </ng-container>
      </ng-container>
    </ng-container>
  `
})
export class DataStreamFetchLogDetailsWidgetComponent extends WidgetBase{
  @Input() entity: DeviceEntityLogEntity = null;
  @Input() options: any = {};
  @Input() layout: string = null;
  @Output() widgetEvent = new EventEmitter<LogWidgetEventStruct>();
  forwardEvent(e, widgetEvent: LogWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }
  public expandCommands = false;
  public LogWidgetEnum = LogWidgetEnum;
  public expand = false;
  public expandRaw = false;
  public openRow = false;
  constructor() {
    super();

  }
}


