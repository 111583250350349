import {DataManagementLogicalWidgetEnum, LogicalWidgetEnum,} from '../../enums/widget';
import {LogicalWidget} from '../logical-widget';
import {HttpClient} from '@angular/common/http';
import {FirebaseAuthService} from '../../noizu/services/firebase-auth.service';

export class DataManagementLogicalWidget extends LogicalWidget{
  public selected: any = null;

  logical_widget_type(): LogicalWidgetEnum | null {
    return LogicalWidgetEnum.LOGICAL_WIDGET__DATA_MANAGEMENT;
  }

  data_management_logical_widget_type(): DataManagementLogicalWidgetEnum | null {
    return DataManagementLogicalWidgetEnum.DATA_MANAGEMENT_LOGICAL_WIDGET__GENERIC;
  }

  constructor() {
    super();
  } // end constructor
} // end Package

