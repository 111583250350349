
import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from '../lacrosse.entity';
import { FirebaseAuthService }            from '../../noizu/services/firebase-auth.service';
import {ElixirEntity} from '../elixir.entity';
import {WidgetEnum} from '../../enums/widget';

export class UserPermissionEntity extends ElixirEntity {
  public staff_level: any = 10;
  public effective_permissions: any = {};
  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    this.effective_permissions = json["effective_permissions"];
    this.staff_level = json["staff_level"];
  } // end constructor



  widget_type() {
    return WidgetEnum.EMBED_WIDGET__PERMISSION_ENTITY;
  }

} // end Package
