import {Component, Input, Inject, OnInit, Output, EventEmitter} from '@angular/core';
import {FirmwareTargetLogicalWidget} from '../../../../entities';
import {FirmwareLogicalWidgetEventStruct} from './firmware-logical-widget-event.struct';

@Component({
  selector: 'firmware-target-logical-widget',
  template: `
    <div *ngIf="entity">
      [SELECT TARGET]
    </div>
  `
})
export class FirmwareTargetLogicalWidgetComponent implements OnInit {
  @Input() entity: FirmwareTargetLogicalWidget | null = null;
  @Input() templateHint: string = null;
  @Input() options: any = null;
  @Input() layout = 'standard';

  @Output() widgetEvent = new EventEmitter<FirmwareLogicalWidgetEventStruct>();
  forwardEvent(e, widgetEvent: FirmwareLogicalWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }
  updateOutput() {
    let event = new FirmwareLogicalWidgetEventStruct()
    event.event_widget_type = this.entity.widget_type();
    event.event_logical_widget_type = this.entity.logical_widget_type();
    event.event_firmware_logical_widget_type = this.entity.firmware_logical_widget_type();
    event.event_type = "firmware_target_selection"
    event.event_body = {entity: this.entity.selected}
    this.widgetEvent.emit(event);
  }

  public valueSubscriber: any = null;

  constructor() {

  }

  public ngOnInit() {
    this.entity.selected = this.entity.subject
  }
}
