
    <div class="alert alert-info">
      <div class="row" *ngFor="let entry of listFormat">
        <div class="col-auto">
          <b>{{entry.key}}</b>
        </div>
        <div class="col-auto">
          <ng-container *ngIf="entry.is_object">
            <render-object [object]="entry.value" [exclude]="exclude" ></render-object>
          </ng-container>
          <ng-container *ngIf="!entry.is_object">
            {{entry.value| json}}
          </ng-container>
        </div>
      </div>
    </div>
  