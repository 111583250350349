<page-header [section]="'general-reporting'">
  <page-title>General Reporting</page-title>
  <page-blurb>Manage and check status of PSK Category, Gateways and Sensors.</page-blurb>
</page-header>

<div class="row">
  <div class="col-12 overlayParent">
    <div class="card">
      <div class="overlay" *ngIf="weatherRanges.loading==true">
        <widget-spinner></widget-spinner>
      </div>
      <div>
        <div class="card-header">
          <div class="row">
            <div class="col-12"><h6>Weather service PSK Range lookup</h6></div>
            <div class="col-2" style="min-width:135px">
              <select [(ngModel)]="categorySelector" class="form-control">
                <option value="1">Category 1</option>
                <option value="2">Category 2</option>
                <option value="3">Category 3</option>
              </select>
            </div>
            <div class="col">
              <button class="btn-sm btn-primary" style="margin-top:0px" (click)="getWeatherLoaded()">
                Get Ranges in Category
              </button>
            </div>
          </div>
        </div>
        <div class="card-block" *ngIf="weatherRanges.categories">
          <div class="col-12" style="margin:5px" *ngIf="weatherRanges.categories['1'].length > 0">
            Category 1: <br>
            <div style="width:100%" *ngFor="let r of weatherRanges.categories['1']">
              {{r}}
            </div>
          </div>
          <div class="col-12" style="margin:5px" *ngIf="weatherRanges.categories['2'].length > 0">
            Category 2: <br>
            <div style="width:100%" *ngFor="let r of weatherRanges.categories['2']">
              {{r}}
            </div>
          </div>
          <div class="col-12" style="margin:5px" *ngIf="weatherRanges.categories['3'].length > 0">
            Category 3: <br>
            <div style="width:100%" *ngFor="let r of weatherRanges.categories['3']">
              {{r}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 overlayParent">
    <div class="card">
      <div class="overlay" *ngIf="report.data.gateways == -1">
        <widget-spinner></widget-spinner>
      </div>
      <div>
        <div class="card-header">
          Gateways
          <button class="btn-sm btn-primary float-right" (click)="report.getGatewaysTally()">
            Get Tally
          </button>
        </div>
        <div class="card-block">
          <div *ngIf="report.data.gateways && report.data.gateways != -1">
            <pre>{{report.data.gateways|json}}</pre>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 overlayParent">
    <div class="card">
      <div class="overlay" *ngIf="report.data.sensors == -1">
        <widget-spinner></widget-spinner>
      </div>
      <div>
        <div class="card-header">
          Sensors
          <button class="btn-sm btn-primary float-right" (click)="report.getSensorsTally()">
            Get Tally
          </button>
        </div>
        <div class="card-block">
          <div *ngIf="report.data.sensors && report.data.sensors != -1">
            <pre>{{report.data.sensors |json}}</pre>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12">
    <div class="card">
      <div class="card-header">
        View status checker
        <button (click)="checkView($event)" class="btn btn-info float-right">Get last check result</button>
      </div>
      <div class="card-block">
        <div *ngIf="!(alarmActive === -1)">
          <button class="btn-sm btn-primary float-right" *ngIf="!alarmActive" (click)="changeActivation(true)"> Activate Alarm</button>
          <button class="btn-sm btn-primary float-right" *ngIf="alarmActive" (click)="changeActivation(false)"> Deactivate Alarm</button>
        </div>
        <div *ngIf="checkDate">
          Time: {{checkDate|date:'MM/dd/yyyy @ h:mm:ssa'}} <br/>Status: {{checkStatus}}
        </div>
        <div *ngIf="statusObtained" class="row">
          <div class="col-12">
            <div *ngIf="!(checkVars.active == 0)">Active</div>
            <div *ngIf="checkVars.active == 0">Deactivated</div>
          </div>
          <div class="col-12 my-1">
            Time before device marked as not seen: {{checkVars.notSeenSeconds}}
          </div>
          <div class="col-12 my-1">
            Minimum time between Notificaiton Emails: {{checkVars.emailGap/60}} Minutes
          </div>
          <div class="col-12 my-1">
            Maximum time to wait for device status before timeout: {{checkVars.deviceCallTimeout}} Seconds
          </div>
          <div class="col-12 my-2">
            <div class="vcenter">
              <span class="mx-2">Set</span>
              <select [(ngModel)]="alarmType" class="col-2 floatLeft form-control">
                <option value="2">Not Seen Seconds</option>
                <option value="3">Email Minimum Time Seconds</option>
                <option value="4">Device status Timeout Seconds</option>
              </select>
              <span class="mx-2">to</span>
              <input class="form-control floatLeft col-1" id="val1" name="var1" placeholder="Seconds" type="number" [(ngModel)]="alarmVal" autocomplete="off">
              <button (click)="updateView(1, $event)" class="btn btn-primary floatLeft">Set Value</button>
            </div>
          </div>
          <div class="col-12">
            Devices to monitor:<br>
            <div *ngFor="let id of checkVars.deviceIds" class="my-2 col-12">
              {{id.val}} <button *ngIf="deleteToggle" class="btn btn-danger btn-sm" (click)="delete($event, id.key)">Delete</button>
            </div>
          </div>
          <div class="col-12">
            Emails to notify:<br>
            <div *ngFor="let email of checkVars.emails" class="my-2 col-12">
              {{email.val}} <button *ngIf="deleteToggle" class="btn btn-danger btn-sm" (click)="delete($event, email.key)">Delete</button>
            </div>
          </div>
          <div class="col-12 my-2">
            <div class="vcenter">
              <span class="mx-2">Add to </span>
              <select [(ngModel)]="alarmType2" class="col-2 floatLeft form-control">
                <option value="5">Devices</option>
                <option value="6">Emails</option>
              </select>
              <span class="mx-2">Value</span>
              <input class="form-control floatLeft col-3" id="val2" name="var2" placeholder="Id/Email" type="text" [(ngModel)]="alarmVal2" autocomplete="off">
              <button (click)="updateView(2, $event)" class="btn btn-primary floatLeft">Add Device/Email</button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer" *ngIf="checkDate">
        <button class="btn btn-warning floatRight" (click)="deleteToggle = !deleteToggle">Toggle Delete Buttons</button>
      </div>
    </div>
  </div>
</div>
