
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { DomainObjectRepo } from '../../noizu/domain-object-repo';
import { FirebaseAuthService }        from '../../noizu/services/firebase-auth.service';
import { CmsAlertEntity }      from '../../entities/cms/';
import { AppengineRepo }      from '../appengine.repo';

@Injectable()
export class CmsAlertRepo extends AppengineRepo {
  public _kind = "null";
  public _singular = "null";
  public _appengine = false;

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  entity(record) {
    return new CmsAlertEntity(this.client, this.auth, record)
  }

  getCms(options = {}){
    return this._getListPromise(`${this.ingvEndpoint()}/cms`, (record) => {return this.entity(record); }, options);
  }

  getVersionHistory(id, options={}) {
    return this._getListPromise(`${this.ingvEndpoint()}/cms/${id}/versions`, (record) => {return record; }, options);
  }

}
