
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { FirebaseAuthService }        from '../../noizu/services/firebase-auth.service';
import { AppengineRepo }      from '../appengine.repo';
import { UserAlertEventEntity } from '../../entities/user/alert-event.entity';

@Injectable()
export class UserAlertEventRepo extends AppengineRepo {
  public _kind = "channels";
  public _singular = "channel";

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  entity(record) {
    return new UserAlertEventEntity(this.client, this.auth, record)
  }

  getUserRecentSimplifiedListPromise(userIdentifier: string, options = {}) {
    let url = `${this.apiBase()}/user/${userIdentifier}/alert-events/recent/as-templates`;
    return this._get(
      url,
      (payload, resolve) => {resolve(payload);},
      {}
    );
  }

  getUserRecentListPromise(userIdentifier: string, options = {}) {
    let url = `${this.apiBase()}/user/${userIdentifier}/alert-events/recent`;
    return this._getListPromise(url, (record) => {return this.entity(record); }, options);
  }

  getUserListPromise(userIdentifier:string, options = {}) {
    let url = `${this.apiBase()}/user/${userIdentifier}/alert-events`;
    return this._getListPromise(url, (record) => {return this.entity(record); }, options);
  }

}
