
import { Component, Inject, Input } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location }               from '@angular/common';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { ReportingEntity } from '../../../entities/reporting.entity';
import { ReportingRepo } from '../../../repos/data/reporting.repo';

@Component({
  templateUrl: './show.component.html',
})
export class ReportingShowComponent {
  public report: ReportingEntity;
  public weatherRanges = {"loading":false,"categories":{"1":[],"2":[],"3":[]}};
  public categorySelector = 1;
  public deleteToggle = false;
  public checkStatus = null;
  public checkDate = null;
  public checkVars = {"active":true,"notSeenSeconds":300,"emailGap":2700,"deviceCallTimeout":4,"deviceIds":[],"emails":[]};
  public statusObtained = false;
  public alarmType = "2";
  public alarmVal = "";
  public alarmType2 = "5";
  public alarmVal2 = "";
  public alarmActive = -1;
  public varsIds = {"active":1,"notSeenSeconds":2,"emailGap":3,"deviceCallTimeout":4};

  constructor(public repo: ReportingRepo) {
    this.report = repo.entity(null);
  }

  //Activate or deactivate the status alarm
  changeActivation(activate) {
    let vars = {"action":"update", "id":"1", "type":"1", "val":"1"};
    if (!activate) {
      vars.val = "0";
    }
    this.repo.updateViewAlert(vars).then((ret) => {this.checkView(null);})
  }

  //Call the Endpoint to get the ranges loaded in the weather service
  getWeatherLoaded() {
    this.weatherRanges.loading = true;
    //Empty the list for the selected category if anything is there already
    this.weatherRanges.categories[this.categorySelector] = [];
    this.repo.getWeatherCategory(this.categorySelector).then((ret) =>{
      //Results are Start1::End1;Start2::End2
      //Split the string by ; to get individual ranges
      let arr = ret.toString().split(";")
      //for each range
      for(let i = 0; i < arr.length; i++) {
        let val = arr[i];
        //Turn the :: into a - so we have Start-End
        val = val.replace("::", "-");
        //Push it to the display array
        this.weatherRanges.categories[this.categorySelector].push(val);
      }
      this.weatherRanges.loading = false;
    }).catch((error) => {
      this.weatherRanges.loading = false;
      this.weatherRanges.categories[this.categorySelector].push("Error loading Try again");
    });
  }

  //Prepare varaibles for update and call the function to update
  updateView(branch, $event) {
    $event.preventDefault();
    let vars = {"action":"update", "id":0, "type":0, "val":"test"};
    let sw = parseInt(this.alarmType);
    if(branch == 1) {
      switch(sw) {
        case 2:
          vars.id = this.varsIds.notSeenSeconds
        break;
        case 3:
          vars.id = this.varsIds.emailGap
        break;
        case 4:
          vars.id = this.varsIds.deviceCallTimeout
        break;
      }
      vars.val = this.alarmVal;
    } else if (branch == 2) {
      vars.action = "add";
      vars.type = parseInt(this.alarmType2);
      vars.val = this.alarmVal2;
    }
    this.callAlertSystem(vars);
  }

  //Delete a device or email from the view status checker
  delete($event, id) {
    $event.preventDefault();
    this.callAlertSystem({"action":"delete", "id":id, "type":0, "val":"test"});
  }

  //Call the endpoint to set the alert variables
  callAlertSystem(vars) {
    /*
    LIMITS:
    5-3600 seconds not Seen
    300-21600 seconds email delay
    1-10 seconds (decimals allowed) timeout

    If out of range, defaults to 600, 2700, and 4 respectively
    */
    this.repo.updateViewAlert(vars)
    .then((ret) => {
      this.checkView(null);
    })
  }

  //Get the view alarm stored variables
  checkView($event) {
    if ($event) {
      $event.preventDefault();
    }
    this.repo.getViewStatus().then((ret)=> {
      let d = new Date();
      d.setTime(ret["time"]*1000);
      this.checkDate = d;
      this.checkStatus =  ret["status"] == 1 ? "good" : "down";

    });
    this.repo.getViewVars().then((ret)=> {
      this.checkVars.deviceIds = [];
      this.checkVars.emails = [];
      //Variables are stored as an ID and a value Check the ID to determine what the value means
      for(let i = 0; i < ret["length"]; i++) {
        switch(ret[i]["type"]) {
          case "1":
            this.checkVars.active = ret[i]["val"];
            this.alarmActive = ret[i]["val"] == 0 ? 0 : 1;
            this.varsIds.active = ret[i]["id"];
          break;
          case "2":
            this.checkVars.notSeenSeconds = ret[i]["val"];
            this.varsIds.notSeenSeconds = ret[i]["id"];
          break;
          case "3":
            this.checkVars.emailGap = ret[i]["val"];
            this.varsIds.emailGap = ret[i]["id"];
          break;
          case "4":
            this.checkVars.deviceCallTimeout = ret[i]["val"];
            this.varsIds.deviceCallTimeout = ret[i]["id"];
          break;
          case "5":
            this.checkVars.deviceIds.push({"val":ret[i]["val"], "key":ret[i]["id"]});
          break;
          case "6":
            this.checkVars.emails.push({"val":ret[i]["val"], "key":ret[i]["id"]});
          break;

        }
      }
      this.statusObtained = true;
    });

  }
}
