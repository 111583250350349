
import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from './lacrosse.entity';
import { FirebaseAuthService }            from '../noizu/services/firebase-auth.service';

export class SubscriptionsEntity extends LacrosseEntity {
  public notes: String = "";
  public name: String;
  public new: boolean = false;
  public description = "";
  public code = "";
  public features;

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    this._kind = "subscriptions";
    this._singular = "subscription";
    this.name = json.name;
    if (json.new) {
      this.new = json.new;
    }
    this.refresh(json);
  } // end constructor

  // Sets the fields, used above, and to reset the values when canceling an edit
  refresh(data) {
    let res = super.refresh(data);
    this.identifier = data.id || data.identifier;
    this.name = data.name;
    this.description = data.description;
    this.code = data.code;
    this.features = {};
    if (data.features) {
      if (data.features.attributes) {
        this.features["attributes"] = data.features.attributes;
      }

      if (data.features.export) {
        this.features["export"] = data.features.export;
      } else {
        this.features["export"] = {"available":false};
      }

      if (data.features.view) {
        this.features["view"] = data.features.view;
      } else {
        this.features["view"] = {"available":false};
      }

      if (data.features.notifications) {
        this.features["notifications"] = data.features.notifications;
      }
    } else {
      this.features = {"attributes":null,"export":{"available":false},"view":{"available":false},"notifications":{}};
    }
    return res;
  }

} // end Package
