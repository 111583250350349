import { Component, Input, Inject } from '@angular/core';
import {DeviceEntity} from '../../entities/device.entity';

@Component({
  selector: 'embed-device',
  template: `
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">Device</div>
          <div class="card-body"><pre>{{entity?.toJson(options) | json}}</pre></div>
        </div>
      </div>
    </div>
    `
})
export class EmbedDeviceComponent {
  @Input() entity: DeviceEntity = null;
  @Input() options: any = null;
  @Input() layout = 'standard';
  constructor() {
  }
}
