
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { DomainObjectRepo } from '../../noizu/domain-object-repo';
import { FirebaseAuthService }        from '../../noizu/services/firebase-auth.service';
import { SubscriptionsEntity }      from '../../entities/subscriptions.entity';
import { AppengineRepo }      from '../appengine.repo';

@Injectable()
export class SubscriptionsRepo extends AppengineRepo {
  public _kind = "subscriptions";
  public _singular = "subscription";

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  entity(record) {
    return new SubscriptionsEntity(this.client, this.auth, record)
  }
}
