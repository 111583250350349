import {ValueAndUnit} from '../../../structs/value-and-unit';
import {AlertFacadeEntity} from '../facade.entity';
import {WidgetEnum_Alert_Facade} from '../../../enums/widget/alert';

export class AlertOnOffFacadeEntity extends AlertFacadeEntity {
  public triggerIfEngaged: any;

  public constructor(alert, json) {
    super(alert, json);
    this.triggerIfEngaged = json.triggerIfEngaged;
  }

  //-----------------------------------
  //
  //-----------------------------------
  public get template(): string | null {
    return 'on-off';
  }

  alert_facade_widget() {
    return WidgetEnum_Alert_Facade.ALERT_FACADE_WIDGET__ON_OFF;
  }
}
