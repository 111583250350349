
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { DomainObjectRepo } from '../noizu/domain-object-repo';
import { FirebaseAuthService }        from '../noizu/services/firebase-auth.service';
import { AppengineRepo }      from './appengine.repo';
import { SensorTypeRangeEntity }      from '../entities/sensor-type-range.entity';

@Injectable()
export class SensorTypeRangeRepo extends AppengineRepo {
  public _kind = "sensor-type-ranges";
  public _singular = "sensor-type-range";

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  entity(record) {
    return new SensorTypeRangeEntity(this.client, this.auth, record)
  }
}
