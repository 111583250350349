
import { Component, Inject, Input, NgZone } from '@angular/core';

@Component({
  templateUrl: './user-reports.component.html',
})
export class SystemHealthUserReportComponent {
  constructor() {
  }
}
