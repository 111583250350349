import { NoizuStruct }        from '../../../noizu/structs/noizu-struct';
import { DeviceDefinitionAggregationRule } from './aggregation-rule';
import {DeviceFieldDefinitionEntity} from '../../../entities/device/field/definition.entity';
import {HttpClient} from '@angular/common/http';
import {FirebaseAuthService} from '../../../noizu/services/firebase-auth.service';
import {DeviceDefinitionEntity} from '../../../entities/device/definition.entity';
import {AggregationTypeEnum} from '../../../enums/aggregation-type.enum';
import {AggregationLevelEnum} from '../../../enums/aggregation-level.enum';

export class DeviceDefinitionFieldEntry extends NoizuStruct {
  public identifier: string;
  public jsonName: string;
  public sensorFieldId: number; // @TODO entity reference
  public inputFields: any; // map string => string
  public aggregationRules: Array<DeviceDefinitionAggregationRule>; // @TODO type
  public field : DeviceFieldDefinitionEntity;
  public pending = false;
  private _entryInputs: any;

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super();
    this.identifier = json["identifier"];
    this.jsonName = json["jsonName"] || json["json_name"];
    this.sensorFieldId = json["sensorFieldId"];
    this.inputFields = json["inputFields"] || json["input_fields"];
    this.strip_from_json["_entryInputs"] = 1;
    this.strip_from_json["entryInputs"] = 1;
    this.strip_from_json["pending"] = 1;

    if (json["field"]) {
      this.field = new DeviceFieldDefinitionEntity(client, auth, json["field"]);
    } else {
      this.field = null;
    }


    let ar = json["aggregationRules"] || json["aggregation_rules"];
    this.aggregationRules = null;
    if (ar) {
      this.aggregationRules = [];
      for(let i = 0; i < ar.length; i++) {
        this.aggregationRules.push(new DeviceDefinitionAggregationRule(ar[i]));
      }
    }
  } // end constructor
  widget() {
    return "embed-device-type-field-entry";
  }

  deleteRetentionRule(input) {
    this.pending = true;
    this.aggregationRules.splice(input, 1);
  }

  deleteInput(input) {
    this.pending = true;
    delete this.inputFields[input.key];
    this._entryInputs = null;
    this.entryInputs;
  }


  addRetentionRule(input) {
    this.pending = true;
    this.aggregationRules.push(new DeviceDefinitionAggregationRule(input));
  }

  addInput(input) {
    this.pending = true;
    if (!this.inputFields) this.inputFields = {};
    this.inputFields[input.key] = input.value;
    this._entryInputs = null;
    this.entryInputs;
  }

  get entryInputs() {
    if (this._entryInputs) return this._entryInputs;
    this._entryInputs = [];
    if (this.inputFields) {
      for(const key in this.inputFields) {
        let value = this.inputFields[key];
        this._entryInputs.push({key: key, value: value});
      }
    }
    return this._entryInputs;
  }
  set entryInputs(v) {
    this._entryInputs = v;
  }

  binaryEncode(input) {
    return this.field.binaryEncode(input);
  }



}
