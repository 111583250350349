
      <div class="embed-ds-time-card" *ngIf="!editMode">
          <div class="row">
              <div class="col font-xl"><b>Type:</b> {{entity.link.linkType()}}</div>
              <div class="col font-xl"><b>Language:</b> {{entity.link.linkLanguage()}}</div>
          </div>
          <div class="row">
              <div class="col font-xl"><b>TimeZone:</b> {{entity.link.time_zone}}</div>
          </div>
      </div>
      <div class="embed-ds-time-card" *ngIf="editMode">
          <div class="row">
              <div class="col-12">
                  <div class="card mb-0 pb-0 border-warning">
                      <div class="card-body" *ngIf="entity">
                          <!-- Type Specific -->
                          <div class="row"><div class="col-4"><b>Type</b></div><div class="col-8">
                            <ng-select
                              placeholder="Link Type"
                              [name]="'[link]type'"
                              [items]="linkOptions"
                              [multiple]="false"
                              [(ngModel)]="entity.link.type"
                              bindLabel="text"
                              bindValue="value"
                              (ngModelChange)="setLink($event)"
                            ></ng-select>


                          </div></div>

                          <div class="row"><div class="col-4"><b>Language</b></div><div class="col-8">

                            <ng-select
                              placeholder="Link Language"
                              [name]="'[link]language'"
                              [multiple]="false"
                              [(ngModel)]="entity.link.language"
                              bindLabel="text"
                              bindValue="value"
                              (ngModelChange)="setLanguage($event)"
                              [items]="languageOptions"
                            ></ng-select>

                          </div></div>

                          <div class="row"><div class="col-4"><b>Time Zone</b></div><div class="col-8">

                            <ng-select
                              placeholder="Link TimeZone"
                              [name]="'[link]tz'"
                              [multiple]="false"
                              [(ngModel)]="entity.link.time_zone"
                              bindLabel="text"
                              bindValue="value"
                              (ngModelChange)="setTimeZone($event)"
                              [items]="timeZoneOptions"
                            ></ng-select>

                          </div></div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    