import {NoizuStruct} from '../../noizu/structs/noizu-struct';
import {ForecastReading} from './weather-card.entity';


export enum NwsReading {
  wind_speed,
  wind_direction,
  wind_gust,
  snow_accumulation,
  chance_tornado,
  chance_hail,
  chance_damaging_thunderstorms,
  sky_cover,
  disabled,
  unknown
}

export class DataStreamNwsCardEntity extends NoizuStruct {
  public _kind = 'GoldenRatio.DataStream.Card.NationalWeatherServiceCard.V1_1';
  public messages = {one: ':auto', two: ':auto'};
  public link: NwsReading | undefined = NwsReading.unknown;
  public modified = false;

  constructor(json) {
    super();
    if (json['messages']) {
      this.messages.one = json['messages']['one'];
      this.messages.two = json['messages']['two'];
    }

    if (json['link']) {
      this.link = (<any>NwsReading)[json['link']];
      if (this.link === undefined) {
        console.log(`Unsupported Forecast Link: ${json['link']}`);
        this.link = NwsReading.unknown;
      }
    } else {
      this.link = NwsReading.unknown;
    }
  } // end constructor

  linkType() {
    return NwsReading[this.link];
  }

  toJson(options: any = {}) {
    return {
      messages: this.messages,
      link: this.linkType(),
    }
  }

  validate() {
    const errors = [];
    if (this.link === undefined || this.link === null || this.link === NwsReading.unknown || this.link === NwsReading.disabled) {
      errors.push({error: 'NoLink', message: 'Nws Link Required'});
    }
    return errors;
  }

  widget() {
    return 'embed-ds-nws-card';
  }
} // End Package
