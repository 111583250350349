import { Component, Input, Inject } from '@angular/core';
import {LacrosseEntity} from "../../entities/lacrosse.entity";
import {NoizuStruct} from "../../noizu/structs/noizu-struct";

@Component({
  selector: 'embed-default',
  template: `
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">Unsupported Type: {{entityType()}}</div>
          <div class="card-body"><pre>{{defaultJson() | json}}</pre></div>
        </div>
      </div>
    </div>
    `
})
export class EmbedDefaultComponent {
  @Input() entity: any = null;
  @Input() options: any = null;
  @Input() layout: string = null;
  constructor() {
  }


  defaultJson() {
    if (this.entity && this.entity instanceof NoizuStruct) {
      return this.entity.toJson(this.options);
    } else {
      return this.entity;
    }
  }

  entityType() {
    if (this.entity && this.entity instanceof NoizuStruct) {
      return this.entity.widget();
    } else {
      return 'Unknown';
    }
  }

}
