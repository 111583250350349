
    <div class="row">
      <div class="col-12">
        <div class="card m-0 p-0 mb-1" *ngIf="entity">
          <div class="card-header">
            <div class="row">
              <div class="col-1">
            <span class="badge badge-pill" [ngClass]="{
            'badge-info': entity.level == 'info',
            'badge-warning': entity.level == 'warn',
            'badge-danger': entity.level == 'error',
            'badge-secondary': !(entity.level == 'error' || entity.level == 'warn' || entity.level == 'info')
            }">{{entity.level}}</span>
              </div>
              <div class="col-11">
                <strong>
                <span>ReportBinary (<a
                    routerLinkActive="active"
                    [routerLink]="['/portal/devices/show/' + entity?.message?.encoding?.device]"
                  >{{entity?.message?.encoding?.device}}</a>)</span>
                </strong>
                <span> ({{entity?.message?.encoding?.envelope?.firmware}})</span>
                <span> : {{entity.date_time | date:'MM/dd/yyyy HH:mm:ss'}}</span>
                <span class="float-right">
              <i
                (click)="expand = !expand"
                class="fa fa-sm"
                [ngClass]="{'fa-chevron-down': !expand, 'fa-chevron-up': expand}"> </i>
            </span>
              </div>
            </div>
          </div>
          <div class="card-body m-0 p-1" *ngIf="expand">
            <div class="row mb-1">

              <div class="col-3">
                <div class="row">
                  <div class="col-12">
                  <b>Topic</b>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                  {{entity.topic}}
                  </div>
                </div>
              </div>

              <div class="col-3">
                <div class="row">
                  <div class="col-12">
                    <b>Level</b>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    {{entity.level}}
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div class="row">
                  <div class="col-12">
                    <b>Time</b>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    {{entity.date_time | date:'MM/dd/yyyy HH:mm:ss'}}
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <div class="row mb-4">
              <div class="col-12">
                <div>
                  <div class="row">
                    <div class="col-3"><b>Device</b></div>
                    <div class="col-9">
                      <a
                        routerLinkActive="active"
                        [routerLink]="['/portal/devices/show/' + entity.message.encoding.device]"
                      >{{entity?.message?.encoding?.device}}</a>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-3"><b>Raw</b></div>
                    <div class="col-9">
                      <span class="badge badge-pill badge-primary">{{entity?.message?.encoding?.internal}}</span>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-3"><b>Received On</b></div>
                    <div class="col-9">{{entity?.message?.encoding?.envelope?.received_on}}</div>
                  </div>

                  <div class="row">
                    <div class="col-3"><b>Gateway</b></div>
                    <div class="col-9">
                      <a
                        routerLinkActive="active"
                        [routerLink]="['/portal/devices/show/' + entity?.message?.encoding?.envelope?.gateway]"
                      >{{entity?.message?.encoding?.envelope?.gateway}}</a>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-3"><b>Firmware</b></div>
                    <div class="col-9">{{entity?.message?.encoding?.envelope?.firmware}}</div>
                  </div>

                  <div class="row mb-2">
                    <div class="col-3"><b>Event</b></div>
                    <div class="col-9">{{entity?.message?.encoding?.envelope?.event}}</div>
                  </div>

                  <div class="div-table-th">
                    <div class="row">
                      <div class="col-2">
                        Field
                      </div>
                      <div class="col-2">
                        Raw Value
                      </div>
                      <div class="col-4">
                        Unit
                      </div>
                      <div class="col-4">
                        Attributes
                      </div>
                    </div>
                  </div>

                  <div *ngFor="let reading of entity.readings(); let i = index;"
                       [ngClass]="{'div-table-tr': (i % 2) == 1, 'div-table-tr-alt': (i % 2) == 0}">
                    <div class="row">
                      <div class="col-2">
                        {{reading.field}}
                      </div>
                      <div class="col-2">
                        {{reading.value | number}}
                      </div>
                      <div class="col-4">
                        {{reading.unit}}
                      </div>
                      <div class="col-4">
                        <pre>{{reading.attributes| json}}</pre>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                        Raw Data<span class="float-right">
              <i
                (click)="expandRaw = !expandRaw"
                class="fa fa-sm"
                [ngClass]="{'fa-chevron-down': !expandRaw, 'fa-chevron-up': expandRaw}"> </i>
            </span>
                  </div>
                  <div class="card-body" *ngIf="expandRaw">
                    <div class="row">
                      <div class="col-12">
                        <pre>Message: {{entity.message| json}}</pre>
                        <pre>Context: {{entity.context| json}}</pre>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
    