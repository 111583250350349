import {Component, Input, Output, EventEmitter} from '@angular/core';
import {EntityLogEntity} from '../../../../entities/log/entity-log.entity';
import {WidgetBase} from '../../widget-base';
import {LogWidgetEventStruct} from '../log-widget-event.struct';
import {LogWidgetEnum} from '../../../../enums';

@Component({
  selector: 'gateway-wake-log-details-widget',
  template: `
    <ng-container *ngIf="entity">
      <ng-container [ngSwitch]="layout">
        <div class="gateway-wake-log-details-widget" *ngSwitchCase="'card-details'">

          <hr />



          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-2"><b>Gateway</b>:</div>
                <div class="col-10">
                  <a
                    routerLinkActive="active"
                    [routerLink]="['/portal/devices/show/ref.device.' + entity.message.payload.GatewaySerial + '@V2']"
                  >ref.gateway.{{entity.message.payload.GatewaySerial}}@V2</a>
                </div>
              </div>
              <div class="row">
                <div class="col-2"><b>Build</b>:</div>
                <div class="col-10">{{entity.message.payload.build}}  <strong  *ngIf="entity.message.payload.git">&lt;{{entity.message.payload.git}}&gt;</strong></div>
              </div>
              <div class="row">
                <div class="col-2"><b>Firmware</b>:</div>
                <div class="col-10">{{entity.message.payload.Firmware}}</div>
              </div>

              <div class="row">
                <div class="col-2"><b>Wifi Strength</b>:</div>
                <div class="col-10">
                  RSSI {{entity.message.payload.rssi || 'unknown'}}
                  <ng-container *ngIf="entity.message.payload.rssi">
                    <span *ngIf="entity.message.payload.rssi <= -90">(Unusable)</span>
                    <span *ngIf="entity.message.payload.rssi > -90 && entity.message.payload.rssi <= -80 ">(Bad Signal)</span>
                    <span *ngIf="entity.message.payload.rssi > -80 && entity.message.payload.rssi <= -60 ">(Okay)</span>
                    <span *ngIf="entity.message.payload.rssi > -60 && entity.message.payload.rssi <= 0 ">(Very Good)</span>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-12">
              <b>Refs</b>: <pre>{{entity.message.refs | json}}</pre>
            </div>
          </div>



          <div class="row mt-2" *ngIf="entity?.message?.payload?.ExtMCU">
            <div class="col-12">
              <div class="row">
                <div class="col"><b>MCU OTA Details</b></div>
              </div>
              <div class="alert alert-success">
                <div class="row">
                  <div class="col-4">Last Reported MCU</div><div class="col-8">{{entity?.message?.payload?.MCU}}</div>
                  <div class="col-4">Next Status</div><div class="col-8">{{entity.StatusCode(entity?.message?.payload?.ExtMCU?.S)}}</div>
                  <div class="col-4">Next Version</div><div class="col-8">{{entity?.message?.payload?.ExtMCU?.N}}</div>
                  <div class="col-4">Next MCU Identifier</div><div class="col-8">{{entity?.message?.payload?.ExtMCU?.F}}</div>
                  <div class="col-4">Current ForceRef</div><div class="col-8">{{entity?.message?.payload?.ExtMCU?.Ic}}</div>
                  <div class="col-4">Pending ForceRef</div><div class="col-8">{{entity?.message?.payload?.ExtMCU?.Ip}}</div>
                  <div class="col-4">Current Ref</div><div class="col-8">{{entity?.message?.payload?.ExtMCU?.R}}</div>
                  <div class="col-4">Pending Ref</div><div class="col-8">{{entity?.message?.payload?.ExtMCU?.P}}</div>
                  <div class="col-4">Update Status</div><div class="col-8">{{entity.StatusCode(entity?.message?.payload?.ExtMCU?.US)}}</div>
                  <div class="col-4">Resume Download After</div><div class="col-8">{{entity?.message?.payload?.ExtMCU?.D}}</div>
                  <div class="col-4">Last IAP Sent</div><div class="col-8">{{entity?.message?.payload?.ExtMCU?.Sent}}</div>
                  <div class="col-4">Processing Segment</div><div class="col-8">{{entity?.message?.payload?.ExtMCU?.Seg}}</div>
                  <div class="col-4">Total Segments</div><div class="col-8">{{entity?.message?.payload?.ExtMCU?.Segs}}</div>
                </div>
              </div>

            </div>
          </div>

          <!-- Command History -->
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  <b>Command History</b>
                  <span class="float-right">
              <i
                (click)="expandCommands = !expandCommands"
                class="fa fa-sm"
                [ngClass]="{'fa-chevron-down': !expandCommands, 'fa-chevron-up': expandCommands}"> </i>
            </span>
                </div>
                <div class="card-body" *ngIf="expandCommands">
                  <div class="div-table-th">
                    <div class="row">
                      <div class="col-1">#</div>
                      <div class="col-4">Code</div>
                      <div class="col-7">Command</div>
                    </div>
                  </div>
                  <div
                    *ngFor="let command of entity.commandLog(); let i = index;"
                    [ngClass]="{'div-table-tr': (i % 2) == 1, 'div-table-tr-alt': (i % 2) == 0}">
                    <div class="row">
                      <div class="col-1">{{i}}</div>
                      <div class="col-4">{{command.code}}</div>
                      <div class="col-7">{{command.command}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>








        </div>
        <div class="gateway-wake-log-details-widget" *ngSwitchCase="'table-row-details'">


          <div class="text-dark">
            <div class="row mb-4">
              <div class="col-12">
                <pre>Firmware: {{entity.message.firmware | json}}</pre>
                <pre>Report: {{entity.message.gateway_report?.report | json}}</pre>
              </div>
            </div>




            <div class="row mt-2" *ngIf="entity?.message?.payload?.ExtMCU">
              <div class="col-12">
                <div class="row">
                  <div class="col"><b>MCU OTA Details</b></div>
                </div>
                <div class="alert alert-success">
                  <div class="row">
                    <div class="col-4">Last Reported MCU</div><div class="col-8">{{entity?.message?.payload?.MCU}}</div>
                    <div class="col-4">Next Status</div><div class="col-8">{{entity.StatusCode(entity?.message?.payload?.ExtMCU?.S)}}</div>
                    <div class="col-4">Next Version</div><div class="col-8">{{entity?.message?.payload?.ExtMCU?.N}}</div>
                    <div class="col-4">Next MCU Identifier</div><div class="col-8">{{entity?.message?.payload?.ExtMCU?.F}}</div>
                    <div class="col-4">Current ForceRef</div><div class="col-8">{{entity?.message?.payload?.ExtMCU?.Ic}}</div>
                    <div class="col-4">Pending ForceRef</div><div class="col-8">{{entity?.message?.payload?.ExtMCU?.Ip}}</div>
                    <div class="col-4">Current Ref</div><div class="col-8">{{entity?.message?.payload?.ExtMCU?.R}}</div>
                    <div class="col-4">Pending Ref</div><div class="col-8">{{entity?.message?.payload?.ExtMCU?.P}}</div>
                    <div class="col-4">Update Status</div><div class="col-8">{{entity.StatusCode(entity?.message?.payload?.ExtMCU?.US)}}</div>
                    <div class="col-4">Resume Download After</div><div class="col-8">{{entity?.message?.payload?.ExtMCU?.D}}</div>
                    <div class="col-4">Last IAP Sent</div><div class="col-8">{{entity?.message?.payload?.ExtMCU?.Sent}}</div>
                    <div class="col-4">Processing Segment</div><div class="col-8">{{entity?.message?.payload?.ExtMCU?.Seg}}</div>
                    <div class="col-4">Total Segments</div><div class="col-8">{{entity?.message?.payload?.ExtMCU?.Segs}}</div>
                  </div>
                </div>

              </div>
            </div>

            <!-- Command History -->
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                    <b>Command History</b>
                    <span class="float-right">
              <i
                (click)="expandCommands = !expandCommands"
                class="fa fa-sm"
                [ngClass]="{'fa-chevron-down': !expandCommands, 'fa-chevron-up': expandCommands}"> </i>
            </span>
                  </div>
                  <div class="card-body" *ngIf="expandCommands">
                    <div class="div-table-th div-table-light">
                      <div class="row">
                        <div class="col-1">#</div>
                        <div class="col-4">Code</div>
                        <div class="col-7">Command</div>
                      </div>
                    </div>
                    <div class=" div-table-light"
                      *ngFor="let command of entity.commandLog(); let i = index;"
                      [ngClass]="{'div-table-tr': (i % 2) == 1, 'div-table-tr-alt': (i % 2) == 0}">
                      <div class="row">
                        <div class="col-1">{{i}}</div>
                        <div class="col-4">{{command.code}}</div>
                        <div class="col-7">{{command.command}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



        </div>
      </ng-container>
    </ng-container>
    `
})
export class GatewayWakeLogDetailsWidgetComponent extends WidgetBase{
  @Input() entity: EntityLogEntity = null;
  @Input() options: any = {};
  @Input() layout: string = null;
  @Output() widgetEvent = new EventEmitter<LogWidgetEventStruct>();
  forwardEvent(e, widgetEvent: LogWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }
  public expandCommands = false;
  public LogWidgetEnum = LogWidgetEnum;
  public expand = false;
  public expandRaw = false;
  public openRow = false;
  constructor() {
    super();

  }
}
