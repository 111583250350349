import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { FirebaseAuthService }            from '../../noizu/services/firebase-auth.service';
import {ElixirEntity} from '../elixir.entity';
import {CacheService} from '../../services/cache.service';
import {FirmwareDefinitionEntity} from './definition.entity';
import {FirmwareApprovalEntity} from './approval.entity';
import {FirmwareReleaseRepo} from '../../repos/firmware/release.repo';
import {FirmwareApprovalRepo} from '../../repos/firmware/approval.repo';
import {FirmwareDefinitionRepo} from '../../repos/firmware/definition.repo';
import {EntityReference} from '../../noizu/structs/entity-reference';
import {NoizuStruct} from '../../noizu/structs/noizu-struct';
import {TargetConstraintStruct} from './target/constraints.struct';
import {FirmwareWidgetEnum, WidgetEnum} from '../../enums';
import {TargetStruct} from './target.struct';

export class TargetSetStruct extends NoizuStruct {

  public targets: Array<TargetStruct> = new Array<TargetStruct>();

  //-------------------------------
  // constructor
  //-------------------------------
  public constructor(json) {
    super();
    // TODO parse incoming json.
  }

  widget_type() {
    return WidgetEnum.EMBED_WIDGET__FIRMWARE;
  }

  firmware_widget_type() {
    return FirmwareWidgetEnum.FIRMWARE_WIDGET__TARGET_SET
  }

} // end Package
