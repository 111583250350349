import {Component, Input, Inject, Output, EventEmitter} from '@angular/core';
import {LogicalDeviceWidgetEnum, LogicalExtendedDeviceWidgetEnum, LogicalWidgetEnum, WidgetEnum} from '../../../../enums/widget';
import {ExtendedDeviceLogicalWidget} from '../../../../entities';
import {LogicalWidgetEventStruct} from '../../logical';

@Component({
  selector: 'extended-device-logical-widget',
  template: `
    <ng-container *ngIf="entity">
      <ng-container [ngSwitch]="entity.logical_extended_device_widget_type()">

        <extended-device-chart-logical-widget *ngSwitchCase="LogicalExtendedDeviceWidgetEnum.LOGICAL_EXTENDED_DEVICE_WIDGET__CHART" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"  ></extended-device-chart-logical-widget>
        <extended-device-reading-logical-widget *ngSwitchCase="LogicalExtendedDeviceWidgetEnum.LOGICAL_EXTENDED_DEVICE_WIDGET__READING" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></extended-device-reading-logical-widget>


        <!-- Default -->
        <div class="extended-device-logical-widget card" *ngSwitchDefault [ngClass]="{'mb-0': options?.nested}">
          <div class="card-header" [ngClass]="{'p-2 m-0': options?.nested}">LogicalExtendedDeviceWidgetEnum: {{ LogicalExtendedDeviceWidgetEnum[entity.logical_extended_device_widget_type()] }}</div>
          <div class="card-body">
            <pre>{{entity.toJson()| json}}</pre>
          </div>
        </div>
      </ng-container>
    </ng-container>
  `
})
export class ExtendedDeviceLogicalWidgetComponent {
  @Input() entity: ExtendedDeviceLogicalWidget | null = null;
  @Input() templateHint: string = null;
  @Input() options: any = null;
  @Input() layout = 'standard';
  @Output() widgetEvent = new EventEmitter<LogicalWidgetEventStruct>();
  forwardEvent(e, widgetEvent: LogicalWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }
  public  LogicalExtendedDeviceWidgetEnum = LogicalExtendedDeviceWidgetEnum;

  constructor() {

  }
}
