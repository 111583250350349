import { NoizuStruct }        from '../noizu/structs/noizu-struct';
import { FormulaTreeNode }        from './formula-tree/node';
import { ValueAndUnitUnit }   from './value-and-unit/unit';

export class Transformation extends NoizuStruct {
  public hierarchy: FormulaTreeNode;
  public unit: number;//ValueAndUnitUnit;

  constructor(json: any) {
      super();
      if (json) {
        this.hierarchy = new FormulaTreeNode(json["hierarchy"]);
        this.unit = json["unit"];//new ValueAndUnitUnit(json["unit"]);
      }

  } // end constructor
}
