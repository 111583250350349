
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { DomainObjectRepo } from '../../../noizu/domain-object-repo';
import { FirebaseAuthService }        from '../../../noizu/services/firebase-auth.service';
import { DeviceFieldDefinitionEntity }      from '../../../entities/device/field/definition.entity';
import { AppengineRepo }      from '../../appengine.repo';

@Injectable()
export class DeviceFieldDefinitionRepo extends AppengineRepo {
  public _kind = "sensor-fields";
  public _singular = "sensor-field";

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  entity(record) {
    return new DeviceFieldDefinitionEntity(this.client, this.auth, record)
  }


  filterOptions() {
    return "name:* name:\"*\" handle:* handle:\"*\" unit:* type:[0-9]* ";
  }

}
