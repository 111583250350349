import {Component, Input, Inject, OnInit} from '@angular/core';
import {FirmwareLinkV2Entity} from "../../../entities/firmware/link-v2.entity";
import {MCUFirmwareDefinitionEntity, MCUFirmwareLinkEntity} from '../../../entities/mcu/firmware';
import {MCUFirmwareDefinitionRepo} from '../../../repos/mcu/firmware';
import {BatchRepo, DeviceDefinitionRepo, UserRepo} from '../../../repos';
import {Observable, Observer} from 'rxjs';
import {AppengineEntityList} from '../../../noizu/structs/appengine-entity-list';
import {BatchEntity, DeviceDefinitionEntity} from '../../../entities';
import {TargetStruct} from '../../../entities/firmware/target.struct';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ModalOptions} from 'ngx-bootstrap/modal';

@Component({
  selector: 'embed-mcu-fw-link-v2',
  template: `
  <div [ngSwitch]="layout">

    <!-- ========================================================================== -->
    <!-- Table Layout                                                               -->
    <!-- ========================================================================== -->
    <div *ngSwitchCase="'table'">

      <div  [ngClass]="{'div-table-tr': (options?.index % 2) == 1, 'div-table-tr-alt': (options?.index % 2) == 0}">
        <div class="row">
          <div class="col text-left"><a class="btn btn-link" href="#" role="button">#{{entity?.identifier }}</a></div>
          <div class="col text-left">{{entity?.name }}</div>
          <div class="col text-left">

            <b class="btn-pill pl-2 pr-2" [ngClass]="{'bg-success': (entity.status == 'approved' || entity.status == 'managed'), 'bg-danger': entity.status == 'pending'}">
              {{entity?.status}}
            </b>

          </div>
          <div class="col text-left">{{firmwareName(entity?.firmware)}}</div>
          <div class="col text-left">
            <embed-firmware-target  [entity]="entity.target"  [layout]="'mini'" [options]="options"> </embed-firmware-target>
          </div>
          <div class="col text-left">{{entity?.approval}}</div>
        </div>
      </div>
    </div>

    <!-- ========================================================================== -->
    <!-- Modal Layout                                                               -->
    <!-- ========================================================================== -->
    <div *ngSwitchCase="'modal'">
      <ng-container *ngTemplateOutlet="defaultView"></ng-container>
    </div>

    <!-- ========================================================================== -->
    <!-- Default                                                                    -->
    <!-- ========================================================================== -->
    <div *ngSwitchDefault>
      <div class="card m-0 p-0">
        <div class="card-header bg-dark m-0 p-0">
          #{{entity?.identifier }}
        </div>
        <div class="card-body m-0 p-1">
          <ng-container *ngTemplateOutlet="defaultView"></ng-container>
        </div>

        <div class="card-footer" *ngIf="options.edit && !options?.modal_form && (entity.approval == null && entity?.userMayApprove() || entity?.userMayDelete() || entity?.pending)">
          <div class="row">
            <div class="col-4">
              <button *ngIf="entity?.pending" (click)="saveLink(linkModal, $event)" class="btn btn-success btn-block" type="submit"><i class="fa fa-plus-circle"></i> Save Changes</button>
            </div>
            <div class="col-4">
              <button *ngIf="entity.approval == null && entity?.userMayApprove()" (click)="approveLink(linkModal, $event)" class="btn btn-primary btn-block">Approve</button>
            </div>
            <div class="col-4">
              <button *ngIf="entity?.userMayDelete()" (click)="deleteLink(linkModal, $event)" class="btn btn-warning btn-block">Delete</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- ================== -->
  <!-- Default Template   -->
  <!-- ================== -->
  <ng-template #defaultView>
    <div class="row">
      <div class="col">
        <span><strong>Identifier: </strong><span>{{entity.identifier}}</span></span>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <span *ngIf="!options?.edit"><strong>Name: </strong><span>{{entity.name}}</span></span>
        <span *ngIf="options?.edit"><strong>Name: </strong><span><input type="text" [(ngModel)]="entity.name" (ngModelChange)="updateDefinition()" size="8" /></span></span>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <span><strong>Status: </strong><span>{{entity.status}}</span></span>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <span *ngIf="!options?.edit"><strong>Release: </strong><span>{{entity.release}}</span></span>
        <!-- <span *ngIf="options?.edit"><strong>Release: </strong><span><input type="text" [(ngModel)]="entity.release" (ngModelChange)="updateDefinition()" size="8" /></span></span> -->
        <span *ngIf="options?.edit"><strong>Release: </strong><span>{{entity.release}}</span></span>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <span *ngIf="!options?.edit"><strong>Approval: </strong><span>{{entity.approval}}</span></span>
        <span *ngIf="options?.edit"><strong>Approval: </strong><span>{{entity.approval}}</span></span>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <span *ngIf="!options?.edit"><strong>Firmware: </strong><span>{{entity.firmware}}</span></span>
        <div *ngIf="options?.edit">
          <strong>Firmware: </strong>
          <div *ngIf="available.firmware | async as versions">
            <ng-select
              placeholder="Select Firmware"
              [items]="versions"
              [multiple]="false"
              bindLabel="label"
              bindValue="value"
              [(ngModel)]="entity.firmware"
              (ngModelChange)="onChange($event)"
            ></ng-select>
          </div>
        </div>

      </div>
    </div>
    <div class="row">
      <div class="col">
        <span *ngIf="!options?.edit"><strong>Owner: </strong><span>{{entity.owner}}</span></span>
        <span *ngIf="options?.edit"><strong>Owner: </strong><span>{{entity.owner}}</span></span>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <span *ngIf="!options?.edit"><strong>Description: </strong><br/><span>{{entity.description}}</span></span>
        <span *ngIf="options?.edit"><strong>Description: </strong><br/><span><textarea [(ngModel)]="entity.description" (ngModelChange)="updateDefinition()"></textarea></span></span>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <span *ngIf="!options?.edit"><strong>Notes: </strong><br/><span>{{entity.notes}}</span></span>
        <span *ngIf="options?.edit"><strong>Notes: </strong><br/><span><textarea [(ngModel)]="entity.notes" (ngModelChange)="updateDefinition()" ></textarea></span></span>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <span *ngIf="!options?.edit"><strong>Criteria: </strong><span><embed-firmware-target  [entity]="entity.target"  [layout]="'mini'" [options]="options"> </embed-firmware-target></span></span>
        <div *ngIf="options?.edit">
          <ng-container *ngTemplateOutlet="editCriteria"></ng-container>
        </div>
      </div>
    </div>
  </ng-template>

  <!-- ======================== -->
  <!-- Edit Criteria Template   -->
  <!-- ======================== -->
  <ng-template #editCriteria>
    <div class="row">
      <div class="col mx-auto">
        <strong>Criteria</strong>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="card m-0 p-0">
          <div class="card-header card-accent-dark m-0 p-0">
            <ng-select
              placeholder="Select Criteria"
              [items]="criteria_types"
              [multiple]="false"
              [(ngModel)]="entity.target.type"
              bindLabel="label"
              bindValue="value"
              (ngModelChange)="onChangeCriteria($event)"></ng-select>
          </div>
          <div *ngIf="entity.target.type" class="card-body m-0 p-1">
            <!-- ============================= -->
            <!-- Series                        -->
            <!-- ============================= -->
            <div *ngIf="entity.target.typeHasConstraint('series')" class="row">
              <div class="col-4">
                <strong>Series: </strong>
              </div>
              <div class="col-8">
                <div *ngIf="available.series">
                  <ng-select
                    placeholder="Select Series"
                    [items]="available.series"
                    bindLabel="label"
                    bindValue="value"
                    [multiple]="false"
                    [(ngModel)]="entity.target.constraints.series"
                  ></ng-select>
                </div>
              </div>
            </div>

            <!-- ============================= -->
            <!-- Manufacturer                  -->
            <!-- ============================= -->
            <div *ngIf="entity.target.typeHasConstraint('manufacturer')" class="row">
              <div class="col-4">
                <strong>Manufacturer: </strong>
              </div>
              <div class="col-8">
                <div *ngIf="available.manufacturers">
                  <ng-select
                    placeholder="Select Manufacturer"
                    [items]="available.manufacturers"
                    [multiple]="false"
                    bindLabel="label"
                    bindValue="value"
                    [(ngModel)]="entity.target.constraints.manufacturer"
                  ></ng-select>
                </div>
              </div>
            </div>

            <!-- ============================= -->
            <!-- Gateway                       -->
            <!-- ============================= -->
            <div *ngIf="entity.target.typeHasConstraint('gateway')" class="row">
              <div class="col-4"><strong>Gateway:</strong></div>
              <div class="col-8">
                <input type="text" [(ngModel)]="entity.target.constraints.gateway" (ngModelChange)="updateDefinition()" size="6" />
              </div>
            </div>

            <!-- ============================= -->
            <!-- Appengine                     -->
            <!-- ============================= -->
            <div *ngIf="entity.target.typeHasConstraint('appengine')" class="row">
              <div class="col-4">
                <strong>Appengine Device: </strong>
              </div>
              <div class="col-8">
                <input type="text" [(ngModel)]="entity.target.constraints.appengine" (ngModelChange)="updateDefinition()" size="12" />
              </div>
            </div>

            <!-- ============================= -->
            <!-- Gateway Type                  -->
            <!-- ============================= -->
            <div *ngIf="entity.target.typeHasConstraint('type')" class="row">
              <div class="col-4">
                <strong>Type: </strong>
              </div>
              <div class="col-8">
                <div *ngIf="available.types | async as types">
                  <ng-select
                    placeholder="Select Device Type"
                    [items]="types"
                    [multiple]="false"
                    bindLabel="label"
                    bindValue="value"
                    [(ngModel)]="entity.target.constraints.type"
                  ></ng-select>
                </div>
              </div>
            </div>

            <!-- ============================= -->
            <!-- Batch                         -->
            <!-- ============================= -->
            <div *ngIf="entity.target.typeHasConstraint('batch')" class="row">
              <div class="col-4">
                <strong>Batch: </strong>
              </div>
              <div class="col-8">
                <div *ngIf="available.batches | async as batches">
                  <ng-select
                    placeholder="Select Batch"
                    [items]="batches"
                    [multiple]="false"
                    bindLabel="label"
                    bindValue="value"
                    [(ngModel)]="entity.target.constraints.batch"
                  ></ng-select>
                </div>
              </div>
            </div>

            <!-- ============================= -->
            <!-- Range                         -->
            <!-- ============================= -->
            <div *ngIf="entity.target.typeHasConstraint('range')" class="row">
              <div class="col-4">
                <strong>Range: </strong>
              </div>
              <div class="col-8">
                <input type="text" [(ngModel)]="entity.target.constraints.range_from" (ngModelChange)="updateDefinition()" size="6" /> - <input type="text" [(ngModel)]="entity.target.constraints.range_to" (ngModelChange)="updateDefinition()" size="6" />
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </ng-template>


  <!--                                                        -->
  <!--                    Pop-Up Menu                         -->
  <!--                                                        -->
  <ng-template #linkModal>
    <div class="modal-header">
      <h4 class="modal-title pull-left">{{currentModal.title}}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div *ngIf="currentModal.form == 'delete-link'">
        <div class="row">
          <div class="col">
                <div class="alert alert-danger">Delete Link?</div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <widget-embed [entity]="entity" [layout]="'sidebar'" [options]="{}"> </widget-embed>
          </div>
        </div>
      </div>

      <div *ngIf="currentModal.form == 'save-changes'">
        <div class="row" *ngIf="entity?.pending">
          <div class="col-12">
            <div class="alert alert-danger" >
              Caution: Saving changes to a firmware link will clear it's approval flag, once cleared firmware will downgrade to previous next highest weight link.
            </div>
          </div>
        </div>
      </div>


      <div *ngIf="currentModal.form == 'approve-link'">
        <div class="row">
          <div class="col">
            <div class="alert alert-danger">Approve Firmware Link?</div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <span><strong>Approval Note: </strong><span><input type="text" [(ngModel)]="currentModal.approvalMessage" size="16" placeholder="Required Comment" /></span></span>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        *ngIf="currentModal?.cancelMsg"
        type="button"
        class="btn btn-secondary"
        (click)="currentModal?.cancel()"
        data-dismiss="modal">{{currentModal?.cancelMsg}}</button>
      <button
        *ngIf="currentModal?.deleteMsg"
        type="button"
        class="btn btn-warning"
        (click)="currentModal?.delete()"
        data-dismiss="modal">{{currentModal?.deleteMsg}}</button>
      <button
        *ngIf="currentModal?.confirmMsg"
        type="button"
        class="btn btn-danger"
        (click)="currentModal?.confirm()">{{currentModal?.confirmMsg}}</button>
    </div>
  </ng-template>


  `
})
export class EmbedMCUFirmwareLinkComponent implements OnInit {
  @Input() entity: MCUFirmwareLinkEntity = null;
  @Input() options: any = null;
  @Input() layout: string = null;

  public criteria_types = [];
  public modalRef: BsModalRef;
  public currentModal: any;

  public available = {
    batches: null,
    types: null,
    firmware: null,
    manufacturers: [
      {value: 'ref.manufacturer.14', label: 'Sino'},
      {value: 'ref.manufacturer.23', label: 'Fos'},
    ],
    series: [
      {value: 'V2', label: 'V2'},
      {value: 'V3', label: 'V3'},
    ]
  };



  public deleteModal = {
    title: 'Are you sure you wish to delete?',
    errorMsg: null,
    processing: false,
    form: 'delete-link',
    confirmMsg: 'Delete',
    confirm: ()  => {
      this.entity.delete().then((r) => {this.modalRef.hide()});
    },
    cancelMsg: 'Cancel',
    cancel: ()  => {
      this.modalRef.hide();
    },
  };



  public saveModal = {
    title: 'Save Changes! (Caution)',
    errorMsg: null,
    processing: false,
    form: 'save-changes',
    confirmMsg: 'Save',
    confirm: ()  => {
        this.entity.update().then((r) => {this.modalRef.hide()});
    },
    cancelMsg: 'Cancel',
    cancel: ()  => {
      this.modalRef.hide();
    },
  };


  public approveModal = {
    title: 'Approve Link',
    errorMsg: null,
    processing: false,
    approvalMessage: "",
    form: 'approve-link',
    confirmMsg: 'Approve',
    confirm: ()  => {
      if(this.approveModal.approvalMessage.length > 8) {
        this.entity.approve(this.approveModal.approvalMessage).then((r) => {
          this.approveModal.approvalMessage = "";
          this.modalRef.hide()})
      } else {
        alert('Approval comment required.');
      }
    },
    cancelMsg: 'Cancel',
    cancel: ()  => {
      this.modalRef.hide();
    },
  };


  //-----------------------------
  //
  //-----------------------------
  constructor(public modalService: BsModalService, public repoVersions: MCUFirmwareDefinitionRepo, public repoTypes: DeviceDefinitionRepo, public repoBatches: BatchRepo) {

  }


  saveLink(modal, e) {
    e.preventDefault();
    this.currentModal = this.saveModal;
    let config: ModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    this.modalRef = this.modalService.show(modal, config);
  }

  deleteLink(modal, e) {
    e.preventDefault();
    this.currentModal = this.deleteModal;
    let config: ModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    this.modalRef = this.modalService.show(modal, config);
  }


  approveLink(modal, e) {
    e.preventDefault();
    this.currentModal = this.approveModal;
    let config: ModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    this.modalRef = this.modalService.show(modal, config);
  }




  firmwareName(sref) {
      if (sref in this.repoVersions.firmwareBySref) return this.repoVersions.firmwareBySref[sref].firmwareVersion;
      return sref;
  }


  //-----------------------------
  //
  //-----------------------------
  ngOnInit(): void {
    if (this.options['edit']) {
      this.criteria_types = TargetStruct.type_options;

      this.available.firmware =  new Observable<any>(observer => {
        this.repoVersions.getListPromise().then((v: AppengineEntityList) => {
          const response = [];
          v.items.forEach((i: MCUFirmwareDefinitionEntity) => {
            const entry = {value: i.sref(), label: i.firmwareVersion};
            response.push(entry);
          });
          observer.next(response)
        });
      });

      this.available.types =  new Observable<any>(observer => {
        this.repoTypes.getListPromise().then((v: AppengineEntityList) => {
          const response = [];
          v.items.forEach((i: DeviceDefinitionEntity) => {
            const entry = {value: i.sref(), label: i.internalName};
            response.push(entry);
          });
          observer.next(response)
        });
      });

      this.available.batches =  new Observable<any>(observer => {
        this.repoBatches.getListPromise().then((v: AppengineEntityList) => {
          const response = [];
          v.items.forEach((i: BatchEntity) => {
            const entry = {value: i.sref(), label: `${i.identifier} - ${i.name}`};
            response.push(entry);
          });
          observer.next(response)
        });
      });
    }
  }

  //-----------------------------
  //
  //-----------------------------
  onChange(e) {
    this.updateDefinition();
  }

  //-----------------------------
  //
  //-----------------------------
  onChangeCriteria(e) {
    this.updateDefinition();
  }

  //-----------------------------
  //
  //-----------------------------
  updateDefinition() {
    this.entity.pending = true;
  }

}
