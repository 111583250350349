
import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from '../lacrosse.entity';
import { FirebaseAuthService }            from '../../noizu/services/firebase-auth.service';
import {ElixirEntity} from '../elixir.entity';
import {CmsArticleInfo} from './article-info';

export class CmsDataStreamEntity extends ElixirEntity {
  public _kind = "cms-ds";
  public _singular = "cms-ds";

  public identifier: any;
  public name: string;
  public fields: any;
  public created_on: any;
  public modified_on: any;
  public deleted_on: any;
  public article_info: CmsArticleInfo;


  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    if (json) {
      this.refresh(json);
    }
  } // end constructor

  refresh(data) {
    super.refresh(data);
    this.identifier = data["identifier"];
    this.name = data["name"];


    if (data["fields"]) {
      this.fields = {one: data.fields["one"], two: data.fields["two"], three: data.fields["three"]}
    } else {
      this.fields = {one: null, two: null, three: null}
    }


    this.created_on = data["created_in"];
    this.modified_on = data["modified_on"];
    this.deleted_on = data["deleted_on"];
    this.article_info = new CmsArticleInfo(data["article_info"]);
    return this;
  }

} // end Package
