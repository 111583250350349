<page-header [section]="'nodes-sys-health'">
  <page-title>Nodes</page-title>
  <page-blurb>Display active and inactive nodes. Manage and initiate node actions and requests.</page-blurb>
</page-header>


<div class="row">
  <div class="col">
    <h1>Nodes</h1>
    <div class="row">
      <div class="col-12" *ngIf="loading">  <new-spinner [size]="'sm'"></new-spinner> </div>
      <div class="col-6" *ngFor="let k of objectKeys(node_reports)">
        <widget [entity]="node_reports[k]" [options]="{}" [layout]="'card'"></widget>
      </div>
    </div>
  </div>
</div>
