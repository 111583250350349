
    <ng-container *ngIf="entity">
      <ng-container [ngSwitch]="entity.log_widget_type()">
        <alert-log-widget *ngSwitchCase="LogWidgetEnum.LOG_WIDGET__ALERT" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></alert-log-widget>
        <notification-log-widget *ngSwitchCase="LogWidgetEnum.LOG_WIDGET__NOTIFICATION" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></notification-log-widget>
        <gateway-log-widget *ngSwitchCase="LogWidgetEnum.LOG_WIDGET__GATEWAY" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></gateway-log-widget>
        <device-log-widget *ngSwitchCase="LogWidgetEnum.LOG_WIDGET__DEVICE" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></device-log-widget>
        <data-stream-log-widget *ngSwitchCase="LogWidgetEnum.LOG_WIDGET__DATA_STREAM" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></data-stream-log-widget>
        <user-log-widget *ngSwitchCase="LogWidgetEnum.LOG_WIDGET__USER" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></user-log-widget>
        <generic-log-widget *ngSwitchDefault (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></generic-log-widget>
      </ng-container>
    </ng-container>
    