import {HttpClient} from '@angular/common/http';
import { FirebaseAuthService }            from '../../../noizu/services/firebase-auth.service';
import { ExtendedUserEffectiveSubscriptionEntity } from './effective-subscription.entity';
import { UserSubscriptionEntitySet } from './subscription.entity.set';
import {UserExtendedWidgetEnum, WidgetEnum} from '../../../enums';
import {UserExtendedEntity} from './user-extended.entity';

export class ExtendedUserSubscriptionDetailsEntity extends UserExtendedEntity {
  public effective: ExtendedUserEffectiveSubscriptionEntity | null = null;
  public subscriptions: UserSubscriptionEntitySet | null = null;

  constructor(client: HttpClient, auth: FirebaseAuthService, user, json) {
    super(client, auth, json);
    this.effective = new ExtendedUserEffectiveSubscriptionEntity(this.client, this.auth, json['effective']);
    this.subscriptions = new UserSubscriptionEntitySet(this.client, this.auth, user, json['subscriptions']);
    this.subscriptions.queryable = false;
    this.raw = null;
  } // end constructor

  widget_type(): any {
    return WidgetEnum.EMBED_WIDGET__USER_EXTENDED;
  }

  user_extended_widget_type(): any {
    return UserExtendedWidgetEnum.USER_EXTENDED_WIDGET__SUBSCRIPTION_DETAILS;
  }
} // end Package
