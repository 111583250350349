<form action="" class="form-horizontal">
  <div class="card" ><!--*ngIf="!deleted"-->

    <div class="card-block" ><!--*ngIf="!(entry.identifier=='new' || editEntry==true)"-->
      <button *ngIf="!entry.history" class="btn-sm btn-primary float-right" (click)="getVersionHistory(entry)">
         Get Version History
      </button>
      <div class="row">
        <div class="col-9">
          <h4 class="card-title">{{entry.name}}</h4>
          <h6 class="card-subtitle mb-2 text-muted">{{entry.identifier}}</h6>
        </div>
      </div>
      <p class="card-text">Description: {{entry.description}}</p>
      <p class="card-text">Type: {{entry.type}}</p>
      <p class="card-text">Status: {{entry.status}}</p>
      <p class="card-text">Version: {{entry.version}}</p>
      <p class="card-text">Version Record: {{entry.version_record}}</p>

      <div class="row mb-3" *ngIf="entry.record">
        <div class="float-left col-1">Record:</div>
        <div class="col-11 float-left">
          <render-object [object]="entry.record"></render-object>
        </div>
      </div>

      <div class="row mb-3" *ngIf="entry.tags">
        <div class="float-left col-1">Tags:</div>
        <div class="col-11 float-left">
          <div *ngFor="let key of entry.tags">
            <div>{{key}}</div>
          </div>
        </div>
      </div>
      <div class="card" *ngIf="entry.history">
        <div class="card-block">
          <button class="btn-sm btn-primary float-right" (click)="entry.historyVisibility = !entry.historyVisibility">
             <i class="fa fa-sm"  [ngClass]="{'fa-chevron-down': !entry.historyVisibility, 'fa-chevron-up': entry.historyVisibility}"></i>
          </button>
          <p>{{entry.name}} History</p>
          <div *ngIf="entry.historyVisibility">
            <pre>{{entry.history|json}}</pre>
          </div>
        </div>
      </div>
      <!--<button (click)="edit($event, entry)" class="btn btn-sm btn-primary" type="submit"><i class="fa fa-dot-circle-o"></i> Edit</button>-->
    </div>
<!--



    <div *ngIf="entry.identifier=='new' || editEntry==true" class="card-block">
      <div class="card-block">
        <div class="form-group row">
          <label class="col-md-2 form-control-label" for="{{entry.identifier}}-fileA">File 1</label>
          <div class="col-md-10">
            <input class="form-control" required [(ngModel)]="entry.fileA" value="{{entry.fileA}}" id="fileA" name="fileA" placeholder="File 1 URL" type="text">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 form-control-label" for="{{entry.identifier}}-fileB">File 2</label>
          <div class="col-md-10">
            <input class="form-control" required [(ngModel)]="entry.fileB" value="{{entry.fileB}}" id="fileB" name="fileB" placeholder="File 2 URL" type="text">
          </div>
        </div>

      </div>
      <div class="card-footer">
        <button (click)="cancel($event)" class="btn btn-sm btn-primary" type="submit"><i class="fa fa-dot-circle-o"></i> Cancel</button>
        <button (click)="update($event, entry)" class="btn btn-sm btn-primary float-right" type="submit"><i class="fa fa-dot-circle-o"></i> Add/Update</button>
        <button (click)="delete($event, entry)" class="btn btn-sm btn-warning" type="submit"><i class="fa fa-minus-circle"></i> Delete</button>
      </div>
    </div>
-->

  </div>
</form>
