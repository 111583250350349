<div class="row">
  <div class="col-12">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header"> Search Group List </div>
          <div class="card-block">
            <div class="row">
              <div class="col-12">
                <form class="form-horizontal" >
                  <div class="form-group row">
                    <div class="col-12">
                      <button (click)="listGroups($event)" type="button" class="btn btn-info"><i class="fa fa-list"></i> List All Groups</button>
                      <button (click)="newGroup($event)" type="button" class="btn btn-info pull-right"><i class="fa fa-list"></i> New Group</button>
                    </div> <!--
                      <div class="form-group row">
                          <div class="col-md-12">
                              <div class="input-group">
                                  <span class="input-group-btn">
                                      <button (click)="searchGroups(searchTerm.id, 'id', $event)" type="button" class="btn btn-primary"><i class="fa fa-search"></i> Search Firebase Id</button>
                                  </span>
                                  <input type="text" name="searchTerm[id]" class="form-control" placeholder="Group ID" [(ngModel)]="searchTerm.id">
                              </div>
                          </div>
                      </div> -->
                  </div>
                  <div>
                    Limit results to System Groups only <input type="checkbox" [checked]="system" (change)="system = !system"/>
                  </div>
                </form>
                <div *ngIf="state?.error" role="alert" class="alert alert-danger">{{state.errorMsg}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="newG">
      <group-entry [group]="newG" style="width:100%"></group-entry>
    </div>

    <div class="row">
      <div class="col-12">
        <div *ngIf="state?.loading || searchResults" class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <i class="fa fa-align-justify"></i> Search Results
              </div>
              <div class="card-body card-block">
                <div *ngIf="state?.loading" class="overlayParent spinner-holder">
                  <div  class="overlay">
                    <widget-spinner></widget-spinner>
                  </div>
                </div>
                <div *ngIf="!state?.loading">
                  <div *ngIf="!searchResults?.items">No Matching Entries Found</div>
                  <div *ngIf="searchResults?.items">
                    <ul class="pagination">
                      <li class="page-item" *ngIf="pagination.page > 0"><a (click)="searchAt(searchResults, pagination.page - 1,  $event)" class="page-link">Previous</a></li>
                      <li class="page-item" *ngFor="let cursor of pagination.cursorArray; let cursorIndex = index;" [ngClass]="{'active' : cursorIndex == pagination.page}">
                        <a (click)="searchAt(searchResults, cursorIndex, $event)" class="page-link">{{cursorIndex}}</a>
                      </li>
                      <li class="page-item" *ngIf="pagination.next && pagination.next != ''"><a (click)="searchNext(searchResults, $event)" class="page-link">Next</a></li>
                    </ul>

                    <table class="table table-bordered table-striped table-sm">
                      <thead><tr><th>Id</th><th>name</th><th>handle</th><th>description</th><th>Is a system group</th></tr></thead>
                      <tbody>
                        <tr *ngFor="let group of searchResults.items;">
                          <td><a (click)="viewGroup(group, $event)" href="/portal/groups/show/{{group.identifier}}">{{group.identifier}}</a></td>
                          <td>{{group.name}}</td>
                          <td>{{group.handle}}</td>
                          <td>{{group.description}}</td>
                          <td>{{group.system}}</td>
                        </tr>
                      </tbody>
                    </table>
                    <ul class="pagination">
                      <li class="page-item" *ngIf="pagination.page > 0"><a (click)="searchAt(searchResults, pagination.page - 1,  $event)" class="page-link">Previous</a></li>
                      <li class="page-item" *ngFor="let cursor of pagination.cursorArray; let cursorIndex = index;" [ngClass]="{'active' : cursorIndex == pagination.page}">
                        <a (click)="searchAt(searchResults, cursorIndex, $event)" class="page-link">{{cursorIndex}}</a>
                      </li>
                      <li class="page-item" *ngIf="pagination.next && pagination.next != ''"><a (click)="searchNext(searchResults, $event)" class="page-link">Next</a></li>
                    </ul>
                  </div> <!-- end *ngIf="searchResults" -->
                </div>
              </div>
            </div><!-- end search results card -->
          </div>
        </div>
      </div>
    </div><!-- end search results row -->
  </div>
</div>
<!--
<ng-template #confirm>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{currentModal.title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {{currentModal.msg}}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="currentModal.cancel()" data-dismiss="modal">{{currentModal.cancelMsg}}</button>
    <button type="button" class="btn btn-danger" (click)="currentModal.confirm()">{{currentModal.confirmMsg}}</button>
  </div>
</ng-template>-->
