import {Component, Input, Inject, Output, EventEmitter} from '@angular/core';
import {NoizuStruct} from '../../../noizu/structs/noizu-struct';
import {ModalWidgetEnum, WidgetEnum} from '../../../enums/widget';
import {ModalWidget} from "../../../entities/";
import {ToasterService} from 'angular2-toaster';
import {ModalWidgetEventStruct} from './modal-widget-event.struct';

@Component({
  selector: 'generic-modal-widget',
  template: `

    <div class="modal-header">
      <h4 class="modal-title pull-left">{{entity?.title}}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="entity?.modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <widget-spinner *ngIf="entity?.processing"></widget-spinner>
      <div  *ngIf="!entity?.processing">
        <widget [entity]="entity?.subject" [layout]="entity?.embed_layout" [options]="entity?.embed_options"></widget>
      </div>
    </div>
    <div class="modal-footer">
      <button
        *ngIf="entity['callbacks']['cancel']"
        type="button"
        class="btn btn-secondary"
        (click)="entity.callbacks['cancel'].cb(entity, $event, entity?.modalRef, toasterService)"
        data-dismiss="modal">{{entity['callbacks']['cancel']['msg']}}</button>
      <button
        *ngIf="entity['callbacks']['confirm']"
        type="button"
        class="btn btn-danger"
        (click)="entity.callbacks['confirm'].cb(entity, $event, entity?.modalRef, toasterService)"
      >{{entity['callbacks']['confirm']['msg']}}</button>
    </div>
  `
})
export class GenericModalWidgetComponent {
  @Input() entity: ModalWidget | null = null;
  @Input() templateHint: string = null;
  @Input() options: any = null;
  @Input() layout = 'standard';
  @Output() widgetEvent = new EventEmitter<ModalWidgetEventStruct>();
  forwardEvent(e, widgetEvent: ModalWidgetEventStruct) {
    e.preventDefault();
    this.widgetEvent.emit(widgetEvent);
  }
  constructor(public toasterService: ToasterService) {

  }
}
