import { CommonModule } from "@angular/common";
import { RouterModule }   from '@angular/router';
import { NgModule, Inject, Injectable }      from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TabsModule } from 'ngx-bootstrap/tabs';
import { NAV_DROPDOWN_DIRECTIVES } from '../shared/nav-dropdown.directive';


import { SIDEBAR_TOGGLE_DIRECTIVES } from '../shared/sidebar.directive';
import { AsideToggleDirective } from '../shared/aside.directive';
import { BreadcrumbsComponent } from '../shared/breadcrumb.component';

import { SpinnerComponent } from '../components/misc/spinner.component';
import {BackButtonComponent} from '../widgets';
import {ForwardButtonComponent} from '../widgets/forward-button.component';
import {SelectDropDownModule} from 'ngx-select-dropdown';
import {ModalModule} from 'ngx-bootstrap/modal';
import {DatepickerModule} from 'ngx-bootstrap/datepicker';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {PopoverModule} from 'ngx-bootstrap/popover';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SelectDropDownModule,
    ModalModule,
    DatepickerModule,
    TooltipModule,
    ModalModule,
    DatepickerModule,
    BsDropdownModule,
    PopoverModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),

    RouterModule.forChild([])
  ],
  exports: [
    NAV_DROPDOWN_DIRECTIVES,
    BreadcrumbsComponent,
    SIDEBAR_TOGGLE_DIRECTIVES,
    AsideToggleDirective,
    SpinnerComponent,
    BackButtonComponent,
    ForwardButtonComponent,
  ],
  declarations: [
    NAV_DROPDOWN_DIRECTIVES,
    BreadcrumbsComponent,
    SIDEBAR_TOGGLE_DIRECTIVES,
    AsideToggleDirective,
    SpinnerComponent,
    BackButtonComponent,
    ForwardButtonComponent,
  ],

})
export class LayoutModule {
    constructor() {}
}
