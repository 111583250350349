

    <div class="card">
      <div class="card-header"><strong>Article Name:</strong> {{entity?.name}}</div>
      <div class="card-body">
        <div class="row border-bottom">
          <div class="col-2"><strong>Identifier</strong></div>
          <div class="col-10">{{entity?.identifier | json}}</div>
        </div>
        <div class="row">
          <div class="col-2"><strong>Field-One</strong></div>
          <div class="col-10"><span *ngIf="entity?.fields?.one">{{entity?.fields?.one}}</span><span *ngIf="!entity?.fields?.one">(null)</span></div>
        </div>
        <div class="row">
          <div class="col-2"><strong>Field-Two</strong></div>
          <div class="col-10"><span *ngIf="entity?.fields?.two">{{entity?.fields?.two}}</span><span *ngIf="!entity?.fields?.two">(null)</span></div>
        </div>
        <div class="row">
          <div class="col-2"><strong>Field-Three</strong></div>
          <div class="col-10"><span *ngIf="entity?.fields?.three">{{entity?.fields?.three}}</span><span *ngIf="!entity?.fields?.three">(null)</span></div>
        </div>

        <div class="card m-0 p-0 mt-3">
          <div class="card-header">Article Info
            <span class="float-right">
              <i
                (click)="expand = !expand"
                class="fa fa-sm"
                [ngClass]="{'fa-chevron-down': !expand, 'fa-chevron-up': expand}"> </i>
            </span>
          </div>
          <div class="card-body" *ngIf="expand">
            <pre>{{entity?.article_info | json}}</pre>
          </div>
        </div>
      </div>
    </div>
    