import {Component, Input, Inject, Output, EventEmitter} from '@angular/core';
import {EmailTemplateDynamicSelectorEntity, EmailTemplateEntity} from '../../entities';

@Component({
  selector: 'email-template-section-selector-widget',
  template: `
    <ng-container *ngIf="entity">
      <div class="card">
        <div class="card-header">[Selector] <span *ngFor="let s of entity.selector">&gt; {{s}}</span></div>
      </div>
    </ng-container>
`
})
export class EmailTemplateSectionSelectorWidgetComponent {
  @Input() entity: EmailTemplateDynamicSelectorEntity | null = null;
  @Input() options: any = null;
  @Input() layout = 'standard';
  expand = false;
  constructor() {
  }
}
