
    <div class="card">
      <div class="card-header">
        Effective Subscription
      </div>
      <div class="card-body m-1 p-0">

        <div class="row">
          <div class="col">
            <div class="alert alert-success p-1">
              <div class="row">
                <div class="col">
                  <b>view.max</b> = {{entity?.view?.max}}
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <b>export.max</b> = {{entity?.export?.max}}
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="row pt-1 pb-0 mb-0">
          <div class="col">
            <div class="alert alert-info p-1 pb-0 mb-0">
              <div class="row" *ngFor="let key of objectKeys(entity.notifications)">
                <div class="col">
                  <b>{{key}}</b> = {{entity.notifications[key]}}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  