import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'show-object',
  templateUrl: './show-object.component.html',
})
export class ShowObjectComponent {
  @Input() O = {};
  @Input() F = [];
  headingWidth = 75;
  constructor() {
  }

  ngOnInit(): void {
    //Ensure its wide enough to encompass the text at least most of the time
    let longest = 1;
    for (let x in this.O) {
      longest = x.length > longest ? x.length : longest;
    }
    this.headingWidth = longest * 8+5;
  }

  isObj(v) {
    let r = false;
    if (this.O[v] !== null && typeof this.O[v] === 'object') {
      r = true;
    }
    return r;
  }
}
