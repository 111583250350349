import {Component, Input, Inject, OnInit, Output, EventEmitter} from '@angular/core';
import {NoizuStruct} from '../../../noizu/structs/noizu-struct';
import {LogicalWidgetEnum, ModalWidgetEnum, WidgetEnum} from '../../../enums/widget';
import {BatchEntity, BatchLogicalWidget, ModalWidget, PinGroupEntity, PinGroupLogicalWidget} from '../../../entities/';
import {BatchRepo, PinGroupRepo} from '../../../repos';
import {Observable} from 'rxjs';
import {AppengineEntityList} from '../../../noizu/structs/appengine-entity-list';
import {FirmwareWidgetEventStruct} from '../firmware';
import {LogicalWidgetEventStruct} from './logical-widget-event.struct';

@Component({
  selector: 'pin-group-logical-widget',
  template: `
    <div *ngIf="!entity">
      <div class="row"><div class="col">
        <new-spinner [size]="'sm'"></new-spinner>
      </div></div>
    </div>
    <div *ngIf="entity">
      <div *ngIf="options?.edit">
        <div *ngIf="groupSubscriber | async as values; else loading">
          <ng-select
            placeholder="Select Pin Group"
            [items]="values"
            [multiple]="false"
            bindLabel="label"
            bindValue="value"
            [(ngModel)]="entity.selected"
            (ngModelChange)="updateOutput()"
          ></ng-select>
        </div>

        <ng-template #loading>
          <div class="row"><div class="col">
            <new-spinner [size]="'sm'"></new-spinner>
          </div></div>
        </ng-template>

      </div>
      <div *ngIf="!options?.edit">
        {{entity.selected | json}}
      </div>
    </div>
`
})
export class PinGroupLogicalWidgetComponent implements OnInit {
  @Input() entity: PinGroupLogicalWidget | null = null;
  @Input() templateHint: string = null;
  @Input() options: any = null;
  @Input() layout = 'standard';
  @Output() widgetEvent = new EventEmitter<LogicalWidgetEventStruct>();
  forwardEvent(e, widgetEvent: LogicalWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }

  public groupSubscriber: any = null;

  constructor(public repo: PinGroupRepo) {

  }

  public ngOnInit() {

    this.groupSubscriber =  new Observable<any>(observer => {
      this.repo.getListPromise().then((v: any) => {
        const response = [];
        console.log("Group Subscriber", v);

        let i = v.sort((a,b) => {
          return a.name < b.name
        })

        v.forEach((i: PinGroupEntity) => {
          const entry = {value: i.sref(), label: `${i.identifier} - ${i.name}`};
          response.push(entry);
        });

        observer.next(response);
      });
    });

  }


  updateOutput() {
    let event = new LogicalWidgetEventStruct()
    event.event_widget_type = this.entity.widget_type();
    event.event_logical_widget_type = this.entity.logical_widget_type();
    event.event_type = "pin_group_selection_made"
    event.event_body = {sref: this.entity.selected}
    this.widgetEvent.emit(event);
  }



}
