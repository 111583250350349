import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { NgModule, Inject, Injectable } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { CalendarModule } from 'angular-calendar';
import { LayoutModule } from './layout.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AuthenticatedUserComponent } from '../components/authenticated-user.component';

import { UserShowComponent } from '../components/user/show.component';
import { UserListComponent } from '../components/user/list.component';
import { DeviceDashboardComponent } from '../components/device/device-dashboard.component';
import { GatewayListComponent } from '../components/gateway/list.component';
import { LoginComponent } from '../components/misc/login.component';
import { LogoutComponent} from '../components/misc/logout.component';
import { PageNotFoundComponent } from '../components/misc/page-not-found.component';
import { DeviceShowComponent } from '../components/device/show/show.component';
import { GroupListComponent } from '../components/group/list.component';
import { GroupShowComponent } from '../components/group/show.component';
import { ToolsComponent } from '../components/tools/show.component';
import { AppNotificationComponent } from '../components/app-notifications/show.component';
import { CmsAlertListComponent, CmsDataStreamListComponent} from '../components/cms';

import { EmailTemplateListComponent } from '../components/data-management/email/list.component';
import { EmailQueueListComponent } from '../components/data-management/email-queue/list.component';
import { DeviceDefinitionListComponent } from '../components/device/definition/list.component';
import { DeviceDefinitionViewComponent } from '../components/device/definition/view.component';
import { DeviceFieldDefinitionListComponent } from '../components/device/field/definition/list.component';
import { DeviceFieldDefinitionViewComponent } from '../components/device/field/definition/view.component';
import { DeviceAutoLoadLookupTableComponent } from '../components/device/auto-load/lookup-table.component';``
import { GatewayAutoLoadLookupTableComponent } from '../components/gateway/auto-load/lookup-table.component';
import { DataManagementSynchQueueComponent } from '../components/data-management/synch-queue.component';
import { DataManagementEntityArchiveComponent } from '../components/data-management/entity-archive.component';
import { DeviceAutoLoadSensorTypeMappingComponent } from '../components/device/auto-load/sensor-type-mapping.component';
import { DataManagementUploadComponent } from '../components/data-management/upload.component';
import { ReportingAuditHistoryComponent } from '../components/reporting/audit-history.component';
import { DataManagementWipfliSynchQueueComponent } from '../components/data-management/wipfli/synch-queue.component';
import { ChannelDefinitionListComponent } from '../components/channel-definition/list.component';
import { FirmwareOverviewComponent} from '../components/firmware/overview/overview.component';
import { FirmwareDefinitionListComponent } from '../components/firmware/definition/list.component';
import { FirmwareLinkListComponent } from '../components/firmware/link/list.component';
import { DeviceDuplicatesListComponent } from '../components/device/duplicates/list.component';
import { ReportingShowComponent } from '../components/reporting/data/show.component';
import { DataManagementBatchesComponent } from '../components/data-management/batches.component';
import { DataManagementManufacturersComponent } from '../components/data-management/manufacturers.component';
import { SubscriptionsComponent } from '../components/subscriptions/subscriptions.component';
import { DeviceSimulatorComponent } from '../components/device/simulator/list.component';


// System Health
import {SystemHealthWorkersComponent} from '../components/system-health/workers/workers.component';
import {SystemHealthUserReportComponent} from '../components/system-health/user-reports/user-reports.component';
import {SystemHealthTelemetryComponent} from '../components/system-health/telemetry/telemetry.component';
import {SystemHealthTimeScaleComponent} from '../components/system-health/timescale/timescale.component';
import {SystemHealthNodesComponent} from '../components/system-health/nodes/nodes.component';
import {SystemHealthOverviewComponent} from '../components/system-health/overview/overview.component';
import {SystemHealthServicesComponent} from '../components/system-health/services/services.component';


// . . . New Style

import { AuthService } from '../services/auth.service';


import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';
import {FirmwareApprovalListComponent} from '../components/firmware/approval/list.component';
import {FirmwareReleaseListComponent} from '../components/firmware/release/list.component';
import {MCUFirmwareReleaseListComponent} from '../components/mcu/release';
import {MCUFirmwareDefinitionListComponent} from '../components/mcu/definition';
import {MCUFirmwareLinkListComponent} from '../components/mcu/link';
import {MCUFirmwareOverviewComponent} from '../components/mcu/overview';
import {AdminHomePanelComponent} from '../components/admin-home';
import {
  FirmwareApprovalShowComponent,
  FirmwareReleaseShowComponent,
  FirmwareDefinitionShowComponent,
  FirmwareLinkShowComponent,
  FirmwareLinkViewComponent, ChannelDefinitionShowComponent
} from '../components';
import {FirmwareDefinitionViewComponent} from '../components/firmware/definition/view.component';
import {SelectDropDownModule} from 'ngx-select-dropdown';
import {ToasterModule} from 'angular2-toaster';
import {SafePipe} from '../pipes';







const routes: Routes = [

  { path: 'portal/admin', canActivate: [AuthService],
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full'},
      { path: 'home',  component: AdminHomePanelComponent },
    ]
  },

  { path: 'portal/device/definitions', canActivate: [AuthService],
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'full'},
      { path: 'list',  component: DeviceDefinitionListComponent },
      { path: ':id',  component: DeviceDefinitionViewComponent },
    ]
  },

  { path: 'portal/device/field/definitions', canActivate: [AuthService],
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'full'},
      { path: 'list',  component: DeviceFieldDefinitionListComponent },
      { path: ':id',  component: DeviceFieldDefinitionViewComponent },
    ]
  },

  { path: 'portal/users', canActivate: [AuthService],
    children: [
      { path: '', redirectTo: 'search', pathMatch: 'full'},
      { path: 'list',  component: UserListComponent },
      { path: 'show/:id', component: UserShowComponent }
    ]
  },
  { path: 'portal/devices', canActivate: [AuthService],
    children: [
      { path: '', redirectTo: 'search', pathMatch: 'full'},
      { path: 'search',  component: DeviceDashboardComponent },
      { path: 'show/:id/:uid',  component: DeviceShowComponent },
      { path: 'show/:id',  component: DeviceShowComponent },
      { path: 'duplicates',  component: DeviceDuplicatesListComponent }
    ]
  },
  { path: 'portal/gateways', canActivate: [AuthService],
    children: [
      { path: '', redirectTo: 'search', pathMatch: 'full'},
      { path: 'search',  component: GatewayListComponent }
    ]
  },

  { path: 'portal/device/auto-load/lookup-table', canActivate: [AuthService],
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'full'},
      { path: 'list',  component: DeviceAutoLoadLookupTableComponent }
    ]
  },

  { path: 'portal/gateway/auto-load/lookup-table', canActivate: [AuthService],
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'full'},
      { path: 'list',  component: GatewayAutoLoadLookupTableComponent }
    ]
  },


  { path: 'portal/data-management', canActivate: [AuthService],
    children: [
      { path: 'synch-queue',
        children: [
          { path: '', redirectTo: 'list', pathMatch: 'full'},
          { path: 'list', component: DataManagementSynchQueueComponent },
        ]
      },
      { path: 'wipfli/synch-queue',
        children: [
          { path: '', redirectTo: 'list', pathMatch: 'full'},
          { path: 'list', component: DataManagementWipfliSynchQueueComponent }
        ]
      },
      { path: 'entity-archive',
        children: [
          { path: '', redirectTo: 'list', pathMatch: 'full'},
          { path: 'list', component: DataManagementEntityArchiveComponent }
        ]
      },
      { path: 'manufacturers',
        children: [
          { path: '', redirectTo: 'list', pathMatch: 'full'},
          { path: 'list', component: DataManagementManufacturersComponent }
        ]
      },
      { path: 'subscriptions',
        children: [
          { path: '', redirectTo: 'list', pathMatch: 'full'},
          { path: 'list', component: SubscriptionsComponent }
        ]
      },
      { path: 'batches',
        children: [
          { path: '', redirectTo: 'list', pathMatch: 'full'},
          { path: 'list', component: DataManagementBatchesComponent }
        ]
      }
    ]
  },

  { path: 'portal/data-management/upload', canActivate: [AuthService],
    children: [
      { path: '', pathMatch: 'full', component: DataManagementUploadComponent }
    ]
  },

  { path: 'portal/device/auto-load/sensor-type-mapping', canActivate: [AuthService],
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'full'},
      { path: 'list',  component: DeviceAutoLoadSensorTypeMappingComponent }
    ]
  },

  { path: 'portal/reporting', canActivate: [AuthService],
    children: [
      { path: 'audit-history',
        children: [
          { path: '', redirectTo: 'list', pathMatch: 'full'},
          { path: 'list', component: ReportingAuditHistoryComponent }
        ]
      },
      { path: 'general',
        children: [
          { path: '', redirectTo: 'list', pathMatch: 'full'},
          { path: 'list', component: ReportingShowComponent }
        ]
      }
    ]
  },
  { path: 'portal/tools', canActivate: [AuthService],
    children: [
      { path: '', redirectTo: 'utility', pathMatch: 'full'},
      { path: 'utility',  component: ToolsComponent },
    ]
  },
  { path: 'portal/app-notifications', canActivate: [AuthService],
    children: [
      { path: '', redirectTo: 'admin', pathMatch: 'full'},
      { path: 'admin',  component: AppNotificationComponent },
    ]
  },

  { path: 'portal/email', canActivate: [AuthService],
    children: [
      { path: '', redirectTo: 'templates', pathMatch: 'full'},
      { path: 'templates',  component: EmailTemplateListComponent },
      { path: 'queue',  component: EmailQueueListComponent },
    ]
  },

  { path: 'portal/cms', canActivate: [AuthService],
    children: [
      { path: '', redirectTo: 'data-stream', pathMatch: 'full'},
      { path: 'alerts',  component: CmsAlertListComponent },
      { path: 'data-stream',  component: CmsDataStreamListComponent },
    ]
  },

  { path: 'portal/groups', canActivate: [AuthService],
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'full'},
      { path: 'list',  component: GroupListComponent },
      { path: 'show/:id/:uid',  component: GroupShowComponent },
      { path: 'show/:id',  component: GroupShowComponent },
    ]
  },

  { path: 'portal/chanel-definitions', canActivate: [AuthService],
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'full'},
      { path: 'list',  component: ChannelDefinitionListComponent },
      { path: 'show/:id',  component: ChannelDefinitionShowComponent },
    ]
  },

  { path: 'portal/definition', canActivate: [AuthService],
    children: [
      { path: '', redirectTo: 'data', pathMatch: 'full'},
      { path: 'data',  component: DeviceSimulatorComponent }
    ]
  },

  { path: 'portal/firmware', canActivate: [AuthService],
    children: [
      { path: '', redirectTo: 'overview', pathMatch: 'full'},
      { path: 'overview',  component: FirmwareOverviewComponent },
      { path: 'definitions',  component: FirmwareDefinitionListComponent },
      { path: 'definitions/:id',  component: FirmwareDefinitionViewComponent },
      { path: 'links', component: FirmwareLinkListComponent },
      { path: 'links/:id', component: FirmwareLinkViewComponent },
      { path: 'approvals', component: FirmwareApprovalListComponent },
      { path: 'approvals/:id', component: FirmwareApprovalShowComponent },
      { path: 'releases', component: FirmwareReleaseListComponent },
      { path: 'releases/:id', component: FirmwareReleaseShowComponent }
    ]
  },


  { path: 'portal/mcu', canActivate: [AuthService],
    children: [
      { path: '', redirectTo: 'overview', pathMatch: 'full'},
      { path: 'overview',  component: MCUFirmwareOverviewComponent },
      { path: 'definitions',  component: MCUFirmwareDefinitionListComponent },
      { path: 'links', component: MCUFirmwareLinkListComponent },
      { path: 'approvals', component: FirmwareApprovalListComponent },
      { path: 'releases', component: MCUFirmwareReleaseListComponent }
    ]
  },


  { path: 'portal/system-health', canActivate: [AuthService],
    children: [
      { path: '', redirectTo: 'overview', pathMatch: 'full'},
      { path: 'overview',  component: SystemHealthOverviewComponent },
      { path: 'timescale',  component: SystemHealthTimeScaleComponent },
      { path: 'nodes',  component: SystemHealthNodesComponent },
      { path: 'services',  component: SystemHealthServicesComponent },
      { path: 'workers',  component: SystemHealthWorkersComponent },
      { path: 'telemetry',  component: SystemHealthTelemetryComponent },
      { path: 'user-reports',  component: SystemHealthUserReportComponent },

    ]
  },

  { path: 'login', component: LoginComponent
  },
  { path: 'logout', component: LogoutComponent
  },
  { path: '', redirectTo: '/login', pathMatch: 'full'
  },

  { path: '**', component: PageNotFoundComponent
  }

];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    LayoutModule,
    SelectDropDownModule,
    TabsModule,
    RouterModule.forChild([
      { path: '', component: AuthenticatedUserComponent,
        children: routes
      }
    ]),
    ToasterModule,
    AppAsideModule,
    AppBreadcrumbModule,
    AppHeaderModule,
    AppFooterModule,
    AppSidebarModule,
    PerfectScrollbarModule
  ],
  declarations: [
    AuthenticatedUserComponent
  ],
  exports: [RouterModule]
})
export class AuthenticatedUserModule {
  constructor() {}
}
