
import {Component, Inject, Input, NgZone, OnInit} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location }               from '@angular/common';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { FirmwareDefinitionEntity } from '../../../entities/firmware/definition.entity';
import { FirmwareDefinitionRepo } from '../../../repos/firmware/definition.repo';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap/modal';
import {AuthService} from '../../../services/auth.service';
import {FirebaseAuthService} from '../../../noizu/services/firebase-auth.service';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {UserPreferencesService} from '../../../services/user-preferences.service';
import {environment} from '../../../../environments/environment';

@Component({
  templateUrl: './list.component.html',
})
export class FirmwareDefinitionListComponent implements OnInit  {

  public liveViewLink: SafeResourceUrl | boolean = false;
  public pageVersionToggle = false;
  public pageVersion = '1.0';


  public entries: any; //Map<String, Array<FirmwareDefinitionEntity>>;
  public manufacturers: any; // Map<String, String>;
  public loaded = false;
  public selectedFirmware: any;
  public firmwareDefinitionModalRef: BsModalRef;
  public currentModal: any;
  public client: HttpClient;
  public auth: FirebaseAuthService;
  public newFirmwareDefinition: FirmwareDefinitionEntity;
  public widget_options = {edit: false, modal_form: true};
  public sidebar_options = {edit: false};
  public firmware_admin = false;


  public newFirmwareDefinitionModal = {
    title: 'Add Firmware',
    msg: `Add new firmware definition.`,
    errorMsg: null,
    processing: false,
    form: 'new-firmware',
    confirmMsg: 'Save',
    confirm: ()  => {
      if (this.saveNewfirmware()) {
        this.firmwareDefinitionModalRef.hide();
      }
    },

    cancelMsg: 'Cancel',
    cancel: ()  => {
      this.newFirmwareDefinitionModal.errorMsg = null;
      this.firmwareDefinitionModalRef.hide()
    },
  };



  constructor(
    public modalService: BsModalService,
    public repo: FirmwareDefinitionRepo,
    public zone: NgZone,
    public authService: AuthService,
    public preferences: UserPreferencesService,
    private domSanitizer: DomSanitizer,

    @Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    this.client = client;
    this.auth = auth;


    this.manufacturers = {}; //new Map<String, String>();
    this.entries = {};
    this.manufacturers["14.V2"]  = {id: "14.V2", man: "14", series: "V2",  name: "Sino V2"};
    this.manufacturers["14.V3"]  = {id: "14.V3", man: "14", series: "V2",  name: "Sino V3"};
    this.manufacturers["23.V2"]  = {id: "23.V2", man: "23", series: "V3",  name: "FOS V2"};
    this.manufacturers["23.V3"]  = {id: "23.V3", man: "23", series: "V3",  name: "FOS V3"};

    this.entries["14.V2"] = []; //new Array<FirmwareDefinitionEntity>();
    this.entries["23.V2"] = []; //new Array<FirmwareDefinitionEntity>();
    this.entries["14.V3"] = []; //new Array<FirmwareDefinitionEntity>();
    this.entries["23.V3"] = []; //new Array<FirmwareDefinitionEntity>();


    this.zone.run(
      () => {
        if (this.authService.loadedPromise) {
          this.authService.loadedPromise.then( () => {
            if (this.authService.userHasPermission('firmware_admin')) {
              this.firmware_admin = true;
              this.widget_options.edit = true;
              this.sidebar_options.edit = true;
            }
          });
        }


        this.repo.getListPromise().then((u: any) => {
          let items = u.items;
          const len = items.length;
          let man: any = {};

          for (let i = 0; i < len; i++) {
            const m = items[i].manufacturerId.toString() + "." +  items[i].series;
            man[m] = true;
            if (this.manufacturers[m] == undefined) {
              this.manufacturers[m] = {id: m, name: "Unknown", man: items[i].manufacturerId.toString(), series:  items[i].series};
              this.entries[m] = [];
              this.entries[m].push(items[i]);
            } else {
              this.entries[m].push(items[i]);
            }
          }
          this.loaded = true;

          for (let key in man) {
            this.entries[key] = this.entries[key].sort( (a,b) => {
              return this.sortVersion(a,b);
            });
          }
        });
      }
    )
  }





  ngOnInit() {
    this.loadVersion();
    this.auth.getTokenPromise().then((token) => {
      let link = environment.live_view + `/firmware/releases?jwt=${token}`;
      this.liveViewLink = link;//this.domSanitizer.bypassSecurityTrustResourceUrl(link);
    })
  }

  liveViewLinkSanitized() {
    return this.liveViewLink;
  }

  loadVersion() {
    if (this.preferences ) {
      this.preferences.session.readyPromise.then(() => {
        this.pageVersion = this.preferences.featureVersion('firmware-definitions', '1.0');
        this.pageVersionToggle = this.pageVersion == '2.0';
      });
    }
  }

  togglePageVersion(event) {
    event.preventDefault();
    if (this.pageVersion == '1.0') {
      this.pageVersion = '2.0';
    } else {
      this.pageVersion = '1.0';
    }
    this.pageVersionToggle = this.pageVersion == '2.0';
    this.preferences.setFeatureVersion('firmware-definitions', this.pageVersion);
  }



  public sortVersion(a,b) {
    let av = a.firmwareVersion.split(".");
    let bv = b.firmwareVersion.split(".");
    for (let i = 0; i < 3; i++) {
      const an = av[i] + 0;
      const bn = bv[i] + 0;
      if (an != bn) return an - bn;
    }
    return 0;
  }


  selectFirmwareEntry(entry, event) {
    event.preventDefault();
    if (this.selectedFirmware && this.selectedFirmware.identifier == entry.identifier) {
      this.selectedFirmware = null;
    } else {
      this.selectedFirmware = entry;
    }
  }

  manufacturerList() {
    let l = [];
    for (let k in this.manufacturers) {
      l.push(this.manufacturers[k])
    }
    return l;
  }


  saveNewfirmware() {
    this.newFirmwareDefinition.update().then( (r : FirmwareDefinitionEntity) => {

      this.entries[r.manufacturerId].push(r);

    })
    return true;
  }

  add(modal, manufacturer, series, e) {
    console.log("add", manufacturer, series, e);

    e.preventDefault();
    this.newFirmwareDefinition = new FirmwareDefinitionEntity(this.client, this.auth, {identifier: "new", manufacturerId: manufacturer, firmwareVersion: "0.0.0", size: 0, checksum: "123", series: series, fileA: "user1.bin", fileB: "user2.bin", notes: "New Firmware"});
    this.currentModal = this.newFirmwareDefinitionModal;
    let config: ModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    this.firmwareDefinitionModalRef = this.modalService.show(modal, config);

    /*
    let u = this.repo.entity({id: "new"});
    u.manufacturerId = new String(manufacturer);
    if (this.manufacturers[manufacturer] != undefined) {
      this.entries[manufacturer].push(u);
    } else {
      this.manufacturers[manufacturer] = {id: manufacturer, name: "Unknown"};
      this.entries[manufacturer] = [];
      this.entries[manufacturer].push(u);
    }*/
  }
}
