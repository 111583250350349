
    <div class="app flex-row align-items-center">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 overlayParent">
            <div class="overlay" *ngIf="authService.redundancyPrevent == -1">
              <widget-spinner></widget-spinner>
            </div>
            <div class="card-group mb-0">
              <div class="card p-4">
                <div class="card-block">
                  <h1>Login</h1>
                  <p class="text-muted">Sign In to your account</p>
                  <div class="input-group mb-3">
                    <span class="input-group-addon"><i class="icon-user"></i></span>
                    <input type="text" class="form-control" [(ngModel)]="user" placeholder="Username">
                  </div>
                  <div class="input-group mb-4">
                    <span class="input-group-addon"><i class="icon-lock"></i></span>
                    <input (keyup.enter)="login($event)" type="password" class="form-control" [(ngModel)]="password" placeholder="Password">
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <button type="button" [disabled]="loading" (click)="login($event)" class="btn btn-primary px-4">Login</button>
                    </div>
                  </div>
                </div>
                <div *ngIf="err" class="alert alert-danger"> {{msg}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  