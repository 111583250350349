import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from './lacrosse.entity';
import { FirebaseAuthService }            from '../noizu/services/firebase-auth.service';
import {AlertFacadeEntity} from './alert/facade.entity';
import {
  AlertHumidityFacadeEntity,
  AlertThermometerFacadeEntity,
  AlertContactFacadeEntity,
  AlertLightningFacadeEntity,
  AlertMotionFacadeEntity,
  AlertOnOffFacadeEntity,
  AlertProbeFacadeEntity,
  AlertRainfallFacadeEntity,
  AlertTemperatureFacadeEntity,
  AlertWetDryFacadeEntity,
  AlertWindFacadeEntity,
  NotificationSettings
} from './alert';
import {EntityLogSet, RequestLogSet} from './log';
import {AlertWidgetEnum, WidgetEnum, WidgetEnum_Alert_Facade} from '../enums';
import {AlertRepo} from '../repos';
import {ExtendedAlertDetails} from '../structs/alert/extended-alert-details';
import {cloneDeep, clone} from 'lodash';

export class AlertEntity extends LacrosseEntity {
  public _singular: string = "alert";
  public _sref_module: string = "alert";

  public extended: ExtendedAlertDetails = null;
  public raw: any = null;
  public state;
  public wait_status_promise: any = null;
  private wait_status_promise_resolve: any = null;
  private wait_status_promise_reject: any = null;

  public name: string | null = null;
  public enabled: boolean = false;
  public notificationSettings: NotificationSettings;
  public owner: any;
  public weight: any;
  public timeZone: any;
  public template: any;
  public language: any = 'default';
  public facades: Record<string, AlertFacadeEntity> = {};

  public get facade() : AlertFacadeEntity {
    return this.facades[this.template];
  }
  public set facade(value: AlertFacadeEntity) {
    this.facades[this.template] = value;
  }



  constructor(client: HttpClient, auth: FirebaseAuthService, json, private repo: AlertRepo) {
    super(client, auth, json);
    this.refresh(json);
    this.raw = json;
    this.raw['constraintTree'] = null;
  } // end constructor

  public shallowCopy() {
    let copy = clone(this);
    copy.facades = cloneDeep(this.facades);
    copy.raw = cloneDeep(this.raw);
    return copy;
  }

  refresh(data) {
    super.refresh(data);
    this.extended = new ExtendedAlertDetails(this.client, this.auth, data);
    this.extended.repo = this.repo;
    this.notificationSettings = new NotificationSettings(data["notificationSettings"]);
    this.enabled = !!data.enabled;
    this.name = data.name;
    this.owner = data.owner;
    this.template = data.kind;
    this.weight = data.weight || null;
    this.timeZone = data.timeZone || null;
    this.language = data.language || null;
    this.raw = data;
    this.raw['constraintTree'] = null;
    this.facades = {};
    this.facades[this.template] = this.extended.factory[ (this.template in this.extended.factory) ? this.template : "lacrosseAlerts#Generic" ](this.identifier, data);
    if (this.template in this.extended.factory) this.raw = null;
    return this;
  }

  cancelEdit() {
    this.refresh(this.raw);
    this.meta.hasChanges = false;
    this.meta.editMode = false;
  }

  setFreq(i) {
    this.notificationSettings && this.notificationSettings.setFreq(i);
  }


  public setTemplate(type) {

    let m = {
      "ALERT_FACADE_WIDGET__HUMIDITY": "lacrosseAlerts#Alert.Humidity",
      "ALERT_FACADE_WIDGET__CONTACT": "lacrosseAlerts#Alert.Contact",
      "ALERT_FACADE_WIDGET__LIGHTNING": "lacrosseAlerts#Alert.LightningProximity",
      "ALERT_FACADE_WIDGET__MOTION": "lacrosseAlerts#Alert.Motion",
      "ALERT_FACADE_WIDGET__ON_OFF": "lacrosseAlerts#Alert.OnOff",
      "ALERT_FACADE_WIDGET__PROBE": "lacrosseAlerts#Alert.Probe",
      "ALERT_FACADE_WIDGET__RAINFALL": "lacrosseAlerts#Alert.Rainfall",
      "ALERT_FACADE_WIDGET__TEMPERATURE": "lacrosseAlerts#Alert.Temperature",
      "ALERT_FACADE_WIDGET__THERMOMETER": "lacrosseAlerts#Alert.Thermometer",
      "ALERT_FACADE_WIDGET__WET_DRY": "lacrosseAlerts#Alert.WetDry",
      "ALERT_FACADE_WIDGET__WIND": "lacrosseAlerts#Alert.Wind",
      "ALERT_FACADE_WIDGET__DEFAULT": "lacrosseAlerts#Alert.Wind",

    }

    let new_template = m[type];
    if (new_template) {
      if (!this.facades[new_template]) this.facades[new_template] = this.extended.factory[new_template](this.identifier, this.raw);
      this.template = new_template;
    }
  }

  public statusLabel() {
    if (this.isTriggered()) return 'Triggered';
    if (this.isGreen()) return 'Green';
    return 'Red';
  }

  public isTriggered() {
    if (this.extended.status_loaded) {
      return this.extended.status["triggered?"]
    } else {
      return this.extended.status && this.facade.is_triggered() || false;
    }
  }

  public isGreen() {
    if (this.extended.status_loaded) {
      return !this.extended.status["triggered?"]
    } else {
      return this.extended.status && !this.facade.is_triggered() || false;
    }
  }

  public hasStatus() {
    return !!this.extended.state
  }

  public setState(json) {
    if (this.facade) {
      this.facade.updateStatus(json);
    }
    this.extended.setState(json);
  }



  refreshAlertState(force = false) {
    return this.extended.refreshAlertState(this, force);
  }

  alertStatePromise() {
    return this.extended.alertStatePromise(this);
  }

  save() {
    if (this.facade && this.facade.template) {
      let json = this.facade.toJson();
      json['id'] = this.identifier;
      json['owner'] = this.owner;
      json['weight'] = this.weight;
      json['createdOn'] = this.createdOn;
      json['modifiedOn'] = new Date();
      json['handle'] = this.handle;
      json['timeZone'] = this.timeZone;
      json['sensor'] = json.device;
      json['enabled'] = this.enabled;
      json['language'] = this.language;
      json['name'] = this.name;
      json['notificationSettings'] = this.notificationSettings.toJson();

      if (this.meta.new) {
        return this._post(`${this.apiBase()}/user/${this.owner}/alerts/as-template/${this.facade.template}/${this.identifier}`, json, (outcome, resolve) => {
          this.meta.saving = false;
          resolve(outcome);
        });
      } else {
        this.meta.saving = true;
        return this._put(`${this.apiBase()}/user/${this.owner}/alerts/as-template/${this.facade.template}/${this.identifier}`, json, (outcome, resolve) => {
          this.meta.saving = false;
          resolve(outcome);
        });
      }
    }
    return new Promise((resolve, reject) => reject(null));
  }

  enable() {
    this.meta.saving = true;
    return this._post(`${this.apiBase()}/user/${this.owner}/alert/${this.identifier}/enable`, {}, (outcome, resolve) => {
      this.meta.saving = false;
      resolve(outcome);
    });
  }

  disable() {
    this.meta.saving = true;
    return this._post(`${this.apiBase()}/user/${this.owner}/alert/${this.identifier}/disable`, {}, (outcome, resolve) => {
      this.meta.saving = false;
      resolve(outcome);
    });
  }

  widget_type() {
    return WidgetEnum.EMBED_WIDGET__ALERT_ENTITY;
  }

  alert_widget_type() {
    return AlertWidgetEnum.ALERT_WIDGET__ALERT_ENTITY;
  }

  filter(filter: string) {
    if (filter) {
      if (this.meta['filter'] == filter) return this.meta['filter_result'];
      this.meta['filter'] = filter;

      let triggered = filter.includes('alert:triggered');
      let green = filter.includes('alert:green');

      filter = filter.replace('alert:triggered', '');
      filter = filter.replace('alert:green', '');
      filter = filter.trim();

      this.meta['filter_result'] = false;
      if (green && !this.isTriggered()) this.meta['filter_result'] = true;
      else if (triggered && this.isTriggered()) this.meta['filter_result'] = true;
      else if (filter != '') {
        if (this.name && this.name.includes(filter)) this.meta['filter_result'] = true;
      }
      return this.meta['filter_result'];
    }  else {
      return true;
    }
  }

} // end Package
