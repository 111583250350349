
    <ng-container *ngIf="!entity">
      <div class="firmware-definition-logical-widget row"><div class="col">
        <new-spinner [size]="'sm'"></new-spinner>
      </div></div>
    </ng-container>
    <div class="firmware-definition-logical-widget" *ngIf="entity">
      <div *ngIf="options?.edit">
        <div *ngIf="valueSubscriber | async as values; else loading">
          <ng-select
            placeholder="Select Firmware"
            [items]="values"
            bindLabel="label"
            bindValue="value"
            [multiple]="false"
            [(ngModel)]="entity.selected"
            (ngModelChange)="updateOutput()"
          ></ng-select>
        </div>

        <ng-template #loading>
          <div class="row"><div class="col">
            <new-spinner [size]="'sm'"></new-spinner>
          </div></div>
        </ng-template>

      </div>
      <div *ngIf="!options?.edit">
        <span *ngIf="entity.selected">
          <a (click)="showFirmware($event)" href="/portal/firmware/definitions/{{entity.selected}}">
            <span *ngIf="selected">
            ({{selected.identifier}}) {{selected.firmwareVersion}}@{{selected.series}}
            </span>
            <span *ngIf="!selected">
            {{entity.selected}}
            </span>
          </a>
        </span>
        <span *ngIf="!entity.selected">
          (Null)
        </span>
      </div>
    </div>
