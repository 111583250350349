
    <div class="card card-accent-dark alert-facade-rainfall-widget">
      <div class="card-header">Rainfall Alert</div>
      <div class="card-body">
        <div class="row"><div class="col-4">Device</div><div class="col-8"><a [routerLink]="'/portal/devices/show/' + entity.device">{{entity.device}}</a></div></div>
        <div class="row"><div class="col-4">Association</div><div class="col-8">{{entity.deviceAssociation}}</div></div>
        <div class="row"><div class="col-12"><hr/></div></div>


        <div class="row" *ngIf="entity.hasPrecipitationLevel || options?.edit">
          <div class="col-4"><b>Precipitation Level</b></div>
          <div *ngIf="options?.edit" class="col-1">
            <label class="switch switch-3d switch-primary">
              <input checked="" class="switch-input switch-primary" (change)="toggleOptional($event)" [(ngModel)]="entity.hasPrecipitationLevel" type="checkbox">
              <span class="switch-slider"></span>
            </label>
          </div>
          <div *ngIf="!options?.edit" class="col-8"><b>{{entity.precipitationLevel}}</b></div>
          <div *ngIf="options?.edit" class="col-7">
            <ng-container *ngIf="entity.hasPrecipitationLevel">
              <ng-select
                placeholder="Precipitation Level"
                [items]="precipitation_levels"
                bindLabel="label"
                bindValue="value"
                [multiple]="false"
                [(ngModel)]="entity.precipitationLevel"
                (ngModelChange)="facadeChange($event)"
              ></ng-select>
            </ng-container>
          </div>
        </div>

        <div class="row" *ngIf="entity.hasNoRain  || options?.edit">
          <div *ngIf="!options?.edit" class="col-12">No rain for [<b><span>{{entity.noRainForXDays}}</span></b>] days.</div>
          <ng-container  *ngIf="options?.edit">
            <div class="col-4"><b>No rain for</b></div>
            <div class="col-1">
              <label class="switch switch-3d switch-primary">
                <input checked="" class="switch-input switch-primary" (change)="toggleOptional($event)" [(ngModel)]="entity.hasNoRain" type="checkbox">
                <span class="switch-slider"></span>
              </label>
            </div>
            <div class="col-7">
              <ng-container *ngIf="entity.hasNoRain">
                <input
                  type="number"
                  [(ngModel)]="entity.noRainForXDays"
                  (ngModelChange)="facadeChange($event)"
                  name="no_rain"
                /> days.
              </ng-container>
            </div>
          </ng-container>

        </div>

        <div class="row" *ngIf="entity.hasHourlyAccumulationAbove  || options?.edit">
          <div class="col-4"><b>Hourly Above</b></div>
          <div *ngIf="options?.edit" class="col-1">
            <label class="switch switch-3d switch-primary">
              <input checked="" class="switch-input switch-primary" (change)="toggleOptional($event)" [(ngModel)]="entity.hasHourlyAccumulationAbove" type="checkbox">
              <span class="switch-slider"></span>
            </label>
          </div>
          <div *ngIf="!options?.edit" class="col-8"><b>{{entity.hourlyAccumulationAbove.value}}</b><span> {{entity.hourlyAccumulationAbove.unit.name}}</span></div>
          <div *ngIf="options?.edit" class="col-7">
            <ng-container *ngIf="entity.hasHourlyAccumulationAbove">
              <input
                type="number"
                [(ngModel)]="entity.hourlyAccumulationAbove.value"
                (ngModelChange)="facadeChange($event)"
                name="temp_above"
              />
              <span> {{entity.hourlyAccumulationAbove.unit?.name}}</span>
            </ng-container>
          </div>
        </div>
        <div class="row" *ngIf="entity.hasDailyAccumulationAbove  || options?.edit">
          <div class="col-4"><b>Daily Above</b></div>
          <div *ngIf="!options?.edit" class="col-8"><b>{{entity.dailyAccumulationAbove.value}}</b><span> {{entity.dailyAccumulationAbove.unit.name}}</span></div>
          <div *ngIf="options?.edit" class="col-1">
            <label class="switch switch-3d switch-primary">
              <input checked="" class="switch-input switch-primary" (change)="toggleOptional($event)" [(ngModel)]="entity.hasDailyAccumulationAbove" type="checkbox">
              <span class="switch-slider"></span>
            </label>
          </div>
          <div *ngIf="options?.edit" class="col-7">
            <ng-container *ngIf="entity.hasDailyAccumulationAbove">
              <input
                type="number"
                [(ngModel)]="entity.dailyAccumulationAbove.value"
                (ngModelChange)="facadeChange($event)"
                name="temp_above"
              />
              <span> {{entity.dailyAccumulationAbove.unit?.name}}</span>
            </ng-container>
          </div>
        </div>
        <div class="row" *ngIf="entity.hasWeeklyAccumulationAbove  || options?.edit">
          <div class="col-4"><b>Weekly Above</b></div>
          <div *ngIf="options?.edit" class="col-1">
            <label class="switch switch-3d switch-primary">
              <input checked="" class="switch-input switch-primary" (change)="toggleOptional($event)" [(ngModel)]="entity.hasWeeklyAccumulationAbove" type="checkbox">
              <span class="switch-slider"></span>
            </label>
          </div>
          <div *ngIf="!options?.edit" class="col-8"><b>{{entity.weeklyAccumulationAbove.value}}</b><span> {{entity.weeklyAccumulationAbove.unit.name}}</span></div>
          <div *ngIf="options?.edit" class="col-7">
            <ng-container *ngIf="entity.hasWeeklyAccumulationAbove">
              <input
                type="number"
                [(ngModel)]="entity.weeklyAccumulationAbove.value"
                (ngModelChange)="facadeChange($event)"
                name="temp_above"
              />
              <span> {{entity.weeklyAccumulationAbove.unit?.name}}</span>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    