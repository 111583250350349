<page-header [section]="'cms-data-stream'">
  <page-title>CMS: Data Stream Cards</page-title>
  <page-blurb> </page-blurb>
</page-header>

<div>
  <spinner *ngIf="!entries"></spinner>

  <div class="row">
    <div class="col">
      <div class="alert-danger p-3 mb-2">
        CRUD is not currently exposed via this admin panel but crud and version history viewing will be added in a future update.
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-12">
      <div class="card card-accent-primary">
        <div class="card-header bg-primary">Format Legend
          <span class="float-right">
              <i
                (click)="expand = !expand"
                class="fa fa-sm"
                [ngClass]="{'fa-chevron-down': !expand, 'fa-chevron-up': expand}"> </i>
            </span>
        </div>
        <div class="card-body" *ngIf="expand">

          <div class="row mt-3">
            <div class="col">
              <h2>Formatting</h2>
              <div class="row mb-2">
                <div class="col">
                  <div class="alert-info p-3">
                    The DataStream cards are driven by the Noizu CMS V2 library. The V2 CMS library supports version trees and multiple revisions per version. A article, for example, may have a version such as  1.4.3 where 1 is the root version, 4 is the 4th version based on the root version 1 and 3 is the 3rd version derived from 1.4.
                    You can see this versioning path information in the CMS entry identifiers. `<strong>&#123; "tuple": [ "revision", &#123; "tuple": [ &#123; "tuple": [ "english", "alarm", "nws_chance_hail" ] &#125;, &#123; "tuple": [ 3,2,1 ] &#125;, 5 ] &#125; ] &#125;</strong>` for example is the json encoding of
                    of the elixir internal value of &#123;:revision, &#123;"english", "alarm", "news_chance_hail&#124;, &#123;1&#125;, 5&#125; where :revision indicates a revision record, &#123;"english","alarm","nws_chance_hail"&#125; is the underlying article identifier, &#123;3,2,1&#125; is the version path and 5 indicates this is the 5th revision of version 3.2.1
                    <br/>
                    DataStream cards are broken into three fields corresponding to the left, right and center field. The center (field 3) is not available on alarm devices due to a requirement to always show current time on alarm devices. Each field consists of a raw tax and optional curly base &#123; deliminated formatting tags &#125;
                  </div>
                </div>
              </div>


              <!-- Extended -->
              <div class="card">
                <div class="card-header bg-success"><strong>Details &amp; and Standard Options</strong><span></span>
                  <span class="float-right"><i
                    (click)="legend.details.enabled = !legend.details.enabled"
                    class="fa fa-sm"
                    [ngClass]="{'fa-chevron-down': !legend.details.enabled, 'fa-chevron-up': legend.details.enabled}"> </i></span>
                </div>
                <div class="card-body" *ngIf="legend.details.enabled">



                  <!-- Standard Options -->
                  <div class="card">
                    <div class="card-header bg-primary"><strong>Standard Options</strong><span></span>
                      <span class="float-right"><i
                        (click)="legend.details.options = !legend.details.options"
                        class="fa fa-sm"
                        [ngClass]="{'fa-chevron-down': !legend.details.options, 'fa-chevron-up': legend.details.options}"> </i></span>
                    </div>
                    <div class="card-body" *ngIf="legend.details.options">
                      <div class="row mb-2">
                        <div class="col">
                          <div class="alert-info p-3">
                            Standard options are placed after the | section of a tag. The are used to control width, decimal representation, optional units and alignment.
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <li>(I)nteger: Don't display decimal part.</li>
                          <li>(d)[0-9]ecimal: Show decimals if sufficient space. Default 1 decimal</li>
                          <li>(D)[0-9]ecimal: Required Decimal.</li>
                          <li>(C)[0-9]enter: center align output within buffer of n length. default n = 10.</li>
                          <li>(L)[0-9]left: left align output within buffer of n length. default n = 10.</li>
                          <li>(R)[0-9]ight: left align output within buffer of n length. default n = 10.</li>
                          <li>(F)[0-9]fixed Width: required space to allocate for character. As opposed to C, L, R space which is collapsible.</li>
                          <li>(T)[LRC]runcate: truncate preference left, right or sides.</li>
                          <li>(X)[lr][0-9] - Offset Pin (From left or right) - e.g. ensure position is 5 chars from left |Xl5</li>
                          <li>(u)nit: show unit including space when available.</li>
                          <li>(U)nit: show long form unit (if space available) with higher preference for keeping space between unit and value.</li>
                          <li>(P)[*]ad with - specify symbol to use for padding. E.g. pad with '-' or '*' etc.</li>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Selectors -->
                  <div class="card">
                    <div class="card-header bg-success"><strong>Selectors</strong><span></span>
                      <span class="float-right"><i
                        (click)="legend.details.selectors = !legend.details.selectors"
                        class="fa fa-sm"
                        [ngClass]="{'fa-chevron-down': !legend.details.selectors, 'fa-chevron-up': legend.details.selectors}"> </i></span>
                    </div>
                    <div class="card-body" *ngIf="legend.details.selectors">
                      <div class="row mb-2">
                        <div class="col">
                          <div class="alert-info p-3">
                            Specify input to use for output.
                            Forecast-Day, Forecast-Night, Forecast-Hour, String Literal, Numeric Literal, Value and Unit Literal
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <ul>
                            <li>:s:x,*: Diagnostic Selector</li>
                            <li>:s:C,*: Current Reading (special section of weather service payload).</li>
                            <li>:s:D,[0-6],*: Daily[i] Forecast Selector</li>
                            <li>:s:d,[0-6],*: daily_day[i] Forecast Selector</li>
                            <li>:s:n,[0-6],*: daily_night[i] Forecast Selector</li>
                            <li>:s:H,[0-11],*: Hourly[i] Forecast Selector</li>
                            <li>:s:a,[0-11],*: Active[i] (day/night) time of day dependent.</li>
                            <li>:s:t,HELLO: String Literal (Text) Selector</li>
                            <li>:s:f,123.23: Numeric Literal (Double) Selector</li>
                            <li>:s:i,123.23: Numeric Literal (Integer) Selector</li>
                            <li>:s:v,cm,32.3: Value and Unit Literal</li>
                            <li>:s:r,*: Input Reading Selector (select input included in datastream payload. :s:r,office-hours:   :s:r,reading-one:,   etc.)</li>
                            <li>:s:c,123: Category Selector</li>
                            <li>:s:m,123: Moon Phase Selector</li>
                            <li>:s:T,12,12: Time of Day Selector</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Priority -->
                  <div class="card">
                    <div class="card-header bg-success"><strong>Priority</strong><span></span>
                      <span class="float-right"><i
                        (click)="legend.details.priority = !legend.details.priority"
                        class="fa fa-sm"
                        [ngClass]="{'fa-chevron-down': !legend.details.priority, 'fa-chevron-up': legend.details.priority}"> </i></span>
                    </div>
                    <div class="card-body" *ngIf="legend.details.priority">
                      <div class="row mb-2">
                        <div class="col">
                          <div class="alert-info p-3">
                            Priority determines order in which tags may be compressed to fit within buffer constraints.<br/>
                            A priority of zero indicates a tag should be the last item compressed where a higher priority number means that component may be compressed first.<br/>

                            Priority may also be set per individual option using hyphen level syntax.  E.g.  &#123;link|u-1|9&#125;<br/>
                            Priority is global the unit for the &#123;link&#125; above would be compressed before anything else even though the actual tag has a high priority.<br/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>



              <div class="card">
                <div class="card-header bg-primary"><strong>Tags</strong><span></span>
                  <span class="float-right"><i
                    (click)="legend.tags.enabled = !legend.tags.enabled"
                    class="fa fa-sm"
                    [ngClass]="{'fa-chevron-down': !legend.tags.enabled, 'fa-chevron-up': legend.tags.enabled}"> </i></span>
                </div>
                <div class="card-body" *ngIf="legend.tags.enabled">

                  <!-- Reading Tags -->
                  <div class="card">
                    <div class="card-header"><strong>Reading Tags: </strong><span>&#123;READING[:PRIORITY][|OPTIONS]&#125;</span>
                      <span class="float-right"><i
                        (click)="legend.tags.reading = !legend.tags.reading"
                        class="fa fa-sm"
                        [ngClass]="{'fa-chevron-down': !legend.tags.reading, 'fa-chevron-up': legend.tags.reading}"> </i></span>
                    </div>
                    <div class="card-body" *ngIf="legend.tags.reading">
                      <div class="row mb-2">
                        <div class="col">
                          <div class="alert-info p-3">
                            Output contents of a reading variable (such as a virtual device reading) or input selector for obtaining diagnostic device information or forecast readings.
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2">
                          <strong>Examples</strong>
                        </div>
                        <div class="col-10">
                          <ul>
                            <li> &#123;link&#125; - Default Options </li>
                            <li> &#123;link:2|ud2C5&#125; - Show units, two decimals, center within span of 5 chars. Priority level 2 for over all tag.</li>
                            <li> &#123;link|IC&#125; - Show number with out decimal part, center output over span of 10  (or remaining space).</li>
                            <li> &#123;link:2&#125;  - Default options, Priority 2.</li>
                            <li> &#123;link:32|I&#125;  - Show as integer, Priority 32,</li>
                            <li> &#123;:s:h,0,gh:|I&#125; - Show hourly Gust Heading as Integer. *Any input selector may be used.</li>
                          </ul>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2">
                          <strong>Options</strong>
                        </div>
                        <div class="col-10">
                          <ul>
                            <li> (I)nteger: Don't display decimal part. </li>
                            <li> (d)[0-9]ecimal: Show decimals if sufficient space. Default 1 decimal </li>
                            <li> (D)[0-9]ecimal: Required Decimal. </li>
                            <li> (C)[0-9]enter: center align output within buffer of n length. default n = 10. </li>
                            <li> (L)[0-9]left: left align output within buffer of n length. default n = 10. </li>
                            <li> (R)[0-9]ight: left align output within buffer of n length. default n = 10. </li>
                            <li> (X)Offset Pin  X[L|R|''][0-9]* (fine grained positioning control)</li>
                            <li> (F)[0-9]fixed Width: required space to allocate for character. As opposed to C, L, R space which is collapsible. </li>
                            <li> (T)[LRC]runcate: truncate preference left, right or sides. </li>
                            <li> (u)nit: show unit including space when available. </li>
                            <li> (U)nit: show long form unit (if space available) with higher preference for keeping space between unit and value. </li>
                            <li> (P)[*]ad with - symbol to use for padding. </li>
                            <li> + - extended instruction set

                              <ul>
                                <li> Time Format
                                  <ul>
                                    <li>+t=M# - Use Military Time</li>
                                    <li>+t=am# - Use Meridian No Padding</li>
                                    <li>+t=AM# - Use Meridian With 1 char Padding</li>
                                  </ul>
                                </li>

                                <li> Unit Overrides
                                  <ul>
                                    <li>+U=I# - Force Imperial</li>
                                    <li>+U=S# - Force Standard Units (Metric)</li>
                                    <li>+u=[cm,m,in,..]# - Explicitly set unit. +=cm# (force centimeters cast)</li>
                                  </ul>
                                </li>

                                <li> Null Handling
                                  <ul>
                                    <li>+n=D# - Treat Nulls 1 integer, 2 signicant digits decimal part number  '-' or '-.--' or '-.-', etc. depending on decimal option and precision. </li>
                                    <li>+n+I# - Treat Nulls as 2 digit integer  '--' or '--.-' or '--.--' depending of tags decimal option and precision. </li>
                                    <li>+n=V# - Treat Nulls as 2 digit integer  '--' or '--.-' or '--.--' depending of tags decimal option and precision display units</li>
                                    <li>+n=B# - Treat Nulls as blank. No output.</li>
                                    <li>+n=N# - Treat Nulls as Null (output null)</li>
                                    <li>+n=S[1-6]# - Show 1-6 hyphens. +n=S# four dashes.  +n=S1# 1 dash. </li>
                                  </ul>
                                </li>

                              </ul>

                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>


                  <!-- Padding Tag -->
                  <div class="card">
                    <div class="card-header"><strong>Padding Tags: </strong><span>&#123;:p:[PRIORITY][|OPTIONS]&#125;</span>
                      <span class="float-right"><i
                        (click)="legend.tags.padding = !legend.tags.padding"
                        class="fa fa-sm"
                        [ngClass]="{'fa-chevron-down': !legend.tags.padding, 'fa-chevron-up': legend.tags.padding}"> </i></span>
                    </div>
                    <div class="card-body" *ngIf="legend.tags.padding">
                      <div class="row mb-2">
                        <div class="col">
                          <div class="alert-info p-3">
                            Add padding to string (if sufficient characters remain at given priority)
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2">
                          <strong>Examples</strong>
                        </div>
                        <div class="col-10">
                          <ul>
                            <li> &#123;:p:&#125; - single white space of padding if space available. </li>
                            <li> &#123;:p:1&#125; - single white space of padding with priority 1. </li>
                            <li> &#123;:p:1|C3P-&#125; add up to 3 --- characters with a priority of 1 </li>
                          </ul>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2">
                          <strong>Options</strong>
                        </div>
                        <div class="col-10">
                          <ul>
                            <li> C[0-9]ount - length of padding, default 1. </li>
                            <li> P[*]ad with - symbol to use for padding. </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>



                  <!-- Progressive Padding Tag -->
                  <div class="card">
                    <div class="card-header"><strong>Progressive Weight Tags: </strong><span>&#123;:P:[PRIORITY][|OPTIONS]&#125;</span>
                      <span class="float-right"><i
                        (click)="legend.tags.progressive_padding = !legend.tags.progressive_padding"
                        class="fa fa-sm"
                        [ngClass]="{'fa-chevron-down': !legend.tags.progressive_padding, 'fa-chevron-up': legend.tags.progressive_padding}"> </i></span>
                    </div>
                    <div class="card-body" *ngIf="legend.tags.progressive_padding">
                      <div class="row mb-2">
                        <div class="col">
                          <div class="alert-info p-3">
                            Same as :p: except priority for each compressed character adjusted to increase pressure to retain some padding.
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2">
                          <strong>Examples</strong>
                        </div>
                        <div class="col-10">
                          <ul>
                            <li>&#123;:P:&#125; - single white space of padding if space available.</li>
                            <li>&#123;:P:1&#125; - single white space of padding with priority 1.</li>
                            <li>&#123;:P:1|C3P-&#125; add up to 3 --- characters with a priority of 1</li>
                          </ul>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2">
                          <strong>Options</strong>
                        </div>
                        <div class="col-10">
                          <ul>
                            <li>C[0-9]ount - length of padding, default 1.</li>
                            <li>P[*]ad with - symbol to use for padding.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>


                  <!-- Compass Heading -->
                  <div class="card">
                    <div class="card-header"><strong>Compass Heading Tags: </strong><span>&#123;:cd:[PRIORITY],SELECTOR[|OPTION]&#125;</span>
                      <span class="float-right"><i
                        (click)="legend.tags.direction = !legend.tags.direction"
                        class="fa fa-sm"
                        [ngClass]="{'fa-chevron-down': !legend.tags.direction, 'fa-chevron-up': legend.tags.direction}"> </i></span>
                    </div>
                    <div class="card-body" *ngIf="legend.tags.direction">
                      <div class="row mb-2">
                        <div class="col">
                          <div class="alert-info p-3">
                            Output compase direction,  N, NSE, NE, etc.
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2">
                          <strong>Examples</strong>
                        </div>
                        <div class="col-10">
                          <ul>
                            <li>&#123;:cd:,:s:h,0,gh:|C10&#125; -  cardinal direction of hourly forecast gust heading, Center with 10</li>
                          </ul>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2">
                          <strong>Options</strong>
                        </div>
                        <div class="col-10">
                          <ul>
                            <li>Input selector, e.g. :s:h,0,gh:, :s:r,link:, :s:d,0,gh:, :s:n,0,gh:,   hourly selector,  input reading, daily selector, night selector.</li>
                            <li>Standard Formatting Options Apply</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>



                  <!-- Optional String -->
                  <div class="card">
                    <div class="card-header"><strong>Optional String Tags: </strong><span>&#123;:o:[Priority],String[|OPTIONS]&#125;</span>
                      <span class="float-right"><i
                        (click)="legend.tags.optional_string = !legend.tags.optional_string"
                        class="fa fa-sm"
                        [ngClass]="{'fa-chevron-down': !legend.tags.optional_string, 'fa-chevron-up': legend.tags.optional_string}"> </i></span>
                    </div>
                    <div class="card-body" *ngIf="legend.tags.optional_string">
                      <div class="row mb-2">
                        <div class="col">
                          <div class="alert-info p-3">
                            String that may be omitted due to insufficient available characters at given priority.
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2">
                          <strong>Examples</strong>
                        </div>
                        <div class="col-10">
                          <ul>
                            <li>W&#123;:o:5,:s:s,in:&#125;d</li>
                          </ul>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2">
                          <strong>Options</strong>
                        </div>
                        <div class="col-10">
                          <ul>
                            <li>Standard Formatting Options Apply</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>



                  <!-- Time of Day -->
                  <div class="card">
                    <div class="card-header"><strong>Time of Day Tags: </strong><span>&#123;:tod:[PRIORITY],SELECTOR[|OPTION]&#125;</span>
                      <span class="float-right"><i
                        (click)="legend.tags.time_of_day = !legend.tags.time_of_day"
                        class="fa fa-sm"
                        [ngClass]="{'fa-chevron-down': !legend.tags.time_of_day, 'fa-chevron-up': legend.tags.time_of_day}"> </i></span>
                    </div>
                    <div class="card-body" *ngIf="legend.tags.time_of_day">
                      <div class="row mb-2">
                        <div class="col">
                          <div class="alert-info p-3">
                            Output Hour, Minute, AM|PM for an event with option for military time.
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2">
                          <strong>Examples</strong>
                        </div>
                        <div class="col-10">
                          <ul>
                            <li>&#123;:tod:5,:s:d,0,sr:|MC10&#125; - Output Sunrise Time, Military Format.</li>
                          </ul>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2">
                          <strong>Options</strong>
                        </div>
                        <div class="col-10">
                          <ul>
                            <li>M(ilitary time)</li>
                            <li>m(eridian time)</li>
                            <li>Input selector, e.g. :h0,gh:, :r,link:, :d0,gh:, :n0,gh:,   hourly selector,  input reading, daily selector, night selector.</li>
                            <li>Standard Formatting Options Apply</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>


                  <!-- Moon Phase -->
                  <div class="card">
                    <div class="card-header"><strong>Moon Phase Tags: </strong><span>&#123;:phase:[PRIORITY],SELECTOR[|OPTIONS]&#125;</span>
                      <span class="float-right"><i
                        (click)="legend.tags.moon_phase = !legend.tags.moon_phase"
                        class="fa fa-sm"
                        [ngClass]="{'fa-chevron-down': !legend.tags.moon_phase, 'fa-chevron-up': legend.tags.moon_phase}"> </i></span>
                    </div>
                    <div class="card-body" *ngIf="legend.tags.moon_phase">
                      <div class="row mb-2">
                        <div class="col">
                          <div class="alert-info p-3">
                            Output Moon Phase String
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2">
                          <strong>Examples</strong>
                        </div>
                        <div class="col-10">
                          <ul>
                            <li>...</li>
                          </ul>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2">
                          <strong>Options</strong>
                        </div>
                        <div class="col-10">
                          <ul>
                            <li>...</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>


                  <!-- Weather Category -->
                  <div class="card">
                    <div class="card-header"><strong>Weather Category Tags: </strong><span>&#123;:cat:[PRIORITY],SELECTOR[|OPTIONS]&#125;</span>
                      <span class="float-right"><i
                        (click)="legend.tags.category = !legend.tags.category"
                        class="fa fa-sm"
                        [ngClass]="{'fa-chevron-down': !legend.tags.category, 'fa-chevron-up': legend.tags.category}"> </i></span>
                    </div>
                    <div class="card-body" *ngIf="legend.tags.category">
                      <div class="row mb-2">
                        <div class="col">
                          <div class="alert-info p-3">
                            Output Enum Category String (Moderate|Heavy|Danger|etc.)
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2">
                          <strong>Examples</strong>
                        </div>
                        <div class="col-10">
                          <ul>
                            <li>...</li>
                          </ul>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2">
                          <strong>Options</strong>
                        </div>
                        <div class="col-10">
                          <ul>
                            <li>...</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>


                  <!-- Unit Abbreviation-->
                  <div class="card">
                    <div class="card-header"><strong>Unit Abbreviation Tags: </strong><span>&#123;:u:[PRIORITY],SELECTOR[|OPTIONS]&#125;</span>
                      <span class="float-right"><i
                        (click)="legend.tags.short_unit = !legend.tags.short_unit"
                        class="fa fa-sm"
                        [ngClass]="{'fa-chevron-down': !legend.tags.short_unit, 'fa-chevron-up': legend.tags.short_unit}"> </i></span>
                    </div>
                    <div class="card-body" *ngIf="legend.tags.short_unit">
                      <div class="row mb-2">
                        <div class="col">
                          <div class="alert-info p-3">
                            Output unit abbreviations:  oF,  RH, IN, CM MPH, KPH, etc.
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2">
                          <strong>Examples</strong>
                        </div>
                        <div class="col-10">
                          <ul>
                            <li>...</li>
                          </ul>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2">
                          <strong>Options</strong>
                        </div>
                        <div class="col-10">
                          <ul>
                            <li>...</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>


                  <!-- Unit Name -->
                  <div class="card">
                    <div class="card-header"><strong>Unit Name Tags: </strong><span>&#123;:U:[PRIORITY],SELECTOR[|OPTIONS]&#125;</span>
                      <span class="float-right"><i
                        (click)="legend.tags.long_unit = !legend.tags.long_unit"
                        class="fa fa-sm"
                        [ngClass]="{'fa-chevron-down': !legend.tags.long_unit, 'fa-chevron-up': legend.tags.long_unit}"> </i></span>
                    </div>
                    <div class="card-body" *ngIf="legend.tags.long_unit">
                      <div class="row mb-2">
                        <div class="col">
                          <div class="alert-info p-3">
                            Output (localized) units: CELSIUS, MILES, INCHES|INCH|IN, ...
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2">
                          <strong>Examples</strong>
                        </div>
                        <div class="col-10">
                          <ul>
                            <li>...</li>
                          </ul>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2">
                          <strong>Options</strong>
                        </div>
                        <div class="col-10">
                          <ul>
                            <li>...</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>




              <!-- Readings -->
              <div class="card">
                <div class="card-header bg-dark"><strong>Readings &amp; Units</strong><span></span>
                  <span class="float-right"><i
                    (click)="legend.readings.enabled = !legend.readings.enabled"
                    class="fa fa-sm"
                    [ngClass]="{'fa-chevron-down': !legend.readings.enabled, 'fa-chevron-up': legend.readings.enabled}"> </i></span>
                </div>
                <div class="card-body" *ngIf="legend.readings.enabled">




                  <!-- Weather Readings -->
                  <div class="card">
                    <div class="card-header bg-dark"><strong>Weather Readings</strong><span></span>
                      <span class="float-right"><i
                        (click)="legend.readings.forecast = !legend.readings.forecast"
                        class="fa fa-sm"
                        [ngClass]="{'fa-chevron-down': !legend.readings.forecast, 'fa-chevron-up': legend.readings.forecast}"> </i></span>
                    </div>
                    <div class="card-body" *ngIf="legend.readings.forecast">
                      <div class="row mb-2">
                        <div class="col">
                          <div class="alert-info p-3">
                            The following reading abbreviations may be used with weather selectors. (FullName|Token)
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <ul>
                            <li>READING_DATE_TIME|DT</li>
                            <li>READING_EPOCH|U</li>
                            <li>READING_WEATHER_ICON|WX</li>
                            <li>READING_IS_DAYTIME|isDT</li>
                            <li>READING_IS_DAYLIGHT|isDL</li>
                            <li>READING_TEMP|T</li>
                            <li>READING_RF_TEMP|F</li>
                            <li>READING_WET_BULB_TEMP|WB</li>
                            <li>READING_DEW_POINT|DP</li>
                            <li>READING_WIND_SPEED|WS</li>
                            <li>READING_WIND_HEADING|WH</li>
                            <li>READING_WIND_GUST|GS</li>
                            <li>READING_WIND_GUST_HEADING|GH</li>
                            <li>READING_RELATIVE_HUMIDITY|RHu</li>
                            <li>READING_VISIBILITY|V</li>
                            <li>READING_CEILING|C</li>
                            <li>READING_UV_INDEX|UVi</li>
                            <li>READING_UV_CATEGORY|UVc</li>
                            <li>READING_CHANCE_PRECIP|Pp</li>
                            <li>READING_CHANCE_RAIN|Rp</li>
                            <li>READING_CHANCE_SNOW|Sp</li>
                            <li>READING_CHANCE_TSTORM|Tp</li>
                            <li>READING_CHANCE_ICE|Ip</li>
                            <li>READING_TOTAL_LIQUID|TLiq</li>
                            <li>READING_CHANCE_HAIL|Hp</li>
                            <li>READING_CHANCE_TORNADO|TNp</li>
                            <li>READING_RAIN|R</li>
                            <li>READING_SNOW|S</li>
                            <li>READING_ICE|I</li>
                            <li>READING_CLOUD_COVER|CC</li>
                            <li>READING_DATE|D</li>
                            <li>READING_SUN_RISE|Sr</li>
                            <li>READING_SUN_SET|Ss</li>
                            <li>READING_MOON_RISE|Mr</li>
                            <li>READING_MOON_SET|Ms</li>
                            <li>READING_MIN_TEMP|Tl</li>
                            <li>READING_MAX_TEMP|Th</li>
                            <li>READING_MIN_FTEMP|Fl</li>
                            <li>READING_MAX_FTEMP|Fh</li>
                            <li>READING_MIN_FTEMP_SHADE|FSl</li>
                            <li>READING_MAX_FTEMP_SHADE|FSh</li>
                            <li>READING_HRS_OF_SUN|HoS</li>
                            <li>READING_DAY_SUMMARY_HEAT|DsH</li>
                            <li>READING_DAY_SUMMARY_COOL|DsC</li>
                            <li>READING_AIR_QUALITY|AQc</li>
                            <li>READING_GRASS|Gc</li>
                            <li>READING_MOLD|Mc</li>
                            <li>READING_RAGWEED|Rc</li>
                            <li>READING_TREE|Tc</li>
                            <li>READING_HRS_OF_PRECIP|Ph</li>
                            <li>READING_HRS_OF_RAIN|Rh</li>
                            <li>READING_HRS_OF_SNOW|Sh</li>
                            <li>READING_HRS_OF_ICE|Ih</li>
                            <li>READING_MOON_PHASE|MP</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Diagnostic Readings -->
                  <div class="card">
                    <div class="card-header bg-dark"><strong>Diagnostic Readings</strong><span></span>
                      <span class="float-right"><i
                        (click)="legend.readings.diagnostic = !legend.readings.diagnostic"
                        class="fa fa-sm"
                        [ngClass]="{'fa-chevron-down': !legend.readings.diagnostic, 'fa-chevron-up': legend.readings.diagnostic}"> </i></span>
                    </div>
                    <div class="card-body" *ngIf="legend.readings.diagnostic">
                      <div class="row mb-2">
                        <div class="col">
                          <div class="alert-info p-3">
                            The following diagnostic abbreviations may be used with diagnostic selectors. (FullName|Token)
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <ul>
                            <li>READING_FIRMWARE_VERSION|firmware</li>
                            <li>READING_MEMORY|mem</li>
                            <li>READING_PSK|psk</li>
                            <li>READING_API_FAILURES|api_fail</li>
                            <li>READING_SSID|ssid</li>
                            <li>READING_RESTARTS|rsts</li>
                            <li>READING_MCU_VERSION|mcu</li>
                            <li>READING_NEXT_MCU_VERSION|n.mcu</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>


                  <!-- Diagnostic Readings -->
                  <div class="card">
                    <div class="card-header bg-dark"><strong>Units</strong><span></span>
                      <span class="float-right"><i
                        (click)="legend.readings.units = !legend.readings.units"
                        class="fa fa-sm"
                        [ngClass]="{'fa-chevron-down': !legend.readings.units, 'fa-chevron-up': legend.readings.units}"> </i></span>
                    </div>
                    <div class="card-body" *ngIf="legend.readings.units">
                      <div class="row mb-2">
                        <div class="col">
                          <div class="alert-info p-3">
                            The following unit abbreviations may be used to override or specify units displayed.
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <ul>
                            <li>UNIT_NONE|no_units</li>
                            <li>UNIT_ERROR|err</li>
                            <li>UNIT_NONE|none</li>
                            <li>UNIT_MILLIMETERS|mm</li>
                            <li>UNIT_CENTIMETERS|cm</li>
                            <li>UNIT_METERS|m</li>
                            <li>UNIT_KILOMETERS|km</li>
                            <li>UNIT_INCHES|in</li>
                            <li>UNIT_FEET|ft</li>
                            <li>UNIT_MILES|mi</li>
                            <li>UNIT_STEPS|steps</li>
                            <li>UNIT_METERS_PER_SECOND|m/s</li>
                            <li>UNIT_KILOMETERS_PER_HOUR|kph</li>
                            <li>UNIT_FEET_PER_SECOND|ft/s</li>
                            <li>UNIT_MILES_PER_HOUR|mi/h</li>
                            <li>UNIT_KNOTS|kn</li>
                            <li>UNIT_SECONDS|s</li>
                            <li>UNIT_MINUTES|min</li>
                            <li>UNIT_HOURS|hr</li>
                            <li>UNIT_DAYS|day</li>
                            <li>UNIT_DEGREES_CELSIUS|degC</li>
                            <li>UNIT_DEGREES_FAHRENHEIT|degF</li>
                            <li>UNIT_DEGREES_KELVIN|degK</li>
                            <li>UNIT_RADIANS|rad</li>
                            <li>UNIT_DEGREES|deg</li>
                            <li>UNIT_RELATIVE_HUMIDITY|rh</li>
                            <li>UNIT_PERCENT|%</li>
                            <li>UNIT_MILLIBARS|mb</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>






            </div>
          </div>






        </div>
      </div>
    </div>
  </div>

  <div *ngIf="entries" class="row">
    <div class="col-6" *ngFor="let entry of entries" >
      <widget-embed [entity]="entry"></widget-embed>
    </div>
  </div>
</div>
