import {AlertWidgetEnum, LogicalWidgetEnum, WidgetEnum} from '../../../enums/widget';
import {LogicalWidget} from '../../logical-widget';

export class LogicalAlertWidget extends LogicalWidget{

  widget_type() {
    return WidgetEnum.EMBED_WIDGET__LOGICAL;
  }

  logical_widget_type(): LogicalWidgetEnum | null {
    return LogicalWidgetEnum.LOGICAL_WIDGET__ALERT;
  }

  alert_widget_type(): AlertWidgetEnum | null {
    return AlertWidgetEnum.ALERT_WIDGET__GENERIC
  }

  constructor() {
    super();
  } // end constructor
} // end Package
