import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToasterService} from 'angular2-toaster';
import {WidgetBase} from './widget-base';
import {WidgetEventStruct} from '../widget-event.struct';
import {BatchEntity} from '../../entities/batch.entity';
import {WidgetEnum} from '../../enums';

@Component({
  selector: 'batch-widget',
  template: `

    <ng-container class="row" *ngIf="entity">

      <ng-container [ngSwitch]="layout">

        <ng-container *ngSwitchCase="'table'">

          <div class="div-table-th" *ngIf="options['header']">
            <div class="row">
              <div class="col-1">
                <span class="pl-1">#Identifier</span>
              </div>
              <div class="col-2">
                Name
              </div>
              <div class="col">
                Notes
              </div>
              <div class="col-2">
                Created On
              </div>
              <div class="col-2">
                Modified On
              </div>
            </div>
          </div>

          <div [ngClass]="{'div-table-tr': (options['index'] % 2) == 1, 'div-table-tr-alt': (options['index'] % 2) == 0}">
            <div class="row">
              <div class="col-1 text-truncate" title="{{entity.identifier}}">
                <span class="pl-1"><a href="#" (click)="select($event)" >#{{entity.identifier}}</a></span>
              </div>
              <div class="col-2 text-truncate" title="{{entity.name}}">
                <ng-container *ngIf="options && options['edit']">
                  <input (ngModelChange)="saveBatch()" type="text" [(ngModel)]="entity.name"/>
                </ng-container>
                <ng-container *ngIf="!(options && options['edit'])">
                  {{entity.name}}
                </ng-container>
              </div>
              <div class="col text-truncate" title="{{entity.notes}}">
                <ng-container *ngIf="options && options['edit']">
                  <input (ngModelChange)="saveBatch()" type="text" [(ngModel)]="entity.notes"/>
                </ng-container>
                <ng-container *ngIf="!(options && options['edit'])">
                  {{entity.notes}}
                </ng-container>
              </div>
              <div class="col-2 text-truncate" title="{{entity.createdOn| date: 'longDate'}}">
                {{entity.createdOn| date: 'mediumDate'}}
              </div>
              <div class="col-2 text-truncate" title="{{entity.modifiedOn| date: 'longDate'}}">
                {{entity.modifiedOn| date: 'mediumDate'}}
              </div>
            </div>
          </div>

        </ng-container>


        <ng-container *ngSwitchCase="'shadowbox'">

          <ng-container *ngIf="this.entity.meta['new']">



            <div class="row">
              <div class="col"><b>Identifier:</b></div>
              <div class="col">
                <ng-container *ngIf="options && options['edit']">
                  <input (ngModelChange)="updateBatch()" type="number" [(ngModel)]="entity.identifier"/>
                </ng-container>
                <ng-container *ngIf="!(options && options['edit'])">
                  {{entity.identifier}}
                </ng-container>
              </div>
            </div>

            <div class="row">
              <div class="col"><b>Name:</b></div>
              <div class="col">
                <ng-container *ngIf="options && options['edit']">
                  <input (ngModelChange)="updateBatch()" type="text" [(ngModel)]="entity.name"/>
                </ng-container>
                <ng-container *ngIf="!(options && options['edit'])">
                  {{entity.name}}
                </ng-container>
              </div>
            </div>
            <div class="row">
              <div class="col"><b>Notes:</b></div>
              <div class="col">
                <ng-container *ngIf="options && options['edit']">
                  <input (ngModelChange)="updateBatch()" type="text" [(ngModel)]="entity.notes"/>
                </ng-container>
                <ng-container *ngIf="!(options && options['edit'])">
                  {{entity.notes}}
                </ng-container>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <embed-device-attributes [entity]="entity" [layout]="'card'" [options]="options?.attributes || {title: 'Attributes', edit: options['edit']}"></embed-device-attributes>
              </div>
            </div>


          </ng-container>
          <ng-container *ngIf="!this.entity.meta['new']">


            <div class="row">
              <div class="col-8">

                <div class="row">
                  <div class="col"><b>Identifier:</b></div>
                  <div class="col  text-truncate">{{entity.identifier}}</div>
                </div>
                <div class="row">
                  <div class="col"><b>Created On:</b></div>
                  <div class="col">{{entity.createdOn| date: 'mediumDate'}}</div>
                </div>
                <div class="row">
                  <div class="col"><b>Modified On:</b></div>
                  <div class="col">{{entity.modifiedOn| date: 'mediumDate'}}</div>
                </div>
                <div class="row">
                  <div class="col"><b>Name:</b></div>
                  <div class="col">
                    <ng-container *ngIf="options && options['edit']">
                      <input (ngModelChange)="updateBatch()" type="text" [(ngModel)]="entity.name"/>
                    </ng-container>
                    <ng-container *ngIf="!(options && options['edit'])">
                      {{entity.name}}
                    </ng-container>
                  </div>
                </div>
                <div class="row">
                  <div class="col"><b>Notes:</b></div>
                  <div class="col">
                    <ng-container *ngIf="options && options['edit']">
                      <input (ngModelChange)="updateBatch()" type="text" [(ngModel)]="entity.notes"/>
                    </ng-container>
                    <ng-container *ngIf="!(options && options['edit'])">
                      {{entity.notes}}
                    </ng-container>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <embed-device-attributes [entity]="entity" [layout]="'card'" [options]="options?.attributes || {title: 'Attributes'}"></embed-device-attributes>
                  </div>
                </div>

              </div>
              <div class="col">
                <entity-notes-v2
                  [title] = "'Batch Notes'"
                  [entity]="entity.sref()"
                  [options]="{'expand': true, 'dragula': false}"
                  [placeholder] = "'Batch Staff Note.'"
                ></entity-notes-v2>
              </div>
            </div>



          </ng-container>

        </ng-container>

        <ng-container *ngSwitchDefault>
          <div class="card" [ngStyle]="(layout != 'sidebar') ? {'height': '100%'} : null">
            <div class="card-header">Batch #{{entity.identifier}} - {{entity.name}}</div>
            <div class="card-body">


              <div class="row">
                <div class="col-8">
                  <div class="row">
                    <div class="col"><b>Identifier:</b></div>
                    <div class="col">{{entity.identifier}}</div>
                  </div>
                  <div class="row">
                    <div class="col"><b>Created On:</b></div>
                    <div class="col">{{entity.createdOn| date: 'mediumDate'}}</div>
                  </div>
                  <div class="row">
                    <div class="col"><b>Modified On:</b></div>
                    <div class="col">{{entity.modifiedOn| date: 'mediumDate'}}</div>
                  </div>
                  <div class="row">
                    <div class="col"><b>Name:</b></div>
                    <div class="col">
                      <ng-container *ngIf="options && options['edit']">
                        <input (ngModelChange)="updateBatch()" type="text" [(ngModel)]="entity.name"/>
                      </ng-container>
                      <ng-container *ngIf="!(options && options['edit'])">
                        {{entity.name}}
                      </ng-container>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col"><b>Notes:</b></div>
                    <div class="col">
                      <ng-container *ngIf="options && options['edit']">
                        <input (ngModelChange)="updateBatch()" type="text" [(ngModel)]="entity.notes"/>
                      </ng-container>
                      <ng-container *ngIf="!(options && options['edit'])">
                        {{entity.notes}}
                      </ng-container>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <embed-device-attributes [entity]="entity" [layout]="'card'" [options]="options?.attributes || {title: 'Attributes', edit: options['edit']}"></embed-device-attributes>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <entity-notes-v2
                    [title] = "'Batch Notes'"
                    [entity]="entity.sref()"
                    [options]="{'expand': true, 'dragula': false}"
                    [placeholder] = "'Batch Staff Note.'"
                  ></entity-notes-v2>
                </div>
              </div>




            </div>
            <div class="card-footer" *ngIf="options['edit']">
              <div  *ngIf="entity.meta['hasChanges']" class="btn btn-warning btn-lg mr-2" (click)="saveBatch($event)">Save</div>
              <div  class="btn btn-danger btn-lg" (click)="deleteBatch($event)">Delete</div>
            </div>
          </div>
        </ng-container>



      </ng-container>

    </ng-container>
  `
})
export class BatchWidgetComponent extends WidgetBase implements OnInit {
  public editMode = false;
  @Input() layout: string = null;
  @Input() entity: BatchEntity;
  @Input() options: any;
  @Output() widgetEvent = new EventEmitter<WidgetEventStruct>();
  forwardEvent(e, widgetEvent: WidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }

  constructor(public toasterService: ToasterService) {
    super();
  }

  public select(event) {
    let a = new WidgetEventStruct();
    a.event_body = this.entity;
    a.event_type = 'select_entity';
    a.event_widget_type = WidgetEnum.EMBED_WIDGET__BATCH_ENTITY;
    this.forwardEvent(event, a);
  }

  deleteBatch(event = null){
    if (event) {
      event.preventDefault();
    }
    this.entity.delete().then(r => {
      this.toasterService.pop('success',  'Success', `Batch Deleted`);
      this.entity.meta.hasChanges = false;
    }).catch(r => {
      this.toasterService.pop('warning', 'Error', `Batch Delete Failed`);
    });
  }

  saveBatch(event = null) {
    if (event) {
      event.preventDefault();
    }
    this.entity.update().then(r => {
      this.toasterService.pop('success',  'Success', `Batch Updated`);
      this.entity.meta.hasChanges = false;
    }).catch(r => {
      this.toasterService.pop('warning', 'Error', `Batch Update Failed`);
    });
  }

  updateBatch() {
    this.entity.meta.hasChanges = true;
  }


  ngOnInit(): void {
  }

}
