
import {Component, Inject, Input, NgZone, OnInit} from '@angular/core';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap/modal';
import {AuthService} from '../../services/auth.service';
import {HttpClient} from '@angular/common/http';
import {FirebaseAuthService} from '../../noizu/services/firebase-auth.service';
import {ModalWidget, PinGroupEntity, UserEntity, UserPermissionEntity} from '../../entities';
import {PinGroupRepo, PinRepo} from '../../repos';
import {ModalWidgetEnum} from '../../enums';
import {UserPreferencesService} from '../../services/user-preferences.service';

@Component({
  template: `
    <page-header [section]="'admin-home'">
      <page-title>Admin Home: <b>{{user?.last_name}}, {{user?.first_name}}</b></page-title>
      <page-blurb>View and manage your admin user details and user settings. View your permission levels and manage pin groups.</page-blurb>
    </page-header>

    <div class="row">
      <div class="col-4">
        <div *ngIf="permissions| async as p"><widget [entity]="p" [options]="{}"></widget></div>
        <widget [entity]="user" [options]="{}"></widget>

        <user-preferences-widget [entity]="user" [options]="{}"></user-preferences-widget>

      </div>

      <div class="col-8">
        <!-- Widget ! -->
        <div class="card">
          <div class="card-header bg-primary">Pins</div>
          <div class="card-body">

          <div class="row">
            <div class="col">
              <p>Pinned Entities</p>
            </div>
          </div>
          </div>
          <div class="card-footer text-center">
            <button (click)="createGroup($event, pageModal)" class="btn btn-primary">Create Pin Group</button>
          </div>
          <div *ngIf="pinGroups">
            <div class="row" *ngFor="let group of pinGroups">
              <div class="col">
                <widget [entity]="group" [layout]="'standard'" [options]="{}"></widget>
              </div>
            </div>
          </div>
        </div>




      </div>
    </div>


    <ng-template #pageModal>
      <modal-widget [entity]="currentModal" [layout]="'shadowbox'" [options]="{}"></modal-widget>
    </ng-template>
  `
})
export class AdminHomePanelComponent {
  public client: HttpClient;
  public auth: FirebaseAuthService;
  public permissions: any;
  public effective: any = [];
  public user: UserEntity = null;
  public pinGroups: Array<PinGroupEntity> = [];
  public logicalEntity: any = null;
  public currentModal: any;


  constructor(public modalService: BsModalService, public zone: NgZone, public authService: AuthService, public pinGroupRepo: PinGroupRepo, @Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService, public preferences: UserPreferencesService) {
    this.client = client;
    this.auth = auth;

    let res: any = null;
    let rej: any = null;
    this.permissions = new Promise( (resolve, reject) => {
      res = resolve;
      rej = reject;
    });

    this.zone.run(
      () => {
        this.user = this.authService.user;
        this.pinGroups = [];
        this.pinGroupRepo.getListPromise({pins: true}).then((r: Array<PinGroupEntity>) => {
          this.pinGroups = r;
          this.pinGroups.sort((a: PinGroupEntity,b: PinGroupEntity) => {return a.identifier >= b.identifier ? 1 : -1})
        })

        this.user.getAdminPermissionsPromise().then((p) => {
          p['staff_level'] = auth.permissionLevel;
          let r = new UserPermissionEntity(this.client, this.auth, p);
          res(r);
        })
      }
    )
  }

  createGroup(event, modal){
    event.preventDefault();

    let newRecord = this.pinGroupRepo.entity({})
    newRecord.identifier = 'new'

    let dialog = new ModalWidget(
      "Create Range Mapping Entry",
      ModalWidgetEnum.MODAL_WIDGET__CREATE,
      newRecord,
      {edit: true},
      'shadowbox');

    this.showModal(dialog, modal)
  }


  showModal(current, modal, clickOut = false) {
    this.currentModal = current;
    if (!clickOut) {
      let config: ModalOptions = {
        backdrop : 'static',
        keyboard : false,
        class: "lg-modal"
      };
      this.currentModal.modalRef = this.modalService.show(modal, config);
    } else {
      let config: ModalOptions = {
        class: "lg-modal"
      };
      this.currentModal.modalRef = this.modalService.show(modal, config);
    }
  }

}
