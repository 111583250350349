

    <ng-container [ngSwitch]="layout">
      <ng-container *ngSwitchCase="'table'">

        <div class="div-table-th" *ngIf="options['header']">
          <div class="row">
            <div class="col-2"><span class="pl-1">#Id</span></div>
            <div class="col-2">Name</div>
            <div class="col-2">Note</div>
            <div class="col-2">Type</div>
            <div class="col">Actions</div>
          </div>
        </div>

        <div [ngClass]="{'div-table-tr': (options['index'] % 2) == 1, 'div-table-tr-alt': (options['index'] % 2) == 0}">
          <div class="row">
            <div class="col-2 text-truncate">
              <span class="pl-1"><a title="{{entity.identifier}}" (click)="select($event)" >{{entity.identifier}}</a></span>
            </div>

            <div class="col-2 text-truncate" title="{{entity.name}}">{{entity.name}}</div>
            <div class="col-2 text-truncate" title="{{entity.note}}">{{entity.note}}</div>
            <div class="col-2 text-truncate" title="{{entity.channelType}}">{{entity.channelType}}</div>
            <div class="col">
              <span class="badge badge-info">Test Message</span>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <div class="card shadow" *ngIf="entity">
          <div class="card-header">
            <div class="row">
              <div class="col">
                User Communication Channel #{{entity.identifier}}
                <span *ngIf="options" class="float-right"><i (click)="toggleExpand()" class="fa fa-sm" [ngClass]="{'fa-chevron-down': !options.expand, 'fa-chevron-up': options.expand}"> </i></span>
              </div>
            </div>
          </div>
          <div class="card-body p-1 m-0" *ngIf="entity && expandWidget()">

            <div class="row">
              <div class="col-4">Name</div>
              <div class="col">{{entity.name}}</div>
            </div>

            <div class="row">
              <div class="col-4">Note</div>
              <div class="col">{{entity.note}}</div>
            </div>

            <div class="row">
              <div class="col-4">Channel Type</div>
              <div class="col">{{entity.channelType}}</div>
            </div>

            <div class="row">
              <div class="col-4">Fields</div>
              <div class="col"><pre>{{entity.fields| json}}</pre></div>
            </div>


          </div>
          <div class="card-footer">
            <div class="btn btn-pill btn-dark">Test Message</div>
          </div>
        </div>
      </ng-container>
    </ng-container>


  