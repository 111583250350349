import { NoizuStruct }        from '../../noizu/structs/noizu-struct';
import { AggregationTypeEnum }      from '../../enums/aggregation-type.enum';

export class AggregationType extends NoizuStruct {
  public enum: any;
  public name: string;
  constructor(json: any) {
      super();
      this.name = AggregationTypeEnum[json];
      this.enum = AggregationTypeEnum[this.name];
  } // end constructor

  toJson(options = {}) {
    return {name: this.name, enum: this.enum};
  }

}
