
import { Component, Inject, Input, NgZone } from '@angular/core';
import {HealthReportRepo} from '../../../repos/health-report.repo';
import {HttpClient} from '@angular/common/http';
import {FirebaseAuthService} from '../../../noizu/services/firebase-auth.service';
import {ToasterService} from 'angular2-toaster';
import {BsModalService} from 'ngx-bootstrap/modal';
import {AuthService} from '../../../services/auth.service';

@Component({
  templateUrl: './overview.component.html',
})
export class SystemHealthOverviewComponent {
  public nodes = [];
  public selected = null;
  public node_reports = {};
  public worker_tallies = {};

  public user_registration_report: any = null;
  public user_registration_report_daily: any = null;
  public user_registration_report_hourly: any = null;
  public system_status_report: any = null;
  public modal_ref: any = null;
  public sensor_daily_report: any = null;
  public sensor_hourly_report: any = null;
  public metric_report: any = {};
  public maintenance_reason = "";
  public objectKeys(o) {
    return Object.keys(o).sort();
  }


  public data_map = {};

  public graph = {
    data: [
    ],
    layout: {width: 1280, height: 480, title: 'Plot'}
  };


  public data_map2 = {};

  public graph2 = {
    data: [
    ],
    layout: {width: 1280, height: 480, title: 'Plot'}
  };

  public data_map3 = {};

  public graph3 = {
    data: [
    ],
    layout: {width: 1280, height: 480, title: 'Plot'}
  };


  refresh_graph() {
    this.graph.data = [];
    this.graph.data = Object.values(this.data_map);
  }

  refresh_graph2() {
    this.graph2.data = [];
    this.graph2.data = Object.values(this.data_map2);
  }

  refresh_graph3() {
    this.graph3.data = [];
    this.graph3.data = Object.values(this.data_map3);
  }

  public show_maintenance = false;

  constructor(public reportRepo: HealthReportRepo, public modal: BsModalService,  public authService: AuthService,  public toaster: ToasterService) {

    this.show_maintenance = (this.authService.userHasPermission('firmware_admin') || this.authService.userHasPermission('maintenance_admin'));


    reportRepo.getWorkerReport('stage2').then((report: any) => {
      if (report && report['results']) {
        let active_workers = [];
        let active_nodes = [];
        let time = [];

        for (let i = 0; i < report.results.length; i++) {
          let e = report.results[i];
          time.push(new Date(e.t * 1000))
          active_workers.push(e.aw);
          active_nodes.push(e.n);
        }
        this.data_map['s2-workers'] = {x: time, y: active_workers, type: 'line', mode: 'lines', name: 's2 workers'};
        this.data_map['s2-nodes'] = {x: time, y: active_nodes, type: 'line', mode: 'lines', name: 's2 nodes'};
        this.refresh_graph();
      }
    });


    reportRepo.getWorkerReport('stage1').then((report: any) => {
      if (report && report['results']) {
        let active_workers = [];
        let active_nodes = [];
        let time = [];

        for (let i = 0; i < report.results.length; i++) {
          let e = report.results[i];
          time.push(new Date(e.t * 1000))
          active_workers.push(e.aw);
          active_nodes.push(e.n);
        }
        this.data_map['s1-workers'] = {x: time, y: active_workers, type: 'line', mode: 'lines', name: 's1 workers'};
        this.data_map['s1-nodes'] = {x: time, y: active_nodes, type: 'line', mode: 'lines', name: 's1 nodes'};
        this.refresh_graph();
      }
    });


    reportRepo.getWorkerReport('datastreams').then((report: any) => {
      if (report && report['results']) {
        let active_workers = [];
        let active_nodes = [];
        let time = [];

        for (let i = 0; i < report.results.length; i++) {
          let e = report.results[i];
          time.push(new Date(e.t * 1000))
          active_workers.push(e.aw);
          active_nodes.push(e.n);
        }
        this.data_map['ds-workers'] = {x: time, y: active_workers, type: 'line', mode: 'lines', name: 'ds workers'};
        this.data_map['ds-nodes'] = {x: time, y: active_nodes, type: 'line', mode: 'lines', name: 'ds nodes'};
        this.refresh_graph();
      }
    });

    reportRepo.getWorkerReport('alerts').then((report: any) => {
      if (report && report['results']) {
        let active_workers = [];
        let active_nodes = [];
        let time = [];

        for (let i = 0; i < report.results.length; i++) {
          let e = report.results[i];
          time.push(new Date(e.t * 1000))
          active_workers.push(e.aw);
          active_nodes.push(e.n);
        }
        this.data_map['alert-workers'] = {x: time, y: active_workers, type: 'line', mode: 'lines', name: 'alert workers'};
        this.data_map['alert-nodes'] = {x: time, y: active_nodes, type: 'line', mode: 'lines', name: 'alert nodes'};
        this.refresh_graph();
      }
    });


    reportRepo.getUserRegistrationReport().then((report) => this.user_registration_report = report);
    reportRepo.getUserRegistrationPlot('day').then((report: any) => {
      if (report && report['results']) {
        //this.user_registration_report_daily = report;

        let time = [];
        let users = [];
        let has_display = [];
        let not_connected = [];

        for (let i = 0; i < report.results.length; i++) {
          let e = report.results[i];
          time.push(new Date(e.t * 1000))
          users.push(e.u);
          has_display.push(e.d);
          not_connected.push(e.n);
        }

        this.data_map3['day.users'] = {x: time, y: users, type: 'line', mode: 'lines', name: 'day users'};
        this.data_map3['day.users.display'] = {x: time, y: has_display, type: 'line', mode: 'lines', name: 'day users.display'};
        this.data_map3['day.users.nc'] = {x: time, y: not_connected, type: 'line', mode: 'lines', name: 'day users.not_connected'};
        this.refresh_graph3();
      }
    });

    reportRepo.getUserRegistrationPlot('hour').then((report: any) => {
      if (report && report['results']) {
        //this.user_registration_report_hourly = report;

        let time = [];
        let users = [];
        let has_display = [];
        let not_connected = [];

        for (let i = 0; i < report.results.length; i++) {
          let e = report.results[i];
          time.push(new Date(e.t * 1000))
          users.push(e.u);
          has_display.push(e.d);
          not_connected.push(e.n);
        }

        this.data_map3['hour.users'] = {x: time, y: users, type: 'line', mode: 'lines', name: 'hour users'};
        this.data_map3['hour.users.display'] = {x: time, y: has_display, type: 'line', mode: 'lines', name: 'hour users.display'};
        this.data_map3['hour.users.nc'] = {x: time, y: not_connected, type: 'line', mode: 'lines', name: 'hour users.not_connected'};
        this.refresh_graph3();
      }
    });

    reportRepo.getDailyMetrics('active_sensors').then((report: any) => {
      if (report && report['results']) {
        //this.sensor_daily_report = report;
        let time = [];
        let count = [];

        for (let i = 0; i < report.results.length; i++) {
          let e = report.results[i];
          time.push(new Date(e.t * 1000))
          count.push(e.c);
        }
        this.data_map['daily.sensors'] = {x: time, y: count, type: 'line', mode: 'lines', name: 'daily sensors'};
        this.refresh_graph();
      }
    });

    reportRepo.getMetricPlot('gateway.worker.report_binary').then((report: any) => {
      if (report && report['results']) {
        //this.metric_report['gateway.worker.report_binary'] = report

        let time = [];
        let count = [];

        for (let i = 0; i < report.results.length; i++) {
          let e = report.results[i];
          time.push(new Date(e.t * 1000))
          count.push(e.c);
        }
        this.data_map2['report_binary'] = {x: time, y: count, type: 'line', mode: 'lines', name: 'report_binary'};
        this.refresh_graph2();
      }
    });
    reportRepo.getMetricPlot('gateway.worker.report_binary.drop').then((report: any) => {
      if (report && report['results']) {
        //this.metric_report['gateway.worker.report_binary.drop'] = report;

        let time = [];
        let count = [];

        for (let i = 0; i < report.results.length; i++) {
          let e = report.results[i];
          time.push(new Date(e.t * 1000))
          count.push(e.c);
        }
        this.data_map2['report_binary.drop'] = {x: time, y: count, type: 'line', mode: 'lines', name: 'report_binary.drop'};
        this.refresh_graph2();
      }
    });
    reportRepo.getMetricPlot('request.json.error').then((report: any) => {
      if (report && report['results']) {
        //this.metric_report['request.json.error'] = report;

        let time = [];
        let count = [];

        for (let i = 0; i < report.results.length; i++) {
          let e = report.results[i];
          time.push(new Date(e.t * 1000))
          count.push(e.c);
        }
        this.data_map2['json.error'] = {x: time, y: count, type: 'line', mode: 'lines', name: 'json error'};
        this.refresh_graph2();
      }
    });
    reportRepo.getMetricPlot('request.serial.error').then((report: any) => {
      if (report && report['results']) {
        //this.metric_report['request.serial.error'] = report;


        let time = [];
        let count = [];

        for (let i = 0; i < report.results.length; i++) {
          let e = report.results[i];
          time.push(new Date(e.t * 1000))
          count.push(e.c);
        }
        this.data_map2['serial.error'] = {x: time, y: count, type: 'line', mode: 'lines', name: 'serial error'};
        this.refresh_graph2();
      }
    });
    reportRepo.getMetricPlot('request.timeout.error').then((report: any) => {
      if (report && report['results']) {
        //this.metric_report['request.timeout.error'] = report;

        let time = [];
        let count = [];

        for (let i = 0; i < report.results.length; i++) {
          let e = report.results[i];
          time.push(new Date(e.t * 1000))
          count.push(e.c);
        }
        this.data_map2['timeout.error'] = {x: time, y: count, type: 'line', mode: 'lines', name: 'timeout error'};
        this.refresh_graph2();
      }
    });

    reportRepo.getMetricPlot('request.uncaught.error').then((report: any) => {
      if (report && report['results']) {
        //this.metric_report['request.uncaught.error'] = report;

        let time = [];
        let count = [];

        for (let i = 0; i < report.results.length; i++) {
          let e = report.results[i];
          time.push(new Date(e.t * 1000))
          count.push(e.c);
        }
        this.data_map2['uncaught.error'] = {x: time, y: count, type: 'line', mode: 'lines', name: 'uncaught error'};
        this.refresh_graph2();
      }
    });


    reportRepo.getSystemStatus().then((report) => this.system_status_report = report);

  }

  public clear_maintenance(event) {
    event.preventDefault();
    this.reportRepo.clearMaintenance().then((r) => {
        this.toaster.pop('info', 'Maintenance Mode', "Cleared.");
      }
    );
  }

  public schedule_maintenance(event, modalElm) {
    event.preventDefault();
    this.modal_ref = this.modal.show(modalElm);
  }


  public confirm_maintenance(event, reason) {
    event.preventDefault();
    this.reportRepo.scheduleMaintenance(reason).then((r) => {
        this.toaster.pop('info', 'Maintenance Mode', "Scheduled.");
      this.modal_ref.hide();
      }
    );
  }

}
