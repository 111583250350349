
import { Component,Input, OnInit}      from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location }               from '@angular/common';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { GatewayEntity } from '../../entities/gateway.entity';
@Component({
  selector: 'gateway-show',
  templateUrl: './show.component.html'
})
export class GatewayShowComponent implements OnInit {
  public gatewayForm: FormGroup;
  showHide:Object = {"loading": false, "nav":false, "err":false};
  public unmodifiedEntry = null;
  public editEntry : boolean = false;
  @Input() entry: GatewayEntity = null;
  isNew: boolean= false;
  public manufacturers = [
    {"value":'14', "display":"SINO"},
    {"value":'23', "display":"FOS"}
  ];

  constructor(
    public _fb: FormBuilder,
    public route: ActivatedRoute,
    public location: Location ) {
  }

   ngOnInit() {
     if (!this.entry) {
       this.isNew = true;
     }
   }

  update(e, entity) {
    e.preventDefault();
    this.editEntry = false;
    entity.update();
  }

  edit(e, entity) {
    e.preventDefault();
    this.unmodifiedEntry = this.entry.toJson();
    this.editEntry = true;
  }

  cancel(e) {
    e.preventDefault();
    if (this.unmodifiedEntry) {
      this.entry.refresh(this.unmodifiedEntry);
      this.editEntry = false;
    } else {
      // new entry deleted
    }

  }

  delete(e, entity) {
    /*
    e.preventDefault();
    // todo remove from parent so it vanishes after success
    this.editEntry = false;
    entity.delete().then( (res:any) => {
      if(res) {
        this.deleted=true;
      }
    });*/
  }
}
