import {Component, Input, Inject, OnInit} from '@angular/core';
import {LacrosseEntity} from "../../entities/lacrosse.entity";
import {NoizuStruct} from "../../noizu/structs/noizu-struct";
import {CallbackObject, RuntimeConfigurationObject, SubscriberObject} from '../../entities/alert';

@Component({
  selector: 'toggle-widget',
  template: `<div class="pr-3" (click)="toggle($event)"><i class=" fa" [ngClass]="{'fa-lg': options?.lg, 'fa-sm': !options?.lg,  'fa-chevron-down': options?.toggle, 'fa-chevron-up': !options?.toggle}"> </i></div>`
})
export class WidgetToggleComponent implements  OnInit{
  @Input() options: any = null;
  constructor() {
  }

  public ngOnInit(): void {
    if (this.options && ! ('toggle' in this.options)) {
      this.options.toggle = false;
    }
  }

  public toggle(e) {
    if (this.options) {
      this.options.toggle = !this.options.toggle;
    }
  }

}
