



    <ng-container [ngSwitch]="layout">
      <ng-container *ngSwitchCase="'table'">
        <ng-container *ngIf="entity.has_entries">
          <div class="row">
            <div class="col ml-3 mr-3">
              <ng-container *ngFor="let e of entity.filteredSet; let i = index">
                <div class="row" *ngIf="i >= (entity.page * entity.dpp) && i < ((entity.page + 1) * entity.dpp)" >
                  <div class="col">
                    <widget (widgetEvent)="forwardEvent($event)"  [entity]="e" [options]="{nested: options['nested'], id: e.identifier, columns: options['headers'], edit: options['edit'], index: i, header: i == (entity.page * entity.dpp)}" [layout]="layout"></widget>
                  </div>
                </div>
              </ng-container>

            </div>

            <div [ngClass]="{'col-6': options?.sideBarWidth == 6, 'col-5': options?.sideBarWidth == 5, 'col-4': options?.sideBarWidth == 4, 'col-3': options?.sideBarWidth == 3, 'col-3': !options?.sideBarWidth}" class="col-3" *ngIf="entity.selected">
              <widget (widgetEvent)="forwardEvent($event)"  [entity]="entity.selected" [options]="{nested: options['nested'], edit: options['edit'], expand_on_load: true}" [layout]="'sidebar'"></widget>
            </div>
          </div>
        </ng-container>

      </ng-container>

      <ng-container *ngSwitchDefault>
        <div *ngIf="entity.has_entries">


              <div class="row">
                <ng-container [ngSwitch]="entity.columns">

                  <ng-container *ngSwitchCase="'1'">
                    <ng-container *ngFor="let e of entity.filteredSet; let i = index">
                      <div class="{{card_margins}} col-12"  *ngIf="i >= (entity.page * entity.dpp) && i < ((entity.page + 1) * entity.dpp)" >
                        <widget (widgetEvent)="forwardEvent($event)"  [entity]="e" [options]="{nested: options['nested'], id: e.identifier, edit: options['edit'], expand_on_load: true}" [layout]="layout"></widget>
                      </div>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngSwitchCase="'2'">
                    <ng-container *ngFor="let e of entity.filteredSet; let i = index">
                      <div class="{{card_margins}} col-lg-6 col-12"  *ngIf="i >= (entity.page * entity.dpp) && i < ((entity.page + 1) * entity.dpp)" >
                        <widget (widgetEvent)="forwardEvent($event)"  [entity]="e" [options]="{nested: options['nested'], id: e.identifier, edit: options['edit'], expand_on_load: true}" [layout]="layout"></widget>
                      </div>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngSwitchCase="'3'">
                    <ng-container *ngFor="let e of entity.filteredSet; let i = index">
                      <div class="{{card_margins}} col-lg-4 col-12"  *ngIf="i >= (entity.page * entity.dpp) && i < ((entity.page + 1) * entity.dpp)" >
                        <widget (widgetEvent)="forwardEvent($event)"  [entity]="e" [options]="{nested: options['nested'], id: e.identifier, edit: options['edit'], expand_on_load: true}" [layout]="layout"></widget>
                      </div>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngSwitchCase="'4'">
                    <ng-container *ngFor="let e of entity.filteredSet; let i = index">
                      <div class="{{card_margins}} col-lg-3 col-12"  *ngIf="i >= (entity.page * entity.dpp) && i < ((entity.page + 1) * entity.dpp)" >
                        <widget (widgetEvent)="forwardEvent($event)"  [entity]="e" [options]="{nested: options['nested'], id: e.identifier, edit: options['edit'], expand_on_load: true}" [layout]="layout"></widget>
                      </div>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngSwitchCase="'6'">
                    <ng-container *ngFor="let e of entity.filteredSet; let i = index">
                      <div class="{{card_margins}} col-lg-2 col-12"  *ngIf="i >= (entity.page * entity.dpp) && i < ((entity.page + 1) * entity.dpp)" >
                        <widget (widgetEvent)="forwardEvent($event)"  [entity]="e" [options]="{nested: options['nested'], id: e.identifier, edit: options['edit'], expand_on_load: true}" [layout]="layout"></widget>
                      </div>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngSwitchCase="'12'">
                    <ng-container *ngFor="let e of entity.filteredSet; let i = index">
                      <div class="{{card_margins}} col-lg-1 col-12"  *ngIf="i >= (entity.page * entity.dpp) && i < ((entity.page + 1) * entity.dpp)" >
                        <widget (widgetEvent)="forwardEvent($event)"  [entity]="e" [options]="{nested: options['nested'], id: e.identifier, edit: options['edit'], expand_on_load: true}" [layout]="layout"></widget>
                      </div>
                    </ng-container>
                  </ng-container>


                  <ng-container *ngSwitchDefault>
                    <ng-container *ngFor="let e of entity.filteredSet; let i = index">
                      <div class="{{card_margins}} col-lg-6 col-12"  *ngIf="i >= (entity.page * entity.dpp) && i < ((entity.page + 1) * entity.dpp)" >
                        <widget (widgetEvent)="forwardEvent($event)"  [entity]="e" [options]="{nested: options['nested'], id: e.identifier, edit: options['edit'], expand_on_load: true}" [layout]="layout"></widget>
                      </div>
                    </ng-container>
                  </ng-container>

                </ng-container>
              </div>



        </div>

      </ng-container>

    </ng-container>

  