import {NoizuStruct} from '../noizu/structs/noizu-struct';
import {WidgetEnum, WidgetEnum_Alert_Facade} from '../enums/widget';
import {AlertTriggerEvent} from './alert';

export class BaseEntitySet extends NoizuStruct {
  public entities: any = [];

  public constructor() {
    super();
  }


  isMap() {
    if (this.entities &&  typeof this.entities == 'object') return true;
    return false;
  }

  hasEntities() {
    if (this.entities &&  typeof this.entities == 'object') {
      return !!Object.keys(this.entities).length;
    }

    if ( Array.isArray(this.entities)) {
      return !(this.entities==[]);
    }
    return false;
  }

  name() {
    return typeof this;
  }

  widget_type(): WidgetEnum_Alert_Facade | null | string | number  {
    return WidgetEnum.EMBED_WIDGET__GENERIC_SET;
  }
}
