import {Component, Input, Output, EventEmitter} from '@angular/core';
import {DataManagementLogicalWidgetEnum} from '../../../enums/widget';
import {PSKUploadEntity, VerificationCodeUploadEntity} from '../../../entities/';
import {ToasterService} from 'angular2-toaster';
import {DataManagementWidgetEventStruct} from './data-management-widget-event.struct';

@Component({
  selector: 'data-management-generic-widget',
  template: `
    <div class="data-management-generic-widget  card">
      <div class="card-header">
        <div class="row mb-5">
          <div class="col text-center border-bottom">
            <span class="display-4">{{ DataManagementLogicalWidgetEnum[entity.data_management_logical_widget_type()] }}</span>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <pre>{{entity.toJson()| json}}</pre>
          </div>
        </div>
      </div>
    </div>`
})
export class DataManagementGenericWidgetComponent {
  @Input() entity: PSKUploadEntity | VerificationCodeUploadEntity | null = null;
  @Input() options: any = null;
  @Input() layout = 'standard';

  @Output() widgetEvent = new EventEmitter<DataManagementWidgetEventStruct>();
  forwardEvent(e, widgetEvent: DataManagementWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }

  DataManagementLogicalWidgetEnum = DataManagementLogicalWidgetEnum

  constructor(public toasterService: ToasterService) {

  }
}
