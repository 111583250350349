import {LogicalExtendedDeviceWidgetEnum, LogicalWidgetEnum} from '../../../enums';
import {ExtendedDeviceLogicalWidget} from './extended-device-logical.widget';
import {HttpClient} from '@angular/common/http';
import {FirebaseAuthService} from '../../../noizu/services/firebase-auth.service';
import {DeviceDefinitionEntity} from '../definition.entity';
import {DeviceStatusEntity} from '../../device-status.entity';
// import {forEach} from '@angular/router/src/utils/collection';

export class ReadingFieldAggregate {
  public aggregate;
  public value;
  public time;
  public attributes;
  public has_attributes = false;
  constructor(aggregate, json) {
    this.aggregate = aggregate;
    this.value = json['value'];
    this.time = json['time'] && new Date(json['time'] * 1000) || null;
    this.attributes = json['attributes'] || null;
    this.has_attributes = this.attributes && Object.keys(this.attributes).length > 0 && true || false
  }

}

export class ReadingField {
  public name;
  public unit;
  public data_points;
  public attribute_history;
  public aggregates: any; // Map<String, ReadingFieldAggregate>;
  public raw: ReadingFieldAggregate[];

  constructor(name, json) {
    this.name = name;
    this.unit = json['unit'];
    this.data_points = json['data_points'];
    this.attribute_history = json['attribute_history'] || null;
    //this.aggregates = new Map<String, ReadingFieldAggregate>();
    this.aggregates = {};
    ["high", "low", "average", "tally", "spot"].forEach((v) => {
      if (json[v]) this.aggregates[v] = new ReadingFieldAggregate(v, json[v]);
    });
  }
}

export class ReadingSection {
  public name: string;
  public aggregate_interval; // Enum
  public cover_type; // Enum

  public cover_start: Date | null;
  public cover_end: Date | null;

  public block_start: Date | null;
  public block_end: Date | null;

  public state; // Enum
  public fields: any; // Map<String, ReadingField>;

  public updated = false;
  protected _raw = [];

  constructor(time, cover, json) {
    this.name = `${time} - ${cover}`;
    this.aggregate_interval = time;
    this.cover_type = cover;
    this.cover_start = json['cover'] && json['cover']['u_from'] && (new Date(json['cover']['u_from'] * 1000)) || null;
    this.cover_end = json['cover'] && json['cover']['u_to'] && (new Date(json['cover']['u_to'] * 1000)) || null;
    this.block_start = json['block'] && json['block']['u_from'] && (new Date(json['block']['u_from'] * 1000)) || null;
    this.block_end = json['block'] && json['block']['u_to'] && (new Date(json['block']['u_to'] * 1000)) || null;
    this.state = json['state'] || 'other';
    this.fields = {}; // new Map<String, ReadingField>();
    for (let key in json['fields']) {
      let value = json['fields'][key];
      this.fields[key] = new ReadingField(key, value);
    }
    this.updated = true;
  }
}

export class ExtendedDeviceReadingEntity extends ExtendedDeviceLogicalWidget {
  public device_type: DeviceDefinitionEntity | null = null;
  public attributes: any = null;
  public battery_status: string | null = null;
  public battery_level: number | null = null;
  public last_update: Date | null = null;
  public time_zone = 'America/Chicago';
  public s2_poll = false;
  public s3_poll = false;
  public cached_on: Date | null = null;
  public readings: any = null;
  public update_promise: any | null = null;

  constructor(public client: HttpClient, public auth: FirebaseAuthService, json) {
    super();
    this.subject = json['device'];
    this.device_type = json['device_type'];
    this.attributes = json['attributes'];
    this.meta['loading'] = true;
    this.meta['loading_failed'] = false;
    this.meta['fall_back_mode'] = false;
  }



  loadJsonVNext(json) {
    this.battery_status = json['status'] && json['status']['battery_status'] || 'Unknown';
    this.battery_level = json['status'] && json['status']['battery_level'] || '??';
    this.last_update = json['recorded_on'] && new Date(json['recorded_on']);
    this.time_zone = json['time_zone'] || this.time_zone;
    this.cached_on = json['cached_on'] && new Date(json['cached_on']);

    let f = {};
    for (let key in json['readings']) {
      let unit = json['readings'][key]['reading']['unit'];
      let data_points = 1;
      let aggregates = [{"aggregate": "plot", "value": json['readings'][key]['reading'].value, time: json['readings'][key]['recorded_on']}];
      f[key] = {
        unit: unit,
        name: key,
        data_points: data_points,
        aggregates: aggregates
      }
    }
    this.readings = {"current": {head: {name: 'plot', fields: f}}};
  }



  loadJson(json) {
    this.battery_status = json['status'] && json['status']['battery_status'] || 'Unknown';
    this.battery_level = json['status'] && json['status']['battery_level'] || '??';
    this.last_update = json['last_update'] && new Date(json['last_update'] * 1000);
    this.time_zone = json['time_zone'] || this.time_zone;
    this.s2_poll = json['s2_poll'];
    this.s3_poll = json['s3_poll'];
    this.cached_on = json['cached_on'] && new Date(json['cached_on']);

    this.readings = {};

    for (let key in json['data']) {
      let value = json['data'][key];
      if (key == 'unbound' || key == 'current') value = {head: {fields: value}};
      this.readings[key] = {};

      if (value) {
        for (let key2 in value) {
          let value2 = value[key2];
          this.readings[key][key2] = new ReadingSection(key, key2, value2)
        }
      }

    }


    /*
    this.readings = json['data'];
    let current = this.readings['current'];
    let unbound = this.readings['unbound'];
    this.readings.current = {head: current};
    this.readings.unbound = {head: unbound};
    */
  }


  getPromise(time_zone = 'default', refresh = false, force = false) {
    console.log("Sensor", this)

    if (time_zone == 'default') time_zone = this.time_zone;

    if (force) {
      this.update_promise = false;
      this.meta['loading'] = false;
      this.meta['loading_failed'] = false;
      this.meta['fall_back_mode'] = false;
    }

    if (refresh || (time_zone != this.time_zone) ) {
      if (this.update_promise && this.meta['loading']) return this.update_promise;
      else (this.update_promise = null);
    }

    if (!this.update_promise) {
      this.meta['loading'] = true;
      this.meta['fall_back_mode'] = false;
      this.update_promise = new Promise(
        (resolve, reject) => {

          if (this.attributes['api'] == "2") {

            let url = `${this._ingv_endpoint}/v1.3/active-user/device-association/${this.subject}/status?tz=${time_zone}`;
            this._get(url, (data, r2) => {
              this.loadJsonVNext(data);
              this.time_zone = time_zone;
              this.meta['loading'] = false;
              r2(data);
            })
              .then((r) => resolve(r))
              .catch((e) => {
                let url = `${this.ingvEndpoint()}/admin-tools/device/${this.subject}/last-update`;
                this._get(url, (data, r2) => {
                    this.meta['loading'] = false;
                    this.time_zone = time_zone;
                    if (data['outcome']) {
                      this.meta['fall_back_mode'] = true;
                      // hack to avoid setting to 'Etc/Utc'
                      data['value']['time_zone'] = null;
                      this.loadJson(data['value']);
                    } else {
                      this.meta['loading_failed'] = true;
                    }
                    r2(data);
                  }
                ).then(
                  (r) => resolve(r)
                ).catch((e) => {
                  this.meta['loading'] = false;
                  this.meta['loading_failed'] = true;
                  this.time_zone = time_zone;
                  // invalid status load
                  console.warn("UNCAUGHT ", e);
                  reject(e);
                });

              });

          } else {
            let url = `${this.ingvEndpoint()}/active-user/device-association/${this.subject}/status?tz=${time_zone}`;
            this._get(url, (data, r2) => {
              this.loadJson(data);
              this.time_zone = time_zone;
              this.meta['loading'] = false;
              r2(data);
            })
              .then((r) => resolve(r))
              .catch((e) => {
                let url = `${this.ingvEndpoint()}/admin-tools/device/${this.subject}/last-update`;
                this._get(url, (data, r2) => {
                    this.meta['loading'] = false;
                    this.time_zone = time_zone;
                    if (data['outcome']) {
                      this.meta['fall_back_mode'] = true;
                      // hack to avoid setting to 'Etc/Utc'
                      data['value']['time_zone'] = null;
                      this.loadJson(data['value']);
                    } else {
                      this.meta['loading_failed'] = true;
                    }
                    r2(data);
                  }
                ).then(
                  (r) => resolve(r)
                ).catch((e) => {
                  this.meta['loading'] = false;
                  this.meta['loading_failed'] = true;
                  this.time_zone = time_zone;
                  // invalid status load
                  console.warn("UNCAUGHT ", e);
                  reject(e);
                });

              });
          }


        });
    }
    return this.update_promise;
  }


  logical_extended_device_widget_type() {
    return LogicalExtendedDeviceWidgetEnum.LOGICAL_EXTENDED_DEVICE_WIDGET__READING;
  }
}
