import {
  LogicalExtendedDeviceWidgetEnum,
  LogicalWidgetEnum
} from '../../../enums/widget';
import {LogicalWidget} from '../../logical-widget';

export class ExtendedDeviceLogicalWidget extends LogicalWidget {
  logical_widget_type(): LogicalWidgetEnum | null {
    return LogicalWidgetEnum.LOGICAL_WIDGET__EXTENDED_DEVICE;
  }

  logical_extended_device_widget_type(): LogicalExtendedDeviceWidgetEnum | null {
    return LogicalExtendedDeviceWidgetEnum.LOGICAL_EXTENDED_DEVICE_WIDGET__GENERIC;
  }

  constructor() {
    super();
  } // end constructor
} // end Package
