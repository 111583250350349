
import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from './lacrosse.entity';
import { FirebaseAuthService }            from '../noizu/services/firebase-auth.service';

export class AppNotificationEntity extends LacrosseEntity {
  public _kind = '';


  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
  } // end constructor

  postMessage(cat, msg, color) {
    // @todo review backend, where is the cloud function hosted? firebase?
    return this._post(`https://us-central1-lax-gateway.cloudfunctions.net/setCategoryNotification`, {"category":cat,"text":msg,"color":color}, (data, resolve) => {
      console.log(data);
      resolve(data);
    }, {})
  }

} // end Package
