
    <ng-container *ngIf="entity">
      <div class="card">
      <div class="card-header">[EACH] <i class="float-right fa fa-plus" (click)="expand = !expand"></i></div>
      <div class="card-body" *ngIf="expand">

        <ng-container *ngIf="entity.clause">
          <div class="row">
            <div class="col"><strong>clause</strong></div>
          </div>
          <div class="row">
            <div class="col">
              <email-template-section-widget [entity]="entity.clause" [layout]="layout" [options]="options"></email-template-section-widget>
            </div>
          </div>
        </ng-container>


        <ng-container *ngIf="entity.arguments && entity.arguments.length > 0">
          <div class="row">
            <div class="col"><strong>Children</strong></div>
          </div>
          <div class="row" *ngFor="let item of entity.arguments">
            <div class="col">
              <email-template-section-widget [entity]="item" [layout]="layout" [options]="options"></email-template-section-widget>
            </div>
          </div>
        </ng-container>



      </div>
      </div>
    </ng-container>
