import {NoizuStruct} from '../../noizu/structs/noizu-struct';
import {DataStreamDeviceLink} from './link/device-link';
import {DiagnosticReading} from './diagnostic-card.entity';


export class DataStreamDeviceCardEntity extends NoizuStruct {
  public _kind = 'GoldenRatio.DataStream.Card.VirtualDeviceCard.V1_1';
  public messages = {one: ':auto', two: ':auto'};
  public link: DataStreamDeviceLink;
  public modified = false;

  constructor(json) {
    super();
    if (json['messages']) {
      this.messages.one = json['messages']['one'];
      this.messages.two = json['messages']['two'];
    }
    if (json['link']) {
      this.link = new DataStreamDeviceLink(json['link']);
    } else {
      this.link = new DataStreamDeviceLink({});
    }
  } // end constructor

  toJson(options: any = {}) {
    return {
      messages: this.messages,
      link: this.link ? this.link.toJson() : null,
    }
  }

  validate() {
    if (this.link) {
      return this.link.validate();
    } else  {
      return [{error: 'NoLink', message: 'Device Link Details Missing'}];
    }
  }

  widget() {
    return 'embed-ds-device-card';
  }
} // End Package
