import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ToasterService} from 'angular2-toaster';
import {AlertWetDryFacadeEntity} from '../../../../entities/alert';
import {AlertFacadeDefaultWidgetComponent} from './default.widget.component';
import {AlertFacadeWidgetEventStruct} from './alert-facade-widget-event.struct';

@Component({
  selector: 'alert-facade-wet-dry-widget',
  template: `
    <div class="card card-accent-dark alert-facade-wet-dry-widget">
      <div class="card-header">Wet-Dry Alert


        <strong>Details</strong><span></span>
        <span class="float-right"><i
          (click)="refreshStatus($event)"
          class="fa fa-sm"
          [ngClass]="{'fa-refresh': !entity.meta.refreshing, 'fa-cloud text-primary':entity.meta.refreshing}"> </i></span>


      </div>
      <div class="card-body">
        <div class="row"><div class="col-4">Device</div><div class="col-8"><a [routerLink]="'/portal/devices/show/' + entity.device">{{entity.device}}</a></div></div>
        <div class="row"><div class="col-4">Association</div><div class="col-8">{{entity.deviceAssociation}}</div></div>
        <div class="row"><div class="col-12"><hr/></div></div>
        <div class="row">
          <div class="col-12" *ngIf="entity.triggerIfWet">Trigger If <b>Wet</b></div>
          <div class="col-12" *ngIf="!entity.triggerIfWet">Trigger If <b>Dry</b></div>
        </div>
        <div *ngIf="!options?.edit" class="row">
          <div class="col-12">Trigger If <b *ngIf="entity.triggerIfWet">Wet</b><b *ngIf="!entity.triggerIfWet">Dry</b></div>
        </div>
        <div *ngIf="options?.edit" class="row"><div class="col-4">Trigger If</div>
          <div class="col-8">
            <label class="switch switch-3d switch-primary">
              <input checked="" class="switch-input switch-primary" (change)="facadeChange($event)" [(ngModel)]="entity.triggerIfWet" type="checkbox">
              <span *ngIf="entity.triggerIfWet" class="switch-slider">Wet</span>
              <span *ngIf="!entity.triggerIfWet" class="switch-slider">Dry</span>
            </label>
          </div>
        </div>

      </div>
    </div>
    `
})
export class EmbedAlertWetDryFacadeComponent extends AlertFacadeDefaultWidgetComponent{
  public editMode = false;
  @Input() layout: string = null;
  @Input() entity: AlertWetDryFacadeEntity;
  @Input() options: any;
  @Output() widgetEvent = new EventEmitter<AlertFacadeWidgetEventStruct>();
  forwardEvent(e, widgetEvent: AlertFacadeWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }

  constructor(public toasterService: ToasterService) {
    super();
  }

  refreshStatus(e) {
      this.entity.meta.refreshing = !this.entity.meta.refreshing;
  }

}
