import {Component, Input, Output, EventEmitter} from '@angular/core';
import {EntityLogEntity} from '../../../../entities/log/entity-log.entity';
import {WidgetBase} from '../../widget-base';
import {LogWidgetEventStruct} from '../log-widget-event.struct';
import {LogWidgetEnum} from '../../../../enums';

@Component({
  selector: 'gateway-extract-binary-log-details-widget',
  template: `
    <ng-container *ngIf="entity">
      <ng-container [ngSwitch]="layout">
        <div class="gateway-extract-binary-log-details-widget" *ngSwitchCase="'card-details'">


          <hr />

          <div class="row mb-4">
            <div class="col-12">
              <div>
                <div class="row">
                  <div class="col-3"><b>Device</b></div>
                  <div class="col-9">
                    <a
                      routerLinkActive="active"
                      [routerLink]="['/portal/devices/show/' + entity.message.encoding.device]"
                    >{{entity?.message?.encoding?.device}}</a>
                  </div>
                </div>

                <div class="row">
                  <div class="col-3"><b>Raw</b></div>
                  <div class="col-9">
                    <span class="badge badge-pill badge-primary">{{entity?.message?.encoding?.internal}}</span>
                  </div>
                </div>

                <div class="row">
                  <div class="col-3"><b>Received On</b></div>
                  <div class="col-9">{{entity?.message?.encoding?.envelope?.received_on}}</div>
                </div>

                <div class="row">
                  <div class="col-3"><b>Gateway</b></div>
                  <div class="col-9">
                    <a
                      routerLinkActive="active"
                      [routerLink]="['/portal/devices/show/' + entity?.message?.encoding?.envelope?.gateway]"
                    >{{entity?.message?.encoding?.envelope?.gateway}}</a>
                  </div>
                </div>

                <div class="row">
                  <div class="col-3"><b>Firmware</b></div>
                  <div class="col-9">{{entity?.message?.encoding?.envelope?.firmware}}</div>
                </div>

                <div class="row mb-2">
                  <div class="col-3"><b>Event</b></div>
                  <div class="col-9">{{entity?.message?.encoding?.envelope?.event}}</div>
                </div>

                <div class="div-table-th">
                  <div class="row">
                    <div class="col-2">
                      Field
                    </div>
                    <div class="col-2">
                      Raw Value
                    </div>
                    <div class="col-4">
                      Unit
                    </div>
                    <div class="col-4">
                      Attributes
                    </div>
                  </div>
                </div>

                <div *ngFor="let reading of entity.readings(); let i = index;"
                     [ngClass]="{'div-table-tr': (i % 2) == 1, 'div-table-tr-alt': (i % 2) == 0}">
                  <div class="row">
                    <div class="col-2">
                      {{reading.field}}
                    </div>
                    <div class="col-2">
                      {{reading.value | number}}
                    </div>
                    <div class="col-4">
                      {{reading.unit}}
                    </div>
                    <div class="col-4">
                      <pre>{{reading.attributes| json}}</pre>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  Raw Data<span class="float-right">
              <i
                (click)="expandRaw = !expandRaw"
                class="fa fa-sm"
                [ngClass]="{'fa-chevron-down': !expandRaw, 'fa-chevron-up': expandRaw}"> </i>
            </span>
                </div>
                <div class="card-body" *ngIf="expandRaw">
                  <div class="row">
                    <div class="col-12">
                      <pre>Message: {{entity.message| json}}</pre>
                      <pre>Context: {{entity.context| json}}</pre>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



        </div>
        <div class="gateway-extract-binary-log-details-widget" *ngSwitchCase="'table-row-details'">



          <div class="row mb-4">
            <div class="col-12">
              <div>
                <div class="row">
                  <div class="col-3"><b>Device</b></div>
                  <div class="col-9">
                    <a
                      routerLinkActive="active"
                      [routerLink]="['/portal/devices/show/' + entity.message.encoding.device]"
                    >{{entity?.message?.encoding?.device}}</a>
                  </div>
                </div>

                <div class="row">
                  <div class="col-3"><b>Raw</b></div>
                  <div class="col-9">
                    <span class="badge badge-pill badge-primary">{{entity?.message?.encoding?.internal}}</span>
                  </div>
                </div>

                <div class="row">
                  <div class="col-3"><b>Received On</b></div>
                  <div class="col-9">{{entity?.message?.encoding?.envelope?.received_on}}</div>
                </div>

                <div class="row">
                  <div class="col-3"><b>Gateway</b></div>
                  <div class="col-9">
                    <a
                      routerLinkActive="active"
                      [routerLink]="['/portal/devices/show/' + entity?.message?.encoding?.envelope?.gateway]"
                    >{{entity?.message?.encoding?.envelope?.gateway}}</a>
                  </div>
                </div>

                <div class="row">
                  <div class="col-3"><b>Firmware</b></div>
                  <div class="col-9">{{entity?.message?.encoding?.envelope?.firmware}}</div>
                </div>

                <div class="row mb-2">
                  <div class="col-3"><b>Event</b></div>
                  <div class="col-9">{{entity?.message?.encoding?.envelope?.event}}</div>
                </div>

                <div class="div-table-th">
                  <div class="row">
                    <div class="col-2">
                      Field
                    </div>
                    <div class="col-2">
                      Raw Value
                    </div>
                    <div class="col-4">
                      Unit
                    </div>
                    <div class="col-4">
                      Attributes
                    </div>
                  </div>
                </div>

                <div *ngFor="let reading of entity.readings(); let i = index;"
                     [ngClass]="{'div-table-tr': (i % 2) == 1, 'div-table-tr-alt': (i % 2) == 0}">
                  <div class="row">
                    <div class="col-2">
                      {{reading.field}}
                    </div>
                    <div class="col-2">
                      {{reading.value | number}}
                    </div>
                    <div class="col-4">
                      {{reading.unit}}
                    </div>
                    <div class="col-4">
                      <pre>{{reading.attributes| json}}</pre>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  Raw Data<span class="float-right">
              <i
                (click)="expandRaw = !expandRaw"
                class="fa fa-sm"
                [ngClass]="{'fa-chevron-down': !expandRaw, 'fa-chevron-up': expandRaw}"> </i>
            </span>
                </div>
                <div class="card-body" *ngIf="expandRaw">
                  <div class="row">
                    <div class="col-12">
                      <pre>Message: {{entity.message| json}}</pre>
                      <pre>Context: {{entity.context| json}}</pre>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



        </div>
      </ng-container>
    </ng-container>
  `
})
export class GatewayExtractBinaryLogDetailsWidgetComponent extends WidgetBase{
  @Input() entity: EntityLogEntity = null;
  @Input() options: any = {};
  @Input() layout: string = null;
  @Output() widgetEvent = new EventEmitter<LogWidgetEventStruct>();
  forwardEvent(e, widgetEvent: LogWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }
  public expandCommands = false;
  public LogWidgetEnum = LogWidgetEnum;
  public expand = false;
  public expandRaw = false;
  public openRow = false;
  constructor() {
    super();

  }
}
