
import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from './lacrosse.entity';
import { FirebaseAuthService }            from '../noizu/services/firebase-auth.service';
import { DeviceDefinitionRepo }           from '../repos/device/definition.repo';
import { DeviceDefinitionCategory }       from '../structs/device/definition/category';
import { DeviceDefinitionFieldEntry }     from '../structs/device/definition/field-entry';

export class SynchQueueEntryEntity extends LacrosseEntity {
  public _kind = "synch-queue-entry";
  public environment;
  public entityClass;
  public entityIdentifier;
  public status;
  public requestToken;

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    this.environment = json["environment"];
    this.entityClass = json["entityClass"];
    this.entityIdentifier = json["entityIdentifier"];
    this.status = json["status"];
    this.requestToken = json["requestToken"];
  } // end constructor

  public retry(purge) {
    const ps = (purge === true) ? 'true' : 'false';
    return this._put(
      `${this.apiBase()}/backend-synch-queue/${this.identifier}/synchronize?purge=${ps}`,
      {},
      (data, resolve) => {console.log(data); resolve(data);},
      {}
    );
  }

} // end Package
