import {Component, Input, Output, EventEmitter} from '@angular/core';
import {EntityLogEntity} from '../../../entities/log/entity-log.entity';
import {WidgetBase} from '../widget-base';
import {LogWidgetEventStruct} from './log-widget-event.struct';
import {AlertLogWidgetEnum, LogWidgetEnum} from '../../../enums';
import {AlertEntityLogEntity} from '../../../entities';
import {LogWidgetComponent} from './log-widget.component';

@Component({
  selector: 'alert-log-widget',
  template: `
    <ng-container [ngSwitch]="entity.alert_log_type">
      <alert-generic-log-widget *ngSwitchDefault (widgetEvent)="forward($event)" [entity]="entity" [options]="options" [layout]="layout"></alert-generic-log-widget>
    </ng-container>

    `
})
export class AlertLogWidgetComponent extends LogWidgetComponent{
  @Input() entity: AlertEntityLogEntity = null;
  @Input() options: any = {};
  @Input() layout: string = null;
  @Output() widgetEvent = new EventEmitter<LogWidgetEventStruct>();
  forward(widgetEvent: LogWidgetEventStruct) {
    this.widgetEvent.emit(widgetEvent);
  }
  forwardEvent(e, widgetEvent: LogWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }
  public LogWidgetEnum = LogWidgetEnum;
  public expand = false;
  public expandRaw = false;
  public AlertLogWidgetEnum = AlertLogWidgetEnum;
  constructor() {
    super();

  }
}
