import {NoizuStruct} from '../../noizu/structs/noizu-struct';
import {WidgetEnum} from '../../enums';
import {DeviceEntity} from '../device.entity';

export class DeviceLinkStruct extends NoizuStruct{
  public device: DeviceEntity;
  public device_identifier;

  constructor(json) {
    super();
    this.device_identifier = json.sensorId;
  } // end constructor


  widget_type(): any {
    return WidgetEnum.EMBED_WIDGET__DEVICE_LINK;
  }

} // end Package
