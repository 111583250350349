import { Component } from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'forward-button',
  template: `
    <i class="fa fa-2x fa-arrow-circle-o-right text-dark" (click)="back($event)"></i>
  `
})
export class ForwardButtonComponent {

  constructor(private locationService: Location) {
  }

  back(e) {
    e.preventDefault()
    this.locationService.forward()
  }
}
