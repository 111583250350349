/*fieldTypeIdentifier: {
  notSupported: 0,

  temperature: 1, humidity: 2, rain: 3, windSpeed: 4, windHeading: 5,
  heatIndex: 6, windChill: 7, lightning: 8, wetness: 10,
  windGust: 11, feelsLike: 12, windLevel: 13, precipitationLevel: 14
},
*/
export enum FieldTypeEnum {
  NotSupported = 0,
  Temperature,
  Humidity,
  Rain,
  WindSpeed,
  WindHeading,
  HeatIndex,
  WindChill,
  Lightning,
  Wetness = 10,
  WindGust,
  FeelsLike,
  WindLevel,
  PrecipitationLevel
}
