<toaster-container [toasterconfig]="toasterConfig"></toaster-container>

<app-header
  [fixed]="true"
  [navbarBrandFull]="{src: 'https://cdn.shopify.com/s/files/1/0035/7443/1790/files/Main_copy_2x_f85b234b-d84f-43ea-91b3-92cc0f5ebaaf_190x@2x.png?v=1574701271', width: 240, alt: 'Logo'}"
  [navbarBrandMinimized]="{src: 'https://cdn.shopify.com/s/files/1/0035/7443/1790/files/Main_copy_2x_f85b234b-d84f-43ea-91b3-92cc0f5ebaaf_190x@2x.png?v=1574701271', width: 50, alt: 'Logo'}"
  [sidebarToggler]="'lg'"
  [asideMenuToggler]="'lg'">

  <ul class="nav navbar-nav d-md-down-none text-left pl-4">

    <li class="nav-item">
      <div class="row">
        <div class="col text-left">
        <span class="pr-3">
          <back-button></back-button>
        </span>
          <span>
          <forward-button></forward-button>
        </span>
        </div>
      </div>
    </li>

    <!--
    <form class="form-inline px-4">
      <i class="fa fa-search"></i>
      <input class="form-control" type="text" placeholder="Search...">
    </form>
    -->
  </ul>
  <ul class="nav navbar-nav ml-auto mr-auto text-center">

    <li class="nav-item" *ngIf="maintenance_mode?.maintenance_mode">
          <span>Maintenance: <span class="text-danger">{{maintenance_mode.reason}}</span> - <strong>{{maintenance_mode.user}}</strong>. <span *ngIf="maintenance_mode.from">From: {{maintenance_mode.from | date:'short'}}</span> <span *ngIf="maintenance_mode.to">Until: {{maintenance_mode.to | date:'short'}}</span> </span>
    </li>


    <!--
    <li class="nav-item dropdown d-md-down-none" dropdown placement="bottom right">
      <a class="nav-link nav-pill" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle (click)="false">
        <i class="icon-bell"></i><span class="badge badge-pill badge-danger">5</span>
      </a>
      <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg" *dropdownMenu aria-labelledby="simple-dropdown">
        <div class="dropdown-header text-center"><strong>You have 5 notifications</strong></div>
        <a href="#" class="dropdown-item">
          <i class="icon-user-follow text-success"></i> New user registered
        </a>
        <a href="#" class="dropdown-item">
          <i class="icon-user-unfollow text-danger"></i> User deleted
        </a>
        <a href="#" class="dropdown-item">
          <i class="icon-chart text-info"></i> Sales report is ready
        </a>
        <a href="#" class="dropdown-item">
          <i class="icon-basket-loaded text-primary"></i> New client
        </a>
        <a href="#" class="dropdown-item">
          <i class="icon-speedometer text-warning"></i> Server overloaded
        </a>
        <div class="dropdown-header text-center"><strong>Server</strong></div>
        <a href="#" class="dropdown-item">
          <div class="text-uppercase mb-1"><small><b>CPU Usage</b></small></div>
          <span class="progress progress-xs">
            <div class="progress-bar bg-info" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
          </span>
          <small class="text-muted">348 Processes. 1/4 Cores.</small>
        </a>
        <a href="#" class="dropdown-item">
          <div class="text-uppercase mb-1"><small><b>Memory Usage</b></small></div>
          <span class="progress progress-xs">
            <div class="progress-bar bg-warning" role="progressbar" style="width: 70%" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
          </span>
          <small class="text-muted">11444GB/16384MB</small>
        </a>
        <a href="#" class="dropdown-item">
          <div class="text-uppercase mb-1"><small><b>SSD 1 Usage</b></small></div>
          <span class="progress progress-xs">
            <div class="progress-bar bg-danger" role="progressbar" style="width: 95%" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100"></div>
          </span>
          <small class="text-muted">243GB/256GB</small>
        </a>
      </div>
    </li>
    <li class="nav-item dropdown d-md-down-none" dropdown placement="bottom right">
      <a class="nav-link nav-pill" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle (click)="false">
        <i class="icon-list"></i><span class="badge badge-pill badge-warning">15</span>
      </a>
      <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg" *dropdownMenu aria-labelledby="simple-dropdown">
        <div class="dropdown-header text-center"><strong>You have 5 pending tasks</strong></div>
        <a href="#" class="dropdown-item">
          <div class="small mb-1">Upgrade NPM &amp; Bower <span class="float-right"><strong>0%</strong></span></div>
          <span class="progress progress-xs">
            <div class="progress-bar bg-info" role="progressbar" style="width: 0%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
          </span>
        </a>
        <a href="#" class="dropdown-item">
          <div class="small mb-1">ReactJS Version <span class="float-right"><strong>25%</strong></span></div>
          <span class="progress progress-xs">
            <div class="progress-bar bg-danger" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
          </span>
        </a>
        <a href="#" class="dropdown-item">
          <div class="small mb-1">VueJS Version <span class="float-right"><strong>50%</strong></span></div>
          <span class="progress progress-xs">
            <div class="progress-bar bg-warning" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
          </span>
        </a>
        <a href="#" class="dropdown-item">
          <div class="small mb-1">Add new layouts <span class="float-right"><strong>75%</strong></span></div>
          <span class="progress progress-xs">
            <div class="progress-bar bg-info" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
          </span>
        </a>
        <a href="#" class="dropdown-item">
          <div class="small mb-1">Angular 2 Cli Version <span class="float-right"><strong>100%</strong></span></div>
          <span class="progress progress-xs">
            <div class="progress-bar bg-success" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
          </span>
        </a>
        <a href="#" class="dropdown-item text-center">
          <strong>View all tasks</strong>
        </a>
      </div>
    </li>
    <li class="nav-item dropdown d-md-down-none" dropdown placement="bottom right">
      <a class="nav-link nav-pill" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle (click)="false">
        <i class="icon-envelope-letter"></i><span class="badge badge-pill badge-info">7</span>
      </a>
      <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg" *dropdownMenu aria-labelledby="simple-dropdown">
        <div class="dropdown-header text-center"><strong>You have 4 messages</strong></div>
        <a href="#" class="dropdown-item">
          <div class="message">
            <div class="py-3 mr-3 float-left">
              <div class="avatar">
                <img src="assets/img/avatars/7.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
                <span class="avatar-status badge-success"></span>
              </div>
            </div>
            <div>
              <small class="text-muted">John Doe</small>
              <small class="text-muted float-right mt-1">Just now</small>
            </div>
            <div class="text-truncate font-weight-bold"><span class="fa fa-exclamation text-danger"></span> Important message</div>
            <div class="small text-muted text-truncate">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...</div>
          </div>
        </a>
        <a href="#" class="dropdown-item">
          <div class="message">
            <div class="py-3 mr-3 float-left">
              <div class="avatar">
                <img src="assets/img/avatars/6.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
                <span class="avatar-status badge-warning"></span>
              </div>
            </div>
            <div>
              <small class="text-muted">John Doe</small>
              <small class="text-muted float-right mt-1">5 minutes ago</small>
            </div>
            <div class="text-truncate font-weight-bold">Lorem ipsum dolor sit amet</div>
            <div class="small text-muted text-truncate">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...</div>
          </div>
        </a>
        <a href="#" class="dropdown-item">
          <div class="message">
            <div class="py-3 mr-3 float-left">
              <div class="avatar">
                <img src="assets/img/avatars/5.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
                <span class="avatar-status badge-danger"></span>
              </div>
            </div>
            <div>
              <small class="text-muted">John Doe</small>
              <small class="text-muted float-right mt-1">1:52 PM</small>
            </div>
            <div class="text-truncate font-weight-bold">Lorem ipsum dolor sit amet</div>
            <div class="small text-muted text-truncate">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...</div>
          </div>
        </a>
        <a href="#" class="dropdown-item">
          <div class="message">
            <div class="py-3 mr-3 float-left">
              <div class="avatar">
                <img src="assets/img/avatars/4.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
                <span class="avatar-status badge-info"></span>
              </div>
            </div>
            <div>
              <small class="text-muted">John Doe</small>
              <small class="text-muted float-right mt-1">4:03 PM</small>
            </div>
            <div class="text-truncate font-weight-bold">Lorem ipsum dolor sit amet</div>
            <div class="small text-muted text-truncate">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...</div>
          </div>
        </a>
        <a href="#" class="dropdown-item text-center">
          <strong>View all messages</strong>
        </a>
      </div>
    </li>
    <li class="nav-item d-md-down-none">
      <a class="nav-link nav-pill" href="#"><i class="icon-location-pin"></i></a>
    </li>
    <li class="nav-item dropdown" dropdown placement="bottom right">
      <a class="nav-link nav-pill avatar" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle (click)="false">
        <img src="assets/img/avatars/6.jpg" class="img-avatar" alt="admin@bootstrapmaster.com"/>
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <div class="dropdown-header text-center"><strong>Account</strong></div>
        <a class="dropdown-item" href="#"><i class="fa fa-bell-o"></i> Updates<span class="badge badge-info">42</span></a>
        <a class="dropdown-item" href="#"><i class="fa fa-envelope-o"></i> Messages<span class="badge badge-success">42</span></a>
        <a class="dropdown-item" href="#"><i class="fa fa-tasks"></i> Tasks<span class="badge badge-danger">42</span></a>
        <a class="dropdown-item" href="#"><i class="fa fa-comments"></i> Comment<span class="badge badge-warning">42</span></a>
        <div class="dropdown-header text-center"><strong>Settings</strong></div>
        <a class="dropdown-item" href="#"><i class="fa fa-user"></i> Profile</a>
        <a class="dropdown-item" href="#"><i class="fa fa-wrench"></i> Setting</a>
        <a class="dropdown-item" href="#"><i class="fa fa-usd"></i> Payments<span class="badge badge-dark">42</span></a>
        <a class="dropdown-item" href="#"><i class="fa fa-file"></i> Projects<span class="badge badge-primary">42</span></a>
        <div class="divider"></div>
        <a class="dropdown-item" href="#"><i class="fa fa-shield"></i> Lock account</a>
        <a class="dropdown-item" href="#"><i class="fa fa-lock"></i> Logout</a>
      </div>
    </li>
    -->
  </ul>

</app-header>
<div class="app-body">
  <app-sidebar [fixed]="true" [display]="'lg'">
    <app-sidebar-nav [navItems]="authService.navigation" [perfectScrollbar] [disabled]="sidebarMinimized"></app-sidebar-nav>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <!-- Main content -->
  <main class="main">
    <!-- Breadcrumb -->
    <ol class="breadcrumb">
      <app-breadcrumb></app-breadcrumb>
      <!-- Breadcrumb Menu-->
      <!--
      <li class="breadcrumb-menu d-md-down-none">
        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
          <a class="btn" href="#"><i class="icon-speech"></i></a>
          <a class="btn" [routerLink]="['/dashboard']"><i class="icon-graph"></i> &nbsp;Dashboard</a>
          <a class="btn" href="#"><i class="icon-settings"></i> &nbsp;Settings</a>
        </div>
      </li>
      -->
    </ol>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div><!-- /.container-fluid -->
  </main>
  <app-aside [fixed]="true" [display]="false">
    <tabset>
      <!-- <tab>
      </tab> -->
    </tabset>
  </app-aside>
</div>

<app-footer>
  <span class="ml-auto">  <a href="https://lacrossetechnology.com">La Crosse Technology</a> &copy; 2019 Lacrosse Technology, LTD. (v0.1.1)</span>
</app-footer>
