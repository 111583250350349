
import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from './lacrosse.entity';
import { FirebaseAuthService }            from '../noizu/services/firebase-auth.service';
import {ElixirEntity} from './elixir.entity';
import {NoizuStruct} from '../noizu/structs';
//import { DeviceDefinitionRepo }           from '../../repos/device/definition.repo';

export class MaintenanceEntity extends NoizuStruct {
  public maintenance_mode = false;
  public reason = "";
  public from: Date = null;
  public to: Date = null;
  public user: string = null;

  constructor(json = null) {
    super();
    this.maintenance_mode = json ? true : false;
    if (json) {
      this.to = json.to && new Date(json.to);
      this.from = json.from && new Date(json.from);
      this.reason = json.reason;
      this.user = json.context.caller;
    }
  } // end constructor

} // end Package
