import {ValueAndUnit} from '../../../structs/value-and-unit';
import {AlertFacadeEntity} from '../facade.entity';
import {WidgetEnum_Alert_Facade} from '../../../enums/widget/alert';

export class AlertLightningFacadeEntity extends AlertFacadeEntity {
  public lightningWithin: ValueAndUnit;

  public constructor(alert, json) {
    super(alert, json);

    this.lightningWithin = null;
    if (json["lightningWithin"]) {
      this.lightningWithin = new ValueAndUnit(json["lightningWithin"]);
    }
  }

  //-----------------------------------
  //
  //-----------------------------------
  public get template(): string | null {
    return 'lightning';
  }

  alert_facade_widget() {
    return WidgetEnum_Alert_Facade.ALERT_FACADE_WIDGET__LIGHTNING;
  }
}
