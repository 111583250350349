
<div *ngIf="!entity">
  <widget-spinner></widget-spinner>
</div>
<div *ngIf="entity">
  <div [ngSwitch]="entity.firmware_logical_widget_type()">
    <firmware-definition-logical-widget *ngSwitchCase="WidgetType.FIRMWARE_LOGICAL_WIDGET__DEFINITION" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></firmware-definition-logical-widget>
    <firmware-target-logical-widget *ngSwitchCase="WidgetType.FIRMWARE_LOGICAL_WIDGET__TARGET" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></firmware-target-logical-widget>
    <firmware-target-set-logical-widget *ngSwitchCase="WidgetType.FIRMWARE_LOGICAL_WIDGET__TARGET_SET" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></firmware-target-set-logical-widget>

    <!-- Default -->
    <firmware-generic-logical-widget *ngSwitchDefault (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></firmware-generic-logical-widget>
  </div>
</div>
