import {
  LogicalWidgetEnum,
  WidgetEnum
} from "../enums/widget";
import {NoizuStruct} from "../noizu/structs/noizu-struct";
import {environment} from 'environments/environment';
import {DomainObject} from '../noizu/domain-object';
import {HttpClient} from '@angular/common/http';
import {FirebaseAuthService} from '../noizu/services/firebase-auth.service';

export class LogicalWidget extends NoizuStruct{
  public _endpoint = environment.lax_gateway_url + '/_ah/api/lacrosseAdmin';
  public _ingv_endpoint = environment.ingv2_url + '/api';
  public _ingv_version = 'v1.1';
  public _version = 'v1.1';
  public client;
  public auth;
  public subject: any = null;
  public state: any = {};
  public meta: any = {};

  widget_type() {
    return WidgetEnum.EMBED_WIDGET__LOGICAL;
  }

  logical_widget_type(): LogicalWidgetEnum | null {
    return LogicalWidgetEnum.LOGICAL_WIDGET__GENERIC;
  }


  apiBase() {
    return `${this._endpoint}/${this._version}`;
  }

  ingvEndpoint() {
    return `${this._ingv_endpoint}/${this._ingv_version}`;
  }

  _get(url, init, options = {})  {
    return new Promise(
      (resolve, reject) => {
        this.auth.getTokenPromise().then(
          (token) => {
            const requestOptions = this.auth.request_options(token, options);
            this.client.get(url, requestOptions)
              .toPromise()
              .then((response: any) => {
                init(response, resolve);
              })
              .catch((error) => {
                reject({message: 'request error', details: error});
              });
          }, (error) => {
            reject({message: 'token error', details: error});
          }
        );
      }
    );
  }

  constructor() {
    super();
  } // end constructor
} // end Package
