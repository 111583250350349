
    <div class="card" *ngIf="entity">
      <div class="card-header">
        <div class="row">
          <div class="col"><b class="text-left">Notification Settings</b></div>
          <div class="col-2 text-right p-0 text-middle">
            <toggle-widget [options]="expand_settings"></toggle-widget>
          </div>
        </div>
      </div>
      <div class="card-body"  [ngClass]="{'d-none': !expand_settings.toggle}" >
        <div class="row">
          <div class="col-3">
            SMS Alerts
          </div>
          <div class="col">
            <label class="switch switch-3d switch-primary">
              <input checked="" class="switch-input switch-success" (change)="toggleSms($event)" [(ngModel)]="entity.smsEnabled" type="checkbox">
              <span class="switch-slider"></span>
            </label>
          </div>
          <div class="col">
            <input type="text" (change)="notificationChange()"  [(ngModel)]="entity.smsUserChannel" placeholder="Channel: Leave Blank for Default">
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            Email Alerts
          </div>
          <div class="col">
            <label class="switch switch-3d switch-success">
              <input checked="" class="switch-input switch-primary" (change)="toggleEmail($event)" [(ngModel)]="entity.emailEnabled" type="checkbox">
              <span class="switch-slider"></span>
            </label>
          </div>
          <div class="col">
            <input type="text"  (change)="notificationChange()"[(ngModel)]="entity.emailUserChannel" placeholder="Channel: Leave Blank for Default">
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            Push Notifications
          </div>
          <div class="col">
            <label class="switch switch-3d switch-success">
              <input checked="" class="switch-input switch-primary" (change)="togglePush($event)" [(ngModel)]="entity.pushEnabled" type="checkbox">
              <span class="switch-slider"></span>
            </label>
          </div>
          <div class="col">
            <input type="text" (change)="notificationChange()" [(ngModel)]="entity.pushUserChannel" placeholder="Channel: Leave Blank for Default">
          </div>
        </div>


        <div class="row">
          <div class="col-3">
            Webhook Notifications
          </div>
          <div class="col">
            <label class="switch switch-3d switch-success">
              <input checked="" class="switch-input switch-primary" (change)="toggleWebHook($event)" [(ngModel)]="entity.webhookEnabled" type="checkbox">
              <span class="switch-slider"></span>
            </label>
          </div>
          <div class="col">
            <input type="text" (change)="notificationChange()" [(ngModel)]="entity.webhookUserChannel" placeholder="Channel: Leave Blank for Default">
          </div>
        </div>


         <div class="row m-2">
           <div class="col"><hr/></div>
         </div>

        <div class="row">
          <div class="col-3">
            Freq: Hourly
          </div>
          <div class="col">
            <label class="switch switch-3d switch-warning">
              <input checked="" class="switch-input switch-primary" (change)="toggleFrequency('hourly', $event)" [(ngModel)]="entity.onceHourlyEnabled" type="checkbox">
              <span class="switch-slider"></span>
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            Freq: Daily
          </div>
          <div class="col">
            <label class="switch switch-3d switch-warning">
              <input checked="" class="switch-input switch-primary" (change)="toggleFrequency('daily', $event)" [(ngModel)]="entity.onceDailyEnabled" type="checkbox">
              <span class="switch-slider"></span>
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            Freq: Continuous
          </div>
          <div class="col">
            <label class="switch switch-3d switch-warning">
              <input checked="" class="switch-input switch-primary" (change)="toggleFrequency('continuous', $event)" [(ngModel)]="entity.continuousEnabled" type="checkbox">
              <span class="switch-slider"></span>
            </label>
          </div>
        </div>

<!--
Pending:
    public continuousTimeSpan: any = null;

    public timeFrameEnabled: boolean = false;
    public timeFrameStart: any = null;
    public timeFrameEnd: any = null;
-->

      </div>
    </div>
    