
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { DomainObjectRepo } from '../../noizu/domain-object-repo';
import { FirebaseAuthService }        from '../../noizu/services/firebase-auth.service';
import { AppengineRepo }      from '../appengine.repo';

@Injectable()
export class DeviceAlarmRepo extends AppengineRepo {

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  getAlarm(serial){
    return this._get(this.ingvEndpoint()+`/alarm/admin/${serial}/AlarmSync`,(response, resolve) => {console.log(response);resolve(response);}, {"authorizationHeader":"false"});

  }
/*
  setAlarm(serial,alarm) {
    let v;
    return new Promise(
      (resolve, reject) => {
        this._get(this.ingvEndpoint()+`/alarm/${v}/AlarmSync`,(response, resolve) => {resolve(true);}, {});
      })
  }*/

}
