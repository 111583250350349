<page-header [section]="'gateway-search'">
  <page-title>Gateways</page-title>
  <page-blurb>View and manage device information. Search for a specific gateway using a valid 6 digit device serial.</page-blurb>
</page-header>

<div class="row">
  <div class="col-12">
    <div *ngIf="notification.showLoading" class="overlayParent spinner-holder">
      <div  class="overlay">
        <widget-spinner></widget-spinner>
      </div>
    </div>
    <div *ngIf="notification.message.length > 0" class="row">
      <div class="alert col-12" [ngClass]="{'alert-info': !notification.iserror, 'alert-danger': notification.iserror}" (click)="hideMessage()">
        {{notification.message}}. Click to close
      </div>
    </div>

    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <div class="card">
          <div class="card-header"> Gateway Search </div>
          <div class="card-block">
            <div class="row">
              <div class="col-12">
                <form class="form-horizontal">
                  <div class="form-group row">
                    <div class="col-md-10">
                      <div class="input-group">
                        <span class="input-group-btn">
                          <button (click)="searchGateway($event)" type="button" class="btn btn-primary"><i class="fa fa-search"></i> Search</button>
                        </span>
                        <input type="text" (keyup.enter)="searchGateway($event)" name="searchTerm" class="form-control" placeholder="Full Serial Number" [(ngModel)]="searchTerm">
                      </div>
                    </div>
                  </div>
                </form>
                <div *ngIf="state?.error" role="alert" class="alert alert-danger">{{state.errorMsg}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1"></div>
      <gateway-show *ngIf="gatewayEntity" [entry]="gatewayEntity" class="col-10"></gateway-show>
    </div><!-- end search results row -->
  </div>
</div>
