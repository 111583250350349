import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from '../lacrosse.entity';
import { FirebaseAuthService }            from '../../noizu/services/firebase-auth.service';
import { DeviceDefinitionRepo }           from '../../repos/device/definition.repo';
import { DeviceDefinitionCategory }       from '../../structs/device/definition/category';
import { DeviceDefinitionFieldEntry }     from '../../structs/device/definition/field-entry';
import {NoizuStruct} from '../../noizu/structs/noizu-struct';

export class GatewayScriptOverrideEntity extends NoizuStruct {
  public sequencer_info: any = null;
  public enabled: any = null;
  public script: any = null; // String or Entity

  constructor(json) {
    super();
    if (json) {
      if (json['override'] && json['override']['enabled']) {
        this.enabled = json['override']['enabled'];
      } else {
        this.enabled = false;
      }
      if (json['override'] && json['override']['script']) {
        this.script = json['override']['script'];
      } else {
        this.script = null;
      }

      this.sequencer_info = json.sequence;
    }
  } // end constructor

} // end Package
