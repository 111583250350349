import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  BatchLogicalWidget,
  ManufacturerLogicalWidget,
  PSKUploadEntity,
  SeriesLogicalWidget,
  WeatherCategoryLogicalWidget
} from '../../../entities/';
import { FormBuilder, FormGroup } from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {FirebaseAuthService} from '../../../noizu/services/firebase-auth.service';
import {DataManagementWidgetEventStruct} from './data-management-widget-event.struct';
import {FirmwareWidgetEventStruct} from '../firmware';
import {WidgetEventStruct} from '../../widget-event.struct';
import {LogicalWidgetEventStruct} from '../logical';
import {LogicalWidgetEnum} from '../../../enums';
@Component({
  selector: 'data-management-psk-upload-widget',
  template: `
    <form action="" class="data-management-psk-upload-widget form-horizontal" [formGroup]="uploadFile" (ngSubmit)="onSubmit($event)">
      <div class="card">
        <div class="card-header bg-danger">
          <h1>Gateway PSK Upload</h1>
        </div>
        <div class="card-body m-2 p-0"  *ngIf="entity?.initialized">
          <div class="row mb-3">
            <div class="col text-center">
              <div class="btn-group" role="group" aria-label="Upload Type">
                <button class="btn" [ngClass]="{'btn-primary': (entity?.upload_type == 'csv'), 'btn-secondary': (entity?.upload_type != 'csv') }" (click)="setUploadType('csv', $event)" type="button">CSV File</button>
                <button class="btn" [ngClass]="{'btn-primary': (entity?.upload_type == 'csv-snippet'), 'btn-secondary': (entity?.upload_type != 'csv-snippet') }" (click)="setUploadType('csv-snippet', $event)" type="button">CSV Text</button>
                <button class="btn" [ngClass]="{'btn-primary': (entity?.upload_type == 'json-snippet'), 'btn-secondary': (entity?.upload_type != 'json-snippet') }" (click)="setUploadType('json-snippet', $event)" type="button">Json</button>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">

              <div class="alert alert-success">
                <!-- has header -->
                <div class="row" *ngIf="entity?.upload_type == 'csv' || entity?.upload_type == 'csv-snippet'">
                  <div class="col">
                    <label>
                      <input type="checkbox" [checked]="entity.flags.has_header" (change)="entity.flags.has_header = !entity.flags.has_header" />
                      CSV Has Header?
                    </label>
                  </div>
                </div>

                <!-- over write? -->
                <div class="row">
                  <div class="col">
                    <label>
                      <input type="checkbox" [checked]="entity.flags.overwrite" (change)="entity.flags.overwrite = !entity.flags.overwrite" />
                      Overwrite Existing?
                    </label>
                  </div>
                </div>

                <!-- update Objectify
                <div class="row">
                  <div class="col">
                    <label>
                      <input type="checkbox" [checked]="upload_options.flags.objectify" (change)="upload_options.flags.objectify = !upload_options.flags.objectify" />
                      Update Objectify (Appengine DataStore)
                    </label>
                  </div>
                </div>
                -->

                <!-- update Weather Service -->
                <div class="row">
                  <div class="col">
                    <label>
                      <input type="checkbox" [checked]="entity.flags.update_redis" (change)="entity.flags.update_redis = !entity.flags.update_redis" />
                      Update Weather Service
                    </label>
                  </div>
                </div>


                <!-- overwrite redis -->
                <div class="row" *ngIf="entity.flags.update_redis">
                  <div class="col">
                    <label>
                      <input type="checkbox" [checked]="entity.flags.overwrite_redis" (change)="entity.flags.overwrite_redis = !entity.flags.overwrite_redis" />
                      Overwrite Weather Service PSK/Category
                    </label>
                  </div>
                </div>

              </div>

              <!-- Only if update Redis -->
              <ng-container  *ngIf="entity.flags.update_redis">
                <div class="row" *ngIf="entity?.validation?.messages['weather_category']">
                  <div class="col">
                    <div class="alert alert-danger">{{entity?.validation?.messages['weather_category']}}</div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4"><strong>Weather Category: </strong></div>
                  <div class="col-8">
                    <logical-widget (widgetEvent)="eventHandler($event, 'weather_category')" [entity]="weather_category" [options]="options" [layout]="'default'"></logical-widget>
                  </div>
                </div>
              </ng-container>

              <div class="row" *ngIf="entity?.validation?.messages['manufacturer']">
                <div class="col">
                  <div class="alert alert-danger">{{entity?.validation?.messages['manufacturer']}}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-4"><strong>Manufacturer: </strong></div>
                <div class="col-8">
                  <logical-widget (widgetEvent)="eventHandler($event, 'manufacturer')" [entity]="manufacturer" [options]="options" [layout]="'default'"></logical-widget>
                </div>
              </div>

              <div class="row" *ngIf="entity?.validation?.messages['batch']">
                <div class="col">
                  <div class="alert alert-danger">{{entity?.validation?.messages['batch']}}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-4"><strong>Batch: </strong></div>
                <div class="col-8">
                  <logical-widget (widgetEvent)="eventHandler($event, 'batch')" [entity]="batch" [options]="options" [layout]="'default'"></logical-widget>
                </div>
              </div>

              <div class="row" *ngIf="entity?.validation?.messages['series']">
                <div class="col">
                  <div class="alert alert-danger">{{entity?.validation?.messages['series']}}</div>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-4"><strong>Series: </strong></div>
                <div class="col-8">
                  <logical-widget (widgetEvent)="eventHandler($event, 'series')" [entity]="series" [options]="options" [layout]="'default'"></logical-widget>
                </div>
              </div>




              <div class="form-group" *ngIf="entity.upload_type == 'csv'">
                <label class="col-md-3 col-form-label" for="csv">CSV Upload</label>
                <input type="file" (change)="updateFile($event)" name="psk_file" />
              </div>

              <div class="form-group" *ngIf="entity.upload_type == 'csv-snippet'">
                <textarea name="csv_snippet" [(ngModel)]="csv_snippet" cols="64" rows="32" placeholder="Copy and Paste CSV entries."></textarea>
              </div>

              <div class="form-group" *ngIf="entity.upload_type == 'json-snippet'">
                <textarea name="json_snippet" [(ngModel)]="json_snippet" cols="64" rows="32" placeholder="Copy and Paste Json Array"></textarea>
              </div>

            </div>
          </div>
        </div>
        <div class="card-footer text-center">
          <button type="submit"  class="btn btn-lg btn-danger"><i class="fa fa-dot-circle-o"></i> Upload Verification Codes</button>
        </div>
      </div>
    </form>
  `
})
export class DataManagementPSKUploadComponent implements OnInit {
  @Input() entity: PSKUploadEntity = null;
  @Input() options: any = null;
  @Input() layout: string = null;
  @Output() widgetEvent = new EventEmitter<DataManagementWidgetEventStruct>();
  forwardEvent(widgetEvent: DataManagementWidgetEventStruct) {
    this.widgetEvent.emit(widgetEvent);
  }

  // Upload
  public series: SeriesLogicalWidget;
  public manufacturer: ManufacturerLogicalWidget;
  public batch: BatchLogicalWidget;
  public weather_category: WeatherCategoryLogicalWidget;
  public csv_snippet: string = "";
  public json_snippet: string = "";
  uploadFile: FormGroup;

  constructor(private formBuilder: FormBuilder,  private httpClient: HttpClient, private auth: FirebaseAuthService) {

  }

  ngOnInit() {
    this.uploadFile = this.formBuilder.group({
      psk_file: ['']
    });

    this.weather_category = new WeatherCategoryLogicalWidget();
    this.weather_category.subject = [this.entity.weather_category];

    this.series = new SeriesLogicalWidget();
    this.series.subject = this.entity.series;

    this.manufacturer = new ManufacturerLogicalWidget();
    this.manufacturer.subject = this.entity.manufacturer;

    this.batch = new BatchLogicalWidget();
    this.batch.subject = this.entity.batch;

    this.entity.initialized = true;
  }

  setUploadType(type, event) {
    event.preventDefault();
    this.entity.upload_type = type;
  }


  revalidate() {
    if  (!this.entity.validation.valid && this.entity.validation.validated) {
      this.entity.validate()
    }
  }

  eventHandler(event: WidgetEventStruct, source) {
    if (event instanceof LogicalWidgetEventStruct) {
      if (event.event_logical_widget_type == LogicalWidgetEnum.LOGICAL_WIDGET__WEATHER_CATEGORY) {
        if (event.event_type == 'weather_category_selection_made') {
          this.entity.weather_category = event.event_body['weather_category'];
          this.entity.meta.pending = true;
          this.revalidate();
        }
        return;
      }
      if (event.event_logical_widget_type == LogicalWidgetEnum.LOGICAL_WIDGET__MANUFACTURER) {
        if (event.event_type == 'manufacturer_selection_made') {
          this.entity.manufacturer = event.event_body['sref'];
          this.entity.meta.pending = true;
          this.revalidate();
        }
        return;
      }
      if (event.event_logical_widget_type == LogicalWidgetEnum.LOGICAL_WIDGET__SERIES) {
        if (event.event_type == 'series_selection_made') {
          this.entity.series = event.event_body['series'];
          this.entity.meta.pending = true;
          this.revalidate();
        }
        return;
      }
      if (event.event_logical_widget_type == LogicalWidgetEnum.LOGICAL_WIDGET__BATCH) {
        if (event.event_type == 'batch_selection_made') {
          this.entity.batch = event.event_body['sref'];
          this.entity.meta.pending = true;
          this.revalidate();
        }
        return;
      }
    }
    console.log('unhandled event', source, event)
  }



  //--------------------------------------------------
  // get file and store it in form data
  //--------------------------------------------------
  updateFile(e: any) {
    // @ts-ignore
    if (event.target.files.length > 0) {
      // @ts-ignore
      const file = event.target.files[0];
      this.uploadFile.get('psk_file').setValue(file);
    }
  }




  uploadSuccessEvent(res) {
    console.log("Upload Success", res);
    let e = new DataManagementWidgetEventStruct();
    e.event_widget_type = this.entity.widget_type();
    e.event_logical_widget_type = this.entity.logical_widget_type();
    e.event_data_management_widget_type = this.entity.data_management_logical_widget_type();
    e.event_type = "upload_success"
    e.event_body = {response: res}
    this.widgetEvent.emit(e);
  }

  uploadFailureEvent(res) {
    console.log("Upload Error", res);
    let e = new DataManagementWidgetEventStruct();
    e.event_widget_type = this.entity.widget_type();
    e.event_logical_widget_type = this.entity.logical_widget_type();
    e.event_data_management_widget_type = this.entity.data_management_logical_widget_type();
    e.event_type = "upload_failure"
    e.event_body = {response: res}
    this.widgetEvent.emit(e);
  }


  onSubmit(e) {
    e.preventDefault();
    let fd = new FormData();
    fd.append('file_content', this.uploadFile.get('psk_file').value);

    const series = `series=${this.entity.series}`;
    const manufacturer = `manufacturer=${this.entity.manufacturer}`;
    const batch = `batch=${this.entity.batch}`;
    const weather_category = `weather_category=${this.entity.weather_category}`;
    const has_header = `has_header=${this.entity.flags.has_header ?  'true' : 'false'}`;
    const overwrite = `overwrite=${this.entity.flags.overwrite ?  'true' : 'false'}`;
    const objectify = `objectify=${this.entity.flags.objectify ?  'true' : 'false'}`;
    const update_redis = `update_redis=${this.entity.flags.update_redis ?  'true' : 'false'}`;
    const overwrite_redis = `overwrite_redis=${this.entity.flags.overwrite_redis ?  'true' : 'false'}`;
    const url = `${environment.ingv2_url + '/api'}/v1.1/admin-tools/upload/psks?${series}&${manufacturer}&${batch}&${has_header}&${overwrite}&${objectify}&${update_redis}&${overwrite_redis}&${weather_category}`;

    this.auth.getTokenPromise().then(
      (token) => {
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', `bearer ${token}`)

        if (this.entity.upload_type == 'csv') {
          this.httpClient.post<any>(url, fd, {headers: headers}).subscribe(
            (res) =>
            {
              if (res['outcome'] == 'success') {
                this.uploadSuccessEvent(res)
              } else {
                this.uploadFailureEvent(res)
              }
            },
            (err) => this.uploadFailureEvent(err)
          );
        }
        else if (this.entity.upload_type == 'csv-snippet') {
          this.httpClient.post<any>(url, {csv_content: this.csv_snippet}, {headers: headers}).subscribe(
            (res) =>
            {
              if (res['outcome'] == 'success') {
                this.uploadSuccessEvent(res)
              } else {
                this.uploadFailureEvent(res)
              }
            },
            (err) => this.uploadFailureEvent(err)
          );
        }
        else if (this.entity.upload_type == 'json-snippet') {
          //alert(`Feature Pending| ${json}`);
          this.httpClient.post<any>(url, {json_content: this.json_snippet}, {headers: headers}).subscribe(
            (res) =>
            {
              if (res['outcome'] == 'success') {
                this.uploadSuccessEvent(res)
              } else {
                this.uploadFailureEvent(res)
              }
            },
            (err) => this.uploadFailureEvent(err)
          );
        }
        else {
          console.log('unsupported upload option');
        }
      }
    );
    return true;
  }

}
