import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {DeviceReadingLogicalWidget} from '../../../../entities';
import {LogicalDeviceWidgetEventStruct} from './logical-device-widget-event.struct';

@Component({
  selector: 'device-reading-widget',
  template: `
    <div class="device-reading-widget" *ngIf="!entity">
      <widget-spinner></widget-spinner>
    </div>
    <div class="device-reading-widget" *ngIf="entity">
            [PENDING]
    </div>
  `
})
export class DeviceReadingWidgetComponent implements OnInit{
  @Input() entity: DeviceReadingLogicalWidget | null = null;
  @Input() templateHint: string = null;
  @Input() options: any = null;
  @Input() layout = 'standard';
  @Output() widgetEvent = new EventEmitter<LogicalDeviceWidgetEventStruct>();
  forwardEvent(e, widgetEvent: LogicalDeviceWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }

  constructor(
  ) {

  }

  ngOnInit(): void {

  }
}
