
import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from './lacrosse.entity';
import { FirebaseAuthService }            from '../noizu/services/firebase-auth.service';
import { DeviceDefinitionRepo }           from '../repos/device/definition.repo';
import { DeviceDefinitionCategory }       from '../structs/device/definition/category';
import { DeviceDefinitionFieldEntry }     from '../structs/device/definition/field-entry';

export class EntityArchiveEntity extends LacrosseEntity {
  public _kind = "entity-archive";
  public category: number;
  public description:string;
  public name:string;
  public entityKind:string;
  public archive;
  public strippedRaw;
  public view = {archive:false};


  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    this.archive = json["archive"];
    let temp = json;
    delete temp["archive"];
    this.strippedRaw = temp;
  } // end constructor

} // end Package
