
<div class="device-field-widget" *ngIf="!entity">
  <widget-spinner></widget-spinner>
</div>
<ng-container *ngIf="entity">
  <ng-container [ngSwitch]="layout">
    <!-- ================================================= -->
    <!-- Mini                                              -->
    <!-- ================================================= -->
    <div class="device-field-widget" *ngSwitchCase="'mini'">
        [Device Field.mini]
    </div>

    <!-- ================================================= -->
    <!-- Brief                                             -->
    <!-- ================================================= -->
    <div class="device-field-widget" *ngSwitchCase="'brief'">

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">Field Definition <b><a [routerLink]="['/portal/device/field/definitions/'  + entity.identifier]"  title="{{entity.identifier}}" ><i class="pr-1 text-dark fa fa-link"></i> {{entity.identifier}}</a></b></div>
            <div class="card-body p-2">
              <ng-container *ngTemplateOutlet="standard"></ng-container>
            </div>
          </div>
        </div>
      </div>


    </div>

    <!-- ================================================= -->
    <!-- Standard                                          -->
    <!-- ================================================= -->
    <div class="device-field-widget" *ngSwitchCase="'standard'">

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">Field Definition <b><a [routerLink]="['/portal/device/field/definitions/'  + entity.identifier]"  title="{{entity.identifier}}" ><i class="pr-1 text-dark fa fa-link"></i> {{entity.identifier}}</a></b></div>
            <div class="card-body p-2">
              <ng-container *ngTemplateOutlet="standard"></ng-container>
            </div>
          </div>
        </div>
      </div>

    </div>


    <!-- ================================================= -->
    <!-- shadowbox                                          -->
    <!-- ================================================= -->
    <div class="device-field-widget" *ngSwitchCase="'shadowbox'">
      <ng-container *ngTemplateOutlet="standard"></ng-container>
    </div>



    <!-- ================================================= -->
    <!-- Sidebar                                           -->
    <!-- ================================================= -->
    <div class="device-field-widget" *ngSwitchCase="'sidebar'">

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">Field Definition <b><a [routerLink]="['/portal/device/field/definitions/'  + entity.identifier]"  title="{{entity.identifier}}" ><i class="pr-1 text-dark fa fa-link"></i> {{entity.identifier}}</a></b></div>
            <div class="card-body p-2">
              <ng-container *ngTemplateOutlet="standard"></ng-container>
            </div>
          </div>
        </div>
      </div>

    </div>


    <!-- ================================================= -->
    <!-- Table                                             -->
    <!-- ================================================= -->

    <ng-container *ngSwitchCase="'nested-header'">
      [Device Field: Nested Headers]
    </ng-container>


    <ng-container *ngSwitchCase="'table'">
      <div class="device-entity-widget row" *ngIf="options['index'] == 0 && options['header']">
        <ng-container *ngFor="let column of options.columns">
          <ng-container [ngSwitch]="column">
            <div class="col-2  div-table-th" *ngSwitchCase="'identifier'">Id</div>
            <div class="col-2  div-table-th" *ngSwitchCase="'name'">Name</div>
            <div class="col-2  div-table-th" *ngSwitchCase="'handle'">Handle</div>
            <div class="col-1  div-table-th" *ngSwitchCase="'unit'">Unit</div>
            <div class="col-1  div-table-th" *ngSwitchCase="'high'">High</div>
            <div class="col-1  div-table-th" *ngSwitchCase="'low'">Low</div>
            <div class="col-1  div-table-th" *ngSwitchCase="'width'">Width</div>
            <div class="col-1  div-table-th" *ngSwitchCase="'derived'">Derived</div>
            <div class="col-1  div-table-th" *ngSwitchCase="'variant'">Variant</div>
            <!-- <div class="col  div-table-th" *ngSwitchCase="'actions'">Actions</div> -->
          </ng-container>
        </ng-container >
      </div>

      <div class="row">
        <ng-container *ngFor="let column of options.columns">
          <ng-container [ngSwitch]="column">
            <div class="col-2 text-nowrap" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'identifier'">
              <a [routerLink]="['/portal/device/field/definitions/'  + entity.identifier]"  title="{{entity.identifier}}" ><i class="pr-1 text-dark fa fa-link"></i></a>&nbsp;<a title="{{entity.identifier}}" href="'/portal/device/field/definitions/'  + entity.identifier" (click)="select($event)" >{{entity.identifier}}</a>
            </div>
            <div class="col-2" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'name'">{{entity.name}}</div>
            <div class="col-2" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'handle'">{{entity.handle}}</div>
            <div class="col-1" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'unit'">{{entity.unit}}</div>
            <div class="col-1" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'high'">{{entity.rangeHigh}}</div>
            <div class="col-1" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'low'">{{entity.rangeLow}}</div>
            <div class="col-1" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'width'">{{entity.width}}</div>
            <div class="col-1" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'derived'">{{entity.is_derived_type}}</div>
            <div class="col-1" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'variant'">{{entity.is_variant_type}}</div>

            <!-- <div class="col" [ngClass]="{'div-table-tr-alt': (options['index'] % 2), 'div-table-tr': !(options['index'] % 2) }"  *ngSwitchCase="'actions'">
              [actions]
            </div> -->
          </ng-container>
        </ng-container>

      </div>


    </ng-container>


    <ng-container *ngSwitchCase="'nested-table'">
      [Device Field: Nested Table]
    </ng-container>


    <div class="device-entity-widget" *ngSwitchDefault>
      [Device Default.default {{layout |json}}]
    </div>
  </ng-container>
</ng-container>



<ng-template #standard>

          <!-- Name -->
          <div class="row mb-1">
            <div class="col-3"><b>Name:</b></div>
            <div class="col-9">{{entity.name}}</div>
          </div>

          <!-- Handle -->
          <div class="row mb-1">
            <div class="col-3"><b>Handle:</b></div>
            <div class="col-9">{{entity.handle}}</div>
          </div>

          <!-- Type -->
          <div class="row mb-1">
            <div class="col-3"><b>Type:</b></div>
            <div class="col-9">{{entity.type}}</div>
          </div>

          <!-- Unit -->
          <div class="row mb-1">
            <div class="col-3"><b>Unit:</b></div>
            <div class="col-9">{{entity.unit}}</div>
          </div>

          <!-- Device Type -->
          <div class="row mb-1">
            <div class="col-3"><b>Derived Type:</b></div>
            <div class="col-9">{{entity.is_derived_type}}</div>
          </div>

          <!-- Device Type -->
          <div class="row mb-1">
            <div class="col-3"><b>Variant Type:</b></div>
            <div class="col-9">{{entity.is_variant_type}}</div>
          </div>


          <!-- Precision -->
          <div class="row mb-1">
            <div class="col-3"><b>Precision:</b></div>
            <div class="col-9">{{entity.precision}}</div>
          </div>

          <!-- Accuracy -->
          <div class="row mb-1">
            <div class="col-3"><b>Accuracy:</b></div>
            <div class="col-9">{{entity.accuracy}}</div>
          </div>

          <!-- Range High -->
          <div class="row mb-1">
            <div class="col-3"><b>Range:</b></div>
            <div class="col-9">{{entity.rangeLow}} - {{entity.rangeHigh}}</div>
          </div>

          <div class="card">
            <div class="card-header">Formulas <span *ngIf="options" class="float-right"><i (click)="toggleExpand()" class="fa fa-sm" [ngClass]="{'fa-chevron-down': !expand, 'fa-chevron-up': expand}"> </i></span></div>
            <div class="card-body" *ngIf="expand">
              <!-- Stage 1 -->
              <div class="row mb-1">
                <div class="col-3"><b>Stage 1:</b></div>
                <div class="col-9"><pre *ngIf="entity.stageOneFormula">{{entity.stageOneFormula.toJson() | json}}</pre></div>
              </div>

              <!-- Stage 2 -->
              <div class="row mb-1">
                <div class="col-3"><b>Stage 2:</b></div>
                <div class="col-9"><pre *ngIf="entity.stageTwoFormula">{{entity.stageTwoFormula.toJson() | json}}</pre></div>
              </div>

              <!-- Stage 3 -->
              <div class="row mb-1">
                <div class="col-3"><b>Stage 3:</b></div>
                <div class="col-9"><pre *ngIf="entity.stageThreeFormula">{{entity.stageThreeFormula.toJson() | json}}</pre></div>
              </div>
            </div>
          </div>
</ng-template>


