<div *ngIf="!group" class="overlayParent spinner-holder">
  <div  class="overlay">
    <widget-spinner></widget-spinner>
  </div>
</div>
<div *ngIf="group">
  <div class="col-12">
    <div class="row">
      <div class="col-1"></div>
      <div class="col-2"><button (click)="back($event)" class="btn btn-primary">Back to Group Search</button></div>
      <div class="col-6"></div>
      <div class="col-2" *ngIf="backUserID"><button (click)="backUser($event)" class="btn btn-primary float-right">Back to previous User Page</button></div>
    </div>
  </div>
  <div class="row">
    <div class="col-1"></div>
    <div class="col-10">
      <group-entry [group]="group" (reloadPerms)="reloadPermissions($event)"></group-entry>
    </div>
  </div>
  <div class="row" *ngIf="permissionsInit">
    <div class="col-1"></div>
    <div class="col-10">
      <permissions-entry [initVars]="permissionsInit" [groupId]="group.identifier"></permissions-entry>
    </div>
  </div>
</div>
