
import { Component, Inject, Input, NgZone } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Location }               from '@angular/common';
import { NgModel, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { GroupEntity } from '../../entities/group.entity';
import { GroupRepo } from '../../repos/group.repo';

@Component({
  templateUrl: './list.component.html',
})
export class GroupListComponent {
  public searchResults:any;
  public searchTerm = {id: null};
  public pagination = {next: null, page: 0, cursors: {0: ""}, cursorArray: [""]};
  public state = {loading: false, hasResults: false, error: false, errorMsg: ""}
  public system = true;
  public newG = null;
  //public currentModal:any;  public modalRef: BsModalRef;
  constructor(public groupRepo: GroupRepo, public zone: NgZone, public router: Router) {}
/*
  listGroups(systemOnly, event) {
    event.preventDefault();
    this.groupRepo.getGroupListPromise(systemOnly).then((u: any) => { /* this.entries = u; * / });
  }
*/

  toggleSystem() {
    this.system = !this.system;
  }
  viewGroup(group, event) {
    event.preventDefault();
    this.router.navigateByUrl(`/portal/groups/show/${group.identifier}`);
  }

  clearSearchResults() {
    this.state.error = false;
    this.state.loading = true;
    this.searchResults = null;
  }

  newGroup(e) {
    e.preventDefault();
    this.newG = this.groupRepo.entity({"id":"new"});
  }

  listGroups(e) {
    e.preventDefault();
    this.clearSearchResults();
    this.pagination = {next: null, page: 0, cursors: {0: ""}, cursorArray: [""]};
    let options = {"system":this.system};
    this.groupRepo.getListPromise(options).then(
      (results) => {
        this.searchResults = results;
        this.pagination.next = this.searchResults.cursor;
        this.searchResults.options = {};
        this.state.loading = false;
      }
    );
  }

  searchGroups(searchTerm, searchType, e) {
    e.preventDefault();
    this.clearSearchResults();
    let options = {};
    options[searchType] = searchTerm;
    this.groupRepo.getListPromise(options).then(
      (results) => {
        this.searchResults = results;
        this.pagination.next = this.searchResults.cursor;
        this.searchResults.options = options;
        this.state.loading = false;
      }
    );
  }

  searchAt(searchResults, index, e) {
    e.preventDefault();
    let options = searchResults.options;
    options.cursor = this.pagination.cursors[index];
    this.state.loading = true;
    this.groupRepo.getListPromise(options).then(
      (results) => {
        this.pagination.page = index;
        this.searchResults = results;
        this.pagination.cursorArray[this.pagination.page] = options.cursor;
        this.pagination.cursors[this.pagination.page] = options.cursor;
        this.searchResults.options = options;
        this.state.loading = false;
      }
    );
  }

  searchNext(searchResults, e) {
    if (this.pagination.cursors[this.pagination.page + 1]) {
      this.searchAt(searchResults, this.pagination.page + 1, event);
    } else {
      e.preventDefault();
      let options = searchResults.options;
      options.cursor = this.pagination.next;
      this.state.loading = true;
      this.groupRepo.getListPromise(options).then(
        (results) => {
          this.pagination.page++;
          this.searchResults = results;
          this.pagination.next = this.searchResults.cursor;
          this.pagination.cursorArray[this.pagination.page] = options.cursor;
          this.pagination.cursors[this.pagination.page] = options.cursor;
          this.searchResults.options = options;
          this.state.loading = false;
        }
      );
    }
  }

}
