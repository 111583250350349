
    <page-header [section]="'batches'">
      <page-title>Batches</page-title>
      <page-blurb>View and manage the current list of batches. Add new batch entries to the list.</page-blurb>
    </page-header>

    <spinner *ngIf="!entries"></spinner>
    <div class="row">
      <div class="col">
        <widget [entity]="entries" [options]="{edit: edit, columns: 2, sideBarWidth: 5}" [layout]="'table'"></widget>
      </div>
    </div>
  