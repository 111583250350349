
    <div *ngIf="!entity">
      <widget-spinner></widget-spinner>
    </div>
    <ng-container *ngIf="entity">
      <ng-container [ngSwitch]="entity.firmware_widget_type()">
        <firmware-approval-widget *ngSwitchCase="WidgetType.FIRMWARE_WIDGET__APPROVAL" (widgetEvent)="forwardEvent(widgetEvent)" [entity]="entity" [layout]="layout" [options]="options"></firmware-approval-widget>
        <firmware-definition-widget *ngSwitchCase="WidgetType.FIRMWARE_WIDGET__DEFINITION" (widgetEvent)="forwardEvent(widgetEvent)" [entity]="entity" [layout]="layout" [options]="options"></firmware-definition-widget>
        <firmware-link-widget *ngSwitchCase="WidgetType.FIRMWARE_WIDGET__LINK" (widgetEvent)="forwardEvent(widgetEvent)" [entity]="entity" [layout]="layout" [options]="options"></firmware-link-widget>
        <firmware-release-widget *ngSwitchCase="WidgetType.FIRMWARE_WIDGET__RELEASE" (widgetEvent)="forwardEvent(widgetEvent)" [entity]="entity" [layout]="layout" [options]="options"></firmware-release-widget>
        <firmware-target-widget *ngSwitchCase="WidgetType.FIRMWARE_WIDGET__TARGET" (widgetEvent)="forwardEvent(widgetEvent)" [entity]="entity" [layout]="layout" [options]="options"></firmware-target-widget>
        <firmware-generic-widget *ngSwitchCaseDefault (widgetEvent)="forwardEvent(widgetEvent)" [entity]="entity" [layout]="layout" [options]="options"></firmware-generic-widget>
      </ng-container>
    </ng-container>

  