//-------------------------------
//
//------------------------------




//-------------------------------
//
//-------------------------------

export enum WidgetEnum {
  EMBED_WIDGET__GENERIC,
  EMBED_WIDGET__GENERIC_SET,
  EMBED_WIDGET__ELIXIR_SET,
  EMBED_WIDGET__APPENGINE_SET,
  EMBED_WIDGET__LEGACY,

  EMBED_WIDGET__ALERT_ENTITY,
  EMBED_WIDGET__ALERT_FACADE_ENTITY,
  EMBED_WIDGET__ALERT_STATUS_ENTITY,
  EMBED_WIDGET__ALERT_TRIGGER_STATE_OBJECT,



  EMBED_WIDGET__ALERT_STATUS_FACADE,
  EMBED_WIDGET__ALERT_CONSTRAINT_STATUS_SET,
  EMBED_WIDGET__ALERT_CONSTRAINT_STATUS,
  EMBED_WIDGET__ALERT_TRIGGER_EVENT_SET,
  EMBED_WIDGET__ALERT_TRIGGER_EVENT,
  EMBED_WIDGET__ALERT_CONSTRAINT_TRIGGER_STATE,

  EMBED_WIDGET__RUNTIME_CONFIGURATION_OBJECT,
  EMBED_WIDGET__CALLBACK_OBJECT,
  EMBED_WIDGET__SUBSCRIBER_OBJECT,
  EMBED_WIDGET__CALLBACK_OBJECT_SET,
  EMBED_WIDGET__SUBSCRIBER_OBJECT_SET,

  EMBED_WIDGET__CMS,
  EMBED_WIDGET__DS,

  EMBED_WIDGET__DEVICE,
  EMBED_WIDGET__DEVICE_ENTITY,
  EMBED_WIDGET__DEVICE_LINK,
  EMBED_WIDGET__DEVICE_TYPE,
  EMBED_WIDGET__DEVICE_FIELD,

  EMBED_WIDGET__FIRMWARE,
  EMBED_WIDGET__GATEWAY,
  EMBED_WIDGET__LOG_ENTITY,
  EMBED_WIDGET__LOG_SET_ENTITY,
  EMBED_WIDGET__MCU,
  EMBED_WIDGET__UPLOAD,
  EMBED_WIDGET__BATCH,
  EMBED_WIDGET__NOTIFICATION_SETTINGS_ENTITY,

  EMBED_WIDGET__DEVICE_RANGE,

  // PlaceHolder Widgets (link to a composite structure not a specific entity or record set.
  EMBED_WIDGET__LOGICAL,


  EMBED_WIDGET__COMMUNICATION_CHANNEL_ENTITY,
  EMBED_WIDGET__COMMUNICATION_CHANNEL_TYPE_ENTITY,

  EMBED_WIDGET__USER_ENTITY,
  EMBED_WIDGET__USER_EXTENDED,
  EMBED_WIDGET__USER_DEVICE_ASSOCIATION_ENTITY,
  EMBED_WIDGET__PERMISSION_ENTITY,
  EMBED_WIDGET__SUBSCRIPTION_DEFINITION_ENTITY,
  EMBED_WIDGET__SUBSCRIPTION_DEFINITION_REPO,

  EMBED_WIDGET__MODAL_WIDGET,

  EMBED_WIDGET__PIN_GROUP,
  EMBED_WIDGET__PIN,

  EMBED_WIDGET__BATCH_SET,
  EMBED_WIDGET__BATCH_ENTITY,

  EMBED_WIDGET__REPORT,

  EMBED_WIDGET__EMAIL,
  EMBED_WIDGET__NOTE_ENTITY,
  EMBED_WIDGET__MANUFACTURER_ENTITY,


}

export enum EmailWidgetEnum {
  EMAIL_WIDGET__GENERIC,
  EMAIL_WIDGET__QUEUE_ENTRY,
  EMAIL_WIDGET__TEMPLATE
}

export enum ReportWidgetEnum {
  REPORT_WIDGET__GENERIC,
  REPORT_WIDGET__HEALTH_REPORT,
  REPORT_WIDGET__WORKER_REPORT,
}

//-------------------------------
//
//------------------------------
export enum NotificationSettingsWidgetEnum{
  NOTIFICATION_SETTINGS_WIDGET__GENERIC
}

//-------------------------------
//
//------------------------------
export enum PermissionWidgetEnum{
  PERMISSION_WIDGET__GENERIC
}

//-------------------------------
//
//------------------------------
export enum SubscriberWidgetEnum{
  SUBSCRIBER_WIDGET__GENERIC
}

//-------------------------------
//
//------------------------------
export enum RuntimeWidgetEnum{
  RUNTIME_WIDGET__GENERIC
}

//-------------------------------
//
//------------------------------
export enum UserWidgetEnum{
  USER_WIDGET__GENERIC
}

//-------------------------------
//
//------------------------------
export enum UserExtendedWidgetEnum {
  USER_EXTENDED_WIDGET__GENERIC,
  USER_EXTENDED_WIDGET__SUBSCRIPTION_DETAILS,
  USER_EXTENDED_WIDGET__EFFECTIVE_SUBSCRIPTION,
  USER_EXTENDED_WIDGET__SUBSCRIPTION,
  USER_EXTENDED_WIDGET__SUBSCRIPTION_PAYMENT,
}


//-------------------------------
//
//------------------------------
export enum PinWidgetEnum{
  PIN_WIDGET__GENERIC
}

//-------------------------------
//
//------------------------------
export enum CallbackWidgetEnum {
  CALLBACK_WIDGET__GENERIC,
  CALLBACK_WIDGET__EVENT,
  CALLBACK_WIDGET__EVENT_SET,
}

//-------------------------------
//
//------------------------------
export enum AlertWidgetEnum {
  ALERT_WIDGET__GENERIC,
  ALERT_WIDGET__ALERT_ENTITY,
  ALERT_WIDGET__ALERT_ENTITY_DELETE,
  ALERT_WIDGET__ALERT_FACADE,
  ALERT_WIDGET__ALERT_FACADE_SELECTOR,
  ALERT_WIDGET__ALERT_TRIGGER_STATE,
  ALERT_WIDGET__ALERT_TRIGGER_EVENT_SET,
  ALERT_WIDGET__ALERT_TRIGGER_EVENT,
  ALERT_WIDGET__ALERT_STATUS_FACADE,
  ALERT_WIDGET__ALERT_CONSTRAINT_TRIGGER_STATE,
  ALERT_WIDGET__ALERT_CONSTRAINT_STATUS,
  ALERT_WIDGET__ALERT_CONSTRAINT_STATUS_SET
}
//-------------------------------
//
//------------------------------
export enum LogicalWidgetEnum {
  LOGICAL_WIDGET__GENERIC,
  LOGICAL_WIDGET__USER,
  LOGICAL_WIDGET__DEVICE,
  LOGICAL_WIDGET__DEVICE_TYPE,
  LOGICAL_WIDGET__EXTENDED_DEVICE,
  LOGICAL_WIDGET__BATCH,
  LOGICAL_WIDGET__MANUFACTURER,
  LOGICAL_WIDGET__PIN_GROUP,
  LOGICAL_WIDGET__SERIES,
  LOGICAL_WIDGET__GATEWAY,
  LOGICAL_WIDGET__CMS,
  LOGICAL_WIDGET__LAZY_LOAD,
  LOGICAL_WIDGET__APPROVAL,
  LOGICAL_WIDGET__FIRMWARE,
  LOGICAL_WIDGET__MCU,
  LOGICAL_WIDGET__MCU_MANAGER,
  LOGICAL_WIDGET__NOTES,
  LOGICAL_WIDGET__FORECAST,
  LOGICAL_WIDGET__CONFIRMATION,
  LOGICAL_WIDGET__DATA_MANAGEMENT,
  LOGICAL_WIDGET__WEATHER_CATEGORY,
  LOGICAL_WIDGET__DATE_TIME,
  LOGICAL_WIDGET__CHANNEL,
  LOGICAL_WIDGET__ALERT,
  LOGICAL_WIDGET__DATE_TIME_RANGE,
}

export enum DataManagementLogicalWidgetEnum {
  DATA_MANAGEMENT_LOGICAL_WIDGET__GENERIC,
  DATA_MANAGEMENT_LOGICAL_WIDGET__PSK,
  DATA_MANAGEMENT_LOGICAL_WIDGET__VERIFICATION_CODE,
}

export enum FirmwareLogicalWidgetEnum {
  FIRMWARE_LOGICAL_WIDGET__GENERIC,
  FIRMWARE_LOGICAL_WIDGET__DEFINITION,
  FIRMWARE_LOGICAL_WIDGET__LINK,
  FIRMWARE_LOGICAL_WIDGET__TARGET,
  FIRMWARE_LOGICAL_WIDGET__TARGET_SET,
}

//-------------------------------
// Modal Widgets
//------------------------------
export enum ModalWidgetEnum {
  MODAL_WIDGET__GENERIC,
  MODAL_WIDGET__CONFIRM,
  MODAL_WIDGET__CREATE,
  MODAL_WIDGET__UPDATE,
  MODAL_WIDGET__SHOW,
  MODAL_WIDGET__MINI,
  MODAL_WIDGET__BARE
}

export enum DeviceWidgetEnum {
  DEVICE_WIDGET__GENERIC,
  DEVICE_WIDGET__ENTITY,
  DEVICE_WIDGET__SIMULATOR_FIELD,
  DEVICE_WIDGET__LOGICAL_WIDGET,
  DEVICE_WIDGET__RANGE,
  DEVICE_WIDGET__SIMULATOR,
  DEVICE_WIDGET__FEED,
  DEVICE_WIDGET__READINGS,
  DEVICE_WIDGET__ENVIRONMENT_TARGET = 5
}

export enum LogWidgetEnum {
  LOG_WIDGET__GENERIC,
  LOG_WIDGET__ALERT,
  LOG_WIDGET__NOTIFICATION,
  LOG_WIDGET__GATEWAY,
  LOG_WIDGET__DEVICE,
  LOG_WIDGET__DATA_STREAM,
  LOG_WIDGET__USER,
}

export enum UserLogWidgetEnum {
  USER_LOG_WIDGET__GENERIC,
}

export enum AlertLogWidgetEnum {
  ALERT_LOG_WIDGET__GENERIC,
  ALERT_LOG_WIDGET__LOAD,
  ALERT_LOG_WIDGET__LOAD_ERROR,
  ALERT_LOG_WIDGET__SET_ENABLED,
  ALERT_LOG_WIDGET__SET_DISABLED,

  ALERT_LOG_WIDGET__PREPARE_CALLBACKS,
  ALERT_LOG_WIDGET__REGISTER_CALLBACK,
  ALERT_LOG_WIDGET__DEREGISTER_CALLBACK,
  ALERT_LOG_WIDGET__REFRESH_CALLBACK,
  ALERT_LOG_WIDGET__TRIGGERED,
  ALERT_LOG_WIDGET__TRIGGERED_ERROR,
  ALERT_LOG_WIDGET__RECOVERED,
  ALERT_LOG_WIDGET__RECOVERED_ERROR,
  ALERT_LOG_WIDGET__REFRESH,
  ALERT_LOG_WIDGET__REFRESH_ERROR,
  ALERT_LOG_WIDGET__UNCHANGED,
  ALERT_LOG_WIDGET__UNLOADED,
  ALERT_LOG_WIDGET__CREATED,
  ALERT_LOG_WIDGET__UPDATED,
  ALERT_LOG_WIDGET__DELETED,
}


export enum NotificationLogWidgetEnum {
  NOTIFICATION_LOG_WIDGET__GENERIC,
  NOTIFICATION_LOG_WIDGET__SEND_TRIGGER,
  NOTIFICATION_LOG_WIDGET__SEND_TRIGGER_ERROR,
  NOTIFICATION_LOG_WIDGET__SEND_TRIGGER_WARN,

  NOTIFICATION_LOG_WIDGET__SEND_RECOVER,
  NOTIFICATION_LOG_WIDGET__SEND_RECOVER_ERROR,
  NOTIFICATION_LOG_WIDGET__SEND_RECOVER_WARN,

  NOTIFICATION_LOG_WIDGET__SEND_REFRESH,
  NOTIFICATION_LOG_WIDGET__SEND_REFRESH_ERROR,
  NOTIFICATION_LOG_WIDGET__SEND_REFRESH_WARN,
}

export enum GatewayLogWidgetEnum {
  GATEWAY_LOG_WIDGET__GENERIC,
  GATEWAY_LOG_WIDGET__LOAD,
  GATEWAY_LOG_WIDGET__LOAD_ERROR,
  GATEWAY_LOG_WIDGET__SHOW_GEO,
  GATEWAY_LOG_WIDGET__UPDATE_GEO,
  GATEWAY_LOG_WIDGET__WAKE,
  GATEWAY_LOG_WIDGET__WAKE_ERROR,
  GATEWAY_LOG_WIDGET__MCU_DETAILS,
  GATEWAY_LOG_WIDGET__NOTIFICATIONS,
  GATEWAY_LOG_WIDGET__STAGE_ONE,
  GATEWAY_LOG_WIDGET__REPORT_BINARY,
  GATEWAY_LOG_WIDGET__EXTRACT_BINARY,
  GATEWAY_LOG_WIDGET__EXTRACT_BINARY_ERROR,
}

export enum DeviceLogWidgetEnum {
  DEVICE_LOG_WIDGET__GENERIC,
  DEVICE_LOG_WIDGET__STAGE_ONE,
  DEVICE_LOG_WIDGET__BROADCAST_ERROR,
  DEVICE_LOG_WIDGET__RECORD_PLOT_ERROR,
  DEVICE_LOG_WIDGET__UPDATE_BINARY,
  DEVICE_LOG_WIDGET__UPDATE_BINARY_ERROR,
  DEVICE_LOG_WIDGET__SHALLOW_PERSIST_ERROR
}

export enum DataStreamLogWidgetEnum {
  DATA_STREAM_LOG_WIDGET__GENERIC,
  DATA_STREAM_LOG_WIDGET__DATA_STREAM
}


export enum FirmwareWidgetEnum {
  FIRMWARE_WIDGET__GENERIC,
  FIRMWARE_WIDGET__DEFINITION,
  FIRMWARE_WIDGET__LINK,
  FIRMWARE_WIDGET__RELEASE,
  FIRMWARE_WIDGET__APPROVAL,
  FIRMWARE_WIDGET__TARGET,
  FIRMWARE_WIDGET__TARGET_SET,
}

export enum LogicalUserWidgetEnum {
  LOGICAL_USER_WIDGET__GENERIC,
  LOGICAL_USER_WIDGET__PERMISSIONS,
  LOGICAL_USER_WIDGET__SHARE,
  LOGICAL_USER_WIDGET__CHANNEL,
}

export enum LogicalApprovalWidgetEnum{
  LOGICAL_APPROVAL_WIDGET__GENERIC
}

export enum LogicalAlertWidgetEnum {
  LOGICAL_ALERT_WIDGET__GENERIC
}

export enum LogicalAlertFacadeWidgetEnum {
  LOGICAL_ALERT_FACADE_WIDGET__GENERIC,
}


export enum LogicalFirmwareManagerWidgetEnum{
  LOGICAL_FIMRWARE_MANAGER_WIDGET__GENERIC
}

export enum LogicalNotesWidgetEnum {
  LOGICAL_NOTES_WIDGET__GENERIC,
}


export enum LogicalGenericWidgetEnum {
  LOGICAL_GENERIC_WIDGET__GENERIC
}

export enum LogicalMcuFirmwareWidgetEnum {
  LOGICAL_MCU_FIRMWARE_WIDGET__GENERIC
}

export enum LogicalMcuManagerWidgetEnum{
  LOGICAL_MCU_MANAGER_WIDGET__GENERIC
}

export enum LogicalDeviceWidgetEnum {
  LOGICAL_DEVICE_WIDGET__GENERIC,
  LOGICAL_DEVICE_WIDGET__GROUP,
  LOGICAL_DEVICE_WIDGET__SIMULATOR,
  LOGICAL_DEVICE_WIDGET__FIELD_SIMULATOR,
  LOGICAL_DEVICE_WIDGET__USERS,
  LOGICAL_DEVICE_WIDGET__CLAIMS,

  LOGICAL_DEVICE_WIDGET__FEED,
  LOGICAL_DEVICE_WIDGET__READINGS,
}

export enum LogicalDeviceFieldWidgetEnum {
  LOGICAL_DEVICE_FIELD_WIDGET__NOT_SUPPORTED,
  LOGICAL_DEVICE_FIELD_WIDGET__TEMPERATURE, // Or Probe
  LOGICAL_DEVICE_FIELD_WIDGET__HUMIDITY,
  LOGICAL_DEVICE_FIELD_WIDGET__RAIN,
  LOGICAL_DEVICE_FIELD_WIDGET__WIND_SPEED,
  LOGICAL_DEVICE_FIELD_WIDGET__WIND_HEADING,
  LOGICAL_DEVICE_FIELD_WIDGET__HEAT_INDEX,
  LOGICAL_DEVICE_FIELD_WIDGET__WIND_CHILL,
  LOGICAL_DEVICE_FIELD_WIDGET__LIGHTNING,
  LOGICAL_DEVICE_FIELD_WIDGET__WETNESS= 10,
  LOGICAL_DEVICE_FIELD_WIDGET__WIND_GUST,
  LOGICAL_DEVICE_FIELD_WIDGET__FEELS_LIKE,
  LOGICAL_DEVICE_FIELD_WIDGET__WIND_LEVEL,
  LOGICAL_DEVICE_FIELD_WIDGET__PRECIPITATION_LEVEL,
  LOGICAL_DEVICE_FIELD_WIDGET__ON_OFF,
  LOGICAL_DEVICE_FIELD_WIDGET__WET_DRY,
  LOGICAL_DEVICE_FIELD_WIDGET__MOTION,
  LOGICAL_DEVICE_FIELD_WIDGET__CONTACT,
  LOGICAL_DEVICE_FIELD_WIDGET__THERMOMETER = 20,
  LOGICAL_DEVICE_FIELD_WIDGET__FLEX,
  LOGICAL_DEVICE_FIELD_WIDGET__BAROMETRIC_PRESSURE = 24
}

export enum LogicalExtendedDeviceWidgetEnum {
  LOGICAL_EXTENDED_DEVICE_WIDGET__GENERIC,
  LOGICAL_EXTENDED_DEVICE_WIDGET__CHART,
  LOGICAL_EXTENDED_DEVICE_WIDGET__READING,
}

export enum LogicalGatewayWidgetEnum {
  LOGICAL_GATEWAY_WIDGET__GENERIC,
  LOGICAL_GATEWAY_WIDGET__DEVICES,
}

export enum LogicalCmsWidgetEnum {
  LOGICAL_CMS_WIDGET__GENERIC,
  LOGICAL_CMS_WIDGET__LEGACY_ARTICLE,
  LOGICAL_CMS_WIDGET__ARTICLE,
  LOGICAL_CMS_WIDGET__VERSION,
  LOGICAL_CMS_WIDGET__REVISION,
  LOGICAL_CMS_WIDGET__HISTORY,
}

export enum LogicalLazyLoadWidgetEnum {
  LOGICAL_LAZY_LOAD_WIDGET__GENERIC,
}

export enum LogicalFirmwareWidgetEnum {
  LOGICAL_FIRMWARE_WIDGET__GENERIC,
  LOGICAL_FIRMWARE_WIDGET__BATCH,
  LOGICAL_FIRMWARE_WIDGET__SCRIPT,
  LOGICAL_FIRMWARE_WIDGET__CYCLE,
  LOGICAL_FIRMWARE_WIDGET__MANAGER,
}

export enum LogicalForecastWidgetEnum {
  LOGICAL_FORECAST_WIDGET__GENERIC,
  LOGICAL_FORECAST_WIDGET__FORECAST,
  LOGICAL_FORECAST_WIDGET__INTEROP,
}




















//-------------------------------
//
//------------------------------
