
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { DomainObjectRepo }       from '../../noizu/domain-object-repo';
import { FirebaseAuthService }    from '../../noizu/services/firebase-auth.service';
import { AppengineRepo }          from '../appengine.repo';
import { DeviceDuplicatesEntity } from '../../entities/device/duplicates.entity';

@Injectable()
export class DeviceDuplicatesRepo extends AppengineRepo {
  public _kind = "duplicateSensors";
  public _singular = "duplicateSensor";

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  entity(record) {
    return new DeviceDuplicatesEntity(this.client, this.auth, record)
  }

  getDuplicatesBySerial(serial, series = "V2") {
    return this._getEntityPromise(`${this.apiBase()}/duplicateSensor/ref.device.${serial}@${series}`, (record, resolve) => { resolve( this.entity(record) );});
  }

  getListPromise(options:any = {}) {
    let url = `${this.apiBase()}/${this._kind}?resultsPerPage=500`;

    // Should be moved into base.
    if ("cursor" in options) {
      url = url + `&cursor=${options.cursor}`
    }
    return this._getListPromise(url, (record) => {return this.entity(record); }, options);
  }
}
