<page-header [section]="'auto-load-device-entries'">
  <page-title>Auto-Load Device Entries</page-title>
  <page-blurb>Manage device entries. Update or delete existing devices. Create new device entries.</page-blurb>
</page-header>

<spinner *ngIf="!entries"></spinner>

<div *ngIf="entries" class="row">

  <div class="p-1" flex="grow" *ngFor="let entry of entries.items">
    <div class="col-12">
      <device-lookup-entry [entry]="entry"></device-lookup-entry>
    </div>
  </div>

  <div class="col-12">
    <button (click)="add($event)" class="btn btn-sm btn-primary" type="submit"><i class="fa fa-plus-circle"></i> New</button>
  </div>

</div>
