
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from './lacrosse.entity';
import { FirebaseAuthService }            from '../noizu/services/firebase-auth.service';
import {WidgetEnum} from '../enums';
import {AttributeFieldType} from './device';

export class BatchEntity extends LacrosseEntity {
  public _sref_module = "batch";
  public notes: String = "";
  public name: String;
  public new: boolean = false;
  public attributes:Object = null;
  public _attributeList: any = null;

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    this._kind = "batches";
    this._singular = "batch";
    this.strip_from_json["_attributeList"] = 1;
    this.strip_from_json["attributeList"] = 1;
    // double call refresh to insure fields with default values set correctly.
    this.refresh(json);
  } // end constructor


  // Sets the fields, used above, and to reset the values when canceling an edit
  refresh(data) {
    super.refresh(data);
    if (typeof this.identifier == 'string') {
      // @ts-ignore
      this.identifier = parseInt(this.identifier, 10);
    }
    this.name = data['name'];
    this.notes = data['notes'] || null;
    this.new = data['new'] || false;
    this.raw = null;

    this.attributes = {};
    if (data["attributes"]) {
      for (const key in data["attributes"]) {
        let value =  data["attributes"][key];
        this.attributes[key] = parseInt(value);
      }
    }

    return this;
  }


  /**
   * @todo attribute handling logic is copy pasta and should be wrapped into it's own object for easier re-use  - kebrings
   * @param key
   */
  public attributeType(key) {
    return AttributeFieldType.NumericField;
  }

  updateAttribute(attribute) {
    this.meta.hasChanges = true;

    if (attribute.originalKey != attribute.key) {
      delete this.attributes[attribute.originalKey];
    }

    this.attributes[attribute.key] = attribute.value;
    this.attributeList = null;
    console.log("Update ", attribute);
  }

  addAttribute(attribute) {
    this.meta.hasChanges = true;
    this.attributes[attribute.key] = attribute.value;
    this.attributeList = null;
    console.log("Add ", attribute);
  }

  dropAttribute(attribute) {
    this.meta.hasChanges = true;
    delete this.attributes[attribute.key]
    this.attributeList = null;
    console.log("drop", attribute);
  }


  set attributeList(v) {
    this._attributeList = v;
  }

  get attributeList() {
    if (this._attributeList) return this._attributeList;
    this._attributeList = [];
    if (this.attributes) {
      for (const key in this.attributes) {
        let value =  this.attributes[key];
        this._attributeList.push({key: key, originalKey: key, value: value, type: this.attributeType(key)});
      }
      this._attributeList = this._attributeList.sort((a,b) => a.key < b.key);
    }
    return this._attributeList;
  }

  validate() {
    let valid = true;
    let validated = true;
    let error = null;
    let messages = {};

    if (!this.identifier && !this.meta['new']) {
      valid = false;
      error = 'Identifier not set.'
      messages['identifier'] = 'Not Set';
    }

    if (!this.name) {
      valid = false;
      error = 'Name Required'
      messages['name'] = 'Name is Required';
    }

    this.validation = {valid: valid,  validated: validated, error: error, messages: messages}
    return this.validation;
  }


  filter(filter: string) {
    if (filter) {
      if (this.name && this.name.includes(filter)) return true;
      if (this.notes && this.notes.includes(filter)) return true;
      return false;
    }  else {
      return true;
    }
  }


  widget_type() {
    return WidgetEnum.EMBED_WIDGET__BATCH_ENTITY;
  }

} // end Package
