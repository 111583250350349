import {Component, Input, Output, EventEmitter} from '@angular/core';
import {EntityLogEntity} from '../../../entities/log/entity-log.entity';
import {WidgetBase} from '../widget-base';
import {LogWidgetEventStruct} from './log-widget-event.struct';
import {LogWidgetEnum} from '../../../enums';

@Component({
  selector: 'log-widget',
  template: `
    <ng-container *ngIf="entity">
      <ng-container [ngSwitch]="entity.log_widget_type()">
        <alert-log-widget *ngSwitchCase="LogWidgetEnum.LOG_WIDGET__ALERT" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></alert-log-widget>
        <notification-log-widget *ngSwitchCase="LogWidgetEnum.LOG_WIDGET__NOTIFICATION" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></notification-log-widget>
        <gateway-log-widget *ngSwitchCase="LogWidgetEnum.LOG_WIDGET__GATEWAY" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></gateway-log-widget>
        <device-log-widget *ngSwitchCase="LogWidgetEnum.LOG_WIDGET__DEVICE" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></device-log-widget>
        <data-stream-log-widget *ngSwitchCase="LogWidgetEnum.LOG_WIDGET__DATA_STREAM" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></data-stream-log-widget>
        <user-log-widget *ngSwitchCase="LogWidgetEnum.LOG_WIDGET__USER" (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></user-log-widget>
        <generic-log-widget *ngSwitchDefault (widgetEvent)="forwardEvent($event)" [entity]="entity" [layout]="layout" [options]="options"></generic-log-widget>
      </ng-container>
    </ng-container>
    `
})
export class LogWidgetComponent extends WidgetBase{
  @Input() entity: EntityLogEntity = null;
  @Input() options: any = {};
  @Input() layout: string = null;
  @Output() widgetEvent = new EventEmitter<LogWidgetEventStruct>();
  forwardEvent(e, widgetEvent: LogWidgetEventStruct) {
    e.preventDefault()
    this.widgetEvent.emit(widgetEvent);
  }
  public LogWidgetEnum = LogWidgetEnum;
  public expand = false;
  public expandRaw = false;

  constructor() {
    super();

  }
}
