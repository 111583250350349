
import { Inject, Injectable }             from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LacrosseEntity }                 from './lacrosse.entity';
import { FirebaseAuthService }            from '../noizu/services/firebase-auth.service';

export class PermissionsEntity extends LacrosseEntity {
  public handle: string;
  public permissionType: string;
  public permissions = {};
  public groupId: string;
  public editMode: boolean = false;
  //public attributes:Object;

  constructor(client: HttpClient, auth: FirebaseAuthService, json) {
    super(client, auth, json);
    if (json) {
      this.refresh(json);
    }
  } // end constructor

  refresh(data) {
    let sr = super.refresh(data);
    this.handle = data["handle"];
    this.permissionType = data["permissionType"];
    this.permissions = data["permissions"];
    this.groupId = data["groupId"];
    return sr;
  }

  updatePermissions(newPerms) {
    this.permissions = newPerms;
    if (this.identifier== "new") {
      this.identifier = null;
      return this._post(
        `${this.apiBase()}/group/${this.groupId}/admin-permissions`,
        this.toJson({for: "appengine"}),
        (data, resolve) => {resolve(this.refresh(data));},
        {}
      );
    } else {
      return this._put(
        `${this.apiBase()}/group/${this.groupId}/admin-permission/${this.identifier}`,
        this.toJson({for: "appengine"}),
        (data, resolve) => {resolve(this.refresh(data));},
        {}
      );
    }
  }

} // end Package
