
import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { DomainObjectRepo } from '../noizu/domain-object-repo';
import { FirebaseAuthService }        from '../noizu/services/firebase-auth.service';
import { AppengineRepo }      from './appengine.repo';
import { SynchQueueEntryEntity }      from '../entities/synch-queue-entry.entity';

@Injectable()
export class SynchQueueRepo extends AppengineRepo {
  public _kind = "backend-synch-queue";
  public _singular = "backend-synch-queue";

  constructor(@Inject(HttpClient) client: HttpClient, @Inject(FirebaseAuthService) auth: FirebaseAuthService) {
    super(client, auth);
  }

  entity(record) {
    return new SynchQueueEntryEntity(this.client, this.auth, record)
  }

  getListPromise(options:any = {}) {
    let url = `${this.apiBase()}/${this._kind}`;
    url = ("resultsPerPage" in options) ? url + `?resultsPerPage=${encodeURIComponent(options.resultsPerPage)}` : url + "?resultsPerPage=500";

    // Should be moved into base.
    if ("cursor" in options) {
      url = url + `&cursor=${options.cursor}`
    }

    console.log(`url: ${url}`);
    return this._getListPromise(url, (record) => {return this.entity(record); }, options);
  }
}
